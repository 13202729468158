(function(matchReport, $, undefined ) 
{    
    var selectedTextId = false;
    
    function _getTriggerId(locationIndex)
    {
        return $('#triggerDropdown' + locationIndex).val()
    }
    
    function _selectLastText(locationIndex)
    {
        if(selectedTextId === false)
        {
            var height = $('#matchReportFragments' + locationIndex)[0].scrollHeight;
            $('#matchReportFragments' + locationIndex).children().last().click();
            $('#matchReportFragments' + locationIndex).scrollTop(height);
        }
        else
        {
            var option = $('#matchReportFragments' + locationIndex).find('a[data-value="' + selectedTextId + '"]');
            option.click();
        }
    }
    
    matchReport.search = function()
    {
        var data = new Object();
        data.search = $('#searchInput').val();
        if(data.search.length == 0) return;
        $('#searchResult').getLoad('/acp/matchreport/editor/text/search', data);
    };
    
    matchReport.onClickVerify = function(element)
    {
        var locationIndex = $(element).closest('.panel-body').attr('data-location-id');
        var localeId = $('#localeSelect' + locationIndex).val();
        var text = $('#matchReportFragments' + locationIndex).find('a.active');
        if(text.length == 0 && localeId == 1) { alert('Keinen Text ausgewählt!'); return; }

        $.post('/acp/matchreport/editor/text/verified', { 'textId' : selectedTextId , 'localeId': localeId, 'verified': $(element).is(':checked') ? 1 : 0}, 
        function() 
        { 
            matchReport.onClickTrigger(locationIndex);
        });
    };

    matchReport.onClickTrigger = function(locationIndex, callback)
    {
        if(typeof callback === typeof undefined) callback = false;
        var localeId = $('#localeSelect' + locationIndex).val();
        var triggerId = $('#triggerDropdown' + locationIndex).val();
        var option =  $('#triggerDropdown' + locationIndex).find('option[value="' + triggerId + '"]');
        $('#triggerDesc' + locationIndex).val(option.attr('data-desc')); 
        $('#matchReportFragments' + locationIndex).getLoad('/acp/matchreport/editor/text/list' ,{ 'triggerId' : triggerId, 'localeId' : localeId }, function()
        {
            _selectLastText(locationIndex);
            if(callback!==false)
            callback();
        });
    };
    
    matchReport.onClickMatchReportFragment = function(textId, element)
    {
        var verified = $(element).attr('data-verified');
        $(element).parent().find('a').removeClass('active');
        var locationIndex = $(element).closest('.panel-body').attr('data-location-id');
        if (typeof verified !== 'undefined' && verified !== false) 
        {
            $('#matchTypeVerified' + locationIndex).prop('checked', verified == '1');
            $('#matchTypeVerified' + locationIndex).parent().show()
        }
        else
        {
            $('#matchTypeVerified' + locationIndex).parent().hide()
        }
        $(element).addClass('active');
        selectedTextId = textId;
        var localeId = $('#localeSelect' + locationIndex).val();
        $.get('/acp/matchreport/editor/text', { 'textId' : textId, 'localeId': localeId }, 
        function(res) 
        { 
            $('#text' + locationIndex).val(res.text);
            $('#toggle' + locationIndex + ' [name=matchType]').val(res.matchType);
            
            if(localeId > 1)
            {
                $('#matchTypeSelect' + locationIndex).hide();
            }
            else
            {
                $('#matchTypeSelect' + locationIndex).show();
            }
            console.log('#toggle' + locationIndex + ' .matchType');
            console.log('Ress ->>> ' + res.matchType);
        });
    };
    
    matchReport.init = function(initTriggerId)
    {
        selectedTextId = initTriggerId;
    };
    
    matchReport.onClickBtnAddText = function(locationIndex)
    {
        triggerId = _getTriggerId(locationIndex);
        if(triggerId <= 0) 
        { 
            alert('Trigger auswählen!'); 
            return; 
        }
        if(confirm('Neuen Text erstellen?'))
        {
            selectedTextId = false;
            $.post('/acp/matchreport/editor/text/add', { 'triggerId' : triggerId }, 
            function() 
            { 
                var selectedTextId = false;
                matchReport.onClickTrigger(locationIndex);
            });
        }
    };
    
    matchReport.onClickBtnRemoveText = function(locationIndex)
    {
        var text = $('#matchReportFragments' + locationIndex).find('a.active');
        if(text.length == 0) return;
        if(confirm('Wollen sie den Text \'' + text.html() + '\' wirklich löschen?'))
        {
            $.post('/acp/matchreport/editor/text/remove', { 'textId' : selectedTextId }, 
            function() 
            { 
                selectedTextId = false;
                matchReport.onClickTrigger(locationIndex);
            });
        }
    };
    
    matchReport.onClickBtnSaveText = function(locationIndex) 
    {
        var localeId = $('#localeSelect' + locationIndex).val();
        var text = $('#matchReportFragments' + locationIndex).find('a.active');
        if(text.length == 0 && localeId == 1) { alert('Keinen Text ausgewählt!'); return; }
        var text = $('#text' + locationIndex).val(); 
        if(text.length == 0 && localeId == 1) return;

        var matchType = $('#toggle' + locationIndex + ' [name=matchType]').val();
        console.log('Speichern mit MatchType ' + matchType);

        $.post('/acp/matchreport/editor/text/update', { 'text': text, 'textId' : selectedTextId , 'matchType' : matchType, 'localeId': localeId}, 
        function() 
        { 
            alert('Gespeichert!');
            matchReport.onClickTrigger(locationIndex);
        });
    };
    
    function insertAtCursor(myField, myValue) 
    { 
        cursorPos = $(myField).prop('selectionEnd');
        //IE support
        if (document.selection) {
            myField.focus();
            sel = document.selection.createRange();
            sel.text = myValue;
        }
        //MOZILLA and others
        else if (myField.selectionStart || myField.selectionStart == '0') {
            var startPos = myField.selectionStart;
            var endPos = myField.selectionEnd;
            myField.value = myField.value.substring(0, startPos)
                + myValue
                + myField.value.substring(endPos, myField.value.length);
        } else {
            myField.value += myValue;
        }
        $(myField).focus();
        myField.selectionEnd = cursorPos + myValue.length;
}
    
    matchReport.onClickPlaceholder = function(locationIndex, key)
    {
        if(selectedTextId === false) return;
        insertAtCursor($('#text' + locationIndex)[0], '[' + key + ']');
    };
    
    matchReport.saveTriggerDesc = function(locationIndex)
    {
        var triggerId = _getTriggerId(locationIndex);
        $('#optTrigger_' + locationIndex + '_' + triggerId).attr('data-desc', $('#triggerDesc' + locationIndex).val());
        $.post('/acp/matchreport/editor/trigger/update', { 'triggerId': triggerId, 'description' : $('#triggerDesc' + locationIndex).val() }, 
        function() 
        { 
            alert('Gespeichert!');
        });
        
    };
    
    matchReport.onClickBtnTestText = function(locationIndex) 
    {
        var text = $('#matchReportFragments' + locationIndex).find('a.active');
        if(text.length == 0) { alert('Keinen Text ausgewählt!'); return; }
        var text = $('#text' + locationIndex).val(); 
        if(text.length == 0) return;


        textTest = $('#text' + locationIndex).val();

        console.log('Test Trigger Text started!' + textTest );
        var data = { text: textTest };
        console.log(' deliverd Data : ' + data.toString() );
        olMsgBox.postLoad('/acp/matchreport/editor/text/test' , data , function() { } );
    };

    matchReport.onChangeTextMatchType = function(locationIndex)
    {
        var text = $('#matchReportFragments' + locationIndex).find('a.active');
        if(text.length == 0) { alert('Keinen Text ausgewählt!'); return; }
        var text = $('#text' + locationIndex).val(); 
        if(text.length == 0) return;

        console.log('text var ' + text );

        $('select[name=matchType] option').filter(':selected').text()

        $.post('/acp/matchreport/editor/text/update', { 'text': text, 'textId' : selectedTextId, 'matchType' : 5 },
        function()
        {
            alert('Gespeichert!');
        });

    }
    
    

    matchReport.onClickMatchReportSearchResult = function(typeId, triggerId, textId)
    {
        if($('#toggleCollapse' + typeId).attr("aria-expanded") !== 'true')
            $('#toggleCollapse' + typeId).click();
        olGUI.scrollTo($('#toggleCollapse' + typeId));
        $('#triggerDropdown' + typeId).val(triggerId);
        matchReport.onClickTrigger(typeId, function()
        {
            $('#matchReportFragments' + typeId).val(textId);
            $('#matchReportFragments' + typeId + ' .list-group-item[data-value=' + textId + ']').click();
            $('#matchReportFragments' + typeId + ' .list-group-item[data-value=' + textId + ']')[0].scrollIntoView();
        });
    }


}( window.matchReport = window.matchReport || {}, jQuery ));
