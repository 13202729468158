(function(lazyLoader, $, undefined) {
    var fURL = undefined;

    lazyLoader.loadPages = function(forceLoad, fallbackURL, callback) {
        if (jQuery.isFunction(forceLoad)) {
            callback = forceLoad;
            forceLoad = false;
        } else if (jQuery.isFunction(fallbackURL)) {
            callback = fallbackURL;
            fallbackURL = undefined;
        }
        if (typeof forceLoad === typeof undefined || typeof forceLoad !== typeof true)
            forceLoad = false;

        $("#nextPage:not([data-loading='true'])").each(function() {
            var e = $(this);

            if (forceLoad || isInViewport(e)) {
                var url = $(e).attr('data-url') || fallbackURL || fURL;
                var id = parseInt($(e).attr('data-page-id'));

                var clbk = function(hasNext) {
                    if (typeof hasNext === typeof undefined) {
                        hasNext = false;
                    }
                    $(e).attr('data-page-id', ++id);
                    $(e).attr("data-loading", "");
                    if (jQuery.isFunction(callback))
                        callback();
                    if (forceLoad && hasNext)
                        lazyLoader.loadPages(forceLoad);
                };

                if (typeof id !== typeof undefined && id !== false) {
                    $(e).attr("data-loading", "true");
                    var params = parseURLParams();
                    params['pageId'] = id;
                    loadPageContent(url, e, params, clbk);
                }
            }
        });
    };

    lazyLoader.loadNews = function(forceLoad) {
        if (typeof forceLoad === typeof undefined || typeof forceLoad !== typeof true)
            forceLoad = false;

        $(".ol-news-parent.ol-lazy-loading:not([data-lazy-loading-done='true']), .ol-news.ol-lazy-loading:not([data-lazy-loading-done='true'])").each(function() {
            var news = $(this);
            var plc = news.prev(".ol-placeholder");

            if (forceLoad || isInViewport(news)) {
                var imgsToSrc = news.find("img.ol-lazy-loading:not([data-lazy-loading-done='true'])");
                var imgsToCSS = news.find("img.ol-lazy-loading-to-css:not([data-lazy-loading-done='true'])");
                var vig = news.find("div.ol-vignette-1.ol-lazy-loading:not([data-lazy-loading-done='true'])");
                var size = imgsToSrc.length + imgsToCSS.length;

                if (size <= 0) {
                    _done(function() {});
                    return;
                }

                function _done(callback) {
                    if (typeof vig !== typeof undefined && vig !== false) {
                        vig.attr("data-lazy-loading-done", "true");
                    }
                    news.attr("data-lazy-loading-done", "true");
                    setTimeout(function() {
                        plc.css("opacity", 0);
                        news.addClass("ol-lazy-loading-fade-in");
                        callback();
                        if (typeof vig !== typeof undefined && vig !== false) {
                            vig.addClass("ol-lazy-loading-fade-in");
                        }
                    }, 50);
                }

                function _load() {
                    if (size <= 0) {
                        $(imgsToSrc).each(function() {
                            $(this).removeAttr("data-src");
                        });
                        $(imgsToCSS).each(function() {
                            $(this).remove();
                        });
                        _done(function() {
                            news.dequeue("images");
                        });
                    }
                }

                function _error(imgSrc, callback) {
                    $([imgsToSrc, imgsToCSS]).each(function() {
                        $(this).attr("src", imgSrc);
                        $(this).attr("data-lazy-loading-done", "true");
                    });
                    _done(callback);
                }
                
                var _imgLoad = function(img)
                {
                    img = $(img);
                    if(img.length == 0) return;
                    var oldSrc = img.attr('src');
                    var dataSrc = img.attr('data-src');
                    img.crossOrigin = "Anonymous";
                    
                    img.load(dataSrc, function(response, status, xhr) {
                            var status = xhr.status;
                            if (status == 202) {
                                setTimeout(function() {
                                    img.parent().addClass('loading-ball-overlay');
                                    img.addClass("ol-lazy-loading-fade-in");
                                    _imgLoad(img);
                                }, 1500);
                            }
                            else {
                                img.load(function() {
                                    news.queue("images", function(next) {
                                        img.parent().removeClass('loading-ball-overlay');
                                        img.addClass("ol-lazy-loading-fade-in");
                                        next();
                                    });
                                    size--;
                                    _load();
                                }).error(function() {
                                    _error(oldSrc, function() {
                                        img.parent().removeClass('loading-ball-overlay');
                                        img.addClass("ol-lazy-loading-fade-in");
                                    });
                                    return false;
                                });
                                img.attr("src", dataSrc);
                                img.attr("data-lazy-loading-done", "true");
                            }
                        });
                };

                imgsToSrc.each(function() {
                    var img = this;
                    if (typeof img !== typeof undefined && img !== false) {
                        img = $(img);

                        var oldSrc = img.attr('src');
                        var dataSrc = img.attr('data-src');
                        
                        if (typeof dataSrc !== typeof undefined && dataSrc !== false) {
                            img.attr("data-lazy-loading-done", "true");
                            img.parent().addClass('loading-ball-overlay');
                            _imgLoad(img);
                        }
                    }
                });

                imgsToCSS.each(function() {
                    var img = this;
                    if (typeof img !== typeof undefined && img !== false) {
                        img = $(img);

                        var oldSrc = img.attr('src');
                        var dataSrc = img.attr('data-src');

                        if (typeof dataSrc !== typeof undefined && dataSrc !== false) {
                            img.load(function() {
                                news.queue("images", function(next) {
                                    img.addClass("ol-lazy-loading-fade-in");
                                    next();
                                });
                                img.parent("div.ol-news-image-parent").css("background-image", "url(" + dataSrc + ")");
                                size--;
                                _load();
                            }).error(function() {
                                _error(oldSrc, function() {
                                    img.addClass("ol-lazy-loading-fade-in");
                                });
                                return false;
                            });

                            img.attr("src", dataSrc);
                            img.attr("data-lazy-loading-done", "true");
                        }
                    }
                });
            }
        });
    };

    lazyLoader.loadImages = function(forceLoad) {
        if (typeof forceLoad === typeof undefined || typeof forceLoad !== typeof true)
            forceLoad = false;

        $("img.ol-lazy-loading:not([data-lazy-loading-done='true'])").each(function() {
            var img = $(this);
            if (forceLoad || isInViewport(img)) {
                var vig = img.next("div.ol-lazy-loading:not([data-lazy-loading-done='true'])");

                var oldSrc = img.attr('src');
                var dataSrc = img.attr('data-src');

                if (typeof dataSrc !== typeof undefined && dataSrc !== false) {
                    img.load(function() {
                        setTimeout(function() {
                            img.addClass("ol-lazy-loading-fade-in");
                            if (typeof vig !== typeof undefined && vig !== false) {
                                vig.addClass("ol-lazy-loading-fade-in");
                            }
                        }, 50);
                    }).error(function() {
                        img.attr("src", oldSrc);
                    });

                    img.attr("src", dataSrc);
                    img.attr("data-lazy-loading-done", "true");
                    if (typeof vig !== typeof undefined && vig !== false) {
                        vig.attr("data-lazy-loading-done", "true");
                    }
                }
            }
        });
    };

    lazyLoader.loadPageContent = function(url, target, data, append, callback) {
        if (typeof data === typeof undefined)
            data = {};
        if (typeof append === typeof undefined)
            append = false;
        else if (jQuery.isFunction(append)) {
            callback = append;
            append = false;
        }
        if (typeof callback === typeof undefined)
            callback = false;

        addLoadingAnimationToElement(target);
        clbk = function() {
            removeLoadingAnimationFromElement(target);
            if (callback !== false)
                callback();
        };

        if (!append)
            unloadPage(target);
        $(target).getLoad(url, data, clbk, append);
    };

    lazyLoader.setFallbackURL = function(fallbackURL) {
        fURL = fallbackURL;
    };

    function loadPageContent(url, target, data, callback) {
        if (typeof data === undefined)
            data = {};
        if (typeof callback === undefined)
            callback = false;

        addLoadingAnimationToElement(target);
        var clbk = function(hasNext) {
            if (typeof hasNext === typeof undefined) {
                hasNext = false;
            }
            removeLoadingAnimationFromElement(target);
            if (callback !== false)
                callback(hasNext);
        };

        loadBefore(url, target, data, clbk);
    }

    function loadBefore(url, target, params, callback) {
        var selector, response, off = url.indexOf(" ");
        target = $(target);

        if (off > -1) {
            selector = jQuery.trim(url.slice(off));
            url = url.slice(0, off);
        }

        if (jQuery.isFunction(params)) {
            callback = params;
            params = undefined;
        }

        var hasNext = false;
        if (target.length > 0) {
            return jQuery.ajax({
                url: url,
                type: "GET",
                dataType: "json",
                data: params
            }).done(function(jsonObj) {
                response = arguments;
                hasNext = jsonObj.hasNextPage;
                var content = selector ? jQuery("<div>").append(jQuery.parseHTML(jsonObj.view)).find(selector) : jsonObj.view;
                target.before(content);
                if (!hasNext) {
                    unloadPage(target);
                    target.remove();
                }
            }).always(callback && function(jqXHR, status) {
                olAds.loadAxContainers();

                target.each(function() {
                    callback.apply(this, [hasNext].concat(response || [jqXHR.responseText, status, jqXHR]));
                });
            });
        }
    }
}(window.lazyLoader = window.lazyLoader || {}, jQuery));
