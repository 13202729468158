// TODO Move to namespace object

var olStadiumEditorCall = 0;

function initializeMyStadium() {
    $(function(eReady) {
        $(document).on('keyup', '.stadion-wrapper input', function(event) { if (event.keyCode == 13) $(this).blur(); })
        
        console.log("ready");
        $('.mystadiumTab').on('click', function(e) {
            var stadiumId = $(this).attr('data-id');

            if ($(this).parent().hasClass('ui-tabs-active')) return;

            var mystadiumURL = '';
            if (typeof mystadiumURLVar != 'undefined') {
                mystadiumURL = mystadiumURLVar;
            }
            mystadiumURL = decodeURIComponent(mystadiumURL);
            mystadiumURL = mystadiumURL.replace(':stadiumId', stadiumId);

            if (typeof addLoadingAnimationToElementNoTimeOut == 'function' && $('#content').length) {
                addLoadingAnimationToElementNoTimeOut($('#content'));
            }
            $.ajax({
                type: 'get',
                url: mystadiumURL,
                success: function(data){
                    var data = $(data).filter('#mystadiumContent').html();
                    $("#mystadiumContent").html(data);
                    callAfterPageLoad();
                },
                error: function(jqXHR, exception) {
                    console.error(exception);
                    //window.location.reload();
                }
            });

            return false;
        });

        callAfterPageLoad();
    });

    $(function() {
        var headerBackgroundImage = '';
        if (screen.width <= 767) {
            headerBackgroundImage = $('.stadium-bg').attr('data-image-src-mobile');
        } else {
            headerBackgroundImage = $('.stadium-bg').attr('data-image-src');
        }
        $('.stadium-bg').css('background-image', 'url(' + headerBackgroundImage + ')')
    });
}

function callAfterPageLoad() {
    var editorData = [];
    if (typeof editorDataVar != 'undefined') {
        editorData = editorDataVar;
    }
    if ($('#stadiumLoadingImg').length) {
        $('#stadiumLoadingImg').on('load', function() {
            removeLoadingAnimationFromElement($('#content'));
            clearTimeout(olStadiumEditorCall);
            olStadiumEditorCall = setTimeout(function() {
                olStadiumEditor.init(editorData);
                clearTimeout(olStadiumEditorCall);
            }, 150);
        });
    }
    removeLoadingAnimationFromElement($('#content'));
    clearTimeout(olStadiumEditorCall);
    olStadiumEditorCall = setTimeout(function() {
        olStadiumEditor.init(editorData);
        clearTimeout(olStadiumEditorCall);
    }, 150);
}