(function(olAuth, $, undefined) {
    var bEmailValid = false;
    var bPWValid = false;
    var bGeoIdValid = false;
    var bAccessCode = false;
    var userStateId = false;
    var postregisterTeamTimer = false;
    var agbAccepted = false;
    var cancellationAccepted = false;

    bPostRegister = {};
    bPostRegister['inputStateFavClub'] = true;



    olAuth.onClickSponsorGo = function() {
        olOverlayWindow.close();
        
        var $anchor = $('#ol-root');
        addLoadingAnimationToElement($anchor, {instant: true});
        location.href = '/';
        removeLoadingAnimationFromElement($anchor);
    };

    olAuth.onClickSponsorRegister = function() {
        $('#btnRegister').addClass('ol-button-loading');
        var data = $('#regForm').serialize().replace(/(^|&)sponsor_(.*)=/g, '$1$2');
        var items = $('#regForm').serializeArray();
        var data = {};
        for (var i = 0;  i < items.length;  i++) {
            var item = items[i];
            var name = item.name.replace(/^sponsor_/, '');
            data[name] = item.value;
        }
        olOverlayWindow.postLoad('/sponsor/register', data, undefined, undefined, true);
    };

    olAuth.showGTC = function (sponsor) {
        if (sponsor) {
            var b2b = true;
        } else {
            var b2b = false;
        }
        $.get('/gtc', {"b2b": b2b}, function (html) {
            olMsgBox.msg('', html, false, Lang.trans('js/user.close'), true);
        });
    };

    olAuth.showPrivacy = function () {
        $.get('/privacy', function (html) {
            olMsgBox.msg('', html, false, Lang.trans('js/user.close'), true);
        });
    };


    olAuth.onClickSavePostRegistrationTeam = function() {
        var data = [
            {name: 'favClubId', value: $('#hiddenInputFavClubId').val()},
            {name: '_token', value: $('input[name="_token"]').val()}
        ];
        
        olOverlayWindow.addLoadingAnimation();
        $.post("/postregister/savefavclub", data, function(data) {
            window.location.href = "/";
        });
    };

    function selectLocationOnChange(id, stateId) {
        //console.log("selectLocationOnChange");
        $("#btnRegister").removeClass('disabled');
        $("#btnRegister").prop('disabled', false);
        $("#btnRegister").addClass('ol-state-primary-color-' + stateId);
        $('#divLocation').removeClass('inputStateInvalid');
        $('#divLocation').addClass('inputStateValid');
        bGeoIdValid = true;
        $("#inputHiddenGeoId").val(id);
        updateRegistrationGUIState();
    }

    olAuth.initRegistration = function() {
        bEmailValid = false;
        bPWValid = false;
        bGeoIdValid = false;
        userStateId = false;
        postregisterTeamTimer = false;
        agbAccepted = false;
        cancellationAccepted = false;
        $("#divComplexityBar").progressbar({value: 100 });
        progressbarValue = $($("#divComplexityBar")).find( ".ui-progressbar-value" );
        progressbarValue.animate({width: "0%"}, 'fast');
        $('#inputPassword').complexify({}, function (valid, complexity) {
            var progressBar = $('#divComplexityBar');
            rescale = Math.min(100, complexity * 2);
            factor = rescale / 100;
            r = parseInt((1 - factor) * 255);
            g = parseInt(factor * 255);
            b = parseInt(0 * 255);
            color = "rgb(" + r + ", " + g + ", " + b + ")";
            progressbarValue = $(progressBar).find( ".ui-progressbar-value" );
            $(progressbarValue).css({'background' : color });
            progressbarValue.stop(true, false);
            progressbarValue.animate({width: rescale + "%", 'background-color': color}, 'fast');
        });
        $('#registerAgb').click(function(){ // check if agb button is checked 
            $(this).attr('checked', function(index, attr){
                return attr == 'checked' ? null : 'checked';
            });
           if ($('#registerAgb').prop('checked')){
                agbAccepted = true;
           } else {
                agbAccepted = false;
           }
           updateRegistrationGUIState();
        });
        
        $('#inputLocation').typeahead({
            matcher: function(item) { return true },
            updater: function(item) { selectLocationOnChange($(item).attr("id"), $(item).attr("stateId")); return item; },
            limit: 10,
            items: 100,
            minLength: 0
        });

        if ($("#inputAccessCode").length == 0)
            bAccessCode = true;
    };

    olAuth.inputAccessCodeOnKeyUp = function(value) {
        count = $("#inputAccessCode").length;
        if (count == 1) {
            split = value.split("-");
            bComponentSize = true;
            $.each(split, function(index, value) {
                if (value.length != 4) bComponentSize = false;
            });
            bAccessCode = (split.length === 4) && bComponentSize;
            if (bAccessCode) {
                $("#btnAccessCode").removeClass('disabled');
                $("#btnAccessCode").addClass('ol-state-primary-color-0');
            } else {
                $("#btnAccessCode").addClass('disabled');
                $("#btnAccessCode").removeClass (function (index, className) {
                    return (className.match (/(^|\s)ol-state-primary-color-\S+/g) || []).join(' ');
                });
            }
        }
    };

    olAuth.onClickAccessCodeButton = function() {
        olMsgBox.postLoad('/auth/isvalidcoupon', { accessCode: $("#inputAccessCode").val() }, function() {
            $response = olMsgBox.getCurrentJSON();
            if ($response.check === 'success') {
                $('#accessCodeOverlay').fadeOut();
                $('#registerMask').removeClass('beta-register-mask');
                $('#inputHiddenAccessCode').val($("#inputAccessCode").val());
            }
        });
    };

    olAuth.inputFavoriteClubOnKeyUp = function(value) {
        $("hiddenInputFavClubId").val(-1);
        if (value.length == 0) {
            bPostRegister['inputStateFavClub'] = true;
            updateState($("#inputStateFavClub"), true);
        } else {
            bPostRegister['inputStateFavClub'] = false;
            updateState($("#inputStateFavClub"), false);
        }
    };

    olAuth.inputLocationOnKeyUp = function(value, key, inPostRegistration) {
        if (key === 38 || key === 40 || key === 13) return;

        $("#btnRegister").addClass('disabled');
        $("#btnRegister").prop('disabled', true);
        $("#btnRegister").removeClass(function (index, className) {
            return (className.match (/(^|\s)ol-state-primary-color-\S+/g) || []).join(' ');
        });
        $('#divLocation').addClass('inputStateInvalid');
        $('#divLocation').removeClass('inputStateValid');
        bGeoIdValid = false;
        var payload = { location : value };
        $.get("/auth/getlocations", payload, function (data) {
            //data = JSON.stringify(data);
            typeaheadData = $('#inputLocation').data('typeahead');
            typeaheadData.source = data;
            $("#inputLocation").typeahead('val', "cookie").trigger("input").typeahead('open');
            if (!inPostRegistration) { 
                updateRegistrationGUIState();
            } else {
                olAuth.callbackValidatePostRegisterInputPage1();
            }
        }, "json");
    };

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    
    function verifyInvitationCode(code) {
        console.log("Code: ", code, "Length:", code.length);
        if (code.length == 0) {
            bEmailValid = true;
            $("#invitationCode").removeClass("inputStateInvalid");
            updateRegistrationGUIState();
            window.clearInterval(invTimer);
            invTimer = null;
            return true;
        };
        var payload = {code: code};
        $.getJSON("/auth/isinvitationcode", payload, function(data) {
            if (data['valid']) {
                bEmailValid = true;
                $("#invitationCode").removeClass("inputStateInvalid");
                $("#invitationCode").addClass("inputStateValid");
                $("#emailStateInfo").css('color', 'green');
            } else {
                bEmailValid = false;
                $("#invitationCode").addClass("inputStateInvalid");
                $("#invitationCode").removeClass("inputStateValid");
                // $("#emailStateInfo").css('color', 'red');
            }
            $("#invitationCodeUserName").html(data['info']);
            updateRegistrationGUIState();
            window.clearInterval(invTimer);
            invTimer = null;
            $('#invitationCodeUser').show();
        });
    }

    function verifyEmail(email, sponsor) {
        var payload = {email: email};
        $.getJSON("/auth/isvalidemail", payload, function(data) {
            if (data['valid']) {
                bEmailValid = true;
                $("#divEmail").removeClass("inputStateInvalid");
                $("#divEmail").addClass("inputStateValid");
                $("#emailStateInfo").css('color', 'green');
            } else {
                bEmailValid = false;
                $("#divEmail").addClass("inputStateInvalid");
                $("#divEmail").removeClass("inputStateValid");
                // $("#emailStateInfo").css('color', 'red');
            }
            $("#emailStateInfo").html(data['error']);
            updateRegistrationGUIState();
            window.clearInterval(emailTimer);
            emailTimer = null;
        });
    }

    var emailTimer = null;
    var emailInputChanged = false;

    olAuth.onChangeInputEmail = function(element) {
        emailInputChanged = true;
        email = $(element).val();
        if (emailTimer === null) {
            emailTimer = window.setInterval(function() {
                if (emailInputChanged) {
                    emailInputChanged = false;
                    email = $("#inputEMail").val();
                    if (validateEmail(email)) {
                        verifyEmail(email);
                    } else {
                        updateState($("#inputEMail"), "hide");
                        $("#divEmail").addClass("inputStateInvalid");
                        $("#divEmail").removeClass("inputStateValid");
                    }
                }
            }, 1000);
        }
    };

    var emailPreregTimer = null;
    var emailPreregInputChanged = false;

    olAuth.validatePreregMail = function (element) { 
        emailPreregInputChanged = true;
        email = $(element).val();
        if (emailPreregTimer === null) {
            emailPreregTimer = window.setInterval(function() {
                if (emailPreregInputChanged) {
                    emailPreregInputChanged = false;
                    email = $("#inputEMailPrereg").val();
                    if (validateEmail(email)) {
                        $("#divEmailPrereg").addClass("inputStateValid");
                        $("#divEmailPrereg").removeClass("inputStateInvalid");
                    } else {
                        updateState($("#inputEMailPrereg"), "hide");
                        $("#divEmailPrereg").addClass("inputStateInvalid"); 
                        $("#divEmailPrereg").removeClass("inputStateValid");                       
                    }
                }
            }, 1000);
        }
    }

    olAuth.onBlurInputEmail = function(element, sponsor) {
        email = $(element).val();
        verifyEmail(email, sponsor);
    };

    olAuth.onBlurInputEmail2 = function(element, sponsor) {
        email = $(element).val();
        verifyEmail(email, sponsor);
    };

    function updateState(stateElement, valid) {
        if (valid === "hide") {
            $("#emailStateInfo").html("");
        } else if (valid) {
            $(stateElement).removeClass("inputStateInvalid");
            $(stateElement).addClass("inputStateValid");
        } else {
            $(stateElement).addClass("inputStateInvalid");
            $(stateElement).removeClass("inputStateValid");;
        }
    }

    olAuth.onChangeInputPassword = function(element) {
        var pw = $(element).val();
        if (pw.length >= $("#pwLengthStateInfo").attr("pwMin") && pw.length <= $("#pwLengthStateInfo").attr("pwMax")) {
            $("#pwLengthStateInfo").hide();
            updateState($("#divPassword"), true);
        } else {
            updateState($("#divPassword"), false);
            $("#pwLengthStateInfo").show();
        }

        olAuth.onChangeInputPasswordConfirmation($("#inputPasswordConfirmation"))
    };

    olAuth.OfficeOnChangeInputPassword = function(element) {
        var pw = $(element).val();
        if (pw.length >= $("#pwLengthStateInfo").attr("pwMin") && pw.length <= $("#pwLengthStateInfo").attr("pwMax")) {
            $("#pwLengthStateInfo").hide();
            updateState($("#divPassword"), true);
        } else {
            updateState($("#divPassword"), false);
            $("#pwLengthStateInfo").show();
        }
        olAuth.onChangeInputPasswordConfirmation($("#inputPasswordConfirmation"))
    };

    function checkPassword(element) {
        var pw = $("#inputPassword").val();
        var pwC = $(element).val();
        if (pw === pwC) {
            $("#pwConfirmationStateInfo").hide();
        } else {
            $("#pwConfirmationStateInfo").show();
        }

        if ($("#pwConfirmationStateInfo").is(":visible")) {
            $("#divPasswordConfirmation").addClass("has-error");
        } else {
            $("#divPasswordConfirmation").removeClass("has-error");
        }

        if (!$("#pwConfirmationStateInfo").is(":visible") && !$("#pwLengthStateInfo").is(":visible")) {
            bPWValid = true;
            updateState($("#divPasswordConfirmation"), true);
        } else {
            bPWValid = false;
            updateState($("#divPasswordConfirmation"), false);
        }
        updateRegistrationGUIState();
    }

    olAuth.onBlurInputPasswordConfirmation = function(element) {
        checkPassword(element);
    };

    olAuth.onChangeInputPasswordConfirmation = function(element) {
        var pw = $("#inputPassword").val();
        var pwC = $(element).val();
        if (pw.length == pwC.length || pwC.length > pw.length)
            checkPassword(element);
    };
    
    var invitationCodeChanged = false;
    var invTimer = null;
    olAuth.onChangeInputInvitationCode = function(element) {
        var code = $("#inputInvitationCode").val();
        
        $('#invitationCodeUser').hide();
        invitationCodeChanged = true;
        if (invTimer !== null) clearInterval(invTimer);
        invTimer = window.setInterval(function() {
            if (invitationCodeChanged) {
                invitationCodeChanged = false;
                code = $("#inputInvitationCode").val();
                verifyInvitationCode(code);
            }
        }, 1000);
    };
    
    function updateRegistrationGUIState() {
        if (validRegistration()) {
            $("#btnRegister").removeAttr('disabled');
            $("#btnRegister").removeClass('disabled');
        } else {
            $("#btnRegister").attr('disabled', true);
            $("#btnRegister").addClass('disabled');
        }
    }

    function validRegistration() {
        return bEmailValid && bPWValid && bGeoIdValid && bAccessCode && agbAccepted;

    }

    olAuth.onClickLogIn = function() {
        var data =  $('#regForm').serializeArray();
        if ($('#inputPassword').val().length == 0) {
            olMsgBox.msgById('missingPassword');
            return;
        }
        if ($('#inputUsername').val().length == 0) {
            olMsgBox.msgById('missingUsername');
            return;
        }
        $('#btnLogin').addClass('ol-button-loading');
        olOverlayWindow.addLoadingAnimation();
        $('.ol-auth-shake').removeClass('ol-auth-shake');
        $.post('/login', $('#regForm').serializeArray(), function(response) {
            var liveModeSelectionUrl = olConfig.liveModeSelectionUrl || '/liveticker/modeselection';
            if (response.length > 0 && response !== liveModeSelectionUrl) {
                olOverlayWindow.removeLoadingAnimation();
                olOverlayWindow.setContent(response);
                $('.help-block').addClass('ol-auth-shake')
                $('#btnLogin').removeClass('ol-button-loading');
            } else {
                if (response === liveModeSelectionUrl) {
                    location.href = liveModeSelectionUrl;
                } else {
                    location.href = "/";
                }
            }
        });
    };
    
    olAuth.onClickRegisterFacebook = function() {
        if (olAuth.isAGBAccepted())
            return olAuth.trackRegisterEvent();
        olMsgBox.msgById('acceptAGBFirst');
        return false;
    };
    
    olAuth.isAGBAccepted = function(callback) {
        return agbAccepted;
    };
    
    // If value is a number >= 0 then only facebook is called (with that value)
    olAuth.trackRegisterEvent = function(isSponsor, value) {
        if (typeof isSponsor !== typeof true) isSponsor = false;
        if (typeof value !== 'number') value = -1;

        var gEv = 'signup', fbEv = 'CompleteRegistration', tEv = 'complete_registration';
        if (isSponsor === true) {
            gEv += '-sponsor';
            fbEv += 'Sponsor';
            tEv += '_sponsor';
        }
        if (value >= 0) olAnalytics.event(gEv, 'registration', 'registration-form');
        if (olConfig.env === 'production' || (olConfig.debug && olConfig.facebookPixelId)) {
            if (window.fbq) {
                var v = (value >= 0)? value : 1;
                var args = {status: true, content_name: location.host, value: v};
                //args['value'+v] = v;
                fbEv += (v == 1)? '' : ''+value;
                window.fbq('track', fbEv, args);
            }
            //if (value >= 0 && typeof _tfa !== 'undefined') _tfa.push({notify: 'event', name: tEv, id: 1270864});
        }
        return true; //important for executing href as well (used in onclick)
    };

    olAuth.onClickRegister = function() {
        $('#btnRegister').addClass('ol-button-loading');
        
        var obj = {'v': '1'};
        var bid = getCookie('adcell').split('####')[0];
        if (bid.length > 0)
            obj.adcell = {'bid': bid};

        olAuth.trackRegisterEvent(false, 0);
        olOverlayWindow.postLoad('/register', $('#regForm').serializeAllArray() + '&' + $.param({'medium': obj}), function (response) {
            olAuth.trackRegisterEvent();
            $('#btnRegister').removeClass('ol-button-loading');
        }, undefined, true); 
        return false;
    };

    olAuth.onClickSavePostRegistration = function() {
        var data = $('#postRegForm').serializeArray();

        var catId = $('.ol-team-color-selector-main').attr('data-cat');
        var index = $('.ol-team-color-selector-main').attr('data-color-index');
        data.push({name: 'primaryColor', value: index});

        var catIdSecondary = $('.ol-team-color-selector-secondary').attr('data-cat');
        var indexSecondary = $('.ol-team-color-selector-secondary').attr('data-color-index');
        data.push({name: 'secondaryColor', value: indexSecondary});

        $('#btnSave').addClass('ol-button-loading');
        
		olAuth.trackRegisterEvent(false, 2);
        $.post('/postregister/save', data, function(response) {
            if (response.trim().length == 0) {
                olAuth.trackRegisterEvent(false, 3);
                $.get('/postregister', {overlay: 'true'}, function (rc) {
                    olAuth.trackRegisterEvent(false, 4);
                    olOverlayWindow.setContent(rc);
                    $('.ol-overlay-window-content').css('min-height', '');
                });
                return true;
            } else if (response.match(/<!--isSponsor=1-->/)) {
                // Fehlermeldung
                olOverlayWindow.setContent(response);
                olAuth.callbackValidateSponsorPostRegisterInputPage();
                $('#btnSave').prop('disabled', true).addClass('disabled');
                $('.ol-register-form-input').click();
            }
        });
    };

    function closeColorSelector() {
        olAuth.focusToColor(this);
        $('#colorCategorySelector').hide();
        $('#teamColorParent').removeClass('primary');
        $('#teamColorParent').removeClass('secondary');
        $('.color-selector').hide();
        $('.colorCategoryWrapper').hide();
    }

    olAuth.updateColorSelectorTimer = function() {
        if (typeof colorSelectorCloserTimer !== typeof undefined)
            clearTimeout(colorSelectorCloserTimer);

        colorSelectorCloserTimer = setTimeout(closeColorSelector, 15000);
    };

    olAuth.onClickBadge = function(element) {
        closeColorSelector();
        if ($(element).prev().hasClass('register-form-badge-big')) {
            olAuth.onClickBadgeSelection(1);
        }
        if ($(element).next().hasClass('register-form-badge-big')) {
            olAuth.onClickBadgeSelection(-1);
        }
    };

    olAuth.onClickBadgeBulletPoint = function(badgeId) {
        $('.ol-carousel-bullets > div').removeClass('selected');
        $('#carouselBullet' + badgeId).addClass('selected');
        $('.register-form-badge-small').removeClass('register-form-badge-small');
        $('.register-form-badge-big').removeClass('register-form-badge-big');
        var newSelection = $('.badge-selection[data-value=' + badgeId +']');
        newSelection.next().addClass('register-form-badge-small');
        newSelection.prev().addClass('register-form-badge-small');
        $(newSelection).addClass('register-form-badge-big');
        var prev = $(newSelection).prev();
        if (prev.length > 0) {
            $('.badge-selektor-arrow-left').removeClass('inactive');
        } else {
            $('.badge-selektor-arrow-left').addClass('inactive');
        }
        var next = $(newSelection).next();
        if (next.length > 0) {
            next.show();
            $('.badge-selektor-arrow-right').removeClass('inactive');
        } else {
            $('.badge-selektor-arrow-right').addClass('inactive');
        }
    };

    olAuth.onClickBadgeSelection = function(direction) {
        closeColorSelector();
        var selection = $('.register-form-badge-big');
        var newSelection = false;
        if (direction == -1) {
            if (selection.prev().length == 0) return;
            else newSelection = selection.prev();
        } else {
            if (selection.next().length == 0) return;
            else newSelection = selection.next();
        }

        $('.ol-carousel-bullets > div').removeClass('selected');
        $('#carouselBullet' + $(newSelection).attr('data-value')).addClass('selected');

        $('.register-form-badge-small').removeClass('register-form-badge-small');
        newSelection.next().addClass('register-form-badge-small');
        newSelection.prev().addClass('register-form-badge-small');
        $('.register-form-badge-big').removeClass('register-form-badge-big');

        $(newSelection).addClass('register-form-badge-big').removeClass('register-form-badge-small').show();
        $('#badgeId').val($(newSelection).attr('data-value'));

        var prev = $(newSelection).prev();
        if (prev.length > 0) {
            $('.badge-selektor-arrow-left').removeClass('inactive');
        } else {
            $('.badge-selektor-arrow-left').addClass('inactive');
        }
        var next = $(newSelection).next();
        if (next.length > 0) {
            next.show();
            $('.badge-selektor-arrow-right').removeClass('inactive');
        } else {
            $('.badge-selektor-arrow-right').addClass('inactive');
        }
    };

    olAuth.getColorElement = function() {
        return $('#teamColorParent').hasClass('primary') ? $('#prime-color') : $('#second-color');
    };

    olAuth.focusToColor = function(element) {
        var catId = $(element).attr('data-cat');
        var index = $(element).attr('data-color-index');
        $('.active.ol-register-form-colorgrid-block').removeClass('active');
        $('#colorCategory' + catId).find('.index' + index).addClass('active');
        $('.active.ol-register-form-colorgrid-block-categorie').removeClass('active');
        $('.color-cat-' + catId).addClass('active');
    };

    olAuth.callbackValidatePostRegisterInputPage2 = function() {
        if('inputStateTeamName' in bPostRegister && bPostRegister['inputStateTeamName']) {
            $("#btnSave").removeAttr('disabled');
            $("#btnSave").removeClass('disabled');
            $("#btnSave").addClass('ol-state-primary-color-' + userStateId);
        } else {
            $("#btnSave").attr('disabled', true);
            $("#btnSave").addClass('disabled');
            $("#btnSave").removeClass('ol-state-primary-color-' + userStateId);
        }
    };

    olAuth.callbackValidatePostRegisterInputPage1 = function() {
        if ('inputStateUserName' in bPostRegister && bPostRegister['inputStateUserName'] 
        && (('inputStateFirstName' in bPostRegister && bPostRegister['inputStateFirstName']) 
           || !('inputStateFirstName' in bPostRegister) 
         || $('#inputFirstName').val().length === 0) 
        && (('inputStateLastName' in bPostRegister && bPostRegister['inputStateLastName']) 
        || !('inputStateLastName' in bPostRegister) 
                || $('#inputLastName').val().length === 0)
        && ($('#inputLocation').size() == 0 || bGeoIdValid)) {
            $("#btnNext1").removeAttr('disabled');
            $("#btnNext1").removeClass('disabled');
            $("#btnNext1").addClass('ol-state-primary-color-' + userStateId);
        } else {
            $("#btnNext1").attr('disabled', true);
            $("#btnNext1").addClass('disabled');
            $("#btnNext1").removeClass('ol-state-primary-color-' + userStateId);
        }
    };

    olAuth.callbackValidateSponsorPostRegisterInputPage = function() {
        if (('inputStateCompanyName' in bPostRegister && bPostRegister['inputStateCompanyName'])
         && ('inputStateFirstName' in bPostRegister && bPostRegister['inputStateFirstName'])
         && ('inputStateLastName' in bPostRegister && bPostRegister['inputStateLastName'])) {
            $("#btnSave").removeAttr('disabled');
            $("#btnSave").removeClass('disabled');
            $("#btnSave").addClass('ol-state-primary-color-' + userStateId);
        } else {
            $("#btnSave").attr('disabled', true);
            $("#btnSave").addClass('disabled');
            $("#btnSave").removeClass('ol-state-primary-color-' + userStateId);
        }
    };

    olAuth.onChangeInputUserName = function(value, callback, optional) {
        if (callback === undefined) callback = olAuth.callbackValidatePostRegisterInputPage1;
        if (optional === undefined) optional = false
        onChangeValidateInput(value, "username", "/postregister/isvalidname", "inputStateUserName", "userNameStateInfo", callback, optional);
    };

    olAuth.onChangeInputTeamName = function(value, callback, optional) {
        if (callback === undefined) callback = olAuth.callbackValidatePostRegisterInputPage2;
        if (optional === undefined) optional = true;
        onChangeValidateInput(value, "teamname", "/postregister/isvalidteamname", "inputStateTeamName", "teamNameStateInfo", callback, optional);
    };

    olAuth.onChangeInputFirstName = function(value, callback, optional) {
        if (callback === undefined) callback = olAuth.callbackValidatePostRegisterInputPage1;
        if (optional === undefined) optional = true;
        if (value.length == 0) $("#firstNameStateInfo").hide();
        onChangeValidateInput(value, "name", "/postregister/isvalidfirstorlastname", "inputStateFirstName", "firstNameStateInfo", callback, optional);
    };

    olAuth.onChangeInputFirstNameSponsor = function(value) {
        olAuth.onChangeInputFirstName(value, olAuth.callbackValidateSponsorPostRegisterInputPage, false);
    };

    olAuth.onChangeInputLastName = function(value, callback, optional) {
        if (callback === undefined) callback = olAuth.callbackValidatePostRegisterInputPage1;
        if (optional === undefined) optional = true;
        if (value.length == 0) $("#lastNameStateInfo").hide();
        onChangeValidateInput(value, "name", "/postregister/isvalidfirstorlastname", "inputStateLastName", "lastNameStateInfo", callback, optional);
    };

    olAuth.onChangeInputLastNameSponsor = function(value) {
        olAuth.onChangeInputLastName(value, olAuth.callbackValidateSponsorPostRegisterInputPage, false);
    };

    olAuth.onChangeInputCompanyName = function(value, callback, optional) {
        if (callback === undefined) callback = olAuth.callbackValidateSponsorPostRegisterInputPage;
        if (optional === undefined) optional = false;
        onChangeValidateInput(value, "companyName", "/postregister/isvalidcompanyname", "inputStateCompanyName", "companyNameStateInfo", callback, optional);
    };
    
    olAuth.onChangeInputCompanyNameSponsor = function(value) {
        olAuth.onChangeInputCompanyName(value, olAuth.callbackValidateSponsorPostRegisterInputPage, false);
    }
    
    olAuth.initSponsorPostRegisterInputPage = function() {
        olAuth.callbackValidateSponsorPostRegisterInputPage();
        $('#inputCompanyName').change();
        $('#inputFirstName').change();
        $('#inputLastName').change();
    };

    olAuth.onClickNextBtn1 = function() {
        $('#page1').hide();
        $('#page2').show();

        $('#lineStep1-2').addClass('active');
        $('#regStep1').addClass('done');
        setTimeout(function() {
            $('#regStep2').addClass('active');
        }, 500);
        $('.ol-register-back-button ').removeClass('hidden');
        olGUI.doubleButton();
    };

    olAuth.onClickBackBtn = function() {
        $('#page2').hide();
        $('#page1').show();

        $('#regStep1').removeClass('done');
        $('#regStep2').removeClass('active');
        $('#lineStep1-2').removeClass('active');
        $('.ol-register-back-button ').addClass('hidden');
    };

    olAuth.registerDoubleToggle = function(toggle) {
        var $colors = $('#teamColorParent');
        var $badge = $('#teamBadgeParent');

        if(toggle == 'colors') {
            $colors.removeClass('hidden');
            $badge.addClass('hidden');
        } else if (toggle == 'badge') {
            $colors.addClass('hidden');
            $badge.removeClass('hidden');
        }
    }

    olAuth.colorSelection = function(elem, target) {
        var $colorCatWrap = $('.colorCategoryWrapperPop');
        $('.ol-team-color-popup').addClass('active');
        $('#colorCategorySelectorPop').attr('data-active', target);
        $colorCatWrap.show();
        $colorCatWrap.attr('data-target', target);
        var cat = $(elem).attr('data-cat');
        var index = $(elem).attr('data-color-index');

        $('#headline-primary, #headline-secondary').hide();
        $colorCatWrap.find('.ol-color-selector-category').each(function(i, el) {
            $(el).hide();
            $(el).find('.ol-register-form-colorgrid-block.active').removeClass('active');
        });

        $colorCatWrap.find('#colorCategory'+cat).show();
        $colorCatWrap.find('#colorCategory'+cat).find('[data-color-id="'+index+'"]').addClass('active');
        $('#colorCategorySelectorPop .ol-register-form-colorgrid-block-categorie').each(function(i, ele) {
            $(ele).removeClass('active');
        });

        $('#colorCategorySelectorPop .color-cat-'+cat).addClass('active');
        $('#headline-'+target).show();
    }

    olAuth.selectColor = function(elem, color) {
        var target = $('.colorCategoryWrapperPop').attr('data-target');
        var colorId = $(elem).attr('data-color-id');
        var catId = $(elem).attr('data-cat-id');
        var $main = $('.ol-team-color-selector-main');
        var $secondary = $('.ol-team-color-selector-secondary');

        if(target == 'primary') {
            $main.attr('data-color-index', colorId);
            $main.attr('data-cat', catId);
            $main.css('background-color', color);
        } else {
            $secondary.attr('data-color-index', colorId);
            $secondary.attr('data-cat', catId);
            $secondary.css('background-color', color);
        }
        $(elem).addClass('active');
        $('.ol-team-color-popup').removeClass('active');
    }
    
    olAuth.categorySelction = function(elem) {
        var catId = $(elem).attr('data-cat');

        $('.colorCategoryWrapperPop .ol-color-selector-category').each(function(i, el) {
            $(el).hide();
        });

        $('#colorCategorySelectorPop .ol-register-form-colorgrid-block-categorie').each(function(i, ele) {
            $(ele).removeClass('active');
        })

        $(elem).addClass('active');
        $('.colorCategoryWrapperPop #colorCategory'+catId).show();
    }

    olAuth.onClickBtnSave = function() {
    };

    function onChangeValidateInput(value, valuename, url, inputStateSymbol, inputStateInfo, callback, optional) {
        var payload = {}
        payload[valuename] = value;

        if (optional && value.length == 0) {
            updateState($("#" + inputStateSymbol), true);
            $("#" + inputStateInfo).css('color', 'green');
            bPostRegister[inputStateSymbol] = true;
            callback();
            return;
        }

        $.getJSON(url, payload, function(data) {
            if (data['valid']) {
                updateState($("#" + inputStateSymbol), true);
                $("#" + inputStateInfo).css('color', 'green');
                bPostRegister[inputStateSymbol] = true;
                $("#" + inputStateInfo).html('');
            } else {
                updateState($("#" + inputStateSymbol), false);
                $("#" + inputStateInfo).css('color', 'red');
                bPostRegister[inputStateSymbol] = false;
                $("#" + inputStateInfo).html(data['error']);
            }
            callback();
        });
    }

    var _updatePostRegistrationTeam = function() {
        $.getJSON('/postregister/state', function(data) {
            if (data['team'] === 'yes') {
                $('#postRegisterTeamContainer').addClass('teamReady');
                $('#btnSave').removeAttr('disabled');
                $('#btnSave').removeClass('disabled');
                $('#btnSave').addClass('ol-state-primary-color-' + userStateId);
            }

            if (data['league'] !== 'no') {
                $('#postRegisterTeamContainer').addClass('leagueReady');
                $('#leagueName').html(Lang.trans('js/user.nthOnlineleagueName', { level: data['league']['level'], name: data['league']['name'] }));
            }

            if (data['team'] !== 'yes' || data['league'] === 'no') {
                clearTimeout(postregisterTeamTimer);
                setTimeout(_updatePostRegistrationTeam, 3000);
            }
        });
    };

    olAuth.initPostRegistrationTeamForm = function(stateId) {
        clearTimeout(postregisterTeamTimer);
        postregisterTeamTimer = setTimeout(_updatePostRegistrationTeam, 10000);
        userStateId = stateId;
        olAuth.favClubTypeahead();
        if (olAppInfo.APP_MODE) {
            olMobileApp.loadStop(); 
        }

    };


    olAuth.favClubTypeahead = function() {
        clubs = $.parseJSON($("#inputFavClub").attr("data-source"));
        $('#inputFavClub').typeahead({
            updater: function(item) {
                updateState($("#inputStateFavClub"), true);
                bPostRegister['inputStateFavClub'] = true;
                $("#hiddenInputFavClubId").val($(item).attr("id"));
                return item;
            },
            items: 100,
            minLength: 0,
            source: clubs
        });
    };

    olAuth.initPostRegistrationForm = function(stateId) {
        userStateId = stateId;
        $('.ol-overlay-window-content').css('min-height', '600px');
        if (olAppInfo.APP_MODE) {
            olMobileApp.loadStop();
        }

        $('#inputLocation').typeahead({
            matcher : function(item) { return true },
            updater : function(item) { selectLocationOnChange($(item).attr("id"), $(item).attr("stateId")); return item; },
            limit: 10,
            items : 100,
            minLength : 0}); 
    };

    olAuth.onClickResendConfirmationMail = function(login) {
        olMsgBox.postLoad('/register/resendconfirmation/post', { 'login' : login });
    };

    olAuth.onClickResetPasswordMail = function() {
        if (!validateEmail($('#inputUsername').val())) {
            olMsgBox.msgById('wrongEmail');
        } else {
            olMsgBox.question(Lang.trans('js/user.forgetPwd'), Lang.trans('js/user.forgetPwdHint'), function () {
                olMsgBox.postLoad('/password/email', {email: $('#inputUsername').val()});
            });
        }
    };

    //olAuth.onClickAcceptComprehensiveLeague = function() {
    //    if ($('#buttonSelectLeague').hasClass('disabled')) return;
    //
    //    var selectedElement = $('.league-select-focus.selected');
    //    if (selectedElement.length == 0) return;
    //    var parent = selectedElement.parent('.ol-league-selection');
    //
    //    var data = {
    //        acceptComprehensiveLeague: (parent.attr('data-leagueid') == -1 ? true : false)
    //    };
    //
    //    olMsgBox.postLoad('/comprehensiveleague', data, function() {
    //        window.location.href = "/";
    //        olOverlayWindow.removeLoadingAnimation();
    //        olOverlayWindow.close();
    //    });
    //};
    //
    //olAuth.onClickJoinLeague = function() {
    //    if ($('#buttonSelectLeague').hasClass('disabled')) return;
    //     
    //    var selectedElement = $('.league-select-focus.selected');
    //    if (selectedElement.length == 0) return;
    //     
    //    var parent = selectedElement.parent('.ol-league-selection');
    //    var data = {
    //        leagueId: parent.attr('data-leagueid'),
    //        offerIndex: parent.attr('data-offer-index')
    //    };
    //     
    //    olOverlayWindow.addLoadingAnimation();
    //    olMsgBox.postLoad('/selectleague', data, function() {
    //        olOverlayWindow.removeLoadingAnimation();
    //         
    //        var _response = olMsgBox.getCurrentJSON();
    //        if (_response.check === 'failed') {
    //            $('.league-select-focus.selected').removeClass('selected');
    //            $('.ol-league-selection[data-leagueid=' + data.leagueId + ']').addClass('disabled');
    //            $('#buttonSelectLeague').addClass('disabled');
    //        } else {
    //            window.location.href = "/";
    //            //window.location.reload();
    //        }
    //         
    //    });
    //};


    function submit2fa() {
        var data = $('#2faForm').serializeArray()

        $('#btn2fa').addClass('ol-button-loading');
        $('.twofa-error-hint').removeClass('has-error');
        $.post('/2fa', data, function (result) {
            if (result.success) {
                location.href="/";
            } else {
                $('#btn2fa').removeClass('ol-button-loading');
                $('.twofa-error-hint').addClass('has-error');
            }
        }).fail(function (error) {
            $('#btn2fa').removeClass('ol-button-loading');
            $('.twofa-error-hint').addClass('has-error');
        });
    }

    $(document).on('click', '#btn2fa', submit2fa);
    $(document).on('keydown', 'input#2fa', function(ev) {
        if (ev.keyCode == 13) { // enter
            submit2fa()
        }
    });


    olAuth.onClickPreregisterButton = function() {
        var data = $('#preRegForm').serializeArray();
        var closeOverlay = function() {
            olOverlayWindow.close();
        };
        $.get('/prereg/add', data, function(result) {
            if (result && result.err) {
                olMsgBox.msg(Lang.trans('js/user.register'), result.err, closeOverlay);
            } else {
                olMsgBox.msg(Lang.trans('js/user.register'), Lang.trans('js/user.success'), closeOverlay);
            }
        }).fail(function(error) {
            olMsgBox.msg(Lang.trans('js/user.register'), Lang.trans('js/team.error'), closeOverlay);
        });
    }

}(window.olAuth = window.olAuth || {}, jQuery));