(function(olManual, $, undefined ) {

    var scrollTop;

    olMobileApp.listen('openManual', function() {
        if (event.data.method == 'openManual') {
            olManual.open('_auto');
        }
    });
    
    olManual.open = function(page) {
        // Open absolute links in same window
        if (page.match(/^https?:\/\//)) {
            location.href = page;
            return;
        }

        olMobileApp.hideBottomNav();
        olMobileApp.hideHeader();
        
        // relative links are within manual (as before)
        addLoadingAnimationToElement($('#wikioverlay'));
        scrollTop = $(document).scrollTop();

        if (page == "_auto") {
            var activeNav = $('.ol-nav-underline');
            var help = activeNav.data('manual');

            if (help) {
                page = "/manual/"+help;
            } else {
                page = "/manual/index";
            }
        }

        $('#wikioverlay').load(page, function()
        {
            var hashIndex = page.indexOf('#');
            $('#wikioverlay').fadeIn();
            removeLoadingAnimationFromElement($('#wikioverlay'));
            if(hashIndex >= 0)
            {
                var hash = page.substr(hashIndex + 1);
                //$('#wikioverlayscrollbar').scrollTop($('#wikioverlay [name="' + hash + '"]').offset().top + 100);
                var $o = $('#wikioverlay [name="'+hash+'"]'), o = $o[0]; 
                if (o) {
                    o.before();
                    $(o).parent().nextAll('.ol-wiki-subheadline:first').before('<div id="ax-outstream" class="ax-container ax-container-ajax" data-loaded="false" data-fallback="{{ $fallback }}"></div>');
                    o.scrollIntoView();
                    setTimeout(function() {
                        $('#wikioverlayscrollbar').scrollTop(Math.max(0, o.offsetTop-100));
                    }, 700);
                } else {
                    o = $('.ol-wiki-mainpoint').first();
                    $(o).nextAll('.ol-wiki-subheadline:first').before('<div id="ax-outstream" class="ax-container ax-container-ajax" data-loaded="false" data-fallback="{{ $fallback }}"></div>');
                }
            }
            
            $('body').css('position', 'fixed');
            $('body').css('overflow-y', 'scroll');
        });
    };
    
    olManual.close = function() {
        olMobileApp.showBottomNav();
        olMobileApp.showHeader();

        removeLoadingAnimationFromElement($('#wikioverlay'));
        $('#wikioverlay').fadeOut();
        $('body').css('overflow', 'visible');
        $('body').css('position', 'relative');
        $(document).scrollTop(scrollTop);
    }

}( window.olManual = window.olManual || {}, jQuery )); 