(function(olPlayerContract, $, undefined) {

    olPlayerContract.init = function() {
        function onSortPlayerOrder(event, ui) {
        }
        
        olPlayerContract.onClickBtnAcceptContractTerminationCounterProposal = function(contractId, negotiationId) {
            var data = {
                negotiationId: negotiationId,
                contractId: contractId
            }
            addLoadingAnimationToElement($("#ol-root"));
            $("#offerContractExtension").remove();
            $("#ol-root").getLoad("/office/contracts/termination/accept", data, function() {
                removeLoadingAnimationFromElement($("#ol-root")); 
            });
        };
        
        olPlayerContract.onClickBtnPlayerContractExtensionReset = function(playerId) {
            var data = {
                playerId : playerId
            }
            addLoadingAnimationToElement($("#ol-root"));
            $("#offerContractExtension").remove();
            $("#ol-root").getLoad("/office/contracts/extension/offer/reset", data, function() {
                removeLoadingAnimationFromElement($("#ol-root")); 
            });
        };
        
        olPlayerContract.onClickBtnPlayerContractTerminationCounterProposal = function(contractId, playerId) { 
            var data = {
                requirement: $("#inputRequirement").val(), 
                contractId: contractId,
                playerId: playerId,
                debug: $("#debugValue").val()
            }
            
            addLoadingAnimationToElement($("#ol-root"));
            $("#contractNegotiation").getLoad("/office/contracts/termination/offer", data, function() {
                removeLoadingAnimationFromElement($("#ol-root")); 
            });
        };
        
        olPlayerContract.onClickBtnAcceptCounterProposal = function(negotiationId, playerId) {
            var data = {
                negotiationId: negotiationId,
                playerId: playerId
            }
            
            addLoadingAnimationToElement($("#ol-root"));
            
            $("#ol-root").getLoad("/office/contracts/extension/acceptcounterproposal", data, function() {
                removeLoadingAnimationFromElement($("#ol-root")); 
            });
        };
        
        olPlayerContract.onClickBtnPlayerContractExtension = function(playerId) {
            var data = {
                duration : $("#inputDuration").val(),
                salary : $("#inputSalary").val(),
                playerId : playerId
            }
            
            if ($(optAutoContractExtension).prop("checked")) {
                data.optMatchdays = $("#inputDuration").val(),
                data.optYears = $("input[name='optYears']:checked").val(),
                data.optAdditionalYears = $("#inputOptAdditionalYears").val()
            }
            
            addLoadingAnimationToElement($("#ol-root"));
            $("#offerContractExtension").remove();
            $("#contractNegotiation").getLoad("/office/contracts/extension/offer", data, function() {
                removeLoadingAnimationFromElement($("#ol-root")); 
            });
        };
        
        olPlayerContract.onClickBtnPlayerContractCounterProposal = function(playerId) {
            var data = {
                duration: $("#inputDuration").val(),
                salary: $("#inputSalary").val(),
                playerId: playerId
            }
            
            if ($(optAutoContractExtension).prop("checked")) {
                data.optMatchdays = $("#inputDuration").val();
                data.optYears = $("input[name='optYears']:checked").val();
                data.optAdditionalYears = $("#inputOptAdditionalYears").val();
            }
            
            addLoadingAnimationToElement($("#ol-root"));
            
            $("#contractNegotiation").getLoad("/office/contracts/extension/counterproposal", data, function() {
                removeLoadingAnimationFromElement($("#ol-root")); 
            });
        };
    };
    
}(window.olPlayerContract = window.olPlayerContract || {}, jQuery));