"use strict";
(function(olTeamOverview, $, undefined) {

    var imageBaseWidth = 1400;
    if (olConfig !== undefined) imageBaseWidth = olConfig.contentWidth;
    var imageBaseHeight = 308;
    // TODO: Turn this into a proper class.
    var callback = false;
    olTeamOverview.init = function(config) {
        if (config.loadingHeaderElement != undefined) {
            addLoadingAnimationToElement(config.loadingHeaderElement);
        }
        var canvas = config.canvasElement;
        var color = olGUI.getStatePrimaryColor(config.stateId); 
        var primaryColorArray = olGUI.convertRGBStringToArray(color);
        var darkColorFactor = 0.25;
        var darkColorArray = [parseInt(primaryColorArray[0] * darkColorFactor), parseInt(primaryColorArray[1] * darkColorFactor), parseInt(primaryColorArray[2] * darkColorFactor)];
        var primaryColor = Color.RGBtoHSL(olGUI.convertRGBStringToArray(color));
        var rendered = false;
        var visibleImageWidth, offsetLeft;
 
        console.log('olTeamOverview.init wurde aufgerufen');

        var image = new Image();
        image.onload = render;
        var pattern = new Image(imageBaseWidth, imageBaseHeight);
        pattern.onload = render;
        image.crossOrigin = "Anonymous";
        pattern.crossOrigin = "Anonymous";
        pattern.src = olConfig.image_host + 'imgs/uservisuals/state_' + config.stateId + '.png';
        
        // Fallback for old config
        if (!config.visual) {
            config.visual = {type: "SIMPLE", file: config.visualName, statePatternOpacity: config.statePatternOpacity};
        }
        if (config.visual.type == "SIMPLE") {
            image.src = olConfig.image_host + 'imgs/uservisuals/' + config.visual.file;
        } else if (config.visual.type == "CUSTOM") {
            image.src = olConfig.s3_host + config.visual.file;
        }

        
        function _onResize() {
            if (config.loadingHeaderElement != undefined) {
                addLoadingAnimationToElement(config.loadingHeaderElement);
            }
            setTimeout(function() {
                try {
                    rendered = false;
                    render();
                } catch (exception) {
                    console.error("Exception on Resize:", exception);
                } finally {
                    removeLoadingAnimationFromElement(config.loadingHeaderElement);
                }
            }, 1);
        }

        $(window).off("resize", _onResize);
        $(window).resize(_onResize);

        var patternRepeat = new Image(700, imageBaseHeight);
        patternRepeat.crossOrigin = "Anonymous";
        patternRepeat.src = olConfig.image_host + 'imgs/uservisuals/state_' + config.stateId + '_small.png';
        patternRepeat.onload = render;

        function imageReady(image) {
            if (!image.complete) {
                return false;
            }

            if (typeof image.naturalWidth !== "undefined" && image.naturalWidth === 0) {
                return false;
            }

            return true;
        }
        
        function resolveColor(colorName) {
            switch (colorName.toLowerCase()) {
                case "state":
                    return olGUI.getStatePrimaryColor(config.stateId);
                case "secondary":
                    return olCurrentTeamColors[1];
                case "primary":
                    return olCurrentTeamColors[0];
                case "white":
                    return "rgb(255,255,255)";
                default:
                    return "rgb(0,0,0)";
            }
        }

        function render() {
            if (!rendered && imageReady(image) && imageReady(pattern) && imageReady(patternRepeat)) {
                var parentsWidth = (config.fixedWidth != undefined) ? config.fixedWidth : $(canvas).parent().outerWidth();
                var parentsHeight = (config.fixedHeight != undefined) ? config.fixedHeight : $(canvas).parent().outerHeight();
                if ((parentsWidth < 1) || (parentsHeight < 1)) {
                    console.log("olTeamOverview: can not render, invalid size: " + parentsWidth + "x" + parentsHeight);
                    return;
                }
                console.log("olTeamOverview: render: " + parentsWidth + "x" + parentsHeight);
                rendered = true;
                canvas.width = parentsWidth;
                canvas.height = parentsHeight;
                size = Math.min(1.0, parentsHeight / imageBaseHeight);

                visibleImageWidth = parentsWidth > (imageBaseWidth * size) ? (imageBaseWidth * size) : parentsWidth;
                
                offsetLeft = parseInt((parentsWidth - imageBaseWidth * size) * 0.5);
                
                // for simple images draw and colorize them
                // don't colorize custom user images but scale them according to saved data
                var coloredCanvas;
                if (config.visual.type == "SIMPLE") {
                    coloredCanvas = _colorize(image);
                } else {
                    coloredCanvas = _drawCustom(image);
                }
                
                
                var ctx = canvas.getContext("2d");
                ctx.globalAlpha = 1.0;
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                // create backing canvas
                var gradientCanvas = document.createElement('canvas');
                gradientCanvas.width = canvas.width;
                gradientCanvas.height = canvas.height;
                var gradientContext = gradientCanvas.getContext('2d');


                var resizeGradientCanvas = document.createElement('canvas');
                resizeGradientCanvas.width = 800 * size;
                resizeGradientCanvas.height = imageBaseHeight * size;
                var resizeGradientContext = resizeGradientCanvas.getContext('2d');
                
                // Draw pattern if it's active, otherwise print a flat color
                // Always draw pattern for preset visuals
                // Never draw pattern on mobile phones
                
                
                if ((config.visual.type == "SIMPLE" || config.visual.decoration.pattern)) {     
                    resizeGradientContext.drawImage(patternRepeat, 0, 0, 800 * size, imageBaseHeight * size);
                    
                    if (config.visual.type == "CUSTOM" && resolveColor(config.visual.decoration.colorName) == "rgb(255,255,255)") {
                        resizeGradientContext.globalCompositeOperation='difference';
                        resizeGradientContext.fillStyle='white';
                        resizeGradientContext.fillRect(0,0,canvas.width,canvas.height);
                    }
                } else {
                    resizeGradientContext.fillStyle = resolveColor(config.visual.decoration.colorName);
                    resizeGradientContext.fillRect(0,0, 800 * size, imageBaseHeight * size)
                }
                
                if (!$("body").hasClass('ol-xs')) {
                    var pat = gradientContext.createPattern(resizeGradientCanvas, "repeat");
                    var patternOffset = Math.abs(offsetLeft) - 800 * size;
                    gradientContext.translate(offsetLeft, 0);
                    gradientContext.rect(patternOffset, 0, canvas.width + (800 * size - offsetLeft), canvas.height);
                    gradientContext.fillStyle = pat;
                    gradientContext.fill();
                    gradientContext.translate(-offsetLeft, 0);

                    gradientContext.globalCompositeOperation = "destination-out";
                    var gradient = gradientContext.createLinearGradient((offsetLeft < 0 ? 0 : offsetLeft), 0, (offsetLeft < 0 ? 0 : offsetLeft) + visibleImageWidth, 0);
                    gradient.addColorStop(0, "rgba(255, 255, 255, 0.0)");
                    gradient.addColorStop(0.1, "rgba(255, 255, 255, " + (0.5) + ")");
                    gradient.addColorStop(0.25, "rgba(255, 255, 255, " + (1.0) + ")");
                    gradient.addColorStop(0.75, "rgba(255, 255, 255, " + (1.0) + ")");
                    gradient.addColorStop(0.9, "rgba(255, 255, 255, " + (0.5) + ")");
                    gradient.addColorStop(1.0, "rgba(255, 255, 255, 0.0)");

                    gradientContext.fillStyle = gradient;
                    gradientContext.fillRect((offsetLeft < 0 ? 0 : offsetLeft), 0, (offsetLeft < 0 ? 0 : offsetLeft) + visibleImageWidth, canvas.height);
                }


                // draw to final canvas

                ctx.globalCompositeOperation = "source-over";

                if (config.visual.type == "CUSTOM") {
                    primaryColorArray = olGUI.convertRGBStringToArray(resolveColor(config.visual.decoration.colorName));
                    var darkColorFactor = 1;
                    darkColorArray = [parseInt(primaryColorArray[0] * darkColorFactor), parseInt(primaryColorArray[1] * darkColorFactor), parseInt(primaryColorArray[2] * darkColorFactor)];
                    
                    gradient = ctx.createLinearGradient((offsetLeft < 0 ? 0 : offsetLeft), 0, (offsetLeft < 0 ? 0 : offsetLeft) + visibleImageWidth, 0);
                    
                    // Using this guy's algorithm to get a smoother gradient edge
                    // https://stackoverflow.com/questions/30196043/how-to-display-a-smoother-gradient-in-html5-canvas
                    function easeInOut(t) {return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1}
                    for(var t = 0; t <= 1; t += 0.02) {    // convert linear t to "easing" t:
                        gradient.addColorStop(t*0.2, "rgba(" + darkColorArray[0] + ", " + darkColorArray[1] + ", " + darkColorArray[2] + "," + (1-easeInOut(t)) + ")");
                    }
                    var transparent = "rgba(0,0,0,0)";
                    gradient.addColorStop(0.2, transparent);
                    gradient.addColorStop(0.8, transparent);

                    for(var t = 0; t <= 1; t += 0.02) {    // convert linear t to "easing" t:
                        gradient.addColorStop(0.8+t*0.2, "rgba(" + darkColorArray[0] + ", " + darkColorArray[1] + ", " + darkColorArray[2] + "," + easeInOut(t) + ")");
                    }
                    
                    ctx.fillStyle = "rgba(" + darkColorArray[0] + ", " + darkColorArray[1] + ", " + darkColorArray[2] + ", 1)";
                    ctx.fillRect(0,0, offsetLeft, canvas.height);
                    ctx.fillRect(canvas.width-offsetLeft, 0, canvas.width, canvas.height);
                    
                    ctx.drawImage(coloredCanvas, 0, 0);
                    
                    ctx.fillStyle = gradient;
                    
                    if(!$("body").hasClass('ol-xs'))
                        ctx.fillRect(0, 0, canvas.width, canvas.height);
            
                    ctx.globalAlpha = (0.25);
                    ctx.drawImage(gradientCanvas, 0, 0);
                    
                } else {
                    ctx.fillStyle = "rgb(" + darkColorArray[0] + "," + darkColorArray[1] + "," + darkColorArray[2] + ")";
                    ctx.fillRect(0, 0, canvas.width, canvas.height);

                    ctx.drawImage(coloredCanvas, 0, 0);
                    var gradientColor1 = "rgba(" + darkColorArray[0] + ", " + darkColorArray[1] + ", " + darkColorArray[2] + ", 1)";
                    var gradientColor2 = "rgba(" + darkColorArray[0] + ", " + darkColorArray[1] + ", " + darkColorArray[2] + ", 0.0)";

                    gradient = ctx.createLinearGradient((offsetLeft < 0 ? 0 : offsetLeft), 0, (offsetLeft < 0 ? 0 : offsetLeft) + visibleImageWidth, 0);
                    gradient.addColorStop(0, gradientColor1);
                    gradient.addColorStop(0.1, gradientColor2);
                    gradient.addColorStop(0.9, gradientColor2);
                    gradient.addColorStop(1.0, gradientColor1);
                    
                    ctx.fillStyle = gradient;

                    if(!$("body").hasClass('ol-xs'))
                        ctx.fillRect((offsetLeft < 0 ? 0 : offsetLeft), 0, (offsetLeft < 0 ? 0 : offsetLeft) + visibleImageWidth, canvas.height);

                    ctx.globalAlpha = config.visual.statePatternOpacity ? config.visual.statePatternOpacity : 0.15;
                    ctx.drawImage(gradientCanvas, 0, 0);
                }
                


                if (config.loadingHeaderElement != undefined) {
                    removeLoadingAnimationFromElement(config.loadingHeaderElement);
                }
                
                if (typeof config.callback == "function") {
                    config.callback();
                }
            }
        }
        
        function _drawCustom(image) {   
            
            var scaledImage = document.createElement('canvas')
            scaledImage.width = canvas.width;
            scaledImage.height = canvas.height;
            var ctx = scaledImage.getContext('2d');
            
            var zoom = config.visual.zoom;
            
            var scaledHeight = image.height * zoom;
            var scaledWidth = image.width * zoom;
            
            var startX = offsetLeft + config.visual.position[0] - 1;
            var startY = config.visual.position[1];

            if (window.innerWidth < scaledWidth * size) {
                console.log("scaledWidth", scaledWidth * size);
                startX -= (scaledWidth * size - innerWidth) / 2;
            }
            
            ctx.drawImage(image, startX * size, startY * size, scaledWidth * size, scaledHeight * size);
            
            ctx.clearRect(0,0,offsetLeft,scaledImage.height); 
            ctx.clearRect(scaledImage.width-offsetLeft,0,scaledImage.width,scaledImage.height);
            
            return scaledImage;
        }

        function _colorize(image) {
            var coloredCanvas = document.createElement('canvas');
            coloredCanvas.width = canvas.width;
            coloredCanvas.height = canvas.height;
            var coloredContext = coloredCanvas.getContext('2d');

            coloredContext.clearRect(0, 0, canvas.width, canvas.height);
            coloredContext.drawImage(image, (offsetLeft < 0 ? offsetLeft : 0), 0, image.width * size, image.height * size);

            var imgData = coloredContext.getImageData(0, 0, visibleImageWidth, canvas.height);
            var data = imgData.data;

            for (var i = 0; i < data.length; i += 4) {
                var red = data[i];
                var green = data[i + 1];
                var blue = data[i + 2];
                var alpha = data[i + 3];

                var imgRGB = [red, green, blue];
                var hsl = Color.RGBtoHSL(imgRGB);
                rgb = _colorizeColor(primaryColor, hsl, imgRGB);
                data[i] = rgb[0];
                data[i + 1] = rgb[1];
                data[i + 2] = rgb[2];
            }
            coloredContext.clearRect(0, 0, canvas.width, canvas.height);
            coloredContext.putImageData(imgData, (offsetLeft < 0 ? 0 : offsetLeft), 0, 0, 0, visibleImageWidth, canvas.height);
            return coloredCanvas;
        }

        function _colorizeColor(targetColorHSL, srcPixelHSL, imgRGB) {
            var lerp = srcPixelHSL[1];
            var lerpInv = (1.0 - lerp);

            var newHSL = new Array();
            newHSL[0] = targetColorHSL[0];
            newHSL[1] = targetColorHSL[1];
            newHSL[2] = Math.min(targetColorHSL[2], srcPixelHSL[2]);
            if (targetColorHSL[2] > 0.5) {
                factor = 1.0 + 0.4 * (1.0 - targetColorHSL[2]) * 2;
                newHSL[2] *= factor;
            }
            newHSL[2] = newHSL[2] * lerp + lerpInv * srcPixelHSL[2];

            var rgb = Color.HSLtoRGB(newHSL);
            return rgb;
        }

        olTeamOverview.onClickHistorySeason = function(element, userId, season, event) {
            var elementId = 'collapse' + season;
            if ($('#collapse' + season).html().length == 0) {
                loading = $(element).find("table");
                addLoadingAnimationToElement(loading, {size: "small"});

                $('#collapse' + season).getLoad('/team/overview/history/season', {userId: userId, season: season}, function() {
                    $('#collapse' + season).collapse('toggle');
                    removeLoadingAnimationFromElement(loading);
                    var numberOfChildren = $('#'+elementId + ' .collapse-row').length;
                    if (numberOfChildren >= 20) {
                        $('#'+elementId).addClass('ol-full-table');                    
                    }
                    olTeamOverview.toggleLeagueCheckbox();
                });
                event.stopPropagation();
            }
            $('#'+elementId).toggleClass('closing'); 
        };

        olTeamOverview.onClickTransferHistorySeason = function(element, userId, season, event) {
            $('.transferhistory-panel' + season).toggleClass('collapsed');
            var elementId = 'collapse' + season;
            if ($('#collapse' + season).html().length == 0) {
                loading = $(element).find('.team-history-panel-row');
                addLoadingAnimationToElement(loading, {size: "small", instant: true});

                $('#collapse' + season).getLoad('/team/overview/transferhistory/season', {userId: userId, season: season}, function() {
                    olTeamOverview.updatePlayerTransferFilter();
                    setTimeout(function() {
                        $('#collapse' + season).collapse('toggle');
                    }, 1);
                    removeLoadingAnimationFromElement(loading);
                });
                event.stopPropagation();
            } else {
                $('#collapse' + season).collapse('toggle'); 
            }
        };
        
        olTeamOverview.toggleLeagueCheckbox = function() {
            var checked = $('#historyToggle').is(':checked');
            if (!checked) {
                $('.team-overview-game-history .ol-friendly').hide();
            } else {
                $('.team-overview-game-history .ol-friendly').show();
            }
        };
        
        olTeamOverview.updatePlayerTransferFilter = function() {
            setTimeout(function() {
                var val = $('#playerTransferFilterDropdown').dropdown().value();
                switch (val) {
                    case "1": $('.ol-player-transfer-entry').hide(); $('.ol-player-transfer-bought').show(); break;
                    case "2": $('.ol-player-transfer-entry').hide(); $('.ol-player-transfer-sold').show(); break;
                    case "3": $('.ol-player-transfer-entry').show(); break;
                }
            }, 0);
        };

        olTeamOverview.loadSubPage = function(url, userId) {
            var $anchor = $('#olTeamOverviewContent');
            addLoadingAnimationToElement($anchor, {size: 'small'});
            $.get(url+'?userId='+userId, function(html) {
                $anchor.html(html);
                removeLoadingAnimationFromElement($anchor);
                // add params to url
                addUrlParams('?userId='+userId+'&subPage='+url);
            }).fail(function() {
                removeLoadingAnimationFromElement($anchor);
                console.log("***" + url + " failed");
            });
        }      
    };

}(window.olTeamOverview = window.olTeamOverview || {}, jQuery));