(function(olTeamLogoEditor, $, undefined)
{
    var fileReader = false;
    olTeamLogoEditor.create = function(targetSelector)
    {
        var self = olTeamLogoEditor;
        olTeamLogoEditor.inputEl = undefined;
        olTeamLogoEditor.target = targetSelector;

        // Find dialog/overlay
        olTeamLogoEditor.targetJq = $(olTeamLogoEditor.target);
        olTeamLogoEditor.targetEl = olTeamLogoEditor.targetJq[0];

        // Find Editor and Previews
        olTeamLogoEditor.editor = olTeamLogoEditor.targetJq.find('.editor')[0];
        olTeamLogoEditor.previews = olTeamLogoEditor.targetJq.find('.preview').get();

        // Set up SIE
        olSimpleImageEditor.create();
        olTeamLogoEditor.sie = olSimpleImageEditor;
        olTeamLogoEditor.sie.setZoomRange(1.0, 1.0); // change 0.1, 1.0
        olTeamLogoEditor.sie.setAspectRatio(1, 1);
        olTeamLogoEditor.sie.addEditor(olTeamLogoEditor.editor);
        olTeamLogoEditor.sie.setPreviewBorder(olTeamLogoEditor.sie.getEditorPreview(olTeamLogoEditor.editor), 5);
        $(olTeamLogoEditor.previews).each(function(index, preview) {
            olTeamLogoEditor.sie.addPreview(preview);
            olTeamLogoEditor.sie.setPreviewBorder(preview, 5);
        });
/*  */
        // Find scrollbar
        olTeamLogoEditor.slider = olTeamLogoEditor.targetJq.find('#slider');
        olTeamLogoEditor.slider.bootstrapSlider({ 'tooltip' : 'hide' });
        olTeamLogoEditor.slider.bootstrapSlider().on("slideStop", function(ev) {
            var range = parseInt(ev.target.dataset.sliderMax, 10) - parseInt(ev.target.dataset.sliderMin, 10);
            var newZoom = (parseInt(ev.value, 10) / range);
            self.sie.zoom((1.0 - newZoom) * 0.9 + 0.1);
        });
        olTeamLogoEditor.slider.bootstrapSlider().on("slide", function(ev) {
            var range = parseInt(ev.target.dataset.sliderMax, 10) - parseInt(ev.target.dataset.sliderMin, 10);
            var newZoom = (parseInt(ev.value, 10) / range);
            self.sie.zoom((1.0 - newZoom) * 0.9 + 0.1);
        });
        olTeamLogoEditor.slider.trigger("change");
/* */
        // Find confirm and cancel
        olTeamLogoEditor.buttons = {
            'yes': olTeamLogoEditor.targetJq.find('#yes'),
            'no': olTeamLogoEditor.targetJq.find('#no'),
        }
        olTeamLogoEditor.buttons.yes.on("click", function(ev) {
            //self._upload();
            _uploadCheck();
           
        });
        olTeamLogoEditor.buttons.no.on("click", function(ev) {
            _hideEditor();
        });

        // Initialize
        _hideEditor();
    };
    
    olTeamLogoEditor.resetImageZoomAndPosition = function()
    {
        olTeamLogoEditor.sie.zoom(1);
        olSimpleImageEditor.transform.move[0] = 0;
        olSimpleImageEditor.transform.move[1] = 0;
        olTeamLogoEditor.sie.update();
        olTeamLogoEditor.slider.bootstrapSlider('setValue', 0);
    };
    
    olTeamLogoEditor.show = function(closeOverlayOnCancel) {
        if(typeof closeOverlayOnCancel === typeof undefined)
            closeOverlayOnCancel = true;
        var self = olTeamLogoEditor;
        olMsgBox.questionById(
            'logoUploadHint',
            function() {
                _selectFile();
            },
            function() {
                if(closeOverlayOnCancel)
                    olOverlayWindow.close();
            }
        );
    };
    
    var fileDialogIsClosed = false;
    var fileDialogClosedHandle = false;
    function _selectFile() {
        fileDialogIsClosed = false;
        olTeamLogoEditor = olTeamLogoEditor;
        var self = olTeamLogoEditor;
        olTeamLogoEditor.inputEl = document.createElement('input');
        olTeamLogoEditor.inputEl.style.display = 'none';
        olTeamLogoEditor.inputEl.type = 'file';
        olTeamLogoEditor.inputEl.setAttribute("id", "fileInput");
        olTeamLogoEditor.inputEl.accept = 'image/*';
        olTeamLogoEditor.inputEl.onchange = function(ev) {
            fileDialogIsClosed = true;
            $('.ol-root').addLoadingAnimation();
            _onFileSelected(ev);
        };
        olTeamLogoEditor.inputEl.click();
        
        document.body.onfocus = function()
        {
            document.body.onfocus = null;
            setTimeout(function()
            {
                if(!fileDialogIsClosed)
                    olOverlayWindow.close();
            }, 300);
        };
        
    }

    function _onFileSelected(event) {
        olTeamLogoEditor = olTeamLogoEditor;
        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        var reader = new FileReader(event);

        
        
        var input = event.target;

        fileReader = new FileReader();
        fileReader.onload = function()
        {
            var result = fileReader.result;
            console.log("Got File:", result);
            _verifyFile(result, function(r)
            {
                console.log("Verified File:", r)
                if(r)
                {
                    var urlCreator = window.URL || window.webkitURL;
                    // Load image file
                    olTeamLogoEditor.sie.load(result, function()
                    {
                        console.log("Loaded Editor")
                        olTeamLogoEditor.sie.position(0, 0);
                        olTeamLogoEditor.sie.zoom(1.0);
                        olTeamLogoEditor.sie.update();
                        _showEditor();
                    });
                }
            });
        };
        fileReader.readAsDataURL(input.files[0]);
        /*
        var path = event.path || (event.composedPath && event.composedPath());
        var file = path[0].files[0];
        */



        // call overlay
        //renderToOverlay(file);

        // This should fire off an XHR request to upload the content and somehow show a forced dialog to wait?
        // TODO: Move olTeamLogoEditor after editing.
    }

    function _verifyFile(file, callback) {
        //if (file.size > 2 * 1024 * 1024) {
        if (file.size > 150 * 1024) {
            return false;
        }
        
        
        var urlCreator = window.URL || window.webkitURL;
        var img = new Image();

        img.onload = function(){
            if ((img.width > 300) || (img.height > 300)) {
                callback(false);
            }
            $('.ol-team-logo-editor').toggle();
            callback(true);
        };
        img.src = file;
    }

    function _showInvalidFileDialog() {
        var self = olTeamLogoEditor;
        olMsgBox.questionById(
                'logoInvalidFile',
                function() {
            window.setTimeout(function() {
                self.show()
            },
                    100);
        },
                function() {
        }
        );
    }

    function _showEditor() {
        olMobileApp.hideHeader();
        console.log("Showing Editor", olTeamLogoEditor.targetEl);
        olTeamLogoEditor.targetEl.style.display = "block";
        console.log('doing the thing with the overlay window');
        $('#overlayWindow').addClass('open').removeClass('shrink');
        $('.ol-root').removeLoadingAnimation();
    }

    function _hideEditor() {
        olMobileApp.showHeader();
        olTeamLogoEditor.targetEl.style.display = "none";
    }


    function _uploadCheck() {
        var self = olTeamLogoEditor;
        olMsgBox.questionById(
            'logoUploadCheck',
            function() {
                _upload();
            },
            function() {

            }
        );
    }
    
    function _upload() {
        var data = new FormData();
        data.append('file', olTeamLogoEditor.inputEl.files[0]);
        data.append('zoom', olTeamLogoEditor.sie.zoom());
        data.append('move', olTeamLogoEditor.sie.position());
        data.append('_method', 'POST');

        var self = olTeamLogoEditor;
        jQuery.ajax({
            'method': 'POST',
            'type': 'POST',
            'url': '/images',
            'data': data,
            'cache': false,
            'contentType': false,
            'processData': false,
            'success': function(res) {
                if (res && !res.success) {
                    _uploadError(res);
                } else {
                    _uploadSuccess(res);
                }
            },
            'error': function(ev) {
                _uploadError(ev);
            }
        });
    }

    function _uploadSuccess(res) 
    {
        
        olMsgBox.msgById('logoUploadSuccess', function() { location.reload(true); });
        /*
        $.ajax({
            type: "POST",
            url: '/team/teamLogoChange',
            dataType: 'html',
            data: {
                //for authentification
                badgeId: ev.value.id,
                badgeType: 'CUSTOM'
            },
            'success': function(ev) {
                olMsgBox.msg("Hinweis", "Wappen wurde aktualisiert! Zur Aktualisierung der Ansicht wird die Webseite neu geladen.",location.reload(true));
            olOverlayWindow.close();
            },
            'error': function(ev) {
                olMsgBox.msg("Hinweis", "Wappen Speicherung fehlgeschlagen!");
            }
        });
        */
        
        
        
        //olTeamLogoEditor._hideEditor();
         
        
        
        // TODO: Send XHR request to update logo.
        // TODO: Then reload once done.
    }

    function _uploadError(res) {
        if (res.errorTitle) {
            olMsgBox.msg(res.errorTitle, res.errorText);
        } else {
            olMsgBox.msgById('logoUploadFailed');
        }
        // TODO: Do something here.
    }
    
}(window.olTeamLogoEditor = window.olTeamLogoEditor || {}, jQuery));