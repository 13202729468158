(function(olFriendlyRequest, $, undefined) {
    var _offerId = -1;
    var _url;
    var _me = false;
    var _slots = false;
    var _premium = false;

    olFriendlyRequest.init = function(me, slots, premium) {
        _me = (me === 1);
        _slots = (slots === 1);
        _premium = (premium === 1);
    };

    olFriendlyRequest.onChangeWeek = function(val, season) {
        if (typeof val === typeof undefined || typeof season === typeof undefined)
            return;

        var data = parseURLParams();
        if (val === -1 && season === -1) {
            delete data['season'];
            delete data['week'];
        } else
            data = $.extend(data, {'season': season, 'week': val});

        olAnchorNavigation.load('friendlies/requests', data);
    };

    olFriendlyRequest.onChangeDirect = function(val) {
        if (typeof val === typeof undefined)
            return;

        var params = parseURLParams();
        if (val <= 0)
            delete params.user;
        else
            $.extend(params, {'user': val});

        olAnchorNavigation.load('friendlies/requests', params);
    };

    olFriendlyRequest.onChangeMe = function() {
        if (!_premium)
            return;
        _me = !_me;

        olAnchorNavigation.load('friendlies/requests', $.extend(parseURLParams(), {'me': (_me ? 1 : 0)}));
    };

    olFriendlyRequest.onChangeFreeSlots = function() {
        if (!_premium)
            return;
        _slots = !_slots;

        olAnchorNavigation.load('friendlies/requests', $.extend(parseURLParams(), {'slots': (_slots ? 1 : 0)}));
    };

    var _subcallid1 = 0;
    olFriendlyRequest.onChangeLeague = function(min, max) {
        setTimeout(function(callid) {
            if (_subcallid1 === callid) {
                _subcallid1 = 0;
                olAnchorNavigation.load('friendlies/requests', $.extend(parseURLParams(), {"league[min]": min, "league[max]": max}));
            }
        }, 500, ++_subcallid1);
    };

    var _subcallid2 = 0;
    olFriendlyRequest.onChangeTime = function(min, max) {
        setTimeout(function(callid) {
            if (_subcallid2 === callid) {
                _subcallid2 = 0;
                olAnchorNavigation.load('friendlies/requests', $.extend(parseURLParams(), {"time[min]" : min, "time[max]": max}));
            }
        }, 500, ++_subcallid2);
    };

    olFriendlyRequest.details = function(offerId, forwardUrl) {
        _offerId = offerId;
        _url = forwardUrl;
        olOverlayWindow.load('/friendlies/request/details', {'id': offerId}, false, olFriendlyRequest.accept, olFriendlyRequest.close);
    };

    olFriendlyRequest.accept = function() {
        if (_offerId < 0)
            return;

        if (typeof _url !== typeof '')
            _url = 'friendlies/requests';

        $.post('/friendlies/request/accept', {
            'id': _offerId
        }, function(json) {
            if (json.success)
                olMsgBox.json(json, function() {
                    if (json.type == "question") {
                        $.post('/friendlies/request/accept', {
                            'id': _offerId,
                            'force': 1
                        }, function(json) {
                            if (json.success)
                                olMsgBox.json(json, function() {
                                    olAnchorNavigation.load(_url, parseURLParams(), undefined, true);
                                    olFriendlyRequest.close();
                                });
                            else
                                olMsgBox.json(json, olFriendlyRequest.close);
                        });
                    } else {
                        olAnchorNavigation.load(_url, parseURLParams(), undefined, true);
                        olFriendlyRequest.close();
                    }
                });
            else
                olMsgBox.json(json, olFriendlyRequest.close);
        });
    };

    olFriendlyRequest.close = function() {
        _offerId = -1;
        olOverlayWindow.close();
    };
    
    olFriendlyRequest.acceptAd = function(welectURL) {
        
        function runWelect(id)
        {
            function _showWelect(id)
            {
                Welect.checkAvailability(
                { 
                    onAvailable: function()
                    {
                        Welect.runSession({onSuccess: function() 
                        {  
                            olFriendlyRequest.accept();
                        }, onCancel: function() {}});
                    }, 
                    onUnavailable: function()
                    {
                        olFriendlyRequest.accept();
                    }
                });

            }
            Welect.checkToken({ onValid: function() { _showWelect(id); }, onInvalid: function() { _showWelect(id); } });
        }
        
        // first unload existing script
        $('#welectAd').remove();
        var script = document.createElement('script');
        script.setAttribute("id", "welectAd");
        script.onload = function() {
            olFriendlyResult.welectLoaded = true;
            runWelect();
        };
        script.src = welectURL; 

        document.head.appendChild(script); 
    };
}(window.olFriendlyRequest = window.olFriendlyRequest || {}, jQuery));
