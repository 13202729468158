(function(olFriendlyOffer, $, undefined) {
    var _active = {};
    var _season = {};
    var _week = {};
    var _league = {};
    var _startTime = {};
    var _startBy = 0;
    var _direct = 0;
    var _premium = 0;
    var _offerId = -1;

    var _loading = false;

    olFriendlyOffer.init = function(season, weeks, leagues, times, active, offer, premium, startBy, direct) {
        _season['curr'] = season.curr;
        _season['next'] = season.next;
        _week[season.curr] = {'min': weeks.curr.min, 'max': weeks.curr.max};
        _week[season.next] = {'min': weeks.next.min, 'max': weeks.next.max};
        _league['min'] = leagues.min;
        _league['max'] = leagues.max;
        _startTime['min'] = times.min;
        _startTime['max'] = times.max;
        _active[season.curr] = (active === season.curr);
        _active[season.next] = (active === season.next);
        _offerId = offer;
        _premium = (premium === 1);
        _startBy = (typeof startBy === typeof 0 ? startBy : 0);
        _direct = (typeof direct === typeof 0 ? direct : 0);
    };

    olFriendlyOffer.onChangeWeek = function(min, max, season) {
        if (!_premium)
            return;

        _week[season].min = min;
        _week[season].max = max;
    };

    olFriendlyOffer.onChangeLeague = function(min, max) {
        _league.min = min;
        _league.max = max;
    };

    olFriendlyOffer.onChangeTime = function(min, max) {
        _startTime.min = min;
        _startTime.max = max;
    };

    olFriendlyOffer.onChangeStartBy = function(val) {
        _startBy = val;
        $('#friendly-start-by').trigger('changestartby', [_startBy]);
    };

    olFriendlyOffer.onChangeDirect = function(val) {
        _direct = _premium ? val : 0;
    };

    olFriendlyOffer.create = function(season, week) {
        if (typeof season === typeof undefined)
            return;
        if (_loading)
            return;

        var data = {season: season};
        if ((typeof week !== typeof undefined) && (typeof week.max !== typeof undefined)) {
            if (typeof week.min === typeof undefined)
                week.min = week.max;
            data.week = week;
        }

        olOverlayWindow.load('/friendlies/offer/create', data, false, olFriendlyOffer.arrange, olOverlayWindow.close);
    };

    olFriendlyOffer.edit = function(offerId) {
        if (typeof offerId === typeof undefined || offerId <= 0)
            return;
        if (_loading)
            return;

        olOverlayWindow.load('/friendlies/offer/edit', {id: offerId}, false, olFriendlyOffer.rearrange, olOverlayWindow.close);
    };

    olFriendlyOffer.arrange = function() {
        if (!_active[_season.curr] && !_active[_season.next])
            return;
        if (_loading)
            return;

        _loading = true;
        var data = {
            week: {},
            leagueLevel: _league,
            startTime: _startTime,
            startBy: _startBy,
            direct: (_premium ? _direct : 0)
        };

        if (_active[_season.curr])
            data.week.curr = _week[_season.curr];
        if (_premium && _active[_season.next])
            data.week.next = _week[_season.next];

        $.post('/friendlies/offer/arrange', data, function(json) {
            if (json.success)
                olMsgBox.json(json, function() {
                    olAnchorNavigation.load('friendlies/offers', false, 'ol-root', true);
                    olOverlayWindow.close();
                });
            else
                olMsgBox.json(json);
            _loading = false;
        });
    };

    olFriendlyOffer.rearrange = function() {
        if (typeof _offerId === typeof undefined || _offerId <= 0)
            return;
        if (_loading)
            return;

        _loading = true;
        var data = {
            id: _offerId,
            leagueLevel: _league,
            startTime: _startTime,
            startBy: _startBy,
            direct: _direct
        };

        $.post('/friendlies/offer/rearrange', data, function(json) {
            if (json.success)
                olMsgBox.json(json, function() {
                    olAnchorNavigation.load('friendlies/offers', false, 'ol-root', true);
                    olOverlayWindow.close();
                });
            else
                olMsgBox.json(json);
            _loading = false;
        });
    };

    olFriendlyOffer.start = function(offerId, showFeedback) {
        if (typeof offerId === typeof undefined || offerId <= 0)
            return;
        if (typeof showFeedback !== typeof true)
            showFeedback = true;
        if (_loading)
            return;

        _loading = true;
        var target = '#olFriendlyStart-' + offerId;

        addLoadingAnimationToElement(target, {size: 'button'});
        $.post('/friendlies/offer/start', {id: offerId}, function(json) {
            removeLoadingAnimationFromElement(target);

            if (json.success)
                $(target).removeAttr('onclick').hide();

            if (showFeedback)
                olMsgBox.json(json);

            _loading = false;
        });
    };

    olFriendlyOffer.listen = function() {
        olEcho.userChannel()
                .listen('Friendlies.StartFriendly', olFriendlyOffer.status)
                .listen('Friendlies.FinishFriendly', olFriendlyOffer.status);
    };

    olFriendlyOffer.status = function(data) {
        switch (data.status) {
            case data.states.SCHEDULED:
                var $report = $('#olFriendlyReport-' + data.id);

                $('.text-matchresult', $report).text(data.text);
                $('.fa-angle-right', $report).hide();
                $('#olFriendlyStart-' + data.id).removeAttr('onclick');
                $('#olFriendlyStartGroup-' + data.id).hide();
                $('.olFriendlyDelete-' + data.id).remove();
                $report.show();

                break;
            case data.states.DONE:
                var $report = $('#olFriendlyReport-' + data.id);

                $('.text-matchresult', $report).text(data.text);
                $('.fa-angle-right', $report).show();
                $('.olFriendlyDelete-' + data.id).remove();
                if($('#friendlyResultAdOverview').length > 0)
                {
                    $('#friendlyResultAdOverview').addClass('done');
                    $('#friendlyResultAdOverview button').attr('data-match-id', data.matchId);
                }

                break;
            default:
                break;
        }
    };

    olFriendlyOffer.delete = function(offerId) {
        if (typeof offerId === typeof undefined || offerId <= 0)
            return;
        if (_loading)
            return;

        _loading = true;
        $.get('/friendlies/offer/remove', {id: offerId}, function(response) {
            _loading = false;
            olMsgBox.json(response, function() {
                if (_loading || !response.success)
                    return;

                _loading = true;
                $.post('/friendlies/offer/remove', {id: offerId}, function(response) {
                    _loading = false;
                    olMsgBox.json(response, function() {
                        olAnchorNavigation.load('friendlies/offers', false, 'ol-root', true);
                    });
                });
            });
        });
    };

    olFriendlyOffer.toggleSeason = function(target, season) {
        if (!_premium || _active[target] === false)
            return;

        if (_active[season.curr] && _active[season.next]) {
            if (target === season.curr) {
                $('#friendlyCreateWeekNext').addClass('ol-transition-hide');
                $('#friendlyCreateWeekCurr .fa').removeClass('fa-minus').addClass('fa-plus');
                _active[season.next] = false;
            } else if (target === season.next) {
                $('#friendlyCreateWeekCurr').addClass('ol-transition-hide');
                $('#friendlyCreateWeekNext .fa').removeClass('fa-minus').addClass('fa-plus');
                _active[season.curr] = false;
            }
        } else {
            if (target === season.curr) {
                _active[season.next] = true;
            } else if (target === season.next) {
                _active[season.curr] = true;
            }
            $('#friendlyCreateWeekCurr, #friendlyCreateWeekNext').removeClass('ol-transition-hide');
            $('#friendlyCreateWeekCurr .fa, #friendlyCreateWeekNext .fa').removeClass('fa-plus').addClass('fa-minus');
        }

        $('#friendlyCreateWeekCurr, #friendlyCreateWeekNext').trigger('changeseason', [_active[season.curr], _active[season.next]]);
    };

    olFriendlyOffer.reload = function(url, withParams) {
        var $anchor = $('#ol-root');
        var param = withParams ? parseURLParams() : null;
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get(url, param, function(html) {
            $anchor.html(html);
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("***" + url + " failed");
        });
    }  
}(window.olFriendlyOffer = window.olFriendlyOffer || {}, jQuery));
