(function(olFriendlyTime, $, undefined) {
    olFriendlyTime.localize = function(target, trans, replacements) {
        if (typeof target !== 'string') return;
        if (typeof trans !== 'string') return;
        if (typeof replacements !== 'object') return;

        $.each(replacements, function(key, time) {
            trans = trans.replace(':' + key, olRealtimeHelper.formatTime(new Date(time)));
        });

        $(target).text(trans);
    };
})((window.olFriendlyTime = window.olFriendlyTime || {}), jQuery);
