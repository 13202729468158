/**
 * The listener of change minute event which will update the score and raking tables
 * with data on current minute.
 */
(function(olLiveTickerMatchtableScoreListener, $, undefined) {

    var DEBUG = true; // DBG

    var matchTableScores = null;
    var matchesRankingInLeague = null;
    var goalAudio = new Audio('/sounds/match-effect-goal2.mp3'); // The policy of browser may forbid to play while user doesn't iterate with page.
    var ratingTableTbody = null;
    var ratingTableRows = null;
    var goalEvent = false;
    var goalEventTable = true;

    /**
     * Set scores values for current league and match day.
     *
     * @param data
     * @param ltParameters
     */
    olLiveTickerMatchtableScoreListener.setData = function(data) {
        matchTableScores = data.matchesScoresInLeague;
        matchesRankingInLeague = data.matchesRankingInLeague;
    
        if (DEBUG) console.log('LT Debug | matchTableScores:', matchTableScores);
        if (DEBUG) console.log('LT Debug | matchesRankingInLeague:', matchesRankingInLeague);
    };

    /**
     * Listener to minute change event.
     *
     * @param minute
     * @param usingVisualAndSoundEffects
     */
    olLiveTickerMatchtableScoreListener.minuteChangeEvent = function(minute, usingVisualAndSoundEffects) {
        olLiveTickerMatchtableScoreListener.changeScoreTable(minute, usingVisualAndSoundEffects);
        olLiveTickerMatchtableScoreListener.changeRatingTable(minute);
    }

    /**
     * Update score table for a particular minute.
     *
     * @param minute
     * @param usingVisualAndSoundEffects
     */
    olLiveTickerMatchtableScoreListener.changeScoreTable = function(minute, usingVisualAndSoundEffects) {
        if (minute >= 45) {
            $('.live-ticker-results-table').addClass('half-time');
        }
    
        // check if the next highlight will be a goal
        $('div[data-match-id]').each(function() {
            var matchId = parseInt($(this).attr('data-match-id'));
            if (matchTableScores[matchId] && matchTableScores[matchId][minute] && usingVisualAndSoundEffects) {
                goalEvent = true;
            }
        });
    
        // then remove all previous highlights if the next event is a goal
        if (goalEvent) {
                $('div[data-match-id]').removeClass('ol-row-highlight');
            goalEvent = false;
        }
    
        $('div[data-match-id]').each(function() {
            var matchId = parseInt($(this).attr('data-match-id'));
            if (matchTableScores[matchId] && matchTableScores[matchId][minute]) {
                if (usingVisualAndSoundEffects && olLiveTickerTimer.getSettings('liveTickerGoalAlarm') === 1) {
                    goalAudio.play();
                }
    
                // finally highlight the changed row(s) and add the matchIds to an array
                if (usingVisualAndSoundEffects) {
                    $(this).addClass('ol-row-highlight');
                }
    
                
                var halfScoreRow = '<span>( 0 : 0 )<span>';
                for (var i = 0;  i <= minute;  i++) {
                    if (matchTableScores[matchId] && matchTableScores[matchId][i] && i <= 45) {                        
                        halfScoreRow = '<span>( ' + matchTableScores[matchId][i][0] + ' : ' + matchTableScores[matchId][i][1] + ' )<span>';                        
                    }
                }
    
                $(this).find('div.ol-matchdaytable-result-scoreline').html(
                    '<div class="ol-matchdaytable-result-score">' + matchTableScores[matchId][minute][0] + ' : ' + matchTableScores[matchId][minute][1] + '</div>' + ' ' + halfScoreRow
                );
            }
        });
    }

    /**
     * Update rating table for a particular minute.
     *
     * @param minute
     */
    olLiveTickerMatchtableScoreListener.changeRatingTable = function changeRatingTable(minute) {
        ratingTableTbody = $('#ranking-league-table tbody');
        ratingTableRows = $('#ranking-league-table tbody tr');
    
        if (matchesRankingInLeague[minute]) {
            var loopLength = matchesRankingInLeague[minute].length;
            var changedRatingTable = '';
    
            if(goalEventTable) {
                $('tr[data-user-id]').removeClass('ol-row-highlight');
                goalEventTable = false;
            }
    
            // Loop through data from server sort by minutes and points.
            for (var i = 0;  i < loopLength;  i++) {
                var userId = parseInt(matchesRankingInLeague[minute][i]['userId']);
                var neededRow = '';
    
                // Loop through existed rows on the page.
                ratingTableRows.each(function() {
                    var trUserId = parseInt($(this).attr('data-user-id'));
                    if (trUserId === userId) {
                        $(this).find('td.rating-place').text(i + 1);
                        $(this).find('td.match-count').text(matchesRankingInLeague[minute][i]['matches']);
                        $(this).find('td.games-win').text(matchesRankingInLeague[minute][i]['win']);
                        $(this).find('td.games-draw').text(matchesRankingInLeague[minute][i]['draw']);
                        $(this).find('td.games-lost').text(matchesRankingInLeague[minute][i]['lost']);
                        $(this).find('td.scored-conceding-goals').text(
                            matchesRankingInLeague[minute][i]['scoredGoals'] + ':' +
                            matchesRankingInLeague[minute][i]['concedingGoals']);
                        $(this).find('td.diff-goals').text(matchesRankingInLeague[minute][i]['diffGoals']);
                        $(this).find('td.total-points').text(matchesRankingInLeague[minute][i]['points']);
    
                        var outerThat = this;
                        $.each(matchTableScores, function( index, match ) {
                            if (match[minute]) {
                                $.each(match['userIds'], function(jIndex, scoreUserId) {
                                    if (scoreUserId === userId) {
                                        $(outerThat).addClass('ol-row-highlight');
                                        goalEventTable = true;
                                    }
                                });
                            }
                        });
                        neededRow = this;
                    }
                });
                changedRatingTable += neededRow.outerHTML;
            }
            $(ratingTableTbody).html(changedRatingTable);
        }
    }

    /**
     * Update score and rating tables while the page was reloaded.
     * Wait while score and ranking tables will be loaded.
     *
     * @param minute
     */
    olLiveTickerMatchtableScoreListener.updateViewUntilCurrentMinute = function(minute) {
        var isTablesExisted = setInterval(function() {
            if ($('#ranking-league-table tbody tr').length && $('div[data-match-id]').length) {
                clearInterval(isTablesExisted);
                for (var i = 1; i < minute; i++) {
                    olLiveTickerMatchtableScoreListener.minuteChangeEvent(i, false);
                }
                olLiveTickerMatchtableScoreListener.changeRatingTable(minute);
            }
        }, 1000);
    };

})( window.olLiveTickerMatchtableScoreListener = window.olLiveTickerMatchtableScoreListener || {}, jQuery);
