(function(olLeagueLicence, $, undefined) {

    var _inited = false;
    //var _gainedLicenceParts = {};
    var _tutorials = {};

    function _prepareDOM() {
        console.info('OL: League-Licence-Tutorial - prepare DOM');
    }

    function _listenToPushUpdates() {
        console.info('OL: League-Licence-Tutorial - listen to push updates');
        olEcho.userChannel().listen('User.LeagueLicenceUpdate', olLeagueLicence.update);
    }

    function _tutorialSquadFilter() {
        // if (olGUI.isMobileOrTabletDevice()) {
        //     _top('275px');
        // }
        var _dataSquadFilter = true;
        if (!_dataSquadFilter) return;
        $('#squadDropdown a, #dropdownposition a').on('click', function() {
            olLeagueLicence.licencePartGained('squadFilter');
            _dataSquadFilter = false;
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
            $('#dropdownposition').addClass('ol-tutorial-highlight');
            $('#squadDropdown.ol-dropdown').addClass('ol-tutorial-highlight');
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            $('#squadDropdown.ol-dropdown').removeClass('ol-tutorial-highlight');
            $('#dropdownposition').removeClass('ol-tutorial-highlight');
        });
    }

    
    // TODO Adjust lineup parts to redesign changes
    var _lineUpSystemBtn1 = false;
    var _lineUpSystemBtn2 = false;
    function _tutorialLineupSystem() {
        if (olGUI.isMobileOrTabletDevice()) {
            _top('275px');
        }

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
            $('#dropdownSystems').addClass('ol-tutorial-highlight');
            $('#dropdownTactic').addClass('ol-tutorial-highlight');
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            $('#dropdownSystems').removeClass('ol-tutorial-highlight');
            $('#dropdownTactic').removeClass('ol-tutorial-highlight');
        });

        $('.ol-formations-overlay-wrapper .ol-formations-overlay-system-row').on('click', function() {
            _lineUpSystemBtn1 = true;
            _lineUpSystemBtn2 = true;
            $('#dropdownSystems').removeClass('ol-tutorial-highlight');
            if (_lineUpSystemBtn1 && _lineUpSystemBtn2) {
                olLeagueLicence.licencePartGained('system');
            }
        });
        // $('#dropdownTactic a').one('click', function() {
        //     _lineUpSystemBtn2 = true;
        //     $('#dropdownTactic').removeClass('ol-tutorial-highlight');
        //     if (_lineUpSystemBtn1 && _lineUpSystemBtn2) {
        //         olLeagueLicence.licencePartGained('system');
        //     }
        // });
    }

    olLeagueLicence.initLineUpSelectionListener = function() {
        $('.ol-formations-overlay-wrapper .ol-formations-overlay-system-row').on('click', function() {
            _lineUpSystemBtn1 = true;
            _lineUpSystemBtn2 = true;
            $('#dropdownSystems').removeClass('ol-tutorial-highlight');
            if (_lineUpSystemBtn1 && _lineUpSystemBtn2) {
                olLeagueLicence.licencePartGained('system');
            }
        });
    };


    function _tutorialLineUpRename() {
        if($('body').hasClass('ol-xs') && !$('#mobileLineupSettings').hasClass('shown')) {
            $('.ol-more-icon ').click();
        }
        $('.ol-team-settings-line-up-header').addClass('ol-tutorial-highlight');

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            $('.ol-team-settings-line-up-header').removeClass('ol-tutorial-highlight');
        });
    }

    function _tutorialLineupSetupFilterStriker() {
        $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
            $('#positionFilter .dropdown-toggle').addClass('ol-tutorial-highlight');
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            $('#positionFilter .dropdown-toggle').removeClass('ol-tutorial-highlight');
        });

        function _inner() {
            $('#positionFilter a').off('click.tutorialLineupSetupFilterStriker');
            $('#positionFilter a').on('click.tutorialLineupSetupFilterStriker', function() {
                if (!_dataLineupSetupFilterStriker) return;
                if ($(this).parent().attr('data-value') == 8) {
                    _dataLineupSetupFilterStriker = false;
                    olLeagueLicence.licencePartGained('lineupSetupFilterStriker');
                    $('#ol-root').off('lineupEditorChangeUserLineUp.tutorialLineupSetupFilterStriker');
                    $('#dropdownLineUps a').off('click.tutorialLineupSetupFilterStriker');
                    $('#positionFilter a').off('click.tutorialLineupSetupFilterStriker');
                }
            });
        }
        var _dataLineupSetupFilterStriker = true;

        var active = $('#dropdownLineUps').attr('data-active');
        if (active == 11 || active == 1) {
            $('#dropdownLineUps').removeClass('ol-tutorial-highlight');
        } else {
            $('#olTutorialPage1 .ol-state-color-button').addClass('disabled');
            $('#dropdownLineUps').addClass('ol-tutorial-highlight');
        }
        $('#dropdownLineUps a').on('click.tutorialLineupSetupFilterStriker', function() {
            $('#olTutorialPage1 .ol-state-color-button').addClass('disabled');
        });

        $('#ol-root').on('lineupEditorChangeUserLineUp.tutorialLineupSetupFilterStriker', function(element) {
            var active = $('#dropdownLineUps').attr('data-active');
            if (active == 11 || active == 1) {
                $('#olTutorialPage1 .ol-state-color-button').removeClass('disabled');
                $('#dropdownLineUps').removeClass('ol-tutorial-highlight');
                _inner();
            } else {
                $('#olTutorialPage1 .ol-state-color-button').addClass('disabled');
                $('#dropdownLineUps').addClass('ol-tutorial-highlight');
            }
        });
        _inner();
    }

    function _tutorialLineupSetupShootingSkill() {
        $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
            $('#dropdownPlayerAttribute').addClass('ol-tutorial-highlight');
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            $('#dropdownPlayerAttribute').removeClass('ol-tutorial-highlight');
        });

        var _dataLineupSetupShootingSkill = true;
        $('#dropdownPlayerAttribute a').click(function() {
            if (!_dataLineupSetupShootingSkill) return;
            if ($(this).parent().attr('data-value') == 20) {
                olLeagueLicence.licencePartGained('lineupSetupShootingSkill');
                _dataLineupSetupShootingSkill = false;
            }
        });
    }

    var _tutorialLineUp = {};
    function _tutorialLineupSetupSelectBest() {
        var _dataLineupSetupSelectBest = true;
        $('.ol-lineup-editor-checkbox input').click(function() {
            if (!_dataLineupSetupSelectBest) return;
            var checked = $('.ol-lineup-editor-checkbox input:checked');
            if (checked.length != 2) return;
            var highestValue = 0;
            var secondHighestValue = 0;
            $('.ol-lineup-editor-checkbox input').each(function(index, element) {
                var parent = $(element).closest('.ol-lineup-player-details-row');
                if (parent.find('.lineUpPosition').hasClass('lineUpPosition8')) {
                    var val = parseInt(parent.find('.ol-value-pie-value').text());
                    if (val >= highestValue) {
                        _tutorialLineUp.secondSelected = _tutorialLineUp.firstSelected;
                        _tutorialLineUp.firstSelected = parent;
                        secondHighestValue = highestValue;
                        highestValue = val;
                    } else if (val > secondHighestValue) {
                        _tutorialLineUp.secondSelected = parent;
                        secondHighestValue = val;
                    }
                }
            });
            var min = Math.min(parseInt(checked.first().closest('.ol-lineup-player-details-row').find('.ol-value-pie-value').text()),
                parseInt(checked.last().closest('.ol-lineup-player-details-row').find('.ol-value-pie-value').text()));
            if (min >= secondHighestValue) {
                olLeagueLicence.licencePartGained('lineupSetupSelectBest');
                _dataLineupSetupSelectBest = false;
            }
        });
    }

    function _tutorialLineupSetupShowSelected() {
        $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
            $('#settingsSelected').addClass('ol-tutorial-highlight');
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            $('#settingsSelected').removeClass('ol-tutorial-highlight');
        });

        $('#checkboxFilterPlayer').on('change', function() {
            if ($('#checkboxFilterPlayer').prop("checked") == true)
                olLeagueLicence.licencePartGained('lineupSetupShowSelected');
        });
    }

    function _tutorialLineupSetupExpand() {
        $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
            $('#maximizeButton .icon-ol-resize-icon').addClass('ol-tutorial-highlight');
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            $('#maximizeButton .icon-ol-resize-icon').removeClass('ol-tutorial-highlight');
        });

        var _dataLineupSetupExpand = true;
        $('#maximizeButton').click(function() {
            if (!_dataLineupSetupExpand) return;
            olLeagueLicence.licencePartGained('lineupSetupExpand');
            _dataLineupSetupExpand = false;
        });
    }

    function _tutorialLineupSetupRate(data) {
        olLeagueLicence.licencePartGained('lineupSetupRate');
    }

    function _tutorialLineupHelperGetPlayerAttributes(playerId) {
        // old LineUpEditor:
        // var playerElement = $('.ol-team-settings-line-up-row .ol-team-settings-player-drag-and-drop[data-player-id=' + playerId + ']').first();
        // playerElement = playerElement.closest('.ol-team-settings-line-up-row-background-wrapper');
        // var attr = playerElement.find(".ol-gui-lineup-attr");
        // return JSON.parse(attr.attr("data-player-attributes"));

        var $playerRow = $('.ol-lineup-player-details-row#playerListId'+playerId);
        $playerRow = $playerRow.find('.ol-lineup-player-data-pies > div:first-child .ol-value-pie-section')
        return JSON.parse($playerRow.attr('data-player-attributes'));
    }

    function _tutorialLineUpHelperWrongPlayerPosition(playerId) {
        var playerElement = $('.ol-team-settings-player-drag-and-drop[data-player-id=' + playerId + ']').first();
        return playerElement.hasClass('ol-pitch-wrong-player-position');
    }

    function _tutorialLineUpHelperGetPlayerLineupPosition(playerId) {
        var playerElement = $('.ol-team-settings-player-drag-and-drop[data-player-id=' + playerId + ']').first();
        return parseInt(playerElement.parent().attr('data-player-position-index'));
    }

    function _tutorialLineUpHelperGetSystemId() {
        // old LineupEditor:
        // var systemId = $("#dropdownTactic").dropdown().value();
        // return parseInt(systemId);

        var systemId = $(".ol-lineup-selection-wrapper").attr('data-value');
        return parseInt(systemId);
    }

    function _tutorialLineupSetupUnselectOne(data) {
        var _dataLineupSetupUnselectOne = true;
        $('.ol-lineup-editor-checkbox input').click(function() {
            if (!_dataLineupSetupUnselectOne) return;
            var checked = $('.ol-lineup-editor-checkbox input:checked');
            if (checked.length != 1) return;

            var el = checked.first().closest('.ol-team-settings-line-up-row-background-wrapper');
            var playerIdSelected = el.find('.ol-team-settings-player-drag-and-drop').attr('data-player-id');

            var prev1Id = _tutorialLineUp.firstSelected.find('.ol-team-settings-player-drag-and-drop').attr('data-player-id');
            var prev2Id = _tutorialLineUp.secondSelected.find('.ol-team-settings-player-drag-and-drop').attr('data-player-id');

            if (playerIdSelected != prev1Id && playerIdSelected != prev2Id) return;

            var other = (playerIdSelected == prev1Id)? _tutorialLineUp.secondSelected : _tutorialLineUp.firstSelected;

            var valueBar = el.find(".ol-gui-lineup-attr");
            //var playerAttributes = JSON.parse(valueBar.attr("data-player-attributes"));

            var valueBar2 = other.find(".ol-gui-lineup-attr");
            //var otherPlayerAttributes = JSON.parse(valueBar2.attr("data-player-attributes"));

            function _done() {
                olLeagueLicence.licencePartGained('lineupSetupUnselectOne');
                _dataLineupSetupUnselectOne = false;
            }

            _done();
        });
    }

    function _tutorialLineupSetupUnexpand() {
        $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
            $('#maximizeButton .icon-ol-resize-icon').addClass('ol-tutorial-highlight');
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            $('#maximizeButton .icon-ol-resize-icon').removeClass('ol-tutorial-highlight');
        });
        var _dataLineupSetupUnexpand = true;
        $('#maximizeButton').click(function() {
            if (!_dataLineupSetupUnexpand) return;
            olLeagueLicence.licencePartGained('lineupSetupUnexpand');
            _dataLineupSetupUnexpand = false;
        });
    }

    function _tutorialLineupSetupStrikerToLineup() {
        if (olGUI.isMobileOrTabletDevice()) {
            _top('0px');
        }
        $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
            $('.ol-pitch-position[data-player-position-index="8"] .ol-team-settings-player-drag-and-drop').addClass('ol-tutorial-highlight');
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            $('.ol-pitch-position[data-player-position-index="8"] .ol-team-settings-player-drag-and-drop').removeClass('ol-tutorial-highlight');
        });
        var _datatutorialLineupSetupStrikerToLineup = true;
        $('#ol-root').on('lineupEditorSetPlayer', function(event, eventObject) {
            if (!_datatutorialLineupSetupStrikerToLineup) return;
            var prev1Id = _tutorialLineUp.firstSelected.find('.ol-team-settings-player-drag-and-drop').attr('data-player-id');
            var prev2Id = _tutorialLineUp.secondSelected.find('.ol-team-settings-player-drag-and-drop').attr('data-player-id');
            if ((eventObject.playerId == prev1Id || eventObject.playerId == prev2Id) && eventObject.position == 8) {
                olLeagueLicence.licencePartGained('lineupSetupStrikerToLineup');
                _datatutorialLineupSetupStrikerToLineup = false;
            }
        });
    }

    function _tutorialLineupSetupSelectAll() {
        $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
            $('#settingsSelected').addClass('ol-tutorial-highlight');
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            $('#settingsSelected').removeClass('ol-tutorial-highlight');
        });
        if ($('#checkboxFilterPlayer').prop("checked") == false)
            olLeagueLicence.licencePartGained('lineupSetupSelectAll');
        $('#checkboxFilterPlayer').on('change', function() {
            if ($('#checkboxFilterPlayer').prop("checked") == false)
                olLeagueLicence.licencePartGained('lineupSetupSelectAll');
        });
    }

    var _playerHintsCount = 0;
    var _playerHintsCountMSg = true;
    function _tutorialLineUpPlayerHints(playerId, position, data) {
        function _showHint(title, content) {
            $('#tutorialHintElement').html(content);
        }

        if (_playerHintsCount >= 10) {
            if (_playerHintsCountMSg) {
                _showHint(data.msgTitleHint, data.msgHintStop);
                _playerHintsCountMSg = false;
            }
            return;
        }
        var playerAttributes = _tutorialLineupHelperGetPlayerAttributes(playerId);
        var pos = _tutorialLineUpHelperGetPlayerLineupPosition(playerId);
        if (playerAttributes['28'].value <= 30) {
            _showHint(data.msgTitleHint, data.msgContentLowFitness);
            ++_playerHintsCount;
        } else if (playerAttributes['9'].value < 12) {
            _showHint(data.msgTitleHint, data.msgContentLowCondition);
            ++_playerHintsCount;
        } else if (_tutorialLineUpHelperWrongPlayerPosition(playerId)) {
            _showHint(data.msgTitleHint, data.msgContentWrongPosition);
            ++_playerHintsCount;
        } else if ([128, 64, 32].indexOf(pos) >= 0 && playerAttributes['15'].value < 15) {
            _showHint(data.msgTitleHint, data.msgContentLowDuelPower);
            ++_playerHintsCount;
        } else if ([64].indexOf(pos) >= 0 && playerAttributes['7'].value < 10) {
            _showHint(data.msgTitleHint, data.msgContentSlow);
            ++_playerHintsCount;
        } else if ([8, 16].indexOf(pos) >= 0 && playerAttributes['20'].value < 10) {
            _showHint(data.msgTitleHint, data.msgContentLowShootingTechnique);
            ++_playerHintsCount;
        } else if ([64].indexOf(pos) >= 0 && playerAttributes['21'].value < 15) {
            _showHint(data.msgTitleHint, data.msgContentLowTactic);
            ++_playerHintsCount;
        } else if ([256].indexOf(pos) >= 0 && playerAttributes['1'].value < 13) {
            _showHint(data.msgTitleHint, data.msgContentLowGoalkeeperLine);
            ++_playerHintsCount;
        } else if ([256].indexOf(pos) >= 0 && playerAttributes['3'].value < 10) {
            _showHint(data.msgTitleHint, data.msgContentLowGoalkeeperComeOut);
            ++_playerHintsCount;
        } else if ([256].indexOf(pos) >= 0 && playerAttributes['2'].value < 10) {
            _showHint(data.msgTitleHint, data.msgContentLowPenalAreaControl);
            ++_playerHintsCount;
        } else if ([21, 22, 23, 24].indexOf(_tutorialLineUpHelperGetSystemId()) >= 0 && [8].indexOf(pos) >= 0 && playerAttributes['7'].value < 15) {
            _showHint(data.msgTitleHint, data.msgContentLowLowSpeed);
            ++_playerHintsCount;
        } else if ([14, 21, 26, 27, 28].indexOf(_tutorialLineUpHelperGetSystemId()) >= 0 && [16].indexOf(pos) >= 0 && playerAttributes['16'].value < 15) {
            _showHint(data.msgTitleHint, data.msgContentLowTechnic);
            ++_playerHintsCount;
        } else {
            _showHint('', '');
        }
    }

    function _tutorialLineupSetupFillAllSlots(data) {
        //function _getEmptyPositionCount() {
        //    var count = 0;
        //    $('.ol-pitch-position').each(function(index, element) {
        //        var child = $(element).find('.ol-team-settings-player-drag-and-drop').first();
        //        var attr = child.attr('data-player-id');
        //        if (!attr) ++count;
        //    });
        //    return count;
        //}
        if (olGUI.isMobileOrTabletDevice()) {
            _top('50px');
        }
        $('#ol-root').off('lineupEditorSetPlayer.tutorialLineupSetupFillAllSlots');
        $('#ol-root').on('lineupEditorSetPlayer.tutorialLineupSetupFillAllSlots', function(event, eventObject) {
            _tutorialLineUpPlayerHints(eventObject.playerId, eventObject.position, data);
        });
    }

    function _addTutorialHighlightTo(elements) {
        $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
            elements.addClass('ol-tutorial-highlight');
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            elements.removeClass('ol-tutorial-highlight');
        });
    }

    function _tutorialTacticSetLeagueOrFriendly(data) {
        if (olGUI.isMobileOrTabletDevice()) {
            _top('285px');
        }
        _addTutorialHighlightTo($('.ol-team-settings-line-up-header-league, .ol-team-settings-line-up-header-friendly'));
    }

    function _tutorialTacticSetupMacros() {
        $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
            var ele = $('.btn-add-macro-tutorial .btn-text');
            ele.addClass('ol-tutorial-highlight');

            ele.one('click', function() {
                ele.removeClass('ol-tutorial-highlight');
                setTimeout(function() {
                    $('.playactions-entry-name input').parent().addClass('ol-tutorial-highlight');
                }, 5000);
            });
        });

        $('.ol-tutorial-overlay-page-task').one('olTutorialPageSuccess', function(event, eventObject) {
            var ele = $('.playactions-entry-name input').parent();
            ele.removeClass('ol-tutorial-highlight');
            $('.btn-add-macro-tutorial .btn-text').removeClass('ol-tutorial-highlight');
        });
    }

    function _tutorialOfficeContractInfo() {
        $(window).on('scroll.OfficeContractInfo', function() {
            var ele = $('#ntt-success-icon');
            if (ele.length > 0 && isInViewport(ele)) {
                olLeagueLicence.licencePartGained('officeContractInfo');
                $(window).off('scroll.OfficeContractInfo');
            }
        });
    }

    function _tutorialNewLineUp() {
        if($('body').hasClass('ol-xs') && !$('#mobileLineupSettings').hasClass('shown')) {
            $('.ol-more-icon ').click();
        }
        // _addTutorialHighlightTo($('#btnNewLineUp, .ol-team-settings-line-up-header .ol-team-settings-line-up-header-friendly'));
        _addTutorialHighlightTo($('#btnNewLineUp1, #btnNewLineUp2, #selectLineUp .ol-set-friendly-button'));
        if (olGUI.isMobileOrTabletDevice()) {
            _top('160px');
        }
    }

    function _top(value) {
        $('.ol-tutorial-overlay-wrapper').css("top", value === false ? "" : value);
    }

    function _left() {
        $('.ol-tutorial-overlay-wrapper').addClass("overlay-left");
    }

    // Handler einhängen
    function _registerTutorials(data) {
        _top(false);

        /* squad */
        _tutorials['squadFilter'] = _tutorialSquadFilter;

        /* lineup */
        _tutorials['system'] = _tutorialLineupSystem;
        _tutorials['rename'] = _tutorialLineUpRename;
        _tutorials['newLineUp'] = _tutorialNewLineUp; // doesnt work, wrong tutorial

        _tutorials['lineupSetupFilterStriker'] = _tutorialLineupSetupFilterStriker;
        _tutorials['lineupSetupShootingSkill'] = _tutorialLineupSetupShootingSkill;
        _tutorials['lineupSetupSelectBest'] = _tutorialLineupSetupSelectBest;
        _tutorials['lineupSetupShowSelected'] = _tutorialLineupSetupShowSelected;
        _tutorials['lineupSetupExpand'] = _tutorialLineupSetupExpand;
        _tutorials['lineupSetupRate'] = _tutorialLineupSetupRate;
        _tutorials['lineupSetupUnselectOne'] = _tutorialLineupSetupUnselectOne;
        _tutorials['lineupSetupUnexpand'] = _tutorialLineupSetupUnexpand;
        _tutorials['lineupSetupStrikerToLineup'] = _tutorialLineupSetupStrikerToLineup;
        _tutorials['lineupSetupSelectAll'] = _tutorialLineupSetupSelectAll;

        _tutorials['lineupFriendlySetupCreateNew'] = _tutorialNewLineUp;

        _tutorials['lineupSetupFillAllSlots'] = _tutorialLineupSetupFillAllSlots;

        _tutorials['lineupFriendlySetupFillAllSlots'] = _tutorialLineupSetupFillAllSlots;

        _tutorials['tacticSetLeagueOrFriendly'] = _tutorialTacticSetLeagueOrFriendly;
        _tutorials['tacticSetupMacros'] = _tutorialTacticSetupMacros;
        _tutorials['tacticSetupMacrosCondition'] = function() { _addTutorialHighlightTo($('.btn-add-condition .btn-text')); };
        _tutorials['tacticSetupMacrosSliderMinute'] = function() { _addTutorialHighlightTo($('.playactions-entry-item-settings.type-minute').closest('.playactions-entry-item-row')); };
        _tutorials['tacticSetupMacrosAddAction'] = function() { _addTutorialHighlightTo($('.playactions-entry-buttons .btn-add:not(.btn-add-condition) .btn-text')); };
        _tutorials['trainingExecute'] = function() { _addTutorialHighlightTo($('#trainingProcessStartBtn')); };

        _tutorials['officeContractInfo'] = _tutorialOfficeContractInfo;
        _tutorials['officeFinanceAccount'] = function() { _addTutorialHighlightTo($('.ol-button-toggle[data-sub-url="/office/statusAccount"]')); };

        _tutorials['stadiumSettings'] = function() { _addTutorialHighlightTo($('#stadium_row_N .rentStandsOptions')); };
        _tutorials['stadiumSettingsPrice'] = function() {
            _left();
            if (olGUI.isMobileOrTabletDevice()) {
                _top('0px');
            }
            _addTutorialHighlightTo($('#stadium_row_N td:nth-child(3) .settingsRightSection'));
            _addTutorialHighlightTo($('.basicStadiumStands .settingsRightSection'));
        };

        _tutorials['mapLeagueDropdown'] = function() {
            if (olGUI.isMobileOrTabletDevice()) {
                _addTutorialHighlightTo($('#ol-map-sidebar .menubutton .icon-ol-bars-black'));
            }
            _addTutorialHighlightTo($('.panel.ol-panel-collapse-default.leagues'));
            $("body").off("click.mapLeagueDropdown"); 
            $("body").on("click.mapLeagueDropdown", "#ol-map-sidebar-accordion a.ol-league-nav-league-a", function() {
                olLeagueLicence.licencePartGained('mapLeagueDropdown');
                $("body").off("click.mapLeagueDropdown");
            });
        };

        _tutorials['matchdayTableSelectLeague'] = function() {
            _top('300px');
            if (olGUI.isMobileOrTabletDevice()) {
                _top('0px');
            }
            $("body").off("click.matchdayTableSelectLeague");
            $("body").on("click.matchdayTableSelectLeague", "#leagueNavContent-matchdayTable a.ol-league-nav-league-a",function() {
                olLeagueLicence.licencePartGained('matchdayTableSelectLeague');
                $("body").off("click.matchdayTableSelectLeague");
            });
            _addTutorialHighlightTo($('#tooltipLeagueSelection, #stateSelectionTooltip, #districtSelectionTooltip, #leagueSelectionTooltip'));
        };

        _tutorials['rankingSelectCities'] = function() {
            if (olGUI.isMobileOrTabletDevice()) {
                _top('285px');
            }
            _addTutorialHighlightTo($('.menu-button-wrapper button:first-child')); // Markierung
        };

        _tutorials['messengerFindUser'] = function() { _addTutorialHighlightTo($('#user-search-keyword').parent()); };

        _tutorials['teamView'] = function() { _addTutorialHighlightTo($('.ol-button-toggle-container #toggleButtonSquad')); };
        _tutorials['officeYouthPlayerScouting'] = _officeYouthPlayerScouting;
    }

    function _officeYouthPlayerScouting() {
        _left();
    }

    olLeagueLicence._runTutorial = function(name, data) {
        if (typeof data === typeof undefined) data = false;
        if (_tutorials.hasOwnProperty(name))
            _tutorials[name](data);
    };

    /* Function initializes the league licence tutorial */
    olLeagueLicence.init = function() {
        if (_inited === false) {
            console.info('OL: League-Licence-Tutorial - league licence tutorial will be initialized...'); //DBG
            // prepare dom insert new elements and so on
            _prepareDOM();
            // join websocket channel for push updates
            _listenToPushUpdates();
            olEcho.userChannel().listen('User.LeagueLicenceUpdate', function(e) { console.log('ECHO > User.LeagueLicenceUpdate:',e); }); //DBG
            // register special js tutorial handling functions
            _registerTutorials();
            // remember initialization already done
            _inited = true;
            console.info('OL: League-Licence-Tutorial - initialization done!'); //DBG
        }
    };

    olLeagueLicence.showOverview = function() {
        // load overview
        _loadOverview();
    };

    //function _domRoot() {
    //    return $('#leagueLicenceOverlay');
    //}

    var _lastName = false;
    function _loadTutorial(name, doneAfter, callback) {
        console.info('OL: League-Licence-Tutorial - _loadTutorial("'+name+'")'); //DBG
        if (typeof doneAfter === typeof undefined) doneAfter = false;
        jQuery.getJSON('/licencetutorial/tutorial', {name: name}, function(data) {
            _lastName = data.name;
            $('.ol-tutorial-overlay-container').html(data.view);
            $('.ol-tutorial-header-text').html(data.headline);
            $('.ol-tutorial-header').show();
            olLeagueLicence.tutorialOverlayShow();

            if (data.msgOnly) {
                $('.ol-tutorial-overlay-page-task').one('olTutorialPageTask', function(event, eventObject) {
                    olLeagueLicence.licencePartGained(data.name);
                });
            }
            callback(data.name);
            olLeagueLicence.nextPage(1);
            olLeagueLicence.maximizeOverlayWindow();
        });
    }

    function _loadOverview() {
        console.info('OL: League-Licence-Tutorial - loadOverview'); //DBG
        olOverlayWindow.load('/licencetutorial/overview', {}, function() {
        });
    }

    var overviewNext = false;
    olLeagueLicence.overviewNext = function(name) {
        if (name) {
            _loadTutorial(data.name, true);
        }
    };

    /* GUI based licence parts */
    olLeagueLicence.licencePartGained = function(name) {
        $.post('/licencetutorial/gainedLicencePart', {name: name}, function() {
        });
    };

    /* Handle league licence updates */
    olLeagueLicence.update = function(data) {
        console.info('OL: League-Licence-Tutorial - update: ', data); //DBG
        switch (data.type) {
            case 'SHOW_OVERVIEW':
                _loadOverview();
                break;

            case 'SHOW_TUTORIAL':
                setTimeout(function() {
                    var additionalData = (data.data === undefined)? false : data.data;
                    var callback = function(_name) {
                        olLeagueLicence._runTutorial(_name, additionalData);
                    };
                    _loadTutorial(data.name, true, callback);
                }, 2000);
                break;

            case 'LICENCE_LEAGUE_ACQUIRED':
                $('.ol-tutorial-success-btn').removeClass('disabled');
                olLeagueLicence.lastPage();
                olLeagueLicence.maximizeOverlayWindow();
                var event = {};
                $('.ol-tutorial-overlay-page-task').trigger('olTutorialPageSuccess', [ event ]);
                olOverlayWindow.showFadingLock();
                $('.ol-tutorial-success-btn').on('click', function() {
                    olOverlayWindow.hideFadingLock();
                    $('.ol-tutorial-overlay-page-task').trigger('olTutorialPageSuccessBtn', [ event ]);
                    if (data.type === 'SHOW_OVERVIEW' || data.next === 'SHOW_OVERVIEW') {
                        //olLeagueLicence.tutorialOverlayHide();
                        overviewNext = data.next;
                        olLeagueLicence.succesPage();
                    } else {
                        var additionalData = (data.data === undefined)? false : data.data;
                        var callback = function() {
                            olLeagueLicence._runTutorial(data.next, additionalData);
                        };
                        _loadTutorial(data.next, false, callback);
                    }
                });
                break;
        }
    };

    var lastHeight = false;
    var lastWidth = false;
    olLeagueLicence.tutorialOverlayToggleExpand = function() {
        if (!$('.ol-tutorial-overlay').hasClass('ol-tutorial-overlay-expanded')) {
            olLeagueLicence.maximizeOverlayWindow();
            $('.ol-tutorial-overlay').removeClass('ol-tutorial-overlay-animation');
        } else {
            olLeagueLicence.minimizeOverlayWindow();
            setTimeout(function() {
                $('.ol-tutorial-overlay').addClass('ol-tutorial-overlay-animation');
            }, 1000);
        }
    };

    olLeagueLicence.tutorialOverlayHide = function() {
        $('.ol-tutorial-overlay').addClass('ol-tutorial-overlay-hidden');
    };

    olLeagueLicence.tutorialOverlayShow = function() {
        $('.ol-tutorial-overlay').removeClass('ol-tutorial-overlay-hidden');
    };

    olLeagueLicence.maximizeOverlayWindow = function() {
        if ($('.ol-tutorial-overlay').hasClass('ol-tutorial-overlay-expanded')) return;
        var height = $('.ol-tutorial-overlay').height();
        var width = $('.ol-tutorial-overlay').width();

        $('.ol-tutorial-overlay-container').css({ 'width': '', 'height' : ''});
        $('.ol-tutorial-overlay').addClass('ol-tutorial-overlay-expanded');
        lastHeight = $('.ol-tutorial-overlay-container').height();
        lastWidth = $('.ol-tutorial-overlay-container').width();
        $('.ol-tutorial-overlay-container').height(lastHeight);
        $('.ol-tutorial-overlay-container').width(lastWidth);
        lastHeight = $('.ol-tutorial-overlay').height();
        lastWidth = $('.ol-tutorial-overlay').width();
        $('.ol-tutorial-overlay').removeClass('ol-tutorial-overlay-expanded');

        $('.ol-tutorial-overlay').height(height);
        $('.ol-tutorial-overlay').width(width);
        $('.ol-tutorial-overlay').addClass('ol-tutorial-minimize-maximize-animation');
        setTimeout(function() {
            $('.ol-tutorial-overlay').addClass('ol-tutorial-overlay-expanded');
            if (lastHeight !== false) {
                $('.ol-tutorial-overlay').height(lastHeight);
                $('.ol-tutorial-overlay').width(lastWidth);
            } else {
                $('.ol-tutorial-overlay-container').css({ 'width': '', 'height' : ''});
                $('.ol-tutorial-overlay').css({ 'width': '', 'height' : ''});
            }
            setTimeout(function() {
                $('.ol-tutorial-overlay-container').css({ 'width': '', 'height' : ''});
                $('.ol-tutorial-overlay').css({ 'width': '', 'height' : ''});
                $('.ol-tutorial-overlay').removeClass('ol-tutorial-minimize-maximize-animation');
            }, 1000);
        }, 1);
        $('.ol-tutorial-overlay').removeClass('ol-tutorial-overlay-animation');
    };

    olLeagueLicence.minimizeOverlayWindow = function() {
        if (!_inited) return;
        if (!$('.ol-tutorial-overlay').hasClass('ol-tutorial-overlay-expanded')) return;
        $('.ol-tutorial-overlay').addClass('ol-tutorial-minimize-maximize-animation');
        olOverlayWindow.hideFadingLock();
        lastHeight = $('.ol-tutorial-overlay-container').height();
        lastWidth = $('.ol-tutorial-overlay-container').width();
        $('.ol-tutorial-overlay-container').height(lastHeight);
        $('.ol-tutorial-overlay-container').width(lastWidth);
        lastHeight = $('.ol-tutorial-overlay').height();
        lastWidth = $('.ol-tutorial-overlay').width();
        $('.ol-tutorial-overlay').height(lastHeight);
        $('.ol-tutorial-overlay').width(lastWidth);
        $('.ol-tutorial-overlay').removeClass('ol-tutorial-overlay-expanded');
        setTimeout(function() {
            $('.ol-tutorial-overlay').css({ 'width': '', 'height' : ''});
            setTimeout(function() {
                $('.ol-tutorial-overlay').removeClass('ol-tutorial-minimize-maximize-animation');
            }, 1000);
        }, 1);
        $('.ol-tutorial-overlay').addClass('ol-tutorial-overlay-animation');
    };

    olLeagueLicence.nextPage = function(page) {
        $('.ol-tutorial-header').removeClass('ol-tutorial-overlay-page-success');
        $('.ol-tutorial-overlay-page').each(function(index, element) {
            if (index === (page - 1)) {
                var event = { 'page': page };
                $('.ol-tutorial-overlay-page-task').trigger('olTutorialPage', [ event ]);
                $(element).show()
                if ($(element).hasClass('ol-tutorial-overlay-page-task')) {
                    var event = {};
                    $('.ol-tutorial-overlay-page-task').trigger('olTutorialPageTask', [ event ]);
                }
            } else {
                $(element).hide()
            }
        });
    };

    olLeagueLicence.lastPage = function() {
        $('.ol-tutorial-header').removeClass('ol-tutorial-overlay-page-success');
        $('.ol-tutorial-overlay-page').hide();
        $('.ol-tutorial-overlay-page:not(.ol-tutorial-overlay-page-success)').last().show();
    };

    olLeagueLicence.succesPage = function() {
        if( $('body').attr('data-page-url') === 'licencetutorial/page') olAnchorNavigation.reload();
        $('.ol-tutorial-header').addClass('ol-tutorial-overlay-page-success');
        $('.ol-tutorial-overlay-page').hide();
        $('.ol-tutorial-overlay-page.ol-tutorial-overlay-page-success').show();
    };


    olLeagueLicence.tutorialLoad = function(name) {
        if (name == '') return;
        var data = {name: name};
        $.post('/licencetutorial/load', data, function(rc) { 
            console.log('OL: League-Licence-Tutorial - load tutorial "'+name+'":', rc); 
        }, 'json').fail(function() {
            console.log('*** licencetutorial/load failed:', data);
        });

    };

}(window.olLeagueLicence = window.olLeagueLicence || {}, jQuery));