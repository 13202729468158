(function(widget)
{
    var $el;
    var ids = [];

    widget.init = function(selector) {
        widget.destroy(); // Clear old, in case of refresh

        $el = $(selector).find('.ol-dashboard-body');
        widget.refresh(removeLoadingAnimationFromElement);

        for (var x=0; x<ids.length; x++) {
            olEcho.channel('{{domain}}:online-state.'+ids[x]).stopListening('OnlineStateUpdate')
        }

        ids = [];

        if (!olOnlineWidget.interval) olOnlineWidget.interval = setInterval(widget.refresh, 30000);

        onUnloadPage(widget.destroy);
    }

    widget.destroy = function() {
        if(olOnlineWidget.interval) clearInterval(olOnlineWidget.interval);
        olOnlineWidget.interval = undefined;
    }

    widget.refresh = function(callback) {
        if (!$el || window.nowidgetrefresh) return;

        if($el.size() == 0) {  // We have left the dashboard, stop refreshing
            widget.destroy();
        }

        $.get('/messenger/widget', function(res) {
            $el.html(res);
            if (typeof callback == "function") {
                callback();
            }
        });
    }

    widget.register = function(uid) {
        if (ids.includes(uid)) return;
        ids.push(uid);
        olEcho.channel('{{domain}}:online-state.'+uid).listen('OnlineStateUpdate', widget.refresh);
    }

}(window.olOnlineWidget = window.olOnlineWidget || {}));


(function(widget)
{
    var $el, ctx, history;
    var colorGreen, colorRed;

    widget.init = function(selector, data, config) {
        $el = $(selector);
        ctx = $el[0].getContext('2d');

        history = data.reverse();

        config = config || {};

        colorGreen = config.colorGreen || "#0c0";
        colorRed   = config.colorRed   || "#c00";

        widget.draw();
    }

    widget.draw = function() {
        var height, width;
        var padding = 5;
        ctx.canvas.width  = width  = $el.width();
        ctx.canvas.height = height = $el.height();

        var balanceMax = 0;
        var balanceMin = 0;

        if (!history || !history[0]) return;

        var start = history[0][0];
        var end   = history[history.length-1][0];

        for (var x=0; x<history.length; x++) {
            if (history[x][1] > balanceMax) balanceMax = history[x][1];
            if (history[x][1] < balanceMin) balanceMin = history[x][1];
        }

        var factor = (height-padding*2) / (balanceMax - balanceMin);
        var offset = -balanceMin * factor;
        var step = width / history.length;

        //console.log("factor", factor)
        //console.log("offset", offset);
        var lineColor = $(document.documentElement).hasClass('dark-theme') ? '#0A0A0A' : 'white';
        ctx.fillStyle = lineColor;
        ctx.fillRect(0,0,9999,9999);

        ctx.strokeStyle = "#a2a2a2"
        ctx.lineWidth = 2;

        ctx.beginPath();
        ctx.setLineDash([5, 5]);
        ctx.moveTo(0, (height-padding) - offset);
        ctx.lineTo(width, (height-padding) - offset);
        ctx.stroke()

        ctx.setLineDash([]);
        
        var gradient = ctx.createLinearGradient(0,0,0,height);

        var zeroLine = ((height-padding) - offset) / height;

        gradient.addColorStop(0, colorGreen);
        gradient.addColorStop(zeroLine, colorGreen);
        gradient.addColorStop(zeroLine, colorRed);
        gradient.addColorStop(1, colorRed);
        ctx.strokeStyle = gradient;
        
        ctx.beginPath();
        ctx.moveTo(0, (height-padding) - (history[0][1]*factor + offset))
        
        for (var x=0; x<history.length; x++) {
            ctx.lineTo(x*step, (height-padding) - (((history[x][1]*factor) + offset)))
            
            //console.log(  (height-padding) - (((history[x][1]*factor) + offset)) );
            //console.log("History["+x+"] =", history[x]);
            
        }
        ctx.stroke();


    }


}(window.olFinanceGraph  = window.olFinanceGraph  || {}));