var slimNavAnimLength = 400;
var subNavAnimLength = 300;
var bannerHeightSlim = 35;
var lastSlim = -1;
var navProgressCounter = 0;
var navUp = false;
var wasUp = false;
var lastId = -1;
var lastElement = null;

function initNav()
{
    $("#ol-navigation-collapse").on('show.bs.collapse', function(e) 
    {
        closeSubnavigationSmooth();
    });
    
    $( window ).scroll(function() 
    {
        scaleNavigation();
    });
    
    // $( ".ol-navbar-subnav-item" ).hover
    // (
    //     function() 
    //     {
    //         $(this).addClass("ol-navbar-subnav-item-hover");
    //     }, 
    //     function() 
    //     {
    //         if($(this).attr("data-selected") == 0)
    //             $(this).removeClass("ol-navbar-subnav-item-hover"); 
    //     }
    // );
    
    
    $( ".ol-navbar-subnav-item" ).click
    (
        function() 
        {
            // $( ".ol-navbar-subnav-item" ).removeClass("ol-navbar-subnav-item-hover");
            $( ".ol-navbar-subnav-item" ).attr("data-selected", 0);
            // $(this).addClass("ol-navbar-subnav-item-hover");
            $(this).attr("data-selected", 1);
        }
    );

    var subnavOnScroll =
        function() {
            var $this = $(this);
    
            if ($this.scrollLeft() <= this.scrollWidth - ($this.width()+10)) {
                $(this).parent().addClass('arrow-right');
            } else {
                $(this).parent().removeClass('arrow-right');
            }
            if ($this.scrollLeft() <= 10) {
                $(this).parent().removeClass('arrow-left');
            } else {
                $(this).parent().addClass('arrow-left');
            }
        }

    $('div.ol-navbar-subnav-item-container:visible').each(subnavOnScroll);

    $('div.ol-navbar-subnav-item-container').each(function() { this.addEventListener('scroll', subnavOnScroll, { "passive": true }) });
    
    setTimeout(function() { openSubnav(lastElement)}, 0);

    // Open subnav when on nav-item
    $('.ol-navbar-button-helper > a').mouseenter(function(){hoverEnterNavbarHandler(this)});
    $('.ol-nav-settings-icon').mouseenter(function(){hoverEnterNavbarHandler(this)});

    /* close subnav when:
    *   - leaving the subnav,
    *   - entering the content,
    *   - leaving the navigation area to the top
    */
    $('.ol-subnav-item-container').mouseleave(function(){hoverLeaveNavbarHandler(this)});
    $('.ol-root').mouseenter(function(){hoverLeaveNavbarHandler(this)});
    $('.ol-banner-content.ol-header-content-width').mouseenter(function(){hoverLeaveNavbarHandler(this)});
}

function calculateSubnavBg() {
    var $bg = $('.ol-nav-background');
    var $activeSubNav = $('.ol-subnav-item-container:visible');
    var width = $activeSubNav.innerWidth();
    var height = $activeSubNav.innerHeight();

    $bg.css({
        'width' : width,
        'height' : height
    });
}

// Display the subnavigation and its background
function showSubNav(target) {
    var $subNavContainer = $('.ol-subnav-item-container');
    var $bg = $('.ol-nav-background');

    $subNavContainer.css('display', 'none');

    $bg.fadeIn(100)
    // stop animating if animation is already running.
    $('#subnav_'+target).stop(true, true).fadeIn();
    $bg.attr('data-target','subnav_'+target);            
    calculateSubnavBg()
}

// shows the subnav when hovering on a menu item
// and only remove it, when hovering another menu item
function hoverEnterNavbarHandler(origin) {
    var target = $(origin).attr('data-name');
    var $navItems = $('.ol-navbar-button-helper > a');
    
    // Navitems without submenu:
    if(target == 'nav_map' ||
       target == 'nav_news' ||
       target == 'nav_overview' ||
       target == 'nav_statistics') {
        return
    } else {
        // show subnav on hover and remove other items        
        showSubNav(target);
    
        // keep menu item hover effekt
        $navItems.each(function(i, elem) {
            if($(elem).attr('data-name') == target) {
                $(elem).addClass('hovered');
            } else {
                $(elem).removeClass('hovered');
            }
        })        
    }
}

// close and reset subnav 
function hoverLeaveNavbarHandler(origin) {
    var $navItems = $('.ol-navbar-button-helper > a');
    var $bg = $('.ol-nav-background');
    $bg.fadeOut(300);
    // show subnav item of page you are currently on
    var $subNavContainer = $('.ol-subnav-item-container');
    $subNavContainer.fadeOut(300);

    // remove hover effekt
    $navItems.removeClass('hovered');        
}

function replaceClassKeyword(src, dst)
{
    $("[class*=" + src + "]").each(function(index, obj) 
    {
        var classList = $(obj).attr('class').split(' ');
        $.each(classList, function(index, item) 
        {
            if(item.indexOf(src) !== -1) 
            {
                $(obj).removeClass(item);
                $(obj).addClass(item.replace(src, dst));
            }
        });
    });
}

var scrollShadow = false;

function scaleNavigation()
{

    if (olAppInfo.APP_MODE) {
        return;
    }

    $body = $('body');
    if ($body.hasClass('no-scroll')) {
        return;
    }
    var top = $(document).scrollTop();
    

    if (!scrollShadow && ($body.hasClass('ol-xs') || $body.hasClass('ol-sm')) && top > 0) 
    {
        $('.ol-navbar-subnav-content').addClass('scroll-shadow');
        scrollShadow = true;

        return;
    } else if (scrollShadow && ($body.hasClass('ol-xs') || $body.hasClass('ol-sm'))  && top == 0) {
        $('.ol-navbar-subnav-content').removeClass('scroll-shadow');
        scrollShadow = false; 

        return;
    }

    if ($body.hasClass('ol-xs') || $body.hasClass('ol-sm')) {
        return;
    }

    slim = ol.navMap || (top > 65);
    if(lastSlim == slim) return;
    var validSubNav = (typeof lastElement !== typeof undefined) && lastElement !== null && (lastElement.substring(0, 1) == "#");
    

    if(slim)
    {
        $("#ol-navigation-collapse").collapse('hide');
        if(navUp)
        {
            wasUp = true;
            if(validSubNav)
                closeSubnav(lastElement);
        }
    }
    
    if(navProgressCounter > 0)
    {
        setTimeout(function()
        {
            scaleNavigation();
        }, 10);
        return;
    }
    
    ++navProgressCounter;
         
    setTimeout(function()
    {
        --navProgressCounter;
    }, slimNavAnimLength + 25);
    
    if(slim)
    {
        $("#ol-navbar-subnav-container").removeClass("ol-navbar-subnav-open");
        // $('.ol-banner-logo').removeClass("ol-logo-animate-in");
        // $('.ol-banner-logo').addClass("ol-logo-animate-out");
        // $("body").addClass("ol-nav-slim");
        
    }
    else
    {
        if(wasUp)
        {
            if(validSubNav)
                openSubnav(lastElement);
        }
        setTimeout(function()
        {
            $('.ol-banner-logo').removeClass("ol-logo-animate-out");
            // $('.ol-banner-logo').addClass("ol-logo-animate-in");
        }, 0);
        $("body").removeClass("ol-nav-slim");
        
    }
    lastSlim = slim;
}

function closeSubnavigationSmooth()
{
    $(".ol-navbar-subnav-item-container").each(function() 
    { 
        subnavTarget = this;
        if($(subnavTarget).is(':visible'))
        {
            container = $("#ol-navbar-subnav-container");
            container.removeClass("ol-navbar-subnav-open");
            navUp = false;
        }
    });
}

function closeSubnavigation()
{
    target = 0;
    height = getSubnavHeight();
    $(".ol-navbar-subnav-item-container:visible").each(function() 
    { 
        // $(this).css({ "top": (target - height) + "px" });
        $(this).hide();
    });
}

function getSubnavHeight()
{
    var height = $(".ol-navbar-subnav-item:first").css("height")
    if (!height) return 0;
    
    return parseInt(height.replace(/[^-\d\.]/g, ''));
}

lastNavClickElement = false;

function closeSubnav(subnavTarget)
{
    if (olGUI.getBootstrapDeviceSize() == 'ol-xs' || olGUI.getBootstrapDeviceSize() == 'ol-sm') {
        return; // Mobile subnav always stays open
    }
    $("#ol-navigation-collapse").collapse("hide");
    closeSubnavigation();
    container = $("#ol-navbar-subnav-container");
    container.show();
    // $(subnavTarget).css('display', 'grid');
    // $(subnavTarget).css('top', '0');
    // $(subnavTarget).removeClass('hidden');
    container.removeClass("ol-navbar-subnav-open");
    navUp = false;
}

function openSubnav(subnavTarget)
{
    $("#ol-navigation-collapse").collapse("hide");
    closeSubnavigation();
    container = $("#ol-navbar-subnav-container");
    container.show();
    // $(subnavTarget).css('display', 'grid');
    // $(subnavTarget).css('top', '0');
    // $(subnavTarget).removeClass('hidden');
    container.addClass("ol-navbar-subnav-open");
    navUp = true;
}

function naviUnselect()
{
    lastElement = null;
    if(lastNavClickElement !== false)
        $(lastNavClickElement).removeClass("ol-nav-underline");
}

function onClickNavigationItem(subnavTarget, linkElement, autoLoad, subNavElementId)
{
    //console.log('SubNavTarget: ' + subnavTarget);
    if(typeof autoLoad === typeof undefined) autoLoad = true;
    var newPage = subnavTarget != lastElement;
    var validSubNav = (subnavTarget.substring(0, 1) == "#");

    var subnavName = subNavElementId ? subNavElementId.slice(1) : '';

    if (window.confirmCheckActive) {
        newPage = false;
    }
    if(!newPage)
    {
        if(!navUp && validSubNav)
            openSubnav(lastElement);
        if(autoLoad == false && subNavElementId !== false)
        {
            // $(".ol-navbar-subnav-item-hover").removeClass("ol-navbar-subnav-item-hover");
            $(".ol-navbar-subnav-item").attr("data-selected", 0);
            $(subNavElementId).attr("data-selected", 1);
            // $(subNavElementId).addClass("ol-navbar-subnav-item-hover");
            // $("."+subnavName).addClass("ol-navbar-subnav-item-hover");
        }
        
        if(autoLoad)
        {
            var defaultSubnav = $(subnavTarget).find(".ol-navbar-subnav-item.default");
            if(defaultSubnav.length == 0)
                defaultSubnav = $(subnavTarget).find(".ol-navbar-subnav-item");
            defaultSubnav.first().trigger("click");
        }
        return false;
    }
    
    if(lastNavClickElement !== false)
        $(lastNavClickElement).removeClass("ol-nav-underline");
    $(linkElement).addClass("ol-nav-underline");
    
    lastNavClickElement = linkElement;
    lastElement = subnavTarget;
    if(!validSubNav)
    {
        olAnchorNavigation.load(subnavTarget, undefined);
        if(navUp)
        {
            container.show();
            container.removeClass("ol-navbar-subnav-open");
            navUp = false;
        }
        return;
    }
    
    
    id = $(subnavTarget).attr("id");
    $("#ol-navigation-collapse").collapse("hide");
    closeSubnavigation();
    container = $("#ol-navbar-subnav-container");
    container.show();
    // $(subnavTarget).show();
    if ($("body").hasClass('ol-xs')) $(subnavTarget).css('display', 'flex');
    target = 0;
    height = getSubnavHeight();
    
    if(id != lastId && navUp)
        navUp = false;
    
    container.stop();
    lastId = id;
    if(navUp)
    {
        // nav button already activated - do nothing
        return false;
        // close nav menu (button clicked again after activating sub menu)
        target = target - height;
        container.animate({
            "top": target + "px"
          }, subNavAnimLength);
        navUp = false;
    }
    else
    {
        container.addClass("ol-navbar-subnav-open");
        navUp = true;
    }
    
    // find first subnavigation item and trigger click event (default -> first item selected)
    if(newPage && autoLoad)
    {
        // var defaultSubnav = $(subnavTarget).find(".ol-navbar-subnav-item.default");
        var defaultSubnav = $(subnavTarget).find(".ol-subnav-item.default");
        if(defaultSubnav.length == 0)
            defaultSubnav = $(subnavTarget).find(".ol-subnav-item");
        defaultSubnav.first().trigger("click");
    }
    if(autoLoad == false && subNavElementId !== false)
    {
        // $(".ol-navbar-subnav-item-hover").removeClass("ol-navbar-subnav-item-hover");
        $(".ol-navbar-subnav-item").attr("data-selected", 0);
        $(subNavElementId).attr("data-selected", 1);
        // $(subNavElementId).addClass("ol-navbar-subnav-item-hover");
        // $("."+subnavName).addClass("ol-navbar-subnav-item-hover");
    }
    return false;
}

ol.navMap = false;
function resizeMapContainer(triggerResize)
{
    if(ol.navMap === true)
    { 
		$("#ol-map-root").css("top", $("#ol-header-container").height()).show();
		if (triggerResize) {
			$(window).trigger('resize');
		}
    } else {
		$("#ol-map-root").hide();
	}
}

function openExternal(link) {
    if (event) {
        event.preventDefault();
    }
    if (olAppInfo.APP_MODE) {
        olMobileApp.openExternal(link);
        return false;
    } else {
        window.open(link, '_blank');
    }
}

function openAuthedWindow(link) {
    if (olAppInfo.APP_MODE) {
        olMobileApp.openAuthedBrowser(link);
        return false;
    } else {
        window.open(link, '_blank');
    }
}


function toggleMobileNav(navButton) {
    if ($('.ol-navigation-collapse-container').hasClass('mobileNavAnimation')) {
        hideMobileNav();
        $(navButton).removeClass('navigationAnimation');
    } else {
        showMobileNav();
        $(navButton).addClass('navigationAnimation');
    }
}

function showMobileNav() {
    $('.ol-navigation-collapse-container').addClass('mobileNavAnimation'); 
    $('.ol-mobile-nav-menu-right-layer').addClass('ol-mobile-nav-menu-right-layer-animate');
    olGUI.lockScroll($('#ol-navigation-collapse'));
}

function hideMobileNav() {
    $('.navigationAnimation').removeClass('navigationAnimation');
    $('.ol-navigation-collapse-container').removeClass('mobileNavAnimation'); 
    $('.ol-mobile-nav-menu-right-layer').removeClass('ol-mobile-nav-menu-right-layer-animate');
    olGUI.unlockScroll();
}

// color active menu item
$(window).load(function(){
    $('.ol-mobile-nav-elem').on('click', function(){colorMobileNav()});
    colorMobileNav()
});

function colorMobileNav() {  
    var color = $('#statePrimary').css('background-color');
    var isDarkMode = $(document.documentElement).hasClass('dark-theme');
    var $selector = $('body[data-page-url*="team"] .ol-mobile-nav-elem[data-target="team"],' +
    'body[data-page-url*="office"] .ol-mobile-nav-elem[data-target="office"],' +
    'body[data-page-url*="transfer"] .ol-mobile-nav-elem[data-target="transfer"],' +
    'body[data-page-url*="matchday"] .ol-mobile-nav-elem[data-target="matchday"],' +
    'body[data-page-url*="more"] .ol-mobile-nav-elem[data-target="more"],' +
    'body[data-page-url*="map"] .ol-mobile-nav-elem[data-target="map"],' +
    'body[data-page-url*="news"] .ol-mobile-nav-elem[data-target="news"],' +
    'body[data-page-url*="statistics/ranking"] .ol-mobile-nav-elem[data-target="statistics/ranking"]');
    var $navElem = $('.ol-mobile-nav-elem');
   
    $navElem.css({
        'color': isDarkMode ? 'white' : 'black',
        'border-bottom-color': 'transparent'
    }).find('path').css('fill', isDarkMode ? 'white' : 'black');

    $selector.css({
        'color': color,
        'border-bottom-color': color
    }).find('path').css('fill', color);
}