(function(olPlayerWatchList, $, undefined) {

    function _setActive(watchListIconId) {
        $('#' + watchListIconId + ' .icon-icon_scouting_inactive').hide();
        $('#' + watchListIconId + ' .icon-icon_scouting_active').show();
        $('#' + watchListIconId).attr('data-toggle-active', 'true');
    }

    function _setInactive(watchListIconId) {
        $('#' + watchListIconId + ' .icon-icon_scouting_active').hide();
        $('#' + watchListIconId + ' .icon-icon_scouting_inactive').show();
        $('#' + watchListIconId).attr('data-toggle-active', 'false');
    }

    olPlayerWatchList.removeFromWatchList = function(playerWatchListIds, callback) {
        if (callback === undefined) callback = false;
        if (playerWatchListIds.length <= 0) return;
        var url = '/watchList/multiremove';
        $.post(url, {playerWatchListIds: playerWatchListIds}, function(response) {
            // olMsgBox.json(response);
            if (callback !== false) callback();
        }).fail(function(){
            console.log('***removeFromWatchList failed');
        });
    };

    olPlayerWatchList.initWatchListIcon = function(watchListIconId) {
        var $watchListIcon = $('#' + watchListIconId);
        if ($watchListIcon.attr('data-toggle-active') === 'false') {
            _setInactive(watchListIconId);
        } else {
            _setActive(watchListIconId);
        }
    };

    olPlayerWatchList.toggle = function(watchListIconId, playerId) {
        var $watchListIcon = $('#' + watchListIconId);
        var isInactive = $watchListIcon.attr('data-toggle-active') === 'false';

        var url = isInactive ? '/watchList/add' : '/watchList/remove';
        $.post(url, {playerId: playerId}, function(response) {
            $watchListIcon.attr('data-content', '<b>' + response.content + '</b>');
            if (isInactive && response.check === 'success') {
                _setActive(watchListIconId);
            } else if (!isInactive && response.check === 'success') {
                _setInactive(watchListIconId);
            }
            $watchListIcon.popover('enable');
            $watchListIcon.popover({trigger: 'manual', delay: {show: 3000, hide: 3000}});
            $watchListIcon.popover('show');
            $watchListIcon.popover('disable');
            setTimeout(function() { $watchListIcon.popover('hide'); }, 3000);
        });
    };

}(window.olPlayerWatchList = window.olPlayerWatchList || {}, jQuery));