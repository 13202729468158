(function(olFriendList, $, undefined) {
    
    olFriendList.init = function() {
        //$('#user-search-results').resizable();
    }; 

    olFriendList.toggleIgnore = function(relatedUserId) {
        var ignoreText = $('#usermeta-ignore-checkbox').text();
        var blockText = Lang.trans('js/user.block');
        $('#usermeta-ignore-checkbox').text( ignoreText === blockText ? Lang.trans('js/user.blocked') : blockText);

        $.post('/userrelations/ignore', {relatedUserId: relatedUserId}, function(data){
            messageSystem.loadUserList(relatedUserId);
        });  
    };
    
    
    olFriendList.addUserRelation = function(relatedUserId, type) {
        $.post('/userrelations/add', {relatedUserId: relatedUserId, type: type}, function(data) {
            //todo remark user added to x 
            $('#user-search-results').hide();
            $(document).trigger('newUserRelation', {relatedUserId: relatedUserId, type: type});
        });
    };

    olFriendList.removeUserRelation = function(relatedUserId) {
        $.post('/userrelations/remove', {relatedUserId: relatedUserId}, function(data) {
            //todo remark user removed from x
            $('#user-search-results').hide();
            messageSystem.loadMessageSystem();
        });
    };

    olFriendList.userRelationButton = function(element) {
        $(element).parent().parent().find('button.active').removeClass('active');
        $(element).addClass('active')
    };

    
    //hide search results onclick elsewere
    $(window).on("touchmousedown", function(event) {
        if (event.target.id != 'user-search-results') {
            setTimeout(function() {
                $('#user-search-results').hide();
            }, 500);
        }
    });
    
    var timeout = null;
    var globalkeyword;
    
    olFriendList.searchUser = function(keyword) {
        var timeoutVal = 500;
        if (keyword == undefined) {
            keyword = $('#user-search-keyword').val();
            timeoutVal = 0;
        }
        if (timeout !== null) {
            globalkeyword = keyword;
            return;
        }
        globalkeyword = keyword;
        timeout = setTimeout(function() {
            if (globalkeyword) {
                $.ajax({
                    type: 'POST',
                    url: "/userrelations/search",
                    data: {
                        keyword: globalkeyword
                    },
                    success: function(data) {
                        $('#user-search-results').html(data).show();
                        //$('#user-search-results').focus();
                    }
                });
            }else{
                $('#user-search-results').empty();
                $('#user-search-results').hide();
            }
            timeout = null;
        }, timeoutVal);
    };

}(window.olFriendList = window.olFriendList || {}, jQuery));

$(function() {
    olFriendList.init();
});