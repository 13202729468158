window.olNavigationModules = window.olNavigationModules || {};

// constructor navigation module: time
window.olNavigationModules.toggle = (function(olNavToggle, $, packets, subcall, undefined) {
    var _initActive = false;
    var _active = false;

    // navigation, dropdowns, inputs, etc.
    var $nav, $toggle, $filter1, $filter2;

    olNavToggle.init = function(config) {
        $nav = $('#navToggle');
        $toggle = $('#buttonToggle-navToggle #filter-navToggle', $nav);
        $filter1 = $('#buttonToggle-navToggle #filter-navToggle-1', $nav);
        $filter2 = $('#buttonToggle-navToggle #filter-navToggle-2', $nav);

        _initActive = (config.active === 1);

        olNavToggle.reset();
    };

    packets.toggle = function() {
        return {toggle: (_active ? 1 : 0)};
    };

    olNavToggle.accessToggle = function() {
        return $toggle;
    };

    olNavToggle.reset = function() {
        $toggle.val(_initActive ? 1 : 2);
        $toggle[0].checked = _initActive;

        if (_initActive) {
            $filter2.hide();
            $filter1.show();
        } else {
            $filter1.hide();
            $filter2.show();
        }

        _active = _initActive;
    };

    olNavToggle.onChangeToggle = function(checked, val) {
        if (typeof checked !== typeof true)
            return;

        _active = checked;
        subcall();
    };
});
