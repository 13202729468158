"use strict";
(function(olSponsorArticles, $, undefined) {

    olSponsorArticles.page = '';



    //--- Beiträge > ---

    olSponsorArticles.initArticles = function() {
    };



}(window.olSponsorArticles = window.olSponsorArticles || {}, jQuery));
