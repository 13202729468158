(function(olTimeCounter, $, undefined)
{
    olTimeCounter.init  = function()
    {
        setInterval(function(){ olTimeCounter._updateTimeCounter() }, 1000);
    };
    
    olTimeCounter._updateTimeCounter = function()
    {
        $('.ol-time-counter').each(function(index, element)
        {
            element = $(element).first();
            //init time counter element if not done yet
            if(!$(element).data('time-counter-init-time'))
            {
                $(element).data('time-counter-init-time', (Date.now() / 1000));
            }
            var initTime = parseInt((element).data('time-counter-init-time'));
            var initRemainingTime = parseInt($(element).attr('time-counter-init-remaining-time'));
            var pastTime = (Date.now() / 1000) - initTime;
            var remainingTime = Math.max(0, initRemainingTime - pastTime);
            
            var valElement = $(element).find('.ol-time-counter-value');
            
            $(element).removeClass('time-counter-unit-days');
            $(element).removeClass('time-counter-unit-day');
            $(element).removeClass('time-counter-unit-minutes');
            $(element).removeClass('time-counter-unit-minute');
            $(element).removeClass('time-counter-unit-hours');
            $(element).removeClass('time-counter-unit-hour');
            $(element).removeClass('time-counter-unit-seconds');
            $(element).removeClass('time-counter-unit-second');
            $(element).removeClass('time-counter-unit-done');
            
            
            
            // days?
            if(remainingTime > (48 * 60 * 60))
            {
                var days = parseInt(remainingTime / (24 * 60 * 60));
                valElement.html(days);
                $(element).addClass('time-counter-unit-days');
            }
            // day
            else if(remainingTime > (24 * 60 * 60))
            {
                var days = parseInt(remainingTime / (24 * 60 * 60));
                valElement.html(days);
                $(element).addClass('time-counter-unit-day');
            }
            // hours
            else if(remainingTime > (2 * 60 * 60))
            {
                var hours = parseInt(remainingTime / (60 * 60));
                valElement.html(hours);
                $(element).addClass('time-counter-unit-hours');
            }
            // hour
            else if(remainingTime > (60 * 60))
            {
                var hours = parseInt(remainingTime / (60 * 60));
                valElement.html(hours);
                $(element).addClass('time-counter-unit-hour');
            }
            // minutes
            else if(remainingTime > (2 * 60))
            {
                var minutes = parseInt(remainingTime / 60);
                valElement.html(minutes);
                $(element).addClass('time-counter-unit-minutes');
            }
            // minute
            else if(remainingTime > (60))
            {
                var minutes = parseInt(remainingTime / 60);
                valElement.html(minutes);
                $(element).addClass('time-counter-unit-minute');
            }
            // seconds
            else if(remainingTime > 0)
            {
                var seconds = parseInt(remainingTime);
                valElement.html(seconds);
                $(element).addClass('time-counter-unit-seconds');
            }
            // done
            else
            {
                valElement.html('');
                $(element).addClass('time-counter-unit-done');
                $(element).removeClass('ol-time-counter');
                $(element).trigger('olCounterDone');
            }
        });
    };
}( window.olTimeCounter = window.olTimeCounter || {}, jQuery ));