$(function() {

    // Cache selectors for performance
    var $body = $('body');
    var $ball = $('.ol-drag-refresh-helper > .ball')
    var $document = $(document);
    var $helperEl = $('.ol-drag-refresh-helper');
    var $html = $('html');

    var mouseY = 0;
    var mouseX = 0;
    var startMouseY = 0;
    var startMouseX = 0;

    $document.on('touchstart', function(ev) {
        var userAgent = window.navigator.userAgent;

        if (olAppInfo.APP_MODE !== true) { 
            if ( !(userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) )  {
                return; // Ignore other systems
            }
        }

        if ($('html').hasClass('no-scroll') || $body.hasClass('no-scroll')) {
            return; // Disable this for overlays etc
        }

        if (getWindowScroll() > 5) {
            return;
        }
 
        startMouseY = ev.originalEvent.touches[0].screenY;
        startMouseX = ev.originalEvent.touches[0].screenX;

        mouseY = startMouseY;
        mouseX = startMouseX;

        startMouseY += $body.scrollTop(); // Android
        startMouseY += $html.scrollTop(); // iOS scrolls html element
        
        // Ignore elements that are scrollable by themselves
        if (olAppInfo.APP_MODE && olAppInfo.APP_PLATFORM == "iphone") {
            if ( ev.target.scrollWidth > ev.target.clientWidth || ev.target.scrollHeight > ev.target.clientHeight ) return true;
        } else {
            if ( !($(ev.target).scrollParent().is('html, body') || $(ev.target).scrollParent().is(document)) ) return true;
            if ( ev.target.scrollWidth > ev.target.clientWidth || ev.target.scrollHeight > ev.target.clientHeight ) return true;
        }
        var dragActive = false;

        $document.on('touchmove', function(ev) {
            mouseY = ev.originalEvent.touches[0].screenY;
            mouseX = ev.originalEvent.touches[0].screenX;

            var distance = mouseY - startMouseY;

            if (distance < (mouseX - startMouseX)) distance = 0;
            
            if (distance > 0 && !dragActive) {
                $('body, html').addClass('ol-drag-refresh-noscroll');
                dragActive = true;
            } else if (distance <= 0 && dragActive) {
                $('body, html').removeClass('ol-drag-refresh-noscroll');
                dragActive = false;
            } else if (!dragActive) {
                return;
            }

            distance = Math.min(100, distance);
            distance = Math.max(0, distance);

            $body.css('margin-top', distance + 'px')
            $helperEl.css('height', distance + 'px');
            //$ball.css('transform', 'rotate(' + -2*distance + 'deg)');
        });
    });

    $document.on('touchend', function(ev) {
        var distance = mouseY - startMouseY;
        
        if ((distance < (mouseX - startMouseX)) && (distance < 20)) distance = 0;

        $('body, html').removeClass('ol-drag-refresh-noscroll');
        $body.css('margin-top', '');

        if (distance >= 100) {
            $body.addClass('ol-drag-refresh-loading');
            $ball.css('transform', '');

            olAnchorNavigation.reload(function() {
                setTimeout(function() {
                    $body.removeClass('ol-drag-refresh-loading');
                    $helperEl.css('height', '');
                }, 200);
            });
        } else {
            $helperEl.css('height', '');
        }

        $document.unbind("touchmove");
    });

    $(document).on('mouseleave', function() {
        $body.css('margin-top', '');
        $document.unbind("touchmove");
        $('body, html').removeClass('ol-drag-refresh-noscroll');
    });


});