(function($) {
    $.ol = $.ol || {};
    $.ol.leagueNavigation = (function() {
        var previousSelections = {};
        var callbacks;
        var selection;
        var currentMatchday;
        var currentSeason;
        var currentSeasonUserLeagueId = false;
        var disableRelegation = false;
        var userLoggedIn = false;
        var seasonAndMatchday = true;
        var navId = false;
        var matchdayTableStatistics = true;
        var week = false;
        var autoLoading = false;
        var hasUserLeague = false;
        var navInited = {};
        var m34 = 34;

        var enableRelegation = function(enable) {
            disableRelegation = !enable;
            updateMatchdays(false);
        }

        function resizeHandler() {
            if ($('body').hasClass('ol-xs')) {
                $("#leagueNavWrapper-" + navId).css('height', '');
                return;
            }
        }

        var init = function(config) {
            if (navId) {
                previousSelections[navId] = selection;
            }
            navId = config.navId;

            navInited[navId] = true;
            matchdayTableStatistics = config.matchdayTableStatistics;
            seasonAndMatchday = config.seasonAndMatchday;
            currentSeasonUserLeagueId = config.selection.seasonUserLeagueId;
            $(window).unbind('resize', resizeHandler);
            if (matchdayTableStatistics) {
                $(window).bind('resize', resizeHandler);
                resizeHandler();
            }

            callbacks = [];
            selection = (config.useLastSelection && previousSelections[navId])? previousSelections[navId] : config.selection;
            currentMatchday = config.currentMatchday;
            currentSeason = config.currentSeason;
            currentWeek = config.currentWeek;
            userLoggedIn = config.userLoggedIn;
            autoLoading = (config.autoLoading === undefined? true : config.autoLoading);
            showDropdowns();
            $("#dropdown-matchday-table-1-" + navId).dropdown().selectByClass("selected");
            $("#dropdown-matchday-table-2-" + navId).dropdown().selectByClass("selected");
            $("#dropdown-matchday-table-3-" + navId).dropdown().selectByClass("selected");
            if (config.useLastSelection && selection.leagueId >= 0) {
                if (selection.leagueLevel < 5) {
                    $("#dropdown-matchday-table-1-" + navId).dropdown().selectByValue(selection.leagueId);
                } else if ((selection.leagueLevel === 5) && (selection.stateId >= 0)) {
                    $("#dropdown-matchday-table-1-" + navId).dropdown().selectByValue(selection.stateId);
                    $("#dropdown-matchday-table-2-" + navId).dropdown().selectByValue(selection.leagueId);
                } else if ((selection.leagueLevel > 5) && (selection.stateId >= 0) && (selection.districtId >= 0)) {
                    $("#dropdown-matchday-table-1-" + navId).dropdown().selectByValue(selection.stateId);
                    $("#dropdown-matchday-table-2-" + navId).dropdown().selectByValue(selection.districtId);
                    $("#dropdown-matchday-table-3-" + navId).dropdown().selectByValue(selection.leagueId);
                }
            }
            if (seasonAndMatchday) {
                $("#dropdown-matchday-table-matchday-" + navId).dropdown().selectByValue(selection.matchday);
                $("#dropdown-matchday-table-season-" + navId).dropdown().selectByValue(selection.season);
            }
            $(function() {
                updateUserLeague();
            });
        };

        var getSelection = function() {
            return selection;
        }

        var addCallback = function(clbk) {
            callbacks.push(clbk);
        };

        function showDropdowns() {
            selection.leagueLevel = parseInt(selection.leagueLevel);
            switch(selection.leagueLevel) {
                case 1:
                    $("#dropdown-matchday-table-2-" + navId).hide();
                    $("#dropdown-matchday-table-3-" + navId).hide();
                    $("#dropdown-matchday-table-1-" + navId).hide();
                    break;

                case 2:
                case 3:
                case 4:
                    $("#dropdown-matchday-table-2-" + navId).hide();
                    $("#dropdown-matchday-table-3-" + navId).hide();
                    $("#dropdown-matchday-table-1-" + navId).show();
                    $("#dropdown-matchday-table-1-" + navId).attr("data-default", $("#langSelectLeague-" + navId).html());
                    break;

                case 5:
                    $("#dropdown-matchday-table-3-" + navId).hide();
                    $("#dropdown-matchday-table-2-" + navId).show();
                    $("#dropdown-matchday-table-1-" + navId).show();
                    $("#dropdown-matchday-table-1-" + navId).attr("data-default", $("#langSelectState-" + navId).html());
                    $("#dropdown-matchday-table-2-" + navId).attr("data-default", $("#langSelectLeague-" + navId).html());
                    break;

                case 6:
                default:
                    $("#dropdown-matchday-table-1-" + navId).show();
                    $("#dropdown-matchday-table-2-" + navId).show();
                    $("#dropdown-matchday-table-3-" + navId).show();
                    $("#dropdown-matchday-table-1-" + navId).attr("data-default", $("#langSelectState-" + navId).html());
                    $("#dropdown-matchday-table-2-" + navId).attr("data-default", $("#langSelectDistrict-" + navId).html());
                    $("#dropdown-matchday-table-3-" + navId).attr("data-default", $("#langSelectLeague-" + navId).html());
                    break;
            }
        }

        var onChangeDropdownLeagueLevel = function(level, callback) {
            if (typeof callback === "undefined")
                callback = false;

            selection.leagueLevel = parseInt(level);
            updateDropdowns(callback);
        };

        var updateDropdownLeagueLevel = function() {
            $anchor = $('#leagueLevelDropdown');
            addLoadingAnimationToElement($anchor, {size: 'small'});
            var data = {
                navId: navId, 
                season: selection.season, 
                leagueLevel: selection.leagueLevel
            };
            $.get('/matchdaytable/leaguelevel', data, function(data) {
                //$anchor.html(data);
                $anchor.html(data.html);
                removeLoadingAnimationFromElement($anchor);
                if (selection.leagueLevel > data.maxLevel) {
                    $("#dropdown-matchday-table-league-level-matchdayTable").dropdown().selectByValue(data.maxLevel);
                    onChangeDropdownLeagueLevel(data.maxLevel);
                }
            }).fail(function() {
                removeLoadingAnimationFromElement($anchor);
                console.log("failed to update league level dropdown");
            });
        }

        function updateUserLeague(callback) {
            if (typeof callback === typeof undefined) callback = false;
            if (!userLoggedIn) {
                if (callback !== false) callback();
                return;
            }
            $.getJSON("/leaguenav/userleague", {navId: navId, season: selection.season, matchday: selection.matchday, leagueLevel: selection.leagueLevel}, function(data) {
                hasUserLeague = data.hasLeague;
                if (data.hasLeague) {
                    currentSeasonUserLeagueId = data.leagueId;
                    if (selection.leagueId != currentSeasonUserLeagueId)
                        $("#btnSwitchToUserLeague-" + navId).fadeIn(500);
                    else
                        $("#btnSwitchToUserLeague-" + navId).fadeOut(500);
                    if (callback) callback(data);
                } else {
                    $("#btnSwitchToUserLeague-" + navId).fadeOut(500);
                    currentSeasonUserLeagueId = false;
                    if(callback) callback(data);
                }
            });
        }

        var onChangeDropdownSeason = function(season, callback) {
            if (typeof callback === "undefined" || callback == null)
                callback = false;
            if (selection.season == season) return;

            $("#btnSwitchToUserLeague-" + navId).fadeOut(500);
            hasUserLeague = false;
            var leagueElement = false;
            if (selection.leagueLevel >= 6) {
                leagueElement = $("#dropdown-matchday-table-3-" + navId);
            } else if(selection.leagueLevel === 5) {
                leagueElement = $("#dropdown-matchday-table-2-" + navId);
            } else {
                leagueElement = $("#dropdown-matchday-table-1-" + navId);
            }

            var isUserLeague = false;
            if (leagueElement != false) {
                isUserLeague = (leagueElement.dropdown().selectedElement().attr("data-auth-user") == 1 ? true : false);
            }

            selection.season = parseInt(season);
            var clbk = false;

            if (selection.matchday > m34) { 
                if (selection.season > currentSeason) {
                    selection.matchday = 1;
                } else if (selection.season == currentSeason && currentMatchday < m34) {
                    selection.matchday = m34;
                }
            }

            if (!isUserLeague) {
                clbk = function() {
                    if (userLoggedIn) {
                        updateUserLeague();
                    }
                    if(callback) callback();
                }
                $("#dropdown-matchday-table-matchday-" + navId).dropdown().selectByValue(selection.matchday);
                updateDropdowns(clbk);
            } else {
                clbk = function() {};
                if (userLoggedIn) {
                    updateUserLeague(function(data) {
                        if (data.hasLeague) {
                            switchToUserLeague();
                        } else {
                            $("#dropdown-matchday-table-matchday-" + navId).dropdown().selectByValue(selection.matchday);
                            updateDropdowns(clbk);
                        }
                    });
                } else {
                    $("#dropdown-matchday-table-matchday-" + navId).dropdown().selectByValue(selection.matchday);
                    updateDropdowns(clbk);
                }
            }

            updateDropdownLeagueLevel();
        };

        function updateDropdowns(callback) {
            if (typeof callback === "undefined" || callback === null)
                callback = false;

            $("#dropdown-matchday-table-2-" + navId).show();
            $("#dropdown-matchday-table-3-" + navId).show();
            $("#dropdown-matchday-table-1-" + navId).show();

            disableAllDropdowns();

            clbk = function() {
                if (callback) callback();
                enableAllDropdowns();
            }

            switch(selection.leagueLevel) {
                case 1:
                    $("#dropdown-matchday-table-2-" + navId).hide();
                    $("#dropdown-matchday-table-3-" + navId).hide();
                    $("#dropdown-matchday-table-1-" + navId).hide();
                    updateStateBG();
                    loadLeagues(clbk);
                    break;
                case 2:
                case 3:
                    updateStateBG();
                case 4:
                    $("#dropdown-matchday-table-3-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-3-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-1-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-2-" + navId).hide();
                    $("#dropdown-matchday-table-3-" + navId).hide();
                    $("#dropdown-matchday-table-1-" + navId).attr("data-default", $("#langSelectLeague-" + navId).html());
                    loadLeagues(clbk);
                    break;
                case 5:
                    $("#dropdown-matchday-table-3-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-2-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-1-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-3-" + navId).hide();
                    $("#dropdown-matchday-table-2-" + navId).attr("data-default", $("#langSelectLeague-" + navId).html());
                    $("#dropdown-matchday-table-1-" + navId).attr("data-default", $("#langSelectState-" + navId).html());
                    loadStates(clbk);
                    break;
                case 6:
                    $("#dropdown-matchday-table-1-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-3-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-2-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-1-" + navId).attr("data-default", $("#langSelectState-" + navId).html());
                    $("#dropdown-matchday-table-2-" + navId).attr("data-default", $("#langSelectDistrict-" + navId).html());
                    $("#dropdown-matchday-table-3-" + navId).attr("data-default", $("#langSelectLeague-" + navId).html());
                    loadStates(clbk);
                    break;
                default:
                    $("#dropdown-matchday-table-1-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-3-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-2-" + navId).dropdown().clear();
                    $("#dropdown-matchday-table-1-" + navId).attr("data-default", $("#langSelectState-" + navId).html());
                    $("#dropdown-matchday-table-2-" + navId).attr("data-default", $("#langSelectDistrict-" + navId).html());
                    $("#dropdown-matchday-table-3-" + navId).attr("data-default", $("#langSelectLeague-" + navId).html());
                    loadStates(clbk);
            }
        }

        function enableAllDropdowns() {
            $("#dropdown-matchday-table-league-level-" + navId).dropdown().enable();
            $("#dropdown-matchday-table-1-" + navId).dropdown().enable();
            $("#dropdown-matchday-table-3-" + navId).dropdown().enable();
            $("#dropdown-matchday-table-2-" + navId).dropdown().enable();
            $("#dropdown-matchday-table-matchday-" + navId).dropdown().enable();
            $("#dropdown-matchday-table-season-" + navId).dropdown().enable();
        }

        function disableAllDropdowns() {
            $("#dropdown-matchday-table-league-level-" + navId).dropdown().disable();
            $("#dropdown-matchday-table-1-" + navId).dropdown().disable();
            $("#dropdown-matchday-table-3-" + navId).dropdown().disable();
            $("#dropdown-matchday-table-2-" + navId).dropdown().disable();
            $("#dropdown-matchday-table-matchday-" + navId).dropdown().disable();
            $("#dropdown-matchday-table-season-" + navId).dropdown().disable();
        }

        var onChangeDropdownState = function(sId, callback) {
            if (typeof callback === "undefined" || callback === null)
                callback = false;

            if (sId == -1) {
                sId = $("#dropdown-matchday-table-1-" + navId).dropdown().valueByIndex(1);
            }
            if (sId === "undefined") {
                $("#dropdown-matchday-table-2-" + navId).hide();
                $("#dropdown-matchday-table-3-" + navId).hide();
                if (callback) callback();
                return;
            }
            selection.stateId = sId;
            $("#dropdown-matchday-table-1-" + navId).dropdown().selectByValue(sId);

            updateStateBG(sId);

            disableAllDropdowns();

            clbk = function() {
                if(callback) callback();
                enableAllDropdowns();
            }


            if (selection.leagueLevel >= 6) {
                loadDistricts(clbk);
            } else {
                loadLeagues(clbk);
            }
        };

        function loadStates(callback) {
            if (typeof callback === "undefined" || callback === null)
                callback = false;

            data = {
                season: selection.season,
                matchday: selection.matchday,
                leagueLevel: selection.leagueLevel,
                navId: navId
            };

            $("#dropdown-matchday-table-1-" + navId).dropdown().loadElements("/leaguenav/states", data, function(hasElements) {
                if (hasElements) {
                    if ($("#dropdown-matchday-table-1-" + navId).dropdown().selectByValue(selection.stateId)) {
                    } else if($("#dropdown-matchday-table-1-" + navId).dropdown().selectByValue(ol.userLocation.stateId)) {
                        selection.stateId = ol.userLocation.stateId;
                    } else {
                        selection.stateId = -1;
                    }
                    onChangeDropdownState(selection.stateId, callback);
                } else {
                    $("#dropdown-matchday-table-2-" + navId).hide();
                    $("#dropdown-matchday-table-3-" + navId).hide();
                    if(callback) callback();
                }
            });
        }

        function loadDistricts(callback) {
            if (typeof callback === "undefined" || callback === null)
                callback = false;

            data = {
                season: selection.season,
                matchday: selection.matchday,
                leagueLevel: selection.leagueLevel,
                stateId: selection.stateId,
                navId: navId
            };
            $("#dropdown-matchday-table-2-" + navId).dropdown().loadElements("/leaguenav/districts", data, function(hasElements) {
                if (hasElements) {
                    if ($("#dropdown-matchday-table-2-" + navId).dropdown().selectByValue(selection.districtId)) {
                    } else if($("#dropdown-matchday-table-2-" + navId).dropdown().selectByValue(ol.userLocation.districtId)) {
                        selection.districtId = ol.userLocation.districtId;
                    } else {
                        selection.districtId = -1;
                    }
                    onChangeDropdownDistrict(selection.districtId, callback);
                } else {
                    $("#dropdown-matchday-table-3-" + navId).hide();
                    if(callback) callback();
                }
            });
        }

        function loadLeagues(callback) {
            if (typeof callback === "undefined" || callback === null)
                callback = false;

            var leagueElement;

            if (selection.leagueLevel >= 6) {
                leagueElement = $("#dropdown-matchday-table-3-" + navId);
            } else if (selection.leagueLevel == 5) {
                leagueElement = $("#dropdown-matchday-table-2-" + navId);
            } else {
                leagueElement = $("#dropdown-matchday-table-1-" + navId);
            }

            data = {
                season: selection.season,
                matchday: selection.matchday,
                leagueLevel: selection.leagueLevel,
                stateId: selection.stateId,
                districtId: selection.districtId,
                navId: navId
            };
            leagueElement.dropdown().loadElements("/leaguenav/leagues", data, function(hasElements) {
                if (hasElements) {
                    if (leagueElement.dropdown().selectByValue(selection.leagueId)) {
                    } else if(leagueElement.dropdown().selectByValue(ol.userLocation.leagueId)) {
                        selection.leagueId = ol.userLocation.leagueId;
                    } else {
                        selection.leagueId = -1;
                    }
                    if (autoLoading || data.leagueLevel == 1)
                        onChangeDropdownLeague(selection.leagueId, callback);
                    else if (callback) {
                        callback();
                    }
                } else {
                    onChangeDropdownLeague(false, callback);
                    if (callback) callback();
                }
            });
        }

        var onChangeDropdownDistrict = function(dId, callback) {
            if (typeof callback === "undefined")
                callback = false;

            if (dId == -1) {
               dId = $("#dropdown-matchday-table-2-" + navId).dropdown().valueByIndex(1);
            }
            if (dId === "undefined") {
                $("#dropdown-matchday-table-3-" + navId).hide();
                if (callback) callback();
                return;
            }
            $("#dropdown-matchday-table-2-" + navId).dropdown().selectByValue(dId);

            disableAllDropdowns();

            clbk = function() {
                if (callback) callback();
                enableAllDropdowns();
            }

            selection.districtId = dId;
            data = {
                season: selection.season,
                matchday: selection.matchday,
                leagueLevel: selection.leagueLevel,
                stateId: selection.stateId,
                districtId: selection.districtId,
                navId: navId
            };
            $("#dropdown-matchday-table-3-" + navId).dropdown().loadElements("/leaguenav/leagues", data, function(hasElements) {
                loadLeagues(clbk);
            });
        };

        var onChangeDropdownLeague = function(lId, callback) {
            if (typeof callback === "undefined" || callback == null)
                callback = false;

            var dropdownElement;
            if (selection.leagueLevel >= 6) {
                dropdownElement = $("#dropdown-matchday-table-3-" + navId).dropdown();
            } else if (selection.leagueLevel == 5) {
                dropdownElement = $("#dropdown-matchday-table-2-" + navId).dropdown();
            } else {
                dropdownElement = $("#dropdown-matchday-table-1-" + navId).dropdown();
            }

            if (lId == -1)
               lId = dropdownElement.valueByIndex(1);

            if (lId !== false) {
                dropdownElement.selectByValue(lId);
                selection.leagueName = dropdownElement.getText();

                var leagueMatchday = 0;
                leagueMatchday = dropdownElement.elementByValue(lId).attr("data-matchday");
                leagueMatchday = parseInt(leagueMatchday);
                selection.leagueMatchday = leagueMatchday;
            } else {
                selection.leagueName = "";
                selection.leagueMatchday = 0;
            }

            selection.leagueId = lId;

            if (userLoggedIn && hasUserLeague && selection.leagueId != currentSeasonUserLeagueId) {
                $("#btnSwitchToUserLeague-" + navId).fadeIn(500);
            } else {
                $("#btnSwitchToUserLeague-" + navId).fadeOut(500);
            }

            if (matchdayTableStatistics && selection.leagueLevel == 4) {
                updateStateBG($("#dropdown-matchday-table-1-" + navId).dropdown().selectedElement().attr("data-state-id"));
            }
            updateMatchdays();
            disableAllDropdowns();

            if (callback) callback();
            enableAllDropdowns();
            triggerCallbacks();
        };

        var switchToUserLeague = function() {
            $.getJSON("/leaguenav/userleague", {navId: navId, season: selection.season, matchday: selection.matchday, leagueLevel: selection.leagueLevel }, function(data) {
                selection.districtId = data.districtId;
                selection.stateId = data.stateId;
                selection.leagueId = data.leagueId;
                currentSeasonUserLeagueId = data.leagueId;
                $("#dropdown-matchday-table-league-level-" + navId).dropdown().selectByValue(data.level);
                onChangeDropdownLeagueLevel(data.level);
            });
        };

        var switchToLeague = function(leagueId, season, matchday) {
            if (typeof matchday === typeof undefined) {
                matchday = (season < currentSeason)? m34 : currentMatchday;
            }

            $.getJSON("/leaguenav/league", {leagueId: leagueId, season: season, matchday: matchday }, function(data) {
                selection.districtId = data.districtId;
                selection.stateId = data.stateId;
                selection.leagueId = data.leagueId;
                $("#dropdown-matchday-table-league-level-" + navId).dropdown().selectByValue(data.level);
                onChangeDropdownLeagueLevel(data.level);
            });
        }

        function updateMatchdays(resetMatchday) {
            if (typeof resetMatchday === "undefined")
                resetMatchday = true;
            var dropdownElement;
            if (selection.leagueLevel >= 6) {
                dropdownElement = $("#dropdown-matchday-table-3-" + navId).dropdown();
            } else if (selection.leagueLevel == 5) {
                dropdownElement = $("#dropdown-matchday-table-2-" + navId).dropdown();
            } else {
                dropdownElement = $("#dropdown-matchday-table-1-" + navId).dropdown();
            }
            lId = dropdownElement.valueByIndex(1);
            var leagueMatchday = 0;
            leagueMatchday = dropdownElement.elementByValue(lId).attr("data-matchday");
            leagueMatchday = parseInt(leagueMatchday);
            langMatchday = $("#langMatchday-"+navId).html();
            for (i = 1;  i <= m34;  i++) {
                //var mark = false;
                //if (i == leagueMatchday)
                //    mark = true;
                //$("#matchday" + i + "-" + navId).find("a").text(langMatchday + " " + i + (mark && i > 1 && selection.leagueLevel == 6 ? " *" : ""));
                $("#matchday"+i+"-"+navId).find("a").text(langMatchday+" "+i);
            }

            if (resetMatchday && selection.season == currentSeason)
                selection.matchday = Math.min(selection.matchday, currentMatchday);

            if (disableRelegation)
                selection.matchday = Math.min(m34, selection.matchday);

            if (selection.season > currentSeason) {
                selection.matchday = 1;
            }

            var show35 = (selection.season < currentSeason) 
                      || (selection.season == currentSeason && currentMatchday > m34);
            if (!disableRelegation && show35) {
                $("#matchday35-"+navId).show();
            } else {
                $("#matchday35-"+navId).hide();
            }

            $("#dropdown-matchday-table-matchday-" + navId).dropdown().selectByValue(selection.matchday);
        }

        function triggerCallbacks() {
            $.each(callbacks, function(index, callback) {
               callback(selection);
            });
        }

        var onChangeDropdownMatchday = function(m) {
            if (m == selection.matchday) return;
            selection.matchday = m;
            triggerCallbacks();
        };

        var onChangeMatchdayUp = function() {
            if (selection.matchday > 1) {
                --selection.matchday;
                $("#dropdown-matchday-table-matchday-" + navId).dropdown().up();
                triggerCallbacks();
            }
        };

        var onChangeMatchdayDown = function() {
            if (selection.matchday < m34 
             || ((selection.season == currentSeason && currentMatchday == m34+1) 
             || selection.season < currentSeason)) {
                ++selection.matchday;
                $("#dropdown-matchday-table-matchday-" + navId).dropdown().down();
                triggerCallbacks();
            }
        };

        function updateStateBG(sId) {
            if (matchdayTableStatistics == false) return;
            var clone, parent;
            $("#ol-bg-pattern-" + navId).each(function(index, element) {
                if ($(element).css("display") != "none") {
                    parent = element;
                    clone = $(element.cloneNode(true));
                    clone.prop('id', "temp_clone");
                    clone.first().prop('id', "child_temp_clone");
                    clone.css("z-index", 1);
                }
            });

            $("#ol-bg-pattern-" + navId).removeClass(function(index, css) {
                return (css.match (/(^|\s)ol-state-bg-color-\S+/g) || []).join(' ');
            });

            $("#ol-bg-pattern-img-" + navId).removeClass(function(index, css) {
                return (css.match (/(^|\s)ol-state-bg-\S+/g) || []).join(' ');
            });

            if (selection.leagueLevel == 1) {
                $("#ol-bg-pattern-" + navId).addClass("ol-state-bg-color-gold");
                $("#ol-bg-pattern-img-" + navId).addClass("ol-state-bg-gold");
            } else if (selection.leagueLevel == 2) {
                $("#ol-bg-pattern-" + navId).addClass("ol-state-bg-color-silver");
                $("#ol-bg-pattern-img-" + navId).addClass("ol-state-bg-silver");
            } else if(selection.leagueLevel == 3) {
                $("#ol-bg-pattern-" + navId).addClass("ol-state-bg-color-bronz");
                $("#ol-bg-pattern-img-" + navId).addClass("ol-state-bg-bronz");
            } else {
                if (typeof sId === typeof undefined) sId = 0;
                $("#ol-bg-pattern-" + navId).addClass("ol-state-bg-color-" + sId);
                $("#ol-bg-pattern-img-" + navId).addClass("ol-state-bg-" + sId);
            }

            clone.insertAfter($(parent));
            clone.fadeOut(1000, function() {
                $(this).remove();
            });
        }

        return {
            init: init,
            addCallback: addCallback,
            onChangeDropdownLeagueLevel: onChangeDropdownLeagueLevel,
            onChangeDropdownSeason: onChangeDropdownSeason,
            onChangeDropdownState: onChangeDropdownState,
            onChangeDropdownDistrict: onChangeDropdownDistrict,
            onChangeDropdownLeague: onChangeDropdownLeague,
            onChangeDropdownMatchday: onChangeDropdownMatchday,
            onChangeMatchdayUp: onChangeMatchdayUp,
            onChangeMatchdayDown: onChangeMatchdayDown,
            getSelection: getSelection,
            enableRelegation: enableRelegation,
            switchToUserLeague: switchToUserLeague,
            switchToLeague : switchToLeague,
            navInited : navInited
        };
    })();
})(jQuery);