(function(olNews, $, undefined) {
    var countLocalNews = 0;
    var countGlobalNews = 0;
    var setLocations;
    var setWeek;
    var setSeason;
    var setSeed;
    var setPromotion;
    var gotIds;

    olNews.init = function(locations, week, season, seed, promotion) {
        countLocalNews = 0;
        countGlobalNews = 0;
        setLocations = locations;
        setWeek = week;
        setSeason = season;
        setSeed = seed;
        setPromotion = (promotion === true) ? 1 : 0;
        gotIds = {};
    };

    olNews.loadCommentNews = function(amount) {
        if (amount <= 0)
            return;

        var tmpAmount = countLocalNews + amount;
        var tmpCount = 10 - countLocalNews;

        if (countLocalNews < 10)
            _loadLocalNews(Math.min(tmpCount, amount), countLocalNews);
        if (tmpAmount > 10)
            _loadGlobalNews(amount - tmpCount, countGlobalNews);
    };

    function _loadLocalNews(amount, offset) {
        if ((countLocalNews + amount) > 10) {
            amount = 10 - countLocalNews;
            offset = countLocalNews;
        }

        var data = {
            'amount': amount,
            'offset': offset,
            'seed': setSeed,
            'season': setSeason,
            'week': setWeek,
            'location': $.extend({'target': 'local'}, setLocations),
            'exclude': gotIds,
            'sponsor': 0
        };

        clbk = function() {
            lazyLoader.loadNews(true);
            countLocalNews += amount;

            _cacheNewsIds("#ol-comment-group-news .ol-news-list-element[data-id]");

            if (countLocalNews >= 10) {
                $("#ol-comment-group-news-btn").addClass('hidden');

                var elem = $('#ol-comment-regio-news-sponsor');
                lazyLoader.loadPageContent('/sponsoring/banner', elem, elem.data(), true, function() {
                    elem.removeClass('hidden');
                    $("#ol-comment-regio-news-btn").removeClass('hidden');
                    olNews.selfMeasureCommentsToLoad();
                });
            } else {
                olNews.selfMeasureCommentsToLoad();
            }

        };

        lazyLoader.loadPageContent('/news/list', $('#ol-comment-group-news'), data, true, clbk);
    }

    function _loadGlobalNews(amount, offset) {
        var data = {
            'amount': amount,
            'offset': offset,
            'seed': setSeed,
            'season': setSeason,
            'week': setWeek,
            'location': $.extend({'target': 'global'}, setLocations),
            'exclude': gotIds,
            'self': setPromotion,
            'sponsor': 1
        };

        clbk = function() {
            lazyLoader.loadNews(true);
            countGlobalNews += amount;

            _cacheNewsIds("#ol-comment-regio-news .ol-news-list-element[data-id]");

            olNews.selfMeasureCommentsToLoad();
        };

        var list = $('#ol-comment-regio-news');
        lazyLoader.loadPageContent('/news/list', list, $.extend(list.data(), data), true, clbk);
    }

    function _cacheNewsIds(news) {
        var $news = $(news);
        $news.each(function() {
            var season = $(this).data("season");
            if (!gotIds.hasOwnProperty(season))
                gotIds[season] = [];
            gotIds[season].push($(this).data("id"));
        });
        $news.removeAttr("data-id");
        $news.removeAttr("data-season");
    }

    olNews.selfMeasureNewsToLoad = function() {
        olNews.measureNewsToLoad(olMaths.calcHeightDiff('#comment-wrapper', '#news-wrapper'));
    };

    olNews.measureNewsToLoad = function(size) {
        var bootSize = olGUI.getBootstrapDeviceSize();
        if (bootSize === 'ol-xs' || bootSize === 'ol-sm')
            return;
        if (size <= 0)
            return;

        var count = countLocalNews + countGlobalNews;
        var amount = 0;

        while (size > 154) {
            amount++;
            size -= 154;
            if (((count + amount) % 10) === 0) {
                size -= 327;
                if (size < 0) {
                    amount--;
                }
            }
        }

        olNews.loadCommentNews(amount);
    };

    olNews.selfMeasureCommentsToLoad = function() {
        var bootSize = olGUI.getBootstrapDeviceSize();
        if (bootSize === 'ol-xs' || bootSize === 'ol-sm')
            return;
        if (olMaths.calcHeightDiff('#comment-wrapper', '#news-wrapper') < 0) {
            var $commentWidget = $('.comment_widget');
            var $commentData = $commentWidget.find('.comment-widget-data');
            var uniqueId = $commentData.data('uniqueId');
            var hasNext = $commentData.data('hasNext');
            var hasMore = $commentData.data('hasMore');

            if (hasNext) {
                olComment.onClickCommentShowPage($commentWidget, uniqueId, 0, 'f', uniqueId, olNews.selfMeasureCommentsToLoad);
            } else if (hasMore) {
                olComment.onClickCommentShowPage($commentWidget, uniqueId, 0, 'b', uniqueId, olNews.selfMeasureCommentsToLoad);
            }
        }
    };

    var realNewsInitiated = false;
    olNews.initRealNewsFrame = function() {
        if (realNewsInitiated) return;

        realNewsInitiated = true;
        window.addEventListener('message', function(event) {
            if (event.data.indexOf('resize::') == 0) {
                console.log(event.data);
                var height = parseInt(event.data.replace('resize::', ''));

                $('#real-news-iframe').css('height', height);
            }
        });
    }
}(window.olNews = window.olNews || {}, jQuery));
