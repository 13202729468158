"use strict";
(function(olSponsorStatistics, $, undefined) {

    olSponsorStatistics.page = '';



    //--- Statistiken >  ---

    olSponsorStatistics.initStatistics = function() {
    };

}(window.olSponsorStatistics = window.olSponsorStatistics || {}, jQuery));
