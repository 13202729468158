(function(olEffects, $, undefined) {
    var imageStateBG;
    
    olEffects.blendForgroundToStateBackground = function(target, stateId, direction, parent) {
        if (typeof target === typeof undefined || target === false)
            return;
        if (typeof stateId === typeof undefined || stateId === false)
            return;
        if (typeof direction === typeof undefined || direction === false)
            direction = "to left";
        if (typeof parent === typeof undefined || parent === false)
            parent = target;

        parent = $(parent);
        var parWidth = parent.width();
        var parTop = parent.offset().top;
        var parLeft = parent.offset().left;

        target = $(target);
        var width = target.width();
        var height = target.height();
        var top = target.offset().top;
        var left = target.offset().left;

        var canvas = $(document.createElement('canvas'));
        canvas.addClass('ol-state-overlay-canvas');
        canvas.appendTo(target);
        canvas[0].width = width;
        canvas[0].height = height;

        var ctx = canvas[0].getContext('2d');
        imageStateBG = new Image();
        
        imageStateBG.onload = function() {
            _blend(imageStateBG, ctx, left, top, width, height, parLeft, parTop, parWidth, direction, stateId);
        };

        var stateBgPath;
        if ($('body').hasClass('ol-locale-de')) {
            stateBgPath = 'imgs/ol_state_bg_';
        } else if ($('body').hasClass('ol-locale-at')) {
            stateBgPath = 'imgs/state_bg/at/ol_state_bg_';
        } else if ($('body').hasClass('ol-locale-ch')) {
            stateBgPath = 'imgs/state_bg/ch/ol_state_bg_';
        } else if ($('body').hasClass('ol-locale-gb')) {
            stateBgPath = 'imgs/state_bg/gb/ol_state_bg_';
        } else if ($('body').hasClass('ol-locale-us')) {
            stateBgPath = 'imgs/state_bg/us/ol_state_bg_';
        } else {
            stateBgPath = 'imgs/ol_state_bg_';
        }

        imageStateBG.src = olConfig.image_host + stateBgPath + stateId + '.png';
    };

    olEffects.blendOnResize = function(target, stateId, direction, parent) {
        if (typeof target === typeof undefined || target === false)
            return;
        if (typeof stateId === typeof undefined || stateId === false)
            return;
        if (typeof direction === typeof undefined || direction === false)
            direction = "to left";
        if (typeof parent === typeof undefined || parent === false)
            parent = target;

        parent = $(parent);
        var parWidth = parent.width();
        var offset = parent.offset();
        if (!offset) return;
        var parTop = parent.offset().top;
        var parLeft = parent.offset().left;

        target = $(target);
        var width = target.width();
        var height = target.height();
        var top = target.offset().top;
        var left = target.offset().left;

        var canvas = $(target.children('canvas').first());

        var ctx = canvas[0].getContext('2d');

        _blend(imageStateBG, ctx, left, top, width, height, parLeft, parTop, parWidth, direction, stateId);
    };

    function _blend(image, ctx, left, top, width, height, parLeft, parTop, parWidth, direction, stateId) {
        var farthestCorner = Math.sqrt(Math.pow(parWidth / 2, 2) + Math.pow(250, 2));
        var radGradient = ctx.createRadialGradient(parWidth / 2, 0, 0, parWidth / 2, 0, farthestCorner);
        radGradient.addColorStop(0.0, getStateColor(stateId, true));
        radGradient.addColorStop(1.0, getStateColor(stateId, false));

        var linGradient = ctx.createLinearGradient(0, 0, width, 0);
        if (direction === "to right") {
            linGradient.addColorStop(0.0, 'rgba(0,0,0,1)');
            linGradient.addColorStop(0.5, 'rgba(0,0,0,0)');
        } else if (direction === "to left") {
            linGradient.addColorStop(0.5, 'rgba(0,0,0,0)');
            linGradient.addColorStop(1.0, 'rgba(0,0,0,1)');
        }

        ctx.globalCompositeOperation = 'source-over';
        ctx.fillStyle = radGradient;
        ctx.translate(-(left - parLeft), -(top - parTop));
        ctx.fillRect(left - parLeft, top - parTop, width, height);
        ctx.translate((left - parLeft), (top - parTop));

        ctx.drawImage(image, (left - parLeft) % 960 + 0.5, (top - parTop) % 960, width, height, 0, 0, width, height);

        ctx.globalCompositeOperation = 'destination-out';
        ctx.fillStyle = linGradient;
        ctx.fillRect(0, 0, width, height);
    }
}(window.olEffects = window.olEffects || {}, jQuery));
