(function(olI18n, $, undefined) {
    var translations;

    olI18n.init = function(json) {
        translations = json;
    };

    olI18n.trans = function(key, replace) {
        if (typeof replace === typeof undefined)
            replace = {};

        var trans = key.split('.').reduce(function(t, i) {
            return t[i] || null;
        }, translations);

        for (var placeholder in replace) {
            trans = trans.replace(new RegExp(":" + placeholder, "gi"), replace[placeholder]);
        }

        return trans;
    };

    olI18n.trans_choice = function(key, count, replace) {
        if (typeof count === typeof undefined)
            count = 0;
        if (typeof replace === typeof undefined)
            replace = {};
        count = parseInt(count);

        function _choice(t, v, count) {
            var idx = v.indexOf(' ');
            var range = v.substr(0, idx);
            var value = v.substr(idx + 1);

            var r = range.slice(1, range.length - 1);
            if (range.startsWith("{") && range.endsWith("}") && count === parseInt(r)) {
                return value;
            } else if (range.startsWith("[") && range.endsWith("]")) {
                r = r.split(',');
                if (count >= parseInt(r[0]) && (count <= parseInt(r[1]) || r[1] === "Inf"))
                    return value;
            }

            return (t.startsWith("{") || t.startsWith("[")) ? t.substr(t.indexOf(' ')) : t;
        }

        var trans = key.split('.').reduce(function(t, i) {
            return t[i] || null;
        }, translations);
        trans = (trans.indexOf('|') >= 0) ? trans.split('|').reduce(function(t, v) {
            return _choice(t, v, count);
        }) : _choice(trans, trans, count);

        for (var placeholder in replace) {
            trans = trans.replace(new RegExp(":" + placeholder, "gi"), replace[placeholder]);
        }

        return trans;
    };
}(window.olI18n = window.olI18n || {}, jQuery));
