"use strict";
    // Defines a path for any regular polygon with the specified number of sides and radius, 
    // centered on the provide x and y coordinates.
    // optional parameters: startAngle and anticlockwise

    function polygon(ctx, x, y, radius, sides, startAngle, anticlockwise) {
      if (sides < 3) return;
      var a = (Math.PI * 2)/sides;
      a = anticlockwise?-a:a;
      ctx.save();
      ctx.translate(x,y);
      ctx.rotate(startAngle);
      ctx.moveTo(radius,0);
      for (var i = 1; i < sides; i++) {
        ctx.lineTo(radius*Math.cos(a*i),radius*Math.sin(a*i));
      }
      ctx.closePath();
      ctx.restore();
    }

function drawMatchHistory(canvasName, userId, data, stateId, offset)
{
    function draw()
    {          
        // ==============================================================
        var coordinates = [];
        var x_y = [];
        // ==============================================================
        var canvas = document.getElementById(canvasName);
        canvas.style.width ='100%';
        canvas.style.height='100%';
        canvas.width  = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight; 
        
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        var color = olGUI.getStatePrimaryColor(stateId);
        var mixedLeague = (color == "rgba(0, 0, 0, 0)");
        var parsedData = JSON.parse(data);

        console.log(parsedData);
        
        var vertical = canvas.width < canvas.height;

        if (vertical) parsedData = parsedData.reverse();

        ctx.lineWidth = 2;
        ctx.beginPath();
        
        function _paint(lines)
        {
            var step = 0.6 / parsedData.length;
            $(parsedData).each(function(index, element)
            {
                var y = 0;
                if((userId == element.player1 && element.goals_player1 > element.goals_player2) || 
                    (userId == element.player2 && element.goals_player2 > element.goals_player1))
                    y = (vertical) ? canvas.width*.15 : 0;
                else if((userId == element.player1 && element.goals_player1 < element.goals_player2) ||
                    (userId == element.player2 && element.goals_player2 < element.goals_player1))
                    y = (vertical) ? canvas.width*.85 : 100;
                else 
                    y = (vertical) ? Math.floor(canvas.width*.5) : 50;
                if(lines)
                {
                    if(index == 0) 
                    {
                        if (vertical) 
                        {
                            ctx.moveTo(y, (offset + step * index) * canvas.height);
                        } 
                        else 
                        {
                            ctx.moveTo((offset + step * index) * canvas.width, y + 25);
                        }
                    }
                    else 
                    {
                        if (vertical) 
                        {
                            ctx.lineTo(y, (offset + step * index) * canvas.height);
                        } 
                        else 
                        {
                            ctx.lineTo((offset + step * index) * canvas.width, y + 25);
                        }
                    }
                    // ==============================================================
                    // save coordinates of the elements and matchId
                    
                    if (vertical)
                    {
                        var opponent = (userId == element.player1) ? element.player2 : element.player1;
                        x_y = [( y ),((offset + step * index) * canvas.height),element.matchId,element.season,opponent];
                    }
                    else 
                    {
                        var opponent = (userId == element.player1) ? element.player2 : element.player1;
                        x_y = [((offset + step * index) * canvas.width),( y + 25),element.matchId,element.season,opponent];
                    }
                    coordinates.push(x_y);
                    // ==============================================================
                }
                                
                if(!lines)
                {
                    if (vertical) 
                    {
                        polygon(ctx, y, (offset + step * index) * canvas.height,21, 5, -Math.PI/2);
                    }
                    else 
                    {
                        polygon(ctx, (offset + step * index) * canvas.width, y + 25, 21, 5, -Math.PI/2);
                    }
                    if(y == 0 || (vertical && y < Math.floor(canvas.width*.5)))
                    {
                        if(mixedLeague)
                        {
                            // Create gradient
                            var grdX = (offset + step * index) * (vertical ? canvas.height : canvas.width);
                            var grdY = y + 25;
                            var grd = ctx.createLinearGradient(grdX - 21, grdY - 21, grdX + 21, grdY + 21);

                            // Add colors
                            grd.addColorStop(0.000, 'rgba(0, 0, 0, 1.000)');
                            grd.addColorStop(0.200, 'rgba(0, 0, 0, 1.000)');
                            grd.addColorStop(0.500, 'rgba(158, 0, 0, 1.000)');
                            grd.addColorStop(0.800, 'rgba(255, 252, 0, 1.000)');
                            grd.addColorStop(1.000, 'rgba(255, 252, 0, 1.000)');

                            // Fill with gradient
                            ctx.fillStyle = grd;
                        }
                        else
                            ctx.fillStyle=color;
                    }
                    else if (y == 50 || (vertical && y == Math.floor(canvas.width*.5)))
                        ctx.fillStyle="rgb(204, 204, 204)";
                    else
                        ctx.fillStyle="rgb(255, 255, 255)";
                    ctx.fill();
                    ctx.stroke(); 
                    ctx.beginPath();
                    if(y == 0 || (vertical && y < Math.floor(canvas.width*.5)))
                        ctx.fillStyle="rgb(255, 255, 255)";
                    else
                        ctx.fillStyle="rgb(0, 0, 0)";
                    ctx.font = "600 17px Roboto, sans-serif";
                    ctx.textAlign="center";

                    if (vertical)
                    {
                        ctx.fillText(element.goals_player1 + ":" + element.goals_player2, y, (offset + step * index) * canvas.height + 6);
                    }
                    else 
                    {
                        ctx.fillText(element.goals_player1 + ":" + element.goals_player2, (offset + step * index) * canvas.width, y + 32);
                    }
                }
            });   
        }
        ctx.beginPath();
        _paint(true);
        ctx.fillStyle="";
        ctx.stroke();
        ctx.beginPath();
        _paint(false);

    $(canvas).click(function() {

        var isXS = $('body').hasClass('ol-xs');

        if (isXS) {
            offsetX = event.offsetX;
            offsetY = event.offsetY;
            
            var mouseInCurrent = false;
            for(i=0;i<coordinates.length;i++){
                if((offsetX > (coordinates[i][0]-20)) && (offsetX < (coordinates[i][0]+20)) && (offsetY > (coordinates[i][1]-20)) && (offsetY < (coordinates[i][1]+20))){
                    if (!mouseInCurrent) {
                        mouseInCurrent = coordinates[i];
                    }
                }
            }

            if(!mouseInCurrent) return;

            var popover = $(this).parent().find('.canvas-popover');

            var offsetX = -25;
            offsetX += mouseInCurrent[0];
            offsetX += $(this).offset().left;

            popover.addClass('in').find('.popover-title').html(Lang.trans('js/match.opponent') + ": " + $('#playerName_'+mouseInCurrent[4]).html()); 
            popover.find('.arrow').attr('style', '');
            
            if (offsetX + popover.outerWidth() > window.innerWidth) {
                offsetX = (window.innerWidth - (popover.outerWidth() + 15));
                offsetX -= $(this).offset().left;
                popover.find('.arrow').attr('style', 'left: '+(mouseInCurrent[0] - (offsetX + 10)) +'px!important;');
            }

    
            lastMatch = [mouseInCurrent[2], mouseInCurrent[3]];
            
            popover.attr('style', 'left:'+ (offsetX + 10) + "px !important; top: "+(mouseInCurrent[1] - (139 - 50)) + "px");
            clearTimeout(customPopoverTimeout);

    
                
            customPopoverTimeout = setTimeout(function() {
                popover.removeClass('in');
            }, 1800);
    
            return;
        }

        offsetX = event.offsetX;
        offsetY = event.offsetY;
        
        for(var i=0;i<coordinates.length;i++){
            if((offsetX > (coordinates[i][0]-20)) && (offsetX < (coordinates[i][0]+20)) && (offsetY > (coordinates[i][1]-20)) && (offsetY < (coordinates[i][1]+20))){
                document.location.href = "/match?season="+coordinates[i][3]+"&matchId="+coordinates[i][2];
            }
        }
    });

    var mouseIn = false;
    var customPopoverTimeout = 0;
    var lastMatch;
    $(canvas).mousemove(function() {
        offsetX = event.offsetX;
        offsetY = event.offsetY;
        
        var mouseInCurrent = false;
        for(var i=0;i<coordinates.length;i++){
            if((offsetX > (coordinates[i][0]-20)) && (offsetX < (coordinates[i][0]+20)) && (offsetY > (coordinates[i][1]-20)) && (offsetY < (coordinates[i][1]+20))){
                if (!mouseInCurrent) {
                    mouseInCurrent = coordinates[i];
                }
            }
        }

        var popover = $(this).parent().find('.canvas-popover');

        var offsetX = -25;
        offsetX += mouseInCurrent[0];

        if (mouseInCurrent && !mouseIn) { 
            mouseIn = true;
            // Mouse Enter
            lastMatch = [mouseInCurrent[2], mouseInCurrent[3]];
            popover.find('.popover-title').html(Lang.trans('js/match.opponent') + ": " + $('#playerName_'+mouseInCurrent[4]).html()); 
            popover.attr('style', 'left:'+ (offsetX) + "px !important; top: "+(mouseInCurrent[1] - (139 - 50)) + "px").addClass('in');
            clearTimeout(customPopoverTimeout);

            $(this).addClass('cursor-pointer');
            
        } else if (!mouseInCurrent && mouseIn) {
            mouseIn = false;
            // Mouse leave 
            customPopoverTimeout = setTimeout(function() {
                popover.removeClass('in');
            }, 1800);

            $(this).removeClass('cursor-pointer');
        }

    });

    $('.ol-popover-match-button').click(function() {
        document.location.href = "/match?season="+lastMatch[1]+"&matchId="+lastMatch[0];
    });
  
  
    }

    draw();      
    $(window).resize(draw);
    
    
    
    onUnloadPage(function() { $(window).unbind('resize', draw); });
}


(function(olMatch, $, undefined ) 
{
    olMatch.onClickPreviousDuells = function(season, matchId, event)
    {
        var element = $('#collapseVersusDetails');
        if (element.html().length == 0)
        {
            var loading = element.parent();
            addLoadingAnimationToElement(loading, {size: "small"});

            $(element).getLoad('/match/statistic/previousduells', {'season': season, 'matchId': matchId}, function()
            {
                $(element).collapse('toggle');
                removeLoadingAnimationFromElement(loading);
            });
            event.stopPropagation();
        }
    };

    olMatch.loadSubPage = function(url, anchor, data, callback) {
        // add params to url
        addUrlParams('?season='+data.season+'&matchId='+data.matchId+'&subPage='+url);
        loadPageContent(url, anchor, data, callback);
    };

    olMatch.teamMatchNav = function(element) {
        var args = parseURLParams(location.search);    
        if (args.subPage) {    
            olAnchorNavigation.load('/match', { season : $(element).attr('data-season'), matchId : $(element).attr('data-match'), subPage: args.subPage });  
        } 
        else {
            olAnchorNavigation.load('/match', { season : $(element).attr('data-season'), matchId : $(element).attr('data-match') });
        }
    };

    olMatch.loadLeagueTable = function(data) {
        var $container = $('#matchdayResults');
        if (!$container.html()) {
            addLoadingAnimationToElement($container, {size: 'small'});
            $.get('/matchdaytable/matchdaytable', data, function(rc) {
                $container.html(rc.html);
                var currentMatchRow = "[data-match-id='"+ data.matchId +"']";
                $(currentMatchRow).addClass("ol-bold");
                $(currentMatchRow + " .details-cell a," + currentMatchRow + " .ol-new-tab-btn").hide();
                removeLoadingAnimationFromElement($container);
            }).fail(function() {
                removeLoadingAnimationFromElement($container);
                console.log("*** loadLeagueTable failed");
            });
        }
    };

    olMatch.toggleQualiTable = function(data) {
        var $container = $('#matchdayResults'); 

        if(!$('#teamATable').length) {     
            $('#matchdayResults .ol-matchday-table-table-content').attr('id', 'teamATable');
            $('#teamATable').css('display', 'none');

            addLoadingAnimationToElement($container, {size: 'small'});
            $.get('/matchdaytable/matchdaytable', data, function(rc) {
                $container.append(rc.html);
                $('#matchdayResults .ol-matchday-table-table-content:not(#teamATable)').attr('id', 'teamBTable');
                var currentMatchRow = "[data-match-id='"+ data.matchId +"']";
                $(currentMatchRow).addClass("ol-bold");
                $(currentMatchRow + " .details-cell a," + currentMatchRow + " .ol-new-tab-btn").hide();                
                removeLoadingAnimationFromElement($container);
            }).fail(function() {
                removeLoadingAnimationFromElement($container);
                console.log("*** loadLeagueTableTeam2 failed");
            }); 
        }
    }

}( window.olMatch = window.olMatch || {}, jQuery ));