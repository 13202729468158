"use strict";
(function(olOfficeColorPicker, $, undefined) {

    olOfficeColorPicker.page = '';

    olOfficeColorPicker.initSite = function() {
        //console.log("ColorPicker initialized!");
    };

    olOfficeColorPicker.clickOnPrimaryColorBlock = function(element) {
        $('#primarycolorCategory' + element.data-cat ).attr('style','display:block;');
    };

    olOfficeColorPicker.clickOnSecondaryColorBlock = function(element) {
        $('#primarycolorCategory' + element.data-cat ).attr('style','display:block;');
    };

    olOfficeColorPicker.clickOnPrimaryColor = function(element) {
        $('#primarycolorCategory' + element.data-cat ).attr('style','display:block;');
    };

    //olOfficeColorPicker.loadLiveTicker = function(season, matchId, ticker) {
    //    //setTimeout("self.location.href='/match?season=" + season + "&matchId=" + matchId + "&ticker=1'", 2000);
    //    setTimeout(function() {
    //        location.href = '/match?season=" + season + "&matchId=" + matchId + "&ticker=1';
    //    }, 2000);
    //};
    // 
    //olOfficeColorPicker.postJSON = function(url, data, callback) {
    //    return jQuery.ajax({
    //        'type': 'POST',
    //        'url': url,
    //        'contentType': 'application/json',
    //        'data': data,
    //        'dataType': 'json',
    //        'success': callback
    //    });
    //};

    olOfficeColorPicker.clickOnSaveButton = function() {
        var $primary = $('#settingsPrimaryColor');
        var $secondary = $('#settingsSecondaryColor');

        var colorCat1 = parseInt($primary.attr('data-cat'));
        var colorCat2 = parseInt($secondary.attr('data-cat'));

        var colorIndex1 = parseInt($primary.attr('data-color-index'));
        var colorIndex2 = parseInt($secondary.attr('data-color-index'));

        var ColorId1 = (colorCat1 - 1)*6+colorIndex1;
        var ColorId2 = (colorCat2 - 1)*6+colorIndex2;

        var data = {booleanPrimary: 1, primaryColor: ColorId1, secondaryColor: ColorId2};

        $.post('/office/settings/changeColor', data, function(responseData) {
            if (responseData.type == "msg") {
                olMsgBox.json(responseData);
            } else if (responseData.type == "question") {
                olMsgBox.json(responseData, olOfficeColorPicker.saveColors, function() {});
            }
        }, 'json');
    };

    olOfficeColorPicker.saveColors = function() {
        var $primary = $('#settingsPrimaryColor');
        var $secondary = $('#settingsSecondaryColor');
        var $primaryCol = $('#colColorPrimary');
        var $secondaryCol = $('#colColorSecondary');
        
        $primaryCol.css('background', $primary.css( "background-color" ));
        $secondaryCol.css('background', $secondary.css( "background-color" ));

        var colorCat1 = parseInt($primary.attr('data-cat'));
        var colorCat2 = parseInt($secondary.attr('data-cat'));

        var colorIndex1 = parseInt($primary.attr('data-color-index'));
        var colorIndex2 = parseInt($secondary.attr('data-color-index'));

        //var ColorId1 = (colorCat1 - 1)*6+colorIndex1;
        //var ColorId2 = (colorCat2 - 1)*6+colorIndex2;

        var data = {booleanPrimary: 1, primaryColor: colorIndex1, secondaryColor: colorIndex2};
        var x = 'olOfficeColorPicker.clickOnRevertButton('+colorCat1+','+colorIndex1+','+colorCat2+','+colorIndex2+');';
        $('#OfficeRevertTeamColors').attr('onclick', x);

        olMsgBox.postLoad('/office/settings/saveColor', data, function() {} , 'OKAY');
    };

    olOfficeColorPicker.clickOnRevertButton = function(cat1,index1,cat2,index2) {
        var Color1 = (cat1 - 1)*6+index1;
        var Color2 = (cat2 - 1)*6+index2;

        var realColor1 = $("[data-color-id='" + Color1 + "'").attr('style');
        var realColor2 = $("[data-color-id='" + Color2 + "'").attr('style');

        var compColor1 = $("[data-color-id='" + Color1 + "'").attr('data-compColor');
        var compColor2 = $("[data-color-id='" + Color2 + "'").attr('data-compColor');

        //Alles deactivieren!
        $("[data-color-id]").removeClass('active');
        $('#primarycolorCategorySelector .active.ol-register-form-colorgrid-block-categorie').removeClass('active');
        $('#secondarycolorCategorySelector .active.ol-register-form-colorgrid-block-categorie').removeClass('active');

        for (var i = 1;  i <= 8;  i++) {
            $('#primarycolorCategory'+i).hide();
        }

        $('#primarycolorCategory' + cat1 ).attr('style','display:block;');
        $('#cat' + cat1 ).addClass('active');

        for (var i = 1;  i <= 8;  i++) {
            $('#secondarycolorCategory'+i).hide();
        }

        $('#secondarycolorCategory' + cat2 ).attr('style','display:block;');
        $('#seccat' + cat2 ).addClass('active');

        $("#primarycolorCategory" + cat1 + " [data-color-id='" + Color1 + "']").addClass('active');
        $("#secondarycolorCategory" + cat2 + " [data-color-id='" + Color2 + "']").addClass('active');

        $("#prime-color").attr('style', realColor1 );
        $("#second-color").attr('style', realColor2 );

        $('.home-jersey .jersey-color-shirt').attr('style', realColor1 );
        $('.home-jersey .jersey-color-shorts').attr('style', realColor2 );
        $('.home-jersey .jersey-color-socks').attr('style', realColor1 );

        $('.outward-jersey .jersey-color-shirt').attr('style', 'background-color: ' + compColor1 + ';');
        $('.outward-jersey .jersey-color-shorts').attr('style', 'background-color: ' + compColor2 + ';');
        $('.outward-jersey .jersey-color-socks').attr('style', 'background-color: ' + compColor1 + ';');
    };

    olOfficeColorPicker.clickOnPrimeColor = function(element) {
        var colorcat = 1;
        var colorindex = 1;

        var colors = $(element).attr('style');
        var colors2 = $(element).css('background-color');
        var colorval = colors2;

        var colorcat = $(element).attr('data-cat');
        var colorindex = $(element).attr('data-color-index');
        var colorId  = $(element).attr('data-color-id');
        colorId--;

        colorIndex = colorId % 6 + 1;
        colorcat = colorId / 6 + 1;
        colorcat = Math.floor(colorcat);

        $('.ol-register-form-colorgrid-prime .active.ol-register-form-colorgrid-block').removeClass('active');
        $(this).addClass('active');

        $('#prime-color').attr('style', colors);
        $('#prime-color').attr('data-cat', colorcat);
        $('#prime-color').attr('data-color-index', colorIndex);

        $('.home-jersey .jersey-color-shirt').attr('style', colors);
        $('.home-jersey .jersey-color-socks').attr('style', colors);
        $(element).addClass('active');

        var compColor = $(element).attr('data-compColor');
        $('.outward-jersey .jersey-color-shirt').attr('style', 'background-color: ' + compColor + ';');
        $('.outward-jersey .jersey-color-socks').attr('style', 'background-color: ' + compColor + ';');

        var primColor = $(element).attr('data-color-id');
        var secoColor = $('.ol-register-form-colorgrid-secondary .active.ol-register-form-colorgrid-block').attr('data-color-id');
        var data = { booleanPrimary : 1 , primaryColor : primColor , secondaryColor : secoColor };
    };


    olOfficeColorPicker.clickOnSecondaryColor = function(element) {
        var colorcat = 1;
        var colorindex = 1;

        var colorcat = $(element).attr('data-cat');
        var colorindex = $(element).attr('data-index');
        var colors = $(element).attr('style');
        var colors2 = $(element).css('background-color');
        var colorval = colors2;

        var colorId  = $(element).attr('data-color-id');
        colorId--;

        colorIndex = colorId % 6 + 1;
        colorcat = colorId / 6 + 1;
        colorcat = Math.floor(colorcat);

        $('.ol-register-form-colorgrid-secondary .active.ol-register-form-colorgrid-block').removeClass('active');
        $(element).addClass('active');
        $('#second-color').attr('style', colors);
        $('#second-color').attr('data-cat', colorcat);
        $('#second-color').attr('data-color-index', colorIndex);
        $('.home-jersey .jersey-color-shorts').attr('style', colors);

        var compColor = $(element).attr('data-compColor');
        $('.outward-jersey .jersey-color-shorts').attr('style', 'background-color: ' + compColor + ';');

        var primColor = $('.ol-register-form-colorgrid-prime .active.ol-register-form-colorgrid-block').attr('data-color-id');
        var secoColor = $('.ol-register-form-colorgrid-secondary .active.ol-register-form-colorgrid-block').attr('data-color-id');
        var data = { booleanPrimary : 1 , primaryColor : primColor , secondaryColor : secoColor };
    };

    olOfficeColorPicker.clickOnPrimaryColorCategory = function(element) {
        $('#primarycolorCategorySelector .active.ol-register-form-colorgrid-block-categorie').removeClass('active');

        var x = $(element).attr('data-cat');
        $('#primarycolorCategory1').attr('style','display:none;');
        $('#primarycolorCategory2').attr('style','display:none;');
        $('#primarycolorCategory3').attr('style','display:none;');
        $('#primarycolorCategory4').attr('style','display:none;');
        $('#primarycolorCategory5').attr('style','display:none;');
        $('#primarycolorCategory6').attr('style','display:none;');
        $('#primarycolorCategory7').attr('style','display:none;');
        $('#primarycolorCategory8').attr('style','display:none;');

        $('#primarycolorCategory' + x ).attr('style','display:block;');
        $(element).addClass('active');
    };

    olOfficeColorPicker.clickOnSecondaryColorCategory = function(element) {
        $('#secondarycolorCategorySelector .active.ol-register-form-colorgrid-block-categorie').removeClass('active');
        var x = $(element).attr('data-cat');

        $('#secondarycolorCategory1').attr('style','display:none;');
        $('#secondarycolorCategory2').attr('style','display:none;');
        $('#secondarycolorCategory3').attr('style','display:none;');
        $('#secondarycolorCategory4').attr('style','display:none;');
        $('#secondarycolorCategory5').attr('style','display:none;');
        $('#secondarycolorCategory6').attr('style','display:none;');
        $('#secondarycolorCategory7').attr('style','display:none;');
        $('#secondarycolorCategory8').attr('style','display:none;');

        $('#secondarycolorCategory' + x ).attr('style','display:block;');
        $(element).addClass('active');
    };

}(window.olOfficeColorPicker = window.olOfficeColorPicker || {}, jQuery));