(function(olTeamLogoNavigation, $, undefined)
{
    olTeamLogoNavigation.create = function(config)
    {
        var editorVisible = false;
        var editorToggleEl = false;
        var editorMenus = false;
        var editorMenuToggles = false;
        var editorMenuMenus = false;
        var editorMenuEntries = false;
        var backgroundEditorActive = false;
        var initialBackgroundId = 0;
            
        // TODO: Turn this into a proper class.
        
        var bgCanvas = document.getElementById("userVisualCanvas");
        editorBackgroundArrows = $('.editor-background-arrow');
        editorBackgroundSave = $('.editor-background-save');
        editorBackgroundNumber = $('#backgroundSelectionId');
        editorToggleEl = $('.edit-mode-toggle, #editModeToggle');

        editorVisible = false;
        editorMenus = $('.editor-menu');
        editorMenuToggles = $('.editor-menu .content');
        editorMenuMenus = $('.editor-menu .menu');
        editorMenuEntries = $('.editor-menu .menu .entry');
        
        olTeamLogoNavigation.visualUpdateRemainingMinutes = config.visualUpdateRemainingMinutes;
        
        var visuals = []
        var currentVisual = 0;
        for (var n in config.backgrounds) {
            visuals.push(config.backgrounds[n]);
            if (config.backgrounds[n].visualId === config.currentVisualId) {
                currentVisual = parseInt(n);
            }
        }
        
        function toggleEditMode(keepMargin) {
            editorVisible = !editorVisible;
            var newState = editorVisible ? 'visible' : 'hidden';
            
            $(editorMenuToggles).each(function(index, menu) {
                $(menu).css('visibility', newState);
            });
            $(editorMenuMenus).each(function(index, el) {
                $(el).css('visibility', 'hidden');
            });

            if (keepMargin) return;

            if (editorVisible)
                $('#olTeamOverviewHeader').addClass('edit-mode-on');
            else
                $('#olTeamOverviewHeader').removeClass('edit-mode-on');
        }

        function toggleEditMenu(selector) {
            $(editorMenuMenus).not(selector).css('visibility', 'hidden');

            if($(selector).css('visibility') == 'hidden')
                $(selector).css('visibility', 'visible');
            else
                $(selector).css('visibility', 'hidden');
        }
        
        olTeamLogoNavigation.showLogoDelay = function() {
            olMsgBox.msgById('logoUploadDelay', undefined, {"%minutes%": Math.ceil( (config.nextLogoUpdate - new Date()) / 60000 )});
        }

        function initEditorMode() {
            if(config.officeSettings) {
                $('#selectBackgroundButton').click(function(ev) {
                    olTeamLogoNavigation.visualUpdateRemainingMinutes = Math.ceil( (config.nextVisualUpdate - new Date()) / 60000 );
                        
                    if (olTeamLogoNavigation.visualUpdateRemainingMinutes > 0) {
                        olMsgBox.msgById('visualUploadDelay', undefined, {"%minutes%": olTeamLogoNavigation.visualUpdateRemainingMinutes});
                    } else {
                        showBackgroundSelection();
                    }
                });

                $('#uploadBackgroundButtonBG').click(function() {
                    olTeamLogoNavigation.visualUpdateRemainingMinutes = Math.ceil( (config.nextVisualUpdate - new Date()) / 60000 );
                    if (olTeamLogoNavigation.visualUpdateRemainingMinutes > 0) {
                        olMsgBox.msgById('visualUploadDelay', undefined, {"%minutes%": olTeamLogoNavigation.visualUpdateRemainingMinutes});
                    } else {
                        olMsgBox.questionById('visualUploadHint', showBackgroundUpload);
                    }
                });
            } else {
                $(editorToggleEl).click(function(ev) {
                    toggleEditMode();
                });
            }
            
            $(editorMenuToggles).each(function(index, el) {
                $(el).click(function(ev) {
                    toggleEditMenu('#' + $(el).parent().attr('id') + ' .menu');
                });
            });
            $(editorMenuEntries).each(function(index, el) {
                if ($(el).attr('id') == 'uploadBackgroundButton'){
                    $(el).click(function(ev) {
                        console.log("CLICK")
                        olTeamLogoNavigation.visualUpdateRemainingMinutes = Math.ceil( (config.nextVisualUpdate - new Date()) / 60000 );
                        if (olTeamLogoNavigation.visualUpdateRemainingMinutes > 0) {
                            olMsgBox.msgById('visualUploadDelay', undefined, {"%minutes%": olTeamLogoNavigation.visualUpdateRemainingMinutes});
                        } else {
                            olMsgBox.questionById('visualUploadHint', showBackgroundUpload);
                        }
                    });
                } else if ($(el).attr('id') == 'selectBackgroundButton') {
                    $(el).click(function(ev) {
                        olTeamLogoNavigation.visualUpdateRemainingMinutes = Math.ceil( (config.nextVisualUpdate - new Date()) / 60000 );
                        
                        if (olTeamLogoNavigation.visualUpdateRemainingMinutes > 0) {
                            olMsgBox.msgById('visualUploadDelay', undefined, {"%minutes%": olTeamLogoNavigation.visualUpdateRemainingMinutes});
                        } else {
                            showBackgroundSelection();
                        }
                    });
                } else if ($(el).attr('id') == 'uploadLogoButton2') {
                    $(el).click(function(ev) {
                        var badgeUploadRemainingMinutes =  Math.ceil( (config.nextLogoUpdate - new Date()) / 60000 );
                        if(badgeUploadRemainingMinutes > 0)
                            olMsgBox.msgById('logoUploadDelay', undefined, {"%minutes%": badgeUploadRemainingMinutes});
                        else
                            olOverlayWindow.load('/team/teamLogoUpload');
                    });
                } else if ($(el).attr('id') == 'selectLogoButton') {
                        //if(!config.badgeUploadEnabled)
                         //   olMsgBox.msgById('logoUploadDelay', undefined, {"%minutes%": Math.ceil( (config.nextLogoUpdate - new Date()) / 60000 )});
                } 
            });
            
            $('.editor-background-arrow').click(function(ev) {
                scrollBackgrounds(this);
                ev.preventDefault()
                ev.stopPropagation();
            })
            
            $('a, .ol-button-toggle, #backgroundSaveButton').click(function(ev) {
                if (backgroundEditorActive) {
                    hideBackgroundSelection();
                    if (currentVisual != initialBackgroundId) {
                        saveBackground();
                    }
                }
                
                if ($(this).attr('id') != "editModeToggle" && editorVisible) {
                    toggleEditMode()
                }
            })
            
            // Reset State
            $(editorMenus).each(function(index, el) {
                $(el).css('visibility', 'hidden');
            });
        }
        
        /*
         * Unhide controls for Background Selection
         */
        function showBackgroundSelection () {
            backgroundEditorActive = true;
            initialBackgroundId = currentVisual;
            toggleEditMode(true);
            $(editorBackgroundArrows).show();
            $(editorBackgroundNumber).text( (currentVisual+1) + "/" + visuals.length ).show()
            $(editorBackgroundSave).show();
        }
        
        /*
         * Sets the timer for visual upload delay
         */
        olTeamLogoNavigation.setVisualUploadDelay = function() {
            var now = new Date();
            var then = new Date(now.getTime()+ config.visualUpdateDelay * 60000);
            
            config.nextVisualUpdate = then;
            
            olTeamLogoNavigation.visualUpdateRemainingMinutes = config.visualUpdateDelay;
        }
        
        
        /*
         * Hide controls for background selection
         */
        function hideBackgroundSelection () {
            backgroundEditorActive = false;
            $(editorBackgroundArrows).hide();
            $(editorBackgroundNumber).hide();
            $(editorBackgroundSave).hide();
            $('#olTeamOverviewHeader').removeClass('edit-mode-on');
        }
        
        /*
         * Save selected background
         */
        function saveBackground() {
            $.post("/team/teamVisualSelect", {"visualId": visuals[currentVisual].visualId}, function() {
                olTeamLogoNavigation.setVisualUploadDelay();
            });
        }
        
        /*
         * Load next or previous background from the list
         */
        function scrollBackgrounds(el) {
            // Get scroll direction
            if ($(el).hasClass('background-arrow-left')) {
                currentVisual -= 1;
                if (currentVisual < 0) {
                    currentVisual = visuals.length - 1;
                }
            } else {
                currentVisual = (currentVisual+1) % visuals.length;
            }
            
            // Update text indicator
            $(editorBackgroundNumber).text( (currentVisual+1) + "/" + visuals.length )
            
            // Fade out old image, refresh header canvas, fade back in           
            $(bgCanvas).fadeOut(300, 'linear', function() {
                olTeamOverview.init( { 
                    stateId : config.userStateId,
                    visual: {
                        type: "SIMPLE",
                        file: visuals[currentVisual].filename,
                        statePatternOpacity : visuals[currentVisual].statePatternOpacity,
                    },
                    canvasElement : bgCanvas,
                    loadingHeaderElement : $("#olTeamOverviewHeader"),
                    callback: function() {
                        $(bgCanvas).fadeIn(300, 'linear');
                    }
                });
            });
        }
        
        // Show menu for custom background file upload
        function showBackgroundUpload() {
            olBackgroundEditor.create({targetSelector: '.ol-team-background-editor', stateId: config.userStateId, teamColors: config.teamColors, maxFileSize: config.visualMaxFileSize, maxDimensions: config.visualMaxDimensions});
            olBackgroundEditor.show(false)
            $('.ol-navbar-subnav-open').removeClass('ol-navbar-subnav-open');
            toggleEditMode();
        }

        initEditorMode();
    };
}(window.olTeamLogoNavigation = window.olTeamLogoNavigation || {}, jQuery));