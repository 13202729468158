"use strict";
(function(olDialog, $, undefined) {

    //DEPRECATED
    // Zeigt eine Nachricht mit Inhalt `text` per olMsgBox an.
    // Der Header wird aus #msgHeader entnommen (dflt='') und der Text des OK-Buttons
    //  wird aus #msgOk entnommen (dflt=OK).
    // Hat der Text die Form "...|...", dann wird der vordere Teil als Header benutzt.
    olDialog.messageOld = function(text, onsuccess) {
        var header = $('#msgHeader').html() || '',
            textYes = $('#msgOk').html() || 'OK',
            callbackYes = (typeof onsuccess === 'function')? onsuccess : undefined;
        var m = text.match(/^([^|]*)\|(.*)$/);
        if (m) {
            header = m[1];
            text = m[2];
        }
        olMsgBox.msg(header, text, callbackYes, textYes);
    };



    // Ermittelt die Dialogdaten aus `element` und `args`.
    //
    // `element` kann ein jQuery-Selektor oder jQuery-Object sein;
    //  aus dem so referenzierten DOM-Element werden die data-Attribute entnommen und
    //  in normalisierter Form im Dialogdaten-Objekt gesammelt.
    // Alternativ kann `element` auch ein Objekt sein;
    //  in diesem Fall werden die (eigenen) Attribute mit Präfix "data-" entnommen
    //  und in normalisierter Form im Dialogdaten-Element gesammelt.
    //
    // Die normalisierte Form eines Feldnamens ist in Kleinbuchstaben und
    //  ohne den "data-"-Präfix.
    //
    // Falls ´args´ angegeben ist, dann werden aus diesem Objekt alle (eigenen) Felder
    //  mit Präfix "data-" entnommen und damit die aus `element` ermittelten Werte
    //  überschrieben (d.h. die Felder mit dem zugehörigen normalisierten Namen).
    //
    // Folgende Felder werden beachtet:
    // data-msg-text           der anzuzeigende Text
    // data-msg-title          Überschrift/Titel (optional, dlft=keiner)
    // data-msg-subtitle       Untertitel (nur assistant, dflt=keiner)
    // data-msg-steptitle      title for step buttons (only assistant, dflt=use subtitle)
    // data-msg-textyes        Text für den Ja-Button (nur confirm)
    // data-msg-textno         Text für den Nein-Button (nur confirm)
    // data-msg-textok         Text für den OK-Button (nur message)
    // data-msg-textnext       Text für den Weiter-Button (nur assistant)
    // data-msg-textcancel     Text für den Abbrechen-Button (nur assistant)
    // data-msg-disablecancel  Abbrechen-Button, x, Esc ausblenden/disabeln (nur assistant)
    // data-msg-maxwidth       max-width für Overlay (optional)
    // data-msg-maxheight      max-height für Overlay (optional)
    // data-msg-style          Style für Ja-Butonn (dflt=BL-Farbe, 'sponsorblue'=fix dunkelblau)
    // data-msg-large          large-Form für MsgBox-Anzeige (default=nein)
    //
    // Liefert das Dialogdaten-Objekt.
    function getDlgData(element, args) {
        function isData(name){
            return /^data-/.test(name);
        }

        function normalize(key) {
            return key.replace(/^data-/, '').toLowerCase();
        }

        function addElementAttributes(dlg, element) {
            if (element instanceof HTMLElement) {
                var atts = element.attributes;
                if (atts) {
                    for (var i = 0;  i < atts.length;  i++) {
                        var att = atts[i];
                        if (!isData(att.name)) continue;
                        dlg[normalize(att.name)] = att.value;
                    }
                }
            }
            return dlg;
        };

        function addObjectAttributes(dlg, obj) {
            if (!obj) return dlg;
            if (typeof obj === 'object') {
                var objKeys = Object.getOwnPropertyNames(obj).filter(isData);
                if (objKeys.length) {
                    for (var i = 0;  i < objKeys.length;  i++) {
                        var key = objKeys[i];
                        dlg[normalize(key)] = obj[key];
                    }
                }
            }
            return dlg;
        }

        var dlg = Object.create(null);
        
        if (typeof element === 'string') { // selector
            var $element = $(element);
            if ($element.length) addElementAttributes(dlg, $element[0]);
        } else if (element instanceof jQuery) {
            if (element.length) addElementAttributes(dlg, element[0]);
        } else if (typeof element === 'object') { // object
            addObjectAttributes(dlg, element);
        } else {
            // keine Attribute von hier
        }

        addObjectAttributes(dlg, args);

        // Returns the value of the `key` attribute or `dflt` if not present (dflt='')
        dlg.attr = function(key, dflt) {
            key = key.toLowerCase();
            if (dflt === undefined) dflt = '';

            var value = this[key];
            if (value === undefined) value = dflt;
            value = ''+value;

            value = value.replace(/{([^}]+)}/g, function(m0, key) {
                var key = key.toLowerCase();
                var value = this[key];
                if (value === undefined) value = '';
                return value;
            }.bind(this));
            //value = value.replace(/\n/g, '<br />');
            //value = value.trim();
            return value;
        };

        return dlg;
    };

    // Converts the argument `value` to a boolean:
    // true   true, 'true', 'on', 1
    // false  false, 'false', 'off', 0, ''
    // For strings the case is ignored. for numbers any value != 0 is true.
    // Every unsupported format is false.
    olDialog.toBool = function(value) {
        if (typeof value === 'boolean') return value;
        if (typeof value === 'number') return (value != 0);
        if (typeof value === 'string') {
            value = value.toLowerCase();
            if (value == 'true' || value == 'on') return true;
            if (value == 'false' || value == 'off') return false;
            var i = parseInt(value); if (isNaN(i)) i = 0;
            return (i != 0);
        }
    };

    // Zeigt eine Nachrichtenbox (mit olMsgBox) und ruft dann `callbackOk` auf,
    //  wenn der Dialog geschlossen wurde.
    // Die Dialogdaten werden aus `element` und `args` entnommen (s. getDlgData).
    // Ist data-msg-text nicht gesetzt oder leer, dann erfolgt keine Anzeige
    //  und es wird gleich `callbackOk`aufgerufen.
    olDialog.message = function(element, args, callbackOk) {
        var dlg = getDlgData(element, args);
        var data = {
            title: dlg.attr('msg-title'),
            text: dlg.attr('msg-text'),
            textok: dlg.attr('msg-textok') || 'OK',
            maxwidth: dlg.attr('msg-maxwidth', 492),
            style: dlg.attr('msg-style')
        };
        var large = olDialog.toBool(dlg.attr('msg-large', false));

        function onOk(event) {
            if (typeof callbackOk === 'function') callbackOk(event);
        }

        // Kein Text => keine Message
        if (data.text == '') {
            onOk(null);
            return;
        }

        // MsgBox anzeigen
        olMsgBox.msg(data.title, data.text, onOk, data.textok, large);
    };

    // Zeigt eine Nachrichtenbox (mit olOverlayWindow) und ruft dann `callbackOk` auf,
    //  wenn der Dialog geschlossen wurde.
    // Die Dialogdaten werden aus `element` und `args` entnommen (s. getDlgData).
    // Ist data-msg-text nicht gesetzt oder leer, dann erfolgt keine Anzeige
    //  und es wird gleich `callbackOk`aufgerufen.
    olDialog.messageExt = function(element, args, callbackOk) {
        var dlg = getDlgData(element, args);
        var data = {
            title: dlg.attr('msg-title'),
            text: dlg.attr('msg-text'),
            textok: dlg.attr('msg-textok') || 'OK',
            maxwidth: dlg.attr('msg-maxwidth', 492),
            maxheight: dlg.attr('msg-maxheight', 0.9),
            style: dlg.attr('msg-style')
        };

        function onOk(event) {
            olOverlayWindow.close();
            if (typeof callbackOk === 'function') callbackOk(event);
        }

        // Kein Text => keine Message
        if (data.text == '') {
            onOk(null);
            return;
        }

        // Overlay anzeigen
        olOverlayWindow.postLoad('/dialog/message', data, function() {
            olOverlayWindow.setTitleFromContent();
            var $bar = $('.ol-dialog-buttonbar');
            $bar.find('.ol-ok-button').click(onOk);
        }, onOk, olOverlayWindow.close);
    };


    // Zeigt eine Nachricht als eingeblendeter Text an.
    // Die Dialogdaten werden aus `element` und `args` entnommen (s. getDlgData).
    // Beachtete Felder:
    // - data-msg-target  jQuery-Selektor des Anzeige-Elments
    // - data-msg-title   wird in Unterelement .ol-dialog-title eingesetzt
    // - data-msg-text    wird in Unterelement .ol-dialog-text eingesetzt
    // Ist data-msg-text nicht gesetzt oder leer, dann erfolgt keine Anzeigeö
    olDialog.text = function(element, args) {
        var dlg = getDlgData(element, args);
		
		var target = dlg.attr('msg-target', null);
		if (!target) return;
		var $target = $(target);
		if (!$target.length) return;

		var title = dlg.attr('msg-title'),
		    text = dlg.attr('msg-text');

        // No Text => no message
        $target.removeClass('shown');
        if (data.text == '') return;
        
        // Show text
        $target.find('.ol-dialog-title').html(data.title);
        $target.find('.ol-dialog-text').html(data.text);
        $target.addClass('shown');
    };

    // Zeigt eine Nachricht als Text oder MsgBox and und ruft dann `callbackOk` auf,
    //  wenn der Dialog geschlossen wurde.
    // Falls data-message-target vorhanden ist, dann wird ein Text benutzt
    //  (wie olDialog.text), ansonsten ein Overlay (wie olDialog.messageExt).
    // Die Dialogdaten werden aus `element` und `args` entnommen (s. getDlgData).
    // Ist data-msg-text nicht gesetzt oder leer, dann erfolgt keine Anzeige
    //  und es wird gleich `callbackOk`aufgerufen.
    olDialog.messageOrText = function(element, args, callbackOk) {
        var dlg = getDlgData(element, args);

        var data = {
            title: dlg.attr('msg-title'),
            text: dlg.attr('msg-text'),
            textok: dlg.attr('msg-textok') || 'OK',
            maxwidth: dlg.attr('msg-maxwidth', 492),
            maxheight: dlg.attr('msg-maxheight', 0.9),
            style: dlg.attr('msg-style')
        };
        var large = olDialog.toBool(dlg.attr('msg-large', false));

		var target = dlg.attr('msg-target', null),
		    $target = target? $(target) : null;
	    if (!$target || !$target.length || $target.css('display') == 'none') $target = null;

        function onOk(event) {
            if (typeof callbackOk === 'function') callbackOk(event);
        }

        // Kein Text => keine Message
		if ($target) $target.removeClass('shown');
        if (data.text == '') {
            onOk(null);
            return;
        }

		// Anzeige
		if ($target) {
	        $target.find('.ol-dialog-title').html(data.title);
	        $target.find('.ol-dialog-text').html(data.text);
	        $target.addClass('shown');
	        onOk(null);
		} else {
            olMsgBox.msg(data.title, data.text, onOk, data.textok, large); 
		}
    };

    // Zeigt eine Nachricht als Text oder Overlay and und ruft dann `callbackOk` auf,
    //  wenn der Dialog geschlossen wurde.
    // Falls data-message-target vorhanden ist, dann wird ein Text benutzt
    //  (wie olDialog.text), ansonsten ein Overlay (wie olDialog.messageExt).
    // Die Dialogdaten werden aus `element` und `args` entnommen (s. getDlgData).
    // Ist data-msg-text nicht gesetzt oder leer, dann erfolgt keine Anzeige
    //  und es wird gleich `callbackOk`aufgerufen.
    olDialog.messageExtOrText = function(element, args, callbackOk) {
        var dlg = getDlgData(element, args);

        var data = {
            title: dlg.attr('msg-title'),
            text: dlg.attr('msg-text'),
            textok: dlg.attr('msg-textok') || 'OK',
            maxwidth: dlg.attr('msg-maxwidth', 492),
            maxheight: dlg.attr('msg-maxheight', 0.9),
            style: dlg.attr('msg-style')
        };

		var target = dlg.attr('msg-target', null),
		    $target = target? $(target) : null;
	    if (!$target || !$target.length) $target = null;

        function onOk(event) {
            if (typeof callbackOk === 'function') callbackOk(event);
            olOverlayWindow.close();
        }

        // Kein Text => keine Message
		if ($target) $target.removeClass('shown');
        if (data.text == '') {
            onOk(null);
            return;
        }

		// Anzeige
		if ($target) {
	        $target.find('.ol-dialog-title').html(data.title);
	        $target.find('.ol-dialog-text').html(data.text);
	        $target.addClass('shown');
	        onOk(null);
		} else {
	        olOverlayWindow.postLoad('/dialog/message', data, function() {
	            olOverlayWindow.setTitleFromContent();
	            var $bar = $('.ol-dialog-buttonbar');
	            $bar.find('.ol-ok-button').click(onOk);
	        }, onOk, olOverlayWindow.close);
		}
    };


    // Macht eine Bestätigungsabfrage (mit olMsgBox) und ruft dann `callbackYes`
    //  bzw. `CallbackNo` auf.
    // Die Dialogdaten werden aus `element` und `args` entnommen (s. getDlgData).
    // Ist data-msg-text nicht gesetzt oder leer, dann erfolgt keine Bestätigungsabfrage
    //  und es wird gleich `callbackYes` aufgerufen.
    olDialog.confirm = function(element, args, callbackYes, callbackNo) {
        var dlg = getDlgData(element, args);
        var header = dlg.attr('msg-header') || dlg.attr('msg-title'),
            text = dlg.attr('msg-text'),
            textYes = dlg.attr('msg-textYes'),
            textNo = dlg.attr('msg-textNo');
        var large = olDialog.toBool(dlg.attr('msg-large', false));

        // Kein Text => kein Confirm
        if (text == '') {
            if (typeof callbackYes === 'function') callbackYes();
            return;
        }

        // Confirm anzeigen
        olMsgBox.question(header, text, callbackYes, callbackNo, textYes, textNo, large);
    };

    // Macht eine Bestätigungsabfrage (mit olOverlayWindo) und ruft dann `callbackYes`
    //  bzw. `CallbackNo` auf.
    // Die Dialogdaten werden aus `element` und `args` entnommen (s. getDlgData).
    // Ist data-msg-text nicht gesetzt oder leer, dann erfolgt keine Bestätigungsabfrage
    //  und es wird gleich `callbackYes` aufgerufen.
    olDialog.confirmExt = function(element, args, callbackYes, callbackNo) {
        var dlg = getDlgData(element, args);
        var data = {
            title: dlg.attr('msg-title'),
            text: dlg.attr('msg-text'),
            textyes: dlg.attr('msg-textyes'),
            textno: dlg.attr('msg-textno'),
            maxwidth: dlg.attr('msg-maxwidth', 492),
            maxheight: dlg.attr('msg-maxheight', 0.9),
            style: dlg.attr('msg-style')
        };

        function onYes(event) {
            if (typeof callbackYes === 'function') callbackYes(event);
            olOverlayWindow.removeCloseListeners();
            olOverlayWindow.close();
        }
        function onNo(event) {
            if (typeof callbackNo === 'function') callbackNo(event);
            olOverlayWindow.removeCloseListeners();
            olOverlayWindow.close();
        }

        // Kein Text => kein Confirm
        if (data.text == '') {
            if (typeof callbackNo === 'function') callbackYes(event);
            return;
        }

        // Confirm anzeigen
        olOverlayWindow.addCloseListener(onNo);
        olOverlayWindow.postLoad('/dialog/confirm', data, function() {
            olOverlayWindow.setTitleFromContent();
            var $bar = $('.ol-dialog-buttonbar');
            $bar.find('.ol-ok-button').click(onYes);
            $bar.find('.ol-cancel-button').click(onNo);
        }, onYes, onNo);
    };


    //--- Assistant ---
    var assistantProto = {
        _setPageContent: function(title, subtitle, content, textnext, textcancel, disablecancel) {
            $('.ol-overlay-window-header .ol-overlay-window-header-title').text(title);
            $('.ol-dialog-subtitle').text(subtitle);
            $('.ol-dialog-content').html(content);
            $('.ol-dialog-buttonbar .ol-next-button').html(textnext);
            $('.ol-dialog-buttonbar .ol-cancel-button').html(textcancel);
            this.disableCancel(disablecancel);
            olOverlayWindow.resize();
        },

        _buttonLoading: function(on) {
            var $btn = $('.ol-dialog-buttonbar .ol-next-button');
            $btn.toggleClass('ol-button-loading', on);
        },
        
        _onSuccess: function() {
            this._cbSuccess();
            olOverlayWindow.removeCloseListeners();
            olOverlayWindow.close();
            $('#overlayWindow').removeClass('cancel-disabled');
        },

        _onCancel: function() {
            if (this.isCancelDisabled()) return;
            this._cbCancel();
            olOverlayWindow.removeCloseListeners();
            olOverlayWindow.close();
            $('#overlayWindow').removeClass('cancel-disabled');
        },
        
        _onInit: function() {
            var visited = (this._stepVisits[this.step]++ > 0);
            $('#olDialogNavButton'+this.prevStep).removeClass('active')
                                                 .addClass('visited')
                                                 .prop('disabled', false);
            $('#olDialogNavButton'+this.step).toggleClass('active', true)
                                             .toggleClass('visited', visited)
                                             .prop('disabled', false);

            this.disableNext(false);
            this._cbInit();

            this._buttonLoading(false);
            $('.ol-dialog-navbutton').removeClass('ol-button-loading');
            
            setTimeout(function() {olOverlayWindow.resize();}, 500);
        },

        _onThis: function() {
            // no view change => just call init
            if (this.step == this.prevStep) {
                this._onInit();
                return;
            }

            var dlg = this._dlgs[this.step];
            var url = dlg.attr('view'),
                title = dlg.attr('msg-title'),
                subtitle = dlg.attr('msg-subtitle'),
                textnext = dlg.attr('msg-textnext'),
                textcancel = dlg.attr('msg-textcancel'),
                disablecancel = olDialog.toBool(dlg.attr('msg-disablecancel', 0));

            // no view url given => show text from dialog parameter
            if (!url) {
                this._setPageContent(title, subtitle, dlg.attr('msg-text'), textnext, textcancel, disablecancel);
                this._onInit();
                return;
            }

            // view url given => fetch text from url
            var data = {
                step: this.step,
                param: dlg.attr('param'), //TODO
                data: this.data
            };
            var $anchor = $('.ol-dialog-area');
            addLoadingAnimationToElement($anchor, {size: 'small'});
//            $.get(url, data, function(html) {
//                this._setPageContent(title, subtitle, html, textnext, textcancel, disablecancel);
//                removeLoadingAnimationFromElement($anchor);
//                this._onInit();
//            }.bind(this)).fail(function() {
//                removeLoadingAnimationFromElement($anchor);
//                console.log("*** assistant._onThis: getting view failed:", url, data, this);
//            });
			var m = url.match(/^post:(.*)$/i);
			var method = 'GET';
			if (m) {
				url = m[1];
				method = 'POST';
			}
            $.ajax({url: url, data: data, type: method, success: function(html) {
                this._setPageContent(title, subtitle, html, textnext, textcancel, disablecancel);
                removeLoadingAnimationFromElement($anchor);
                this._onInit();
            }.bind(this)}).fail(function() {
                removeLoadingAnimationFromElement($anchor);
                console.log("*** assistant._onThis: getting view failed:", url, data, this);
            }.bind(this));
            
        },

        _onFirst: function() {
            //this.step = 0;
            var dlg = this._dlgs[this.step];
            var url = dlg.attr('view');

            var adata = {
                title: dlg.attr('msg-title'),
                subtitle: dlg.attr('msg-subtitle'),
                textnext: dlg.attr('msg-textnext'),
                textcancel: dlg.attr('msg-textcancel'),
                disablecancel: olDialog.toBool(dlg.attr('msg-disablecancel')),
                maxwidth: dlg.attr('msg-maxwidth'),
                maxheight: dlg.attr('msg-maxheight'),
                style: dlg.attr('msg-style'),
                param: dlg.attr('param'),
                navbuttons: this._navButtons,
                navmode: this._navMode
            };
            var showOverlay = function(data) {
                var onNext = this._onNext.bind(this),
                    onCancel = this._onCancel.bind(this);
                olOverlayWindow.postLoad('/dialog/assistant', data, function() {
                    olOverlayWindow.setTitleFromContent();
                    $('.ol-overlay-window-content').css('padding', '');
                    var $bar = $('.ol-dialog-buttonbar');
                    $bar.find('.ol-next-button').click(onNext);
                    $bar.find('.ol-cancel-button').click(onCancel);
                    $('#olDialogParam').data('assistant', this);
                    this.disableCancel(data.disablecancel);
                    this._onInit();
                }.bind(this), onNext, onCancel);
            }.bind(this);

            // no view url given => use text from dialog parameter
            if (!url) {
                adata.text = dlg.attr('msg-text');
                showOverlay(adata);
                return;
            }

            // view url given => fetch text from url
            var data = {
                step: this.step,
                param: dlg.attr('param'), //TODO
                data: this.data
            };
            var $anchor = $('.ol-root'); //TODO
            addLoadingAnimationToElement($anchor, {size: 'small'});
//            $.get(url, data, function(html) {
//                removeLoadingAnimationFromElement($anchor);
//                adata.text = html;
//                showOverlay(adata);
//            }.bind(this)).fail(function() {
//                removeLoadingAnimationFromElement($anchor);
//                console.log('*** Assistant._onFirst @'+this.step+'failed:', url, data);
//            }.bind(this));
			var m = url.match(/^post:(.*)$/i);
			var method = 'GET';
			if (m) {
				url = m[1];
				method = 'POST';
			}
            $.ajax({url: url, data: data, type: method, success: function(html) {
                removeLoadingAnimationFromElement($anchor);
                adata.text = html;
                showOverlay(adata);
            }.bind(this)}).fail(function() {
                removeLoadingAnimationFromElement($anchor);
                console.log('*** Assistant._onFirst @'+this.step+'failed:', url, data);
            }.bind(this));
        },

        _onNext: function() {
            this._buttonLoading(true);
            this._cbNext();
        },

        goto: function(newStep) {
            if (newStep < 0) {
                //TODO Fehlerbehandlung
                console.log('*** Assistant.goto('+newStep+')', this);
                this._buttonLoading(false);
            } else {
                this.prevStep = this.step;
                this.step = newStep;
                if (this.step >= this.numSteps) {
                    this._onSuccess();
                } else {
                    this._onThis();
                }
            }
        },
        
        // unconditionally do success
        gotoSuccess: function() {
            this.prevStep = this.step;
            this.step = this.numSteps;
            this._onSuccess();
        },
        
        // unconditionally do cancel
        gotoCancel: function() {
            this.disableCancel(false);
            this._onCancel();
        },
        
        _navGoto: function(newStep) {
            if (this._navMode === 'none') return;
            if (this._navMode === 'display') return;
            if (this._navMode === 'sequential' && newStep > this.step) return;
            if (this._navMode === 'visited' && !this._stepVisits[newStep]) return;
            $('#olDialogNavButton'+newStep).addClass('ol-button-loading');
            this.goto(newStep);
        },
        
        disableNext: function(disabled, loading) {
            if (disabled === undefined) disabled = true;
            if (loading === undefined) loading = false;
            var $btn = $('.ol-dialog-buttonbar .ol-next-button');
            $btn.prop('disabled', disabled);
            this._buttonLoading(loading);
        },
        
        isNextDisabled: function() {
            return $('.ol-dialog-buttonbar .ol-next-button').prop('disabled');
        },
        
        disableCancel: function(disabled) {
            if (disabled === undefined) disabled = true;
//            var $btn = $('.ol-dialog-buttonbar .ol-cancel-button');
//            $btn.prop('disabled', disabled);
            $('#overlayWindow').toggleClass('cancel-disabled', disabled);
            // #overlayWindow.cancel-disabled
        },
        
        isCancelDisabled: function() {
//            return $('.ol-dialog-buttonbar .ol-cancel-button').prop('disabled');
            return $('#overlayWindow').hasClass('cancel-disabled');
        }
        
    };

    function _noop() {}
    function _nextStep() {this.goto(this.step+1);}

    function Assistant(dlgs, options) {
        if (options === undefined) options = {};

        this._dlgs = dlgs;
        this._cbSuccess = (typeof options.success === 'function')?
              options.success.bind(this)
            : _noop;
        this._cbCancel = (typeof options.cancel === 'function')?
              options.cancel.bind(this)
            : _noop;
        this._cbInit = (typeof options.init === 'function')?
              options.init.bind(this)
            : _noop;
        this._cbNext = (typeof options.next === 'function')?
              options.next.bind(this)
            : _nextStep.bind(this);

        this.step = 0;
        this.prevStep = -1;
        this.numSteps = dlgs.length;
        this.data = (options.data === undefined)? null : options.data;

        this._navMode = options.navMode || 'none';
        var navModeNone = (this._navMode == 'none');
        this._navButtons = [];
        this._stepVisits = [];
        for (var i = 0;  i < dlgs.length;  i++) {
            this._stepVisits[i] = 0;
            if (navModeNone) continue;
            var dlg = dlgs[i];
            this._navButtons[i] = dlg['msg-navtitle'] || dlg['msg-subtitle'] || i;
        }
    }

    Assistant.prototype = assistantProto;
    Assistant.prototype.constructor = Assistant;

    // Starts an assistant dialog with the given parameters.
    // Returns the assistant object or null if the step count is 0.
    //
    // `elements` must be an array of dialog data specifiers,
    // so that each element plus `args` will be used to get each dialog data in turn.
    //
    // Additional fields for each step data:
    // data-view   URL called to return the view HTML for the given step
    //             (method is POST if parameter is prefixed by "post:", otherwise GET);
    //             takes msg-text if empty
    // data-param  Additional parameter passed with the view URL
    //
    // The overlay properties from parameters data-msg-maxwidth, data-msg-maxheight, 
    // data-msg-style are applied on first display and will not change.
    // The values are taken from the dialog parameter of the first step.
    //
    // options:
    // - success    called when assistant completed successfully (dflt: noop)
    // - called     called when assistant was aborted, ie. left by cancel/close (dflt: noop)
    // - init       called after display of each page (dflt: noop)
    // - next       called when next button was clicked (dflt: goto next page)
    //              NOTE: must call `goto` after the page actions have completed,
    //                    even if the step doesn't change!!!
    // - data       additional (modifiable) data for handlers (dflt: null)
    // - navMode    determines if and how to show navigation buttons below title (dflt=none):
    //              'none'        don't show nav buttons (dflt)
    //              'display'     shop nav buttons non-clickable
    //              'sequential'  allow to go back only
    //              'visited'     allow to go to visited steps only
    //              'all'         allow to go to any step
    //              'tab'         display as tabs allowing to go anywhere
    // Each callback is called in the context of the assistant object
    //  (ie. this is the assistant object).
    // All options fields are optional.
    //
    // The assistant object contains the following public properties:
    // - step                   the current step (0..numSteps-1 or numSteps on success) (ro)
    // - prevStep               previous step (0..numSteps-1 or -1 on start) (ro)
    // - numSteps               number of steps the current step (>= 1) (ro)
    // - data                   user data for callbacks (rw)
    // - goto(newStep)          goes to step newStep
    // - disableNext(disabled)  sets disabled status of next button to disabled
    //
    // In the DOM the assistant object is accessible via:
    // $('#olDialogParam').data('assistant')
    olDialog.assistant = function(elements, args, options) {
        if (options === undefined) options = {};

        var dlgs = [];
        for (var i = 0;  i < elements.length;  i++) {
            dlgs[i] = getDlgData(elements[i], args);
        }
        if (dlgs.length == 0) return null;

        var _options = {
            success: options.success,
            cancel: options.cancel,
            init: options.init,
            next: options.next,
            data: options.data,
            navMode: options.navMode
        }
        var assistant = new Assistant(dlgs, _options);
        assistant._onFirst();
        return assistant;
    };

}(window.olDialog = window.olDialog || {}, jQuery));
