Lang.CUSTOM_LANGS = ['at', 'ch', 'gb', 'us'];
Lang._getCustomPluralForm = function(count) {
    switch (Lang.locale) {
        case 'at':
        case 'ch':
        case 'gb':
        case 'us':
            return count == 1 ? 0 : 1;

        default:
            return 0;
    }
};

Lang._getStdPluralForm = Lang._getPluralForm;
Lang._getPluralForm = function(count) {
    if (Lang.CUSTOM_LANGS && Lang.CUSTOM_LANGS.includes(Lang.locale)) {
        return Lang._getCustomPluralForm(count);
    } else {
        return Lang._getStdPluralForm(count);
    }
};
