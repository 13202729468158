(function(olPlayerFitnessConditionDiagramm, $, undefined) {
    var darkMode = $(document.documentElement).hasClass('dark-theme');

    
    olPlayerFitnessConditionDiagramm.name_diagramme_array = [];
    
    function findColor(value) {
        // Farbe abhaengig vom Wert

        colorValue = "#cc5500";  // Default

        if (value > 30) {
            colorValue = "#7D7D7D";
        }
        if (value > 69) {
            colorValue = "#A5AF40 ";
        }

        return colorValue;

    }

    function checkIntersectionAndDraw(x1, x2, y1, y2, line_name_canvas) {

        var canvas = document.getElementById(line_name_canvas);
        var ctx = canvas.getContext('2d');


        // Farbe Ausgangspunkt
        startColor = '#cc5500'; //default

        if (y1 > 30) {
            startColor = '#7D7D7D';
        }

        if (y1 > 69) {
            startColor = '#A5AF40 ';
        }

        // Pruefe auf Schnittpunkte mit den Grenzen y = 31 -> grey  y = 70 -> green
        // zeichnen der Linie zwischen den Punkten
        // Rueckgabe der Koordinaten des neuen Startpunktes -> Koordinaten des zweiten Punktes
        // Gleichung Gerade zwischen den Punkten
        // y = m*x+b

        m = (y2 - y1) / (x2 - x1);
        b = y1 - (m * x1);

        // Gleichung
        x30 = (30 - b) / m;  // red
        y30 = 30 * 1.3;
        x31 = (31 - b) / m;  // grey
        y31 = 31 * 1.3;
        x70 = (70 - b) / m;  // green
        y70 = 70 * 1.3;

        // Schnittpunkt fuer Wechsel red - grey
        intersectionRedGrey = false;  // default
        if (y1 < 31 && y2 > 30) {
            intersectionRedGrey = true;
        }
        // Schnittpunkt fuer Wechsel grey - green
        intersectionGreyGreen = false; // default
        if ((y1 < 70 && y2 > 69)) {
            intersectionGreyGreen = true;
        }

        // Schnittpunkt Wechsel green - grey
        intersectionGreenGrey = false; // default
        if ((y1 > 69 && y2 < 70)) {
            intersectionGreenGrey = true;
        }

        // Schnittpunkt fuer Wchsel grey - red
        intersectionGreyRed = false; // default
        if (y1 > 30 && y2 < 31) {
            intersectionGreyRed = true;
        }





        // Zeichnen der linie zwischen den zwei Punkten
        ctx.beginPath();
        ctx.moveTo(x1, 130 - (y1 * 1.3)); // Ausgangspunkt 

        // Schnittpunkte beruecksichtigen
        // steigende Gerade
        if (y1 < y2) {
            // Wechsel red - grey
            if (intersectionRedGrey) {
                // wechsel red - grey
                // zwischenpunkt x31,31
                ctx.lineTo(x31, 130 - y31);
                ctx.lineWidth = 2;
                ctx.strokeStyle = startColor;
                ctx.stroke();
                // neuer Start Linie
                startColor = '#7D7D7D';
                ctx.beginPath();
                ctx.moveTo(x31, 130 - y31); // Ausgangspunkt 
            }
            // Wechsel grey - green
            if (intersectionGreyGreen) {
                // zwischenpunkt x31,31
                ctx.lineTo(x70, 130 - y70);
                ctx.lineWidth = 2;
                ctx.strokeStyle = startColor;
                ctx.stroke();
                // neuer Start Linie
                startColor = '#A5AF40 ';
                ctx.beginPath();
                ctx.moveTo(x70, 130 - y70); // Ausgangspunkt 
            }
            // Rest der Linie zeichnen
            ctx.lineTo(x2, 130 - (y2 * 1.3));
            ctx.lineWidth = 2;
            ctx.strokeStyle = startColor;
            ctx.stroke();
        }

        // fallende Gerade
        if (y1 > y2) {
            // Wechsel green - grey
            if (intersectionGreenGrey) {
                // zwischenpunkt x70,70
                ctx.lineTo(x70, 130 - y70);
                ctx.lineWidth = 2;
                ctx.strokeStyle = startColor;
                ctx.stroke();
                // neuer Start Linie
                startColor = '#7D7D7D';
                ctx.beginPath();
                ctx.moveTo(x70, 130 - y70); // Ausgangspunkt 
            }
            // Wechsel grey - red
            if (intersectionGreyRed) {
                // zwischenpunkt x31,31
                ctx.lineTo(x31, 130 - y31);
                ctx.lineWidth = 2;
                ctx.strokeStyle = startColor;
                ctx.stroke();
                // neuer Start Linie
                startColor = '#cc5500';
                ctx.beginPath();
                ctx.moveTo(x31, 130 - y31); // Ausgangspunkt 
            }
            // Rest der Linie zeichnen
            ctx.lineTo(x2, 130 - (y2 * 1.3));
            ctx.lineWidth = 2;
            ctx.strokeStyle = startColor;
            ctx.stroke();
        }

        // Parallele x-Achse
        if (y1 === y2) {
            ctx.lineTo(x2, 130 - (y2 * 1.3));
            ctx.lineWidth = 2;
            ctx.strokeStyle = startColor;
            ctx.stroke();
        }

        // neuen Startwert setzen
        startX = x2;
        startY = y2;

        return;

    }

    olPlayerFitnessConditionDiagramm.drawById = function(name) {
        
        for(z=0;z<olPlayerFitnessConditionDiagramm.name_diagramme_array.length;z++){
            if(name == olPlayerFitnessConditionDiagramm.name_diagramme_array[z][3])
                olPlayerFitnessConditionDiagramm.draw(olPlayerFitnessConditionDiagramm.name_diagramme_array[z][0],olPlayerFitnessConditionDiagramm.name_diagramme_array[z][1],olPlayerFitnessConditionDiagramm.name_diagramme_array[z][2],olPlayerFitnessConditionDiagramm.name_diagramme_array[z][3],true);           
        }
    };
    
    olPlayerFitnessConditionDiagramm.draw = function(values, values2, currentWeek, name, resize) {
        var t = $('#'+name);
        if (t.length < 1) {
            return;
        }
        t.css('display', 'none');
        t.width(t.parent().width());
        line_name_canvas = 'line_'+name+'_canvas';
        
        var canvas = document.getElementById(line_name_canvas);
        canvas.width = t.width() - 115; // Breite abzueglich der Breite der Textspalte y-Achse default fuer links 75px, rechts 40px
        $('#'+name+'_line_diagramme').width(canvas.width);
        $('#blocks').css('width', canvas.width);
        canvas.height = 130; // feste vorgegebene Hoehe

       if (canvas.getContext) {
        var ctx = canvas.getContext('2d');
                var startx = canvas.width / 20;
                var starty = 10;
                var width = 0;
                var height = 5;
                var spaceBetweenY = 0;
                var spaceBetweenX = canvas.width / 10;
                var step = 20; // Wert fuer die Schrittweite bei den Werten
                var turny = 3;
                var turnx = 5;
                var heightCanvas = canvas.height;
                // Werte

        // Gitter
        // Rand
        var lineColor = darkMode ? '#2f2f2f' : '#d2d2d2';

        ctx.beginPath();
        ctx.moveTo(0, 0);
        ctx.lineTo(canvas.width, 0); 
        ctx.moveTo(0, 130);
        ctx.lineTo(canvas.width, 130);
        ctx.lineWidth = 3;
        ctx.strokeStyle = lineColor;
        ctx.stroke();

        // x-Achse
        for (i = 50; i < 130; i = i + 50) {
            ctx.beginPath();
            ctx.moveTo(0, 130 - (i * 1.3));
            ctx.lineTo(canvas.width, 130 - (i * 1.3));
            ctx.lineWidth = 2;
            ctx.strokeStyle = lineColor;
            ctx.stroke();
        }

        // Y-Achse
        // var j = 1;
        // var k = 0; // Zaehler values2 -> Werte Blockdiagramm
        // for (i = canvas.width / 20; i < canvas.width; i = i + (canvas.width / 20)) {
        //     ctx.beginPath();
        //     if ((j % 2) == 0) {
        //         ctx.moveTo(i, 130);
        //     } else
        //     {
        //         if (k > (values2.length - 1)) {
        //             ctx.moveTo(i, 130);
        //         } else {
        //             //ctx.moveTo(i, 130 - (values2[k]['dailyCondition'] * 20 * 1.3));
        //             ctx.moveTo(i, 130);
        //         }
        //         k++;
        //     }
        //     ctx.lineTo(i, 0);
        //     if ((j % 2) == 0) {
        //         ctx.lineWidth = 2;
        //         ctx.strokeStyle = '#eeeeee';
        //     } else
        //     {
        //         ctx.lineWidth = 1;
        //         ctx.strokeStyle = 'rgba(210,210,210,0.7)';
        //     }
        //     j++;
            
        //     ctx.stroke();
        // }

        // Linie zeichnen, Koordinaten fuer Kreise speichern

        // Linienfarbe abhaengig vom Wert
        // red 1-30
        // grau 31-69
        // gruen >69

        // Ausfuehren nur wenn Daten vorliegen
        if(values.length>0){
        // =================================================
        circle = [];
        circles = [];
        //ctx.beginPath();
        //ctx.moveTo(startx, 130 - (1.3 * values[0]['fitness'])); // Ausgangspunkt -> Nullpunkt koordinatensystem
        circle = [startx, values[0]['fitness']];
        circles.push(circle);

        // Startwerte erster Wert
        startValue = values[0]['fitness']; // Startwert        
        startColor = findColor(values[0]['fitness']); // Farbe definieren
        startX = startx;
        startY = values[0]['fitness'];

        // Hintergrundlinien
        ctx.beginPath();
        ctx.moveTo(startx, 130 - (values[0]['fitness'] * 1.3)); // Ausgangspunkt 


        // max Werte 10
        if (values.length > 10) {
            maxValues = 10
        } else
        {
            maxValues = values.length;
        }
        
        for (i = 1; i < maxValues; i++)
        {
            ctx.lineTo((startx + (i * spaceBetweenX)), 130 - (values[i]['fitness'] * 1.3));
        }
        ctx.lineWidth = 5;
        ctx.strokeStyle = darkMode ? '#18181B': 'white';
        ctx.stroke();


        // Linien zeichnen
        for (i = 1; i < maxValues; i++)
        {
            // Pruefen Schnittpunkte zwischen den zwei Punkten mit den Grenzen fuer grey, green
            // checkIntersection(x1,y1,x2,y2)
            checkIntersectionAndDraw(startX, (startx + (i * spaceBetweenX)), startY, (values[i]['fitness']), line_name_canvas);

            //ctx.lineTo(startx + (i * spaceBetweenX), 130 - (1.3 * values[i]['fitness']));
            circle = [startx + (i * spaceBetweenX), values[i]['fitness']];
            circles.push(circle);

        }

                // Linien zeichnen Ende
        $currentWeekSet = false;
        // Kreise an den Wertepunkten zeichnen
        for (i = 0; i < circles.length; i++) {

            // Kreise ala html 
            if (!resize){$('#'+name+'_circle_area').append("<div class='circleOut' id='"+name+"_circleOut" + i + "'></div>");}
            $('#'+name+'_circleOut' + i).css('top', (130 - (1.3 * circles[i][1])) - 10.3);
            $('#'+name+'_circleOut' + i).css('left', circles[i][0] - 8.1);
 
            //
             if (!resize){$('#'+name+'_circle_area').append("<div class='circle' id='"+name+"_circle" + i + "'></div>");}
            $('#'+name+'_circle' + i).css('top', (130 - (1.3 * circles[i][1])) - 12);
            $('#'+name+'_circle' + i).css('left', circles[i][0] - 11);
            color = "#cc5500";
            if (circles[i][1] > 30) {
                color = "#7D7D7D";
            }
            if (circles[i][1] > 69) {
                color = "#A5AF40 ";
            }
            $('#'+name+'_circle' + i).css('background', color);
            
                
            // innerer Kreis
            if (!resize){$('#'+name+'_circle_area').append("<div class='hoverEffect'><div class='circle_in' id='"+name+"_circle_in" + i + "'></div><div class='flag' id='"+name+"_flag" + i + "'><span class='flagValue' id='"+name+"_flagText" + i + "'>" + Math.round( values[i]['fitness'] ) + "%</span></div></div>");}
            $('#'+name+'_flag' + i).css('border-left', '2px solid ' + color);
            $('#'+name+'_flagText' + i).css('background', color);
            $('#'+name+'_flag' + i).css('top', (130 - (1.3 * circles[i][1])) - 6.3 - 43);
            $('#'+name+'_flag' + i).css('left', circles[i][0] - 4.1 + 4);
            $('#'+name+'_flag' + i).css('margin-top', '-3px');
           
            if (values[i]['week'] === currentWeek) {
 
                $('#'+name+'_circle' + i).css('top', (130 - (1.3 * circles[i][1])) - 9);
                $('#'+name+'_circle' + i).css('left', circles[i][0] - 8);
                $('#'+name+'_circle' + i).css('width', '17px');
                $('#'+name+'_circle' + i).css('height', '17px');

                $('#'+name+'_circle_in' + i).css('top', (130 - (1.3 * circles[i][1])) - 7);
                $('#'+name+'_circle_in' + i).css('left', circles[i][0] - 6);
                $('#'+name+'_circle_in' + i).css('width', '13px');
                $('#'+name+'_circle_in' + i).css('height', '13px'); 

 
                // innerer,innerer Kreis
                if (!resize){$('#'+name+'_circle_area').append("<div class='circle_in_in' id='"+name+"_circle_in_in" + i + "'></div>");}               
                $('#'+name+'_circle_in_in' + i).css('top', (130 - (1.3 * circles[i][1])) - 5);
                $('#'+name+'_circle_in_in' + i).css('left', circles[i][0] - 4);
                $('#'+name+'_circle_in_in' + i).css('background', color);
                $('#'+name+'_currentWeek').css('width', canvas.width / 10);
                $('#'+name+'_currentWeek').css('left', (canvas.width / 10) * (i) + 72); // Padding-right vom Text 15 
                if (currentWeek > 44) {
                    $('#'+name+'_week').html(currentWeek - 44);
                } else
                {
                    $('#'+name+'_week').html(currentWeek);
                }
                document.getElementById(name+'_circle_in_in' + i).addEventListener("mouseover", function(event) {
                    id = event.srcElement.id.substr(-1, 1);
                    $('#'+name+'_circle_in' + id).css('opacity', '0');
                    $('#'+name+'_flag' + id).css('display', 'block');
                });
                document.getElementById(name+'_circle_in_in' + i).addEventListener("mouseout", function(event) {
                    id = event.srcElement.id.substr(-1, 1);
                    $('#'+name+'_circle_in' + id).css('opacity', '1');
                    $('#'+name+'_flag' + id).css('display', 'none');
                });
                $currentWeekSet = true;
                
                // Rahmen für aktuelle Woche setzen
        
               $('#'+name+'_currentWeek').css('left', ((canvas.width /10)*i)+88);
               $('#'+name+'_currentWeek').css('height', 165);
               $('#'+name+'_week').css('top', 135);

               }
            else
            {
                $('#'+name+'_circle' + i).css('top', (130 - (1.3 * circles[i][1])) - 7);
                $('#'+name+'_circle' + i).css('left', circles[i][0] - 6);
                $('#'+name+'_circle' + i).css('width', '13px');
                $('#'+name+'_circle' + i).css('height', '13px');

                $('#'+name+'_circle_in' + i).css('top', (130 - (1.3 * circles[i][1])) - 5);
                $('#'+name+'_circle_in' + i).css('left', circles[i][0] - 4);
                $('#'+name+'_circle_in' + i).css('width', '9px');
                $('#'+name+'_circle_in' + i).css('height', '9px'); 
            }

        }
        // currentWeek == week
        if (!$currentWeekSet)
        {
            // keine Uebereinstimmung der angezeigten Wochen mit der aktuellen Woche
            $('#'+name+'_currentWeek').css('border', 'none');
            $('#'+name+'_week').css('border', 'none');
        }

        }
        else
        {
            // keine Uebereinstimmung der angezeigten Wochen mit der aktuellen Woche
            $('#'+name+'_currentWeek').css('border', 'none');
            $('#'+name+'_week').css('border', 'none');
        }
        }
        t.css('display', 'block');
 
        return;
    }
    ;
    }(window.olPlayerFitnessConditionDiagramm = window.olPlayerFitnessConditionDiagramm || {}, jQuery));