(function(teamSettings, $, undefined) {

    //--- line-up  ---
    var TYPE_FIELD_POSITION = 1;
    var TYPE_LIST_LINE_UP = 2;
    var TYPE_LIST_SUB = 3;
    var TYPE_LIST_OTHER = 4;

    var dragState = new Object();
    dragState.dragging = false;
    dragState.source = false;
    dragState.draggingSourceType = false;
    dragState.sourceIndex = false;
    dragState.forbidden = false;
    dragState.swapItem = false;
    dragState.draggableEffectCounter = 0;
    dragState.hoveredListItem = false;
    dragState.selectedPlayer = false;
    dragState.selecting = false;
    dragState.specialDrop = false;
    dragState.specialTarget = false;
    dragState.fadeEffect = new Object();
    dragState.fadeEffect.hoveredOpacity = 1;
    dragState.fadeEffect.selectedOpacity = 1;
    dragState.fadeEffect.renderHovered = false;
    dragState.fadeEffect.renderSelected = false;
    dragState.fadeEffect.renderLines = true;

    dragState.selectedPlayingFieldItem = false;
    dragState.selectedListItem = false;

    dragState.hoveredPlayingFieldItem = false;
    dragState.hoveredListItem = false;

    var requestID = false;
    var helperWidth = 120;
    var helperHeight = 28;
    var onHoverDroppable = false;
    var onHoverDroppableDraggable = false;
    var onHoverDroppableState = false;
    var insideDroppableCount = 0;
    var lockPlayerType = false;

    var squadSize;
    var userStateId;
    var viewPlayingFieldPlayerAttributes;
    var lineColor;

    var imgServer = "";
    var maximized = false;
    var detailsActive = false;

    var playerPositionFilter = 0;
    var playerSelectedFilter = false;

    window.touchPunchDelay = 250;

    teamSettings.page = 'lineup';

    teamSettings.sortList = function(target, save) {
        if (typeof save === typeof undefined) save = true;
        // remove empty bench slot icons before sorting
        if ($(target).hasClass('ol-linup-player-bench-wrapper') || $(target).is('#collapseOther') || $(target).is('#collapseLineUp')) {
            var $emptyBenchIcons = $('.ol-linup-player-bench-wrapper .ol-lineup-player-icon.ol-lineup-empty-bench-slot');
            $emptyBenchIcons.each(function(i, el) {
                $(el).remove();
            });
        }
        var children = $(target).children(".ol-team-settings-line-up-row").detach();
        //(position & (1 << 8)) AS posTW, (position & (1 << 7)) AS posAV, (position & (1 << 6)) AS posIV, (position & (1 << 5)) AS posDM, (position & (1 << 4)) AS posOM, (position & (1 << 3)) AS posST
        var sorted = children.sort(function(b, a) {
            var dragA = $(a).find(".ol-team-settings-player-drag-and-drop");
            var dragB = $(b).find(".ol-team-settings-player-drag-and-drop");
            var posA = parseInt(dragA.attr("data-player-positions"));
            var posB = parseInt(dragB.attr("data-player-positions"));

            var idA = parseInt(dragA.attr("data-player-id"));
            var idB = parseInt(dragB.attr("data-player-id"));

            if( (posA & (1 << 8)) > (posB & (1 << 8))) return -1;
            if( (posA & (1 << 8)) < (posB & (1 << 8))) return 1;
            if( (posA & (1 << 7)) > (posB & (1 << 7))) return -1;
            if( (posA & (1 << 7)) < (posB & (1 << 7))) return 1;
            if( (posA & (1 << 6)) > (posB & (1 << 6))) return -1;
            if( (posA & (1 << 6)) < (posB & (1 << 6))) return 1;
            if( (posA & (1 << 5)) > (posB & (1 << 5))) return -1;
            if( (posA & (1 << 5)) < (posB & (1 << 5))) return 1;
            if( (posA & (1 << 4)) > (posB & (1 << 4))) return -1;
            if( (posA & (1 << 4)) < (posB & (1 << 4))) return 1;
            return (idA < idB ? 1 : -1);
        });
        sorted.appendTo(target);
        coloringRows(target);
        if (save) {
            saveLineUp();
        }

        // calculate empty slots and add icons to bench
        if ($(target).hasClass('ol-linup-player-bench-wrapper') || $(target).is('#collapseOther') || $(target).is('#collapseLineUp')) {
            _initEmptyBenchPlaceholder();
        };
    };

    teamSettings.sortByPosition = function(event) {
        event.preventDefault();
        event.stopPropagation();
        teamSettings.sortAllByPosition();
    };

    teamSettings.sortAllByPosition = function() {
        $('#playerPosSort').hide();
        $('#playerAttrSort').attr('data-sorting', 'false');
        $('.ol-team-settings-line-up-rows').each(function(index, list) {
            teamSettings.sortList(list, false);
        });
    };

    teamSettings.sortByAttribute = function(event, toggle) {
        event.preventDefault();
        event.stopPropagation();
        $('#playerPosSort').show();
        var sorting = $('#playerAttrSort').attr('data-sorting');
        if (toggle)
            $('#playerAttrSort').attr('data-sorting', (sorting === 'desc' ? 'asc' : 'desc'));
        sorting = (sorting == 'false' ? 'asc' : sorting);
        if (!toggle)
            sorting = (sorting == 'asc' ? 'desc' : 'asc');
        var attr = $('#dropdownPlayerAttribute').dropdown().value();
        $('.ol-team-settings-line-up-rows').each(function(index, list) {
            var children = $(list).children(".ol-team-settings-line-up-row").detach();
            var sorted = children.sort(function(a, b) {
                if (sorting === 'desc') {
                    // swap
                    b = [a, a = b][0];
                }
                var attrA = JSON.parse($(a).find(".ol-gui-lineup-attr").attr('data-player-attributes'));
                var attrB = JSON.parse($(b).find(".ol-gui-lineup-attr").attr('data-player-attributes'));
                var valA = attrA[attr].value;
                var valB = attrB[attr].value;
                if (valA < valB) return 1;
                if (valA > valB) return -1;

                var idA = $(a).find(".ol-team-settings-player-drag-and-drop").attr("data-player-id");
                var idB = $(b).find(".ol-team-settings-player-drag-and-drop").attr("data-player-id");
                return (idA < idB ? 1 : -1);
            });
            sorted.appendTo(list);
            coloringRows(list);
        });
    };
    var firstTimeSelectPlayer = true;
    teamSettings.init = function(_userStateId, _squadSize, _viewPlayingFieldPlayerAttributes, _imgServer, _lockPlayerType) {
        lockPlayerType = (typeof _lockPlayerType === typeof undefined ? false : _lockPlayerType);
        firstTimeSelectPlayer = true;
        imgServer = _imgServer;
        squadSize = _squadSize;
        userStateId = _userStateId;
        viewPlayingFieldPlayerAttributes = _viewPlayingFieldPlayerAttributes;

        var helper = $("<div>");
        lineColor = helper.appendTo("body").addClass("ol-state-primary-color-" + userStateId).css("background-color");
        helper.remove();
        _updateTacticDropdown();

        playerPositionFilter = 0;
        playerSelectedFilter = 0;
        insideDroppableCount = 0;
        lastScrollingOffset = 0;
        dragState = new Object();
        dragState.dragging = false;
        dragState.source = false;
        dragState.draggingSourceType = false;
        dragState.sourceIndex = false;
        dragState.forbidden = false;
        dragState.swapItem = false;
        dragState.draggableEffectCounter = 0;
        dragState.hoveredListItem = false;
        dragState.selectedPlayer = false;
        dragState.selecting = false;
        dragState.specialDrop = false;
        dragState.specialTarget = false;
        dragState.fadeEffect = new Object();
        dragState.fadeEffect.hoveredOpacity = 1;
        dragState.fadeEffect.selectedOpacity = 1;
        dragState.fadeEffect.renderHovered = false;
        dragState.fadeEffect.renderSelected = false;
        dragState.fadeEffect.renderLines = true;

        dragState.selectedPlayingFieldItem = false;
        dragState.selectedListItem = false;

        dragState.hoveredPlayingFieldItem = false;
        dragState.hoveredListItem = false;

        helperWidth = 120;
        helperHeight = 28;
        onHoverDroppable = false;
        onHoverDroppableDraggable = false;
        onHoverDroppableState = false;

//        animationOngoing = false;
        unsetAnimationOngoing = false;

        maximized = false;

        // input element react to enter key
        $(".lineUpName").keyup(function(e) {
            if (e.which == 13)
                $(this).blur();
        });

        setTimeout(function() {
            var childs = $('.ol-team-settings-line-up-rows .ol-team-settings-player-drag-and-drop');
            if (childs.length > 0) {
                selectPlayer(childs.first().attr('data-player-id'), true);
            }
        }, 50);
        resizeWindow();

        // init draggable
        $(".ol-pitch-position .ol-team-settings-player-drag-and-drop").each(function() {
            var attr = $(this).attr('data-player-id');
            if (typeof attr !== typeof undefined && attr !== false) {
                initDraggable(this);
            }
        });

        if ($("#collapseSub").children().length == 1) {
            $("#collapseSub").collapse("hide");
        }

        $(".ol-team-settings-line-up-rows").on('shown.bs.collapse', function() {
        });

        function _checkSubPlayerListSize() {
            var subSize = squadSize - 11;
            var notAllowedSubSquadSize = $('#collapseSub').children('.ol-team-settings-line-up-row').length >= subSize;
            if (notAllowedSubSquadSize) {
                olMsgBox.msg($(".lineUpName").val(), $("#msgWrongSubPlayerCount").html());
                return false;
            }
            return true;
        }

        $(".ol-team-settings-line-up-rows").each(function(index, rows) {
            // player lists
            $(rows).droppable({
                tolerance: "touch",
                accept: ".ol-team-settings-player-drag-and-drop",
                out: function(event, ui) {
                    $(event.target).removeClass('ol-team-settings-hover-list');
                },
                over: function(event, ui) {
                    if (dragState.dragging) {
                        dragState.target = $(event.target);

                        var playerId = $(ui.draggable).attr("data-player-id");
                        var row = getListItem(playerId);
                        var sourceList = $(row).parents('.ol-team-settings-line-up-rows');
                        if (!$(event.target).is($('#collapseLineUp')) && !sourceList.is(event.target)) {
                            $(event.target).addClass('ol-team-settings-hover-list');
                        }
                    } 
                },
                drop: function(event, ui) {
                    // ignore line up (only player switches allowed)
                    if ($(event.target).is($('#collapseLineUp'))) return;

                    // ignore if source and target lists are the same
                    if ($(event.target).is($(ui.draggable).parents('.ol-team-settings-line-up-rows'))) return;

                    // check if sub squad size is valid
                    if ($(event.target).is($('#collapseSub'))) {
                        if(!_checkSubPlayerListSize()) return false;
                    }

                    var playerId = $(ui.draggable).attr("data-player-id");

                    // remove from playing field if necessary
                    if (dragState.draggingSourceType === TYPE_FIELD_POSITION) {
                        updateAvgValues();
                        clearFieldPositionItem(getFieldPositionItem(playerId));
                    }

                    var row = getListItem(playerId);

                    // add player to list
                    addToList(row, event.target);
                }
            });

            // init droppable
            $(rows).find('.ol-team-settings-line-up-row').each(function(index, row) {
                initDroppableList(row);
                initDraggableList($(row).find('.ol-team-settings-player-drag-and-drop'));
            });

            if ($(rows).next().hasClass("ol-team-settings-player-list-empty")) {
                if ($(this).children().length === 0)
                    $(rows).next().addClass('ol-is-empty');
                else
                    $(rows).next().removeClass('ol-is-empty');
            }
            coloringRows($(this));
            var first = true;
            $(this).attr("data-out", true);


            // init empty droppable
            $(".ol-team-settings-player-list-empty").each(function() {
                if (!$(this).parent().is("#collapseLineUpCollapse")) {
                    $(this).droppable({
                        tolerance: 'intersect',
                        accept: ".ol-team-settings-player-drag-and-drop, .ol-team-settings-line-up-row",
                        drop: function(event, ui) {
                            var playerId = $(ui.helper).attr("data-player-id");
                            var targetList = $(this).prev();

                            // ignore if source and target lists are the same
                            if(targetList.is($(ui.draggable).parents('.ol-team-settings-line-up-rows'))) return;

                            $(targetList).removeClass('ol-team-settings-hover-list');
                            // check if sub squad size is valid
                            if($(targetList).is($('#collapseSub')))
                            {
                                if(!_checkSubPlayerListSize()) return false;
                            }
                            addPlayerToEmptyList(playerId, targetList);
                            setTimeout(function() {
                                _updateVisibleRows();
                            }, 0);

                        }
                    });
                }
            });
        });

        // init droppable
        $('.ol-team-settings-player-drag-and-drop').each(function() {
            initDroppable(this);
        });
        var unloadPage = function() {
            if (requestID !== false) cancelAnimationFrame(requestID);
            $("#ol-root").unbind("unloadPage", unloadPage);
        };

        $( "#ol-root" ).bind("unloadPage", unloadPage);
        updateAvgValues();
        _updateVisibleRows();
        teamSettings.sortList($("#collapseLineUp"), false);
        teamSettings.sortList($("#collapseOther"), false);
        teamSettings.sortList($("#collapseSub"), false);
        updatePlayerBanIcons();
        updateDisabledRows();

        _updateLeagueAndFriendlyPositionIcons();

        $('#lineUpPlayerList').on('scroll', scrollShadowHandler)

        if (olAppInfo.APP_MODE && olAppInfo.APP_PLATFORM == "iphone") {
            $('#scrollRoot').scroll(appHeaderHandler)
        } else if (olAppInfo.APP_MODE) {
            $(document).on('scroll', appHeaderHandler);
        }

        //console.log("Registering unload...");
        onUnloadGlobal(function() {
            //console.log("Unloading Lineup Page")
            $(document).off('scroll', appHeaderHandler);
            olMobileApp.showHeader();
            olMobileApp.showBottomNav();
            lineupFullScreen = false;
        })

        // init empty benchslots
        _initEmptyBenchPlaceholder()
    };

    function _initEmptyBenchPlaceholder() {
        var benchLimit = $('#maxBenchSize').attr('data-value');
        var $emptyBenchIcon = $('.ol-lineup-player-icon.ol-lineup-empty-bench-slot');
        var $bench = $('.ol-team-settings-line-up-rows.ol-linup-player-bench-wrapper');
        var playersOnBench = $bench.children().length;
        for(var i = 0; i < (benchLimit - playersOnBench); i++) {
            $emptyBenchIcon.clone().appendTo($bench);
        }
    }

    function validEnvironment() {
        return $("#divPitch").length > 0;
    }

    window.addEventListener('resize', resizeWindow, false);

    function onDragPlayerStart(event, ui) {
        dragState.draggingSourceType = TYPE_FIELD_POSITION;
        olMobileApp.lockHeader();
        dragState.source = ui.item;
        dragState.dragging = true;
        var playerId = $(ui.helper).attr("data-player-id");
        var listItem = getListItem(playerId);
        dragState.sourceIndex = listItem.index();
        if (listItem.parent().is($("collapseLineUp"))) {
            dragState.swapping = true;
        }
        //selectPlayer(playerId, true);
    };

    function onDragPlayerStop(event, ui) {
        dragState.dragging = false;
        olMobileApp.unlockHeader();
        //deselectPlayer();
    };

    function resizeWindow() {
        if (!validEnvironment()) return;
        if ($('body').hasClass('ol-xs')) return;

        var details = $('.ol-team-settings-player-details').children();
        if (details.length > 0) {
            var isSelected = details.first().hasClass('ol-player-details-selected');
            if (!isSelected)
                details.first().addClass('ol-player-details-selected');
            var newHeight = $('#playingFieldContainer').outerHeight() + 35 + details.outerHeight();
            //$('#settingsContent').height(newHeight);
            if (!isSelected)
                details.first().removeClass('ol-player-details-selected');
        } else {

            //$('#settingsContent').height($(window).height() - 65 - 10);
        }
    }

    function initDraggable(draggable) {
        $(draggable).draggable({
            helper: function(event, item) {
                var c = $(this).clone(true);
                c.css("width", helperWidth); c.css("height", helperHeight);
                c.css('position','absolute');
                var left = helperWidth / 2;

                if (olGUI.isTouchCapable()) {
                    var top = helperHeight * 2;
                } else {
                    var top = 0;
                }

                $(this).draggable("option", "cursorAt", {
                    left: Math.floor(left),
                    top: Math.floor(top)
                });
                return c;
            },
            delay: 150,
            start: onDragPlayerStart,
            stop: onDragPlayerStop,
           /* refreshPositions: true, */
            appendTo: "#ol-root"
        }).disableSelection();
    }
    
    function dispatchLineupSwitchEvent(sourcePlayerId, position)
    {
        var event = {};
        event.playerId = sourcePlayerId;
        event.position = position;
        $('#ol-root').trigger('lineupEditorSetPlayer', [ event ]);
    }

    function initDroppableList(target) {
        $(target).droppable({
            tolerance: 'pointer',
            over: function(event, ui) {
                //console.info('over');
                // under special circumstances dragState.target will already be set - so check parents id
                var list = $(event.target).parents('.ol-team-settings-line-up-rows');
                if (list.attr('id') !== 'collapseLineUp') {
                    console.info('no swap because wrong target list id');
                    return;
                }
                insideDroppableCount++;
                //console.info($(event.target).find('.ol-team-settings-player-drag-and-drop').attr('data-player-id') + ' count in ' + insideDroppableCount);
                var swapItem = $(event.target);
                var targetPlayerId = swapItem.find(".ol-team-settings-player-drag-and-drop").attr("data-player-id");
                var srcPlayerId = $(ui.helper).attr("data-player-id");
                //console.info(targetPlayerId + " = " + srcPlayerId);
                if (targetPlayerId != srcPlayerId) {
                    $(ui.helper).css("cursor", "pointer");
                    dragState.swapping = true;
                    dragState.forbidden = false;
                    ui.helper.addClass("ol-swapping");

                    ui.helper.append($("<span style='background-color:" + lineColor + "' class='helper1'></span><span style='border-color:transparent " + lineColor + " transparent transparent' class='helper2'></span>"));
                    dragState.swapItem = swapItem;
                    var playingFieldItem = getFieldPositionItem(targetPlayerId);
                    if (playingFieldItem.length > 0) {
                        dragState.hoveredPlayingFieldItem = playingFieldItem;
                    } else {
                        dragState.hoveredPlayingFieldItem = false;
                    }
                    dragState.hoveredListItem = $(dragState.swapItem);

                    $(".ol-team-settings-sortable-placeholder").hide();
                    $(dragState.hoveredListItem).addClass("ol-swapping-out");
                } else {
                    dragState.swapping = false;
                    dragState.forbidden = true;
                };
            },
            out: function(event, ui) {
                // do this at the end of the javascript loop -> otherwise insideDroppableCount could have the wrong count
                setTimeout(function() {
                    // under special circumstances dragState.target will already be set - so check parents id
                    var list = $(event.target).parents('.ol-team-settings-line-up-rows');
                    if (list.attr('id') !== 'collapseLineUp') {
                        console.info('no swap because wrong target list id');
                        return;
                    }

                    insideDroppableCount--;
                    $(event.target).removeClass("ol-swapping-out");
                    //console.info($(event.target).find('.ol-team-settings-player-drag-and-drop').attr('data-player-id') + ' count out ' + insideDroppableCount);
                    if (insideDroppableCount == 0) {
                        $(ui.helper).css("cursor", "auto");
                        ui.helper.removeClass("ol-swapping");
                        dragState.swapping = true;
                        dragState.hoveredPlayingFieldItem = false;
                        dragState.hoveredListItem = false;
                        ui.helper.find("span.helper1, span.helper2").remove();
                        dragState.swapping = false;
                    }
                }, 0);
            },
            drop: function(event, ui) {
                if ($('.ol-pitch-position.ui-droppable-hover').size() > 0) {
                    return true;
                }

                // under special circumstances dragState.target will already be set - so check parents id
                var list = $(event.target).parents('.ol-team-settings-line-up-rows');
                if (list.attr('id') !== 'collapseLineUp') {
                    return;
                }

                // reset for swapping icon management
                insideDroppableCount = 0;
                // remove all swapping out icons from list. placeholder will be deleted anyways
                $(".ol-swapping-out").removeClass("ol-swapping-out");

                var targetPlayerId = $(event.target).find(".ol-team-settings-player-drag-and-drop").attr("data-player-id");
                var sourcePlayerId = $(ui.helper).attr("data-player-id");
                var fieldItem = getFieldPositionItem(targetPlayerId);
                var positionType = $(fieldItem).parent().attr("data-player-position-index"); 
                var playerPositionType = $(ui.helper).attr("data-player-type");

                if(lockPlayerType === false || (positionType === '256' && playerPositionType === '1') || (positionType !== '256' && playerPositionType === '2'))
                {
                    if (targetPlayerId != sourcePlayerId) {
                        if (typeof targetPlayerId === "undefined") {
                            // insert into empty position
                            insertPlayer(sourcePlayerId, $(target).attr("data-position"));
                        } else {
                            // switch player position
                            switchPlayer(targetPlayerId, sourcePlayerId, false);
                        }
                        $(ui.item).remove();
                    }
                }
            }
        });
    }

    function initDraggableList(target) {
        $(target).draggable({
            helper: function(event, item) {
                var c = $(this).clone(true);
                c.css("width", helperWidth); c.css("height", helperHeight);
                c.css('position', 'absolute');
                var left = helperWidth / 2;

                if (olGUI.isTouchCapable()) {
                    var top = helperHeight * 2;
                } else {
                    var top = 0;
                }

                $(this).draggable("option", "cursorAt", {
                    left: Math.floor(left),
                    top: Math.floor(top)
                });
                return c;
            },
            delay: 150,
            start: function(event, ui) {
                onDragPlayerListStart(event, ui, this);
            },
            stop: onDragPlayerListStop,
            refreshPositions: true,
            appendTo: "#selectedLineUp"
        }).disableSelection();
    }

    function onDragPlayerListStart(event, ui, item) {
        console.info('PlayerListDragStart:');
        olMobileApp.lockHeader();
        dragState.dragging = true;
        var sourceList = $(item).parents('.ol-team-settings-line-up-rows');

        $(item).addClass("ol-dragging");
        if ($(sourceList).is($("#collapseLineUp"))) {
            dragState.draggingSourceType = TYPE_LIST_LINE_UP;
            console.info('lineup');
        } else if($(sourceList).is($("#collapseOther"))) {
            dragState.draggingSourceType = TYPE_LIST_OTHER;
            console.info('other');
        } else if($(sourceList).is($("#collapseSub"))) {
            dragState.draggingSourceType = TYPE_LIST_SUB;
            console.info('sub');
        }
    }

    function onDragPlayerListStop() {
        dragState.dragging = false;
        olMobileApp.unlockHeader();
    }


    function initDroppable(target) {
        var listItem = $(target).parents(".ol-team-settings-line-up-row").length > 0;
        $(target).parent().mouseover(function(event) {
            var curTarget = $(this).find(".ol-team-settings-player-drag-and-drop");
            if (!dragState.dragging)
                mouseoverPlayer($(curTarget).attr("data-player-id"));
        });
        $(target).parent().mouseout(function(event) {
            mouseoutPlayer();
        });
        var flag = false;
        $(target).parent().on("touchmousedown", function(event) {
            var curTarget = $(this).find(".ol-team-settings-player-drag-and-drop");
            var newId = $(curTarget).attr("data-player-id");
            if (dragState.selectedPlayer !== newId && typeof newId !== "undefined" && newId > 0 && !$('body').hasClass('ol-xs')) {
                selectPlayer(newId);
            } else if(typeof newId !== "undefined" && newId > 0) {
            }
            if (listItem === false){
                $("#lineUpPlayer" + newId).parents(".ol-team-settings-line-up-row").click();
            }
        });
        var target;
        if ($(target).parent().hasClass("ol-pitch-position")) {
            target = $(target).parent();
            $(target).droppable({
                tolerance: 'intersect',
                out: function(event, ui) {
                    hoverOutDroppable(this, event, ui);
                },
                accept: ".ol-team-settings-player-drag-and-drop, .ol-team-settings-line-up-row",
                over: function(event, ui) {
                    setTimeout(function() { hoverOverDroppable(event, ui, target); }, 0);
                },
                drop: function(event, ui) {
                    var targetPlayerId = $(event.target).find(".ol-team-settings-player-drag-and-drop").attr("data-player-id");
                    var sourcePlayerId = $(ui.helper).attr("data-player-id");

                    var positionType = $(this).attr("data-player-position-index"); 
                    var playerPositionType = $(ui.helper).attr("data-player-type");

                    if (targetPlayerId != sourcePlayerId) {
                        if(lockPlayerType === false || (positionType === '256' && playerPositionType === '1') || (positionType !== '256' && playerPositionType === '2'))
                        {
                            if (typeof targetPlayerId === "undefined") {
                                // insert into empty position
                                insertPlayer(sourcePlayerId, $(target).attr("data-position"));
                            } else {
                                // switch player position
                                switchPlayer(targetPlayerId, sourcePlayerId, false);
                            }
                        }
                        $(ui.item).remove();
                    }

                    event.stopPropagation();
                }
            });
        }
    }

    function insertRowAnimation(item) {
        $(item).css("border-color", lineColor);
        setTimeout(function() {
            $(item).css("border-color", "rgba(255, 255, 255, 0)");
        }, 1);
    }

    function getFieldPlayerPosition(fieldPosition) {
        return $(fieldPosition).parent().attr('data-player-position-index');
    }

    function getFieldPositionItem(playerId) {
        var item = $(".ol-pitch-position .ol-team-settings-player-drag-and-drop[data-player-id=" + playerId + "]");
        return item;
    }

    function getFieldPositionByPosition(position) {
        return $(".ol-pitch-position[data-position=" + position + "] .ol-team-settings-player-drag-and-drop");
    }

    function getListItem(playerId) {
        return $(".ol-team-settings-line-up-row .ol-team-settings-player-drag-and-drop[data-player-id=" + playerId + "]").parents(".ol-team-settings-line-up-row");
    }

    function getDragAndDropListItem(playerId) {
        return $(".ol-team-settings-line-up-row .ol-team-settings-player-drag-and-drop[data-player-id=" + playerId + "]");
    }

    function addDraggableSwapEffect(item) {
        dragState.draggableEffectCounter++;
        if (dragState.draggableEffectCounter > 0) {
            item.addClass("ol-swapping");
            item.append($("<span style='background-color:" + lineColor + "' class='helper1'></span><span style='border-color:transparent " + lineColor + " transparent transparent' class='helper2'></span>"));
        }
    }

    function removeDraggableSwapEffect(item) {
        dragState.draggableEffectCounter = Math.max(0, dragState.draggableEffectCounter - 1);
        if (dragState.draggableEffectCounter <= 0) {

            if (item !== null) {
                item.removeClass("ol-swapping");
                item.find("span.helper1, span.helper2").remove();
            }
            dragState.hoveredPlayingFieldItem = false;
            dragState.hoveredListItem = false;
        }
    }

    function clearFieldPositionItem(field) {
        field.removeAttr("data-player-id");
        field.removeAttr("data-player-position");
        field.removeClass('ol-pitch-wrong-player-position');
        field.addClass('ol-player-linup-position-empty');
        field.removeAttr('onclick');
        $(field).find('.ol-lineup-player-name').html('<div class="ol-add-icon"></div>');


        field.find('.ol-team-position-short-name').html(field.parent().attr('data-player-position'));

        $(".ol-pitch-position").removeClass("ol-pitch-position-selected");
        $(".ol-pitch-position .ol-team-settings-player-drag-and-drop-selected").removeClass("ol-team-settings-player-drag-and-drop-selected");
        $(".ol-pitch-position .ol-team-settings-player-drag-and-drop-selected-invalid").removeClass("ol-team-settings-player-drag-and-drop-selected-invalid");


        // $(field).find('.ol-lineup-player-average').text('-');
        $(field).find('.ol-lineup-player-average').remove();
        $(field).find('.ol-lineup-player-form-clip').remove();
        $(field).find('.ol-lineup-player-fitness-diagramm-wrapper').remove();
        $(field).find('.ol-team-position-short-name').text('-');
        //field.draggable({ disabled: true });
        $(field).find('.ol-team-lineup-dragable-player-pitch').removeAttr('onclick');
    }

    function insertPlayer(playerId, position) {
        setTimeout(function() {
            var listItem = getListItem(playerId);
            var list = listItem.parent();
            if (!list.is($("#collapseLineUp"))) {
                $("#collapseLineUp").append(listItem);
            }
            var oldFieldPositionItem = getFieldPositionItem(playerId);
            if (oldFieldPositionItem.length > 0) clearFieldPositionItem(oldFieldPositionItem);
            var fieldPositionItem = getFieldPositionByPosition(position);
            var clone = listItem.find(".ol-team-settings-player-drag-and-drop").clone(true);

            var newPosition = parseInt(getFieldPlayerPosition(fieldPositionItem));
            fieldPositionItem.replaceWith(clone);
            initDraggable(clone);
            $(clone).draggable("enable");
            //check if it is the right player position
            var playerPositions = parseInt(clone.attr('data-player-positions'));
            if ((newPosition & playerPositions) !== newPosition)
                clone.addClass('ol-pitch-wrong-player-position');
            else
                clone.removeClass('ol-pitch-wrong-player-position');

            // clone.find('.ol-team-settings-pitch-position-player-position').html(clone.parent().attr('data-player-position'));
            clone.find('.ol-lineup-player-position').html(clone.parent().attr('data-player-position'));

            fieldPositionItem.addClass('ol-pitch-position-selected');

            coloringRows(list);
            teamSettings.sortList($("#collapseLineUp"));
            coloringRows($("#collapseLineUp"));
            insertRowAnimation(listItem);
            _updateVisibleRows();
            updateAvgValues();
            selectPlayer(playerId);
            dispatchLineupSwitchEvent(playerId, newPosition);
        }, 0);
    }

    function addToList(row, list, scroll) {
        setTimeout(function() {
            var srcList = $(row).parents('.ol-team-settings-line-up-rows');
            $(row).detach().appendTo($(list));
            coloringRows(list);
            if (!srcList.is(list)) coloringRows(srcList);
            teamSettings.sortList(list);
            _updateVisibleRows();
            if (scroll)
                olGUI.scrollTo(row, 40, '.ol-line-up-player-list');
        }, 0);
    }

    function switchPlayer(lineUpPlayerId, otherPlayerId, cloneOtherPlayer) {
        setTimeout(function() {
            var listItemOtherPlayer = getListItem(otherPlayerId);
            var list2 = listItemOtherPlayer.parent();
            var targetOtherPlayer = listItemOtherPlayer;
            if(cloneOtherPlayer === true)
                listItemOtherPlayer = listItemOtherPlayer.clone(true);

            var listItemLineUpPlayer = getListItem(lineUpPlayerId);
            var list1 = listItemLineUpPlayer.parent();
            var placeholder = $("<div id='tempItem'></div>");
            placeholder.insertAfter(listItemLineUpPlayer);
            listItemLineUpPlayer.detach();
            listItemLineUpPlayer.insertAfter(targetOtherPlayer);


            insertRowAnimation(listItemLineUpPlayer);

            listItemOtherPlayer.detach();
            listItemOtherPlayer.show();
            $("#tempItem").replaceWith(listItemOtherPlayer);
            insertRowAnimation(listItemOtherPlayer);
            
            var fieldPositionItem = getFieldPositionItem(lineUpPlayerId);
            var clone = listItemOtherPlayer.find(".ol-team-settings-player-drag-and-drop").clone(true);

            var newPosition = parseInt(getFieldPlayerPosition(fieldPositionItem));
            var playerPositions = parseInt(clone.attr('data-player-positions'));
            if ((newPosition & playerPositions) !== newPosition)
                clone.addClass('ol-pitch-wrong-player-position');
            else
                clone.removeClass('ol-pitch-wrong-player-position');


            
            var fieldPositionOtherItem = getFieldPositionItem(otherPlayerId);
            if (fieldPositionOtherItem.length > 0) {
                var clone2 = listItemLineUpPlayer.find(".ol-team-settings-player-drag-and-drop").clone(true);

                newPosition = parseInt(getFieldPlayerPosition(fieldPositionOtherItem));
                playerPositions = parseInt(clone2.attr('data-player-positions'));
                if ((newPosition & playerPositions) !== newPosition)
                    clone2.addClass('ol-pitch-wrong-player-position');
                else
                    clone2.removeClass('ol-pitch-wrong-player-position');

                fieldPositionOtherItem.replaceWith(clone2);
                // clone2.find('.ol-team-settings-pitch-position-player-position').html(clone2.parent().attr('data-player-position'));
                clone2.find('.ol-lineup-player-position').html(clone2.parent().attr('data-player-position'));
                initDraggable(clone2);
            }

            fieldPositionItem.replaceWith(clone);
            initDraggable(clone);
            // clone.find('.ol-team-settings-pitch-position-player-position').html(clone.parent().attr('data-player-position'));
            clone.find('.ol-lineup-player-position').html(clone.parent().attr('data-player-position'));
            teamSettings.sortList(list1, $(list1).is($(list2)));
            coloringRows(list1);
            if (!$(list1).is($(list2))) {
                teamSettings.sortList(list2,  true);
                coloringRows(list2);
            }
            updateAvgValues();
            selectPlayer(dragState.selectedPlayer);
            dispatchLineupSwitchEvent(otherPlayerId, newPosition);
        }, 0);
    }

    function coloringRows(rows) {
        // $(rows).find(".ol-team-settings-line-up-row:visible").each(function(index, element) {
        //     $(element).removeClass("ol-state-color-light-" + userStateId);
        //     if (index % 2 === 0)
        //          $(element).addClass("ol-state-color-light-" + userStateId);
        // });
    }

    function addPlayerToEmptyList(playerId, list) {
        setTimeout(function() {
            var listItem = getListItem(playerId);
            var fieldItem = getFieldPositionItem(playerId);
            if(fieldItem.length > 0) clearFieldPositionItem(fieldItem);
            srcList = listItem.parent();
            $(list).prepend(listItem);
            insertRowAnimation(listItem);
            teamSettings.sortList(list);
            coloringRows(list);
            coloringRows(srcList);
            //initSwapHelper(listItem.find(".ol-sortable-swap-helper"));
        }, 0);
    }

    function updatePlaceholder() {
    }



    function initSwapHelper(item) {
        $(item).droppable({
            refreshPositions: true,
            cursor: 'pointer',
            tolerance: "fit",
            accept: ".ol-team-settings-player-drag-and-drop, .ol-team-settings-line-up-row",
            over: function(event, ui) {
                var swapItem = $(item).parents(".ol-team-settings-line-up-row");
                var targetPlayerId = swapItem.find(".ol-team-settings-player-drag-and-drop").attr("data-player-id");
                var srcPlayerId = $(ui.helper).attr("data-player-id");
                if (targetPlayerId != srcPlayerId) {
                    $(ui.helper).css("cursor", "pointer");
                    dragState.swapping = true;
                    dragState.forbidden = false;
                    ui.helper.addClass("ol-swapping");

                    ui.helper.append($("<span style='background-color:" + lineColor + "' class='helper1'></span><span style='border-color:transparent " + lineColor + " transparent transparent' class='helper2'></span>"));
                    dragState.swapItem = swapItem;
                    var playingFieldItem = getFieldPositionItem(targetPlayerId);
                    if (playingFieldItem.length > 0) {
                        dragState.hoveredPlayingFieldItem = playingFieldItem;
                    } else {
                        dragState.hoveredPlayingFieldItem = false;
                    }
                    dragState.hoveredListItem = $(dragState.swapItem);

                    $(".ol-team-settings-sortable-placeholder").hide();
                    $(dragState.hoveredListItem).addClass("ol-swapping-out");
                } else {
                    dragState.swapping = false;
                    dragState.forbidden = true;
                }
            },
            out: function(event, ui) {
                $(ui.helper).css("cursor", "auto");
                if (dragState.target.is($("#collapseLineUp")) && dragState.draggingSourceType !== TYPE_LIST_LINE_UP) {
                    dragState.swapping = true;
                    ui.helper.addClass("ol-swapping");
                    ui.helper.append($("<span style='background-color:" + lineColor + "' class='helper1'></span><span style='border-color:transparent " + lineColor + " transparent transparent' class='helper2'></span>"));
                    $(".ol-team-settings-sortable-placeholder").hide();
                } else {
                    dragState.swapping = true;
                    ui.helper.removeClass("ol-swapping");
                    dragState.hoveredPlayingFieldItem = false;
                    dragState.hoveredListItem = false;
                    ui.helper.find("span.").remove();
                    dragState.swapping = false;
                    if (!dragState.forbidden) {
                        $(".ol-team-settings-sortable-placeholder").show();
                       // console.info("placeholder show 1066");
                    }
                    $(".ol-swapping-out").removeClass("ol-swapping-out");
                }
            },
            drop: function() {
            },
        });
    }

    function mouseoutPlayer(playerId) {
        $(".ol-pitch-position").removeClass("ol-pitch-position-hover");
        $(".ol-team-settings-player-drag-and-drop-hover").removeClass("ol-team-settings-player-drag-and-drop-hover");
        dragState.hoveredPlayingFieldItem = false;
        dragState.hoveredListItem = false;
    }

    function mouseoverPlayer(playerId) {
        if (typeof playerId === "undefined") return;

        var positionDraggableObject = $(".ol-pitch-position .ol-team-settings-player-drag-and-drop[data-player-id=" + playerId + "]");
        var positionObject = $(positionDraggableObject).parent();

        var listObjectDraggable = $(".ol-team-settings-line-up-row .ol-team-settings-player-drag-and-drop[data-player-id=" + playerId + "]");
        var listObject = listObjectDraggable.parents(".ol-team-settings-line-up-row");

        positionObject.addClass("ol-pitch-position-hover");

        dragState.hoveredPlayingFieldItem = positionDraggableObject;
        dragState.hoveredListItem = listObject;

        positionDraggableObject.addClass("ol-team-settings-player-drag-and-drop-hover");
        listObject.addClass("ol-team-settings-player-drag-and-drop-hover");
    }

    function selectPlayer(playerId, manualTriggered) {
        if (typeof manualTriggered === "undefined") manualTriggered = false;
        $('.ol-player-details-selected-temp').removeClass('ol-player-details-selected-temp');
        deselectPlayer();
        dragState.selectedPlayer = playerId;

        var positionDraggableObject = $("div.ol-pitch-position div.ol-team-settings-player-drag-and-drop[data-player-id=" + playerId + "]");
        var positionObject = $(positionDraggableObject).parent();

        if (positionDraggableObject.length > 0) {
            var positionType = positionObject.attr('data-position-type').split(',');
            var positionIndex =  parseInt(positionObject.attr('data-player-position-index'));

            $('.lineUpPosition').removeClass('selected');
            $('.lineUpPosition' + positionIndex).addClass('selected');

            $('.ol-formation-part.selected').removeClass('selected');
            $.each(positionType, function(index, element) {
                $('#formationPartImg' + element).addClass('selected');
            });
        }

        var listObjectDraggable = $("div.ol-team-settings-line-up-row div.ol-team-settings-player-drag-and-drop[data-player-id=" + playerId + "]");
        var listObject = listObjectDraggable.parents("div.ol-team-settings-line-up-row");

        if (!$('body').hasClass('ol-xs')) {
            var $details = $('#lineUpPlayer' + playerId);
            var _openPlayerDetails = function($d) {
                setTimeout(function() {
                    $d.addClass('ol-player-details-selected');

                    if (firstTimeSelectPlayer)
                        resizeWindow();
                    firstTimeSelectPlayer = false;
                }, 1);

                $('.ol-team-settings-player-details-bottom > .container').scroll(function(ev) {
                    if ($(ev.target).scrollTop() == 0) {
                        $('.ol-team-settings-line-up-playerinfo').removeClass('shadowed');
                    } else {
                        $('.ol-team-settings-line-up-playerinfo').addClass('shadowed');
                    }
                });
            }

            if ($details.length == 0) {
                $.get('/team/lineup/playerdetail', { 'playerId' : playerId, 'showGraph': true }, function(response) {
                    $('.ol-team-settings-player-details').append(response);
                    $details = $('#lineUpPlayer' + playerId);
                    _openPlayerDetails($details);
                });
            } else {
                _openPlayerDetails($details);
            }
        }

        $('.ol-team-settings-line-up-row.ol-line-up-position-fits').removeClass('ol-line-up-position-fits');
        if (positionDraggableObject.length > 0) {
            $('.ol-team-settings-line-up-row').each(function(index, element) {
                if (!$(listObject).is($(element))) {
                    var targetPosition = parseInt($(element).find('.ol-team-settings-player-drag-and-drop').attr('data-player-positions'));
                    if ((targetPosition & positionIndex) == positionIndex) {
                        $(element).addClass('ol-line-up-position-fits');
                    }
                }
            });
        }

        positionDraggableObject.addClass("ol-team-settings-player-drag-and-drop-selected");
        listObject.addClass("ol-team-settings-player-drag-and-drop-selected");
        listObject.find('.ol-team-settings-player-drag-and-drop').addClass('selected');

        var validPitchPosition = positionDraggableObject.length > 0;
        if (validPitchPosition) {
            positionObject.addClass("ol-pitch-position-selected");
            positionObject = positionObject.find("div.ol-team-settings-player-drag-and-drop");
        }

        if (validPitchPosition)
            dragState.selectedPlayingFieldItem = positionObject;
        else
            dragState.selectedPlayingFieldItem = false;

        dragState.selectedListItem = $(listObject);
    }

    function deselectPlayer() {
        var top = 300;

        $('.ol-team-settings-line-up-row.ol-line-up-position-fits').removeClass('ol-line-up-position-fits');
        $('.lineUpPosition').removeClass('selected');
        $('.ol-formation-part.selected').removeClass('selected');
        dragState.selectedPlayingFieldItem = false;
        if (dragState.selectedListItem) {
            var $details = $("#lineUpPlayer" + dragState.selectedPlayer);
            $details.addClass('ol-player-details-selected-temp');
            $('.ol-player-details-selected').removeClass('ol-player-details-selected');
        }
        dragState.selectedListItem = false;
        dragState.selectedPlayer = false;
        $(".ol-pitch-position").removeClass("ol-pitch-position-selected");
        $(".ol-team-settings-player-drag-and-drop-selected").removeClass("ol-team-settings-player-drag-and-drop-selected");
        $(".ol-team-settings-player-drag-and-drop-selected-invalid").removeClass("ol-team-settings-player-drag-and-drop-selected-invalid");
    }

    function getDroppableTarget(target) {
        if ($(target).hasClass(".ol-pitch-position"))
            return $(target).child();
        else
            return $(target).find(".ol-team-settings-player-drag-and-drop");
    }

    function onMoveDraggable(event, ui) {
        if (onHoverDroppable !== false) {
            var bStartEleven = $(onHoverDroppable).parents("#collapseLineUp").length == 1;
            var bSrcStartEleven = $(onHoverDroppableDraggable).attr("data-player-position") < 12;
            var parent = $(onHoverDroppable);
            var relOffsetY = $(onHoverDroppableDraggable).offset().top - $(parent).offset().top;
            var relOffsetX = $(onHoverDroppableDraggable).offset().left - $(parent).offset().left;

            relOffsetY += 16;
            if ((!bStartEleven || bSrcStartEleven) && relOffsetY < 10) {
                // insert before
                if (onHoverDroppableState != "before") {
                    onHoverDroppableState = "before";
                    $(".ol-team-settings-player-list-insert-between").remove();
                    $('<div class="ol-team-settings-player-list-insert-between"></div>').prependTo(parent);
                }
            } else if ((!bStartEleven || bSrcStartEleven) && relOffsetY > 30) {
                // insert after
                if (onHoverDroppableState != "after") {
                    onHoverDroppableState = "after";
                    $(".ol-team-settings-player-list-insert-between").remove();
                    $('<div class="ol-team-settings-player-list-insert-between"></div>').prependTo(parent.next());
                }
            } else {
                // replace
                if (onHoverDroppableState != "replace") {
                    $(".ol-team-settings-player-list-insert-between").remove();
                    onHoverDroppableState = "replace";
                }
            }
        }
    }

    function hoverOverDroppable(event, ui, target) {
        var srcPlayerId = $(ui.helper).attr("data-player-id");
        target = target.find(".ol-team-settings-player-drag-and-drop");
        var playerId = target.attr("data-player-id");

        if (typeof playerId !== typeof undefined && srcPlayerId != playerId) {
            $(event.target).addClass("ol-swapping-out");
        }
        if (srcPlayerId != playerId) {
            addDraggableSwapEffect(ui.helper);
            $(event.target).find(".ol-team-settings-player-drag-and-drop")
                .css("border-color", lineColor);
            dragState.hoveredPlayingFieldItem = getFieldPositionItem($(target).attr("data-player-id"));
            dragState.hoveredListItem = getListItem($(target).attr("data-player-id"));
        }
    }

    function hoverOutDroppable(element, event, ui) {
        $(event.target).removeClass("ol-swapping-out");
        $(element).find(".ol-team-settings-player-drag-and-drop")
            .css("border-color", "initial");
        removeDraggableSwapEffect(ui.helper);
        $(ui.helper).removeClass("ol-team-settings-player-hover");
        $(ui.helper).removeClass("ol-team-settings-player-hover-not-allowed");
        //deselectPlayer(lastHoverPlayerId);
        $(".ol-team-settings-player-list-insert-between").remove();
    }



    function _updateTacticDropdown() {
        var fId = $("#dropdownSystems").attr("data-value");
        var group = $("#dropdownSystems").attr("data-group");
        $("#dropdownTactic").find("li[data-group!=" + group + "]").hide();
        $("#dropdownTactic").find("li[data-group!=" + group + "]").removeClass("visible");
        $("#dropdownTactic").dropdown().enable();
        $("#dropdownTactic").find("li[data-group=" + group + "]").show();
        $("#dropdownTactic").find("li[data-group=" + group + "]").addClass("visible");
    }

    teamSettings.onClickDropdownSystem = function(userLineUpId, group) {
        $("#dropdownSystems").attr("data-group", group);
        _updateTacticDropdown();
        $("#dropdownTactic").dropdown().selectByClass('visible');
        teamSettings.onClickDropdownTactic($("#dropdownTactic").dropdown().value(), group);
        var tactic = $("#dropdownTactic").dropdown().value();
        loadPitch(tactic);
        updatePlayerBanIcons();
        updateDisabledRows();
        saveLineUp();
    }

    teamSettings.onClickSelectSystem = function(userLineUpId, group) {
        var $selecter = $(".ol-lineup-selection-wrapper");
        $selecter.attr("data-value", userLineUpId);
        $selecter.attr("data-group", group);

        // teamSettings.onClickDropdownTactic(userLineUpId, group);
        loadPitch(userLineUpId);
        updatePlayerBanIcons();
        updateDisabledRows();
        saveLineUp(userLineUpId);
        olOverlayWindow.close();
    }

    teamSettings.onBlurInputLineUpName = function(inputElement) {
        var data = new Object();
        data.userLineUpId = $("#dropdownLineUps").dropdown().value();
        data.name = $(inputElement).val();
        if (data.name === $("#liLineUp" + data.userLineUpId).attr("data-name")) return;
        if (data.name.length < 3) {
            olMsgBox.msg($("#msgWrongNameHeadline").html(), $("#msgWrongNameSize").html());
            $(inputElement).val($("#liLineUp" + data.userLineUpId).attr("data-name"));
            return;
        }
        addLoadingAnimationToElement($(inputElement).parent(), {size: "small"});
        $.post("/team/lineup/renamelineup", data, function() {
            removeLoadingAnimationFromElement($(inputElement).parent());
            $("#liLineUp" + data.userLineUpId).attr("data-name", data.name);
            $("#dropdownLineUps").find(".ol-dropdown-text").html(data.name);
            $("#liLineUp" + data.userLineUpId).find("a").html(data.name);
        }).fail(function() {
            removeLoadingAnimationFromElement($(inputElement).parent());
            olMsgBox.msg($("#msgWrongNameHeadline").html(), $("#msgWrongName").html());
            $(inputElement).val($("#liLineUp" + data.userLineUpId).attr("data-name"));
        });
    }

    teamSettings.onClickButtonDeleteLineUp = function(formationId) {
        var active = $("#dropdownLineUps").attr("data-active");
        if (active == 1 || active == 11) {
            olMsgBox.msg(Lang.trans('js/team.attention'), Lang.trans('js/team.startLineupsCantDeleted'));
            return;
        }
        if (active == 10) {
            $("#dropdownLineUps li[data-active=1]").attr("data-active", 11);
        }
        var lineUpId = $("#dropdownLineUps").dropdown().value();
        var data = { "userLineUpId": lineUpId };
        addLoadingAnimationToElement($("#dropdownLineUps"), { size : "small" });
        $("#dropdownLineUps").dropdown().loadElements("/team/lineup/deletelineup", data, function() {
            $("#liLineUp" + lineUpId).remove();

            if ($('#dropdownLineUps li').size() < 4) {
                $('#btnNewLineUp1, #btnNewLineUp2').removeClass('premium-locked');
            }

            removeLoadingAnimationFromElement($("#dropdownLineUps"));
            $("#dropdownLineUps").dropdown().selectByIndex(1)
            teamSettings.onClickDropdownUserLineUp($("#dropdownLineUps").dropdown().value());
        });
    }

    teamSettings.onClickDropdownTactic = function(formationId, group) {
        var item = $("#dropdownLineUps").dropdown().selectedElement();
        var team = JSON.parse(item.attr("data-team"));
        team.formationId = formationId;
        item.attr("data-team", JSON.stringify(team));
        item.attr("data-group", group);
        loadPitch(formationId);
        saveLineUp(formationId);
    };

    var maxLineups = null;
    function getMaxLineups() {
        if (!maxLineups) {
            maxLineups = $('#userMaxLineups').val()
        }

        return maxLineups;
    }

    teamSettings.onClickButtonNewLineUp = function() {
        if ($('#btnNewLineUp1, #btnNewLineUp2').hasClass('premium-locked')) {
            return;
        }

        var systemId = $("#dropdownTactic").dropdown().value();
        var data = { "systemId": systemId };
        addLoadingAnimationToElement($("#selectedLineUp"), { size : "small" });
        $("#dropdownLineUps").dropdown().loadElements("/team/lineup/newlineup", data, function() {
            removeLoadingAnimationFromElement($("#selectedLineUp"));
            $("#dropdownLineUps").dropdown().selectLastElement();
            $("#dropdownLineUps").attr("data-active", 0);
            if ($('#dropdownLineUps li').size() >= getMaxLineups()) {
                $('#btnNewLineUp1, #btnNewLineUp2').addClass('premium-locked');
            }
            teamSettings.onClickDropdownUserLineUp($("#dropdownLineUps").dropdown().value(), function() {
                $(".lineUpName").select();
            });
        });
    }

    teamSettings.onClickDropdownUserLineUp = function(userLineUpId, callback) {
        if (teamSettings.page == 'tactic') {
            addLoadingAnimationToElement($("#settingsContent"));
            $("#settingsContent").getLoad("/team/tacticview", { lineUpId : userLineUpId }, function() {
                removeLoadingAnimationFromElement($("#settingsContent"));
            });
        } else {
            $("#squadOverlay").fadeOut();
            var data = { "userLineUpId": userLineUpId };
            var li = $("#liLineUp" + userLineUpId);

            if (li.hasClass('premium-locked')) {
                return;
            }

            formationId = JSON.parse(li.attr("data-team")).formationId;
            addLoadingAnimationToElement($("#settingsContent"));
            $("#settingsContent").getLoad("/team/lineup/load", data, function() {
                $(".lineUpName").val($("#liLineUp" + userLineUpId).attr("data-name"));

                $("#dropdownLineUps").attr("data-active", $("#liLineUp" + userLineUpId).attr("data-active"));

                $('#selectLineUp').attr("data-active", $("#liLineUp" + userLineUpId).attr("data-active"));                
                $('.ol-team-settings-line-up-header').attr("data-active", $("#liLineUp" + userLineUpId).attr("data-active"));
                $('.ol-lineup-selection-wrapper').attr('data-value', formationId);

                var dataGrp = li.attr("data-group");
                $("#dropdownSystems").dropdown().selectByValue($("#dropdownSystems").find("li[data-group=" + dataGrp + "]").attr("data-value"));
                $("#dropdownSystems").attr("data-group", dataGrp);
                _updateTacticDropdown();
                $("#dropdownTactic").dropdown().selectByValue(formationId);
                removeLoadingAnimationFromElement($("#settingsContent"));
                var event = {};
                $('#ol-root').trigger('lineupEditorChangeUserLineUp', [ event ]);
                if(typeof callback !== "undefined") callback();
            });
        }
    }

    teamSettings.onClickSetLineUpActive = function(target) {
        var $btns = $('#selectLineUp');
        var current = $("#selectedLineUp").attr("data-active");
        if (target == current || current == 11) {
            return;
        }

        var data = new Object();
        var old = $("#selectedLineUp").attr("data-active");
        if(current > 0 ) {
            $("#selectedLineUp").attr("data-active", 11);
            $btns.attr("data-active", 11);
        } else {
            $("#selectedLineUp").attr("data-active", target);
            $btns.attr("data-active", target);
        }
        var newActive = (current > 0 ? 11 : target);
        data.userLineUpId = $("#dropdownLineUps").dropdown().value();
        data.active = newActive;
        addLoadingAnimationToElement($("#dropdownLineUps"), { size : "small"});
        olMsgBox.postLoad("/team/lineup/setlineupactive", data, function() {
            removeLoadingAnimationFromElement($("#dropdownLineUps"));
            if (olMsgBox.getCurrentJSON().check !== 'failed') {
                if (target == 1) {
                    $("#dropdownLineUps li[data-active=1]").attr("data-active", 0);
                    $("#dropdownLineUps li[data-active=11]").attr("data-active", 10);
                } else if(target == 10) {
                    $("#dropdownLineUps li[data-active=10]").attr("data-active", 0);
                    $("#dropdownLineUps li[data-active=11]").attr("data-active", 1);
                }
                var currentActive = $("#liLineUp" + data.userLineUpId).attr("data-active");
                if (currentActive > 0 && currentActive != target) {
                    $("#liLineUp" + data.userLineUpId).attr("data-active", 11);
                    $("#dropdownLineUps").attr("data-active", 11);
                } else {
                    $("#liLineUp" + data.userLineUpId).attr("data-active", target);
                    $("#dropdownLineUps").attr("data-active", target);
                }
                updatePlayerBanIcons();
                updateDisabledRows();
            } else {
                $("#selectedLineUp").attr("data-active", old);
                $btns.attr("data-active", old);
            }
            _updateLeagueAndFriendlyPositionIcons();
        });
    }

    function saveLineUp(systemId) {
        var data = new Object();
        if (typeof systemId === "undefined") {
            // data.systemId = $(".ol-lineup-selection-wrapper").dropdown().value();
            data.systemId = $(".ol-lineup-selection-wrapper").attr('data-value');
        } else {
            data.systemId = systemId;
        }
        data.userLineUpId = $("#dropdownLineUps").dropdown().value();
        data.player = new Object();
        var mapping = new Object();
        var orderIndex = 1;
        $(".ol-pitch-position .ol-team-settings-player-drag-and-drop").each(function(index, element) {
            var playerId = $(element).attr("data-player-id");
            if (typeof playerId !== "undefined" && playerId > 0) {
                var listItem = getListItem(playerId);
                var listIndex = listItem.index() + 1;
                orderIndex = Math.max(orderIndex, listIndex);
                data.player[$(element).parent().attr("data-position")] = { playerId: playerId, 'index': listIndex};
                mapping[playerId] = $(element).parent().attr("data-position");
            }
        });
        var count = 12;
        orderIndex = count;
        $("#collapseSubCollapse .ol-team-settings-player-drag-and-drop").each(function(index, element) {
            var playerId = $(element).attr("data-player-id");
            mapping[playerId] = count;
            data.player[count++] = { playerId: playerId, 'index': orderIndex + index}; //the ++ position is right!!!
        });

        count = squadSize;
        orderIndex = count;
        $("#collapseOtherCollapse .ol-team-settings-player-drag-and-drop").each(function(index, element) {
            var playerId = $(element).attr("data-player-id");
            data.player[++count] = { playerId: playerId, 'index': orderIndex + index + 1}; //the  ++ position is right!!!
            mapping[playerId] = count;
        });

        _updateDropdownPositionMapping(mapping, data.userLineUpId);
        $.post("/team/lineup/update", data, function() {
            // update position mapping in dropdown elements
        }).fail(function(err) {
        	var msgTitle = $('#squad_cant_save_msg').attr('data-title') || Lang.trans('js/team.error');
        	var msgText = $('#squad_cant_save_msg').attr('data-text') || Lang.trans('js/team.errorChangesNotSaved');
            olMsgBox.msg(msgTitle, msgText, function() {
                // TODOredesign wieder einkommentieren
                // loadPage('/team/lineup');
            });
        });
    }

    function _updateDropdownPositionMapping(mapping, userLineUpId) {
        console.info(JSON.stringify(mapping));
        $('#liLineUp' + userLineUpId).attr('data-player-positions', JSON.stringify(mapping));
        _updateLeagueAndFriendlyPositionIcons();
    }

    function _updateLeagueAndFriendlyPositionIcons() {
        var userLineUpId = $("#dropdownLineUps").dropdown().value();
        var item = $("#dropdownLineUps").find('[data-active="1"], [data-active="11"]');
        var lineUp = item.length ? JSON.parse(item.attr('data-player-positions')) : {};
        var active = item.attr('data-active');
        if (active == 1 || active == 11) {
            $('.ol-is-in-league-line-up').removeClass('ol-is-in-league-line-up');
        }
        if (active == 10 || active == 11) {
            $('.ol-is-in-friendly-line-up').removeClass('ol-is-in-friendly-line-up');
        }
        for (var property in lineUp) {
            if (lineUp.hasOwnProperty(property)) {
                var playerId = parseInt(property);
                var position = lineUp[property];
                if (position <= 11) {
                    var element = $('#playerListId' + playerId).find('.ol-line-up-state');

                    if (active == 1 || active == 11) {
                        element.addClass('ol-is-in-league-line-up');
                    }
                    if (active == 10 || active == 11) {
                        element.addClass('ol-is-in-friendly-line-up');
                    }
                }
            }
        }

        item = $('#dropdownLineUps').find('[data-active="10"]');
        if(item.length == 0) return;
        lineUp = JSON.parse(item.attr('data-player-positions'));
        active = item.attr('data-active');

        for (var property in lineUp) {
            if (lineUp.hasOwnProperty(property)) {
                var playerId = parseInt(property);
                var position = lineUp[property];
                if (position <= 11) {
                    var element = $('#playerListId' + playerId).find('.ol-line-up-state');

                    if (active == 10 || active == 11) {
                        element.addClass('ol-is-in-friendly-line-up');
                    }
                }
            }
        }
    }

    function loadPitch(fId) {
        deselectPlayer();

        var path = 'imgs/lineupeditor/';
        $('.ol-formation-bg img').fadeOut(500);
        $('.ol-formation-part img').each(function(index, element)
        {
            $(element).fadeOut(500);
        });


        setTimeout(function() {
            $('.ol-formation-bg img').attr("src", imgServer + path + 'bg_' + fId + '.png');
            $('.ol-formation-part img').each(function(index, element) {
                $(element).attr('src', imgServer + path + 'part_' + fId + '_' + $(element).parent().attr('data-position-type') + '_hover.png');
            });
            $('.ol-formation-bg img').fadeIn(300);
            $('.ol-formation-part img').each(function(index, element) {
                $(element).fadeIn(500);
            });
        }, 500);

        var tacticsDropdownItem = $("#dropdownTactic li[data-value=" + fId + "]");
        var parts = tacticsDropdownItem.attr('data-formation-parts').split(',');
        // var tacticsSelectItem = $(".ol-lineup-selection-lineup-name[data-system-id=" + fId + "]");
        // var parts = tacticsSelectItem.attr('data-formation-parts').split(',');

        $('.ol-system-part-avg').hide();
        $.each(parts, function(index, element) {
            $('#systemPartAverageValue' + element).parent().show();
        });

        $('.ol-formation-part.selected').removeClass('selected');

        var positions = JSON.parse(tacticsDropdownItem.attr("data-positions"));
        // var positions = JSON.parse(tacticsSelectItem.attr("data-positions"));
        $(positions).each(function(index, element) {
            // 'left: {{ $pos["x"] }}%; top: {{ $pos["y"] }}%'
            var target = $(".ol-pitch-position[data-mapping=" + element.mapping + "]");
            target.attr("data-position", element.position);
            target.animate({ left: (element.x) + "%", top: element.y + "%"}, 1000, "easeOutCubic");
            target.attr("data-player-position", element.playerPosition);
            var draggable = target.find(".ol-team-settings-player-drag-and-drop");
            var attr = draggable.attr("data-player-id");
            target.attr('data-player-position-index', element.playerPositionIndex);

            var playerPositions = parseInt(draggable.attr('data-player-positions'));
            if ((element.playerPositionIndex & playerPositions) !== element.playerPositionIndex)
                draggable.addClass('ol-pitch-wrong-player-position');
            else
                draggable.removeClass('ol-pitch-wrong-player-position');

            // target.find('.ol-team-settings-pitch-position-player-position').html(element.playerPosition);
            target.find('.ol-lineup-player-position').html(element.playerPosition);

            target.attr('data-position-type', element.type.join(','));

            if (typeof attr === typeof undefined || attr === false) {
                target.find('.ol-team-position-short-name').html(element.playerPosition);
            }

        });
        updateAvgValues();
    }

    teamSettings.onClickDropdownPlayerAttribute = function(playerAttrId, type) {
        $(".ol-team-settings-lineup-attr").each(function(index, ele) {
            var valueBar = $(ele);
            var draggable = valueBar.parents(".ol-team-settings-line-up-row").find(".ol-team-settings-player-drag-and-drop");
            var playerType = draggable.attr("data-player-type");
            if (type == 1 && type != playerType) {
                valueBar.hide(); //valueBar.fadeOut(300);
            } else {
                valueBar.show(); // valueBar.fadeIn(300);
            }
            var playerAttributes = JSON.parse(valueBar.attr("data-player-attributes"));
            var attr = playerAttributes[playerAttrId];
            var oldValue = parseInt(valueBar.find(".ol-value-bar-small-label").html().replace(/\D+/g, ''));
            valueBar.find(".ol-value-bar-small-bar").css({ "width" : attr["value"] + "%", "background-color" : attr["color"] });

            $({countNum: oldValue}).animate({countNum: parseInt(attr["value"])}, {
                duration: 1000,
                easing:'easeOutQuad',
                step: function() {
                    valueBar.find(".ol-value-bar-small-label").html(Math.floor(this.countNum) + " %");
                },
                complete: function() {
                }
          });
        });
    };

    teamSettings.loadPlayerAttributes = function(element, playerId) {
        if ($("#playerAttributes" + playerId).html().trim().length > 0) return;
        var data = {
            playerId: playerId
        };
        addLoadingAnimationToElement($("#divSquadAccordion"));
        $("#playerAttributes" + playerId).getLoad("/team/lineup/getplayerattributes", data, function() {
            removeLoadingAnimationFromElement($("#divSquadAccordion"));
        });
    }

    function createLineUpDisplayName(lineUpId) {
        var element = $("#liLineUp" + lineUpId);
        var systemName = element.attr("data-system-name");
        var activeString = $("#dropdownLineUps").attr("data-active-trans");
        var name = element.attr("data-name");
        var newName = name + " (" + systemName + ")" + (element.attr("data-active") == 1 ? " (" + activeString + ")" : "");
        return newName;
    }

    function updateLineUpItemNames() {
        $("#dropdownLineUps").find("li").each(function() {
            $(this).find("a").html(createLineUpDisplayName($(this).attr("data-value")));
        });
    }

    teamSettings.onClickButtonLineUpActive = function() {
        var userLineUpId = $("#pitch").attr("data-user-formation-id");
        data = {
            'userLineUpId': userLineUpId
        };

        addLoadingAnimationToElement($("#ol-root"));

        $.post("/team/lineup/setlineupactive", data, function() {
            removeLoadingAnimationFromElement($("#ol-root"));

            $("#dropdownLineUps").find("li").attr("data-active", "0");
            $("#liLineUp" + userLineUpId).attr("data-active", "1");
            updateLineUpItemNames();
            var newName = createLineUpDisplayName(userLineUpId);
            $("#dropdownLineUps").find(".ol-dropdown-text").html(newName);
            $("#liLineUp" + userLineUpId).find("a").html(newName);
        });
    };

    teamSettings.onClickTogglePlayerAttributes = function() {
        viewPlayingFieldPlayerAttributes = !viewPlayingFieldPlayerAttributes;
        $("#selectedLineUp").toggleClass("ol-outfield-player").toggleClass("ol-keeper");
    }

    teamSettings.toggleSelectPlayerById = function(playerId) {
        if (dragState.selectedPlayer == playerId)
            deselectPlayer();
        else
            selectPlayer(playerId);
    }

    teamSettings.selectPlayerById = function(playerId) {
        selectPlayer(playerId);
    }

    function _resizeMaxTable() {
        onUnloadGlobal(function() {
            //console.log("Unloading Lineup Editor");
            if (maximized) {
                teamSettings.onClickButtonMaximizeLineUpTable();
            }
        });
        if (maximized) {
            $('#lineUpTableContainer').width($('#settingsContent').width());
            $('#lineUpTableContainer').height($('#lineUpTableContainer').height() - 28);
            $("#selectedLineUp").toggleClass("ol-maximized").toggleClass("ol-minimized");
            $("#selectedLineUp").toggleClass("ol-minimized-ongoing")

            if ($('body').hasClass('ol-xs')) {
                olMobileApp.lockOrientation('landscape');
                $('html').addClass('no-scroll');
                $('<div id="dummy" class="ol-outfield-player ol-maximized"></div>').appendTo('body');
                $('.ol-team-settings-lineup-list-padding-box').appendTo('#dummy').css('z-index', 2);
            }
        } else {
            $('html').removeClass('no-scroll');
        }
    }


    var widthBefore;
    teamSettings.onClickButtonMaximizeLineUpTable = function(ev) {
        if (detailsActive) return;
        if (maximized === false) {
            maximized = true;
        } else {
            if ($('body').hasClass('ol-xs')) {
                $('.ol-team-settings-lineup-list-padding-box').css('z-index', '').appendTo('#paddingBoxContainer');
                $('html').removeClass('no-scroll');
                $('#dummy').remove();
                olMobileApp.lockOrientation('');
            }

            $("#selectedLineUp").toggleClass("ol-maximized").toggleClass("ol-minimized");
            setTimeout(function() {
                $("#selectedLineUp").toggleClass("ol-minimized-ongoing")
                $('#lineUpTableContainer').css('height', '');
            }, 500);
            maximized = false;
            $('#lineUpTableContainer').css('width', '');
        }
        _updateVisibleRows();
        _resizeMaxTable();
    };

    function _updateVisibleRows() {
        var checkPlayerSelected = false;
        $(".ol-team-settings-line-up-rows .ol-team-settings-line-up-row").each(function(index, element) {
            var playerSelected = true;

            checkPlayerSelected = checkPlayerSelected || $(this).find('input[type="checkbox"]').prop('checked');

            if (playerSelectedFilter) {
                playerSelected = $(this).find('input[type="checkbox"]').prop('checked');
            }

            playerPosition = true;
            if (playerPositionFilter != 0) {
                var drag = $(element).find(".ol-team-settings-player-drag-and-drop");
                if (drag.length > 0) {
                    var playerPos = parseInt(drag.attr("data-player-positions"));
                    playerPosition = ((playerPositionFilter & playerPos) === playerPositionFilter);
                }
            }

            if (playerSelected && playerPosition)
                $(this).addClass('visible').show();
            else
                $(this).removeClass('visible').hide();
        });

        var count = 0;
        var sum = $(".ol-team-settings-line-up-rows .ol-team-settings-line-up-row").length;
        $(".ol-team-settings-line-up-rows").each(function(index, element) {
            var countVisibleChildren = $(element).find('.ol-team-settings-line-up-row.visible').length;
            count += countVisibleChildren;

            if (!maximized || countVisibleChildren > 0) $(element).parent().prev().prev().show();
            else if(maximized) $(element).parent().prev().prev().hide();
        });

        if (count == 0 && sum > 0) {
            $('.ol-team-settings-line-up-rows input[type="checkbox"]').prop('checked', false);
            $('#checkboxFilterPlayer').prop('checked', false);
            playerSelectedFilter = false;
            checkPlayerSelected = false;
            _updateVisibleRows();
        }

        $(".ol-team-settings-line-up-rows").each(function(index, element) {
            var countVisibleChildren = $(element).find('.ol-team-settings-line-up-row.visible').length;
            if (countVisibleChildren > 0) {
                $(element).parent().find('.ol-team-settings-player-list-empty').removeClass('ol-is-empty');
            } else {
                $(element).parent().find('.ol-team-settings-player-list-empty').addClass('ol-is-empty');
            }
        });

        if (!checkPlayerSelected) {
            $('.ol-team-settings-list-header').removeClass('ol-player-filter-selection');
        } else {
            $('.ol-team-settings-list-header').addClass('ol-player-filter-selection');
        }
    }

    teamSettings.filterByPlayerPosition = function(element) {
        playerPositionFilter = parseInt($(element).parent().attr("data-value"));
        _updateVisibleRows();
        return true;
    };

    teamSettings.onChangeFilterPlayer = function(state) {
        playerSelectedFilter = state;
        _updateVisibleRows();
    };

    teamSettings.updateFilter = function() {
        _updateVisibleRows();
    };



    //--- Mannschaft > Einstellungen > Taktik ---
    
    teamSettings.pageLockReload = function() {
        if (olOverlayWindow.isOpen()) olOverlayWindow.forceClose();
        var $lock = $('.ol-page-lock-msg-box');
        //console.log('lock='+$lock.length);
        if ($lock.length) return;
        //console.log('Loading lock page...');
        loadPage('/team/tactic');
    };

    teamSettings.initTacticPage = function() {
        teamSettings.page = 'tactic';
        $('#tacticName').keyup(function(e) {
            teamSettings.onKeyInputTacticName(e);
        });        
    };

    teamSettings.onClickButtonNewTactic = function() {
        if ($('#tooltipaAddTactic').hasClass('premium-locked')) {
            return;
        }

        var data = {};
        var $list = $('#dropdownTactics');
        var $btn = $('#tooltipaAddTactic');

        var $anchor = $list;
        var $anchor = $('#settingContent');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.post('/team/tactic/add', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            $btn.prop('disabled', rc.limitReached);

            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            var tacticId = rc.tacticId;
            var maxTactics = $list.data('max-tactics');
            $list.dropdown().loadElements('/team/tactic/elements', data, function(ok, response) {
                $list.dropdown().selectByValue(tacticId);

                if ($('#dropdownTactics li').size() >= maxTactics) {
                    $('#tooltipaAddTactic').addClass('premium-locked');
                }

                $list.attr('data-active', 0);
                teamSettings.onClickDropdownUserTactic(tacticId, function() {
                    $('#tacticName').select();
                });
            });
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log('*** onClickButtonNewTactic failed')
        });
    };

    teamSettings.onClickButtonDeleteTactic = function() {
        var active = $("#dropdownTactics").attr("data-active");
        var tacticId = $("#dropdownTactics").dropdown().value(),
            tacticName = $("#dropdownTactics").dropdown().getText();
        olDialog.confirm('#msgTacticDeleteConfirm', {"data-name": tacticName}, function() {
            var data = {tacticId: tacticId};
            addLoadingAnimationToElement($("#dropdownTactics"), {size: "small"});
            $("#dropdownTactics").dropdown().loadElements("/team/tactic/deletetactic", data, function() {
                $("#liTactic" + tacticId).remove();

                if ($('#dropdownTactics li').size() < 2) {
                    $('#tooltipaAddTactic').removeClass('premium-locked');
                }
                removeLoadingAnimationFromElement($("#dropdownTactics"));
                $("#dropdownTactics").dropdown().selectByIndex(1);
                teamSettings.onClickDropdownUserTactic($("#dropdownTactics").dropdown().value());

                $('#tooltipaAddTactic').prop('disabled', false);
            });
        });
    }

    teamSettings.onClickDropdownUserTactic = function(tacticId) {
        var page = teamSettings.page || 'tactic';
        var $anchor = $("#ol-root");
        addLoadingAnimationToElement($anchor);
        $("#settingsContent").getLoad("/team/"+page+"view", {tacticId: tacticId}, function() {
            $("#dropdownTactics").attr("data-active", $("#liTactic" + tacticId).attr("data-active"));
            removeLoadingAnimationFromElement($anchor);
        });
    }

    teamSettings.onKeyInputTacticName = function(event) {
        if (event.keyCode == 13) { //Enter
            $('#tacticName').blur();
        }
    };

    teamSettings.onBlurInputTacticName = function(inputElement) {
        var data = {
            tacticId: $("#dropdownTactics").dropdown().value(),
            name: $(inputElement).val()
        };
        if (data.name === $("#liTactic" + data.userTacticId).attr("data-name")) return;
        if (data.name.length < 3) {
            olMsgBox.msg($("#msgWrongNameHeadline").html(), $("#msgWrongNameSize").html());
            $(inputElement).val($("#liTactic" + data.userTacticId).attr("data-name"));
            return;
        }
        addLoadingAnimationToElement($(inputElement).parent(), { size : "small" });
        $.post("/team/tactic/renametactic", data, function(rc) {
            removeLoadingAnimationFromElement($(inputElement).parent());

            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            $("#liTactic" + data.userTacticId).attr("data-name", data.name);
            $("#dropdownTactics").find(".ol-dropdown-text").html(data.name);
            $("#liTactic" + data.userTacticId).find("a").html(data.name);
        }).fail(function() {
            removeLoadingAnimationFromElement($(inputElement).parent());
            olMsgBox.msg($("#msgWrongNameHeadline").html(), $("#msgWrongName").html());
            $(inputElement).val($("#liTactic" + data.userTacticId).attr("data-name"));
        });
    }


    teamSettings.onClickSetTacticActive = function(bit) {
        var current = $("#selectedTactic").attr("data-active");
        var newActive = current | bit;
        if (newActive == current) return;

        $("#selectedTactic").attr("data-active", newActive);

        var data = {
            userTacticId: $("#dropdownTactics").dropdown().value(),
            active: newActive,
            bit: bit
        };
        addLoadingAnimationToElement($("#dropdownTactics"), {size: "small"});
        $.post("/team/tactic/settacticactive", data, function(rc) {
            removeLoadingAnimationFromElement($("#dropdownTactics"));

            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            var $changed = $("#liTactic" + data.userTacticId).attr("data-active", newActive);
            $("#dropdownTactics li").not($changed).each(function(i, o) {
                var $o = $(o);
                $o.attr("data-active", $o.attr('data-active') &~ bit);
            });
            $changed.attr('data-active', newActive);
            $("#dropdownTactics").attr("data-active", newActive);
        }).fail(function() {
            olMsgBox.msg(Lang.trans('js/team.error'), Lang.trans('js/team.errorActiveStateNotSaved'));
        });
    };

    teamSettings.onChangeTactic1 = function() {
        $('.tactic-1-1, .tactic-1-2').toggle();
    };

    teamSettings.onChangeTactic2 = function() {
        $('#tactic-3').prop('checked', false);
        $('.tactic-2-1, .tactic-2-2').toggle();
        $('.tactic-3-1').show();
        $('.tactic-3-2').hide();
        $('.tactic-3').hide();
        $('#tactic3').removeClass('tactic-padding');
    };

    teamSettings.onChangeTactic3 = function() {
        $('#tactic-2').prop('checked', false);
        $('.tactic-3-c1, .tactic-3-c2').toggleClass('tactic-padding');
        $('.tactic-3').toggle();
        $('.tactic-3-1').toggle();
        $('.tactic-3-2').toggle();
        $('.tactic-2-1').show();
        $('.tactic-2-2').hide();
    };

    teamSettings.updateTactic = function() {
        setTimeout(function() {
            var tactic = 0;
            if ($('#tactic-1').prop('checked'))
                tactic = (tactic | parseInt($('#tactic-1').attr('data-value')));
            if ($('#tactic-2').prop('checked'))
                tactic = (tactic | parseInt($('#tactic-2').attr('data-value')));
            if ($('#tactic-3').prop('checked')) {
                tactic = (tactic | parseInt($('#tactic-3').attr('data-value')));
                tactic = (tactic | parseInt($('#dropdownTotalAttack').dropdown().value()));
            }
            var data = {
                tactic: tactic,
                tacticId: $('#dropdownTactics').dropdown().value()
            };
            $.post('/team/tactic/save', data, function(rc) {
                if (rc.reload) {
                    teamSettings.pageLockReload();
                } else if (rc.err) {
                    olError(rc.err)
                }
            }, 'json').fail(function() {
                console.log('*** updateTactic failed')
            });
        }, 0);
    };




    //--- Mannschaft > Einstellungen > Standards ---
    
    teamSettings.initSetplayPage = function(setPlayIds) {
        teamSettings.page = 'setplay';
        for (var i = 0;  i < setPlayIds.length;  i++) {
            var setPlayId = setPlayIds[i];
            olTeamSettings.cutPlayerName($('#setplay'+setPlayId+' .setplay-col-player'));
            $(window).resize(function() { 
                olTeamSettings.cutPlayerName($('#setplay'+setPlayId+' .setplay-col-player'));
            });
        }
        $('#tacticName').keyup(function(e) {
            olTeamSettings.onKeyInputTacticName(e);
        });
    };

    function makeCollapseSortable($collapse, tacticId, setPlayId) {
        var containerSel = '#setplay-entry-players'+setPlayId;
        var $container = $(containerSel);
        var numPlayers = $('.setplay-entry-players-row', $container).length;

        // Nur Drag & Drop, wenn was zum umsortieren ist
        if (numPlayers <= 1) {
            $container.removeClass('sortable');
            return;
        }

        var dy = $('.setplay-entry-players-row').outerHeight()/2-1;
        $('.sortable', $collapse).sortable({
//            containment: containerSel,
            containment: '#setplayEntry'+setPlayId,
            cursorAt: {top: dy},
            axis: 'y',
            cursor: 'move',
            create: function(ev, ui) {
                // Höhe merken, weil bei Zoom != 100% die Anzeige springt
//                $container.attr('data-height', $container.height()+dy);
                $container.attr('data-height', $container.height());
            },
            start: function(ev, ui) {
                // Höhe explizit setzen, damit die Anzeige bei Zoom != 100% nicht springt
                $container.height($container.attr('data-height'));
                $(ui.item).addClass('sortable-dragging');
            },
            stop: function(ev, ui) {
                $(ui.item).removeClass('sortable-dragging');
                teamSettings.onEntryPlayerChange(tacticId, setPlayId);
            }
        });
    }

    teamSettings.onClickAccordeonSetplay = function(element, tacticId, setPlayId, event) {
        var $collapse = $('#collapse' + setPlayId);
        if ($collapse.html().trim().length == 0) {
            var data = {tacticId: tacticId, setPlayId: setPlayId};
            addLoadingAnimationToElement($collapse, {size: "small"});
            $collapse.getLoad('/team/setplay/entry', data, function() {
                $collapse.collapse('toggle');
                removeLoadingAnimationFromElement($collapse);
                makeCollapseSortable($collapse, tacticId, setPlayId);
            });
            event.stopPropagation();
        }

        var $border = $('#border' + setPlayId);
        $border.toggleClass('active');
    };

    // Called after drag & drop of entry
    teamSettings.onEntryPlayerChange = function(tacticId, setPlayId) {
        var playerAttributesId = $('#dropdownSetplayPlayerAttributes'+setPlayId).dropdown().value();
        var data = {
            tacticId: tacticId,
            setPlayId: setPlayId,
            playerAttributesId: playerAttributesId,
            positions: {}
        };
        var $collapse = $('#collapse'+data.setPlayId);
        var changed = false;
        $('.setplay-entry-players-cell-rank', $collapse).each(function(i,o) {
            var $o = $(o);
            var oldPrio = $o.attr('data-priority'), newPrio = i+1;
//            data.positions[newPrio] = oldPrio;
            data.positions[oldPrio] = newPrio;
            if (newPrio != oldPrio) changed = true;
        });
        //console.log(changed, data.positions);
        if (!changed) return;

        addLoadingAnimationToElement($collapse, {size: "small"});
        $collapse.getLoad('/team/setplay/entry/update', data, function() {
            removeLoadingAnimationFromElement($collapse);
            makeCollapseSortable($collapse, tacticId, setPlayId);
            teamSettings.initPlayerAttributesDropdown('dropdownSetplayPlayerAttributes'+setPlayId, playerAttributesId);
            teamSettings.updateSetplayPlayerName(data.tacticId, data.setPlayId);
        });
    };

    teamSettings.initPlayerAttributesDropdown = function(dropdownId, playerAttributesId) {
        var $dropdown = $('#'+dropdownId).dropdown();
        if (typeof playerAttributesId === 'undefined') playerAttributesId = $dropdown.value();
        $dropdown.selectByValue(playerAttributesId);
        $dropdown.selectedElement().children().click()
    }

    teamSettings.onClickAddView = function(tacticId, setPlayId) {
        var playerAttributesId = $('#dropdownSetplayPlayerAttributes'+setPlayId).dropdown().value();
        var data = {
            tacticId: tacticId,
            setPlayId: setPlayId,
            playerAttributesId: playerAttributesId
        };
        olOverlayWindow.load('/team/setplay/entry/addview', data, function() {
            // Player-Attribute-Auswahl als Entry übernehmen
            teamSettings.initPlayerAttributesDropdown('dropdownSetplayPlayerAttributes', playerAttributesId);

            // OK-Button anpassen
            teamSettings.makeButtonLighter($('.btn-ok'));

            // Spielernamen ggf. abkürzen
            $('.setplay-entry-players-cell-name', $('#setplayEntryNewView'+setPlayId)).each(function(i, o) {
                teamSettings.abbrevPlayerName(o);
            });
        });
    };

    // Wandelt "rgb(...)" in einen Wert, der um `factor` heller ist
    function rgbLighter(rgb, factor) {
        if (typeof factor === 'undefined') factor = 2;
        rgb  = rgb.trim().replace(/^rgb\((.*?),(.*?),(.*?)\)$/, function(m0, r, g, b) {
            var r1 = parseInt(r, 10), g1 = parseInt(g, 10), b1 = parseInt(b, 10);
            if (isNaN(r1) || isNaN(g1) || isNaN(b1)) return m0;
            var r2 = Math.floor(r1 * factor), g2 = Math.floor(g1 * factor), b2 = Math.floor(b1 * factor);
            return 'rgb('+r2+','+g2+','+b2+')';
        });
        return rgb;
    }

    teamSettings.makeButtonLighter = function($element) {
        var rgb = $element.css('background-color').trim(), rgb2 = rgbLighter(rgb);
        $element.css('color', rgb2);
    };

    // Liefert die Liste der selektieren Spieler und enabled/disabled den OK-Button
    function getEntrySelectedPlayers(setPlayId) {
        var $newView = $('#setplayEntryNewView'+setPlayId);
        var $btnOk = $('.btn-ok', $newView);

        var selectedPlayerIds = [];
        $('.setplay-entry-players-row.selected').each(function(i, o) {
            var playerId = $(o).attr('data-playerId');
            selectedPlayerIds.push(playerId);
        });

        var disabled = (selectedPlayerIds.length == 0);
        $btnOk.prop('disabled', disabled);

        // var rgb = $btnOk.css('background-color').trim(), rgb2 = rgbLighter(rgb);
        // $btnOk.css('color', disabled? rgb2 : '#ffffff');

        return selectedPlayerIds;
    }

    function updateSetplayEntryArea(tacticId, setPlayId, $collapse, playerAttributesId) {
        if ($collapse === undefined) $collapse = $('#collapse'+setPlayId);
        if (playerAttributesId === undefined) playerAttributesId = $('#dropdownSetplayPlayerAttributes'+setPlayId).dropdown().value();

        // Drag & Drop initialisieren
        makeCollapseSortable($collapse, tacticId, setPlayId);

        // Player-Attribute aktualisieren
        teamSettings.initPlayerAttributesDropdown('dropdownSetplayPlayerAttributes'+setPlayId, playerAttributesId);

        // playerName in Accordeonzeile aktualisieren
        teamSettings.updateSetplayPlayerName(tacticId, setPlayId);

    }

    teamSettings.onClickEntryPlayerAdd = function($element, tacticId, setPlayId, selectedPlayerIds) {
        if (typeof selectedPlayerIds === 'undefined')
            selectedPlayerIds = getEntrySelectedPlayers(setPlayId);

        var playerAttributesId = $('#dropdownSetplayPlayerAttributes'+setPlayId).dropdown().value();
        var data = {
            tacticId: tacticId,
            setPlayId: setPlayId,
            selectedPlayerIds: selectedPlayerIds,
            playerAttributesId: playerAttributesId
        };

        var $anchor = $('#setplay'+data.setPlayId);
        addLoadingAnimationToElement($anchor, {size: "small"});
        olOverlayWindow.close();
        $.get('/team/setplay/entry/add', data, function(entryHtml) {
            removeLoadingAnimationFromElement($anchor);
            var $collapse = $('#collapse' + setPlayId);
            $collapse.html(entryHtml);
            updateSetplayEntryArea(data.tacticId, data.setPlayId, $collapse, playerAttributesId);
        });
    };

    // Kürzt ggf. den Spielername (x...) bzw. stellt ihn wieder her
    teamSettings.cutPlayerName = function($playerName) {
        var name = $playerName.attr('data-value');
        $playerName.html(name);

        function tooBig() {
            var h = $playerName.height();
            $playerName.css('white-space', 'normal');
            var h2 = $playerName.height();
            $playerName.css('white-space', 'nowrap');
            return (h2 != h);
        }

        while (tooBig()) {
            if (name == '') break;
            name = name.substr(0, name.length-1);
            $playerName.html(name+'&hellip;');
        }
    }

    // Kürzt ggf. den Spielername in `element` zu A. Meyer
    teamSettings.abbrevPlayerName = function(element) {
        var $element = $(element);
        if ($element.length == 0) return;

        var $elementText = $('.playerLink', $element);
        if ($elementText.length == 0) $elementText = $element;
        var name = $elementText.html().trim();

        function tooBig() {
            var h = $element.height();
            $element[0].style.whiteSpace = 'normal';
            var h2 = $element.height();
            $element[0].style.whiteSpace = 'nowrap';
            return (h2 != h);
        }

        if (tooBig()) {
            var shortname = name.replace(/^(.).*\s+(\S+?)$/, '$1. $2');
            $elementText.html(shortname);
        }
    }

    // Aktualisiert den Spielername in der Accordeonzeile
    teamSettings.updateSetplayPlayerName = function(tacticId, setPlayId) {
        var data = {tacticId: tacticId, setPlayId: setPlayId};
        $.getJSON('/team/setplay/entry/playername', data, function(rc) {
            var $playerName = $('#setplay'+rc.setPlayId+' .setplay-players');
            if (rc.playerName) {
                $playerName.removeClass('noselection');
                $playerName.html(rc.playerName);
                $playerName.attr('data-value', rc.playerName);
                teamSettings.cutPlayerName($playerName);
            } else {
                $playerName.addClass('noselection');
                $playerName.html("");
            }
        });
    };


    teamSettings.onClickEntryPlayersToggleSelect = function(element, setPlayId) {
        $(element).toggleClass('selected');

        // OK-Button enablen/disablen
        getEntrySelectedPlayers(setPlayId);
    };

    teamSettings.onClickEntryPlayersDblclickSelect = function(element, tacticId, setPlayId) {
        var $element = $(element);
        $element.removeClass('selected').addClass('selected');
        var selectedPlayerIds = [$element.attr('data-playerId')];

        teamSettings.onClickEntryPlayerAdd($('setplayEntryNewView'+setPlayId), tacticId, setPlayId, selectedPlayerIds);
    };

    teamSettings.onClickEntryPlayerDelete = function(element) {
        var setPlayId = element.getAttribute('data-setPlayId');
        var playerAttributesId = $('#dropdownSetplayPlayerAttributes'+setPlayId).dropdown().value();
        var data = {
            tacticId: element.getAttribute('data-tacticId'),
            setPlayId: setPlayId,
            setPlayEntryId: element.getAttribute('data-setPlayEntryId'),
            playerAttributesId: playerAttributesId
        };
        var $anchor = $('#setplay'+data.setPlayId);
        addLoadingAnimationToElement($anchor, {size: "small"});
        var $collapse = $('#collapse'+data.setPlayId);
        $collapse.getLoad('/team/setplay/entry/delete', data, function() {
            removeLoadingAnimationFromElement($anchor);
            updateSetplayEntryArea(data.tacticId, data.setPlayId, $collapse, playerAttributesId);
        });
    };

    teamSettings.onClickEntryPlayerUp = function(element) {
        var setPlayId = element.getAttribute('data-setPlayId');
        var playerAttributesId = $('#dropdownSetplayPlayerAttributes'+setPlayId).dropdown().value();
        var data = {
            tacticId: element.getAttribute('data-tacticId'),
            setPlayId: setPlayId,
            setPlayEntryId: element.getAttribute('data-setPlayEntryId'),
            priority: element.getAttribute('data-priority'),
            playerAttributesId: playerAttributesId
        };
        var $anchor = $('#setplay'+data.setPlayId);
        addLoadingAnimationToElement($anchor, {size: "small"});
        var $collapse = $('#collapse'+data.setPlayId);
        $collapse.getLoad('/team/setplay/entry/up', data, function() {
            removeLoadingAnimationFromElement($anchor);
            updateSetplayEntryArea(data.tacticId, data.setPlayId, $collapse, playerAttributesId);
        });
    };

    function updateDisabledRows() {
        var currentLineUpActiveState = $('.ol-team-settings-line-up-header').attr('data-active');
        var leagueLineUp = (currentLineUpActiveState == 1 || currentLineUpActiveState == 11);
        var friendlyLineUp = (currentLineUpActiveState == 10 || currentLineUpActiveState == 11);
        $('.ol-team-settings-line-up-row').each(function(index, element) {
            var draggable = $(element).find('.ol-team-settings-player-drag-and-drop');
            var leagueBan = draggable.attr('data-ban-league');
            var friendlyBan = draggable.attr('data-ban-friendly');
            var injured = draggable.attr('data-injury');
            if((leagueLineUp && leagueBan != 'false') || injured != 'false' || (friendlyLineUp && friendlyBan != 'false')) {
                draggable.draggable( 'disable' );
            } else {
                draggable.draggable( 'enable' );
            }
        });
    }

    function updatePlayerBanIcons() {
        var currentLineUpActiveState = $('.ol-team-settings-line-up-header').attr('data-active');
        var leagueLineUp = (currentLineUpActiveState == 1 || currentLineUpActiveState == 11);
        var friendlyLineUp = (currentLineUpActiveState == 10 || currentLineUpActiveState == 11);
        $('.ol-ban-states > div').hide();
        $('.ol-team-settings-line-up-row').each(function(index, element) {
            var leagueBan = $(element).find('.ol-team-settings-player-drag-and-drop').attr('data-ban-league');
            if (leagueLineUp && leagueBan != 'false') {
                //'CYNICAL_FOUL','GROSS_UNSPORTING_CONDUCT','SERIOUS_FOUL','INSULT','YELLOW_RED','5_YELLOW_CARDS'
                if (leagueBan === 'YELLOW_RED')
                    $(element).find('.ol-team-settings-player-lineup-state .ol-yellow-red-card').show().css('opacity', 1);
                else if (leagueBan === '5_YELLOW_CARDS')
                    $(element).find('.ol-team-settings-player-lineup-state .ol-yellow-card').show().css('opacity', 1);
                else if (leagueBan === 'MAX_PLAYER_COUNT') {
                    //$(element).find('.ol-team-settings-player-lineup-state .ol-max-player-count').show().css('opacity', 1);
                } else
                    $(element).find('.ol-team-settings-player-lineup-state .ol-red-card').show().css('opacity', 1);
            } else if (leagueBan != 'false') {
                if (leagueBan === 'YELLOW_RED')
                    $(element).find('.ol-team-settings-player-lineup-state .ol-yellow-red-card').show().css('opacity', 0.5);
                else if (leagueBan === '5_YELLOW_CARDS')
                    $(element).find('.ol-team-settings-player-lineup-state .ol-yellow-card').show().css('opacity', 0.5);
                else if (leagueBan === 'MAX_PLAYER_COUNT')
                    $(element).find('.ol-team-settings-player-lineup-state .ol-max-player-count').show().css('opacity', 0.5);
                else
                    $(element).find('.ol-team-settings-player-lineup-state .ol-red-card').show().css('opacity', 0.5);
            }
            if(friendlyLineUp && leagueBan === 'MAX_PLAYER_COUNT')
                $(element).find('.ol-team-settings-player-lineup-state .ol-max-player-count').show().css('opacity', 0.5);
        });
    }

    function updateAvgValues() {
        var avg = [0, 0, 0, 0, 0, 0];
        var count = [0, 0, 0, 0, 0, 0];
        $(".ol-pitch-position .ol-team-settings-player-drag-and-drop").each(function(index, element) {
            var playerId = $(element).attr("data-player-id");
            if(typeof playerId !== "undefined" && playerId > 0) {
                var listItem = getListItem(playerId);
                avg[0] += parseInt(listItem.attr('data-avg-value'));
                count[0]++;

                var types = $(element).parent().attr('data-position-type').split(',');
                $.each(types, function(index, typeId) {
                    avg[typeId] += parseInt(listItem.attr('data-avg-value'));
                    count[typeId]++;
                });
            } else {
                avg[0] += 0;
                count[0]++;

                var types = $(element).parent().attr('data-position-type').split(',');
                $.each(types, function(index, typeId) {
                    avg[typeId] += 0;
                    count[typeId]++;
                });
            }
        });
        $.each(avg, function(index, value) {
            $('#systemPartAverageValue' + index).text(count[index] > 0 ? parseInt(value / count[index]) : 0);
            if(count[index] > 0) $('#systemPartAverageValue' + index).show();
        });
    }

    teamSettings.onClickEntryPlayerDown = function(element) {
        var setPlayId = element.getAttribute('data-setPlayId');
        var playerAttributesId = $('#dropdownSetplayPlayerAttributes'+setPlayId).dropdown().value();
        var data = {
            tacticId: element.getAttribute('data-tacticId'),
            setPlayId: setPlayId,
            setPlayEntryId: element.getAttribute('data-setPlayEntryId'),
            priority: element.getAttribute('data-priority'),
            playerAttributesId: playerAttributesId

        };
        var $anchor = $('#setplay'+data.setPlayId);
        addLoadingAnimationToElement($anchor, {size: "small"});
        var $collapse = $('#collapse'+data.setPlayId);
        $collapse.getLoad('/team/setplay/entry/down', data, function() {
            removeLoadingAnimationFromElement($anchor);
            updateSetplayEntryArea(data.tacticId, data.setPlayId, $collapse, playerAttributesId);
        });
    };




    //--- Mannschaft > Einstellungen > Spielaktionen ---
    
    // Initalizes playactionsView
    teamSettings.initPlayactionsPage = function(tacticId) {
        teamSettings.page = 'playactions';

        $('#tacticName').keyup(function(event) {
            teamSettings.onKeyInputTacticName(event);
        });        
        
        $('.playactions-col-priority .btn-up.btn-active').on('touchmousedown', function(event) {
            teamSettings.onClickEntryMacroUp(event)
        });
        $('.playactions-col-priority .btn-down.btn-active').on('touchmousedown', function(event) {
            teamSettings.onClickEntryMacroDown(event)
        });

        teamSettings.makePlayactionsSortable(tacticId);        
    };

    teamSettings.showFaded = function($element, html, duration1, duration2) {
        // 06.06.18 zunächst normal anzeigen (ggf. später Fading)
        $element.html(html);
//        if (duration1 === undefined) duration1 = 200;
//        if (duration2 === undefined) duration2 = 500;
//        $element.fadeOut(duration1, 'linear');
//        $element.html(html);
//        $element.fadeIn(duration2, 'linear');
//        $element.removeAttr('style');
    };

    teamSettings.enablePlayactionsSortable = function(tacticId, enabled) {
        var containerSel = '#playaction-entry-macros';
        if (enabled) teamSettings.makePlayactionsSortable(tacticId);
        else teamSettings.unmakePlayactionsSortable(tacticId);
    }

    teamSettings.unmakePlayactionsSortable = function(tacticId) {
        var hasSortable = $('.playactions-entry-macros').hasClass('sortable');
        if (!hasSortable) return;
        try { $('#playaction-entry-macros').sortable('destroy'); } catch (ex) {}

    };

    teamSettings.makePlayactionsSortable = function(tacticId) {
        var containerSel = '#playaction-entry-macros';
        var entrySel = '.playactions-headerrow';
        var $container = $(containerSel);
        var $entries = $(entrySel, $container);
        var numEntries = $entries.length;

        // Nur Drag & Drop, wenn was zum Umsortieren da ist
        if (!$container.hasClass('sortable')) return;
        $container.removeClass('sortable');
        if (numEntries <= 1) return;
        $container.addClass('sortable');

        $(containerSel).sortable({
            containment: '.playactions-entry-macros-containment',
            axis: 'y',
            distance: 8, //~
//            handle: '.playactions-col-name, .playactions-col-status, .playactions-col-action',
            handle: '.drag-handle',
            cursor: 'move',
            start: function(ev, ui) {
                olMobileApp.lockHeader();
                $(ui.item).addClass('sortable-dragging');
            },
            stop: function(ev, ui) {
                olMobileApp.unlockHeader();
                $(ui.item).removeClass('sortable-dragging');
                teamSettings.onPlayactionsChange($container, entrySel, tacticId);
            }
        });
    }

    teamSettings.onPlayactionsChange = function($container, entrySel, tacticId) {
        var data = {
            tacticId: tacticId,
            positions: {}
        };
        var changed = false;
        $(entrySel, $container).each(function(i, o) {
            var $o = $(o);
            var oldPrio = $o.attr('data-priority'), newPrio = i+1;
            data.positions[oldPrio] = newPrio;
            if (newPrio != oldPrio) changed = true;
        });
        if (!changed) return;

        var $anchor = $container;
        $container.removeClass('sortable');
        addLoadingAnimationToElementNoTimeOut($anchor, {size: "small"});
        $.post('/team/playactions/change', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            $('.ol-tab-button-section button:nth-child(3)').click();
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log('*** onPlayactionsChange failed')
        });
    };



    teamSettings.onClickAccordeonPlayaction = function(element, macroId, event, callback) {
        var $collapse = $('#collapse' + macroId);
        if ($collapse.html().trim().length == 0) {
            var data = {macroId: macroId};
            addLoadingAnimationToElement($collapse, {size: "small"});
            $collapse.getLoad('/team/playactions/entry', data, function() {
                $collapse.collapse('toggle');
                removeLoadingAnimationFromElement($collapse);

                var $action = $('#playactions'+data.macroId+' .playactions-col-action');
                if (typeof callback == "function") callback(this);
            });
            if (event) event.stopPropagation();
        }

        $('#playactions'+macroId).toggleClass('active');

//        $collapse.removeAttr('style'); //WICHTIG: hinterläßt sonst style="display: block;" nach fadeIn/Out

        // Sortable nur aktiv, wenn nicht aufgeklappt, weil ansonsten
        // das Verschieber per Finger-Drag mobil nicht geht
        var anyOpened = false;
        $('.playactions-entry-macros .collapse').each(function(i, o) {
            var $o = $(o);
            var opened = $o.hasClass('in');
            if ($o.attr('id') == 'collapse'+macroId) opened = !opened;
            if (opened) anyOpened = true;
        });
        var tacticId = $collapse.attr('data-tacticId');
        teamSettings.enablePlayactionsSortable(tacticId, !anyOpened);
    };

    // Aktion im Header aktualisieren (inkl. kürzen)
    teamSettings.updateAccordeonPlayactionAction = function(macroId) {
        var data = {macroId: macroId};
        var $parent = $('#playactions'+macroId);

        var $anchor = $parent;
        addLoadingAnimationToElement($anchor, {size: "small"});
        $.get("/team/playactions/actiontext", data, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            $actionText = $('.playactions-col-action', $parent);
            $actionText.text(rc);
            $actionText.attr('data-value', rc);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("update of accordeon #"+macroId+" failed");
        });
    };


    teamSettings.onClickEntryMacroUp = function(event) {
        this.onClickEntryMacroUpDown(event, 'up');
    };

    teamSettings.onClickEntryMacroDown = function(event) {
        this.onClickEntryMacroUpDown(event, 'down');
    };

    teamSettings.onClickEntryMacroUpDown = function(event, op) {
        event.preventDefault();
        event.stopPropagation();
        var element = event.target;
        var $element = $(element);
        if (!$element.hasClass('btn-active')) return;

        var data = {
            macroId: element.getAttribute('data-macroId'),
            priority: element.getAttribute('data-priority'),
        };

        var $anchor = $('#playaction-entry-macros');
        addLoadingAnimationToElement($anchor, {size: "small"});
        $.post('/team/playactions/'+op, data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
            
            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            $('.tactic-buttons button:nth-child(3)').click();
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log('*** onClickEntryMacroUpDown failed')
        });
    };


    teamSettings.onBlurInputPlayactionName = function(element) {
        var $element = $(element);
        var data = {
            macroId: $element.attr("data-macroId"),
            name: $element.val()
        };
        if (data.name === $element.attr('data-value')) return;
        if (data.name.length < 3) {
            olMsgBox.msg($("#msgWrongNameHeadline").html(), $("#msgWrongNameSize").html());
            $element.val($element.attr('data-value'));
            return;
        }
        var $anchor = $element.parent();
        addLoadingAnimationToElement($anchor, {size: "small"});
        $.post('/team/playactions/rename', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            var name = $element.val();
            $element.attr("data-value", name);

            // update name in header
            var $actionName = $('#playactions'+data.macroId+' .playactions-col-name');
            $actionName.text(name);
            $actionName.attr('data-value', name);
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            olMsgBox.msg($("#msgWrongNameHeadline").html(), $("#msgWrongName").html());
            $element.val($element.attr("data-value"));
        });
    };

    teamSettings.onKeyInputPlayactionName = function(event) {
        if (event.keyCode == 13) { //Enter
            $(event.target).blur();
        }
    };

    teamSettings.onChangePlayactionActive = function(element) {
        var $element = $(element);
        var $parent = $element.parent();
        var $notActive = $('.action-not-active', $parent);
        var $active = $('.action-active', $parent);

        var active = $element.prop('checked')? 1 : 0;
        $notActive.css('display', active? 'none' : 'inline-block');
        $active.css('display', active? 'inline-block' : 'none');

        var data = {
            macroId: $element.attr('data-macroId'),
            active: active
        };
        var $anchor = $('.playactions-entry-active-label-'+data.macroId);
        addLoadingAnimationToElement($anchor, {size: "small"});
        $.post('/team/playactions/activate', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            // Status im Kopf aktualisieren
            var $text = $('#playactions'+data.macroId+' .playactions-col-status');
            $text.html(rc.html);

            var cls = active? 'class-active' : 'class-inactive';
            $('.playactions-col-status, .playactions-col-name, .playactions-col-action', $('#playactions'+data.macroId)).removeClass('class-active class-inactive').addClass(cls);
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("update of active #"+data.macroId+" failed");
        });
    };
    
    teamSettings.onChangePlayactionLinkType = function(element) {
        var $element = $(element);
        var $parent = $element.parent();
        var $parentLabel = $parent.find('.ol-checkbox-label').first();
        var $notActive = $parentLabel.children().eq(0);
        var $active = $parentLabel.children().eq(1);

        var active = $element.prop('checked') ? 1 : 0;
        $notActive.css('display', active? 'none' : 'inline-block'); 
        $active.css('display', active? 'inline-block' : 'none');

        if(active)
        {
            $element.closest('.playactions-entry').addClass('playactions-entry-logic-or');
            $element.closest('.playactions-entry').removeClass('playactions-entry-logic-and');
        }
        else
        {
            $element.closest('.playactions-entry').addClass('playactions-entry-logic-and');
            $element.closest('.playactions-entry').removeClass('playactions-entry-logic-or');
        }

        var data = {
            macroId: $element.attr('data-macroId'),
            link: active
        };
        var $anchor = $element.parent().parent();
        addLoadingAnimationToElement($anchor, {size: "small"});
        $.post("/team/playactions/link", data, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            var $collapse = $('#collapse' + data.macroId);
            teamSettings.showFaded($collapse, rc.html);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("update of link type #"+data.macroId+" failed");
        });
    };

    teamSettings.initPlayactionEntry = function(macroId, stateId) {
        if (stateId === undefined) stateId = 0;
        setTimeout(function(){ //timeout is needed because the width of the element with the class .bootstrapSliderLabeled/.bootstrapSlider2Labeled is unknown otherwise
            teamSettings.bootstrapSliderLabeled('.bootstrapSliderLabeled', undefined, olTeamSettings.onChangeTriggerItem);
            teamSettings.bootstrapSlider2Labeled('.bootstrapSlider2Labeled', undefined, olTeamSettings.onChangeTriggerItem);
            
            // add state color to slider track, necessary for relaunch style
            var $sliderTrack = $('.bootstrapSliderContainer .slider-track .slider-selection');
            var $sliderHandle = $('.bootstrapSliderContainer .slider-track .slider-handle');
            $sliderTrack.each(function(i, el) {
                $(el).addClass('ol-state-primary-color-'+ stateId);
            });
            $sliderHandle.each(function(i, el) {
                $(el).addClass('ol-state-primary-color-'+ stateId);
            });
            
        }, 20);

        $('#playactionName'+macroId).keyup(function(e) {
            teamSettings.onKeyInputPlayactionName(e);
        });
    };

    teamSettings.onClickPlayactionAdd = function(tacticId) {
        var data = {tacticId: tacticId};

        var $anchor = $('#playaction-entry-macros');
        addLoadingAnimationToElement($anchor, {size: "small"});
        $.post('/team/playactions/add', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
            
            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            // Inhalt von Spielaktionen neu laden
            loadPageContent('/team/playactions', '#ol-root', {tacticId: tacticId}, function() {
                // Akkordion aufklappen
                teamSettings.onClickAccordeonPlayaction(this, rc.macroId, null, function(element) {
                    // Makroname selektieren
                    var $macroName = $('#playactionName'+rc.macroId);
                    $macroName.focus().select();
                });

            });
        });
    };



    teamSettings.onClickPlayactionTriggerDelete = function(element) {
        var data = {
            macroId: element.getAttribute('data-macroId'),
            linkIndex: element.getAttribute('data-linkIndex'),
        };

        var $anchor = $('#btn-trigger-delete-'+data.macroId+'-'+data.linkIndex);
        addLoadingAnimationToElement($anchor, {size: "small"});
        $.post('/team/playactions/trigger/delete', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
 
            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            var $collapse = $('#collapse' + data.macroId);
            teamSettings.showFaded($collapse, rc.html);
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log('*** onClickPlayactionTriggerDelete failed');
        });
    };

    teamSettings.onClickTriggerAddView = function(macroId) {
        var data = {macroId: macroId};
        olOverlayWindow.load('/team/playactions/trigger/addview', data, function() {
            getPlayactionSelectedTriggers(macroId);
        });
    };

    teamSettings.onClickPlayactionTriggerToggleSelect = function(element) {
        var $element = $(element);
        var macroId = $element.attr('data-macroId');
        $element.toggleClass('selected');
        getPlayactionSelectedTriggers(macroId);
    };

    teamSettings.onClickPlayactionTriggerDblclickSelect = function(element) {
        var $element = $(element);
        var macroId = $element.attr('data-macroId');
        var type = $element.attr('data-type');
        var selectedTypes = [type];
        teamSettings.onClickPlayactionTriggerAdd(macroId, selectedTypes);
    };

    teamSettings.onClickPlayactionTriggerAdd = function(macroId, selectedTypes) {
        if (typeof selectedTypes === 'undefined')
            selectedTypes = getPlayactionSelectedTriggers(macroId);

        var data = {
            macroId: macroId,
            selectedTypes: selectedTypes
        };

        var $anchor = $('#btn-trigger-add-'+data.macroId);
        addLoadingAnimationToElement($anchor, {size: "small"});
        olOverlayWindow.close();
        $.post('/team/playactions/trigger/add', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
            
            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            var $collapse = $('#collapse' + macroId);
            teamSettings.showFaded($collapse, rc.html);
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log('*** onClickPlayactionTriggerAdd failed');
        });
    };

    // Liefert die Liste der selektieren Trigger und enabled/disabled den OK-Button
    function getPlayactionSelectedTriggers(macroId) {
        var $newView = $('#playactionTriggerAddView-'+macroId);
        var $btnOk = $('.btn-ok', $newView);

        var selectedTypes  = [];
        $('.playaction-triggeradd-row.selected').each(function(i, o) {
            var type = $(o).attr('data-type');
            selectedTypes.push(type);
        });

        var disabled = (selectedTypes.length == 0);
        $btnOk.prop('disabled', disabled);

        // var rgb = $btnOk.css('background-color').trim(), rgb2 = rgbLighter(rgb);
        // $btnOk.css('color', disabled? rgb2 : '#ffffff');

        return selectedTypes;
    }


    teamSettings.onClickActionAddView = function(macroId) {
        var data = {macroId: macroId};
        olOverlayWindow.load('/team/playactions/action/addview', data, function() {
            getPlayactionSelectedActions(macroId);
        });
    };

    teamSettings.onClickPlayactionActionToggleSelect = function(element) {
        var $element = $(element);
        var macroId = $element.attr('data-macroId');
        $element.toggleClass('selected');
        getPlayactionSelectedActions(macroId);
    };

    teamSettings.onClickPlayactionActionDblclickSelect = function(element) {
        var $element = $(element);
        var macroId = $element.attr('data-macroId');
        var tacticId = $element.attr('data-tacticId');
        var type = $element.attr('data-type');
        var selectedTypes = [type];
        teamSettings.onClickPlayactionActionAdd(macroId, selectedTypes);
    };

    teamSettings.onClickPlayactionActionAdd = function(macroId, selectedTypes) {
        if (typeof selectedTypes === 'undefined')
            selectedTypes = getPlayactionSelectedActions(macroId);

        var data = {
            macroId: macroId,
            selectedTypes: selectedTypes
        };

        var $anchor = $('#btn-action-add-'+data.macroId);
        addLoadingAnimationToElement($anchor, {size: "small"});
        olOverlayWindow.close();
        $.post('/team/playactions/action/add', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
            
            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            var $collapse = $('#collapse' + macroId);
            teamSettings.showFaded($collapse, rc.html);
            teamSettings.updateAccordeonPlayactionAction(data.macroId);
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log('*** onClickPlayactionActionAdd failed')
        });
    };

    // Liefert die Liste der selektierten Actions und enabled/disabled den OK-Button
    function getPlayactionSelectedActions(macroId) {
        var $newView = $('#playactionActionAddView-'+macroId);
        var $btnOk = $('.btn-ok', $newView);

        var selectedTypes  = [];
        $('.playaction-actionadd-row.selected').each(function(i, o) {
            var type = $(o).attr('data-type');
            selectedTypes.push(type);
        });

        var disabled = (selectedTypes.length == 0);
        $btnOk.prop('disabled', disabled);

        // var rgb = $btnOk.css('background-color').trim(), rgb2 = rgbLighter(rgb);
        // $btnOk.css('color', disabled? rgb2 : '#ffffff');

        return selectedTypes;
    }

    teamSettings.onClickPlayactionActionDelete = function(element) {
        var data = {
            macroId: element.getAttribute('data-macroId'),
            index: element.getAttribute('data-index'),
        };

        var $anchor = $('#btn-action-delete-'+data.macroId+'-'+data.index);
        addLoadingAnimationToElement($anchor, {size: "small"});
        $.post('/team/playactions/action/delete', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
            
            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            var $collapse = $('#collapse' + data.macroId);
            teamSettings.showFaded($collapse, rc.html);
            teamSettings.updateAccordeonPlayactionAction(data.macroId);
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log('*** onClickPlayactionActionDelete failed')
        });
    };



    teamSettings.onClickPlayactionDeleteConfirm = function(event) {
        var element = event.target;
        event.stopPropagation(); // Auf-/Zuklappen unterdrücken

        var $element = $(element);
        var macroId = $element.attr('data-macroId');
        var name = $('#playactionName'+macroId).val() || $('#playactions'+macroId+' .playactions-col-name').text();
        var header = $element.attr('data-msg-header'),
            text = $element.attr('data-msg-content').replace(/{playaction}/g, name),
            textYes = $element.attr('data-msg-textYes'),
            textNo = $element.attr('data-msg-textNo'),
            callbackNo = undefined,
            callbackYes = function() {
                teamSettings.onClickPlayactionDelete(macroId);
            };
        olMsgBox.question(header, text, callbackYes, callbackNo, textYes, textNo);
    }
    teamSettings.onClickPlayactionDelete = function(macroId) {
        var data = {macroId: macroId};

        var $anchor = $('#playactionsEntry'+data.macroId);
        addLoadingAnimationToElement($anchor, {size: "small"});
        $.post('/team/playactions/delete', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
            $('button[data-sub-url="/team/playactions"]').click();
        });
    };



    teamSettings.onChangeActionActive = function(element, addElement) {
        var $element = $(element);
        var $parent = $element.parent();
        var $notActive = $('.action-not-active', $parent);
        var $active = $('.action-active', $parent);

        var active = $element.prop('checked');
        $notActive.css('display', active? 'none' : 'inline-block');
        $active.css('display', active? 'inline-block' : 'none');

        var implicationId = $element.attr('data-implicationId'),
            value = active? 1 : 0,
            value2 = -1;
        if (addElement) {
            value2 = Number($(addElement).parent().attr('data-value'));
        }
        teamSettings.updateActionItem(implicationId, value, value2);

        // Just value2 selection change?
        if (addElement) return;

        // Also adjust dependent item
        if ($element.hasClass('dependentActionItem')) {
            var type = $element.attr('data-type');
            var macroId = $element.attr('data-macroId');
            $element.addClass('pending');
            var $row = $('#playactions-entry-action'+macroId);
            $('.dependentActionItem', $row).each(function(i, o) {
                var $o = $(o);
                var oType = $o.attr('data-type');
                if (oType == type) return;
                if ($o.hasClass('pending')) return; // $o called me
                if ($o.prop('checked') != active) return; // already inverse
                $o.click();
            });
            $element.removeClass('pending');
        }

        // Toggle select display according to value
        if ($element.hasClass('dependentActionSelect')) {
            var $area = $('#'+$element.attr('id')+'-tacticArea');
            $area.removeClass('display0').removeClass('display1').addClass('display'+value);
        }
    };

    teamSettings.onClickDropdownActionFormation = function(element) {
        var $element = $(element);
        var implicationId = $element.attr('data-implicationId'),
            value = $element.attr('data-formationId');
        teamSettings.updateActionItem(implicationId, value);
    };

    teamSettings.onClickDropdownActionPlayer = function(element) {
        var $element = $(element);

        // Ensure disabled element is not clicked
        var $li = $element.closest('li');
        var liValue = $li.attr('data-value');
        if ($li.hasClass('disabled')) return;

        // Change player
        var playerId = $element.attr('data-playerId'),
            out = ($element.attr('data-kind') == 'out');
        var implicationId = $element.attr('data-implicationId'),
            value = out? playerId : -1,
            value2 = out? -1 : playerId;
        teamSettings.updateActionItem(implicationId, value, value2);

        // Adjust connected player dropdown accordingly
        var baseDropdownId = $element.closest('div.dropdown').attr('id').replace(/-(?:in|out)$/, ''),
            outDropdownId = baseDropdownId+'-out',
            inDropdownId = baseDropdownId+'-in';
        var outValue = out? liValue : $('#'+outDropdownId).dropdown().value(),
            inValue = !out? liValue : $('#'+inDropdownId).dropdown().value();
        if (out) {
            $('#'+inDropdownId+' li').removeClass('disabled');
            $('#'+inDropdownId+' li[data-value="'+outValue+'"]').addClass('disabled');
            if (outValue == inValue) {
                var lis = $('#'+inDropdownId+' li').get();
                for (var i = 0;  i < lis.length;  i++) {
                    var li = lis[i];
                    var value = li.getAttribute('data-value');
                    if (value == outValue) continue;
                    $('a', li).click();
                    break;
                }
            }
        }
    };

    // Führt die Aktualisierung des ActionItem `implicationId`
    // auf `value`/`value2` durch
    teamSettings.updateActionItem = function(implicationId, value, value2) {
        if (value === undefined) value = -1;
        if (value2 === undefined) value2 = -1;
        var $row = $('#playactions-entry-action-row-'+implicationId);
        var data = {
            implicationId: implicationId,
            value: value,
            value2: value2
        };
        var $anchor = $row;//$('#playactions-entry-action-row-'+data.implicationId);
        addLoadingAnimationToElement($anchor, {size: "small"});
        $.post('/team/playactions/action/update', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            // Eintrag in der Zusammenfassung aktualisieren
            var rowId = 'summary-action-row-'+implicationId;
            var $parent = $('#'+rowId);
            var $text = $('.playactions-entry-summary-text', $parent);
            $text.text(rc.data.summaryText);

            // Aktion im Header aktualisieren
            teamSettings.updateAccordeonPlayactionAction(rc.data.macroId);
        }) .fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("update of action item #"+implicationId+" failed");
        });
    };


    teamSettings.onChangeTriggerItem = function(element) {
        var $element = $(element);
        var macroId = $element.attr('data-macroId'),
            linkIndex = $element.attr('data-linkIndex'),
            value = $element.attr('data-value'),
            value2 = $element.attr('data-value2');
        var valueType = $element.attr('data-value-type') || '';
        if (valueType == 'playersoff') {
            value = 11 - Number(value);
        } else if (valueType == 'minute') {
            var v = value.split(/,/);
            value = v[0];
            value2 = v[1];
            if(value2 == 90) {              
                $('#'+ element.id + '-label .bootstrapSlider2Label-1').addClass('sliderLabel-matchend');
            } else {
                $('#'+ element.id + '-label .bootstrapSlider2Label-1').removeClass('sliderLabel-matchend');
            }
        } else if (valueType == 'value2') {
            value2 = value;
            value = -1;
        } else if (valueType == 'value2-mapped') {
            var valueMap = JSON.parse($element.attr('data-value-map'));
            value2 = valueMap[value];
            value = -1;
        }

        var data = {
            macroId: macroId,
            linkIndex: linkIndex,
            value: value,
            value2: value2
        };
        var itemId = macroId+'_'+linkIndex;

        var $anchor = $('#playactions-entry-trigger-row-'+itemId);
        addLoadingAnimationToElement($anchor, {size: "small"});
        $.post('/team/playactions/trigger/update', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            if (rc.reload) {
                teamSettings.pageLockReload();
                return;
            } else if (rc.err) {
                olError(rc.err);
                return;
            }

            // Eintrag in der Zusammenfassung aktualisieren
            var rowId = 'summary-trigger-row-'+itemId;
            var $parent = $('#'+rowId);
            var $text = $('.playactions-entry-summary-text', $parent);
            $text.text(rc.html);
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("update of trigger item #"+itemId+" failed");
        });
    };



    //--- Tools ---

    // "title|text" or "text"
    function olError(text) {
        var title;
        var m = text.match(/^([^|]*)\|(.*)$/);
        if (m) {
            title = m[1];
            text = m[2];
        } else {
            title = $('#msgTitleError').html();
        }
        olMsgBox.msg(title, text);
    }

    function getJsonValue(s, dflt) {
        var value = dflt;
        try {
            value = s? JSON.parse(s) : dflt;
        } catch (ex) {
            console.log(""+ex);
            value = dflt;
        }
        return value;
    }



    //--- Widgets ---

    // Erzeugt ein boostrapSlider mit Belabelung aus den Input-Elementen gemäß `selector`.
    // Das Input-Element muss die Attribute id und data-slider-* haben.
    // `options` ist optional (Default: {scale: 'linear', tooltip: 'hide'}).
    // Wenn `onslidestop` angegeben ist, dann wird diese nach Abschluss einer Eingabeaktion
    // (Click, Drag) aufgerufen (nicht zwischendrin).
    //
    // Zusätzliche Optionen:
    // data-slider-labeltype=""        normal (Label links alignen)
    // data-slider-labeltype="adjust"  Label abhängig von Positon links/mittig/rechts alignen
    // data-slider-labeltype="all"     alle Label anzeigen
    olTeamSettings.bootstrapSliderLabeled = function(selector, options, onslidestop) {
        if (options === undefined) options = {scale: 'linear', tooltip: 'hide'};
        var labelClass = options.labelClass || 'bootstrapSliderLabel';
        var $elements = $(selector);
        $elements.each(function(i, element) {
            var $element = $(element),
                elementId = $element.attr('id'),
                labelId = elementId+"-label",
                elementValue = $element.attr('data-slider-value');
            var labeltype = $element.attr('data-slider-labeltype'),
                all = (labeltype == 'all'),
                adjust = (labeltype == 'adjust');
            if ($element.data('initialized')) return;
            $element.bootstrapSlider(options);

            var bgLabels = '';
            if (all) {
                var min = Number($element.attr('data-slider-min')),
                    max = Number($element.attr('data-slider-max')),
                    step = Number($element.attr('data-slider-step'));
                var w = $element.width(),
                    ow = $element.outerWidth();
                if (olGUI.getBootstrapDeviceSize() == 'ol-xs') w -= 14, ow -= 14; //!?
                var n = (max-min) / step,
                    dx = w/n,
                    x0 = w - ow;
                for (var value = min;  value <= max;  value += step) {
                    var offset = x0 + ((value - min) / step) * dx;
                    bgLabels +='<div class="'+labelClass+'-bg" style="margin-left: '+offset+'px">'+value+'</div>';
                }
                $element.after(
                    '<div id="'+labelId+'" class="'+labelClass+'">'+
                        bgLabels+
                    '</div>');
            } else {
                $element.after(
                    '<div id="'+labelId+'" class="'+labelClass+'">'+
                        '<div id="'+labelId+'-0" class="'+labelClass+'-0">'+elementValue+'</div>'+
                    '</div>');
            }

            function sliderUpdated(value) {
                var min = Number($element.attr('data-slider-min')),
                    max = Number($element.attr('data-slider-max')),
                    step = Number($element.attr('data-slider-step')),
                    names = getJsonValue($element.attr('data-slider-names'), {});
                var w = $element.width(),
                    ow = $element.outerWidth();
                if (olGUI.getBootstrapDeviceSize() == 'ol-xs') w -= 14, ow -= 14; //!?
                var n = (max-min) / step,
                    dx = w/n,
                    x0 = w - ow;

                var offset = x0 + ((value - min) / step) * dx;
                if (all) {
                    $('.'+labelClass+'-bg').removeClass(labelClass+'-cur')
                    $('#'+labelId+' .'+labelClass+'-bg').each(function(i, o) {
                        var $o = $(o);
                        if ($o.css('margin-left') != offset+'px') return;
                        $o.addClass(labelClass+'-cur');
                    });
                } else {
                    var $label = $("#"+labelId+'-0');
                    var valueText = names[value] || value;
                    $label.text(valueText);
                    var transform = '';
                    if (adjust) {
                        var $labelContainer = $label.parent();
                        $labelContainer.removeClass('value-min value-middle value-max');
                        if (value >= max) {
                            offset += 20;
                            transform = 'translateX(-100%)';
                            $labelContainer.addClass('value-max');
                        } else if (value > min) {
                            offset += 10;
                            transform = 'translateX(-50%)';
                            $labelContainer.addClass('value-middle');
                        } else {
                            $labelContainer.addClass('value-min');
                        }
                    }
                    $label.css({marginLeft: offset+'px', transform: transform});
                }

                $element.data('value', value);
            };

            $element.on("slide",  function(ev){ sliderUpdated(ev.value);          });
            $element.on("change", function(ev){ sliderUpdated(ev.value.newValue); });
            if (typeof onslidestop === 'function') {
                $element.on('slideStop', function(ev) { onslidestop(ev.target); });
            }
            $element.data('initialized', true);
            sliderUpdated(elementValue);
        });
    };

    // wie bootstrapSliderLabeled, aber mit 2 Marken
    olTeamSettings.bootstrapSlider2Labeled = function(selector, options, onslidestop) {
        if (options === undefined) options = {scale: 'linear', tooltip: 'hide'};
        var labelClass = options.labelClass || 'bootstrapSlider2Label';
        var $elements = $(selector);
        $elements.each(function(i, element) {
            var $element = $(element),
                elementId = $element.attr('id'),
                labelId = elementId+"-label",
                elementValue = $element.attr('data-slider-value');
            if ($element.data('initialized')) return;
            $element.bootstrapSlider(options);

            var labeltype = $element.attr('data-slider-labeltype'),
                flip = (labeltype == 'flip');

            $element.after('<div id="'+labelId+'" class="'+labelClass+'">'+
                '<div id="'+labelId+'-0" class="'+labelClass+'-0">'+elementValue[0]+'</div>'+
                '<div id="'+labelId+'-1" class="'+labelClass+'-1">'+elementValue[1]+'</div>'+
                '</div>');

            function sliderUpdated(value) {
                var min = $element.attr('data-slider-min'),
                    max = $element.attr('data-slider-max'),
                    step = $element.attr('data-slider-step'),
                    names = getJsonValue($element.attr('data-slider-names'), {}),
                    namesxs = getJsonValue($element.attr('data-slider-names-xs'), {});

                var w = $element.width(),
                    ow = $element.outerWidth();                    
                var xs = (olGUI.getBootstrapDeviceSize() == 'ol-xs');
                if (xs) w -= 14, ow -= 14; //!?
                var n = (max-min) / step,
                    dx = w/n,
                    x0 = w - ow;

                var value0 = value[0],
                    offset0 = x0 + (value0 - min) * dx,
                    $label0 = $("#"+labelId+"-0");
                var value0Text = names[value0] || value0;
                if (xs && namesxs[value0]) value0Text = namesxs[value0];
                $label0.text(value0Text);
                $label0.removeClass('value-left'); if (xs && value0 >= max) $label0.addClass('value-left');
                $label0.css('margin-left', offset0+"px");

                var value1 = value[1],
                    offset1 = x0 + (value1 - min) * dx,
                    $label1 = $("#"+labelId+"-1");
                var value1Text = names[value1] || value1;
                if (xs && namesxs[value1]) value1Text = namesxs[value1];
                $label1.text(value1Text);
                $label1.removeClass('value-left'); if (xs && value1 >= max) $label1.addClass('value-left');
                $label1.css('margin-left', offset1+"px");

                if (flip && value0 != value1) {
                    // label0 overlaps to the right => flip label0 left
                    var width0 = $label0.width();
                    var dw = 20;
                    if (width0 > dw && offset0 + width0 > offset1) {
                        $label0.addClass('value-left');
                    }

                    // label0 still overlaps cause to near => move label left
                    if (offset0 + dw > offset1) {
                        offset0 = offset1 - dw - dw*0; //2x wg. value-left
                        $label0.css('margin-left', offset0+"px");
                        $label0.addClass('value-left');
                        $label1.removeClass('value-left');
                    }
                }

                $element.data('value', value);
            };

            $element.on("slide",  function(ev){ sliderUpdated(ev.value);          });
            $element.on("change", function(ev){ sliderUpdated(ev.value.newValue); });
            if (typeof onslidestop === 'function') {
                $element.on('slideStop', function(ev) { onslidestop(ev.target); });
            }
            $element.attr('data-initialized', true);
            sliderUpdated(JSON.parse(elementValue));
        });
    };



    //--- Mobile ---

    // Shows and hides the box-shadow of the team lineup list header
    function scrollShadowHandler(ev) {
        if ($('#lineUpPlayerList').scrollTop() > 0) {
            $('.ol-team-settings-list-header').addClass('docked');
        } else {
            $('.ol-team-settings-list-header').removeClass('docked');
        }
    }

    // Sets sizes and margins on scroll or resize
    function stickyPitchHandler(ev) {
        // Only active for small devices and in portrait
        if (olGUI.getBootstrapDeviceSize() != 'ol-xs') return;

        if (olGUI.isLandscape()) {
            //$('#lineUpPlayerList').css('height',"calc(100vh - 97px)");
            $('#lineUpPlayerList').css('height', window.innerHeight- (97+35));
            $('#playerDetails').css('height', window.innerHeight  - 50 );
            return true;
        }

        $('#lineUpPlayerList').css('height', window.innerHeight  - ($('#playingFieldContainer').height() + (93 + 35) ));
        $('#playerDetails').css('height', window.innerHeight  - ($('#playingFieldContainer').height() + 50 ));
    }

    // Handles clicks on pitch positions outside of move mode
    function pitchScrollToPlayer (ev) {
        if (olGUI.getBootstrapDeviceSize() != 'ol-xs') return;

        teamSettings.hideDetails();

        var pid = $(this).data('player-id');
        if (pid) {
            olGUI.scrollTo('[data-player-id='+pid+']:last', 40, '.ol-line-up-player-list');
            selectPlayer(pid);
        }
    };

    teamSettings.hideDetails = function(ev) {
        if (ev) ev.stopPropagation()
        $('#playerDetails').removeClass('show-details');
        $('#lineUpPlayerList').removeClass('off-screen');
        detailsActive = false;
        return false;
    }

    teamSettings.showDetails = function(ev) {
        detailsActive = true;
        if (olGUI.isLandscape()) {
            $('#playerDetails').addClass('show-details');
            $('#lineUpPlayerList').addClass('off-screen');
        } else {
            $('#playerDetails').addClass('show-details');
            $('#lineUpPlayerList').addClass('off-screen');
        }
    }

    // Shows the player details on mobile devices
    var playerDetailsMobile = function(ev) {
        var playerId = $(this).parent().find('[data-player-id]').data('player-id');
        var $details = $('#lineUpPlayer' + playerId);

        if ($details.size() >= 1) {
            selectPlayer(playerId);
            $('.ol-player-details-selected').removeClass('ol-player-details-selected');
            $details.addClass('ol-player-details-selected')
            teamSettings.showDetails();
        } else {
            addLoadingAnimationToElementNoTimeOut($(this).parent(), {'size': 'small'} );
            var self = this;
            $.get('/team/lineup/playerdetail', { 'playerId' : playerId, 'showGraph': true }, function(response)
            {
                selectPlayer(playerId);
                $('.ol-player-details-selected').removeClass('ol-player-details-selected');
                $('.ol-team-settings-player-details').append(response);
                $details = $('#lineUpPlayer' + playerId);
                $details.addClass('ol-player-details-selected');
                teamSettings.showDetails();
            }).always(function() {
                removeLoadingAnimationFromElement($(self).parent());
            });
        }

    }

    // Hides the mobil ehelper menu and ends move mode
    var hideMobileHelper = function(ev) {
        if (ev) ev.stopPropagation();
        $('.settings-mobile-helper-dialog').removeClass('expanded');
        $('#playingFieldContainer').removeClass('settings-mobile-helper-pitch-indicator');
        return false;
    }

    // Displays the mobile helper menu and starts move mode
    var showMobileHelper = function(ev) {
        var row = $(this).parents('.ol-team-settings-line-up-row');

        // Don't allow moving of injured or banned players when editing an active line-up
        if ($(this).hasClass("locked") && parseInt($('#selectedLineUp').data('active')) != 0) {
            return;
        }

        // Getting the list name from id, e.g. collapseLineUpCollapse -> LineUp
        var playerStatus = row.parents('.collapse').attr('id').split("").slice(8,-8).join("");

        var playerId = row.find("[data-player-id]").data('player-id');
        var playerName = row.find('.ol-team-position-complete-name').text();

        var dialogue = $('.settings-mobile-helper-dialog');
        dialogue.removeClass('player-bench player-other');

        // Hide the other/bench buttons if the player is already there...
        if (playerStatus == "Sub") dialogue.addClass('player-bench');
        if (playerStatus == "Other") dialogue.addClass('player-other');

        // ..or if we are at the maximum amount of substitute players
        var subSize = squadSize - 11;
        var notAllowedSubSquadSize = $('#collapseSub').children().length >= subSize;
        if(notAllowedSubSquadSize) {
            dialogue.addClass('player-bench')
        }

        // Fill in data and expand
        dialogue.find('.player-name').text(playerName);
        dialogue.data('player-id', playerId);
        dialogue.addClass('expanded');

        // Add animation to pitch positions
        $('#playingFieldContainer').addClass('settings-mobile-helper-pitch-indicator');

        // Select player and scroll to, do it asynchronously
        setTimeout(function() {
            selectPlayer(playerId);
            olGUI.scrollTo('[data-player-id='+playerId+']:last', 158, '.ol-line-up-player-list');
        }, 1);

    };

    // show and handle events of buttons that move players to and from bench/pitch/other
    olTeamSettings.handleQuickOverlayButtons = function(playerId) {
        var $row = $('#playerListId'+playerId);
        // var $btnBench = $('.ol-profile-button[data-target="#collapseSub"]');
        var $btnPitch = $('.ol-profile-button[data-target="LineUp"]');
        var $btnOther = $('.ol-profile-button[data-target="#collapseOther"]');

        // Don't allow moving of injured or banned players when editing an active line-up
        if ($row.hasClass("lineup-player-locked") && parseInt($('#selectedLineUp').data('active')) != 0) {
           return
        }

        // Get status where player is lined up (Sub, Other, LineUp)
        var playerStatus = $row.parents('.collapse').attr('id').split("").slice(8,-8).join("");

        // Calculate if there is space to move player to bench
        var subSize = squadSize - 11;
        var notAllowedSubSquadSize = $('#collapseSub').children('.ol-lineup-player-details-row').length >= subSize;

        // If player is not on bench and bench isn't full show button to move player to bench
        // if(playerStatus != "Sub" && !notAllowedSubSquadSize) {
        //     $btnBench.removeClass('hidden');

        //     $btnBench.click(function() {
        //         $('#paddingBoxContainer').attr('data-target', '#collapseSub');
        //         movePlayerFromOverlay(event, playerId);
        //         olOverlayWindow.close();
        //     })
        // }

        // If player is on bench or lineUp show button to move player out of squad
        if(playerStatus != "Other") {
            $btnOther.removeClass('hidden');

            $btnOther.click(function() {
                $('#paddingBoxContainer').attr('data-target', '#collapseOther');
                olOverlayWindow.close();
                movePlayerFromOverlay(event, playerId);
            })
        }

        if(playerStatus == 'LineUp') {
            $btnPitch.removeClass('hidden')

            $btnPitch.click(function() {
                $('#paddingBoxContainer').attr('data-target', 'LineUp');
                olOverlayWindow.close();
                // open playerOverlay after close animation of quickoverviewwindow is finished
                setTimeout(function() {
                    togglePlayerSelectionOverlay(event, playerId)
                }, 400)
            })
        }

    }

    // Handles touch on the bench/other buttons when in player move mode
    function movePlayerButtonHandler(ev) {
        var parent = $(this).parents('.settings-mobile-helper-dialog')
        var playerId = parent.data('player-id');

        var player = getListItem(playerId);

        // remove from playing field if necessary
        updateAvgValues();
        clearFieldPositionItem(getFieldPositionItem(playerId));

        $($(this).data('target')+'Collapse').collapse('show');

        addToList(player, $(this).data('target'), true);

        hideMobileHelper();
    }

    function movePlayerFromOverlay(ev, playerIdParam) {
        var $overlay = $('#paddingBoxContainer.ol-lineup-mobile-player-list-overlay');
        var target = $('#paddingBoxContainer').attr('data-target'); 
        if (playerIdParam === undefined) {
            var id = $(event.target).closest('.ol-lineup-player-details-row').attr('id');
            var regex = id.match(/\d+/g);
            var playerId = parseInt(regex[0]);
            var player = getListItem(playerId);
        } else {
            var playerId = playerIdParam;
            var player = getListItem(playerIdParam);
        }

        // dont move player if he should not be moved
        if(player.hasClass('lineup-player-locked')) {
            return
        }
        
        // remove from playing field if necessary
        updateAvgValues();
        clearFieldPositionItem(getFieldPositionItem(playerId));

        addToList(player, $(target), false);

        hideMobileHelper();

        // close and reset overlay
        resetAndClosePlayerOverlay()
        $(document).off('click', '#paddingBoxContainer .ol-player-overlay-click-area', movePlayerFromOverlay)
    }

    function togglePlayerSelectionOverlay(ev, swapPlayer) {
       var $overlay = $('#paddingBoxContainer.ol-lineup-mobile-player-list-overlay');
       var $overlayBench = $('.ol-lineup-mobile-player-list-overlay-bench');
       var $mobileNav = $('.ol-mobile-bottom-nav-section');
       
       if(event) {
            var $target = $(event.target).parent();
       } else {
            var $target = false;  
       }

       if ($target && 
        ($target.hasClass('ol-lineup-empty-bench-slot') ||
         $target.hasClass('ol-linup-player-bench-wrapper'))) {
            $overlayBench.addClass('hidden');
            $overlay.attr('data-target', '#collapseSub');
            $(document).on('click', '#paddingBoxContainer .ol-player-overlay-click-area', movePlayerFromOverlay)
            $overlay.toggleClass('open');
            $mobileNav.addClass('hidden');
        } else if ($target && $target.hasClass('ol-lineup-mobile-player-list-overlay-head')){
            // close and reset overlay
            resetAndClosePlayerOverlay()
        } else {
            if (swapPlayer) {
                var fieldItem = getFieldPositionItem(swapPlayer);
                var positionId = fieldItem.parent().attr('data-position');
                $overlay.attr('data-target', 'LineUp');
                $overlay.attr('data-position', positionId);
            } else {
                var pitchPosition = $target.parents('.ol-pitch-position');
                var pitchPositionId = pitchPosition.attr('data-position');
                $overlay.attr('data-target', 'LineUp');
                $overlay.attr('data-position', pitchPositionId);
            }

            // copy players from bench in to overlay
            var $benchPlayers = $('#collapseSubCollapse .ol-team-settings-line-up-rows').children().clone();
            $overlayBench.removeClass('hidden');
            $benchPlayers.appendTo('#tempBench');

            $(document).on('click', '#paddingBoxContainer .ol-player-overlay-click-area', movePlayerFromOverlayPitch)
            $overlay.toggleClass('open');
            $mobileNav.addClass('hidden');
       }
    }

    function movePlayerFromOverlayPitch(ev) {
        var $overlay = $('#paddingBoxContainer.ol-lineup-mobile-player-list-overlay');
        var id = $(event.target).closest('.ol-lineup-player-details-row').attr('id');
        var regex = id.match(/\d+/g);
        var sourcePlayerId = parseInt(regex[0]);
        
        var positionId = $overlay.attr('data-position');
        if (typeof positionId  === "undefined") {
            return
        }
        var $targetPos = $('.ol-pitch-position[data-position="'+ positionId +'"]');
        var targetPlayerId = $targetPos.find('.ol-team-settings-player-drag-and-drop').attr('data-player-id');

        var srcPlayerListItem = getDragAndDropListItem(sourcePlayerId);
        var fieldItem = getFieldPositionItem(targetPlayerId);

        var positionType = $(fieldItem).parent().attr("data-player-position-index"); 
        var playerPositionType = $(srcPlayerListItem).attr("data-player-type");

        // dont move player if he should not be moved
        if(srcPlayerListItem.hasClass('lineup-player-locked') || srcPlayerListItem.hasClass('ui-draggable-disabled')) {
            return
        }
        
        if ( lockPlayerType === false ||
            (positionType === '256' && playerPositionType === '1') ||
            (positionType !== '256' && playerPositionType === '2' && positionId !== '1') || // fieldplayer cannot be placed as keeper
            (positionId === '1' && playerPositionType === '1') // keeper
            ) {
            if (targetPlayerId != sourcePlayerId) {
                if (typeof targetPlayerId === "undefined") {
                    // insert into empty position
                    insertPlayer(sourcePlayerId, $targetPos.attr("data-position"));
                } else {
                    // switch player position
                    switchPlayer(targetPlayerId, sourcePlayerId, false);
                }
                // selectPlayer(sourcePlayerId);
            }
        }
        // close and reset overlay
        resetAndClosePlayerOverlay()
        $(document).off('click', '#paddingBoxContainer .ol-player-overlay-click-area', movePlayerFromOverlayPitch)
    }

    function resetAndClosePlayerOverlay() {
        var $overlay = $('#paddingBoxContainer.ol-lineup-mobile-player-list-overlay');
        $('#tempBench').empty();
        $('.ol-lineup-mobile-player-list-overlay-bench').addClass('hidden');
        $overlay.removeClass('open');
        $overlay.removeAttr('data-target');
        $overlay.removeAttr('data-position');
        $('.ol-mobile-bottom-nav-section').removeClass('hidden');
    }

    // Handles touch on a pitch position when in player move mode
    function movePlayerPitchHandler(ev) {
        var dialogue = $('.settings-mobile-helper-dialog')

        var targetPlayerId = $(this).find(".ol-team-settings-player-drag-and-drop").attr("data-player-id");
        var sourcePlayerId = dialogue.data('player-id');

        var srcPlayerListItem = getDragAndDropListItem(sourcePlayerId);
        var fieldItem = getFieldPositionItem(targetPlayerId);
        var positionType = $(fieldItem).parent().attr("data-player-position-index"); 
        var playerPositionType = $(srcPlayerListItem).attr("data-player-type");

        hideMobileHelper();

        if (lockPlayerType === false || (positionType === '256' && playerPositionType === '1') || (positionType !== '256' && playerPositionType === '2')) {
            if (targetPlayerId != sourcePlayerId) {
                if (typeof targetPlayerId === "undefined") {
                    // insert into empty position
                    insertPlayer(sourcePlayerId, $(this).attr("data-position"));
                } else {
                    // switch player position
                    switchPlayer(targetPlayerId, sourcePlayerId, false);
                }
                selectPlayer(sourcePlayerId);
                olGUI.scrollTo('[data-player-id='+sourcePlayerId+']:last', 40, '.ol-line-up-player-list');
            }
        }
    }


    /*
     * Logic  to handle the swipe away motion for player details
     */
    var detailsTouchStartX = 0;
    var passedMinimum = false;
    var touchMove = 0;
    var goingAway = false;
    function playerDetailsSwipe(ev) {
        var event = ev.originalEvent;
        if (ev.type == "touchstart") {
            detailsTouchStartX = event.touches[0].clientX;
            $('#playerDetails').css('transition', 'none');
            return;
        }

        // Set the distance require for the div to go away
        var moveGoal =  $('#playerDetails').width() * 0.333;
        console.log(moveGoal);


        if (ev.type == "touchmove") {
            console.log("touchmove");
            touchMove = event.touches[0].clientX - detailsTouchStartX;

            // Set a lower limit so minor touches don't move the div
            if (touchMove > 30)
                passedMinimum = true;

            touchMove = Math.max(10, touchMove);

            var touchMoveOffset = touchMove;
            if (olGUI.isLandscape()) touchMoveOffset += Math.round(window.innerWidth/2)

            if (passedMinimum) {
                $('#playerDetails').css('left', touchMoveOffset-5);
                $('#lineUpPlayerList').css('left', touchMoveOffset-window.innerWidth);

                // If the user moved at least 1/3 of the screen prepare to remove the div
                if (!goingAway && touchMove > moveGoal) {
                    goingAway = true;
                    $('#playerDetails').addClass('drag-away');
                } else if (goingAway && touchMove < moveGoal) {
                    goingAway = false;
                    $('#playerDetails').removeClass('drag-away');
                }
            }

            return;
        }

        // Remove the div if needed
        if (ev.type == "touchend") {
            if (touchMove > moveGoal) {
                teamSettings.hideDetails();
                $('#playerDetails').css('transition', '').removeClass('show-details').css('left', '').removeClass('drag-away');
            } else {
                $('#playerDetails').css('transition', '').css('left', '').removeClass('drag-away');
            }

            passedMinimum = false;
        }
    }

    // Handles mobile player selection on full row
    function mobilePlayerSelect (ev) {
        if (!$('body').hasClass('ol-xs')) return;
        var delay = 0;
        if (ev.type == "touchstart") {
            delay = 200;
        }

        var self = this;
        setTimeout(function() {
            var pid = $(self).find('[data-player-id]').data('player-id');
            if (pid) {
                selectPlayer(pid);
            }
        }, delay);
    }

    var mobile = true;
    var dragScrollDirection = 0;
    // Handles scrolling while draggin on mobile
    function mobileScrollHelper (ev) {
        if (!mobile) return;

        var playerList = $('#lineUpPlayerList');

        var dragOffset = ev.pageY - playerList.offset().top;

        if (dragState.dragging && dragOffset < playerList.height()*0.2 && dragOffset > 0 && dragScrollDirection != -1) {
            dragScrollDirection = -1;
            dragScroll(playerList);
        } else if (dragState.dragging && dragOffset > playerList.height()*0.8  && dragOffset < playerList.height() && dragScrollDirection != 1) {
            dragScrollDirection = 1;
            dragScroll(playerList);
        } else {
            dragScrollDirection = 0;
        }
    }

    function dragScroll(playerList) {
        if (!dragState.dragging || dragScrollDirection == 0) return;

        playerList.scrollTop(playerList.scrollTop()+5*dragScrollDirection);
        setTimeout(function() {dragScroll(playerList)}, 5);
    }

    // Events for mobile drag&drop helper
    $(document).on('click', '.settings-mobile-helper-dialog .btn-cancel', hideMobileHelper);
    $(document).on('click', '.ol-team-settings-player-mobile-helper', showMobileHelper);
    $(document).on('click', '.settings-mobile-helper-dialog .ol-buttons .ol-button-mobile-dialogue:not(.btn-cancel)', movePlayerButtonHandler);

    // Event to handle scrolling while dragging
    $(document).on('drag', '#lineUpPlayerList', mobileScrollHelper);

    // Event to lock heights
    $(document).on('click', '.settings-mobile-helper-pitch-indicator .ol-pitch-position', movePlayerPitchHandler);

    // Events for player selection
    $(document).on('click touchclick', '#divPitch .ol-team-settings-player-drag-and-drop', pitchScrollToPlayer);
    $(document).on('click touchclick', '.ol-team-settings-line-up-row', mobilePlayerSelect);

    // Events for mobile playerdetails
    $(document).on('click', '.ol-settings-mobile-playerdetails-caret', playerDetailsMobile);

    $(document).on('touchstart', '#playerDetails', playerDetailsSwipe)
    $(document).on('touchmove', '#playerDetails', playerDetailsSwipe)
    $(document).on('touchend', '#playerDetails', playerDetailsSwipe)

    // toggle mobile player overlay
    $(document).on(
        'click', 
        '.ol-player-linup-position-empty, .ol-lineup-empty-bench-slot, .ol-lineup-mobile-player-list-overlay-close',
        togglePlayerSelectionOverlay
    );

    
    // Adjust height of player list to account for variable height pitch preview
    //$(document).on('scroll', stickyPitchHandler)
    //$(document).on('touchmove', stickyPitchHandler)
    //$(document).on('orientationchange', stickyPitchHandler);
    // Same checks on resize
    //$(document).on('resize', stickyPitchHandler);

    var lineupFullScreen = false;

    function appHeaderHandler () {
        if (olAppInfo.APP_MODE && location.hash.includes('lineup')) {
            if (!lineupFullScreen && getWindowScroll() > 50) {
                olMobileApp.hideHeader();
                olMobileApp.hideBottomNav();
                lineupFullScreen = true;
            } else if (lineupFullScreen && getWindowScroll() <= 50) {
                olMobileApp.showHeader();
                olMobileApp.showBottomNav();
                lineupFullScreen = false;
            }
        }
    }

    function toggleInfoTextEditor(event) {
        var el = $(event.target);

        if (el.hasClass('editing')) {
            el.removeClass('editing');
            el.text(el.data('text-edit'));

            $('.infotext').show();
            $('.infotext-editor').hide();
            $('.team-info-text-edit-characters').hide()

            addLoadingAnimationToElementNoTimeOut($('.infotext'));
            $.post('/team/updateinfotext', { 'text': CKEDITOR.instances.infotextEditor.getData() }, function(result) {
                var el = $(result.view);
                $('#teamInfoTextRow').replaceWith(el);
                removeLoadingAnimationFromElement($('.infotext'));
            });
        } else {
            el.addClass('editing');
            el.text(el.data('text-save'));

            $('.infotext').hide();
            $('.team-info-text-edit-characters').show();
            $('.infotext-editor').removeClass('hide');
            $('.infotext-editor').show();
        }
    }
    $(document).on('click', '#editTeamTextButton', toggleInfoTextEditor);

}( window.olTeamSettings = window.olTeamSettings || {}, jQuery ));

