// File for basic image editing.
// Zoom, Move, with overlay showing which areas are cropped.

(function(olSimpleImageEditor, $, undefined)
{
    
    olSimpleImageEditor.create = function() {
        // Input data
        olSimpleImageEditor.input = {
            'url': undefined,
            'image': undefined
        }

        // Transform info (calculates view window and stuff)
        olSimpleImageEditor.transform = {
            // Zoom in range of olSimpleImageEditor.limits.zoom
            'zoom': 1,
            // Move in range of 0..1 (normalized UV)
            'move': [0, 0],
            'offset': [0, 0]
        };
        olSimpleImageEditor.transformCached = olSimpleImageEditor.transform; // Cache for recalculation.

        // Previews (can be multiple sizes)
        olSimpleImageEditor.previews = new Map();
        /*{
         id = {
         ...
         },
         }*/

        olSimpleImageEditor.editors = new Map();
        /*{
         id = {
         ...
         },
         }*/

        // Limits
        olSimpleImageEditor.limits = {
            'zoom': [0.05, 1.0],
            'aspect': 0.0
        }

        // tmpFileName
        olSimpleImageEditor.tmpFileName = "";
    };
    
    olSimpleImageEditor.load = function(imageURL, readyCallback) {
        if ((imageURL == undefined) || (typeof (imageURL) != 'string')) {
            throw "Invalid URL";
        }
        
        if(typeof readyCallback === typeof undefined)
            readyCallback = false;

        // Step 1: Load the URL as a File/Blob
        // Step 2: Create an ImageBitmap from the File/Blob
        // Step 3: ???
        // Step 4: Profit.

        olSimpleImageEditor.input.url = imageURL;
        var tempImg = new Image();

        tempImg.onload = function() {
            if(readyCallback !== false)
                readyCallback();
            olSimpleImageEditor.update();
        };
            
        tempImg.src = imageURL;
        olSimpleImageEditor.input.image = tempImg;
    };
    
    function _fitImageIntoArea()
    {
        if(olSimpleImageEditor.input.image.width > olSimpleImageEditor.input.image.height)
        {
            var ratio = olSimpleImageEditor.input.image.height / olSimpleImageEditor.input.image.width;
            olSimpleImageEditor.input.image.style.width = Math.ceil(150) + "px";
            olSimpleImageEditor.input.image.style.height = Math.ceil(150 * ratio) + "px";
        }
        else
        {
            var ratio = olSimpleImageEditor.input.image.width / olSimpleImageEditor.input.image.height;
            olSimpleImageEditor.input.image.style.height = Math.ceil(150) + "px";
            olSimpleImageEditor.input.image.style.width = Math.ceil(150 * ratio) + "px";
        }
    }
    
    olSimpleImageEditor.setAspectRatio = function(x, y) {
        if ((typeof (x) == 'number') && (typeof (y) == 'number')) {
            olSimpleImageEditor.limits.aspect = x / y;
        } else if ((typeof (x) == 'number') && (y == undefined)) {
            olSimpleImageEditor.limits.aspect = x;
        } else if (x instanceof Array) {
            if ((typeof (x[0]) == 'number') && (typeof (x[1]) == 'number')) {
                olSimpleImageEditor.limits.aspect = x[0] / x[1];
            } else if ((typeof (x['x']) == 'number') && (typeof (x['y']) == 'number')) {
                olSimpleImageEditor.limits.aspect = x['x'] / x['y'];
            } else {
                throw "Invalid Argument";
            }
        } else {
            throw "Invalid Argument";
        }
        _limit();
        olSimpleImageEditor.update();
    }

    olSimpleImageEditor.setZoomRange = function(x, y) {
        if ((typeof (x) == 'number') && (typeof (y) == 'number')) {
            olSimpleImageEditor.limits.zoom[0] = x;
            olSimpleImageEditor.limits.zoom[1] = y;
        } else if (x instanceof Array) {
            if ((typeof (x[0]) == 'number') && (typeof (x[1]) == 'number')) {
                olSimpleImageEditor.limits.zoom[0] = x[0];
                olSimpleImageEditor.limits.zoom[1] = x[1];
            } else if ((typeof (x['x']) == 'number') && (typeof (x['y']) == 'number')) {
                olSimpleImageEditor.limits.zoom[0] = x['x'];
                olSimpleImageEditor.limits.zoom[1] = x['y'];
            } else {
                throw "Invalid Argument";
            }
        } else {
            throw "Invalid Argument";
        }
        _limit();
        olSimpleImageEditor.update();

    }

    // Set zoom
    olSimpleImageEditor.zoom = function(zoom) {
        if (zoom == undefined) {
            return olSimpleImageEditor.transform.zoom;
        } else {
            olSimpleImageEditor.transform.zoom = zoom;
        }
        _limit();
        olSimpleImageEditor.update();
    }

    // Set position
    olSimpleImageEditor.position = function(x, y) {

        //toDo erkennen eines neuen FileUploads
        // loeschen aller Daten -> neu berechnen erzwingen

        // daten eines bildes anzeigen -> seite neugeladen
        // daten eines bildes anzeigen -> neuer File Upload wurde gestartet
        // 
        // daten nach neuen Uplaod in Uebereinstimmung mitg den daten, beim ersten File Upload


        olSimpleImageEditor.position.isUndefined = true;

        if (olSimpleImageEditor.input.image == undefined) {
            return;
        }
        var xNew = 0;
        var yNew = 0;
        if (x == undefined) {
            return [olSimpleImageEditor.transform.move[0] * olSimpleImageEditor.input.image.width, olSimpleImageEditor.transform.move[1] * olSimpleImageEditor.input.image.height];
        } else if (x instanceof Array) {
            if ((typeof (x[0]) == 'number') && (typeof (x[1]) == 'number')) {
                xNew = x[0];
                yNew = x[1];
            } else if ((typeof (x['x']) == 'number') && (typeof (x['y']) == 'number')) {
                xNew = x['x'];
                yNew = y['y'];
            } else {
                throw "Invalid Input";
            }
        } else if ((typeof (x) == 'number') && (typeof (y) == 'number')) {
            xNew = x;
            yNew = y;

        } else {
            throw "Invalid Input";
        }
        
        olSimpleImageEditor.transform.move[0] = x / olSimpleImageEditor.input.image.width;
        olSimpleImageEditor.transform.move[1] = y / olSimpleImageEditor.input.image.height;

        _limit();
        olSimpleImageEditor.update();
    }

    // Move position
    olSimpleImageEditor.move = function(x, y) {
        if (olSimpleImageEditor.input.image == undefined) {
            return;
        }

        var xAdd = 0;
        var yAdd = 0;
        if (x instanceof Array) {
            if ((typeof (x[0]) == 'number') && (typeof (x[1]) == 'number')) {
                xAdd = x[0];
                yAdd = x[1];
            } else if ((typeof (x['x']) == 'number') && (typeof (x['y']) == 'number')) {
                xAdd = x['x'];
                yAdd = x['y'];
            } else {
                throw "Invalid Input";
            }
        } else if ((typeof (x) == 'number') && (typeof (y) == 'number')) {
            xAdd = x;
            yAdd = y;
        } else {
            throw "Invalid Input";
        }
        olSimpleImageEditor.transform.move[0] = olSimpleImageEditor.transform.move[0] + (xAdd / olSimpleImageEditor.input.image.width);
        olSimpleImageEditor.transform.move[1] = olSimpleImageEditor.transform.move[1] + (yAdd / olSimpleImageEditor.input.image.height);
        _limit();
        olSimpleImageEditor.update();
    }

    function _limit() {
        return;
        // Note to future developers: Do yourself a favor and never implement a method
        //  that changes more than a single transform parameter. It will only complicate
        //  the following logic further, to the point where you can't maintain it anymore.
        // If you do anyway, you've been warned and I'll take no responsibility - X.
        // Limit Values
        if (olSimpleImageEditor.transform.zoom != olSimpleImageEditor.transformCached.zoom) {
            var oldCenter = [
                (olSimpleImageEditor.transformCached.zoom / 2.0) + olSimpleImageEditor.transformCached.move[0],
                (olSimpleImageEditor.transformCached.zoom / 2.0) + olSimpleImageEditor.transformCached.move[1]
            ];
            olSimpleImageEditor.transform.zoom = Math.min(Math.max(olSimpleImageEditor.transform.zoom, olSimpleImageEditor.limits.zoom[0]), olSimpleImageEditor.limits.zoom[1]);
            olSimpleImageEditor.transform.move[0] = oldCenter[0] - (olSimpleImageEditor.transform.zoom / 2.0);
            olSimpleImageEditor.transform.move[1] = oldCenter[1] - (olSimpleImageEditor.transform.zoom / 2.0);
        }
        {// Do not make olSimpleImageEditor depend on move only, it also needs to take into account zooming.
            var viewSize = {
                'x': 1.0,
                'y': 1.0,
            }
            if ((olSimpleImageEditor.input.image != undefined) && olSimpleImageEditor.limits.aspect > 0) {
                // The logic here is a bit complex:
                // 1. Calculate the "view" into the image, using the aspect ratio.
                //    ex 1: Image(1920x1080), Aspect(16:9), Output(1920x1080)
                //    ex 2: Image(1080x1920), Aspect(16:9), Output(1080x607.5)
                //    ex 3: Image(1080x1080), Aspect(16:9), Output(1080x607.5)
                // 2. Convert it to normalized space (0..1) by dividing by image size.
                // 3. Subtract the value from 1.0 to find the normalized move range.

                // Calculate the "view" into the image using the aspect ratio limit.
                viewSize.x = olSimpleImageEditor.input.image.width;
                viewSize.y = olSimpleImageEditor.input.image.height;
                if (olSimpleImageEditor.limits.aspect > 1.0) { // TODO: Figure out a way to simplify olSimpleImageEditor.
                    viewSize.y = viewSize.x * (1.0 / olSimpleImageEditor.limits.aspect);
                } else {
                    viewSize.x = viewSize.y * olSimpleImageEditor.limits.aspect;
                }
                viewSize.x /= olSimpleImageEditor.input.image.width;
                viewSize.y /= olSimpleImageEditor.input.image.height;
            }
            viewSize.x *= olSimpleImageEditor.transform.zoom;
            viewSize.y *= olSimpleImageEditor.transform.zoom;
            olSimpleImageEditor.transform.move[0] = Math.min(Math.max(olSimpleImageEditor.transform.move[0], 0), 1.0 - viewSize.x);
            olSimpleImageEditor.transform.move[1] = Math.min(Math.max(olSimpleImageEditor.transform.move[1], 0), 1.0 - viewSize.y);
        }

        // Update cached transform
        olSimpleImageEditor.transformCached = olSimpleImageEditor.transform;
    }

    function _handleMouseDown(event) {
        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el == undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }

        var editor = olSimpleImageEditor.editors.get(el);
        editor.drag.enabled = true;
        editor.drag.x = event.layerX;
        editor.drag.y = event.layerY;
        editor.handler.style.cursor = "grabbing";
        editor.handler.classList.add("dragging");
        // TODO: Add and remove MouseMove, MouseLeave handlers on MouseDown and MouseUp/MouseLeave (Battery saving and speed improvements)
    }

    function _handleMouseUp(event) {
        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el == undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }

        var editor = olSimpleImageEditor.editors.get(el);
        editor.drag.enabled = false;
        editor.drag.x = event.layerX;
        editor.drag.y = event.layerY;
        editor.handler.style.cursor = "grab";
        editor.handler.classList.remove("dragging");
    }
    
    var prevX = 0;
    var prevY = 0;
    function _handleMouseMove(event) {
        var movementX = (prevX ? event.screenX - prevX : 0)
        var movementY = (prevY ? event.screenY - prevY : 0)

        prevX = event.screenX;
        prevY = event.screenY;
        
        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el == undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }

        if (!olSimpleImageEditor.editors.get(el).drag.enabled) {
            return;
        }

        // FIXME: Take into account Zoom and editor size, so that mouse moves align with the actual cursor.
        olSimpleImageEditor.move(-movementX, -movementY);
    }

    function _handleMouseEnter(event) {
        _handleMouseUp(event);
    }

    function _handleMouseLeave(event) {
        _handleMouseUp(event);
    }

    function _handleTouchStart(event) {
        prevX = event.touches[0].pageX;
        prevY = event.touches[0].pageY;
    }

    function _handleTouchMove(event) {
        var movementX = (prevX ? event.touches[0].pageX - prevX : 0)
        var movementY = (prevY ? event.touches[0].pageY - prevY : 0)

        prevX = event.touches[0].pageX;
        prevY = event.touches[0].pageY;
        
        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el == undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }
        // FIXME: Take into account Zoom and editor size, so that mouse moves align with the actual cursor.
        olSimpleImageEditor.move(-movementX, -movementY);
    }

    function _handleTouchCancel(event) {
        // TODO
        // https://w3c.github.io/touch-events/#touches-and-targettouches-of-a-touchevent
    }

    function _handleTouchEnd(event) {
        // TODO
        // https://w3c.github.io/touch-events/#touches-and-targettouches-of-a-touchevent
    }

    olSimpleImageEditor.addEditor = function(element) {
        // Editors automatically contain a preview.
        olSimpleImageEditor.removeEditor(element);

        var elHandler = document.createElement('div');
        elHandler.classList.add("handler");
        elHandler.style.display = "block";
        elHandler.style.position = "absolute";
        elHandler.style.top = "0px";
        elHandler.style.bottom = "0px";
        elHandler.style.left = "0px";
        elHandler.style.right = "0px";
        elHandler.style['z-index'] = "16777215";
        elHandler.style.background = "transparent";
        elHandler.style.border = "0 solid";
        elHandler.style.margin = "0";
        elHandler.style.padding = "0";
        elHandler.style.cursor = "grab";
        elHandler.onmousedown = _handleMouseDown;
        elHandler.onmouseup = _handleMouseUp;
        elHandler.onmousemove = _handleMouseMove;
        elHandler.onmouseenter = _handleMouseEnter;
        elHandler.onmouseleave = _handleMouseLeave;
        elHandler.ontouchstart = _handleTouchStart;
        elHandler.ontouchend = _handleTouchEnd;
        elHandler.ontouchmove = _handleTouchMove;
        elHandler.ontouchcancel = _handleTouchCancel;
        elHandler.source = olSimpleImageEditor;

        var elRender = document.createElement('div');
        elRender.classList.add("render");
        elRender.style.display = "block";
        elRender.style.position = "absolute";
        elRender.style.top = "0px";
        elRender.style.bottom = "0px";
        elRender.style.left = "0px";
        elRender.style.right = "0px";
        elRender.style.background = "transparent";
        elRender.style.border = "0 solid transparent";
        elRender.style.margin = "0",
                elRender.style.padding = "0";
        elRender.source = olSimpleImageEditor;

        var elContainer = document.createElement('div');
        elContainer.classList.add("simpleimageeditor");
        elContainer.classList.add("editor");
        elContainer.style.display = "block";
        elContainer.style.position = "relative";
        elContainer.style.background = "transparent";
        elContainer.style.border = "0 solid transparent";
        elContainer.style.margin = "0",
                elContainer.style.padding = "0";
        elContainer.style.width = "100%";
        elContainer.style.height = "100%";
        elContainer.appendChild(elRender);
        elContainer.appendChild(elHandler);
        elContainer.source = olSimpleImageEditor;

        element.appendChild(elContainer);
        olSimpleImageEditor.editors.set(element, {
            'container': elContainer,
            'handler': elHandler,
            'render': elRender,
            'drag': {
                'enabled': false,
                'x': 0,
                'y': 0,
            },
        });

        return olSimpleImageEditor.addPreview(elRender);
    }

    olSimpleImageEditor.getEditorPreview = function(element) {
        if (!olSimpleImageEditor.editors.has(element)) {
            return;
        }

        return olSimpleImageEditor.editors.get(element).render;
    }

    olSimpleImageEditor.removeEditor = function(element) {
        if (!olSimpleImageEditor.editors.has(element)) {
            return;
        }

        var editor = olSimpleImageEditor.editors.get(element);
        olSimpleImageEditor.removePreview(editor.render);
        element.removeChild(editor.container);

        olSimpleImageEditor.editors.set(element, undefined);
    }

    olSimpleImageEditor.addPreview = function(element) {
        olSimpleImageEditor.removePreview(element);

        var elImage = document.createElement('img');
        elImage.classList.add("image");
        elImage.setAttribute("id", "imageSrc");
        elImage.style.display = "block";
        elImage.style.position = "absolute";
        elImage.style.left = "0%";
        elImage.style.top = "0%";
        elImage.style.width = "100%";
        elImage.style.height = "100%";
        elImage.source = olSimpleImageEditor;

        var canvasOverlay = document.createElement('canvas');
        canvasOverlay.classList.add("canvasBlur");
        canvasOverlay.setAttribute("id", "canvasBlur");
        canvasOverlay.style.width = "350";
        canvasOverlay.style.height = "350";
        canvasOverlay.source = olSimpleImageEditor;

        var canvasOverlay2 = document.createElement('canvas');
        canvasOverlay2.classList.add("canvasBlur");
        canvasOverlay2.setAttribute("id", "canvasBlur2");
        canvasOverlay2.setAttribute("left", "0px");
        canvasOverlay2.style.width = "300";
        canvasOverlay2.style.height = "300";
        canvasOverlay2.source = olSimpleImageEditor;

        var elOverlay = document.createElement('div');
        elOverlay.classList.add("overlay");
        elOverlay.style.display = "block";
        elOverlay.style.position = "absolute";
        elOverlay.style.width = "100%";
        elOverlay.style.height = "100%";
        elOverlay.style.top = "0px";
        elOverlay.style.bottom = "0px";
        elOverlay.style.left = "0px";
        elOverlay.style.right = "0px";
        elOverlay.style['z-index'] = "16777215";
        elOverlay.style.background = "transparent";
        elOverlay.style['border-width'] = "0";
        elOverlay.style.margin = "0";
        elOverlay.style.padding = "0";
        elOverlay.appendChild(canvasOverlay);
        elOverlay.appendChild(canvasOverlay2);
        elOverlay.source = olSimpleImageEditor;

// kleine Voransicht des hochgeladenen Bildes
        var elContainer = document.createElement('div');
        elContainer.classList.add("simpleimageeditor");
        elContainer.classList.add("preview");
        elContainer.style.display = "block";
        elContainer.style.position = "relative";
        elContainer.style.background = "transparent";
        elContainer.style.border = "0 solid transparent";
        elContainer.style.margin = "0",
                elContainer.style.padding = "0";
        elContainer.style.width = "100%";
        elContainer.style.height = "100%";
        elContainer.appendChild(elImage);

        elContainer.appendChild(elOverlay);
        elContainer.source = olSimpleImageEditor;
        element.appendChild(elContainer);
        olSimpleImageEditor.previews.set(element, {
            'container': elContainer,
            'overlay': elOverlay,
            'image': elImage,
            'border': 0,
        });

        // blur effect for canvasBlur

        const canvasBlur = document.getElementsByClassName('canvasBlur');
        canvasBlur[0].height = 350;
        canvasBlur[0].width = 350;
        canvasBlur[1].height = 300;
        canvasBlur[1].width = 300;

        const ctxBlur = canvasBlur[0].getContext('2d');
        ctxBlur.globalAlpha = 0.6;
        ctxBlur.fillStyle = "#D8D8D8";
        ctxBlur.fillRect(0, 0, 350, 100);
        ctxBlur.fillRect(0, 100, 100, 150);
        ctxBlur.fillRect(250, 100, 100, 150);
        ctxBlur.fillRect(0, 250, 350, 100);
        
        // line around select
        /*
        ctxBlur.beginPath();
        ctxBlur.globalAlpha = 1
        ctxBlur.setLineDash([4,3,4,3,4,3,4,3,4,3,4,3,4,3,4,3,4,3,4,3,8,3,4,3,4,3,4,3,4,3,4,3,4,3,4,3,4,3,4,3,4]);
        ctxBlur.lineDashOffset = 3;
        ctxBlur.moveTo(101, 101);
        ctxBlur.lineTo(249, 101);
        ctxBlur.lineTo(249, 249);
        ctxBlur.lineTo(101, 249);
        ctxBlur.lineTo(101, 101);
        ctxBlur.strokeStyle = "#000000";
        ctxBlur.stroke();
        */
        var imageUrl = olConfig.image_host + 'imgs/dashed_line.png';

        var image = new Image();
        image.onload = function() {           
        ctxBlur.drawImage(image,99,99,152,152);
        }
        image.src = imageUrl;

        return elOverlay;
    };

    olSimpleImageEditor.setPreviewBorder = function(element, pixels) {
        if (!olSimpleImageEditor.previews.has(element)) {
            return;
        }

        var preview = olSimpleImageEditor.previews.get(element);
        preview.border = pixels;
        olSimpleImageEditor.update();
    }

    olSimpleImageEditor.getPreviewOverlay = function(element) {
        if (!olSimpleImageEditor.previews.has(element)) {
            return;
        }

        return olSimpleImageEditor.previews.get(element).overlay;
    }

    olSimpleImageEditor.removePreview = function(element) {
        if (!olSimpleImageEditor.previews.has(element)) {
            return;
        }

        var preview = olSimpleImageEditor.previews.get(element);
        element.removeChild(preview.container);

        olSimpleImageEditor.previews.set(element, undefined);
    }
    var lastZoom = 1;
    olSimpleImageEditor.update = function() {
        // Calculate view window
        var imageAspect = 1.0;
        if (olSimpleImageEditor.input.image != undefined) {
            imageAspect = olSimpleImageEditor.input.image.width / olSimpleImageEditor.input.image.height;
        }

        // TODO: Go through all previews and do stuff (see Documents\test.html)
        olSimpleImageEditor.previews.forEach(function(value, key, map) {
            var view = {
                'x': 0, 'y': 0, 'w': 0, 'h': 0, 'aspect': 0
            }

            // Calculate Container Size and Aspect Ratio
            var containerSize = {
                'w': value.container.clientWidth,
                'h': value.container.clientHeight
            }
            var containerAspect = containerSize.w / containerSize.h;

            // Calculate View Size and Aspect Ratio
            view.w = containerSize.w - (value.border * 2);
            view.w = 150;
            view.h = containerSize.h - (value.border * 2);
            view.h = 150;
            
            if (olSimpleImageEditor.limits.aspect > 0) {
                if (containerAspect < olSimpleImageEditor.limits.aspect) {
                    view.h = view.w * (1.0 / olSimpleImageEditor.limits.aspect);
                } else {
                    view.w = view.h * olSimpleImageEditor.limits.aspect;
                }
            }
            view.aspect = view.w / view.h;

            // Calculate and apply border and offsets
            var border = {
                'x': Math.floor((containerSize.w - view.w) / 2.0),
                'y': Math.floor((containerSize.h - view.h) / 2.0)
            }
            value.container.style.margin = border.y + 'px ' + border.x + 'px';
            value.overlay.style['margin-left'] = -border.x + "px";
            value.overlay.style['margin-top'] = -border.y + "px";
            value.overlay.style['margin-right'] = border.x + "px";
            value.overlay.style['margin-bottom'] = border.y + "px";
            value.overlay.style['border-width'] = border.y + 'px ' + border.x + 'px';

            // Calculate size and reposition the image.
            var imgSize = {
                'w': view.w,
                'h': view.h,
            };
            
            var _imageW = (olSimpleImageEditor.input.image != undefined) ? olSimpleImageEditor.input.image.width : 150;
            var _imageH = (olSimpleImageEditor.input.image != undefined) ? olSimpleImageEditor.input.image.height : 150;
            
            if(_imageW > _imageH) {
                var ratio = _imageH / _imageW;
                imgSize.w = 150;
                imgSize.h = Math.ceil(150 * ratio);
            }
            else {
                var ratio = _imageW / _imageH;
                imgSize.h = 150;
                imgSize.w = Math.ceil(150 * ratio);
            }
            
            var invZoom = 1.0 / olSimpleImageEditor.transform.zoom;
            if (olSimpleImageEditor.transform.zoom != 1.0) {
                //imgSize.w = imgSize.w * invZoom;
                //imgSize.h = imgSize.h * invZoom;
            }
            if (view.aspect < imageAspect) {
                imgSize.w = imgSize.h * imageAspect;
            } else {
                imgSize.h = imgSize.w * (1.0 / imageAspect);
            }

            view.x = Math.ceil(-imgSize.w * olSimpleImageEditor.transform.move[0]);
            view.y = Math.ceil(-imgSize.h * olSimpleImageEditor.transform.move[1]);

            /*
            view.x -= imgSize.w * (lastZoom - invZoom) * 0.5;
            view.y -= imgSize.h * (lastZoom - invZoom) * 0.5;
            lastZoom = invZoom;
            */
            
            if (olSimpleImageEditor.input.url == undefined) {
                value.image.style.display = "none";
            } else {
                value.image.src = olSimpleImageEditor.input.url;
                value.image.style.display = "block";
            }
            value.image.style.left = view.x + "px";
            // Datenausgabe=======================================
            if (olSimpleImageEditor.input.image != undefined) {
                //console.log('image-w', olSimpleImageEditor.input.image.width);
                //console.log('image-h', olSimpleImageEditor.input.image.width);
            }
            // ===================================================
            value.image.style.top = view.y + "px";
            
            value.image.style.marginLeft = Math.ceil(olSimpleImageEditor.transform.offset[0] * 150) + "px";
            value.image.style.marginTop = Math.ceil(olSimpleImageEditor.transform.offset[1] * 150) + "px";
            
            value.image.style.width = Math.ceil(imgSize.w) + "px";
            value.image.style.height = Math.ceil(imgSize.h) + "px";
            value.image.style.transform = "scale(" + invZoom + "," + invZoom + ")";
        });
        
        if (olSimpleImageEditor.position.isUndefined) {

            // Image is undefined at position
            if (olSimpleImageEditor.input.image != undefined) {


                // Bild liegt vor
                // berechnung zur zentrierung

                var tmpimagewidth = olSimpleImageEditor.input.image.width;
                var tmpimageheight = olSimpleImageEditor.input.image.height;

                // Zielgroesse 150px
                if (tmpimageheight > tmpimagewidth) {
                    // hochkant            
                    var newTmpimagewidth = (150 / tmpimageheight) * tmpimagewidth;         
                    var newTmpimageheight = 150;

                    // positionieren
                    olSimpleImageEditor.transform.offset[0] = (150 - newTmpimagewidth) / 2 / 150;
                    olSimpleImageEditor.transform.offset[1] = -((newTmpimageheight - 150) / 2) / 150;
                } else
                {
                    // quer
                    var newTmpimageheight = (150 / tmpimagewidth) * tmpimageheight;
                    var newTmpimagewidth = 150;

                    // positionieren
                    olSimpleImageEditor.transform.offset[0] = -((newTmpimagewidth - 150) / 2) / 150;
                    olSimpleImageEditor.transform.offset[1] = (150 - newTmpimageheight) / 2 / 150;
                }
                olSimpleImageEditor.position.isUndefined = false;
                olSimpleImageEditor.update();
            }
        }
    };
    
}(window.olSimpleImageEditor = window.olSimpleImageEditor || {}, jQuery));
