(function(olPremium, $, undefined) {

    var statusCheckTimeout = 20000; // time after which the status check on redirect payments is startet
    var statusCheckInterval = 5000; // interval in which the status check on redirect payment is issued
    var xframeCloseTimeout = 500; // DEPRECATED
    var xframeReturnCanceledTimeout = 5000; // time after which "back to step 3" button is shown after payReturn with state CANCELED
    var DEBUG = false;


    /*
    premiumData:
        name					type		set at		remark
        ----					----		------		------
        premiumId				Number		@PURCHASE	Premium.premiumId
        premiumPaymentId		Number		@PURCHASE	PremiumPayment.premiumPaymentId
        allowedOrigins			string[]	@PURCHASE    für xwin
        daysRemaining           Number      @PURCHASE
        daysTotal               Number      @PURCHASE
        step                    Number      *           current step
        premiumStep             Number      *           max. allowed step for current state
        orderId                 string      @PURCHASE
        invoiceId               string      @PURCHASE
        //paymentTarget         string      @PURCHASE   sandbox or production
        paymentPublicKey        string      @PURCHASE   heidelpay public key

        paymentDays             numeric     PURCHASE
        paymentPackage          string      PURCHASE
        paymentProductId        string      PURCHASE
        paymentIosProductId     string      PURCHASE
        paymentType				string		PURCHASE    Payment::TYPE_*
        paymentRecurring        bool        PURCHASE/PAYMENT

        paymentPrice            number      ->PAYMENT   might be 0
        conditionsAccepted		bool		PAYMENT

        paymentState			string		->SUMMARY/+
        paymentData				object		->SUMMARY	heidelpay type data (method data) (.id, .method, .recurring, .*)
        paymentError 						->SUMMARY	error from heidelpay form submit | null

        paymentTransactionId	string		->FINISH	payment transaction ID | ''

        redirectUrl				string		->FINISH
        redirectState			string		->FINISH	CALLING | ERROR | CANCELED | SUCCESS | UNFINISHED
        redirectResult			object		->FINISH	result data from redirect

        // @=at start of, ->=on gotoNext to, +=and the following, *=throughout all steps

    premiumData.paymentState:
        FAILED				overview > pay/start => failure
        STARTED				overview > pay/start => success

        TYPE_FAILED			heidelpay > heidelpay form submit => failure
        TYPE_CREATED		heidelpay > heidelpay form submit => success

        PAYMENT_FAILED		heidelpay > pay/charge ==> failure

        PAYMENT_ERROR		heidelpay > pay/charge ==> charge error
        PAYMENT_CANCELED	heidelpay > pay/charge ==> charge aborted
        PAYMENT_SUCCESS     heidelpay > pay/charge ==> success
        PAYMENT_REDIRECT	heidelpay > xframe
            PAYMENT_ERROR		xframe => error
            PAYMENT_CANCELED	xframe => canceled
            PAYMENT_SUCCESS     xframe => success

    */
    var PURCHASE = 1, // Auswahl       premium/purchase  premiumPurchase
        PAYMENT  = 2, // Bezahldetails premium/payment   premiumPayment
        SUMMARY  = 3, // Zahlung       premium/summary   premiumSummary
        RETURN   = 4, // Rückkehr      premium/return    premiumReturn
        FINISH   = 5; // Bestätigung   premium/finish    premiumFinish

    var premiumData = {}; // initialized by initPremiumData
    function initPremiumData(step) {
        if (step === undefined) step = PURCHASE;
        var $premiumData = $('#premiumData');

        var zeroByKind = JSON.parse($premiumData.attr('data-zeroByKind'));

        var iosMode = (olAppInfo.APP_CTXT == olAppInfo.APP_CTXT_IOS
                    || olAppInfo.APP_CTXT == olAppInfo.APP_CTXT_IOS2),
            iosFeaturePlus = (Number($premiumData.attr('data-iosFeaturePlus')) || 0) != 0,
            iosPlusWarning = iosMode && !iosFeaturePlus;

        var KINDS = olGUI.jsonParse($premiumData.attr('data-kinds'), {}),
            PREM = KINDS[0], PLUS = KINDS[1], PREMPLUS = KINDS[2];

        premiumData = {
            premiumId: Number($premiumData.attr('data-premiumId')) || 0,
            conditionsAccepted: false,
            paymentDays: Number($premiumData.attr('data-days')) || 0,
            paymentPackage: $premiumData.attr('data-package'),
            paymentKind: $premiumData.attr('data-kind'),
            paymentProductId: $premiumData.attr('data-productId'),
            paymentIosProductId: $premiumData.attr('data-iosProductId'),
            paymentType: $premiumData.attr('data-payment'),
            paymentPrice: Number($premiumData.attr('data-price')) || 0,
            paymentRecurring: (Number($premiumData.attr('data-prolong')) || 0) != 0,
            paymentState: '',
            premiumPaymentId: Number($premiumData.attr('data-premiumPaymentId')) || 0,
            allowedOrigins: olGUI.jsonParse($premiumData.attr('data-allowedOrigins')),
            paymentData: olGUI.jsonParse($premiumData.attr('data-paymentData')),
            paymentError: null,
            paymentTransactionId: $premiumData.attr('data-paymentTransactionId') || '',
            paymentTarget: $premiumData.attr('data-paymentTarget') || 'sandbox',
            paymentPublicKey: $premiumData.attr('data-paymentPublicKey'),
            orderId: $premiumData.attr('data-orderId'),
            invoiceId: $premiumData.attr('data-invoiceId'),
            redirectUrl: '',
            redirectState: '',
            redirectResult: null,
            daysRemaining: Number($premiumData.attr('data-daysRemaining')) || 0,
            premiumDaysRemaining: olGUI.jsonParse($premiumData.attr('data-premiumDaysRemaining'), zeroByKind),
            daysTotal: Number($premiumData.attr('data-daysTotal')) || 0,
            premiumDaysTotal: olGUI.jsonParse($premiumData.attr('data-premiumDaysTotal'), zeroByKind),
            openEnded: Number($premiumData.attr('data-openEnded')) || 0,
            premiumRecurPids: olGUI.jsonParse($premiumData.attr('data-premiumRecurPids'), zeroByKind),
            fillupData: olGUI.jsonParse($premiumData.attr('data-fillupData'), null),
            step: step,
            premiumStep: Number($premiumData.attr('data-premiumStep')) || 0, // max. allowed step for current state
            premiumState: Number($premiumData.attr('data-premiumState')) || -1,
            premiumDisabled: olGUI.jsonParse($premiumData.attr('data-premiumDisabled')),
            result: olGUI.jsonParse($premiumData.attr('data-result'), {}),

            kinds: KINDS,
            PREM: PREM,
            PLUS: PLUS,
            PREMPLUS: PREMPLUS,
            DURATIONS: olGUI.jsonParse($premiumData.attr('data-DURATIONS'), []),
            DAYS_FREE_PREMIUM: $premiumData.attr('data-DAYS_FREE_PREMIUM'),
            DAYS_FREE_PLUS: $premiumData.attr('data-DAYS_FREE_PLUS'),
            PACKAGE_FREE: $premiumData.attr('data-PACKAGE_FREE'),
            PACKAGE_FILLUP: $premiumData.attr('data-PACKAGE_FILLUP'),
            hadFreePremium: (Number($premiumData.attr('data-hadFreePremium')) || 0) != 0,
            hadFreePlus: (Number($premiumData.attr('data-hadFreePlus')) || 0) != 0,
            prices: olGUI.jsonParse($premiumData.attr('data-prices'), {}),
            allPrices: olGUI.jsonParse($premiumData.attr('data-allPrices'), {}),
            iosFeaturePlus: iosFeaturePlus,
            iosPlusWarning: iosPlusWarning,

            mayRecur: JSON.parse($premiumData.attr('data-mayRecur')),
            prolongationAllowed: function(type) {
                if (type === undefined) type = this.paymentType;
                return (type != '') && this.mayRecur[type]
                    && (this.paymentPackage != this.PACKAGE_FILLUP);
            },

            appCtxt: olAppInfo.APP_CTXT
        };
        if (DEBUG) console.log('initPremiumData('+step+'):', premiumData);
    }


    function disableGotoNext(disabled) {
        if (disabled) {
            $('#gotoNext').attr('disabled', true);
        } else {
            $('#gotoNext').removeAttr('disabled');
        }
    }

    function isGotoNextDisabled() {
        return !!$('#gotoNext').attr('disabled');
    }

    function loadingGotoNext(loading) {
        $('#gotoNext').toggleClass('ol-button-loading', loading);
    }

    function isLoadingGotoNext() {
        return $('#gotoNext').hasClass('ol-button-loading');
    }

    function updateGotoNextState() {
        var iosPlusWarning = (premiumData.paymentKind == premiumData.PLUS || premiumData.paymentKind == premiumData.PREMPLUS)
                          && premiumData.iosPlusWarning;
        var enabled =
            (premiumData.step == PURCHASE)? premiumData.paymentPackage && premiumData.paymentType && !iosPlusWarning
          : (premiumData.step == PAYMENT)?  premiumData.conditionsAccepted
          : (premiumData.step == SUMMARY)?  true
          : (premiumData.step == RETURN)?   false
          : (premiumData.step == FINISH)?   true
          :                                 false;
        disableGotoNext(!enabled);
        loadingGotoNext(false);
        $('#iosPlusWarning').css('display', iosPlusWarning? 'block' : 'none');
    }


    function addTokenTo(data) {
        data._token = $('meta[name="csrf-token"]').attr('content');
    }


    function initPremiumPage(step) {
        // Collect data
        initPremiumData(step);

        // Set status of gotoNext button
        updateGotoNextState();

        // Animate progress bar
        initCheckoutBar();

        // Does Premium state allow this step?
        if (premiumData.premiumStep < premiumData.step) {
            if (DEBUG) console.log('initPremiumPage('+step+'): '+premiumData.premiumStep+' < '+premiumData.step);
            if (DEBUG) console.log('########## initPremiumPag('+step+'): olPremium.orderStepTo('+premiumData.premiumStep+')');
            olPremium.orderStepTo(premiumData.premiumStep);
        }
    }

    function initCheckoutBar() {
        var step = premiumData.step;
        var i = (step <= SUMMARY)? step-1 : (step == RETURN)? 2 : (step == FINISH)? 3 : 0;
        if (i <= 0 || i >= 4) return;

        setTimeout(function(){
            $('.premium-selection-progressbar-line').addClass('line-'+i);
        }, 100);
    }

    function initRemainingDaysBar() {
        var daysPct = (premiumData.daysTotal > 0)? (premiumData.daysRemaining * 100) / premiumData.daysTotal : 0;
        if (daysPct > 100) daysPct = 100;
        $('.premiumbar-progress').css('width', daysPct+'%'); // calculate progressbar width
        $(document).on('click', '.premium-alternative-block', function(event) {
            event.preventDefault();
            //$('html, body').animate({
            //    scrollTop: $($.attr(this, 'href')).offset().top - 100
            //}, 500);
            var $o = $($.attr(this, 'href'));
            var offset = $o? $o.offset() : null;
            if (offset) $('html, body, #scrollRoot').animate({scrollTop: offset.top - 100}, 500);
        });
        if ($('.premiumRemainingDays').attr('data-count') > 0) {
            $('.premiumbar-progress').css('width', '100%');
        }
    }

    // Click on navigation bar
    olPremium.orderStepTo = function(step, force) {
        if (force === undefined) force = false;
        if (DEBUG) console.log('olPremium.orderStepTo('+step+', '+force+')');
        if (DEBUG) console.log('########## olPremium.orderStepTo('+step+', '+force+')');

        // Check for valid step
        if (step < PURCHASE || step > FINISH) return;

        // Forward only by click on (enabled) gotoNext button
        if (!force && step > premiumData.step) return;

        // Same step => already here
        if (step == premiumData.step) {
            if (force) olAnchorNavigation.reload();
            return;
        }

        // Step back => always possible?!
        loadingGotoNext(true);
        switch (step) {
        case PURCHASE:
            //var package = premiumData.paymentPackage;
            //olAnchorNavigation.load('/premium/purchase?package='+package);
            olAnchorNavigation.load('/premium/purchase');
            break;
        case PAYMENT:
            olAnchorNavigation.load('/premium/payment');
            break;
        case SUMMARY:
            olAnchorNavigation.load('/premium/summary');
            break;
        case RETURN:
            //olAnchorNavigation.load('/premium/return'); //=> gefährlich!
            olAnchorNavigation.load('/premium/summary'); //TODO
            break;
        case FINISH:
            // We can get here only thru the payment process
            //olAnchorNavigation.load('/premium/finish');
            break;
        }
    };

    olPremium.orderStepToCheck = function() {
        olPremium.orderStepTo(SUMMARY, true);
    };



    //--- (0) PREMIUM ---

    olPremium.initStartPage = function(opts) {
        var $premiumData = $('#premiumData');
        // struck-down version for start pabe
        premiumData = {
            paymentKind: $premiumData.attr('data-kind'),
            kinds: JSON.parse($premiumData.attr('data-kinds') || '{}'),
            hadFreePremium: (Number($premiumData.attr('data-hadFreePremium')) || 0) != 0,
            prices: JSON.parse($premiumData.attr('data-prices') || '{}'),
            allPrices: JSON.parse($premiumData.attr('data-allPrices') || '{}'),
            appCtxt: olAppInfo.APP_CTXT
        };
        if (DEBUG) console.log('initStartPage():', premiumData);

        olPremium.premiumInfo();
    };

    olPremium.loginInfo = function() {
        var $msg = $('#premiumStartPageMsg');
        var title = $msg.attr('data-msg-title'),
            text = $msg.attr('data-msg-text'),
            textyes = $msg.attr('data-msg-textyes'),
            textno = $msg.attr('data-msg-textno'),
            url = $msg.attr('data-url');
        olMsgBox.question(
            title,
            text,
            function() {olOverlayWindow.load(url)},
            function() {},
            textyes,
            textno);
    };


    olPremium.onSelectKind = function(event) {
        var $element = $(event.target).closest('.premium-kind-select-package'),
            kind = $element.attr('data-kind') || '',
            index = $element.attr('data-index') || 1;
        selectKind(kind, index);
    };
    
    // index: 1=premium, 2=premium/purchase
    function selectKind(kind, index) {
        if (DEBUG) console.log('selectKind('+kind+', '+index+')');
        var checkout = (index == 2);

        // Change kind selection
        $('.premiumByKindHide').hide();
        $('.premiumByKindHide-'+kind).show();
        $('.premiumByKindSelect').removeClass('selected');
        $('.premiumByKindSelect-'+kind).addClass('selected');

        if (checkout) {
            var isRecurringShown = $('#premium-prolong-section-'+kind).is(':visible');
            $('#paymentSelection').css('display', isRecurringShown? 'none' : 'block')
        }

        // Store state
        var kindChanged = (premiumData.paymentKind != kind);
        premiumData.paymentKind = kind;
        if (checkout) {
            //premiumData.prices = premiumData.allPrices[kinds] || {'30': 0, '90': 0, '180': 0, '365': 0}; // %
            premiumData.daysRemaining = premiumData.premiumDaysRemaining[premiumData.paymentKind] || 0;
            updateRemainingDaysBar();
            if (DEBUG) console.log('selectKind:', premiumData);
        }

        // Change selections
        if (checkout && kindChanged) {
            selectPackage(''); // Not needed here
            //selectPayment(''); // already done by selectPackage
            //pageGuidance(); // Not needed here & already done by selectPackage
            //updateGotoNextState(); // Not needed here & already done by selectPackage
        }

        //...?
    };



    //--- (1) PURCHASE ---

    //var purchaseInitialized = false;
    var parentWidth, originalWidth;
    olPremium.initPurchase = function() {
        // Determine display parameters
        parentWidth = $('.settings-premium-progress-bar').width() + 2;
        $('.premiumbar-foreground-font').css('left', parentWidth / 2);
        originalWidth = $('.premiumbar-progress').width();

        // Collect premium data
        initPremiumPage(PURCHASE);
        if (premiumData.premiumState == 4 || premiumData.premiumState == 5) {olPremium.orderStepToCheck(); return;}

        // Maybe set package/days and/or payment type
        var params = parseURLParams();
        var kind = params.kind || premiumData.paymentKind; // URL parameter has priority
        selectKind(kind, 2);
        var package = params.package || premiumData.paymentPackage; // URL parameter has priority
        var packageDays = Number(package) || 0;
        if (!premiumData.DURATIONS.includes(packageDays)) package = ''; // Only standard packages as preselection
        if (premiumData.premiumDisabled[package]) package = ''; // Disable by locale option
        selectPackage(package);
        selectPayment(premiumData.paymentType);
        if (DEBUG) console.log('premiumData:', premiumData);
        //purchaseInitialized = true;

        // Initalize progress bar
        //var daysPct = (premiumData.daysTotal > 0)? (premiumData.daysRemaining * 100) / premiumData.daysTotal : 0;
        //$('.premiumbar-progress').css('width', daysPct+'%'); // calculate progressbar width
        //$(document).on('click', '.premium-alternative-block', function(event) {
        //    event.preventDefault();
        //    //$('html, body').animate({
        //    //    scrollTop: $($.attr(this, 'href')).offset().top - 100
        //    //}, 500);
        //    var $o = $($.attr(this, 'href'));
        //    var offset = $o? $o.offset() : null;
        //    if (offset) $('html, body, #scrollRoot').animate({scrollTop: offset.top - 100}, 500);
        //});
        //if ($('.premiumRemainingDays').attr('data-count') > 0) {
        //    $('.premiumbar-progress').css('width', '100%');
        //}
        initRemainingDaysBar();

        // Further page init
        $(window).scrollTop(0);
    };

    $(window).resize(function(){
        var parentWidth = $('.settings-premium-progress-bar').width() + 2;
        $('.premiumbar-foreground-font').css('left', parentWidth / 2);
    });

    //?
    olPremium.premiumInfo = function() {
        if ($('body[data-page-url="premium"]')) {
            $(window).scroll(function() {
                var i = 1;
                while (i < 16) {
                    //var hT = $('#benefit' + i).offset().top,
                    var hTOffset = $('#benefit' + i).offset(),
                        hT = hTOffset? hTOffset.top : 0,
                        hH = $('#benefit1' + i).outerHeight(),
                        wH = $(window).height(),
                        wS = $(this).scrollTop();
                    if (wS > (hT+hH-wH) && (hT > wS) && (wS+wH > hT+hH)){
                       $('#benefit' + i).addClass('animated');
                    }
                    else
                    {
                        $('#benefit' + i).removeClass('animated');
                    }
                    i++;
                }
            });
        }
        window.setInterval(function() {
            $('.premiumview').addClass('animated');
            setTimeout(function() {
                $('.premiumview').removeClass('animated');
            }, 5000);
        }, 7500);

        $('.premium-alternative-block').mouseenter(function() {
           $(this).addClass('animated');
        });

        $('.premium-alternative-block').mouseleave(function() {
            var par = $(this);
            setTimeout(function() {
                par.removeClass('animated');
            }, 1500);
        });

        $('.premium-benefit-overview-detail').mouseenter(function() {
           $(this).addClass('animated');
        });

        $('.premium-benefit-overview-detail').mouseleave(function() {
            var par = $(this);
            setTimeout(function() {
                par.removeClass('animated');
            }, 0);
        });

        $(document).on('click', 'a[href^="#"]', function(event) {
            event.preventDefault();
            $('html, body, #scrollRoot').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 100
            }, 500);
        });
    };

    olPremium.premiumBannerRotation = function() {
        var dt = 10000; // 10 s
        setTimeout(function() {
            $('#grid-premium, #premium-slogan').fadeOut(400);
            setTimeout(function(){ $('#grid-plus, #plus-slogan').fadeIn(800); }, 1000);
        }, 1*dt);
        setTimeout(function() {
            $('#grid-plus, #plus-slogan').fadeOut(400);
            setTimeout(function(){ $('#grid-premiumplus, #premiumplus-slogan').fadeIn(800); }, 1000);
        }, 2*dt);
        setTimeout(function() {
            $('#grid-premiumplus, #premiumplus-slogan').fadeOut(400);
            setTimeout(function(){ $('#grid-premium, #premium-slogan').fadeIn(800); }, 1000);
        }, 3*dt);
        setTimeout(olPremium.premiumBannerRotation, 3*dt);
    };


    function pageGuidance() {
        if ($('#premiumPurchase').hasClass('payment-selected')) {
        } else {
            if ($('#premiumPurchase').hasClass('package-selection-finished')) {
                setTimeout(function() {
                    $('#premiumPurchase').addClass('package-selected');
                }, 500);
                setTimeout(function() {
                    $('#premiumPurchase').removeClass('package-selected');
                }, 1200);
                setTimeout(function() {
                    $('.paypal .premium-payment-method').css('transform', 'scale(1.15)');
                }, 1200);
                setTimeout(function() {
                    $('.paypal .premium-payment-method').css('transform', 'scale(1)');
                }, 1600);
                setTimeout(function() {
                    $('.creditcard .premium-payment-method').css('transform', 'scale(1.15)');
                }, 1600);
                setTimeout(function() {
                    $('.creditcard .premium-payment-method').css('transform', 'scale(1)');
                }, 2100);
                setTimeout(function() {
                    $('.debit .premium-payment-method').css('transform', 'scale(1.15)');
                }, 2100);
                setTimeout(function() {
                    $('.debit .premium-payment-method').css('transform', 'scale(1)');
                }, 2600);
                setTimeout(function() {
                    $('.sofort .premium-payment-method').css('transform', 'scale(1.15)');
                }, 2600);
                setTimeout(function() {
                    $('.sofort .premium-payment-method').css('transform', 'scale(1)');
                }, 3100);
            }
        }

        if ($('#premiumPackageSelect').hasClass('package-selected')) {
        } else {
            if ($('#premiumPurchase').hasClass('payment-selected')) {
                setTimeout(function() {
                    $('.premiumPackageHeadline').addClass('payment-selection-finished');
                }, 500);
                setTimeout(function() {
                    $('.premiumPackageHeadline').removeClass('payment-selection-finished');
                }, 1200);
                setTimeout(function() {
                    $('.days30').css('transform', 'scale(1.15)');
                }, 1200);
                setTimeout(function() {
                    $('.days30').css('transform', 'scale(1)');
                }, 1700);
                setTimeout(function() {
                    $('.days90').css('transform', 'scale(1.15)');
                }, 1700);
                setTimeout(function() {
                    $('.days90').css('transform', 'scale(1)');
                }, 2200);
                setTimeout(function() {
                    $('.days180').css('transform', 'scale(1.15)');
                }, 2200);
                setTimeout(function() {
                    $('.days180').css('transform', 'scale(1)');
                }, 2700);
                setTimeout(function() {
                    $('.days365').css('transform', 'scale(1.15)');
                }, 2700);
                setTimeout(function() {
                    $('.days365').css('transform', 'scale(1)');
                }, 3200);
            }
        }
    }

    function updateRemainingDaysBar(addDays) {
        if (DEBUG) console.log('updateRemainingDaysBar('+addDays+')');
        if (!addDays) addDays = 0;
        var days = premiumData.daysRemaining + addDays;

        $('.premiumbar-progress').css('width', premiumData.paymentPackage? '100%' : originalWidth);
        $('.premiumRemainingDays').attr('data-count', days);
        //$('.premiumRemainingDays').text(days);
        countToNumber();
    }

    function countToNumber() {
        $('.counter').each(function() {
            var $this = $(this),
                countTo = $this.attr('data-count');
            //console.log('countToNumber:', $this, countTo);

            $({countNum: $this.text()}).animate({
                countNum: countTo
            }, {
                duration: 2000,
                easing: 'linear',
                step: function(){
                    $this.text(Math.floor(this.countNum));
                },
                complete: function() {
                    $this.text(this.countNum);
                }
            });
        });
    }


    olPremium.onSelectPackage = function(event) {
        var $element = $(event.target).closest('.premium-days'),
            package = $element.attr('data-package'),
            days = Number($element.attr('data-days'));
        selectPackage(package, days);
    };

    function selectPackage(package, days) {
        if (DEBUG) console.log('selectPackage('+package+', '+days+') // '+premiumData.paymentKind+'-'+premiumData.paymentPackage+'-'+premiumData.paymentDays+', '+premiumData.paymentType);
        if (days === undefined) {
            days = !package? 0 
                 : (package == premiumData.PACKAGE_FILLUP)?                                              0 //TODO Shouldn't happen here
                 : (package == premiumData.PACKAGE_FREE && premiumData.paymentKind == premiumData.PREM)? premiumData.DAYS_FREE_PREMIUM
                 : (package == premiumData.PACKAGE_FREE && premiumData.paymentKind == premiumData.PLUS)? premiumData.DAYS_FREE_PLUS
                 :                                                                                       Number(package);
        }
        
        // Store state
        var packageSelected = !!package;

        $('#premiumData').attr('data-package', package);
        premiumData.paymentPackage = package;

        $('#premiumData').attr('data-days', days);
        premiumData.paymentDays = days;
        if (DEBUG) console.log('=> premiumData:', premiumData);

        // Adjust payment types and prolongation as allowed
        if (package == '') {
	        $('.premium-payment-enabled.premium-payment-free').addClass('disabled');
	        $('.premium-payment-enabled.premium-payment-nonfree').addClass('disabled');
	        selectPayment('');
            disableProlongation(true);
        } else if (package == premiumData.PACKAGE_FREE) {
	        $('.premium-payment-enabled.premium-payment-free').removeClass('disabled');
	        $('.premium-payment-enabled.premium-payment-nonfree').addClass('disabled');
			selectPayment('FREE');
            disableProlongation(true);
        } else /* package == nonfree */ {
	        $('.premium-payment-enabled.premium-payment-free').addClass('disabled');
	        $('.premium-payment-enabled.premium-payment-nonfree').removeClass('disabled');
	        if (premiumData.paymentType == 'FREE') selectPayment('');
            $('.premium-payment-enabled.premium-payment-nonfree.premium-payment-nonrecurring').toggleClass('disabled', premiumData.paymentRecurring);
            disableProlongation();
        }

        // Set selection status of package
        $('.premium-days').each(function(i, o) {
            var $o = $(o), pkg = $o.attr('data-package');
            $o.toggleClass('selected', pkg == package);
        });

        $('#premiumPackageSelect').toggleClass('package-selected', packageSelected);
        $('#premiumPurchase').toggleClass('package-selection-finished', packageSelected);

        //$('.premiumbar-progress').css('width', packageSelected? '100%' : originalWidth);
        //$('.premiumRemainingDays').attr('data-count', premiumData.daysRemaining + days);
        //countToNumber();
        updateRemainingDaysBar(days);
        
        //if (purchaseInitialized) {
        //    $("html, body, #scrollRoot").animate({scrollTop:400}, 500, 'swing');
        //}
        pageGuidance();

        updateGotoNextState();
    };


    olPremium.onSelectPayment = function(event) {
        var $element = $(event.target).closest('.premium-payment'),
            payment = $element.attr('data-payment');
        if ($element.hasClass('disabled')) return;
        selectPayment(payment);
    };

    function selectPayment(payment) {
        if (DEBUG) console.log('selectPayment('+payment+') // '+premiumData.paymentKind+'-'+premiumData.paymentPackage+', '+premiumData.paymentType);
        var paymentSelected = !!payment;
        $('#premiumData').attr('data-payment', payment);
        premiumData.paymentType = payment;
        if (DEBUG) console.log('=> premiumData:', premiumData);

        disableProlongation();

        $('.premium-payment').each(function(i, o) {
            var $o = $(o), p = $o.attr('data-payment');
            $o.toggleClass('selected', p == payment);
        });

        $('#premiumPurchase').toggleClass('payment-selected', paymentSelected);
        $('#premiumPackageSelect').toggleClass('payment-selected', paymentSelected);

        pageGuidance();

        updateGotoNextState();
    };


    olPremium.onClickProlongation = function(event) {
        var $element = $(event.target),
            checked = $element.prop('checked'),
            update = Number($element.attr('data-update')) || 0,
            kind = $element.attr('data-kind') || premiumData.paymentKind, 
            pid = Number($element.attr('data-pid')) || '', //premiumData.premiumRecurPids[kind],
            ctxt = $element.attr('data-ctxt') || '',
            $premiumData = $('#premiumData');

        $('.subscribtion-info').toggleClass('regular bold', checked);

        $premiumData.attr('data-prolong', checked? 1 : 0);
        premiumData.paymentRecurring = checked;

        $('.premium-payment-enabled.premium-payment-nonrecurring').toggleClass('disabled', checked);
        
        $('#subscribtion-info').toggleClass('regular bold', !checked);
        $('.premium-subscribtion-checker').toggleClass('visible', !checked);

        if (checked && !premiumData.prolongationAllowed()) selectPayment('');

        if (update) {
            var data = {
                premiumId: pid,
                //kind: kind,
                prolong: checked? 1 : 0,
                ctxt: ctxt
            };
            $.post('/premium/setprolong', data, function(rc) {
                if (rc.err) {
                    olDialog.messageExt('#premiumSetProlongError');
                    return;
                }
                $('#gotoSame-'+kind).attr('disabled', checked? '' : null);
                $('#premiumProlongHint').toggleClass('hidden', !checked);

            }, 'json').fail(function() {
                //removeLoadingAnimationFromElement($anchor);
                console.log('*** premium/setprolong failed:', data);
            });
        }
    };

    olPremium.onClickProlongationText = function(event) {
        var $element = $(event.target).closest('.premium-info-small'),
            id = $element.attr('data-id');
        $('#'+id).click();
    };

    function disableProlongation(disabled) {
        if (disabled === undefined) disabled = !premiumData.prolongationAllowed();

        if (premiumData.openEnded) disabled = false;

        var $input = $('#premiumProlongation');
        $input.prop('disabled', disabled);
        if (disabled) $input.prop('checked', false);
        $input.closest('.premium-subscribtion-wrapper').toggleClass('disabled', disabled);

        var prolong = $input.prop('checked');
        $('#premiumData').attr('data-paymentRecurring', prolong? 1 : 0);
        premiumData.paymentRecurring = prolong;
    }


    olPremium.onAcceptConditions = function(event) {
        var $element = $(event.target),
            checked = $element.prop('checked');
        premiumData.conditionsAccepted = checked;
        updateGotoNextState();
    };

    olPremium.onAcceptConditionsText = function(event) {
        var $element = $(event.target),
            $input = $('#premiumAgb');
        $input.click();
    };

    olPremium.orderGotoPurchase = function(event) {
        if (DEBUG) console.log('########## olPremium.orderGotoPurchase: olAnchorNavigation.reload()');
        olAnchorNavigation.reload();
    };


    // prepare payment
    olPremium.orderGotoPayment = function(event) {
        if (DEBUG) console.log('olPremium.orderGotoPayment:', premiumData);
        if (isGotoNextDisabled()) return;

        var data = {
            premiumId: premiumData.premiumId,
            premiumPaymentId: premiumData.premiumPaymentId,
            kind: premiumData.paymentKind,
            package: premiumData.paymentPackage,
            days: premiumData.paymentDays,
            payment: premiumData.paymentType,
            prolong: premiumData.paymentRecurring? 1 : 0
        };
        var $anchor = $('.premium-view-wrapper');
        addLoadingAnimationToElement($anchor);
        loadingGotoNext(true);
        $.post('/premium/prepare', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
            loadingGotoNext(false);
            if (DEBUG) console.log('/premium/prepare: rc:', rc);

            if (rc.err) {
                olDialog.message('#premiumStepPaymentError', {'data-err': olTrans.textToHtml(rc.err)});
                return;
            }

            // Show next step
            if (premiumData.paymentType == 'FREE') {
                doInitPayment();
                premiumData.conditionsAccepted = true;
                doGotoSummary();
            } else {
                if (DEBUG) console.log("########## olPremium.orderGotoPayment after prepare: olAnchorNavigation.load('/premium/payment')");
                olAnchorNavigation.load('/premium/payment');
            }

        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            loadingGotoNext(false);
            console.log('*** premium/prepare failed:', data);
        });
    };



    //--- (2) PAYMENT ---

    olPremium.orderBackToPurchase = function(event) {
        if (DEBUG) console.log('olPremium.orderBacktoPurchase');
        olPremium.orderStepTo(PURCHASE);
    };

    olPremium.orderInitPayment = function() {
        initPremiumPage(PAYMENT);
        if (premiumData.premiumState == 4 || premiumData.premiumState == 5) {olPremium.orderStepToCheck(); return;}

        // Initialize payment page
        doInitPayment();

        var checked = $('.premiumProlongationToggle').prop('checked');
        $('#subscribtion-info').toggleClass('regular bold', checked);
        $('.premium-subscribtion-checker').toggleClass('visible', checked);
    };

    function doInitPayment() {
        var ok = hp.prepare({
            paymentType: premiumData.paymentType,
            dataId: 'premiumStepHeidelpayData',
            recurring: premiumData.paymentRecurring,
            //target: premiumData.paymentTarget,
            publicKey: premiumData.paymentPublicKey
        });
        if (!ok) {
            olDialog.messageOrText('#premiumHeidelpayPrepareError', null, function() {
                disableGotoNext(true);
                olPremium.orderStepTo(1);    
            });
        }
    };

    olPremium.orderGotoSummary = function(event) {
        if (DEBUG) console.log('olPremium.orderGotoSummary:', premiumData);
        if (isGotoNextDisabled()) return;
        if (!premiumData.conditionsAccepted) return;
        doGotoSummary();
    };

    function doGotoSummary() {
        //$('.ol-sponsor-mainsponsor-assistant-heidelpay-status').hide(); //hide messages
        var $anchor = $('.premium-view-wrapper');
        addLoadingAnimationToElement($anchor);
        loadingGotoNext(true);
        hp.submit(function(result) {
            if (DEBUG) console.log('Heidelpay form data submitted successfully:', result);
            premiumData.paymentData = result;
            premiumData.paymentError = null;
            premiumData.paymentState = 'TYPE_CREATED';

            var startData = {
                premiumId: premiumData.premiumId,
                premiumPaymentId: premiumData.premiumPaymentId,
                paymentData: premiumData.paymentData,
                prolong: premiumData.paymentRecurring? 1 : 0
            };
            $.post('/premium/pay/start', startData, function(rc) {
                if (DEBUG) console.log('start result:', rc);
                removeLoadingAnimationFromElement($anchor);
                loadingGotoNext(false);
                if (rc.err) {
                    olDialog.messageOrText('#premiumStepSummaryError', {"data-err": olTrans.textToHtml(rc.err)});
                    return;
                }

                premiumData.paymentState = 'STARTED';
                //premiumData.allowedOrigins = rc.allowedOrigins;

                // Show next step
                if (DEBUG) console.log("########## doGotoSummary after pay/start: olAnchorNavigation.load('/premium/summary')");
                olAnchorNavigation.load('/premium/summary');

            }, 'json').fail(function() {
                removeLoadingAnimationFromElement($anchor);
                loadingGotoNext(false);
                console.log('*** pay/start failed:', startData);
                olDialog.messageOrText('#premiumHeidelpayStartError');
                disableGotoNext(false);
            });

        }, function(error) {
            removeLoadingAnimationFromElement($anchor);
            loadingGotoNext(false);
            console.log('*** Heidelpay form not filled out correctly / submit error:', error);
            premiumData.paymentData = null;
            premiumData.paymentError = error;
            premiumData.paymentState = 'TYPE_FAILED';

            var formErr = olTrans.textToHtml(error.message);
            olDialog.messageOrText('#premiumHeidelpaySubmitError', {"data-err": formErr});

            disableGotoNext(false);
        });

    }



    //--- (3) SUMMARY ---

    olPremium.orderBackToPayment = function(event) {
        if (DEBUG) console.log('olPremium.orderBackToPayment');
        olPremium.orderStepTo((premiumData.paymentType == 'FREE')? PURCHASE : PAYMENT);
    };

    olPremium.orderInitSummary = function() {
        initPremiumPage(SUMMARY);
    };

    olPremium.orderGotoFinish = function(event) {
        if (DEBUG) console.log('olPremium.orderGotoFinish:', premiumData);
        if (isGotoNextDisabled()) return;
        doGotoFinish();
    };

    function doGotoFinish() {
        if (DEBUG) console.log('doGotoFinish:', premiumData);
        var chargeData = {
            premiumId: premiumData.premiumId,
            premiumPaymentId: premiumData.premiumPaymentId,
            _token: $('meta[name="csrf-token"]').attr('content')
            //paymentType: premiumData.paymentType,
            //paymentData: premiumData.paymentData
        };
        var $anchor = $('.premium-view-wrapper');
        addLoadingAnimationToElement($anchor);
        disableGotoNext(true); loadingGotoNext(true);
        $.post('/premium/pay/charge', chargeData, function(rc) {
            if (DEBUG) console.log('charge result:', rc);
            removeLoadingAnimationFromElement($anchor);
            loadingGotoNext(false);
            premiumData.redirectUrl = rc.url;
            premiumData.redirectState = '';
            premiumData.orderId = rc.orderId;
            premiumData.invoiceId = rc.invoiceId;
            premiumData.paymentTransactionId = rc.transactionId;
            //premiumData.paymentState set by orderPaymentFinished()
            if (DEBUG) console.log('After charge:', premiumData);

            if (rc.err) {
                olDialog.messageOrText('#premiumHeidelpayChargeError', {
                    "data-err": olTrans.textToHtml(rc.err).replace(/\s\*\*\*/g, '<br />***')
                });
                var rcState = (rc.errKind == '3')? 'CANCELED' : 'ERROR';
                orderPaymentFinished(rcState, rc.err, rc.errKind); //sets premiumData.paymentState to PAYMENT_ERROR
                disableGotoNext(false);
                return;
            } else if (rc.url) {
                premiumData.paymentState = 'PAYMENT_REDIRECT';
                premiumData.redirectState = 'CALLING';
                switch (olAppInfo.APP_CTXT) {
                    case olAppInfo.APP_CTXT_IOS2: 
                        // TODO
                    case olAppInfo.APP_CTXT_IOS:
                        xframeWin.openIosPayment(rc.url, rc.mode, orderPaymentFinished);
                        break;

                    case olAppInfo.APP_CTXT_ANDROID:
                        xframeWin.openExternal(rc.url, rc.mode, orderPaymentFinished);
                        break;
                        
                    case olAppInfo.APP_CTXT_NORMAL:
                    case olAppInfo.APP_CTXT_ANDROID2:
                    default:
                        xframeWin.redirect(rc.url, rc.mode);
                }
                // orderPaymentFinished gets called on redirection return
            } else if (rc.completed) {
                premiumData.paymentState = 'PAYMENT_COMPLETED';
                orderPaymentFinished('COMPLETED'); //sets premiumData.paymentState to PAYMENT_SUCCESS
            } else {
                premiumData.paymentState = 'PAYMENT_SUCCESS';
                orderPaymentFinished('SUCCESS'); //sets premiumData.paymentState to PAYMENT_SUCCESS
            }
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            loadingGotoNext(false);
            console.log('*** pay/charge failed:', chargeData);
            olDialog.messageOrText('#premiumHeidelpayChargeException');
            disableGotoNext(false);
            orderPaymentFinished('ERROR'); //sets premiumData.paymentState to PAYMENT_ERROR
        });
    }


    function orderPaymentFinished(state, errText, errKind) {
        if (errKind === undefined) errKind = '';
        if (DEBUG) console.log('orderPaymentFinished('+state+((errText === undefined)? '' : ", '"+errText+"'")+", '"+errKind+"')");
        // Status eintragen
        premiumData.redirectState = state; // SUCCESS | ERROR | CANCELED | UNFINISHED | COMPLETED
        premiumData.paymentState = 'PAYMENT_'+state;

        // Aktion ausführen
        var $anchor = $('.premium-view-wrapper');
        if (state === 'COMPLETED') {
            if (DEBUG) console.log("########## orderPaymentFinished COMPLETED: olAnchorNavigation.load('/premium/finish')");
            //olAnchorNavigation.load('/premium/finish');
            location.href = '/#url=premium/finish';
        } else if (state === 'SUCCESS') {
            var finishData = {
                premiumId: premiumData.premiumId,
                premiumPaymentId: premiumData.premiumPaymentId,
                transactionId: premiumData.paymentTransactionId,
                _token: $('meta[name="csrf-token"]').attr('content')
            };
            addLoadingAnimationToElement($anchor);
            $.post('/premium/pay/finish', finishData, function(rc) {
                if (DEBUG) console.log('finish result:', rc);
                removeLoadingAnimationFromElement($anchor);
                olDialog.messageOrText('#premiumHeidelpayStatusSuccess', null, function() {
                    if (rc.err) {
                        olDialog.messageOrText('#premiumHeidelpayFinishError', {"data-err": rc.err});
                        orderPaymentFinished('UNFINISHED');
                    } else {
                        if (DEBUG) console.log("########## orderPaymentFinished after pay/finish: olAnchorNavigation.load('/premium/finish')");
                        olMobileApp.finishInAppOrder(premiumData.paymentIosProductId);
                        //olAnchorNavigation.load('/premium/finish');
                        location.href = '/#url=premium/finish';
                    }
                });

            }, 'json').fail(function() {
                console.log('*** pay/finish failed:', finishData);
                removeLoadingAnimationFromElement($anchor);
                olDialog.messageOrText('#premiumHeidelpayFinishException');
                orderPaymentFinished('UNFINISHED');
            });

        } else if (state === 'CHARGE2') {
            // Return from recurring redirect => do charge now
            var charge2Data = {
                premiumId: premiumData.premiumId,
                premiumPaymentId: premiumData.premiumPaymentId,
                paymentData: premiumData.paymentData,
                _token: $('meta[name="csrf-token"]').attr('content')
            };
            addLoadingAnimationToElement($anchor);
            $.post('/premium/pay/charge2', charge2Data, function(rc) {
                if (DEBUG) console.log('charge2 result:', rc);
                removeLoadingAnimationFromElement($anchor);
                if (rc.recurringCanceled) {
                    orderPaymentFinished('CANCELED');
                } else if (rc.err) {
                    olDialog.messageOrText('#premiumHeidelpayCharge2Error', {"data-err": rc.err});
                    orderPaymentFinished('UNFINISHED');
                } else {
                   premiumData.paymentTransactionId = rc.transactionId;
                   orderPaymentFinished('SUCCESS');
                }

            }, 'json').fail(function() {
                console.log('*** pay/charge2 failed:', finishData);
                removeLoadingAnimationFromElement($anchor);
                olDialog.messageOrText('#premiumHeidelpayCharge2Exception');
                orderPaymentFinished('UNFINISHED');
            });

        } else if (state === 'CANCELED') {
            if (premiumData.step == RETURN) {
                olDialog.messageOrText('#premiumHeidelpayStatusCanceled', null, returnBackToSummary);
            } else if (errKind == '3') {
                olDialog.messageOrText('#premiumHeidelpayStatusError3', {"data-err": errText});
                olPremium.orderStepToCheck();
            } else {
                olDialog.messageOrText('#premiumHeidelpayStatusCanceled');
                disableGotoNext(false);
            }
        } else if (state == 'UNFINISHED') {
            // error message already shown
            disableGotoNext(false);

        } else if (state === 'ERROR0') {
            // Error message already shown
            disableGotoNext(false);
            loadingGotoNext(false);

        } else {//(state === 'ERROR')
            olDialog.messageOrText('#premiumHeidelpayStatusError'+errKind, {"data-err": errText}, function() {
                if (premiumData.step == RETURN) {
                    returnBackToSummary();
                } else {
                    disableGotoNext(false);
                }
            });
        }
    }


    // Heidelpay form submit
    var hp = {

        _options: {},
        _dfltOptions: {
            paymentType: '',
            dataId: '', //'mainsponsorAssistantHeidelpayData'
            onsuccess: null,
            onerror: null,
            locale: 'de-DE' //TODO
        },
        _hp: null,
        _hpType: null,

        _init: function(options) {
            this._options = Object.assign({}, this._dfltOptions, options);

            if (!this._paramsByType[this._options.paymentType]) return false;
            if (!this._options.dataId) return false;
            if (!this._options.publicKey) return false;

            if (typeof this._options.onsuccess !== 'function') this._options.onsuccess = function() {};
            if (typeof this._options.onerror !== 'function') this._options.onerror = function() {};
            if (!this._options.locale) this._options.locale = 'de-DE';

            if (!this._options.recurring) this._options.recurring = false;

            this._hp = null;
            this._hpType = null;

            return true;
        },

        prepare: function(options) {
            try {
                if (!this._init(options)) return false;

                // Get parameters by Type
                var params = this._paramsByType[this._options.paymentType];
                if (!params) return false;

                if (this._options.paymentType == 'FREE') {
                    this._hp = null;
                    this._hpType = 'FREE';
                    return true;
                } else if (this._options.paymentType == 'IOS') {
                    this._hp = null;
                    this._hpType = 'IOS';
                    return true;
                }

                // Create heidelpay instance
                this._hp = new heidelpay(this._options.publicKey, {locale: this._options.locale});
                if (!this._hp || !this._hp[params.cls] || typeof(this._hp[params.cls]) !== 'function') return false; // for security reasons

                //if(!this._hp.isSandbox){alert('ACHUNG: Keine Sandbox!!!!!!!!');return false;}//DBG

                // Create the payment type instance
                this._hpType = this._hp[params.cls]();
                if (!this._hpType) return false;

                // Render the input fields
                var fields = params.fields;
                for (var i = 0;  i < fields.length;  i++) {
                    var field = fields[i];
                    this._hpType.create(field, {
                        containerId: this._options.dataId+'-'+field,
                        onlyIframe: false, //with styling
                        fontSize: '10pt',//'13pt',
                        fontColor: 'black',
                        fontFamily: 'Exo 2, sans-serif'
                    });
                }

                // Done
                return true
            } catch (ex) {
                console.log('*** Error preparing heidelpay:', ex);
                return false;
            }
        },

        submit: function(onsuccess, onerror) {
            try {
                if (this._hpType == 'FREE') {
                    var result = {id: 'x-fre-e', method: 'free', recurring: this._options.recurring};
                    if (DEBUG) console.log('FREE Success', result);
                    if (typeof onsuccess === 'function') onsuccess(result);
                    return;
                } else if (this._hpType == 'IOS') {
                    var result = {id: 'x-ios-e', method: 'ios', recurring: this._options.recurring};
                    if (DEBUG) console.log('IOS Success', result);
                    if (typeof onsuccess === 'function') onsuccess(result);
                    return;
                }

                this._log({before: 'hpSubmit'});
                this._hpType.createResource()
                .then(function(result) {
                    this._log({after: 'hpSubmit', ok: true, result: result});
                    if (DEBUG) console.log('HP Success:', result);
                    result.recurring = this._options.recurring;
                    if (DEBUG) console.log('result*:', result);
                    if (typeof onsuccess === 'function') onsuccess(result);
                }.bind(this)).catch(function(error) {
                    this._log({after: 'hpSubmit', ok: false, result: error});
                    console.log('HP Error:', error);
                    if (typeof onerror === 'function') onerror(error);
                }.bind(this));
                return true;
            } catch (ex) {
                this._log({after: 'hpSubmit', ok: false, result: ex});
                console.log('*** Error preparing heidelpay:', ex);
                return false;
            }
        },

        _paramsByType: {
            PAYPAL: {
                fields: [],
                cls: 'Paypal'
            },
            CREDITCARD: {
                fields: ['number', 'expiry', 'cvc'],
                cls: 'Card'
            },
//            INVOICE: {
//                fields: [],
//                cls: 'InvoiceGuaranteed'
//            },
            DEBIT: {
                // normal
                fields: ['sepa-direct-debit'],
                cls: 'SepaDirectDebit' //TODO
//                // guaranteed
//                fields: ['sepa-direct-debit-guaranteed'],
//                cls: 'SepaDirectDebitGuaranteed' //TODO
            },
            SOFORT: {
                fields: [],
                cls: 'Sofort'
            },
            FREE: {
                fields: [],
                cls: ''
            },
            IOS: {
                fields: [],
                cls: ''
            }
        },

        _log: function(info) {
            if (!info) info = {};
            info.paymentType = this._options.paymentType;
            info.sandbox = this._hp? this._hp.isSandbox : null;
            info.hpType = this._hpType? this._hpType.name : null;
            var data = {kind: 'prem', op: 'payment', data: info};
            $.post('/payment/debug/log', data, function() {
                if (DEBUG) console.log('log:', info);
            }).fail(function(error) {
                if (DEBUG) console.log('*** log error:', error);
            });
        }
    };


    // External window/tab for redirect
    var xframeWin = {

        _window: null,
        _mode: '',
        _onclose: null,
        _listening: false,
        _timer: null,

        // close with state `rc`
        close: function(rc, errText, errKind) {
            if (DEBUG) console.log('xframeWin.close(' + rc + ')');

            if (olAppInfo.APP_MODE && !olAppInfo.NATIVE_MODE) {
                postMessage('closeBrowser', '*');
            }

            if (this._window) {
                this._window.close();
                //this._window.close();
                /**/var win = this._window; setTimeout(function() {if (win) win.close();}, xframeCloseTimeout); // Zur Sicherheit
                this._window = null;
            }
            this._stopTimer();
            var cb = this._onclose;
            this._onclose = null;
            if (!cb) return;
            cb(rc, errText, errKind);
        },

        open: function(url, mode, onclose, autoCheckStatus) {
            if (DEBUG) console.log('xframeWin.open('+url+', '+mode+', onclose, '+autoCheckStatus+')', premiumData);
            if (mode === undefined) mode = '';
            if (autoCheckStatus === undefined) autoCheckStatus = false;
            this._mode = mode;
            this._onclose = (typeof onclose === 'function')? onclose : null;
            if (!this._listening) {
                window.addEventListener('message', this._onmessage.bind(this));
                this._listening = true;
            }
//            var opts = "width="+window.innerWidth+",height="+window.innerHeight+",scrollbars=no";
////if(false){//DBG
            this._window = window.open(url, 'xframe');
//            if (!this._window) this._window = window.open(url, 'xframe', opts);
////}this._window = null; //DBG
            if (!this._window) return false;
            if (autoCheckStatus) this._startTimer(statusCheckTimeout);
            if (DEBUG) console.log('xframeWin:', this);
            return true;
        },

        openExternal: function(url, mode, onclose, autoCheckStatus) {
            if (DEBUG) console.log('xframeWin.open('+url+', '+mode+', onclose, '+autoCheckStatus+')', premiumData);
            if (mode === undefined) mode = '';
            if (autoCheckStatus === undefined) autoCheckStatus = false;
            this._mode = mode;
            this._onclose = (typeof onclose === 'function')? onclose : null;
            if (!this._listening) {
                window.addEventListener('message', this._onmessage.bind(this));
                this._listening = true;
            }

            olMobileApp.openAuthedBrowser(url);

            if (autoCheckStatus) this._startTimer(statusCheckTimeout);
            if (DEBUG) console.log('xframeWin:', this);
            return true;
        },

        openIosPayment: function(url, mode, onclose) {
            if (DEBUG) console.log('xframeWin.openIosPayment('+url+', '+mode+', onclose)', premiumData);
            if (mode === undefined) mode = '';
            this._mode = mode;
            this._onclose = (typeof onclose === 'function')? onclose : null;
            if (!this._listening) {
                window.addEventListener('message', this._onmessage.bind(this));
                this._listening = true;
            }

            var productId = premiumData.paymentIosProductId; //TODO + recur
            var orderId = premiumData.orderId;
            olMobileApp.startIosPayment(productId, orderId);

            if (DEBUG) console.log('xframeWin:', this);
            return true;
        },

        _startTimer: function(ms) {
            if (DEBUG) console.log('xframeWin._startTimer('+ms+')', this._timer);
            if (this._timer) this._stopTimer();
            this._timer = setTimeout(this._oncheck.bind(this), ms);
            if (DEBUG) console.log('xframeWin.: started timer', this._timer);
        },

        _stopTimer: function() {
            if (DEBUG) console.log('xframeWin._stopTimer', this._timer);
            if (!this._timer) return;
            clearTimeout(this._timer);
            this._timer = null;
        },

        _oncheck: function() {
            if (DEBUG) console.log('xframeWin._oncheck', premiumData);
            this._checkStatus(false);
        },

        // receives message from external window
        _onmessage: function(event) {
            if (DEBUG) {
                console.log('xframeWin._onmessage:', event);
                console.log('- origin:', event.origin);
                try {
                    console.log('- source:', event.source);
                } catch (ex) {
                    console.log('- source: (blocked)');
                }
                console.log('- data:', event.data);
            }

            // Are we in the correct phase?
            if (!premiumData || premiumData.step != SUMMARY) {
                console.log('*** xframeWin._onmessage: message at invalid step '+(premiumData? premiumData.step : 0));
                return;
            }

            var orderId = premiumData.orderId,
                orderId2 = premiumData.redirectResult? premiumData.redirectResult.id : '%';
            if (DEBUG) console.log('xframeWin._onmessage: orderId='+orderId+', orderId2='+orderId2);

            // Is the message from the right origin?
            var allowedOrigins = premiumData.allowedOrigins;
            if (allowedOrigins.indexOf(event.origin) < 0) {
                console.log('*** xframeWin._onmessage: message from forbidden domain '+event.origin);
                return;
            }

            // Is the message in the right format?
            if (typeof event.data !== 'object' || (event.data.id != orderId && event.data.id != orderId2)) {
                console.log('*** orderMessageReceive: invalid message data:', event.data);
                return;
            }

            // Handle result
            this._onresult(event.data);
        },

        // processes payment result obtained thru whatever channel
        // result: {orderId: orderId, c: c, status: status}
        _onresult: function(result) {
            if (DEBUG) console.log('xframeWin._onresult:', result);

            // Handle result
//            var success = (result.status.toLowerCase() === 'successful') || (result.status.toLowerCase() === 'successfull');
            var status = result.status.toUpperCase()
                                      .replace(/^(?:SUCCESS|SUCCESSFUL|SUCCESSFULL)$/i, 'SUCCESS')
                                      .replace(/^CANCELLED$/i, 'CANCELED')
                                      .replace(/^FAILED$/i, 'ERROR');
            if (DEBUG) console.log('xframeWin._onresult: status:', status);
            premiumData.redirectResult = result;//{orderId: orderId, c: c, status: status};
            if (status == 'SUCCESS') {
                this.close(this._mode || 'SUCCESS'); // CHARGE2 | SUCCESS
            } else if (status == 'CANCELED' || status == 'ERROR' || status == 'COMPLETED') {
                this.close(status);
            } else if (this._mode) {
                this.close(this._mode); // CHARGE2
                //TODO How do I get canceled state? => works now
            } else {
                if (DEBUG) console.log('******** xframeWin._onresult: status=\''+status+'\' *************************');
                // Status not clear => check status explicitly
                this._checkStatus(true);
            }
        },

        _checkStatus: function(closeOnError) {
            if (DEBUG) console.log('xframeWin._checkStatus('+closeOnError+') //'+(premiumData? premiumData.paymentTransactionId : '?'));
            if (!premiumData.paymentTransactionId) return;

            // Canceled?
            if (premiumData.paymentState == 'CANCELED') {
                this._stopTimer();
                return;
            }

            // Check status explicitly
            var data = {
                transactionId: premiumData.paymentTransactionId,
                alsoPending: closeOnError? 0 : 1,
                _token: $('meta[name="csrf-token"]').attr('content')
            };
            if (DEBUG) console.log('=> /premium/pay/status called', data);
            $.post('/premium/pay/status', data, function(rc) {
                if (DEBUG) console.log('xframeWin._checkStatus: hp status:', rc);
                if (rc.stateName == 'SUCCESS') {
                    this.close('SUCCESS');
                } else if (rc.stateName == 'CANCELED') {
                    this.close('CANCELED');
                } else if (rc.stateName == 'PENDING') {
                    if (premiumData.step == RETURN) {
                        // Offer to set STATE_INACTIVE and return to SUMMARY
                        //TODO PayPal keeps status PENDING until timeout
                    } else {
                        // wait and check again
                        this._startTimer(statusCheckInterval);
                    }
                } else {
                    if (closeOnError) this.close('ERROR');
                }
            }.bind(this)).fail(function(error) {
                console.log('*** premium/pay/status failed', error);
            });
        },


        _reset: function() {
            this._window = null;
            this._mode = '';
            this._onclose = null;
            this._listening = false;
            this._timer = null;
        },

        redirect: function(url, mode) {
            if (DEBUG) console.log('xframeWin.redirect('+url+', '+mode+')', premiumData);
            if (mode === undefined) mode = '';
            this._reset();
            this._mode = mode;

            if (olAppInfo.APP_MODE && !olAppInfo.NATIVE_MODE) {
                olMobileApp.pausePinging();
            }
            location.href = url;
        },

        resume: function(result, mode, onclose) {
            if (mode === undefined) mode = '';
            this._reset();
            this._mode = mode;
            this._onclose = (typeof onclose === 'function')? onclose : null;

            if (olAppInfo.APP_MODE && !olAppInfo.NATIVE_MODE) {
                olMobileApp.resumePinging();
            }
            this._onresult(result);
        },

        // result = {orderId, productId, transactionId, transactionToken, status}
        resumeFromIosPayment: function(result) {
            if (DEBUG) console.log(result);

            // Determine status
            if (!result) result = {status: 'ERROR'};
            //result.status='ERROR'; //DBG
            var status = result.status.toUpperCase()
                                      .replace(/^(?:SUCCESS|SUCCESSFUL|SUCCESSFULL)$/i, 'SUCCESS')
                                      .replace(/^CANCELLED$/i, 'CANCELED')
                                      .replace(/^FAILED$/i, 'ERROR');
            if (!status.match(/^(?:SUCCESS|CANCELED|ERROR|COMPLETED)$/)) status = 'ERROR';
            if (DEBUG) console.log('xframeWin.resumeFromIosPayment: status:', status);
            premiumData.redirectResult = result;

            // Handle result
            premiumData.paymentState = 'PAYMENT_IOSRETURN';
            if (status == 'SUCCESS') {
                premiumData.paymentTransactionId = result.transactionId;

                var returnData = {
                    orderId: result.orderId,
                    productId: result.productId,
                    transactionId: result.transactionId,
                    transactionToken: result.transactionToken,
                    premiumId: premiumData.premiumId,
                    premiumPaymentId: premiumData.premiumPaymentId,
                };

                var $anchor = $('.premium-view-wrapper');
                addLoadingAnimationToElement($anchor);
                disableGotoNext(true); loadingGotoNext(true);
                $.post('/premium/pay/iosreturn', returnData, function(rc) {
                    if (DEBUG) console.log('iosreturn result:', rc);
                    removeLoadingAnimationFromElement($anchor);

                    if (rc.err) {
                        olDialog.messageOrText('#premiumIosReturnError', {'data-err': olTrans.textToHtml(rc.err)}); //TODO
                        xframeWin.close('ERROR0');
                        return;
                    }

                    xframeWin.close('SUCCESS');

                }, 'json').fail(function() {
                    removeLoadingAnimationFromElement($anchor);
                    loadingGotoNext(false);
                    console.log('*** pay/iosreturn failed:', returnData);

                    olDialog.messageOrText('#premiumIosReturnException');
                    xframeWin.close('ERROR0');
                });

            } else if (status == 'ERROR') {
                // Might be error or canceled
                premiumData.paymentTransactionId = '';
                var showIosProdErr = false,
                    iosProdErr = $('#premiumErrIosProductError').attr('data-text'),
                    iosProdRe = new RegExp($('#premiumErrIosProductError').attr('data-regexp'));
                var addErrText = (showIosProdErr && result.productId && result.productId.match(iosProdRe))? iosProdErr : '';
                olDialog.messageOrText('#premiumIosReturnError', {"data-err": addErrText});
                this.close('ERROR0');
                disableGotoNext(false); loadingGotoNext(false);

            } else { // 'CANCELED' || 'ERROR' || 'COMPLETED'
                premiumData.paymentTransactionId = '';
                this.close(status);
                disableGotoNext(false); loadingGotoNext(false);
            }
        }

    };



    //--- (3*) RETURN ---

    olPremium.orderInitReturn = function(result, mode) {
        initPremiumData(RETURN);
        disableGotoNext(true);

        premiumData.paymentState = 'PAYMENT_RETURN';
        xframeWin.resume(result, mode, orderPaymentFinished);
        //disableGotoNext(false);
    };

    olPremium.orderBackToSummary = function(event) {
        if (DEBUG) console.log('olPremium.orderBackToSummary');
        returnBackToSummary();
    };

    function returnBackToSummary() {
        $('.premium-return-checking').hide();
        $('.premium-return-back').show();
        setTimeout(function() {
            $('.premium-return-back2').show();
        }, xframeReturnCanceledTimeout);
        if (DEBUG) console.log("########## returnBackToSummary: location.href = '/#url=/premium/summary'");
        location.href = '/#url=/premium/summary';
    }



    //--- (3**) IOSRETURN ---

    // result = {orderId, productId, transactionId, transactionToken}
    olPremium.orderInitIosReturn = function(result) {
        initPremiumData(RETURN);
        disableGotoNext(true);
        //TODO Check orderId, productId
        xframeWin.resumeFromIosPayment(result);
    };



    //--- (4) FINISH ---

    olPremium.orderInitFinish = function() {
        initPremiumPage(FINISH);
        if (DEBUG) console.log('olPremium.orderInitFinish: premiumState='+premiumData.premiumState);
        if (premiumData.premiumState == 4 || premiumData.premiumState == 5) {olPremium.orderStepToCheck(); return;}
    };


}(window.olPremium = window.olPremium || {}, jQuery));
