(function(olLiveTickerTimer, $, options) {

    var DEBUG = true; // DBG 

    // Primary timer variable
    var liveTickerPlayedSeconds = 0; // How many seconds was already played
    
    // Sync variables
    var liveTickerCurrentDuration = 90; // User set duration of match
    var liveTickerIsPaused = 0; // Is Main Timer was paused by time ending or manually
    var liveTickerIsHalfTime = 0; // Is 2. HalfTime
    var liveTickerIsFinished = 0; // Is Main Timer was finished
    
    // Primary timer variables
    var maxMatchTimeInLeague = 90; // The duration of the longest match in league - in minutes
    var timerMinutes = 0; // Current minutes of Main Timer
    var timerMinutesLast = -1; // Last value of minutes of Main Timer by fire minuteChangeEvent()

    var interval = null; // Main Timer
    var synchronizationInterval = null; // Synchronization Timer
    var blinkInterval = null; // Blink timer

    var breakTime = 30;// 5; // break time should be somehow determine
    var lastMeasureTime = 0;
    var clockUi = null; // DOM element of showing Main Timer current state
    var subscriberData = null;
    var eventMinuteSubscriber = null;

    var tableMinuteSubscriber = null;
    var tableSubscriberData = null;

    // UI
    var ltMinDuration = 5;
    var ltMaxDuration = 90;

    var _prop = {
        liveTickerAudio: 1,
        liveTickerGoalAlarm: 1,
    };

    var userScroll = false;

    
    // timeline

    // The timeline always scrolls to the current minute.
    // When the user scrolls we want to stop that behaviour for 10 seconds.
    olLiveTickerTimer.timeLineInit = function() { 
        var duration = 10000; 

        var scrollTimeOut;
        function resetScrollTimeout() {
            if (scrollTimeOut) clearTimeout(scrollTimeOut);
            scrollTimeOut = setTimeout(function() {                
                userScroll = false;
            }, duration);
        }

        $('.ol-liveticker-timeline-section').on('scroll', function() {
            userScroll = true;
            resetScrollTimeout();
        });
        resetScrollTimeout();

        placeTimeLineEvents();
    };
    
    function placeTimeLineEvents() {
        var $events = $('.ol-liveticker-timeline-event');
        //var maxTime = $('.ol-liveticker-timeline-wrapper').attr('data-max-time');
        var minArr = [];

        $events.each(function(i, event) {  
            var min = $(event).attr('data-minute');
            min = timeToPercent(intval(min));
            minArr.push(min);
        });

        // check if events are in the same minute or to close together
        for (var i = 0; i < minArr.length - 1; i++) {
            var currentValue = minArr[i];
            var nextValue = minArr[i + 1];
            var difference = nextValue - currentValue;
            
            if (difference <= 2) {
                if($('body').hasClass('ol-xs')) {
                    minArr[i + 1] = currentValue + 3;
                } else {
                    minArr[i + 1] = currentValue + 2;
                }                
            }
        }

        minArr.forEach(function(val, i) {
            $($events[i]).css('left', val + '%');
        });
    }

    /**
     * First step. Start timer.
     *
     * @param data
     *      Includes max time of game in the League and matches scores.
     */
    olLiveTickerTimer.init = function(data) {
        if (data) {
            if (DEBUG) console.log('LT Debug | init: live ticker global start with data:', data);

            maxMatchTimeInLeague = intval(data.maxMatchTimeInLeague);
            liveTickerPlayedSeconds = intval(data.liveTickerPlayedSeconds);
            liveTickerIsPaused = intval(data.liveTickerIsPaused);
            //liveTickerIsHalfTime = liveTickerPlayedSeconds > 2700? 1 : 0;//TODO TEMP //intval(data.liveTickerIsHalfTime);
            liveTickerIsHalfTime = intval(data.liveTickerIsHalfTime);
            liveTickerCurrentDuration = intval(data.liveTickerCurrentDuration);
            liveTickerIsFinished = intval(data.liveTickerIsFinished);
            _prop.liveTickerAudio = intval(data.liveTickerAudio);
            _prop.liveTickerGoalAlarm = intval(data.liveTickerGoalAlarm);

            eventMinuteSubscriber = data.subscriber;
            subscriberData = data.subscriberData;

            clockUi = $('.ltTimer');

            if (maxMatchTimeInLeague < 90) {
                if (DEBUG) console.log('LT Debug | init: ERROR: max match time is '+maxMatchTimeInLeague+" < 90'");
                maxMatchTimeInLeague = 90;
            }

            eventMinuteSubscriber.setData(
                subscriberData,
                {'liveTickerAudio' : _prop.liveTickerAudio, 'liveTickerGoalAlarm': _prop.liveTickerGoalAlarm}
            );

            startTimer();
        } else {
            if (DEBUG) console.log('LT Debug | init: ERROR: Can not start timer - no data from server.');
        }
    }

    olLiveTickerTimer.initLiveTable = function(data) {
        tableMinuteSubscriber = data.subscriber;
        tableSubscriberData = data.subscriberData;
        var liveTickerAudio = intval(data.liveTickerAudio);
        var liveTickerGoalAlarm = intval(data.liveTickerGoalAlarm);

        tableMinuteSubscriber.setData(
            tableSubscriberData,
            {'liveTickerAudio' : liveTickerAudio, 'liveTickerGoalAlarm': liveTickerGoalAlarm}
        );


    }

    /**
     * Third step.
     */
    function startTimer() {
        lastMeasureTime = Date.now() / 1000;

        timerMinutes = (liveTickerPlayedSeconds / 60)|0;
        if (DEBUG) console.log('LT Debug | start: timerMinutes='+timerMinutes);

        updateDigitalClockUI();

        synchronizationInterval = setInterval(getLiveTickerState, 10000);
        interval = setInterval(updateTimer, 30);
        setLiveTickerState();

        eventMinuteSubscriber.updateViewUntilCurrentMinute(timerMinutes);

        if (tableMinuteSubscriber) {
            tableMinuteSubscriber.updateViewUntilCurrentMinute(timerMinutes);
        }
    }

    /**
     * Updating timer and stopping it by half and final count of game time.
     */
    function updateTimer() {
        var currentTs = Date.now() / 1000;
        if (!liveTickerIsPaused) {
            var dif = currentTs - lastMeasureTime;

            // The difference between 90 real minutes and set minutes by user, actually the speed of timer.
            var accelerationFactor = 90 / liveTickerCurrentDuration;
            liveTickerPlayedSeconds += dif * accelerationFactor;
            if (DEBUG) console.log("LT Debug | played="+liveTickerPlayedSeconds.toFixed(1)+"''="+(liveTickerPlayedSeconds/60).toFixed(1)+"', timerMinutes="+timerMinutes);

            if (liveTickerPlayedSeconds > maxMatchTimeInLeague * 60) {
                liveTickerPlayedSeconds = maxMatchTimeInLeague * 60;
                stopTimer();
            }

            timerMinutes = ~~(liveTickerPlayedSeconds / 60);
            if (timerMinutes === 45 && liveTickerIsHalfTime === 0) {
                if (DEBUG) console.log("LT Debug | liveTickerIsHalfTime FIRE");
                liveTickerPlayedSeconds = 45 * 60;
                pauseTimer(true);
            }
            if (timerMinutes > timerMinutesLast) {
                if (DEBUG) console.log('LT Debug | FIRE MINUTE CHANGE EVENT: '+timerMinutes+"'");
                eventMinuteSubscriber.minuteChangeEvent(timerMinutes, true);
                if (tableMinuteSubscriber)  tableMinuteSubscriber.minuteChangeEvent(timerMinutes, true);
                timerMinutesLast = timerMinutes;
            }
            updateDigitalClockUI();
        } else if (liveTickerPlayedSeconds >= maxMatchTimeInLeague * 60) {
            // Check if already end (to avoid intervals running forever)
            liveTickerPlayedSeconds = maxMatchTimeInLeague * 60;
            stopTimer();
        }

        lastMeasureTime = currentTs;
    }

    /**
     * Returns true if we reached the auf the matches.
     * 
     * @returns {bool} true if time is up
     */
    olLiveTickerTimer.timeIsUp = function() {
        // Determine state
        var endSeconds = maxMatchTimeInLeague * 60;
        var finished = (liveTickerPlayedSeconds >= endSeconds);
        if (DEBUG) console.log('LT Debug | timeIsUp='+finished);

        // Limit played seconds to max 
        if (liveTickerPlayedSeconds > endSeconds) liveTickerPlayedSeconds = endSeconds;

        // Return state
        return finished;
    }

    /**
     * Updating clock UI (DOM).
     */
    function updateDigitalClockUI() {
        // Don't show seconds and remove 0 when time begins with 0
        var timeString = timerToString();
        var time = timeString.slice(0, -3).replace(/^0/, '');
        clockUi.text(time);

        // Update dotted Progress diagram in Liveticker time display
        updateTimingChart(timeString);

        // Calculate length of timeline based on time
        updateTimeLineLength(timeString);

        // Show events based on time
        showEvents(time, $('.ol-liveticker-timeline-event')); // timeline events
        showEvents(time, $('.match-result-popup'));           // playingfield
        showEvents(time, $('.ol-matchreport-sub-wrapper'));   // substitutions
        showEvents(time, $('.ol-liveticker-results-card'));   // cards
    };

    function updateTimeLineLength(time) {
        //var maxTime = $('.ol-liveticker-timeline-wrapper').attr('data-max-time');
        var percentValue = intval(timeToPercent(time), 10);
        // value could exceed 100% because of overtime
        if (percentValue >= 100) {
            percentValue = 100;
        }
        $('.ol-liveticker-timeline-progress').css('width', percentValue + '%')

        // for mobile devices we only show a section of the timeline
        // so we need to scroll the timeline to the left        
        if ($('body').hasClass('ol-xs')) {
            var $timeLineScrollContainer = $('.ol-liveticker-timeline-section');
            var scrollValue = $timeLineScrollContainer.width() * (percentValue / 100);

            if (percentValue >= 75) {
                scrollValue = 500;
            } else if (scrollValue < 100) {
                scrollValue = 0;
            }

            if (DEBUG) console.log("LT Debug | scrollValue:", scrollValue);

            if (!userScroll) {
                $timeLineScrollContainer.scrollLeft(scrollValue);
                // $timeLineScrollContainer.animate({ scrollLeft: scrollValue }, 500);
            }
        }
    }

    function showEvents(time, $selector) {
        time = intval(time, 10);
        $selector.each(function(i, event) {
            var min = intval($(event).attr('data-minute'), 10);
            if (time >= min) {
                $(event).removeClass('hidden');
            }
        });
    }

    function timeToPercent(time, maxTime) {
        if (maxTime === undefined) maxTime = 90;
        if (typeof time === 'string') {
            var splitStr = time.split(':');
            var minutes = intval(splitStr[0], 10);
            var seconds = intval(splitStr[1], 10);
            var wholeMinutes = minutes + seconds / 60;
            var minInPercent = (wholeMinutes / maxTime) * 100;
            return minInPercent.toFixed(2);
        } else {
            var percent = (time / maxTime) * 100;
            return Math.round(percent);
        }      
    }

    var getAjax = null;
    function getLiveTickerState() {
        if (getAjax) { getAjax.abort(); getAjax = null; }
        getAjax = $.get('/liveticker/getstate', function(response) {
            if (DEBUG) console.log('LT Debug | get-live-ticker-state:', response);

            if (!response || response.length == 0) {
                return false;
            }

            liveTickerPlayedSeconds = intval(response.liveTickerPlayedSeconds);

            if (liveTickerCurrentDuration !== intval(response.liveTickerCurrentDuration)) {
                liveTickerCurrentDuration = intval(response.liveTickerCurrentDuration);
                olLiveTickerSettingsControl.setLiveTickerSliderValue(liveTickerCurrentDuration, false);
            }

            if (liveTickerIsPaused !== intval(response.liveTickerIsPaused)) {
                liveTickerIsPaused = intval(response.liveTickerIsPaused);
                olLiveTickerSettingsControl.togglePlayButton(false);
            }

            if (liveTickerIsHalfTime !== intval(response.liveTickerIsHalfTime)) {
                liveTickerIsHalfTime = intval(response.liveTickerIsHalfTime);
            }

            if (liveTickerIsFinished !== intval(response.liveTickerIsFinished)) {
                liveTickerIsFinished = intval(response.liveTickerIsFinished);
            }

            if (_prop.liveTickerGoalAlarm !== intval(response.liveTickerGoalAlarm)) {
                _prop.liveTickerGoalAlarm = intval(response.liveTickerGoalAlarm);
                olLiveTickerSettingsControl.changeCheckboxes('liveTickerGoalAlarm', intval(_prop.liveTickerGoalAlarm));
            }

            if (_prop.liveTickerAudio !== intval(response.liveTickerAudio)) {
                _prop.liveTickerAudio = intval(response.liveTickerAudio);
                olLiveTickerSettingsControl.changeCheckboxes('liveTickerAudio', intval(_prop.liveTickerAudio));
            }

            updateDigitalClockUI();
            getAjax = null;

        }).error(function(e) {
            if (e.statusText == 'abort') {
                console.log('*** get-live-ticker-state: aborted previous request');
            } else {
                console.log('*** get-live-ticker-state failed: can\'t synchronise timer', e);
            }
        });
    }

    function setLiveTickerState() {
        // Abort last request
        if (getAjax) {
            getAjax.abort();
            getAjax = null;
        }

        var data = {
            liveTickerPlayedSeconds: intval(liveTickerPlayedSeconds),
            liveTickerCurrentDuration: intval(liveTickerCurrentDuration),
            liveTickerAudio: intval(_prop.liveTickerAudio),
            liveTickerGoalAlarm: intval(_prop.liveTickerGoalAlarm),
            liveTickerIsPaused: intval(liveTickerIsPaused),
            liveTickerIsHalfTime: intval(liveTickerIsHalfTime),
            liveTickerIsFinished: intval(liveTickerIsFinished),
            _token: $('meta[name="csrf-token"]').attr('content') || $('input[name="_token"]').val()
        };
        $.post('/liveticker/setstate', data, function(response) {
            $('#liveTicker-settings-error').addClass('hidden');
        }).error(function(e) {
            $('#liveTicker-settings-error').text(e.responseText);
            $('#liveTicker-settings-error').removeClass('hidden');
        });
    }
    
    function stopTimer() {
        // Stop intervals
        if (interval) {
            clearInterval(interval); 
            interval = null; 
        }
        if (synchronizationInterval) {
            clearInterval(synchronizationInterval);
            synchronizationInterval = null;
        }
        if (blinkInterval) {
            clearInterval(blinkInterval);
            blinkInterval = null;
        }
        if (DEBUG) console.log('LT Debug | Timer stopped because time ended after '+(liveTickerPlayedSeconds/60)+"'");

        // Show that we're done
        $('.ol-live-icon-before').removeClass('live-blink');
        //$('.ol-value-pie.ol-liveticker-pie')[0].style.setProperty('--lc', 'rgb(7, 204, 0)'); // TODO Daniel
        var o = $('.ol-value-pie.ol-liveticker-pie')[0];
        if (o) o.style.setProperty('--lc', 'rgb(7, 204, 0)'); // TODO Daniel
    }

    /**
     * Finish live mode and timer.
     * Reload page in normal mode.
     */
    olLiveTickerTimer.finish = function() {
        stopTimer();
        var data = {
            _token: $('meta[name="csrf-token"]').attr('content') || $('input[name="_token"]').val()
        };
        $.post('/liveticker/setfinished', data, function(response) {
            if (DEBUG) console.log('LT Debug | Timer was finished on '+(liveTickerPlayedSeconds/60).toFixed(1)+"'");
            window.location.reload();
        }).error(function(e) {
            console.log('*** live-ticker-timer-is-finished failed: can\'t finish timer', e);
        });
    };

    if (DEBUG) {
        olLiveTickerTimer.reset = function() {
            $.post('/liveticker/reset')
            .then(function() {
                location.reload();
            });
        };
    }

    /**
     * Pause timer.
     *
     * @param {boolean} isHalfPeriod
     */
    function pauseTimer(isHalfPeriod) {
        liveTickerIsPaused = 1;
        if (isHalfPeriod) {
            liveTickerIsHalfTime = 1;
            // setTimeout(resumeTimer, breakTime*1000); // => %5s, 30s
            olLiveTickerTimer.initWelect(olConfig.welectLiveticker, resumeTimer);
        }
        setLiveTickerState();
    }

    // DBG
    olLiveTickerTimer.testHalftime = function() { pauseTimer(true); };

    /**
     * Resume timer.
     */
    function resumeTimer() {
        liveTickerIsPaused = 0;
        setLiveTickerState();
    }

    /**
     * Set new duration of game by user's change.
     *
     * @param newDuration
     */
    olLiveTickerTimer.setDuration = function(newDuration) {
        newDuration = intval(newDuration);
        if (newDuration < ltMinDuration) {
            newDuration = ltMinDuration;
        }
        if (newDuration > ltMaxDuration) {
            newDuration = ltMaxDuration;
        }
        liveTickerCurrentDuration = newDuration;
        setLiveTickerState();
    };

    /**
     * Set audio settings.
     *
     * @param audioSettingsName
     * @param audioSettingsValue
     */
    olLiveTickerTimer.setAudioOrGoalAlarmSettings = function(audioSettingsName, audioSettingsValue) {
        if (audioSettingsName === 'liveTickerAudio') {
            _prop.liveTickerAudio = audioSettingsValue;
        } else if (audioSettingsName === 'liveTickerGoalAlarm') {
            _prop.liveTickerGoalAlarm = audioSettingsValue;
        }
        setLiveTickerState();
    };

    /**
     * Get current settings.
     *
     * @param settingsName
     */
    olLiveTickerTimer.getSettings = function(settingsName) {
        return _prop.hasOwnProperty(settingsName)? _prop[settingsName] : null;
    }

    /**
     * Toggle play/pause button.
     */
    olLiveTickerTimer.togglePlayButton = function() {
        if (liveTickerIsPaused === 1) {
            resumeTimer();
        } else {
            pauseTimer(false);
        }
        if (DEBUG) console.log('LT Debug | togglePlayButton:', liveTickerIsPaused);
    };

    /**
     * Get current state of timer in format 00:00.
     *
     * @returns {string}
     */
    function timerToString() {
        var ltSeconds = ~~(liveTickerPlayedSeconds % 60);
        var ltMinutes = ~~(liveTickerPlayedSeconds / 60);
        return ltMinutes.toString().padStart(2, '0') + ':' + ltSeconds.toString().padStart(2, '0');
    }

    /**
     * Converts val to an integer with parseInt and returns the integer or 0 if invalid.
     *
     * @param val
     * @param {number|undefined} base (default=undefined)
     * @returns {number}
     */
    function intval(val, base) {
        var num = parseInt(val, base);
        return isNaN(num)? 0 : num;
    }

    ///**
    // * TODO Temp Debug. Set "liveTickerPlayedSeconds"
    // *
    // * This action is used only during development.
    // * Should be deleted after finish of realisation.
    // */
    //olLiveTickerTimer.setPS = function(PS) {
    //    liveTickerPlayedSeconds = PS;
    //    setLiveTickerState();
    //};

    // update the dotted progress pie in the liveticker header minute-display
    function updateTimingChart(time) {
        var $sections = $('.ol-liveticker-pie .ol-liveticker-pie-section');
        var splitStr = time.split(':');
        var minutes = intval(splitStr[0], 10);
        var seconds = intval(splitStr[1], 10);
        var wholeMinutes = Math.round(minutes + seconds / 60);

        $sections.each(function(i, el) {
            var elSec = $(el).attr('data-dot-second');
            if (intval(elSec, 10) <= seconds) {
                $(el).fadeIn(400);
            } else {
                $(el).stop(true, true).fadeOut(200);
            }
        });
    }

    // init the dotted progress pie in header
    olLiveTickerTimer.initTimingChart = function() {
        var $stateColor = $('.ol-value-pie-section').css("color");
        var $pie = $('.ol-value-pie.ol-liveticker-pie');
        $pie[0].style.setProperty('--lc', $stateColor);
    }

    /**
     * A blick effect for the sign "Live mode".
     */
    blinkInterval = window.setInterval(function() {
        $('.live-mode-blick').fadeIn(500).fadeOut(500);
    }, 1000);


    //-----------------------------------------------------------------------------------------------------------------

    olLiveTickerTimer.welectLoaded = false;
    
    olLiveTickerTimer.initWelect = function(welectURL, onDone) {
        console.log('LT Debug | Welect | initWelect("'+welectURL+'", onDone)');

        // first unload existing script
        $('#welectAd').remove();
        var script = document.createElement('script');
        script.setAttribute("id", "welectAd");
        script.onload = function() {
            console.log('LT Debug | Welect | onload');
            olLiveTickerTimer.welectLoaded = true;
            runWelect(onDone);
        };
        script.src = welectURL; 

        document.head.appendChild(script); 
    };

    function runWelect(onDone) {
        console.log('LT Debug | Welect | runWelect(onDone)');
        
        function _welectDone() {
            console.log('LT Debug | Welect | _welectDone()');
            onDone();
        }

        function _showWelect() {
            console.log('LT Debug | Welect | _showWelect()');
            Welect.checkAvailability({ 
                onAvailable: function() {
                    Welect.runSession({onSuccess: function() {
                        console.log('LT Debug | Welect | Welect.checkAvailability onSuccess');
                        _welectDone();
                    }, onCancel: function() {
                        console.log('LT Debug | Welect | Welect.checkAvailability onCancel');
                        _welectDone();
                    }});
                }, 
                onUnavailable: function() {
                    console.log('LT Debug | Welect | Welect.checkAvailability onUnavailable');
                    // TODO show ads instead
                    _welectDone();
                }
            });
        }

        console.log('LT Debug | Welect | Before Welect.checkToken');
        Welect.checkToken({ 
            onValid: function() { 
                console.log('LT Debug | Welect | Welect.checkToken onValid');
                _showWelect(id); 
            }, onInvalid: function() { 
                console.log('LT Debug | Welect | Welect.checkToken onInvalid');
                _showWelect(id); 
            } 
        });
        console.log('LT Debug | Welect | After Welect.checkToken');
    }

})(window.olLiveTickerTimer = window.olLiveTickerTimer || {}, jQuery, {});
