
var olAdsense = new (function() {
    this.check = function() {

        $('.ol-adsense:not(.initialized):not([data-show-fallback=false])').each(function() {
        //$('.sponsor-logo-responsive').each(function() {
            $(this).addClass('initialized');
            if (typeof canShowAds != "undefined" && canShowAds) {
                return;
            }

            $(this).getLoad('/sponsoring/banner');
        });
    }
})();