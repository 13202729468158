(function(olAcpViolation, $, undefined)
{

    // var
    var globalUserId = 0;
    var globalConspicPoolId = 0;
    var globalViolationId = 0;
    var globalCategoryId = 0;
    olAcpViolation.showDelegateViolation = function(conspicPoolId, token)
    {
        globalConspicPoolId = conspicPoolId;
        // Indizien einer bestimmten Violation(gruppe)
        $.ajax({
            type: "POST",
            url: '/acp/violation/showViolationUserViolation',
            dataType: 'html',
            data: {
                //for authentification
                conspicPoolId: conspicPoolId,
                _token: token
            },
            success: olAcpViolation.Show
        });
    }

    olAcpViolation.Show = function(result)
    {
        putInId = '#violationContent'; // Default


        if (globalConspicPoolId) {
            putInId = '#conspic' + globalConspicPoolId;
            $(putInId).toggle('display');
        }

        if (globalViolationId) {
            putInId = '#violationGroupContent';
        }

        // Einsetzen
        $(putInId).html(result);

    }


    olAcpViolation.showHideViolation = function()
    {
        // showHide Violation
        var register = $('#violationHeader');
        if (register.css('display') === 'none')
        {
            register.css({
                'display': 'block',
            });
            $('#violationDown').css({
                'display': 'none',
            });
            $('#violationUp').css({
                'display': 'inline',
            });
        } else
        {
            register.css({
                'display': 'none',
            });
            $('#violationUp').css({
                'display': 'none',
            });
            $('#violationDown').css({
                'display': 'inline',
            });
        }

    }

    olAcpViolation.showViolationGroup = function(violationId, token)
    {
        globalViolationId = violationId;
        var violationButton = "#violationButton" + violationId;
        var register = $(violationButton);
        // Reset alle Button
        $('.btn').css('opacity', '0.7');
        $('.btn').css('border', 'none');
        // ausgewaehlten Button aendern
        if (register.css('opacity') === '1')
        {
            register.css({
                'opacity': '0.7',
            });
        } else
        {
            register.css({
                'opacity': '1',
            });
            register.css({
                'border': '1px solid black',
            });
        }

        // Indizien einer bestimmten Violation(gruppe)
        $.ajax({
            type: "POST",
            url: '/acp/violation/showViolationGroup',
            dataType: 'html',
            data: {
                //for authentification
                _token: token,
                violationId: violationId
            },
            success: olAcpViolation.Show
        });
    }

    olAcpViolation.textareaKeyDown = function(button, token)
    {
        // Button zum speichern einblenden
        button = '#' + button;
        $(button).css('display', 'block');
    }

    olAcpViolation.textareaSave = function(button, textareaId, token)
    {


        textarea = '#' + textareaId;
        textareaValue = $(textarea).html();

        // Indizien einer bestimmten Violation(gruppe)
        $.ajax({
            type: "POST",
            url: '/acp/violation/textareaSave',
            dataType: 'html',
            data: {
                //for authentification
                _token: token,
                textareaId: textareaId,
                textareaValue: textareaValue

            },
            success: olAcpViolation.hideSaveButto(button)
        });

    }

    olAcpViolation.hideSaveButton = function(button) {
        // Button nach speichern ausblenden
        button = '#' + button;
        $(button).css('display', 'none');
    }

}(window.olAcpViolation = window.olAcpViolation || {}, jQuery));