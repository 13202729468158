"use strict";
(function(olSponsorSponsoring, $, undefined) {

    olSponsorSponsoring.page = '';
    
    var noticeTimeout = 3000;
    var statusCheckTimeout = 20000; // time after which the status check on redirect payments is startet
    var statusCheckInterval = 5000; // interval in which the status check on redirect payment is issued
    var xframeCloseTimeout = 500;
    var DEBUG = false;



    //--- Sponsoring > * ---

    olSponsorSponsoring.onSelectSeason = function(event) {
        event.stopPropagation();
        event.preventDefault();
        var $element = $(event.target);
//        var season = Number($('#dropdownSponsorSettingsSeason').dropdown().value());
        var season = Number($element.attr('data-value'));
        var currentSeason = parseInt($('#currentSeason').val());
        var $checkbox = $('#sponsorInputField_sponsoringDuration_PROLONG');
        
        var checked = $checkbox.attr('checked') || $checkbox.parent().hasClass('checked');

        var sponsoringDone = $('#sponsoringStatus').val() === "SPONSORING_DONE";

        if (season > currentSeason && checked && sponsoringDone) {
            olMsgBox.question(
                Lang.trans('js/sponsor.sponsoring'), 
                Lang.trans('js/sponsor.msgSponsoringProlong', {s1: currentSeason, s2: season}),
                function() {
                    $checkbox.attr('checked', null);
                    olSponsorSponsoring.onChangeData({ target: '#sponsorInputField_sponsoringDuration_PROLONG' }, function() {
                        olSponsorSponsoring.selectSeason(season);
                    });
                },
                function() { },
                Lang.trans('js/sponsor.changeSponsoring'));
            return;
        }

        olSponsorSponsoring.selectSeason(season);
        
    };

    olSponsorSponsoring.selectSeason = function(season) {
        var data = {season: season};

        var $anchor = $('#ol-root');
        addLoadingAnimationToElement($anchor);
        $.get('/sponsor/sponsoring/mainsponsor', data, function(rc) {
            $('#ol-root').html(rc);
            removeLoadingAnimationFromElement($anchor);
            
            olSponsorSettings.refreshSeasonHead(season);
            $('html, body').scrollTop(0);

        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** selectSeason failed:", data);
        });


    };



    //--- Sponsoring > Haupsponsor ---

    var minLength = 2, minLengthByType = {COUNTRY: 1, STATE: 3, DISTRICT: 3, COMMUNITY: 2};

    olSponsorSponsoring.initMainSponsor = function() {
        olSponsorSponsoring.page = 'mainsponsor';

        var season = $('#dropdownSponsorSettingsSeason').attr('data-value');
        olSponsorSettings.selectSeason(season);

//        // Season info
//        olSponsorSponsoring.selectSeason(0);

        // Region-Autocomplete
        $('.ol-sponsor-data-region-search-input[data-provide="typeahead"]').each(function(i, o) {
            $(o).typeahead({
                minLength: minLength,
                limit: 10,
                items: 100,
                matcher: typeaheadMatcher,
//                updater: typeaheadUpdater,
                afterSelect: typeaheadAfterSelect,
                displayText: typeaheadDisplayText,
                highlighter: typeaheadHighlighter
            });
        });

        $.get('/sponsor/settings/checkfields', function(res) {
            if (!res.missingKind && !res.started) {
                showSponsorStartPrompt();
            }
        });


        //$(window).bind('beforeunload', function() {
        //    showSponsorStartPrompt();
        //    return 'Ihr Sponsoring für die aktuelle Saison ist noch nicht aktiv. Möchten Sie Ihr Sponsoring jetzt starten?';
        //});
    };

    function showSponsorStartPrompt() {
        olMsgBox.question(Lang.trans('js/sponsor.yourSponsoring'), Lang.trans('js/sponsor.msgSponsoringNotActive'), function() {
            olAnchorNavigation.load('sponsor/sponsoring/mainsponsor', function() {
                olGUI.scrollTo($('#sponsorInputField_sponsoringDuration_PROLONG'), 450);
                $('.ol-sponsor-data-button').addClass('sponsor-border-pulse');
            })
        }, function() { }, Lang.trans('js/sponsor.start'), Lang.trans('js/sponsor.cancel'));
    }

    function typeaheadMatcher(item) {
        return true;
    }

//    function typeaheadUpdater(item) {
//        return item;
//    }

    function typeaheadAfterSelect(item) {
        var main = getRegionMain(this.$element);
        //console.log('typeaheadAfterSelect:', item);
        main.setHiddenValue(item.id); // set ID of selected item
        main.$items.click(); // perform custom action on selection
    }

    function typeaheadDisplayText(item) {
        return item.name;
    }

    function typeaheadHighlighter(itemText) {
        var _item = this.source.find(function(item) {return (item.name == itemText);});
        var item = itemText,
            imgCls = _item.imgCls,
            hint = _item.hint;

        //> original part
        var text = this.query;
        if (text === '') return item;
        var matches = item.match(/(>)([^<]*)(<)/g);
        var first = [];
        var second = [];
        var i;
        if (matches && matches.length) {
            // html
            for (i = 0; i < matches.length; ++i) {
                if (matches[i].length > 2) {// escape '><'
                    first.push(matches[i]);
                }
            }
        } else {
            // text
            first = [];
            first.push(item);
        }
        text = text.replace((/[\(\)\/\.\*\+\?\[\]]/g), function(mat) {
            return '\\' + mat;
        });
        var reg = new RegExp(text, 'g');
        var m;
        for (i = 0; i < first.length; ++i) {
            m = first[i].match(reg);
            if (m && m.length > 0) {// find all text nodes matches
                second.push(first[i]);
            }
        }
        for (i = 0; i < second.length; ++i) {
            item = item.replace(second[i], second[i].replace(reg, '<strong>$&</strong>'));
        }
        //<

        item = '<span class="ol-sponsor-data-region-search-item">'+
                   '<span class="ol-sponsor-data-region-search-item-icon '+imgCls+'"></span>'+
                   '<span class="ol-sponsor-data-region-search-item-text">'+item+'</span>'+
                   '<span class="ol-sponsor-data-region-search-item-hint">'+hint+'</span>'+
                '</span>';

        return item;
    }


    olSponsorSponsoring.onClickCheckbox = function(event) {
        var $element = $(event.target),
            $label = $element.closest('.ol-sponsor-data-option-label');
        var checked = $element.prop('checked');
        $label.toggleClass('checked', checked);
    };

    olSponsorSponsoring.onClickCheckboxLabel = function(event) {
        var $element = $(event.target),
            $checkbox = $element.prev().find('input[type="checkbox"]');
        $checkbox.click();
    };

    olSponsorSponsoring.onClickRadio = function(event) {
        var $element = $(event.target),
            $group = $element.closest('.ol-sponsor-data-radio-group');
        var value = $element.val();
        $group.attr('data-value', value);
    };

    olSponsorSponsoring.onClickRadioLabel = function(event) {
        var $element = $(event.target),
            $radio = $element.prev().find('input[type="radio"]');
        $radio.click();
    };



	var regionMainProto = {
		getDropdownValue: function() {
            return this.$dropdown.dropdown().value();
        },
        setDropdownNoitem: function() {
            this.$noitem.click();
        },
        getDropdownItemByType: function(type) {
            return this.$dropdown.find('.dropdown-menu a[data-value="'+type+'"]');
        },

        getHiddenValue: function() {
            return this.$hidden.val();
        },
        setHiddenValue: function(value) {
            this.$hidden.val(value);
        },

        filterOpen: function() {
            this.$filter.typeahead('open');
        },
        filterClose: function() {
            this.$filter.typeahead('close');
        },
        filterClear: function() {
            this.$filter.val('');
			var typeahead = this.$filter.data('typeahead');
            if (typeahead) typeahead.hide();
            if (typeahead) typeahead.source = [];
            this.$filter.typeahead('close');
        },
        filterFocusIf: function(on) {
            if (on) this.$filter.focus();
        },
        setFilterSource: function(source) {
            this.$filter.data('typeahead').source = source;
            this.$filter.typeahead('val', 'cookie')
                        .trigger('input')
                        .typeahead('open');
        },
        getFilterValue: function() {
            return this.$filter.val();
        },
        setFilterValue: function(value) {
            this.$filter.val(value);
        },
        setFilterPlaceholder: function(placeholder) {
            this.$filter.attr('placeholder', placeholder);
        },

        getItems: function() {
            var items = [];
            try {
                items = JSON.parse(this.$items.val());
            } catch (ex) {
                console.log('*** regionMainProto.getItems failed: '+ex);
                items = [];
            }
            return items;
        },
        setItems: function(items) {
            this.$items.val(JSON.stringify(items));
        },
        getItemsValue: function() {
            return this.$items.val();
        },
        setItemsValue: function(value) {
            this.$items.val(value);
        },
        hasItems: function() {
            return (this.getItems().length > 0);
        },
        addItem: function(item) {
            var items = this.getItems();
            items.push(item);
            this.setItems(items);
        },
        removeItem: function(index) {
            var items = this.getItems();
            items.splice(index, 1);
            this.setItems(items);
        },
        updateItemsStatus: function() {
            var $hintArea = this.$dropdown.closest('.ol-sponsor-input-data').find('.ol-sponsor-input-hint-area');
            $hintArea.toggleClass('error', !this.hasItems());
        }
    };
    function getRegionMain($element) {
        var mainId = $element? $element.attr('data-mainId') : '';
        if (!mainId) mainId = 'sponsorInputField_sponsoringRegion_type';
        var $main = $('#'+mainId);
        if (!$main.length) $main = $('#sponsorInputField_main');
        var main = Object.create(regionMainProto);
        main.$dropdown  = $('#'+$main.attr('data-dropdownId'));
        main.$text      = $('#'+$main.attr('data-textId'));
        main.$hidden    = $('#'+$main.attr('data-hiddenId'));
        main.$filter    = $('#'+$main.attr('data-filterId'));
        main.$itemsArea = $('#'+$main.attr('data-itemsAreaId'));
        main.$items     = $('#'+$main.attr('data-itemsId'));
        main.$noitem    = $('#'+$main.attr('data-noitemId'));
        main.$reduced   = $('#'+$main.attr('data-reducedId'));
        return main;
    }

    olSponsorSponsoring.onSelectRegionType = function(event) {
        var $element = $(event.target);
        if ($element.hasClass('disabled')) return;

        var $item = $element.closest('a'),
            main = getRegionMain($item),
            type = $item.attr('data-value'),
            placeholder = $item.attr('data-placeholder'),
            autoValue = $item.attr('data-autoValue');

        // Clear value, set new placeholder
        main.setFilterPlaceholder(placeholder);
        main.setHiddenValue(type);
        main.filterClear();
        main.$filter.prop('disabled', false);
        main.filterFocusIf(!autoValue);

        if (autoValue) {
            $item.addClass('disabled');
            addRegionItem(type, autoValue, true);
        }
    };

    olSponsorSponsoring.onKeySearchRegion = function(event) {
        var main = getRegionMain($(event.target));
        startSearchRegion(main);
    };

    function startSearchRegion(main) {
        var data = {
            type: main.getDropdownValue(),
            value: main.getFilterValue()
        };
        if (data.type == '') return;
        if (data.value.length < minLengthByType[data.type]) return;
        $.get('/sponsor/sponsoring/mainsponsor/regions', data, function(rc) {
            main.setFilterSource(rc);
        }, 'json').fail(function() {
            console.log("*** startSearchRegion failed:", data);
        });
    };

    olSponsorSponsoring.onAddRegion = function(event) {
        var main = getRegionMain($(event.target)),
            regionType = main.getDropdownValue(),
            regionId = main.getHiddenValue();
        addRegionItem(regionType, regionId);
    };

    function refreshRegionItems() {
        var $anchor = $('#sponsorInputField_sponsoringRegion_itemsArea').closest('.ol-sponsor-input-row');

        var season = $('#dropdownSponsorSettingsSeason').attr('data-value');

        $.get('/sponsor/sponsoring/mainsponsor/regions/get', { season: season }, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            updateRegionItemsFrom(rc);

        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** addRegionItem failed:", data);
        });
    }

    function addRegionItem(regionType, regionId, instant) {
        if (instant === undefined) instant = false;

        var data = getMainSponsorFormData({regionType: regionType, regionId: regionId});

        var $anchor = $('#sponsorInputField_sponsoringRegion_itemsArea').closest('.ol-sponsor-input-row');
        addLoadingAnimationToElement($anchor, {size: 'small', instant: instant});
        $.post('/sponsor/sponsoring/mainsponsor/regions/add', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            if (rc.err) {
                olDialog.messageExt('#sponsorMainSponsorSaveError', {'data-msg-text': rc.err});
                return;
            }

            $('#sponsorInputField_sponsoringId').val(rc.sponsoringId);

            var main = getRegionMain();
            //main.$itemsArea.append(rc.html);
            main.setItems(rc.regionItems);
            main.updateItemsStatus();
            updateRegionItemsFrom(rc.regionItems);
            updateCostsBoxWith(rc.price1Html, rc.price3Html)
            
            main.$reduced.hide();
            if (rc.reduced) {
                main.$reduced.show();
                setTimeout(function() {main.$reduced.hide();}, noticeTimeout);
            }

            main.filterClear();
            if (data.regionType == 'COUNTRY') {
                main.setHiddenValue(0);
                main.setDropdownNoitem();
                main.$filter.prop('disabled', true);
            }

        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** addRegionItem failed:", data);
        });
    }

    olSponsorSponsoring.removeRegionItem = function(event) {
        var $element = $(event.target).closest('.ol-sponsor-data-region-chosen-item-ops'),
            main = getRegionMain(),
            regionType = $element.attr('data-type'),
            regionId = $element.attr('data-id');

        var data = getMainSponsorFormData({regionType: regionType, regionId: regionId});
        var $anchor = $('#sponsorInputField_sponsoringRegion_itemsArea');
        addLoadingAnimationToElement($anchor, {size: 'small', instant: true});
        $.post('/sponsor/sponsoring/mainsponsor/regions/remove', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            if (rc.err) {
                olDialog.messageExt('#sponsorMainSponsorSaveError', {'data-msg-text': rc.err});
                return;
            }

            $('#sponsorInputField_sponsoringId').val(rc.sponsoringId);

            main.setItems(rc.regionItems);
            main.updateItemsStatus();
            updateRegionItemsFrom(rc.regionItems);
            updateCostsBoxWith(rc.price1Html, rc.price3Html)

            if (regionType == 'COUNTRY') {
                var $typeItem = main.getDropdownItemByType(regionType);
                if ($typeItem.attr('data-autoValue')) $typeItem.removeClass('disabled');
            }
            
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** removeRegionItem failed:", data);
        });

    };


    function getMainSponsorFormData(addData) {
        if (addData === undefined) addData = {};
        var f = $('#sponsorMainSponsorForm')[0];
//        var main = getRegionMain();
//        var regions = main.getItems();

        var data = {
//            season: f.season.value,
            type: f.sponsoringType.value,
            duration: f.sponsoringDuration.value,
            prolong:  f.sponsoringProlongation.checked? 1 : 0,
//            regions: regions, //TOREMOVE
            sponsoringId: $('#sponsorInputField_sponsoringId').val()
        };
        var keys = Object.getOwnPropertyNames(addData);
        for (var i = 0;  i < keys.length;  i++) {
            var key = keys[i];
            data[key] = addData[key];
        }

        return data;
    }

    olSponsorSponsoring.onChangeData = function(event, callback) {
        var $element = $(event.target);
        var data = getMainSponsorFormData();
        if (DEBUG) console.log('> onChangeData:', data);
        $.post('/sponsor/sponsoring/mainsponsor/save', data, function(rc) {
            if (DEBUG) console.log('< onChangeData:', rc);
            if (rc.err) {
                console.log('*** onchangeData: error: '+rc.err);
                olDialog.messageExt('#sponsorMainSponsorSaveError', {'data-msg-text': rc.err});
                return;
            }

            var sponsoringId = rc.sponsoringId;
            if (sponsoringId > 0) {
                $('#sponsorInputField_sponsoringId').val(sponsoringId);
            }
            updateCostsBoxWith(rc.price1Html, rc.price3Html);
            refreshRegionItems();

            if (typeof callback === "function") {
                callback(rc);
            }

        }, 'json').fail(function() {
            console.log('*** onChangeData failed:', data);
        });
    }

    function updateCostsBoxWith(price1, price3) {
        var $costBox = $('.ol-sponsor-data-costs');
        $costBox.find('.ol-sponsor-data-costs1').html(price1);
        $costBox.find('.ol-sponsor-data-costs3').html(price3);
    }

    function updateRegionItemsFrom(items) {
        var html = ''; for (var i = 0;  i < items.length;  i++) html += items[i].html;
        $('#sponsorInputField_sponsoringRegion_itemsArea').html(html);
    }



    //--- Payment assistant ---
    /*
    assistant.data:
        name					type		set at		remark
        ----					----		------		------
        sponsoringId			numeric		0			Sponsoring.sponsoringId
        //paymentTarget         string      0           sandbox or production
        paymentPublicKey        string      0           heidelpay public key

        gtcAccepted				bool		GTC

        privacyAccepted			bool		PRIVACY

        paymentType				string		PAYMENT		Payment::TYPE_*

        sponsoringPaymentId		numeric		OVERVIEW*	SponsoringPayment.sponsoringPaymentId
        allowedOrigins			string[]	OVERVIEW*
        paymentState			string		OVERVIEW*
        paymentPrice			number		0/OVERVIEW*	Might be changed here
        paymentRecurring        bool        0

        paymentData				object		HEIDELPAY*	returned from heidelpay form submit (method data)
                            	                        mandatory fields: id, method, recurring
        paymentError 						HEIDELPAY*	error from heidelpay form submit | null
        //paymentId				string		HEIDELPAY*	heidelpay payment ID | '' (transaction ID)
        paymentTransactionId	string		HEIDELPAY*	payment transaction ID | ''

        orderId					string		HEIDELPAY*	after pay/charge
        invoiceId				string		HEIDELPAY*	after pay/charge
        redirectUrl				string		HEIDELPAY*	after pay/charge
        redirectState			string		HEIDELPAY*	CALLING | ERROR | CANCELED | SUCCESS | UNFINISHED
        redirectResult			object		HEIDELPAY*	result data from redirect

        // *=on next

    assistant.data.paymentState:
        FAILED				OVERVIEW > pay/start => failure
        STARTED				OVERVIEW > pay/start => success

        TYPE_FAILED			HEIDELPAY > heidelpay form submit => failure
        TYPE_CREATED		HEIDELPAY > heidelpay form submit => success

        PAYMENT_FAILED		HEIDELPAY > pay/charge ==> failure

        PAYMENT_ERROR		HEIDELPAY > pay/charge ==> charge error
        PAYMENT_CANCELED	HEIDELPAY > pay/charge ==> charge aborted
        PAYMENT_SUCCESS     HEIDELPAY > pay/charge ==> success
        PAYMENT_REDIRECT	HEIDELPAY > xframe
            PAYMENT_ERROR		xframe => error
            PAYMENT_CANCELED	xframe => canceled
            PAYMENT_SUCCESS     xframe => success

    */
//    var PREVIEW = 0, GTC = 1, PRIVACY = 2, PAYMENT = 3, OVERVIEW = 4, HEIDELPAY = 5, FINISH = 6;
    var GTC = 0, PRIVACY = 1, PAYMENT = 2, OVERVIEW = 3, HEIDELPAY = 4, FINISH = 5;
    var STEPS = [ //ACHTUNG: Bei Änderung in STEPS auch diese Konstanten ^ anpassen: Index keine ID!
        //'#sponsorMainSponsorAssistantPreview',
        '#sponsorMainSponsorAssistantGtc',
        '#sponsorMainSponsorAssistantPrivacy',
        '#sponsorMainSponsorAssistantPayment',
        '#sponsorMainSponsorAssistantOverview',
        '#sponsorMainSponsorAssistantHeidelpay',
        '#sponsorMainSponsorAssistantFinish',
    ];

    olSponsorSponsoring.showMainSponsorStartWarning = function(event, sponsoringId, rc) {
        var $element = $(event.target);

        olMsgBox.question(
            Lang.trans('js/sponsor.hint'),
            Lang.trans('js/sponsor.msgSettingsNotChangable'),
            function() { $element.removeClass('ol-button-loading'); startMainSponsorOrder(sponsoringId, rc); },
            function() { $element.removeClass('ol-button-loading'); },
            Lang.trans('js/sponsor.continue'),
            Lang.trans('js/sponsor.cancel')
        );
    }

    olSponsorSponsoring.onSubmitMainSponsoringForm = function(event) {
        var $element = $(event.target);
        $element.addClass('ol-button-loading');

        var main = getRegionMain();
        main.updateItemsStatus();

        var data = getMainSponsorFormData();
        var regions = main.getItems();
        if (regions.length == 0) {
            olDialog.messageExt('#sponsorMainSponsorMissingRegion', null, function() {
                $element.removeClass('ol-button-loading');
                setTimeout(function() { $('html, body').scrollTop(0); });
            });
            return;
        }

        var $anchor = $('#sponsorMainSponsorForm');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.post('/sponsor/sponsoring/mainsponsor/start', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
            if (DEBUG) console.log('rc:',rc);
            var sponsoringId = rc.sponsoringId;
            $('#sponsorInputField_sponsoringId').val(sponsoringId);
            
            if (rc.err) {
                $element.removeClass('ol-button-loading');
                if (rc.missingKind) {
                    olDialog.confirmExt('#sponsorMainSponsorMissingField_'+rc.missingKind, {
                        "data-msg-text": rc.missingText,
                    }, function(event) {
                            olAnchorNavigation.load('/sponsor/settings/' + rc.missingKind, function() { olGUI.scrollTo('.ol-sponsor-input-field-error:visible, .error'); });
                    });
                } else {
                    olDialog.messageExt('#sponsorMainSponsorSaveError', {
                        "data-msg-text": rc.err
                    });
                }
                return;
            } else {
                olSponsorSponsoring.showMainSponsorStartWarning(event, sponsoringId, rc);
            }

        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            $element.removeClass('ol-button-loading');
            console.log("*** onSubmitMainSponsoringForm failed:", data);
        });
    };

    function startMainSponsorOrder(sponsoringId, rc) {
        
        olDialog.assistant(STEPS, {"data-param": sponsoringId}, {
            success: orderSuccess,
            cancel: orderCancel,
            init: orderInitStep,
            next: orderNextStep,
            data: {
                sponsoringId: sponsoringId,
                gtcAccepted: false,
                privacyAccepted: false,
                paymentType: rc.sponsoring.payment,
                paymentPrice: rc.sponsoring.origPrice,
                paymentRecurring: (rc.sponsoring.prolong != 0),
                paymentState: rc.paymentState || '',
                continueData: null,
                sponsoringPaymentId: 0,
                allowedOrigins: [],
                paymentData: null,
                paymentError: null,
//                        paymentId: '',
                paymentTransactionId: '',
                paymentTarget: rc.target,
                paymentPublicKey: rc.publicKey,
                orderId: '',
                invoiceId: '',
                redirectUrl: '',
                redirectState: '',
                redirectResult: null,
                sponsoring: rc.sponsoring
            },
            navMode: olSponsorSponsoring.navMode || 'none' //DBG
        });
    }


    // this = assistent object
    function orderSuccess() {
        if (DEBUG) console.log('orderSuccess @'+this.step, this.data);

        // Update display to show result
        //olAnchorNavigation.load('/sponsor/sponsoring/mainsponsor');
        var season = this.data.sponsoring.season;
        olSponsorSponsoring.selectSeason(season);
    }

    // this = assistent object
    function orderCancel() {
        if (DEBUG) console.log('orderCancel @'+this.step, this.data);

        var data = {sponsoringId: this.data.sponsoringId};
        $.post('/sponsor/sponsoring/mainsponsor/cancel', data, function(rc) {
            if (DEBUG) console.log('canceled assistant:', rc);
            // Keep state for continuation
        });
    }

    // this = assistent object
    function orderInitStep() {
        if (DEBUG) console.log('orderInitStep @'+this.step, this.data);
        switch (this.step) {
//        case PREVIEW:
//            // nothing to do
//            if (this.data.paymentState == 'CONTINUE') {
//                this.disableNext(true);
//                this.goto(OVERVIEW);
//            }
//            break;
//
        case GTC:
            this.disableNext(!this.data.gtcAccepted);
            break;

        case PRIVACY:
            this.disableNext(!this.data.privacyAccepted);
            break;

        case PAYMENT:
            var typeIsDisabled = $('.ol-sponsor-mainsponsor-assistant-payment-type .ol-sponsor-data-radio-input[value="'+this.data.paymentType+'"]').prop('disabled');
            if (typeIsDisabled) this.data.paymentType = '';
            this.disableNext(!this.data.paymentType);
            break;

        case OVERVIEW:
            // nothing to do
            break;

        case HEIDELPAY:
            var ok = hp.prepare({
                paymentType: this.data.paymentType,
                dataId: 'mainsponsorAssistantHeidelpayData',
                recurring: this.data.paymentRecurring,
//                target: this.data.paymentTarget,
                publicKey: this.data.paymentPublicKey,
                continueData: this.data.continueData
            });
            if (!ok) {
                var errId = this.data.paymentPublicKey? 'sponsorMainSponsorHeidelpayPrepareError' : 'sponsorMainSponsorHeidelpayPrepareError2';
                olDialog.messageOrText('#'+errId);
                this.disableNext(true);
            }
            break;

        case FINISH:
            var prolong = (this.data.sponsoring.prolong != 0);
            //TODO Toggle checkmark accordingly => zu spät!
            break;
        }

    }

    // this = assistent object
    function orderNextStep() {
        if (DEBUG) console.log('orderNextStep @'+this.step, this.data);
        switch (this.step) {
//        case PREVIEW:
//            // nothing to do
//            this.goto(this.step+1);
//            break;
//
        case GTC:
            var accepted = this.data.gtcAccepted; // set by event handler
            this.goto(accepted? this.step+1 : this.step); // next button should be disable on !type
            break;

        case PRIVACY:
            var accepted = this.data.privacyAccepted; // set by event handler
            this.goto(accepted? this.step+1 : this.step); // next button should be disable on !type
            break;

        case PAYMENT:
            var type = this.data.paymentType; // set by event handler
            this.goto(type? this.step+1 : this.step); // next button should be disable on !type
            break;

        case OVERVIEW:
            var startData = {
                sponsoringId: this.data.sponsoringId,
                paymentType: this.data.paymentType,
                paymentPrice: this.data.paymentPrice,
                sponsoringPaymentId: this.data.sponsoringPaymentId // 0 or old ID
            };
            $.post('/sponsor/sponsoring/mainsponsor/assistant/pay/start', startData, function(rc) {
                if (rc.err) {
                    olDialog.messageOrText({"data-msg-text": olTrans.textToHtml(rc.err)});
                    return;
                }

                this.data.sponsoringPaymentId = rc.sponsoringPaymentId;
                this.data.paymentState = 'STARTED';
                this.data.allowedOrigins = rc.allowedOrigins;
                this.data.continueData = rc.continueData;

                this.data.sponsoring.price = this.data.paymentPrice;

                this.goto(this.step+1);
            }.bind(this), 'json').fail(function() {
                console.log('*** pay/start failed:', startData);
                //TODO
                this.data.paymentState = 'FAILED';
                olDialog.message('#sponsorMainSponsorStartError');
                this.disableNext(false);
            }.bind(this));
            break;

        case HEIDELPAY:
            $('.ol-sponsor-mainsponsor-assistant-heidelpay-status').hide(); //hide messages
            hp.submit(function(result) {
                if (DEBUG) console.log('Heidelpay form data submitted successfully:', result);
                this.data.paymentData = result;
                this.data.paymentError = null;
                this.data.paymentState = 'TYPE_CREATED';

                var chargeData = {
                    sponsoringId: this.data.sponsoringId,
                    sponsoringPaymentId: this.data.sponsoringPaymentId,
                    paymentType: this.data.paymentType,
                    paymentData: this.data.paymentData
                };
                var paymentFinished = orderPaymentFinished.bind(this);
                $.post('/sponsor/sponsoring/mainsponsor/assistant/pay/charge', chargeData, function(rc) {
                    if (DEBUG) console.log('charge result:', rc);
                    this.data.redirectUrl = rc.url;
                    this.data.redirectState = '';
                    this.data.orderId = rc.orderId;
                    this.data.invoiceId = rc.invoiceId;
                    this.data.paymentTransactionId = rc.transactionId;
                    //this.data.paymentState set by orderPaymentFinished()
                    if (DEBUG) console.log('After charge:', this.data);

                    if (rc.err) {
                        olDialog.messageOrText('#sponsorMainSponsorHeidelpayChargeError', {
                            "data-err": olTrans.textToHtml(rc.err).replace(/\s\*\*\*/g, '<br />***')
                        });
                        paymentFinished('ERROR'); //sets this.data.paymentState to PAYMENT_ERROR
                        this.disableNext(false); //#
                        return;
                    } else if (rc.url) {
                        this.data.paymentState = 'PAYMENT_REDIRECT';
                        this.data.redirectState = 'CALLING';
                        xframeWin.open(rc.url, rc.mode, paymentFinished); // orderPaymentFinished gets on redirection return
                    } else {
                        this.data.paymentState = 'PAYMENT_SUCCESS';
                        paymentFinished('SUCCESS'); //sets this.data.paymentState to PAYMENT_SUCCESS
                    }
                }.bind(this), 'json').fail(function() {
                    console.log('*** pay/charge failed:', chargeData);
                    olDialog.messageOrText('#sponsorMainSponsorHeidelpayChargeException');
                    this.disableNext(false); //#
                    paymentFinished('ERROR'); //sets this.data.paymentState to PAYMENT_ERROR
                }.bind(this));

            }.bind(this), function(error) {
                console.log('*** Heidelpay form not filled out correctly / submit error:', error);
                this.data.paymentData = null;
                this.data.paymentError = error;
                this.data.paymentState = 'TYPE_FAILED';

                var formErr = olTrans.textToHtml(error.message);
                olDialog.messageOrText('#sponsorMainSponsorHeidelpaySubmitError', {"data-err": formErr});
//                //olDialog.message('#sponsorMainSponsorHeidelpaySubmitError', {"data-err": formErr});
//                $('.ol-sponsor-mainsponsor-assistant-heidelpay-status-text2').html(formErr);
//                $('#mainsponsorAssistantHeidelpayStatusInvalid').show();

                //TODO unterscheiden:
                // Eingabefehler, => Fehler schon angezeigt für onlyIframe=false
                // Übermittlungsfehler => Ursache steht in `error`

                this.disableNext(false);
            }.bind(this));
            break;

        case FINISH:
            //console.log('TODO: Prolongation might be changed')
            this.goto(this.step+1);
            break;
        }
    }

    // this = assistent object
    function orderPaymentFinished(state) {
        // Status eintragen
        this.data.redirectState = state; // SUCCESS | ERROR | CANCELED | UNFINISHED
        this.data.paymentState = 'PAYMENT_'+state;

        // Aktion ausführen
        if (state === 'SUCCESS') {
            var finishData = {
                sponsoringId: this.data.sponsoringId,
                sponsoringPaymentId: this.data.sponsoringPaymentId,
                transactionId: this.data.paymentTransactionId
            };
            $.post('/sponsor/sponsoring/mainsponsor/assistant/pay/finish', finishData, function(rc) {
                if (DEBUG) console.log('finish result:', rc);
                olDialog.messageOrText('#sponsorMainSponsorHeidelpayStatusSuccess');
                //this.disableNext(false);
                if (rc.err) {
                    olDialog.messageOrText('#sponsorMainSponsorHeidelpayFinishError', {"data-err": rc.err});
                    orderPaymentFinished.call(this, 'UNFINISHED');
                } else {
                    this.goto(FINISH);
                }
            }.bind(this), 'json').fail(function() {
                console.log('*** pay/finish failed:', finishData);
                olDialog.messageOrText('#sponsorMainSponsorHeidelpayFinishException');
                orderPaymentFinished.call(this, 'UNFINISHED');
            }.bind(this));



        } else if (state === 'CHARGE2') {
            // Return from recurring redirect => do charge now
            var charge2Data = {
                sponsoringId: this.data.sponsoringId,
                sponsoringPaymentId: this.data.sponsoringPaymentId,
                paymentData: this.data.paymentData
            };
            $.post('/sponsor/sponsoring/mainsponsor/assistant/pay/charge2', charge2Data, function(rc) {
                if (DEBUG) console.log('charge2 result:', rc);
                //olDialog.messageOrText('#sponsorMainSponsorHeidelpayStatusSuccess');
                if (rc.err) {
                    olDialog.messageOrText('#sponsorMainSponsorHeidelpayCharge2Error', {"data-err": rc.err});
                    orderPaymentFinished.call(this, 'UNFINISHED');
                } else {
                   this.data.paymentTransactionId = rc.transactionId;
                   orderPaymentFinished.call(this, 'SUCCESS');
                }
            }.bind(this), 'json').fail(function() {
                console.log('*** pay/finish failed:', finishData);
                olDialog.messageOrText('#sponsorMainSponsorHeidelpayCharge2Exception');
                orderPaymentFinished.call(this, 'UNFINISHED');
            }.bind(this));

            
            
        } else if (state === 'CANCELED') {
            olDialog.messageOrText('#sponsorMainSponsorHeidelpayStatusCanceled');
            this.disableNext(false);

        } else if (state == 'UNFINISHED') {
            // error message already shown
            this.disableNext(false);

        } else {//(state === 'ERROR'
            olDialog.messageOrText('#sponsorMainSponsorHeidelpayStatusError');
            this.disableNext(false);
        }
    }


    // Heidelpay form submit
    var hp = {

        _options: {},
        _dfltOptions: {
            paymentType: '',
//            paymentAmount: 0, // hier gar nicht nötig!
            dataId: '', //'mainsponsorAssistantHeidelpayData'
            onsuccess: null,
            onerror: null,
//            assistant: null,
            locale: 'de-DE' //TODO
        },
        _hp: null,
        _hpType: null,

        _init: function(options) {
            this._options = Object.assign({}, this._dfltOptions, options);

            if (!this._paramsByType[this._options.paymentType]) return false;
//            if (this._options.paymentAmount <= 0) return false;
            if (!this._options.dataId) return false;
            if (!this._options.publicKey) return false;
            
            if (typeof this._options.onsuccess !== 'function') this._options.onsuccess = function() {};
            if (typeof this._options.onerror !== 'function') this._options.onerror = function() {};
            //if (!this._options.assistant) return false;
            if (!this._options.locale) this._options.locale = 'de-DE';

            if (!this._options.recurring) this._options.recurring = false;

            this._hp = null;
            this._hpType = null;

            return true;
        },

        prepare: function(options) {
            try {
                if (!this._init(options)) return false;

                // Get parameters by Type
                var params = this._paramsByType[this._options.paymentType];
                if (!params) return false;

                if (this._options.paymentType == 'FREE') {
                    this._hp = null;
                    this._hpType = 'FREE';
                    return true;
                } else if (this._options.paymentType == 'OFAINVOICE') {
                    this._hp = null;
                    this._hpType = 'OFAINVOICE';
                    return true;
                }

                // Create heidelpay instance
//                this._hp = new heidelpay(this[params.publicKey], {locale: this._options.locale});
                this._hp = new heidelpay(this._options.publicKey, {locale: this._options.locale});

//if(!this._hp.isSandbox){alert('ACHUNG: Keine Sandbox!!!!!!!!');return false;}//DBG

                // Create the payment type instance
                this._hpType = this._hp[params.cls]();

                // Render the input fields
                var fields = params.fields;
                for (var i = 0;  i < fields.length;  i++) {
                    var field = fields[i];
                    this._hpType.create(field, {
                        containerId: this._options.dataId+'-'+field,
                        onlyIframe: false, //with styling
//                        onlyIframe: true, //without styling
                        fontSize: '10pt',//'13pt',
                        fontColor: 'black',
                        fontFamily: 'Exo 2, sans-serif'
                    });
                }

                // Done
                return true
            } catch (ex) {
                console.log('*** Error preparing heidelpay:', ex);
                return false;
            }
        },

        submit: function(onsuccess, onerror) {
            try {
                if (this._hpType == 'FREE') {
                    var result = {id: 'x-fre-e', method: 'free', recurring: this._options.recurring};
                    if (DEBUG) console.log('FREE Success', result);
                    if (typeof onsuccess === 'function') onsuccess(result);
                    return;
                } else if (this._hpType == 'OFAINVOICE') {
                    var result = {id: 'x-inv-e', method: 'ofainvoice', recurring: this._options.recurring};
                    if (DEBUG) console.log('OFAINVOICE Success', result);
                    if (typeof onsuccess === 'function') onsuccess(result);
                    return;
                }
                
                if (this._options.continueData) {
                    var result = this._options.continueData;
                    if (DEBUG) console.log('CONTINUE Success', result);
                    if (typeof onsuccess === 'function') onsuccess(result);
                    return;
                }

                this._hpType.createResource()
                .then(function(result) {
                    // TODO: Success: Submit result.id to the server
                    if (DEBUG) console.log('HP Success:', result);
                    result.recurring = this._options.recurring;
                    if (DEBUG) console.log('result*:', result);
                    if (typeof onsuccess === 'function') onsuccess(result);
                }.bind(this)).catch(function(error) {
                    // TODO: Handle errors
                    console.log('HP Error:', error);
                    if (typeof onerror === 'function') onerror(error);
                }.bind(this));
                return true;
            } catch (ex) {
                console.log('*** Error preparing heidelpay:', ex);
                return false;
            }
        },

        _paramsByType: {
            PAYPAL: {
                fields: [],
                cls: 'Paypal'
            },
            CREDITCARD: {
                fields: ['number', 'expiry', 'cvc'],
                cls: 'Card'
            },
            INVOICE: {
                fields: [],
                cls: 'InvoiceGuaranteed'
            },
            DEBIT: {
                // normal
                fields: ['sepa-direct-debit'],
                cls: 'SepaDirectDebit' //TODO
//                // guaranteed
//                fields: ['sepa-direct-debit-guaranteed'],
//                cls: 'SepaDirectDebitGuaranteed' //TODO
            },
            SOFORT: {
                fields: [],
                cls: 'Sofort'
            },
            FREE: {
                fields: [],
                cls: ''
            },
            OFAINVOICE: {
                fields: [],
                cls: ''
            }
        }
    };


    // External window/tab for redirect
    var xframeWin = {

        _window: null,
        _mode: '',
        _onclose: null,
        _listening: false,
        _timer: null,

        // close with state `rc`
        close: function(rc) {
            if (!this._window) return;
            this._window.close();
            //this._window.close();
            var win = this._window; setTimeout(function() {if (win) win.close();}, xframeCloseTimeout); // Zur Sicherheit
            this._window = null;
            this._stopTimer();
            var cb = this._onclose;
            this._onclose = null;
            if (!cb) return;
            cb(rc);
        },

        open: function(url, mode, onclose) {
            if (mode === undefined) mode = '';
            this._mode = mode;
            if (typeof onclose === 'function') this._onclose = onclose;
            if (!this._listening) {
                window.addEventListener('message', this._onmessage.bind(this));
                this._listening = true;
            }
            this._window = window.open(url, 'xframe');
            this._startTimer(statusCheckTimeout);
            if (DEBUG) console.log('xframeWin:', this);
        },

        _startTimer: function(ms) {
            if (DEBUG) console.log('xframeWin._startTimer('+ms+')', this._timer);
            if (this._timer) this._stopTimer();
            this._timer = setTimeout(this._oncheck.bind(this), ms);
            if (DEBUG) console.log('xframeWin.: started timer', this._timer);
        },

        _stopTimer: function() {
            if (DEBUG) console.log('xframeWin._stopTimer', this._timer);
            if (!this._timer) return
            clearTimeout(this._timer);
            this._timer = null;
        },

        _oncheck: function() {
            if (DEBUG) console.log('xframeWin._oncheck', premiumData);
            this._checkStatus(false);
        },

        // receives message from window
        _onmessage: function(event) {
            if (DEBUG) {
                console.log('xframeWin._onmessage:', event);
                console.log('- origin:', event.origin);
                try {
                    console.log('- source:', event.source);
                } catch (ex) {
                    console.log('- source: (blocked)');
                }
                console.log('- data:', event.data);
            }

            // Are we in the correct phase?
            var assistant = $('#olDialogParam').data('assistant');
            if (!assistant || assistant.step != HEIDELPAY) {
                console.log('*** xframeWin._onmessage: message at invalid step '+(assistent? assistent.step : 0));
                return;
            }

            // Is the message from the right origin?
            var allowedOrigins = assistant.data.allowedOrigins;
            if (allowedOrigins.indexOf(event.origin) < 0) {
                console.log('*** xframeWin._onmessage: message from forbidden domain '+event.origin);
                return;
            }

            // Is the message in the right format?
//            if (typeof event.data !== 'object' || event.data.id != assistant.data.invoiceId) {
            if (typeof event.data !== 'object' || event.data.id != assistant.data.orderId) {
                console.log('*** orderMessageReceive: invalid message data:', event.data);
                return;
            }

            // Handle result
            var success = (event.data.status.toLowerCase() == 'successful');
            var c = event.data.c;
            assistant.data.redirectResult = event.data;
            if (success) {
                this.close(this._mode || 'SUCCESS'); // CHARGE2 | SUCCESS
            } else if (this._mode) {
                this.close(this._mode); // CHARGE2
                //TODO How do I get canceled state?
            } else {
                // Status not clear => check status explicitly
                this._checkStatus(true);
//                var data = {transactionId: assistant.data.paymentTransactionId};
//                $.post('sponsor/sponsoring/mainsponsor/assistant/pay/status', data, function(rc) {
//                    if (DEBUG) console.log('xframeWin._onmessage: hp status:', rc);
//                    if (rc.stateName == 'SUCCESS') {
//                        this.close('SUCCESS');
//                    } else if (rc.stateName == 'CANCELED') {
//                        this.close('CANCELED');
//                    } else {
//                        this.close('ERROR');
//                    }
//                }.bind(this));
            }
        },
        
        _checkStatus: function(closeOnError) {
            var assistant = $('#olDialogParam').data('assistant');
            if (DEBUG) console.log('xframeWin._checkStatus('+closeOnError+') //'+(assistant? assistant.data.paymentTransactionId : '?'));
            if (!assistant.data.paymentTransactionId) return;
            
            // Canceled?
            if (assistant.data.paymentState == 'CANCELED') {
                this._stopTimer();
                return;
            }
            
            // Check status explicitly
            var data = {
                transactionId: assistant.data.paymentTransactionId,
                alsoPending: closeOnError? 0 : 1
            };
            $.post('/sponsor/sponsoring/mainsponsor/assistant/pay/status', data, function(rc) {
                if (DEBUG) console.log('xframeWin._checkStatus: hp status:', rc);
                if (rc.stateName == 'SUCCESS') {
                    this.close('SUCCESS');
                } else if (rc.stateName == 'CANCELED') {
                    this.close('CANCELED');
                } else if (rc.stateName == 'PENDING') {
                    // wait and check again
                    this._startTimer(statusCheckInterval);
                } else {
                    if (closeOnError) this.close('ERROR');
                }
            }.bind(this));            
        }

    };


    // assistant dialog helpers

    olSponsorSponsoring.onSelectPaymentType = function(event) {
        var $radio = $(event.target),
            type = $radio.val(),
            disabled = $radio.prop('disabled'),
            assistant = $('#olDialogParam').data('assistant');
        if (disabled) return;
        assistant.data.paymentType = type;
        assistant.disableNext(!type);
    };

    olSponsorSponsoring.onSelectPaymentTypeLabel = function(event) {
        var $element = $(event.target),
            $radio = $element.closest('.ol-sponsor-mainsponsor-assistant-payment-type').find('input.ol-sponsor-data-radio-input');
        $radio.click();
    };


    olSponsorSponsoring.onClickAcceptCheckbox = function(event) {
        var $element = $(event.target),
            key = $element.val(),
            $label = $element.closest('.ol-sponsor-mainsponsor-assistant-checkbox-label'),
            assistant = $('#olDialogParam').data('assistant');
        var checked = $element.prop('checked');
        $label.toggleClass('checked', checked);

        assistant.data[key] = checked;
        assistant.disableNext(!checked);
    };

    olSponsorSponsoring.onClickAcceptCheckboxLabel = function(event) {
        var $element = $(event.target),
            $checkbox = $element.prev().find('input[type="checkbox"]');
        $checkbox.click();
    };


    olSponsorSponsoring.gotoPayment = function(event) {
        var $btn = $(event.target),
            assistant = $('#olDialogParam').data('assistant');

        $btn.addClass('ol-button-loading');
        assistant.goto(PAYMENT);
    }



    //--- Sponsoring > Ligavermarktung ---

    olSponsorSponsoring.initStadiumMarketer = function() {
        olSponsorSponsoring.page = 'stadiummarketer';
    };



    //--- Sponsoring > Liga-Sponsor ---

    olSponsorSponsoring.initLeagueSponsor = function() {
        olSponsorSponsoring.page = 'leaguesponsor';
    };

}(window.olSponsorSponsoring = window.olSponsorSponsoring || {}, jQuery));
