/*
    Partner Specific Logic for Himedia
*/
(function(self, $, undefined) {
    self.init = function() {
        /*
            Headerlift/IDBH Event
        */
        console.log("* Ads Debug | Adding Event Listener to IDHB", idhb);
        idhb.addEventListener('slotRenderEnded', function(e) {
            console.log('* Ads Debug | slotRenderEnded', e);
            if (e.isEmpty === true) {
                // No ad available for this slot
                console.log('* Ads Debug | slotRenderEnded results empty "'+ e.slotId + '"');
                olAds.handleFallback(e.slotId);
            } else {
                // Special case: SKIN slot
                console.log("* Ads Debug | slotId", e.slotId);
                if (e.slotId == "ax-skin-idhb") {
                    $('body').addClass('ol-ad-skin');
                    skinActive = true;
                }

                logAdRender(e.slotId);
            }
        });

        // Himedia SKIN config
        window.hmAdParameters = {
            "templates": {
                "skinban": {
                    "targetId": ".ol-page-wrap-helper",
                    "targetSite": ".ol-root",
                    "style": {
                        "zIndex": 2
                    }
                }
            }
        }
    };

    // Prepares a slot to load an ad, empties the slot if there is already something loaded
    self.prepareSlot = function(slot) {
        var childContainerId, slotId;

        // Check if slot is displayed
        if (slot[0].offsetHeight == 0 && slot[0].offsetWidth == 0) {
            console.log("* Ads Debug | Skipping slot", slot[0], "because it is not rendered.");
            return null;
        }

        if (slot.hasClass('ax-container-endless')) {
            childContainerId = slot.data('name') + "-n-idhb";
        } else {
            slotId = slot.attr('id');
            childContainerId = slotId + "-idhb";
        }

        var deviceSize = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        if (deviceSize < 768) {
            childContainerId += "-m"
        } else if (deviceSize <= 991) {
            childContainerId += "-t"
        }

        if (slot.hasClass('ax-container-endless')) {
            var tagIndex = slot.data("index");
            childContainerId += "@" + tagIndex;
        }

        if (slotId == "ax-skin") {
            skinActive = false;
            $('body').removeClass('ol-ad-skin');
            $('#weborama_pushdown_div').remove();
        }

        slot.html("<div id='" + childContainerId + "'></div>");

        return childContainerId;
    };

    // Logs a successful adRender in LocalStorage
    function logAdRender(slotId) {
        var logEntry = {}
        logEntry.slotId = slotId;
        logEntry.time = new Date();
        logEntry.uid = olGUI.getUid();

        var slotData = pbjsidhb.getHighestCpmBids(slotId);

        if (slotData.length == 0) return;

        slotData = slotData[0];

        logEntry.bidder = slotData.bidder;
        logEntry.adId = slotData.adId;
        logEntry.meta = slotData.meta;
        logEntry.creativeId = slotData.creativeId;

        var existingLog = JSON.parse(localStorage.getItem(olAds.LOCAL_STORAGE_LOG_NAME));
        if (!existingLog) {
            existingLog = [];
        }
        existingLog.unshift(logEntry);
        if (existingLog.length > config.maxLogEntries) {
            //a.length = config.maxLogEntries;
            existingLog = existingLog.slice(0, config.maxLogEntries);
        }

        localStorage.setItem(olAds.LOCAL_STORAGE_LOG_NAME, JSON.stringify(existingLog));
    }

    self.canShowAds = function() {
        return idhb && idhb.requestAds;
    };


    self.getSpecialSlots = function(slotIds) {
        return slotIds.filter(function(slotId) {
            return adGroups.flat(2).includes(slotId);
        });
    }


    self.renderAds = function(slotIds) {
        // Requests and renders ads in the given slotIds
        return new Promise(function(resolve, reject) {
            if (!slotIds || slotIds.length == 0) {
                console.info("* Ads Debug | requestIDHB | No slots to request");
                resolve("no slots");
                return;
            }

            idhb.addEventListener('slotRenderEnded', function() { resolve("slot render ended"); }, { once: true });

            console.log("* Ads Debug | Request ", slotIds);
            idhb.que.push(function() {
                idhb.requestAds({
                    slotIds: slotIds
                });
            });
        });
    }

    function renderIDHB(slotIds) {
        return new Promise(function(resolve, reject) {
            if (!slotIds || slotIds.length == 0) {
                console.info("* Ads Debug | renderIDHB | No slots to render");
                resolve("no slots");
                return;
            }

            idhb.addEventListener('slotRenderEnded', function() { resolve("slot render ended"); }, { once: true });

            console.log("* Ads Debug | renderIDHB | Rendering ", slotIds);
            idhb.que.push(function() {
                idhb.renderAds({
                    slotIds: slotIds
                });
            });
        });
    }

    self.renderSpecialAds = function(specialSlots) {
        prefetchIDHB(specialSlots).then(function(prefetch) {
            // Only use slots that are registered in the library
            newSlots = specialSlots;
            console.log("* Ads Debug | Special Slots after prefetch:", newSlots);
            var resolve = prefetch.callback; // CK 14.06.22 was missing

            if (!newSlots) {
                console.log("* Ads Debug | No prefetch result, aborting:", prefetch);
                resolve("no prefetch"); // CK 14.06.22 lead to exception because resolve was not defined here
                return;
            }

            // Filter out slots that are mutually exclusive
            newSlots = compareAdGroups(newSlots, prefetch);
            console.log("* Ads Debug | New Slots after CPM compare:", newSlots);

            renderIDHB(newSlots).then(resolve); // CK 14.06.22 lead to exception because resolve was not defined here

        }).catch(function(reason) {
            console.info("* Ads Debug | Cannot retrieve Ad prices:", reason);
        });
    }

    var prefetchedSlots = [];
    var renderingPrefetched = false;

    // Sets slots that are exclusive with each other
    // e.g. When a SKIN is loaded, don't fill the top/left/right slots
    var adGroups = [
        [
            ["ax-billboard-top-idhb", "ax-skyscraper-left-idhb", "ax-skyscraper-idhb"],
            ["ax-skin-idhb"]
        ],
        [
            ["ax-billboard-top-idhb-m", "ax-skyscraper-left-idhb-m", "ax-skyscraper-idhb-m"],
            ["ax-skin-idhb-m"]
        ]
    ];


    // For groups of exclusive ad slots, compare the CPM of each group and remove the slots that expect less revenue
    function compareAdGroups(slotIds, prefetch) {
        for (var i1 = 0; i1 < adGroups.length; i1++) {
            var group = adGroups[i1];
            var includesA = false;
            var includesB = false;

            // Check if both groups are included in the set of slots we're refreshing
            for (var i2 = 0; i2 < slotIds.length; i2++) {
                var slot = slotIds[i2];
                if (group[0].includes(slot)) {
                    includesA = true;
                }
                if (group[1].includes(slot)) {
                    includesB = true;
                }

                if (includesA && includesB) break;
            }

            // If we have slots that are in opposite groups, check each group's CPM and filter out the lesser group
            if (includesA && includesB) {
                var cpmA = 0;
                var cpmB = 0;

                for (var i3 = 0; i3 < group[0].length; i3++) {
                    var slot = group[0][i3];
                    if (prefetch.slots[slot] && prefetch.slots[slot].hbCpm) {
                        cpmA += prefetch.slots[slot].hbCpm;
                    }
                }
                for (var i4 = 0; i4 < group[1].length; i4++) {
                    var slot = group[1][i4];
                    if (prefetch.slots[slot] && prefetch.slots[slot].hbCpm) {
                        cpmB += prefetch.slots[slot].hbCpm;
                    }
                }

                console.info("* Ads Debug | CPM for group ", group[0], ":", cpmA);
                console.info("* Ads Debug | CPM for group ", group[1], ":", cpmB);


                var pickA = true;
                if (cpmA == cpmB) {
                    if (cpmA > 0) {
                        pickA = Math.round(Math.random());
                    } else if (cpmB > 5) {
                        pickA = false;
                    }
                } else if (cpmA < cpmB) {
                    pickA = false;
                }

                if (pickA) {
                    slotIds = slotIds.filter(function(element) {
                        return !group[1].includes(element);
                    });
                } else {
                    slotIds = slotIds.filter(function(element) {
                        return !group[0].includes(element);
                    });
                }
            }
        }

        return slotIds;
    }

    // Clears the specified slots and fetches ad data
    function prefetchIDHB(slotIds) {
        return new Promise(function(resolve, reject) {
            if (!slotIds || slotIds.length == 0 ) {
                console.info("* Ads Debug | prefetchIDHB | No slots to load");
                resolve({slotIds: [], prefetch: [], callback: resolve}); //CK 14.06.22 added callback field
                return;
            }

            // Fetch data for new slots
            console.info("* Ads Debug | prefetchIDHB | Prefetching slots ", slotIds);

            idhb.que.push(function() {
                idhb.requestAds({
                    slotIds: slotIds,
                    prefetchOnly: true,
                    callback: resolve
                });
            });
        })
    }

})(olAdProviderHimedia = window.olAdProviderHimedia || {}, jQuery);
