var mapIntegration = new (function($) {
	"use strict";
	var self = this;
	var mapRoot, container, loadingOverlay, tutorialContainer, qualityElement;
	var mapShowReady = false;
	var mapIntroReady = false;
	var mapIntroPlayed = false;
	var mapIntroFinished = false;
	var overlayAlwaysWaitTime = 1000;
	var overlayFadeOutTime = 1000;
	var userDetails, connectionDetails;
	var userDetailsShouldBeVisibleAfterIntro = false;
	var shouldAnimateFor = "";
	var _currentSeason = -1;
	var defaultTilt, defaultZoomUser, defaultZoomGermany, defaultPositionGermany, defaultFOVPortraitCorrectionFactor;
	var currentUserScreenOffsetX, currentUserScreenOffsetY, currentConnectionScreenOffsetX, currentConnectionScreenOffsetY, currentUserDetailsHeightAndMargin, currentConnectionDetailsHeightAndMargin, currentUserDetailsMaxPadding, currentConnectionDetailsMaxPadding;
	var qualitySettingStorageKey = 'ol-map-quality';
	var qualitySettingTryStorageKey = 'ol-map-quality-try';
	var qualitySettingValue = 0;
	var qualitySettingTryActive = false;
	var qualitySettingIsAuto = true;
	var qualitySettingAutoMinBadValue = 0;
	var qualitySettingAlreadyAskedGood = false;
	var qualitySettingAlreadyAskedBad = false;
	var refreshUserDataIntervalMinutes = 30;
	var animationDefaultLength = 2.0;	// seconds (use with autoDuration)
	var inputTutorialCurrentStep = 0;
	var inputTutorialFinishedStorageKey = 'ol-map-inputtutorial';
	var delayedShowCalls = [];
	var defaultConnections = [];
	
	this.selectedUserId = -1;
	this.selectedLeagueId = -1;

	var sidebarElement, searchInputElement, searchSuggestionElement, searchResultElement, accordionElement;
	var lastAjaxXHRSearch = null;
	var lastAjaxSearchValue = "";
	var lastAjaxXHRUserInfo = null;
	var lastAjaxXHRRelationAccess = null;
	
	this.useDebugLayer = false;


	var abortAjaxSearch = function() {
//			console.log("abortAjaxSearch()");
		if (lastAjaxXHRSearch !== null) {
			lastAjaxXHRSearch.abort();
		}
	};
	var doAjaxSearch = function(value, detailed, startFrom) {
//			console.log("doAjaxSearch()", value, detailed);
		var currentAjaxSearchValue = value+(detailed? "D"+startFrom : "");
		if (!detailed && (lastAjaxSearchValue == currentAjaxSearchValue)) {	// prevent duplicate searches
			return;
		}
		abortAjaxSearch();	
		if (detailed) {
			abortAjaxSearchDelayed();
			searchSuggestionElement.empty();
			searchResultElement.addClass("loading");
			if (startFrom > 0) {
				addLoadingAnimationToElement($('<div class="loadingplaceholder" style="height:80px;"></div>').appendTo(searchResultElement), {size:"small", overlay:true});
			} else {
				addLoadingAnimationToElement(searchResultElement, {size:"small", overlay:true});
			}
			sidebarElement.removeClass("sidebar").addClass("open searchresults");
		} else {
			lastAjaxSearchValue = currentAjaxSearchValue;
			searchSuggestionElement.html("<p>&nbsp;</p>");
			addLoadingAnimationToElement(searchSuggestionElement, {size:"small", overlay:true});
			startFrom = 0;
		}
		var sendData = {
				d: (detailed? 1 : 0),
				q: value
		};
		if (startFrom > 0) {
			sendData['s'] = startFrom;
		}
		if (self.selectedLeagueId > 0) {
			sendData.l = self.selectedLeagueId;
		}
		if (self.selectedUserId > 0) {
			sendData.u = self.selectedUserId;
		}
		lastAjaxXHRSearch = $.ajax({
			type: 'GET',
			url: "/map/ajax-search",
			data: sendData,
			success: function(ajaxData) {
				if (detailed) {
					if (startFrom > 0) {
						searchResultElement.append(ajaxData);
					} else {
						searchResultElement.html(ajaxData);
					}
				} else {
					searchSuggestionElement.html(ajaxData);
				}
			},
			error: function() {
				if (detailed) {
//					searchResultElement.html("<p>Error while fetching data</p>");
					searchResultElement.empty();
				} else {
					searchSuggestionElement.empty();
				}
			},
			complete: function() {
				if (detailed) {
					searchInputElement.blur();
					if (startFrom > 0) {
						removeLoadingAnimationFromElement(searchResultElement.children(".loadingplaceholder"));
						searchResultElement.children(".loadingplaceholder").remove();
					} else {
						removeLoadingAnimationFromElement(searchResultElement);
					}
					searchResultElement.removeClass("loading");
				} else {
					removeLoadingAnimationFromElement(searchSuggestionElement);
				}
				lastAjaxXHRSearch = null;
//				console.log("completed", value, detailed);
			}
		});	
//		console.log("started", value, detailed);
	};

	var ajaxSearchDelayedTimeoutId = undefined;
	var doAjaxSearchDelayed = function(value, detailed) {
//			console.log("doAjaxSearchDelayed()", value, detailed);
		abortAjaxSearchDelayed();
		ajaxSearchDelayedTimeoutId = window.setTimeout(function() {
			doAjaxSearch(value, detailed);
		}, 250);
	};
	var abortAjaxSearchDelayed = function() {
//			console.log("abortAjaxSearchDelayed()");
		if (ajaxSearchDelayedTimeoutId !== undefined) {
			window.clearTimeout(ajaxSearchDelayedTimeoutId);
		}
		abortAjaxSearch();
	};
	var clearSearchSuggestion = function() {
//			console.log("clearSearchSuggestion()");
		lastAjaxSearchValue = "";
		searchSuggestionElement.empty();
	};



		
	var addLoadingOverlay = function() {
		loadingOverlay.finish().show().addClass("ol-page-loading-fade-in");
	};

	var removeLoadingOverlay = function() {
		loadingOverlay.finish().removeClass("ol-page-loading-fade-in");//.delay(1100).hide(0);
		window.setTimeout(function() {
			loadingOverlay.remove();
		}, 1100);
	};

	var playMapIntro = function() {
		if (mapIntroReady && mapShowReady) {
			removeLoadingOverlay();
			if (!mapIntroPlayed) {
				window.setTimeout(function() {
					self.map.playIntro();
				}, overlayFadeOutTime);
			}
			mapIntroPlayed = true;
		}
	};

	var animateOrReplaceIntro = function(animation) {
		if (!self.map) return;
		if (mapIntroPlayed) {
			var centerView = {duration:animation.duration, tilt:animation.tilt, yaw:animation.yaw};
			if (animation.x !== undefined || animation.y !== undefined) centerView['center'] = [animation.x, animation.y];
			if (animation.z !== undefined) centerView['zoom'] = animation.z;
			if (animation.radius !== undefined) centerView['radius'] = animation.radius;
			if (animation.autoDuration !== undefined) centerView['autoDuration'] = true;
			if (animation.screenOffsetX !== undefined) centerView['screenOffsetX'] = animation.screenOffsetX;
			if (animation.screenOffsetY !== undefined) centerView['screenOffsetY'] = animation.screenOffsetY;
			if (animation.useCenterOffset!==undefined) centerView['useCenterOffset'] = animation.useCenterOffset;
			self.map.setConfig({centerView: centerView});
		} else {
			self.map.setConfig({introAnimation:[animation]});
		}
	};
	
	var loadUserInfo = function(userId, placeholderName) {
		if (lastAjaxXHRUserInfo !== null) {
			if (self.selectedUserId == userId) {
				console.log("userInfo for uid already loading: ", userId);
				return;
			}
			self.selectedUserId = userId;
			lastAjaxXHRUserInfo.abort();
		} else {
			self.selectedUserId = userId;
		}
		if (placeholderName) {
			userDetails.html('<div class="badge ol-page-loading-small"></div><div class="content"><h3>'+placeholderName+'</h3><p>&nbsp;</p></div>');
			if (mapIntroFinished) {
				userDetails.show();
				connectionDetails.hide();
			}
			userDetailsShouldBeVisibleAfterIntro = true;
		} else {
			userDetails.hide();
			userDetailsShouldBeVisibleAfterIntro = false;
		}
		
//		window.setTimeout(function() {	// add with slight delay to avoid flashes // momentan nicht mehr nötig, hat eingebautes Delay
//			if (lastAjaxXHRUserInfo !== null) {
				addLoadingAnimationToElement(accordionElement, {size:"small", overlay:true});
//			}
//		}, 250);
		lastAjaxXHRUserInfo = $.ajax({
			type: 'GET',
			url: "/map/ajax-userinfo",
			dataType: 'json',
			data: {
				id: userId
			},
			success: function(ajaxData) {
//					console.log(ajaxData);
				self.map.updateSelectedUserPosition(ajaxData.userPositionX, ajaxData.userPositionY);
				userDetails.html(ajaxData.htmlDetail);
				if (mapIntroFinished) {
					userDetails.show();
				}
				userDetailsShouldBeVisibleAfterIntro = true;
				if (shouldAnimateFor == "user"+userId) {
					animateOrReplaceIntro({x:ajaxData.userPositionX, y:ajaxData.userPositionY, z:defaultZoomUser, duration:animationDefaultLength, tilt:defaultTilt, yaw:0, autoDuration:true, screenOffsetX:currentUserScreenOffsetX, screenOffsetY:currentUserScreenOffsetY});
					shouldAnimateFor = "";
				}
				// offenen Tab merken
				var openPanelId = '';
				var openPanelCheckboxId = '';
				var openPanel = accordionElement.find(".panel-collapse.in");
				var openPanelCheckbox;
				if (openPanel.length > 0) {
					openPanelId = openPanel.get(0).id;
					openPanelCheckbox = openPanel.find("input:checked");
					if (openPanelCheckbox.length > 0) {
						openPanelCheckboxId = openPanelCheckbox.get(0).id;
					}
				}
				accordionElement.html(ajaxData.htmlSidebarAccordion);
				// offenen Tab wiederherstellen
				if (openPanelId.length > 0) {
					var panelToOpen = accordionElement.find("#"+openPanelId).first();
					if (!panelToOpen.hasClass("in")) {
						openPanel = accordionElement.find(".panel-collapse.in");
						openPanel.collapse('hide');
						panelToOpen.collapse('show');
					}
					if (openPanelCheckboxId.length > 0) {
						openPanelCheckbox = panelToOpen.find("input#"+openPanelCheckboxId).first();
						if (openPanelCheckbox.length > 0) {
							openPanelCheckbox.prop("checked", true);
						} else {
							panelToOpen.data("lastCheckedCheckboxId", openPanelCheckboxId);	// vielleicht wird Panel Inhalt noch geladen, also erst mal merken...
						}
					}
				}
				if (ajaxData.defaultConnections !== undefined) {
//					console.log("new defaultConnections");
					defaultConnections = ajaxData.defaultConnections;
					toggleRelationDisplay();
				}
				if (ajaxData.leagueId && (ajaxData.leagueId != self.selectedLeagueId)) {
					self.selectedLeagueId = ajaxData.leagueId;
					self.map.setConfig({showLeagues:[ajaxData.leagueId]});
				}
				updateShortInfoDisplay();
			},
			error: function() {
				if (self.selectedUserId == userId) {
					console.log("Could not load user info, unselect user");
					self.showUser();
				} else {
					console.log("Could not load user info, other user selected");
				}
			},
			complete: function() {
				removeLoadingAnimationFromElement(accordionElement);
				lastAjaxXHRUserInfo = null;
			}
		});
	};
	
	var updateCheckboxClass = function() {
		var t = $(this);
		t.closest(".ol-checkbox").toggleClass("ol-checkbox-checked", t.is(":checked"));
	};

	var toggleRelationDisplay = function() {
//		console.log("toggleRelationDisplay()");
		var connectionsToShow = [];
		var relationsPanel = $("#ol-map-sidebar-accordion .relations.panel").first();
		var relationsPanelTitle = relationsPanel.find("a.ol-panel-collapse-title").first();
//		if (relationsPanelTitle.length < 1 || relationsPanelTitle.hasClass("collapsed")) {
//			console.log("relations panel is collapsed");
//			return;
//		}
		relationsPanel.find(".panel-collapse .ol-map-relation-details:visible").slideUp();
		var hasCheckedRadios = false;
		if (relationsPanelTitle.length > 0 && !relationsPanelTitle.hasClass("collapsed")) {
			relationsPanel.find(".panel-collapse input.ol-radio:checked").each(function() {
				hasCheckedRadios = true;
				var t = $(this);
				var detailsRow = t.closest(".row").next(".row").first().filter(".ol-map-relation-details");
				detailsRow.filter(":hidden").slideDown();
				var connectionData = t.data("resultConnections");
				if (!connectionData) {
					connectionData = t.data("connections");
				}
				if (connectionData) {
					connectionsToShow = connectionData;
					return false;	// nur eins möglich
				} else {
					console.log("no connection data found", t);
					return false;
				}
			});
		}
		if (connectionsToShow.length < 1) {
			connectionsToShow = defaultConnections;
		}
		if (!hasCheckedRadios) {
			relationsPanel.find(".ol-map-relationsearch input").val("").trigger("change");	// will not set connection config because nothing is checked
		}
//		console.log("active connections: ", connectionsToShow);
		self.map.setConfig({showConnections: connectionsToShow});
		if (self.selectedUserId > 0) {
			userDetails.show();
		}
		connectionDetails.hide();
		updateShortInfoDisplay();
	};
	
	var loadTryQualitySettingFromBrowser = function() {
		/**
		 * 
		 * @param {int} settingValue 0: auto, 1: low, 2: medium, 3: high
		 * @param {Boolean} fromAuto
		 * @param {Boolean} autoDown coming from higher setting?
		 * @returns {Boolean}
		 */
		var tryQualitySetting = function(settingValue, fromAuto, autoDown) {
			if (!self.map && (settingValue <= 3) && (settingValue >= 0)) {
				qualitySettingTryActive = true;
				if (settingValue == 0) {
					qualitySettingValue = getDefaultQualitySettingValue();
					qualitySettingIsAuto = true;
				} else {
					qualitySettingValue = settingValue;
					qualitySettingIsAuto = (fromAuto==true);
					if (qualitySettingIsAuto) {
						if (autoDown) {
							qualitySettingAutoMinBadValue = (qualitySettingAutoMinBadValue>0)? Math.min(qualitySettingValue+1, qualitySettingAutoMinBadValue) : qualitySettingValue+1;
						}
					} else {
						// Forget min bad quality setting when manually trying a setting
						qualitySettingAutoMinBadValue = 0;
					}
				}
				return true;
			} else {
				return false;
			}
		};

		try {
			var tryRawSetting = window.localStorage.getItem(qualitySettingTryStorageKey);
			if (tryRawSetting !== null) {
				window.localStorage.removeItem(qualitySettingTryStorageKey);
				var trySetting = JSON.parse(tryRawSetting);
				if (tryQualitySetting(trySetting.quality, trySetting.auto, trySetting.autoDown)) {
					return true;
				}
			}
		} catch(e) {}
		return false;
	};
	
	var loadQualitySettingFromBrowser = function() {
		try {
			var userQualitySetting = JSON.parse(window.localStorage.getItem(qualitySettingStorageKey));
			var qualityValue = parseInt(userQualitySetting.quality, 10);
			if (isNaN(qualityValue)) {
				return null;
			}
			var autoMinBadQualityValue = parseInt(userQualitySetting.autobadquality, 10);
			if (isNaN(autoMinBadQualityValue)) {
				autoMinBadQualityValue = 0;	// unset
			}
			return {quality:qualityValue, auto:(userQualitySetting.auto==true), autobadquality:autoMinBadQualityValue};
		} catch(e) {}
		return null;
	};
	
	var saveQualitySettingToBrowser = function() {
		try {
			window.localStorage.setItem(qualitySettingStorageKey, JSON.stringify({
				auto:qualitySettingIsAuto,
				quality:qualitySettingValue,
				autobadquality:qualitySettingAutoMinBadValue
			}));
			return true;
		} catch(e) {}
		return false;
	};

	var tryNewQualitySetting = function(settingValue, settingIsAuto, settingIsAutoDown) {
		try {
			window.localStorage.setItem(qualitySettingTryStorageKey, JSON.stringify({
				auto:(settingIsAuto===true),
				autoDown:(settingIsAutoDown===true),
				quality:settingValue
			}));
			window.location.reload();
			return true;
		} catch(e) {}
		return false;
	}
	
	var updateQualitySettingDisplay = function() {
		var currentSetting = (qualitySettingIsAuto? 0 : qualitySettingValue);
		var minifiedCaption = '?';
		qualityElement.children(".maximized").children().each(function() {
			var t = $(this);
			var s = t.data("setting");
			if (s == qualitySettingValue) {
				minifiedCaption = t.text();
			}
			t.toggle((s != currentSetting));
		});
		qualityElement.children(".minimized").html(qualitySettingIsAuto? "auto<br>("+minifiedCaption+")": minifiedCaption);
	};
	
	var getDefaultQualitySettingValue = function() {
		return olGUI.isMobileOrTabletDevice()? 2 : 3;	// mobile -> medium, other -> high
	};
		
	var checkAndAutoChangeMapConfig = function(mapConfig) {
		// some checks based on: https://github.com/mrdoob/three.js/blob/master/examples/js/Detector.js
		// check basic canvas support
		if (! window.CanvasRenderingContext2D) {
			return 1;
		}
		var testCanvas, testGLContext;
		// check webgl support
		if (!(function() {
				try {
					testCanvas = document.createElement( 'canvas' );
					return !! ( window.WebGLRenderingContext && ( (testGLContext = testCanvas.getContext( 'webgl' )) || (testGLContext = testCanvas.getContext( 'experimental-webgl' )) ) );
				} catch ( e ) {
					return false;
				}
			})()) {
			LigaMap.console.warn("Hardware check error: Could not create gl canvas object");
			return 2;
		};
		// check some webgl features
		if (!testGLContext || !testGLContext.getSupportedExtensions) {
			LigaMap.console.warn("Hardware check error: No GL context");
			return 3;
		}
		var extensions = testGLContext.getSupportedExtensions();
		var extensionsToCheck = Array(
				'ANGLE_instanced_arrays'
//				'OES_texture_float',
//				'OES_texture_float_linear',
//				'OES_texture_half_float',
//				'OES_texture_half_float_linear',
//				'OES_element_index_uint'
        );
		var n = 0;
		for (var i in extensionsToCheck) {
			if (extensions.indexOf(extensionsToCheck[i]) < 0) {
				LigaMap.console.warn("Hardware check error: Missing GL extension: ", extensionsToCheck[i]);
				return (10+n);
			}
			n++;
		}
		
//		var glRenderer = '';
//		var glVendor = '';
//		var dbgRenderInfo = testGLContext.getExtension("WEBGL_debug_renderer_info");
//		if (dbgRenderInfo != null) {
//			glRenderer = testGLContext.getParameter(dbgRenderInfo.UNMASKED_RENDERER_WEBGL);
//			glVendor = testGLContext.getParameter(dbgRenderInfo.UNMASKED_VENDOR_WEBGL);
//		}
//		console.log("Renderer: ", glRenderer, " Vendor: ", glVendor);
	
		var isLowMemoryDevice = olGUI.isMobileOrTabletDevice();
		loadTryQualitySettingFromBrowser();
		var userQualitySetting = loadQualitySettingFromBrowser();
		if (userQualitySetting !== null) {
			if ((qualitySettingAutoMinBadValue>0) && (userQualitySetting.autobadquality>0)) {
				qualitySettingAutoMinBadValue = Math.min(userQualitySetting.autobadquality, qualitySettingAutoMinBadValue);
			} else {
				qualitySettingAutoMinBadValue = Math.max(0, userQualitySetting.autobadquality, qualitySettingAutoMinBadValue);
			}
		}
		if (!qualitySettingTryActive) {
			qualitySettingValue = getDefaultQualitySettingValue();
			qualitySettingIsAuto = true;
			// read setting from browser storage		
			if (userQualitySetting !== null) {
				qualitySettingValue = userQualitySetting.quality;
				qualitySettingIsAuto = userQualitySetting.auto;
			}
			if (qualitySettingIsAuto && isLowMemoryDevice) {	// limit auto quality to medium on mobile
				qualitySettingValue = Math.min(2, qualitySettingValue);
			}
		}
		if (qualitySettingIsAuto && (qualitySettingAutoMinBadValue>0)) {
			qualitySettingValue = Math.min(Math.max(1, qualitySettingAutoMinBadValue-1), qualitySettingValue);
		}
		// apply settings
		switch (qualitySettingValue) {
			case 1:	// low
				mapConfig.lateInit = true;
				mapConfig.stadiumOnlyLoVersions = true;
//				mapConfig.generateFloortileBuildings = false;
//				mapConfig.generateFloortilesOnlyStadium = true;
				mapConfig.generateFloortiles = false;
				mapConfig.useFloorDetailTexture = false;
				mapConfig.useRealtimeShadow = false;
				mapConfig.pixelRatio = 1.0;
				mapConfig.labelResolution = mapConfig.pixelRatio;
				mapConfig.lowResTextures = true;
				mapConfig.layerUnloadInvisibleDelay = 5000;	// ms
				mapConfig.maxLoadedLeavesCount = 4;
				mapConfig.noForeignLayer = true;
				if (isLowMemoryDevice) {
					mapConfig.whitelistStadiumObjects = ['sks_0', 'sks_0_lo'];
					mapConfig.introStartDelay = 2500;
				} else {
					mapConfig.introStartDelay = 1500;
				}
				break;
			case 2:	// medium
				mapConfig.lateInit = true;
				mapConfig.stadiumOnlyLoVersions = true;
				mapConfig.generateFloortileBuildings = false;
				mapConfig.generateFloortilesOnlyStadium = true;
//				mapConfig.generateFloortiles = false;
//				mapConfig.useFloorDetailTexture = false;
				mapConfig.useRealtimeShadow = false;
//				mapConfig.pixelRatio = 1.0;
//				mapConfig.labelResolution = mapConfig.pixelRatio;
//				mapConfig.lowResTextures = true;
				mapConfig.layerUnloadInvisibleDelay = 10000;	// ms
				mapConfig.maxLoadedLeavesCount = 8;
				if (isLowMemoryDevice) {
					mapConfig.lowResTextures = true;
					mapConfig.introStartDelay = 1500;
					mapConfig.useFloorDetailTexture = false;
					mapConfig.generateFloortiles = false;
//					mapConfig.whitelistStadiumObjects = ['stadium_floor', 'sks_0', 'sks_0_lo'];
					mapConfig.pixelRatio = 1.0;
					mapConfig.labelResolution = mapConfig.pixelRatio;
				} else {
					if (mapConfig.pixelRatio > 1.0) {
						mapConfig.antialiasing = false;
					}
					mapConfig.introStartDelay = 1500;
				}
				break;
			default:	// high
//				mapConfig.useRealtimeShadow = false;
				if (isLowMemoryDevice && (mapConfig.pixelRatio > 1.0)) {
					mapConfig.antialiasing = false;
				}
				// nothing to change
		}
//		mapConfig.labelResolution = 0.5;

//		if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
//			mapConfig.lateInit = true;
//			mapConfig.stadiumOnlyLoVersions = true;
//		}
		if (isLowMemoryDevice) {
			mapConfig.preloadMaterials = false;
			mapConfig.preloadTextures = false;
			mapConfig.useRealtimeShadow = false;	// workaround for ios bug: invisible bld
//			mapConfig.lowResTextures = true;
//			mapConfig.useFloorDetailTexture = false;
			var defaultConfig = LigaMap.getDefaultConfig();
			mapConfig.layerUnloadInvisibleDelay = Math.max(1000, Math.round(defaultConfig.layerUnloadInvisibleDelay/3));	// earlier unload
			mapConfig.maxLoadedLeavesCount = Math.max(0, Math.round(defaultConfig.maxLoadedLeavesCount/4));
//			mapConfig.generateFloortiles = false;
		}
		if (self.useDebugLayer) {
			mapConfig.useDebugLayer = true;
		}
		
		updateQualitySettingDisplay();
		
		return 0;
	};
	
	var inputTutorial = function(stepNumber, fromTouch) {
		var shouldBeTouchTutorial = olGUI.isTouchCapable();
		if ((fromTouch !== shouldBeTouchTutorial) || (stepNumber !== (inputTutorialCurrentStep+1))) {
//			console.log("aborting inputTutorial", fromTouch,'!=', shouldBeTouchTutorial, stepNumber, '!=', (inputTutorialCurrentStep+1))
			// nothing to do
			return;
		}
		try {
			if (window.localStorage.getItem(inputTutorialFinishedStorageKey)) {
				console.log("aborting inputTutorial, already finished");
				// already finished
				return;
			}
		} catch(e) {}
		if (!shouldBeTouchTutorial && (stepNumber==4)) {	// skip
			stepNumber = 5;
		}
		var isMacDesktop = navigator.platform.toUpperCase().indexOf('MAC')>=0;
		tutorialContainer.toggleClass('touch', shouldBeTouchTutorial).toggleClass('mac', isMacDesktop).removeClass("step"+inputTutorialCurrentStep).addClass("step"+stepNumber).show();
		inputTutorialCurrentStep = stepNumber;
		console.log("inputTutorial", "touch:", shouldBeTouchTutorial, "step:", stepNumber);
		
		if (inputTutorialCurrentStep >= 6) {	// finished, do not show again
			try {
				window.localStorage.setItem(inputTutorialFinishedStorageKey, true);
				tutorialContainer.fadeOut(1000);
				inputTutorialCurrentStep = 0;
				return true;
			} catch(e) {}
		}
	};
	
	var checkMapInputMouseNotMoved = function(abortClick) {
		var mapInput = self.map.getInput();
		if (!mapInput.getMouseMovedWhilePressed()) {
			if (abortClick) {
				mapInput.abortMouseClick();
			}
			return true;
		}
		return false;
	}
	
	var toggleSidebar = function(open) {
		if (open !== true && open !== false) {
			open = !sidebarElement.hasClass("open");
		}
		sidebarElement.toggleClass("open sidebar", open).removeClass("searchresults");
		onWindowResize();
	};

	var updateLeagueDisplay = function(leagueID, leagueLevel, leagueName) {
		if (self.selectedLeagueId != leagueID) {
			console.log("update league nav: ", leagueID, _currentSeason)
			$.ol.leagueNavigation.switchToLeague(leagueID, _currentSeason);
		}
		self.selectedLeagueId = leagueID;
		if ((leagueLevel !== undefined) && (leagueName !== undefined)) {
			accordionElement.find(".leagues .ol-panel-title-text").text(Lang.trans('js/misc.nthOnlineleagueName', { level: leagueLevel, name: leagueName }));
		} else {
			accordionElement.find(".leagues .ol-panel-title-text").empty();
		}
		updateShortInfoDisplay();
	};
	
	var updateShortInfoDisplay = function() {
//		console.log("updateShortInfoDisplay()");
		var teamName = accordionElement.find(".user.panel .ol-panel-title-text").first().text().trim();
		var userLeagueName = accordionElement.find(".user.panel .ol-liga-font").first().text().trim();
		var customLeagueName = $("#ol-map-sidebar-accordion .leagues.panel .ol-panel-title-text").first().text().trim();
		var relationsPanel = $("#ol-map-sidebar-accordion .relations.panel").first();
		var relationsPanelTitle = relationsPanel.find("a.ol-panel-collapse-title").first();
		var relationType = '';
		if (relationsPanelTitle.length > 0 && !relationsPanelTitle.hasClass("collapsed")) {
			var checkedInput = relationsPanel.find(".panel-collapse input.ol-radio:checked").first();
			if (checkedInput.length > 0) {
				relationType = checkedInput.next("label").text().trim();
			}
		}
		var row1 = $('<div class="row"></div>');
		var row2 = $('<div class="row"><span>'+customLeagueName+'</span></div>');
		if (relationType.length > 0) {
			row1.html('<span class="big">'+teamName+':</span> <span>'+relationType+'</span>');
		} else {
			if (teamName.length > 0) {
				if ((customLeagueName.length > 0) && (customLeagueName != userLeagueName)) {
					row1.html('<span class="big">'+teamName+'</span> <span>('+userLeagueName+')</span>');
				} else {
					row1.html('<span class="big">'+teamName+'</span>');
				}
			} else {
				row1.html('<span class="big">' + Lang.trans('js/misc.noTeamChoosed') + '</span>');
			}
		}
		var shortInfo = sidebarElement.find(".ol-map-shortinfo").first();
		shortInfo.empty().append(row1).append(row2);
		var userBadge = accordionElement.find(".user.panel .user-badge").first().clone();	
		if (userBadge.length > 0) {
			shortInfo.addClass("with-badge").prepend(userBadge);
		}
	};
	
	var lastCameraViewOffsetX = 0;
	var lastCameraViewContainerWidth = 0;
	var lastCameraViewContainerHeight = 0;
	var onWindowResize = function() {
		resizeMapContainer();
		var isSmall = $("body").hasClass("ol-xs");
		var cw = container.width();
		var ch = container.height();
		var sidebarOffsetX = 0;
		var cameraViewOffsetX = 0;
		if (sidebarElement && (cw < 1200) && (cw > 420)) {
			if (sidebarElement.hasClass("open")) {	// wenig Platz: Sidebar bei Zentrierung mit einberechnen
				sidebarOffsetX = 0.5*sidebarElement.width();
				cameraViewOffsetX = -1 * sidebarOffsetX;
			}
		}
		var userDetailMaxLineHeight = 280;
		var connectionDetailMaxLineHeight = 100;
		var userDetailWidth = (isSmall? 170 : 200);	// variabel, Mittelwert
		var userDetailHeight = (isSmall? 55 : 65);
		var connectionDetailWidth = (isSmall? 240 : 320);
		var connectionDetailHeight = (isSmall? 287 : 367);
		var detailsMargin = (isSmall? 60 : 10);
		currentUserDetailsHeightAndMargin = userDetailHeight + detailsMargin;
		currentConnectionDetailsHeightAndMargin = connectionDetailHeight + detailsMargin;
		currentUserDetailsMaxPadding = Math.min(userDetailMaxLineHeight, Math.round(ch*0.5-currentUserDetailsHeightAndMargin));	// nie höher als 50% Höhe der Map
		currentConnectionDetailsMaxPadding = Math.min(connectionDetailMaxLineHeight, Math.round(ch*0.75-currentConnectionDetailsHeightAndMargin));	// nie höher als 75% Höhe der Map
		var defaultUserScreenOffsetX = -0.5*userDetailWidth + sidebarOffsetX;
		var defaultUserScreenOffsetY = 0.5*(currentUserDetailsHeightAndMargin + currentUserDetailsMaxPadding);	// ~170
		var defaultConnectionScreenOffsetX = -0.5*connectionDetailWidth + sidebarOffsetX;
		var defaultConnectionScreenOffsetY = 0.5*(currentConnectionDetailsHeightAndMargin + currentConnectionDetailsMaxPadding);	// ~235
		currentUserScreenOffsetX = Math.min(0.4*cw, Math.max(-0.4*cw, defaultUserScreenOffsetX));
		currentUserScreenOffsetY = Math.min(0.4*ch, Math.max(-0.4*ch, defaultUserScreenOffsetY));
		currentConnectionScreenOffsetX = Math.min(0.4*cw, Math.max(-0.4*cw, defaultConnectionScreenOffsetX));
		currentConnectionScreenOffsetY = Math.min(0.4*ch, Math.max(-0.4*ch, defaultConnectionScreenOffsetY));
		if (self.map) {
			self.map.setConfig({labelKeptInViewMargin:(isSmall? 10 : 30)});
			self.map.containerSizeChanged();
			if (self.selectedUserId) {
				if (connectionDetails.is(":visible")) {
					self.map.changeAnimationScreenOffset(currentConnectionScreenOffsetX, currentConnectionScreenOffsetY);
				} else {
					self.map.changeAnimationScreenOffset(currentUserScreenOffsetX, currentUserScreenOffsetY);
				}
			}
			if (mapIntroFinished) {
				if (
					(lastCameraViewOffsetX !== cameraViewOffsetX) ||
					((cameraViewOffsetX !== 0) && (lastCameraViewContainerWidth !== cw || lastCameraViewContainerHeight !== ch))	// falls aktiv, auch bei Containergrößenänderungen aktualisieren
				) {
					if (self.map.setCameraViewOffset(cw, ch, cameraViewOffsetX, 0, cw, ch)) {
						lastCameraViewOffsetX = cameraViewOffsetX;
						lastCameraViewContainerWidth = cw;
						lastCameraViewContainerHeight = ch;
					}
				}
			}
		}
		if ((defaultFOVPortraitCorrectionFactor !== undefined) && (cw > 100)) {	// (cw > 100): abort with wrong size during init
			// Estimate size of Germany when fov is corrected in portrait mode
			var cameraFOVCorrection = LigaMap.getCameraFOVCorrection(cw/Math.max(1, ch), defaultFOVPortraitCorrectionFactor);
//			console.log("cameraFOVCorrection:", cameraFOVCorrection);
			loadingOverlay.css("background-size", "auto "+(100/(1.05*cameraFOVCorrection)).toFixed(1)+"%");
			loadingOverlay.addClass("bg-visible");
//		} else {
//			console.log("defaultFOVPortraitCorrectionFactor is undefined");
		}
	};
	
	var windowResizeTimeout = undefined;
	this.init = function(mapConfig, currentSeason, userDetailsHasContent) {
		if (this.map !== null) {	// prevent multiple calls
			return;
		}
//		console.log("mapIntegration.init()");
		_currentSeason = currentSeason;
		mapRoot = $("#ol-map-root");
		container = $("#ol-map");
		loadingOverlay = container.children(".ol-map-loadingoverlay").first();
		userDetails = $("#ol-map .ol-map-userdetails").first().hide();
		if (userDetailsHasContent) {
			userDetailsShouldBeVisibleAfterIntro = true;
		}
		connectionDetails = $("#ol-map .ol-map-connectiondetails").first().hide();
		tutorialContainer = container.children(".ol-inputtutorial").first();
		if (window.location.search.toLowerCase().indexOf('map-reset-tutorial') >= 0) {
			try {
				window.localStorage.removeItem(inputTutorialFinishedStorageKey);
			} catch(e) {}
		}

		addLoadingOverlay();

		qualityElement = $("#ol-map-navigation .quality");
		qualityElement.find("a").on("click", function(e) {
			e.preventDefault();
			var t = $(this);
			var setting = t.data("setting");
			if (setting==3 && olGUI.isMobileOrTabletDevice()) {
				if (!confirm(Lang.trans('js/misc.msgWarningMemory'))) {
					return;
				}
			}
			var noReloadNeeded = false;
			if (qualitySettingIsAuto) {
				if ((setting > 0) && (setting == qualitySettingValue)) {
					// console.log("same setting: auto -> manual");
					noReloadNeeded = true;
					qualitySettingIsAuto = false;
				}
			} else {
				if ((setting == 0) && (qualitySettingValue == getDefaultQualitySettingValue())) {
					// console.log("same setting: manual -> auto");
					noReloadNeeded = true;
					qualitySettingIsAuto = true;
				}
			}
			if (noReloadNeeded) {
				e.stopPropagation();
				qualityElement.removeClass("touchhover");
				if (!qualitySettingTryActive) {
					saveQualitySettingToBrowser();
				}
				updateQualitySettingDisplay();
			} else {
				tryNewQualitySetting(setting);
			}
		});
		qualityElement.on("click", function() {
			qualityElement.addClass("touch").toggleClass("touchhover");
		});
		var errorCode = checkAndAutoChangeMapConfig(mapConfig);
		if (errorCode > 0) {
			this.map = false;
			container.addClass("ol-maperror");
			container.find(".ol-maperrormsg").append(" ("+errorCode+")");
			return;
		}
		defaultConnections = mapConfig['showConnections']? mapConfig['showConnections'] : [];
		
		container.on("mapUserSelected", function(e, data, positionKnown, positionOutOfView) {
			console.log("onMapUserSelected", data, positionKnown, positionOutOfView);
			if (!$("body").hasClass("ol-xs")) {
				toggleSidebar(true);
			}
			inputTutorial(6, olGUI.isTouchCapable());

			// muss ich überhaupt aktualisieren?
			if ((self.selectedUserId > 0) && (self.selectedUserId == data.id)) {
//				console.log("userinfo & userpanel uptodate");
				var sidebarUserPanel = sidebarElement.find(".panel.user").first();
				if (positionOutOfView !== null) {
					animateOrReplaceIntro({x:positionOutOfView.x, y:positionOutOfView.y, z:defaultZoomUser, duration:animationDefaultLength, autoDuration:true, screenOffsetX:currentUserScreenOffsetX, screenOffsetY:currentUserScreenOffsetY});
					return;
				}
				if (sidebarUserPanel.length > 0) {
					var currentSidebarHomeData = sidebarUserPanel.data("home");
					if (currentSidebarHomeData) {
						if (currentSidebarHomeData.position && (currentSidebarHomeData.position.length==2)) {
							if (self.map) self.map.updateSelectedUserPosition(currentSidebarHomeData.position[0], currentSidebarHomeData.position[1]);
							if (shouldAnimateFor == "user"+currentSidebarHomeData.userId) {
								shouldAnimateFor = "";
//								console.log("center view");
								animateOrReplaceIntro({x:currentSidebarHomeData.position[0], y:currentSidebarHomeData.position[1], z:defaultZoomUser, duration:animationDefaultLength, autoDuration:true, tilt:defaultTilt, yaw: 0, screenOffsetX:currentUserScreenOffsetX, screenOffsetY:currentUserScreenOffsetY});
							}
						}
					}
				}
				return;
			}
			var placeholderName = undefined;
			var animationAlreadyInitiated = false;
			if (positionKnown) {
				placeholderName = data.name;
				/*if (data.origPosition && (shouldAnimateFor == "user"+data.id || shouldAnimateFor == "")) {
					shouldAnimateFor = "";
					var selectUserMaxZoom = self.map.getConfig(['selectUserMaxZoom'])['selectUserMaxZoom'];
					var camPos = self.map.getCameraPosition();
					if (camPos.z > selectUserMaxZoom) {	// nur zentrieren, wenn weit weg
						animateOrReplaceIntro({x:data.origPosition.x, y:data.origPosition.y, z:defaultZoomUser, duration:animationDefaultLength, autoDuration:true});
					}
				}*/
			} else {
				// vielleicht home-user?
				var homedata = sidebarElement.find(".ol-map-search .homebutton").first().data("home");
				if (homedata && (homedata.userId>0) && (homedata.userId == data.id)) {
					if (homedata.position && (homedata.position.length==2)) {
						if (self.map) self.map.updateSelectedUserPosition(homedata.position[0], homedata.position[1]);
						if (shouldAnimateFor == "user"+homedata.userId) {
							shouldAnimateFor = "";
//							console.log("center view");
							animateOrReplaceIntro({x:homedata.position[0], y:homedata.position[1], z:defaultZoomUser, duration:animationDefaultLength, autoDuration:true, screenOffsetX:currentUserScreenOffsetX, screenOffsetY:currentUserScreenOffsetY});
							animationAlreadyInitiated = true;
						}
					}
					if (homedata.name && (homedata.name.length>0)) {
						placeholderName = homedata.name;
					}					
				}
			}
			if (!animationAlreadyInitiated && positionOutOfView !== null) {
				animateOrReplaceIntro({x:positionOutOfView.x, y:positionOutOfView.y, z:defaultZoomUser, duration:animationDefaultLength, autoDuration:true, screenOffsetX:currentUserScreenOffsetX, screenOffsetY:currentUserScreenOffsetY});
			}

			loadUserInfo(data.id, placeholderName);
		}).on("mapLoadingProgress", function(e, data) {
//			console.log("onMapLoadingProgress", e, data);
			var progress = data.progress;
			loadingOverlay.children(".ol-map-loadingprogress").css("visibility", "visible").children().first().css("width", (progress*100.0).toFixed(1)+"%");
			if (data.statusData !== undefined) {
				if (data.statusData.success==true) {
					container.find(".ol-mapasseterrormsg").hide();
				} else {
					container.find(".ol-mapasseterrormsg").show().children("span").first().text("("+(data.statusData.loaded!==undefined? data.statusData.loaded : "?")+"/"+(data.statusData.total!==undefined? data.statusData.total : "?")+")");
				}
			}
		}).on("mapIntroReady", function(e, data) {
			console.log("onMapIntroReady", e, data);
			mapIntroReady = true;
			playMapIntro();
		}).on("mapIntroFinished", function(e, data) {
			console.log("OnMapIntroFinished, success:", data);
			if (userDetailsShouldBeVisibleAfterIntro) {
				userDetails.fadeIn(1000);
			}
			sidebarElement.fadeIn(1000);
			$("#ol-map-navigation").fadeIn(1000);
			mapIntroFinished = true;
			onWindowResize();
			// start tutorial
			inputTutorial(1, olGUI.isTouchCapable());
		}).on("mapUserUnselected", function(e) {
			console.log("onMapUserUnselected");
			sidebarElement.find(".panel.user").first().slideUp(350, function() {
				$(this).remove();
				toggleRelationDisplay();
			});
			userDetails.hide();
			userDetailsShouldBeVisibleAfterIntro = false;
			self.selectedUserId = -1;
			// hide relations
			$("#ol-map-sidebar-collapse4").collapse('hide');
			sidebarElement.find(".panel.relations .ol-panel-collapse-title").addClass("disabled");
		}).on("mapUpdateUserDetailsPosition", function(e, pos) {
//			console.log("onMapUpdateUserDetailsPosition", pos);
			var ch = container.height();
			var b = (ch - pos.y);
			var p = Math.max(20, Math.min(currentUserDetailsMaxPadding, pos.y-currentUserDetailsHeightAndMargin));
			userDetails.css({
				left: pos.x+"px",
				bottom: b+"px",
				paddingBottom: p+"px"

			});
			b = (ch - pos.cy);
			p = Math.max(20, Math.min(currentConnectionDetailsMaxPadding, pos.cy-currentConnectionDetailsHeightAndMargin));
			connectionDetails.css({
				left: pos.cx+"px",
				bottom: b+"px",
				paddingBottom: p+"px",
				opacity: pos.ca,
				display: ((pos.ca > 0.001)? '' : 'none')
			});
		}).on("mapLeaguesLoaded", function(e, data) {
			console.log("onMapLeaguesLoaded", data);
			var league = undefined;
			if (data.leagues) for (var i in data.leagues) {
				league = data.leagues[i];
				break;
			}
			if (league!==undefined && league.leagueID && shouldAnimateFor == "league"+league.leagueID) {
				updateLeagueDisplay(league.leagueID, league.level, league.name);
				container.removeClass("ol-map-loading-ext");
				var centerX = 0.5 * (Number(data.boundingBox.min.x)+Number(data.boundingBox.max.x));
				var centerY = 0.5 * (Number(data.boundingBox.min.y)+Number(data.boundingBox.max.y));
				animateOrReplaceIntro({
					x: centerX,
					y: centerY,
					radius: Math.max(0.5*Math.abs(Number(data.boundingBox.max.x)-Number(data.boundingBox.min.x)), 0.5*Math.abs(Number(data.boundingBox.max.y)-Number(data.boundingBox.min.y))),	// kein echter Radius, aber reicht
					duration: animationDefaultLength,
					tilt:defaultTilt,
					yaw:0,
					autoDuration:true
				});
				shouldAnimateFor = "";	
			}
		}).on("mapUpdateCameraYaw", function(e, data) {
//			console.log("onMapUpdateCameraYaw", data);
			$("#ol-map-compass").css("transform", "rotate("+data+"rad)");
		}).on("mapUpdateCameraTilt", function(e, data) {
//			console.log("mapUpdateCameraTilt", data);
			var config = self.map.getConfig(['minTilt', 'maxTilt']);
			tiltSliderElement.children(".handle").css("top", (1-(data-config['minTilt'])/(config['maxTilt']-config['minTilt']))*(tiltSliderElement.height()-9));
		}).on("mapFPSChange", function(e, data) {
			console.log("mapFPSChange", data);
			if (data && data.rating) {
				if (qualitySettingTryActive || qualitySettingIsAuto) {
					if (!qualitySettingAlreadyAskedGood && qualitySettingIsAuto) {
						if (
							(data.rating == 'good') &&
							(qualitySettingValue < (olGUI.isMobileOrTabletDevice()? 2 : 3)) &&	// limit auto quality to medium on mobile
							((qualitySettingAutoMinBadValue <= 0) || ((qualitySettingValue+1) < qualitySettingAutoMinBadValue))
						) {	// läuft super, bessere Qualität probieren?
							var msg = Lang.trans('js/misc.msgBetterQuality');
							if (confirm(msg)) {
								tryNewQualitySetting(qualitySettingValue+1, qualitySettingIsAuto);
								return;
							}
							qualitySettingAlreadyAskedGood = true;
						}
					}
					if (!qualitySettingAlreadyAskedBad) {
						if ((data.rating == 'bad') && (qualitySettingValue > 1)) {	// läuft schlecht, schlechtere Qualität probieren?
							if (confirm(Lang.trans('js/misc.msgLowerQuality'))) {
								tryNewQualitySetting(qualitySettingValue-1, qualitySettingIsAuto, qualitySettingIsAuto);
								return;
							}
							qualitySettingAlreadyAskedBad = true;
						}
					}
					if (qualitySettingTryActive) {
						if ((data.rating != 'bad') && (qualitySettingAutoMinBadValue > 0)) {
							// push setting up when ok or good
							qualitySettingAutoMinBadValue = Math.max(qualitySettingAutoMinBadValue, qualitySettingValue+1);
						}
						if (!saveQualitySettingToBrowser()) {	// ist wohl sicher zum speichern...
							LigaMap.console.log("Error: Could not save map quality setting to browser!");
						}
						qualitySettingTryActive = false;
						updateQualitySettingDisplay();
					}
				}
			}
		}).on("mapInputPan", function(e, fromTouch) {
//			console.log("onMapInputPan", fromTouch);
			inputTutorial(2, fromTouch);
		}).on("mapInputZoom", function(e, fromTouch) {
//			console.log("onMapInputZoom", fromTouch);
			inputTutorial(3, fromTouch);
		}).on("mapInputYaw", function(e, fromTouch) {
//			console.log("onMapInputYaw", fromTouch);
			inputTutorial(4, fromTouch);
		}).on("mapInputTilt", function(e, fromTouch) {
//			console.log("onMapInputTilt", fromTouch);
			inputTutorial(5, fromTouch);
		}).on("mapConnectionSelected", function(e, connectionData, position, inView) {
			console.log("OnMapConnectionSelected", connectionData, position, inView);
			var targetUID = (connectionData && connectionData.selectUID)? connectionData.selectUID : null;
			var targetType = (connectionData && connectionData.selectType)? connectionData.selectType : null;
			var showConnectionDetails = function() {
				var hasDetails = false;
				if (targetUID && targetType) {
					var newDetails = $(".relations .ol-map-relation-details .relation-"+targetType+"-uid-"+targetUID);
					if (newDetails.length > 0) {
						hasDetails = true;
						connectionDetails.html(newDetails.html());
						connectionDetails.find(".titlebar .ol-button-toggle").first().addClass("active");	// Klasse wurde evtl. entfernt durch vorangegangenen Click (siehe widgets.js)
						connectionDetails.find(".content canvas").each(function() {	// update diagrams
							if (this.olDiagram && this.olDiagram.update) {
								this.olDiagram.update();
							}
						});
					}
					if (position !== null) {
						var animationData = {x:position.x, y:position.y, duration:animationDefaultLength, autoDuration:true, useCenterOffset:false, screenOffsetX:currentConnectionScreenOffsetX, screenOffsetY:currentConnectionScreenOffsetY};
						if (!inView) {
							animationData.z = defaultZoomUser;
							animationData.useCenterOffset = true;
						}
						animateOrReplaceIntro(animationData);
					}
				}
				if (hasDetails) {
					userDetails.hide();
					connectionDetails.show();
				} else {
					userDetails.show();
					connectionDetails.hide();
				}
			};
			var isPremiumUser = olGUI.isPremiumUser();
			if (location.search.toLowerCase().indexOf('forcenonpremium')>=0) isPremiumUser = false;	// TODO: remove when implemented
			if ((targetUID===null) || (targetType===null) || isPremiumUser) {
				showConnectionDetails();
			} else {
				userDetails.hide();
				connectionDetails.html('<div class="titlebar"></div><div class="content"><div class="ol-page-loading-small"></div></div>');
				connectionDetails.show();
				if (lastAjaxXHRRelationAccess !== null) {
					lastAjaxXHRRelationAccess.abort();
				}
				var relationString = targetType+'_'+self.selectedUserId+'_'+targetUID;
				lastAjaxXHRRelationAccess = $.ajax({
					type: 'GET',
					url: "/map/ajax-checklimits",
					data: {data:relationString, type:'relations'},
					dataType: 'json',
					success: function(ajaxData) {
						if (ajaxData && (ajaxData.allowed === true)) {
							showConnectionDetails();
						} else {
							self.map.unselectConnection();
							olMsgBox.premium().questionById('mapRelationLimit', function() {
								olAnchorNavigation.load('premium');
							});
						}
					},
					error: function() {
						self.map.unselectConnection();
					},
					complete: function() {
						lastAjaxXHRRelationAccess = null;
					}
				});	
			}
		}).on("wheel mousewheel DOMMouseScroll touchstart touchend touchmove", ".scrollable", function(e) {
			// Native Scroll-Funktion erlauben...
			e.stopPropagation();
			e.originalEvent.stopPropagation();
			if (e.type == 'touchstart') {
				$(this).data('touchmoved', false);
			}
			if (e.type == 'touchmove') {
				$(this).data('touchmoved', true);
			}
		});

		sidebarElement = $("#ol-map-sidebar");
		searchInputElement = sidebarElement.find(".ol-map-search input");
		searchSuggestionElement = sidebarElement.find(".ol-map-search .suggestions");
		searchResultElement = sidebarElement.find(".ol-map-sidebar-searchresults");
		accordionElement = sidebarElement.find("#ol-map-sidebar-accordion");
		sidebarElement.find(".menubutton").on("click", toggleSidebar);
		var searchBlurTimeoutId = undefined;
		searchInputElement.on("keyup paste change focus click", function(e) {
//			console.log("searchInputElement keyup paste change focus click");
			if (this != document.activeElement) {
				return;
			}
			var t = $(this);
			t.closest(".ol-map-search").addClass("searchfocus");
			if (e.keyCode == 13) {	// Enter
				if (t.val().length > 0) {
					doAjaxSearch(t.val(), true);
				}
			} else if (e.keyCode == 27) {	// ESC
				t.blur();
			} else {
				if (t.val().length > 2) {
					doAjaxSearchDelayed(t.val(), false);
				} else {
					abortAjaxSearchDelayed();
					clearSearchSuggestion();
				}
			}
		}).on("blur", function(e) {
//			console.log("searchInputElement blur", e);
			var t = $(this);
			searchBlurTimeoutId = window.setTimeout(function() {
//				console.log("searchInputElement blur timeout");
				searchBlurTimeoutId = undefined;
				abortAjaxSearchDelayed();
				clearSearchSuggestion();
				t.closest(".ol-map-search").removeClass("searchfocus");
			}, 250);
		});
		sidebarElement.find(".ol-map-search .searchbutton").on("click", function(e) {
//			console.log("searchbutton click");
			var t = $(this);
			if (t.closest(".ol-map-search").hasClass("searchfocus") && (searchInputElement.val().length > 0)) {
				if (searchBlurTimeoutId != undefined) {
					window.clearTimeout(searchBlurTimeoutId);
					searchBlurTimeoutId = undefined;
				}
				doAjaxSearch(searchInputElement.val(), true);
			} else {
				t.closest(".ol-map-search").find("input").focus();
			}
		})/*.on('focus', function(e) {
			console.log("searchbutton focus");
		})*/;
		sidebarElement.find(".ol-map-search .homebutton").on("click", function(e) {
			var homedata = $(this).data("home");
			if (homedata) {
				if (homedata.userId > 0) {
					self.showUser(homedata.userId);
				} else if (self.selectedUserId > 0) {
					self.showUser(self.selectedUserId);
				} else {
					animateOrReplaceIntro({x:defaultPositionGermany[0], y:defaultPositionGermany[1], z:defaultZoomGermany, tilt:defaultTilt, yaw: 0, duration:animationDefaultLength, autoDuration:true});
				}
			}
		});
		userDetails.on("mouseup touchend", "div", function(e) {
			if (checkMapInputMouseNotMoved(true)) {
				var sidebarUserPanel = sidebarElement.find(".panel.user").first();
				if (sidebarUserPanel.length > 0) {
					var currentSidebarHomeData = sidebarUserPanel.data("home");
					if (currentSidebarHomeData) {
						if (currentSidebarHomeData.position && (currentSidebarHomeData.position.length==2)) {
							animateOrReplaceIntro({x:currentSidebarHomeData.position[0], y:currentSidebarHomeData.position[1], z:defaultZoomUser, duration:animationDefaultLength, tilt:defaultTilt, autoDuration:true, screenOffsetX:currentUserScreenOffsetX, screenOffsetY:currentUserScreenOffsetY});
						}
					}
				}
			}
		});
		
		connectionDetails.on("mouseup touchend", "div", function(e) {
			checkMapInputMouseNotMoved(true);	// abort all clicks
		}).on("click", ".titlebar .ol-button-toggle", function(e) {
			// Standard-Click event verhindern, benutzen hier mouseup/touchend
			e.stopPropagation();
		}).on("mouseup touchend", ".titlebar .ol-button-toggle", function(e) {
			if (checkMapInputMouseNotMoved(false)) {
				var t = $(this);
				connectionDetails.find(".content").hide();
				connectionDetails.find("."+t.data("target")).first().show();
				connectionDetails.find(".ol-button-toggle").removeClass("active");
				t.addClass("active");
			}
		}).on("mouseup touchend", ".titlebar .close", function(e) {
			if (checkMapInputMouseNotMoved(false)) {
				self.map.unselectConnection();
			}
		}).on("mouseup touchend", ".content .historyentry", function(e) {
			if (checkMapInputMouseNotMoved(false)) {
				var t = $(this);
				// check also scrollable area
				if (t.hasClass("clickable")) {
					var scrollable = t.closest('.scrollable');
					if (scrollable.length < 1 || scrollable.data('touchmoved') != true) {
						var season = t.data('season');
						var matchId = t.data('matchid');
						if (season !== undefined && matchId !== undefined) {
							olAnchorNavigation.load('/match', { season: season, matchId: matchId }, undefined, undefined);
						}
					}
				}
			}
		});
		container.find(".ol-open-manual").on("mouseup touchend", function(e) {
			// no interaction on map through help icon
			var mapInput = self.map.getInput();
			mapInput.abortMouseClick();
		})
		sidebarElement.on("click", ".result", function(e) {
			var uid = $(this).data("uid");
			if (uid) {
				searchInputElement.val("");
				abortAjaxSearchDelayed();
				clearSearchSuggestion();
				self.showUser(uid);
			}
		});
		searchResultElement.on("scroll", function(e) {
//			console.log("scroll", e);
			if (searchResultElement.hasClass("loading")) {
				return;
			}
			var maxCount = searchResultElement.children("h3").first().data("resultcount");
			var currentCount = searchResultElement.children(".result").length;
//			console.log("scroll", searchResultElement.scrollTop(), searchResultElement.outerHeight(), searchResultElement.prop('scrollHeight'), currentCount);
			if (
				(maxCount > 0) &&
				(currentCount > 0) &&
				(currentCount < maxCount) &&
				(searchResultElement.scrollTop() + searchResultElement.outerHeight() + 20) > searchResultElement.prop('scrollHeight')
			) {
//				console.log("LOAD MORE");
				doAjaxSearch(searchInputElement.val(), true, currentCount);
			}
		});
		
		sidebarElement.on("change", ".ol-checkbox input", updateCheckboxClass);
		sidebarElement.on("change", ".relations input.ol-radio", function(e) {
			toggleRelationDisplay();
			if ($("body").hasClass("ol-xs")) {
				toggleSidebar(false);
			}
		});
//		sidebarElement.on("shown.bs.collapse", ".relations .panel-collapse", toggleRelationDisplay);
		sidebarElement.on("hidden.bs.collapse", ".relations .panel-collapse", function() {
			$(".relations input.ol-radio").prop("checked", false);	// unselect when close
			$(".relations .ol-map-relation-details").hide();
			toggleRelationDisplay();
		});
		var lastSidebarRelationsXHR = null;
		sidebarElement.on("show.bs.collapse", ".relations .panel-collapse", function(e) {
			var panel = $(this);
			var title = panel.closest(".panel").find("a.ol-panel-collapse-title").first();
			if (panel.is(':empty')) {
				// Load content if empty
				if (lastSidebarRelationsXHR===null) {
					if (title.is(":visible")) {
						addLoadingAnimationToElement(title, {size:"small", overlay:true});
					}
					lastSidebarRelationsXHR = $.ajax({
						type: 'GET',
						url: "/map/ajax-sidebarrelations",
						data: {uid:self.selectedUserId},
						success: function(ajaxData) {
							// hide and reshow panel with content
							panel.removeClass("in");
							panel.html(ajaxData);
							var lastCheckedCheckboxId = panel.data("lastCheckedCheckboxId");
							if (lastCheckedCheckboxId) {	// sollte eine Checkbox vorgewählt sein?
								var checkBox = panel.find("input#"+lastCheckedCheckboxId).first();
								panel.removeData("lastCheckedCheckboxId");
								if (checkBox.length > 0) {
									checkBox.prop("checked", true);
									toggleRelationDisplay();
								} else {
									console.log("checkbox/radio not found: #"+lastCheckedCheckboxId);
								}
							}
							panel.collapse('show');
						},
						complete: function() {
							removeLoadingAnimationFromElement(title);
							lastSidebarRelationsXHR = null;
						}
					});
				}
			}
		});
		sidebarElement.on("click", "div.panel.user .icon-icon_map_small", function(e) {
			e.preventDefault();
			e.stopPropagation();
			var t = $(this);
			var userPositionX = t.data("userposition_x");
			var userPositionY = t.data("userposition_y");
			if (userPositionX !== undefined && userPositionY !== undefined) {
				animateOrReplaceIntro({x:userPositionX, y:userPositionY, z:defaultZoomUser, duration:animationDefaultLength, tilt:defaultTilt, yaw: 0, screenOffsetX:currentUserScreenOffsetX, screenOffsetY:currentUserScreenOffsetY});
				self.map.unselectConnection();
			}
		});
		sidebarElement.on("click", "div.panel.user .title", function(e) {
			var t = $(this);
			if (!t.closest('a.ol-panel-collapse-title').hasClass('collapsed')) {
				olAnchorNavigation.load('team/overview', { userId : t.data("uid") });
			}
		});
		var relationSearchDelayedTimeoutId = undefined;
		sidebarElement.on("click", ".ol-map-relationsearch .clearbutton", function(e) {
			$(this).prevAll("input").val("").focus().trigger("change");
		}).on("keyup", ".ol-map-relationsearch input", function(e) {
			if ((e.keyCode == 13) || (e.keyCode == 27)) {
				this.blur();
			}
		}).on("keyup change paste focus blur", ".ol-map-relationsearch input", function(e) {
			var t = $(this);
			if (relationSearchDelayedTimeoutId !== undefined) {
				window.clearTimeout(relationSearchDelayedTimeoutId);
			}
			relationSearchDelayedTimeoutId = window.setTimeout(function() {
				relationSearchDelayedTimeoutId = undefined;
				var suggestions = {};
				var suggestionsCount = 0;
				var value = t.val();
				var showSuggestions = (value.length > 0);
				var search = new RegExp(value, "i");
				var replace = new RegExp('('+value+')', "gi");
				t.closest(".panel-body").find("input[name=toggle-relation]").each(function() {
					var t = $(this);
					var connectionData = t.data("connections");
					var resultConnectionData = [];
					var cids = [];	// Connection-IDs
					var count = 0;
					if (connectionData) {
						for (var i in connectionData) {
							var hasHit = true;
							if (showSuggestions) {
								hasHit = false;
								keyword_loop: {
									for (var j in connectionData[i]['keywords']) {
										var keywords = connectionData[i]['keywords'][j];
										if (!Array.isArray(keywords)) {
											keywords = [keywords];
										}
										var keyword;
										for (var k in keywords) {
											keyword = keywords[k];
	//										console.log({search:value, cd:i, kwt:j, kw:keyword, hit:search.test(keyword)});
											if (search.test(keyword)) {
												hasHit = true;
												if (showSuggestions && !suggestions[keyword] && (suggestionsCount < 5)) {	// maximale Anzahl begrenzen
													var replacedKeyword = keyword.replace(replace, '<b>$1</b>');
													var hint = '['+j+']';
													switch (j) {
														case 'team':
														case 'state':
														case 'district':
														case 'community':
														case 'user':
														case 'firstName':
														case 'lastName':
														case 'name':
														case 'playerNames':
															hint = Lang.trans('js/misc.' + j);
															break;
													}
													suggestions[keyword] = replacedKeyword+'<span class="hint">'+hint+'</span>';
													suggestionsCount++;
												} else {
//													console.log("too many suggestions", i, j, hasHit);
													if (hasHit) break keyword_loop;	// no need to check further
												}
											}
										}
									}
								}
							}
							if (hasHit) {
								resultConnectionData.push(connectionData[i]);
								cids.push(connectionData[i]["selectUID"]? connectionData[i]["selectUID"] : "("+i+")");
								count++;
							}
						}
					}
					var joinedCIDs = cids.join(",");
					if (t.prop("checked")) {	// update visible connections
						var prevCIDs = t.data("resultConnectionIDs");
						if ( !((prevCIDs == undefined && joinedCIDs == "") || (prevCIDs == joinedCIDs)) ) {
							if (resultConnectionData.length < 1) {
								self.map.setConfig({showConnections: defaultConnections});
							} else {
								self.map.setConfig({showConnections: resultConnectionData});
							}
						}
					}
					t.data("resultConnections", resultConnectionData);
					t.data("resultConnectionIDs", joinedCIDs);
					t.next("label").find(".count").text(count);
				});
				t.nextAll(".clearbutton").toggle(showSuggestions);
				var suggestionsElement = t.nextAll(".suggestions");
				suggestionsElement.empty();
				if (t.is(":focus") && suggestionsCount > 0) {
					for (var keyword in suggestions) {
						suggestionsElement.append('<p class="result" data-keyword="'+keyword+'">'+suggestions[keyword]+'</p>');
					}
					suggestionsElement.show();
				} else {
					suggestionsElement.hide();
				}
				updateShortInfoDisplay();
			}, 250);
		});
		sidebarElement.on("click", ".ol-map-relationsearch .suggestions p.result", function(e) {
			var t = $(this);
			t.closest(".ol-map-relationsearch").children("input").val(t.data("keyword")).trigger("change");
//			t.parent().empty().hide();
			
		});

		$(document).on("newUserRelation", function(e, data) {
			var homedata = sidebarElement.find(".ol-map-search .homebutton").first().data("home");	// vielleicht home-user?		
			if ((data.relatedUserId == self.selectedUserId) || (homedata && (homedata.userId>0) && (homedata.userId == self.selectedUserId))) {
				// Reload sidebar
				loadUserInfo(self.selectedUserId);
			}
		});
		$("#ol-map-navigation .zoom-in").on('mousedown touchstart', function(e) {
			e.preventDefault();
			var mapInput = self.map.getInput();
			mapInput.startInputAction("zoomIn");
		}).on('mouseup mouseleave touchend', function(e) {
			// todo: react to touchleave
			var mapInput = self.map.getInput();
			mapInput.stopInputAction("zoomIn");
		});
		$("#ol-map-navigation .zoom-out").on('mousedown touchstart', function(e) {
			e.preventDefault();
			var mapInput = self.map.getInput();
			mapInput.startInputAction("zoomOut");
		}).on('mouseup mouseleave touchend', function(e) {
			// todo: react to touchleave
			var mapInput = self.map.getInput();
			mapInput.stopInputAction("zoomOut");
		});
		$("#ol-map-navigation .north").on('click', function(e) {
			e.preventDefault();
			self.map.setConfig({centerView: {yaw:0, tilt:defaultTilt, duration: 1.0}});
		});
		var tiltSlideActive = false;
		var tiltSliderElement = $("#ol-map-navigation .tiltslider");
		var tiltSlideEvent = function(e) {
			if (tiltSlideActive) {
				e.preventDefault();
				var event = e.originalEvent;
				var containerOffset = tiltSliderElement.offset();
				var posY, maxY;
				if (event.touches && event.touches.length > 0) {
					posY = event.touches[0].pageY - containerOffset.top;
				} else {
					posY = e.pageY - containerOffset.top;
				}
				var maxY = tiltSliderElement.height()-9;
				posY = Math.min(Math.max(7, posY), maxY+7) - 7;	// clamp
				var value = (posY/maxY);
				var config = self.map.getConfig(['minTilt', 'maxTilt']);
				self.map.setConfig({centerView: {tilt:(config.maxTilt*(1-value)+config.minTilt*value)}});
			}
		};
		tiltSliderElement.on('mousedown touchstart', function(e) {
			tiltSlideActive = true;
			tiltSlideEvent(e);
		});
		mapRoot.on('mousemove touchmove', function(e) {
			tiltSlideEvent(e);
		}).on('mouseup mouseleave touchend', function(e) {
			tiltSlideActive = false;
		});
		
		if (mapConfig && mapConfig.userPosition && mapConfig.userPosition.id && (mapConfig.userPosition.id > 0)) {
			self.selectedUserId = mapConfig.userPosition.id;
		}
		if (mapConfig && mapConfig.showLeagues && (mapConfig.showLeagues.length > 0)) {
			self.selectedLeagueId = mapConfig.showLeagues[0];
		}
		updateShortInfoDisplay();
		// init map
		self.map = LigaMap.createInstance(jQuery, container, mapConfig, function() {
//			console.log("LigaMap init finished!");
			onWindowResize();
		});
		// remember some values
		var config = self.map.getConfig(['dblclickZoomSteps', 'fovPortraitCorrectionFactor', 'defaultX', 'defaultY', 'defaultZoom', 'defaultZoomUser', 'defaultTilt']);
		defaultTilt = config['defaultTilt'];
		defaultZoomUser = config['defaultZoomUser'];
		defaultZoomGermany = config['defaultZoom'];
		defaultPositionGermany = [config['defaultX'], config['defaultY']];
		defaultFOVPortraitCorrectionFactor = config.fovPortraitCorrectionFactor;
		// replay calls made before init
		for (var c in delayedShowCalls) {
			delayedShowCalls[c][0].apply(null, delayedShowCalls[c][1]);
		}
		delayedShowCalls = [];
		// regularly refresh data
		window.setInterval(self.invalidateUserData, refreshUserDataIntervalMinutes*60*1000);
		$(window).resize(function() {
			if (windowResizeTimeout === undefined) {
				windowResizeTimeout = setTimeout(function() {
					windowResizeTimeout = undefined;
					onWindowResize();
				}, 100);
			}
		});
		onWindowResize();
//		console.log("mapIntegration.init() finished");
	};

	this.map = null;

	this.setMapActive = function(active) {
		//addLoadingOverlay();
		ol.navMap = active;
		scaleNavigation();
		resizeMapContainer(true);
		
		if (!self.map) {
			return;
		}

		if (active) {
			/*jQuery.ajax({
				url: _leagueNavUrl,
				type: "GET",
				dataType: "html"
			}).done(function(data) {
				$("#ol-map-sidebar .ol-map-nav-league").html(data);
			});*/
			container.focus();
            
			if(initMapLeagueNav != undefined)
                initMapLeagueNav();
            
			if (mapIntroPlayed) {
				sidebarElement.show();
				$("#ol-map-navigation").show();
				self.map.setConfig({renderActive:true});
			} else {
				window.setTimeout(function() {
					if (ol.navMap) {
						mapShowReady = true;
						self.map.setConfig({renderActive:true});
						playMapIntro();
					}
				}, overlayAlwaysWaitTime);
			}
		} else {
			self.map.setConfig({renderActive:false});
			sidebarElement.hide();
			$("#ol-map-navigation").hide();
		}
	};

	/**
	 * 
	 * @param {number} userid
	 * @returns {Boolean}
	 */
	this.showUser = function(userid) {
		if (!self.map) {
			delayedShowCalls.push([this.showUser, [userid]]);
			return false;
		}
		shouldAnimateFor = "user"+userid;
		self.map.setConfig({userPosition:{id:userid}});
		self.map.unselectConnection();
		if ($("body").hasClass("ol-xs")) {
			toggleSidebar(false);
		}
		return true;
	};

	/**
	 * 
	 * @param {number} leagueid
	 * @param {number} [leagueLevel]
	 * @param {string} [leagueName]
	 * @returns {Boolean}
	 */
	this.showLeague = function(leagueid, leagueLevel, leagueName) {
		if (!self.map) {
			delayedShowCalls.push([this.showLeague, [leagueid, leagueLevel, leagueName]]);
			return false;
		}
		updateLeagueDisplay(leagueid, leagueLevel, leagueName);
		shouldAnimateFor = "league"+leagueid;
		self.map.setConfig({showLeagues:[leagueid]});
		container.addClass("ol-map-loading-ext");
		if ($("body").hasClass("ol-xs")) {
			toggleSidebar(false);
		}
		return true;
	};
	
	this.invalidateUserData = function() {
		if (self.map) {
			self.map.invalidateUserData();
			if (self.selectedUserId > 0) {
				loadUserInfo(self.selectedUserId);
			}
		}
	};
	
})($);
