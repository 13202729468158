(function(olACPComments, $, undefined ) 
{    
    var debug = false;

    olACPComments.saveEasyComment = function()
    {
        var commentId = $('[data-id]').attr('data-id');
        var commentType = $('[data-typeid]').attr('data-typeid');
        var commentContent = $('[data-comment]').val();

        console.log('commentId : ' + commentId + '!');
        console.log('commentType : ' + commentType + '!');
        console.log('commentContent : ' + commentContent + '!');

        $.ajax({
            type: "POST",
            url: '/acp/violation/easyComment/save',
            dataType: 'json',
            data: {
                //for authentification => JS in der VIEW!!!!
                //_token: "{{ csrf_token() }}",
                commentId : commentId,
                commentType : commentType,
                commentContent : commentContent
            },
            success: showSavedComment
        });
    }

    olACPComments.saveEasyCommentForUser = function(userId)
    {
        //var commentId = $('[data-id]').attr('data-id');
        //var commentType = $('[data-typeid]').attr('data-typeid');
        var commentContent = $('#supportComment').val();

        console.log('userId : ' + userId + '!');
        //console.log('commentType : ' + commentType + '!');
        console.log('commentContent : ' + commentContent + '!');

        $.ajax({
            type: "POST",
            url: '/acp/user/easyComment/save',
            dataType: 'json',
            data: {
                //for authentification => JS in der VIEW!!!!
                //_token: "{{ csrf_token() }}",
                //commentId : commentId,
                //commentType : commentType,
                userId : userId,
                commentContent : commentContent
            },
            success: showSavedComment
        });
    }

    olACPComments.saveComment = function()
    {
        var commentId = $('#commentId').val();
        var commentType = $('[name=commentType]').val();
        var commentContent = $('[name=comment]').val();

        $.ajax({
            type: "POST",
            url: '/acp/violation/comment/save',
            dataType: 'json',
            data: {
                //for authentification => JS in der VIEW!!!!
                //_token: "{{ csrf_token() }}",
                commentId : commentId,
                commentType : commentType,
                commentContent : commentContent
            },
            success: showSavedComment
        });
    }


    function showSavedComment()
    {
        //olMsgBox?
        alert('Kommentar wurde gespeichert!');

    }

    olACPComments.createComment = function()
    {
        var commentId = $('#commentId').val();
        var commentType = $('[name=commentType]').val();
        var commentRefer = $('[name=commentRefer]').val();
        var commentContent = $('[name=comment]').val();

        $.ajax({
            type: "POST",
            url: '/acp/violation/comment/save',
            dataType: 'json',
            data: {
                //for authentification => JS in der VIEW!!!!
                //_token: "{{ csrf_token() }}",
                commentId : commentId,
                commentType : commentType,
                commentRefer : commentRefer,
                commentContent : commentContent
            },
            success: showSavedComment
        });

    }


    olACPComments.editCommentColumnJson = function(modelType, modelId)
    {

        $.ajax({
            type: "POST",
            url: '/acp/violation/commentColumnJson/edit',
            dataType: 'html',
            data: {
                //for authentification => JS in der VIEW!!!!
                //_token: "{{ csrf_token() }}",
                modelId : modelId,
                modelType : modelType
            },
            success: showCommentColumnJson
        });
    }


    olACPComments.saveCommentColumnJson = function(modelType, modelId, commentContent)
    {
        //var commentContent = $()        
        if (commentContent == 0)
        {
            var selector = '#commentContent-' + modelId + ' .commentContent';
            var selector = '#commentColumn-' + modelId + ' [name=commentContent]';
            console.log('Selektor :' + selector);
            commentContent=$(selector).val();
        }
        
        console.log('commentContent' + commentContent);
        console.log('modelId ' + modelId );
        console.log('modelType ' + modelType );

        $.ajax({
            type: "POST",
            url: '/acp/violation/commentColumnJson/save',
            dataType: 'html',
            data: {
                //for authentification => JS in der VIEW!!!!
                //_token: "{{ csrf_token() }}",
                modelId : modelId,
                modelType : modelType,
                commentContent : commentContent
            },
            success: alert('Kommentar gespeichert!')
        });
    }

    function showCommentColumnJson(result)
    {
        console.log(result);
        var jsonArray = JSON.parse(result);
        console.log(jsonArray);
        
        jsonArray.forEach(function(jsonItem) {
            $(jsonItem[0]).html(jsonItem[1]);
        });

    }

    olACPComments.openComments = function()
    {
        $('#susVioAccordion .ui-accordion-content').show();
    }

    olACPComments.closeReportByCommentId = function(params, callback){
        $.ajax({
            'type': 'POST',
            'url': "/acp/comment/closeReport",
            'data': params,
            'dataType': 'json',
            'success': function(resp) {
                if (typeof callback === 'function'){
                    callback(resp);
                }
            }
        });
    }

}( window.olACPComments = window.olACPComments || {}, jQuery ));