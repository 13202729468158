window.olSponsorSelection = undefined;
$(function() {
    window.olSponsorSelection = new (function(opt) {
        if (!opt) {
            opt = {}
        };

        var activeFilters = [];
        var scrollCheckpoint = 0;

        var isPremium = olGUI.isPremiumUser();
        var maxFilters = 1;

        var expandHandler = function(event) {
            if (!$(this).hasClass('expanded')) {
                unexpandHandler(undefined, true);

                var top = $(this).offset().top - $(this).parent().offset().top;
                var left = $(this).offset().left - $(this).parent().offset().left;

                var _this = this;

                // Collapse navigation stuff
                // closeSubnavigationSmooth();
                // $("#ol-navbar-subnav-container").removeClass("ol-navbar-subnav-open");
                // $('.ol-banner-logo').removeClass("ol-logo-animate-in");
                // $('.ol-banner-logo').addClass("ol-logo-animate-out");
                // $("body").addClass("ol-nav-slim");

                olMobileApp.hideHeader();
                olMobileApp.hideBottomNav();
                // End navigation stuff

                $(_this).css('opacity', 0)
                setTimeout(function() {
                    $(_this).addClass('expanded');
                    olGUI.lockScroll($(_this).find('.sponsor-info-details-wrapper'));
                }, 10)
                setTimeout(function() {
                    $(_this).css('opacity', 1);
                    $(_this).find('.sponsor-info-details').css('opacity', 1);
                    $(_this).find('.sponsor-info-details  .sponsor-detail-fadein').css('opacity', 1);
                }, 20)
                setTimeout(function() {
                    $(_this).css('opacity', '');
                }, 500)
            }
        };

        var unexpandHandler = function(event, noscroll) {
            $('.expanded').removeClass('expanded').each(function() {
                var _this = this;
                $(_this).find('.sponsor-info-details  .sponsor-detail-fadein').css('opacity', '');
                setTimeout(function() {
                    //$('.sponsor-placeholder').remove();
                    $(_this).css({
                        'position': '',
                        'left': '',
                        'top': '',
                        'z-index': '',
                    });
                }, 0)
            });
            //if (!noscroll) $('html, body').animate({ scrollTop: scrollCheckpoint})
            olMobileApp.showHeader();
            olMobileApp.showBottomNav();
            if(!noscroll) {
                olGUI.unlockScroll();
            }

            //event.preventDefault();
            return false;
        };

        $(document).on('click', '.sponsor-info-block.info-block-clickable', expandHandler);
        $(document).on('click', '.back-link', unexpandHandler);
        $(document).on('click', '.expanded .sponsor-big-logo', unexpandHandler);

        onUnloadPage(function() {
            unexpandHandler();
            activeFilters = [];
        });

        var slideHandler = function(event) {
            var sum = 0;
            if (typeof event === 'undefined') {
                return;
            }
            
            $('.sponsor-filter-slider.active').each(function(slider) {
                slider = $(this).bootstrapSlider();
                if (!$(this).is(event.target)) {
                    var slider = $(slider).bootstrapSlider();
                    sum += parseInt(slider.bootstrapSlider('getValue'));
                }
            });

            var count = $('.sponsor-filter-slider.active').size() - 1;
            if (count > 0) {
                $('.sponsor-filter-slider.active').each(function(slider) {
                    if ($(this).is(event.target)) return;
                    slider = $(this).bootstrapSlider();

                    var pct = $(slider).bootstrapSlider('getValue');
                    console.log(sum, pct, "=", sum + pct)
                    if (sum + event.value > 100) {
                        if (sum>0) {
                            pct = (100-event.value) * (pct/sum);
                        } else {
                            pct = (100-event.value) * (1/count);
                        }
                    }
                    $(slider).bootstrapSlider('setValue', pct)
                            .parents('.sponsor-filter-bg')
                            .find('.pct-txt')
                            .text(Math.floor(pct) + "%")
                            .css('left', Math.floor(pct) + "%");
                });

                $(event.target).parents('.sponsor-filter-bg')
                        .find('.pct-txt')
                        .text(Math.floor(event.value) + "%")
                        .css('left', event.value + "%");
            } else {
                $(event.target).parents('.sponsor-filter-bg')
                        .find('.pct-txt')
                        .text(Math.round(event.value) + "%")
                        .css('left', event.value + "%");
            }
        };

        var changeHandler = function(event) {
            slideHandler(event);

            $('.sponsor-list-wrapper').addLoadingAnimation();

            var data = {};
            $('.sponsor-filter-slider.active').each(function() {
                data[$(this).data('filterType')] = $(this).bootstrapSlider().bootstrapSlider('getValue');
            })
            $.post('/sponsor/list', {"weights": data}, function(res) {
                $('.sponsor-list-wrapper').html(res);
                $('.sponsor-list-wrapper').removeLoadingAnimation();
            });
        };

        this.resetFilters = function() {
            // activeFilters = [];

            $('.ol-sponsoring-filter-block .ol-create-offer-block').each(function(i, el) {
                $closeBtn = $(el).find('.sponsor-filter-delete');
                $closeBtn.click();
            });
        };

        this.addFilter = function(filterType, filterText) {
            var first = activeFilters.length == 0;

            if (activeFilters.length >= maxFilters && !isPremium) return;

            if (!activeFilters.includes(filterType)) {
                var clone = $('#filterTemplate').clone()

                clone.removeAttr('hidden')
                    .removeAttr('id')
                    .addClass('ol-sponsoring-filter-block')
                    .insertBefore('#filterTemplate')
                    .find('.sponsor-filter-headline')
                    .text(filterText);

                var slider = clone.find(".sponsor-filter-slider")
                                .data('filterType', filterType)
                                .addClass('active')
                                .bootstrapSlider({ scale: 'linear', tooltip: 'hide'})
                                .on('slide', slideHandler)
                                .on('slideStop', changeHandler);

                activeFilters.push(filterType);

                $('#filter-'+filterType).css('display', 'none');

                if (activeFilters.length == 6) {
                    $('#addFilter').css('display', 'none');
                }

                if (first) {
                    slider.bootstrapSlider('setValue', 100);
                    clone.find('.pct-txt')
                        .text(100 + "%")
                        .css('left', 100 + "%");

                    changeHandler({value: 100, target: slider});
                }
            }

            if (activeFilters.length >= maxFilters && !isPremium) {
                $('#addFilter').parent().addClass('premium-locked');
            }

        };

        $(document).on('click', '.sponsor-filter-delete', function(ev) {
            var container = $(this).parents('.sponsor-filter-bg').parent();
            var filterType = container.find('.sponsor-filter-slider').data('filterType');

            // Remove active filter
            activeFilters = activeFilters.filter(function(s) {return s != filterType});

            $('#addFilter, #filter-'+filterType).css('display', '');

            container.remove();
            changeHandler();

            if (activeFilters.length < maxFilters || isPremium) {
                $('#addFilter').parent().removeClass('premium-locked');
            }
        });

        this.animate = function() {
            $('.sponsor-info-block:not(.sponsor-block-animation)').each(function(idx) {
                var el = this;
                setTimeout(function() {
                    $(el).addClass('sponsor-block-animation');
                }, 350*idx);
            });
        };

        this.animate();

        $(document).on('change', '.sponsor-decision-checkbox', function(ev) {
            $(ev.target).parents('.sponsor-sign-conditions').find('.sponsor-sign-conditions-text').toggleClass('checked')
        });

        var signInProgress = false;

        var signHandler = function(ev) {
            //console.log(ev);

            if (signInProgress) {
                return;
            }

            var checked = $(ev.target).parent().find('.sponsor-decision-checkbox').prop('checked');
            if (!checked && !$(ev.target).hasClass('no-checkbox')) {
                olMsgBox.msgById('conditions_box');
                return false;
            }

            var typeId      = $(ev.target).parents('.sponsor-info-block').data('sponsor-type') || $(ev.target).parents('.single-sponsor-view').data('sponsor-type');
            var sponsorId   = $(ev.target).parents('.sponsor-info-block').data('sponsor-id')   || $(ev.target).parents('.single-sponsor-view').data('sponsor-id');
            var sponsorName = $(ev.target).parents('.sponsor-info-block').data('sponsor-name') || $(ev.target).parents('.single-sponsor-view').data('sponsor-name');

            signInProgress = true;
            $.post('/sponsor/select', { typeId: typeId, sponsorId: sponsorId }, function(data) {
                signInProgress = false;
                data = JSON.parse(data);

                if (data.status == "error") {
                    olMsgBox.msgById('error_' + data.error);

                    if (data.error == 'not_available') {
                        olAnchorNavigation.reload(function() {
                            olGUI.unlockScroll();
                            olMobileApp.hideHeader();
                            olMobileApp.hideBottomNav();
                        });
                    }
                } else {
                    olMsgBox.msgById(
                        'sponsor_selected', 
                        function(){
                            olAnchorNavigation.reload(function() {
                                olGUI.unlockScroll();
                                olMobileApp.showBottomNav();
                                olMobileApp.showHeader();
                            });
                            olAnchorNavigation.load('sponsor/select');
                        }, 
                        {":sponsor": sponsorName}
                    );
                }
            });
        };

        $(document).on('click', '.sponsor-decision-button:not(.sponsor-cancel):not(.sponsor-button-locked):not(.no-checkbox)', signHandler);

        // back button in sponsoring/detail
        $(document).on('click', '.single-sponsor-view .back-link', function() {history.back()});

        var cancelHandler = function(ev) {
            var checked = $(ev.target).parent().find('.sponsor-decision-checkbox').prop('checked');
            if (!checked) {
                olMsgBox.msgById('conditions_box');
                return false;
            }

            var typeId    = $(ev.target).parents('.sponsor-info-block').data('sponsor-type');
            var sponsorId = $(ev.target).parents('.sponsor-info-block').data('sponsor-id');

            $.post('/sponsor/cancel', function(data) {
                data = JSON.parse(data);

                if (data.status == "error") {
                    olMsgBox.msgById('error_' + data.error);
                } else {
                    olMsgBox.msgById('sponsor_cancelled');

                    if ($('.single-sponsor-view').size() > 0) {
                        olAnchorNavigation.load('sponsor/select');
                    } else {
                        olAnchorNavigation.reload(function() {
                            olGUI.unlockScroll();
                            olMobileApp.hideHeader();
                            olMobileApp.hideBottomNav();
                        });
                    }
                }
            });
        };

        $(document).on('click', '.sponsor-cancel .sponsor-decision-button:not(.sponsor-button-locked)', cancelHandler);

        $(document).on('click', '#addFilter', function(event) {
            event.stopPropagation();
            $(this).find('.dropdown-toggle').click();
        })

        this.sponsorClick = function(sponsoringId, type, sponsorURL) {
            var validTypes = ['details', 'redirect'];

            if (typeof type === 'undefined') {
                type = 'details';
            }

            if (!validTypes.includes(type.toLowerCase())) {
                throw "Error: " + type + " is not a valid type for sponsorClick"
            }

            var action = 'clicked';
            var category = 'sponsorings';
            var label = 'sponsoring-' + sponsoringId + '-' + type;

            var data = {
                season: olTimeHelper.getCurrentSeason(),
                week: olTimeHelper.getCurrentWeek(),
                matchday: olTimeHelper.getCurrentMatchday()
            };

            olAnalytics.event(action, category, label, data);

            if (type == 'redirect') {
                if (APP_MODE) {
                    olMobileApp.openExternal(sponsorURL);
                    event.preventDefault();
                    return false;
                } else {
                    window.open(sponsorURL, '_blank');
                    event.preventDefault();
                }
            } else if (type == 'details') {
                olAnchorNavigation.load('/sponsor/details?sponsorId=' + sponsoringId);
            }

        };

        this.toggleAllMarketingValues = function(el) {
            var $el = $(el);
            if($el.hasClass('collapsed')) {
                $el.removeClass('collapsed');
                $('.ol-league-marketing-btn-text').toggle();
                $('.ol-league-marketing-collapse-button[aria-expanded="true"]').click();
            } else {
                $el.addClass('collapsed');
                $('.ol-league-marketing-btn-text').toggle();
                $('.ol-league-marketing-collapse-button:not([aria-expanded="true"])').click();
            }
        }

    })();
});


window.olSponsorLogoResponsive = new (function() {
    this.init = function() {
        $('.sponsor-logo-responsive:not(.initialized)').each(function() {
            $(this).addClass('initialized');
            var debugName = $(this).parents('.sponsor-info-block').attr('data-sponsor-name');

            var img = new Image();
            img.src = $(this).data('src');

            var ctx = this.getContext('2d');

            var offsetX = parseInt($(this).attr('data-offsetX'));
            var offsetY = parseInt($(this).attr('data-offsetY'));

            var zoom = $(this).attr('data-zoom');
            var height = parseInt($(this).attr('data-height'));
            var width = parseInt($(this).attr('data-width'));

            var external = $(this).attr('data-external') || $(this).attr('data-external') == "true";

            var canvas = this;

            img.onload = function() {
                var tWidth = img.width;
                var tHeight = img.height;

                if (tWidth < tHeight * 2) {
                    tWidth = tHeight * 2;
                } else {
                    tHeight += offsetY;
                }

                canvas.width = tWidth;
                canvas.height = tHeight;

                if (external) {
                    if (img.width < canvas.width) {
                        offsetX = (canvas.width - img.width) / 2;
                    }
                    if (img.height < canvas.height) {
                        offsetY = (canvas.height - img.height) / 2;
                    }
                }

                //console.log(debugName, { offsetX: offsetX, offsetY: offsetY, tWidth: tWidth, theight: tHeight, height: this.height, width: this.width }, (-(img.width*zoom - img.width)/2) + offsetX, (-(img.height*zoom - img.height)/2) + offsetY, img.width*zoom, img.height*zoom);
                //ctx.drawImage(this, (-(img.width*zoom - img.width)/2) + offsetX, (-(img.height*zoom - img.height)/2) + offsetY, img.width*zoom, img.height*zoom);
                ctx.drawImage(this, offsetX, offsetY, img.width*zoom, img.height*zoom);
            };
        });
    };

    this.debug = function() {
        $('.sponsor-logo-responsive').removeClass('initialized');
        this.init();
    };
})();
