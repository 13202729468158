(function(olMsgBox, $, undefined)
{
    var _callbackYes = false;
    var _callbackNo = false;
    var _open = false;
    var _type = false;
    var _json = false;
    var _premium = false;
    
    $(document).keyup(function(e) 
    {
        if(_open && e.keyCode == 13)
        {
            olMsgBox.onClickYes();
        }
        else if(_open && e.keyCode === 27)
        {
            if(_type == "question")
                olMsgBox.onClickNo();
            else
                olMsgBox.onClickYes();
        }
    });
    
    olMsgBox.init  = function()
    {
    };
    
    function _replace(target, replacements)
    {
        if(typeof replacements === typeof undefined || typeof target === typeof undefined) return target;
        for (var property in replacements) 
        {
            if (replacements.hasOwnProperty(property))
            {
                target = target.replace(new RegExp(property.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replacements[property]);
            }
        }
        return target;
    }
    
    olMsgBox.premium = function()
    {
        _premium = true;
        return olMsgBox;
    };
    
    olMsgBox.msgById = function(id, callbackYes, replacements)
    {
        var element = $('#_msgId' + id);
        if(element.length > 0)
        {
            olMsgBox.msg
            (
                _replace(element.find('.ol-msg-data-titel').html(), replacements), 
                _replace(element.find('.ol-msg-data-content').html(), replacements),
                callbackYes,
                _replace(element.find('.ol-msg-data-yes-text').html(), replacements)
            );
        }
    };
    
    olMsgBox.questionById = function(id, callbackYes, callbackNo, replacements, large)
    {
        var element = $('#_msgId' + id);
        if(element.length > 0)
        {
            olMsgBox.question
            (
                _replace(element.find('.ol-msg-data-titel').html(), replacements), 
                _replace(element.find('.ol-msg-data-content').html(), replacements),
                callbackYes,
                callbackNo,
                _replace(element.find('.ol-msg-data-yes-text').html(), replacements),
                _replace(element.find('.ol-msg-data-no-text').html(), replacements),
                large
            );
        }
    };

    olMsgBox.largeQuestionById = function(id, callbackYes, callbackNo, replacements)
    {
        var element = $('#_msgId' + id);
        if(element.length > 0)
        {
            olMsgBox.question
            (
                _replace(element.find('.ol-msg-data-titel').html(), replacements), 
                _replace(element.find('.ol-msg-data-content').html(), replacements),
                callbackYes,
                callbackNo,
                _replace(element.find('.ol-msg-data-yes-text').html(), replacements),
                _replace(element.find('.ol-msg-data-no-text').html(), replacements),
                true
            );
        }
    };
    
    olMsgBox.json = function(json, callbackYes, callbackNo)
    {
        _json = json;
        if(json.type == "ignore")
        {
            if(typeof callbackYes !== typeof undefined) callbackYes();
            return;
        }
        if(json.type == "msg") olMsgBox.msg(json.header, json.content, callbackYes, json.yes);
        else if(json.type == "msgNoClose") olMsgBox.msgNoClose(json.header, json.content, callbackYes, json.yes);
        else if(json.type == "question") olMsgBox.question(json.header, json.content, callbackYes, callbackNo, json.yes, json.no);
        else if(typeof callbackYes !== typeof undefined) callbackYes();;
    }
    
    olMsgBox.question = function(header, text, callbackYes, callbackNo, textYes, textNo, large)
    {
        if(typeof callbackYes === "undefined") callbackYes = false;
        if(typeof callbackNo === "undefined") callbackNo = false;
        _callbackYes = callbackYes;
        _callbackNo = callbackNo;

        if(typeof textYes !== typeof undefined) {
            $("#msgBoxYes").text(textYes);
        } else {
            $("#msgBoxYes").text(Lang.trans('js/misc.okay'));
        }

        if(typeof textNo !== typeof undefined) {
            $("#msgBoxNo").text(textNo);
        } else {
            $("#msgBoxNo").text(Lang.trans('js/misc.cancel'));
        }

        if (large) {
            $('#msgBox').addClass('msg-box-wide');
        } else {
            $('#msgBox').removeClass('msg-box-wide');
        }
        if(_premium)
        {
            $('#msgBox').addClass('msg-box-premium');
            _premium = false;
        }
        else
        {
            $('#msgBox').removeClass('msg-box-premium');
        }
        
        _enableButtons();

        $(".ol-msg-box-header").html(header);
        $(".ol-msg-box-content").html(text);
        $("#msgBoxNo").show();
        $("#msgBox").css('display', 'flex').hide().fadeIn(300);
        $("#msgBox").disableSelection();
        _open = true;
        _type = "question";
    }
    
    olMsgBox.msg = function(header, text, callbackYes, textYes, large)
    {
        $("#msgBox").css({'z-index': 10000});
        if(typeof callbackYes === "undefined") callbackYes = false;
        if(typeof textYes !== typeof undefined) {
            $("#msgBoxYes").text(textYes);
        } else {
            $("#msgBoxYes").text(Lang.trans('js/misc.okay'));
        }

        if (large) {
            $('#msgBox').addClass('msg-box-wide');
        } else {
            $('#msgBox').removeClass('msg-box-wide');
        }
        
        _callbackYes = callbackYes;
        
        if(_premium)
        {
            $('#msgBox').addClass('msg-box-premium');
            _premium = false;
        }
        else
        {
            $('#msgBox').removeClass('msg-box-premium');
        }
        
        _enableButtons();
        
        $(".ol-msg-box-header").html(header);
        $(".ol-msg-box-content").html(text);
        $("#msgBox").css('display', 'flex').hide().fadeIn(300);
        $("#msgBoxNo").hide();
        $("#msgBox").disableSelection();
        $('#msgBox .icon-close_button').show();
        _open = true;
        _type = "msg";
    }
    
    olMsgBox.msgBoxBlock = function(header, text, callbackYes, textYes, large) {
        olMsgBox.msg(header, text, callbackYes, textYes, large);
        $("#msgBoxYes").hide();
        $('#msgBox .icon-close_button').hide();
    }
    
    olMsgBox.msgNoClose = function(header, text, callbackYes, textYes, large) {
        olMsgBox.msg(header, text, callbackYes, textYes, large);
        $('#msgBox .icon-close_button').hide();
    }
    
    olMsgBox.onClickYes = function()
    {
        _close();
        if(_callbackYes !== false) 
        {
            // click spam protection
            _disableButtons();
            _callbackYes();
        }
    }
    
    olMsgBox.onClickNo = function()
    {
        _close();
        if(_callbackNo !== false) 
        {
            // click spam protection
            _disableButtons();
            _callbackNo();
        }
        if(_type === "msg" && !$('#msgBox').hasClass('msg-box-premium') && _callbackYes !== false) _callbackYes();
    }
    
    olMsgBox.getCurrentJSON = function()
    {
        return _json;
    };
    
    var msgBoxHasChild = "false"; 
    olMsgBox.html = function(html) {          
        if(msgBoxHasChild == "false"){
            $('<div id="msgBox-gtc"></div>').appendTo('#msgBox');
            msgBoxHasChild = "true";            
        }
        $("#msgBox-gtc").html(html);
        $("#msgBox").show();
        $(".ol-msg-box").hide();
    };
    
    olMsgBox.postLoad = function(url, data, callbackYes, callbackNo)
    {
        $.post(url, data, function(response)
        {
            _json = response;
            olMsgBox.json(response, callbackYes, callbackNo);
        });
    };
    
    function _disableButtons()
    {
        $('#msgBoxYes, msgBoxNo').prop('disabled', true);
    }
    
    function _enableButtons()
    {
        $('#msgBoxYes, msgBoxNo').prop('disabled', false);
    }
    
    function _close()
    {
        $("#msgBox").fadeOut(300);
        _type = _open = false;
    }
    
    olMsgBox.close = function()
    {
        _close();
    };
    
}( window.olMsgBox = window.olMsgBox || {}, jQuery ));