// TODO Move to namespace object

function initializeNewStadium() {
    $("#mainBanner").owlCarousel({
        loop:true,
        margin:0,
        nav:true,
        items:1,
        autoplay:false,
        autoplayTimeout:5000,
        autoplayHoverPause:false,
        dots:false,
        touchDrag:false,
        mouseDrag:false,
        pullDrag:false,
        freeDrag:false,
        smartSpeed: 1500,
        onInitialized: function() {
            manageContentHeight();
        },
    })/*
    .trigger('stop.owl.autoplay')*/;



    $(function(eReady) {
        $('.createStadiumButton').on('click', function(e) {
            var buttonLink = $('#mainBanner').find('.owl-item.active').find('.button-link').val();
            var buttonOnclick = $('#mainBanner').find('.owl-item.active').find('.button-onclick').val();

            if (buttonOnclick != '') {
                stadiumNotReadyAlert(buttonOnclick);
            } else {
                //window.location.href = buttonLink;
                olAnchorNavigation.load(buttonLink);
            }
        });
        removeLoadingAnimationFromElement($('#ol-root'));
        addLoadingAnimationToElementNoTimeOut($(".new-stadium-banner")); 
    });

    $('.mainImage').on('load', function() {
        //removeLoadingAnimationFromElement($('#content'));
        $(this).animate({opacity: 1}, 250, function() {removeLoadingAnimationFromElement($(".new-stadium-banner"));})
    }).each(function() {
        if(this.complete) {
            $(this).load();
        }
    });
}

function stadiumNotReadyAlert() {
    olMsgBox.msgById('stadiumNotReadyAlert');
}

function manageContentHeight() {
    $('.owl-item').each(function() {
        if ($(this).find('.leftTitle').innerHeight() > $(this).find('.rightTitle').innerHeight()) {
            $(this).find('.rightTitle').css('min-height', $(this).find('.leftTitle').innerHeight());
        } else if ($(this).find('.leftTitle').innerHeight() < $(this).find('.rightTitle').innerHeight()) {
            $(this).find('.leftTitle').css('min-height', $(this).find('.rightTitle').innerHeight());
        }

        if ($(this).find('.leftSubTitle').innerHeight() > $(this).find('.rightSubTitle').innerHeight()) {
            $(this).find('.rightSubTitle').css('min-height', $(this).find('.leftSubTitle').innerHeight());
        } else if ($(this).find('.leftSubTitle').innerHeight() < $(this).find('.rightSubTitle').innerHeight()) {
            $(this).find('.leftSubTitle').css('min-height', $(this).find('.rightSubTitle').innerHeight());
        }

        if ($(this).find('.firstLeftContent').innerHeight() > $(this).find('.firstRightContent').innerHeight()) {
            $(this).find('.firstRightContent').css('min-height', $(this).find('.firstLeftContent').innerHeight());
        } else if ($(this).find('.firstLeftContent').innerHeight() < $(this).find('.firstRightContent').innerHeight()) {
            $(this).find('.firstLeftContent').css('min-height', $(this).find('.firstRightContent').innerHeight());
        }

        if ($(this).find('.secondLeftContent').innerHeight() > $(this).find('.secondRightContent').innerHeight()) {
            $(this).find('.secondRightContent').css('min-height', $(this).find('.secondLeftContent').innerHeight());
        } else if ($(this).find('.secondLeftContent').innerHeight() < $(this).find('.secondRightContent').innerHeight()) {
            $(this).find('.secondLeftContent').css('min-height', $(this).find('.secondRightContent').innerHeight());
        }

        if ($(this).find('.thirdLeftContent').innerHeight() > $(this).find('.thirdRightContent').innerHeight()) {
            $(this).find('.thirdRightContent').css('min-height', $(this).find('.thirdLeftContent').innerHeight());
        } else if ($(this).find('.thirdLeftContent').innerHeight() < $(this).find('.thirdRightContent').innerHeight()) {
            $(this).find('.thirdLeftContent').css('min-height', $(this).find('.thirdRightContent').innerHeight());
        }

        if ($(this).find('.fourthLeftContent').innerHeight() > $(this).find('.fourthRightContent').innerHeight()) {
            $(this).find('.fourthRightContent').css('min-height', $(this).find('.fourthLeftContent').innerHeight());
        } else if ($(this).find('.fourthLeftContent').innerHeight() < $(this).find('.fourthRightContent').innerHeight()) {
            $(this).find('.fourthLeftContent').css('min-height', $(this).find('.fourthRightContent').innerHeight());
        }
    });
}