// helpsystem-widget ruft die Funktion olHelpSystem.initElement zur Aufnahme in helper_list auf
// gleiche Prios möglich, es wird die zuerst hinzugefügte verwandt
//                        Priofolge: 1 am höchsten, dann absteigen ganze zahlen
// Anzeige der höchsten Prio erfolgt sofort, danach selbstverwaltend
(function(olHelpSystem, $) 
{    
	var helpers = {};
	var show_first_timer = null;
	var debug = false;
	
	olHelpSystem.hideAll = function() {
		$.each($('.helpsystem_bubble'), function(i, element) {
			$(element).fadeOut('slow');
		});
	};
	
	olHelpSystem.initElement = function(domid) {
		// prüfe, ob helper mit derselben ID schon in Liste
		removeHelperFromList(domid);
		var $helper = $('#' + domid);
		if ($helper.length == 0) {
			if (debug) console.log("init help for salt " + domid + " domelement not found - ignore");
			return;
		}
		helpers[domid] = $helper;
		if (debug) console.log("init help for salt " + domid + ", prio=" + $helper.data('prio'));
		// timer zum Warten der Anzeige der ersten Hilfe
		if (!show_first_timer) {
			show_first_timer = window.setTimeout(function() {
				show_first_timer = null;
				olHelpSystem.showNextHelp();
			}, 1000);
		}
	};
	
	// klick auf das Element, das Hilfe benötigt
	olHelpSystem.onClickElement = function(event) {
		var $referto = $(event.currentTarget);
		var $helper = $referto.data('belongs2helper');
		if (debug) console.log("clicked on element " + $referto.attr('id') + " of helper " + $helper.attr('id'));
		olHelpSystem.clearUp($helper);
	};
    
	// klick auf das HilfeElement
	olHelpSystem.onClickHelp = function(event, helperElement, url) {
		var $helper = $(helperElement);
		$target = $(event.target);
		if ($target.hasClass('icon-icon_helpsystem')) {
			// URL aufrufen
			if ((typeof(url) != 'undefined') && (url != '') && ($helper.data('uid') > 0)) {
				olManual.open(url);
			}
		}
		if (debug) console.log("clicked on helper " + $helper.attr('id') + ", " + ($target.hasClass('helpsystem_close') ? "just closed" : "url-openend:" + url));
		olHelpSystem.clearUp($helper);
	};
    
    function removeHelperFromList(domid) {
    	$helper = helpers[domid];
    	if (typeof($helper) == 'undefined') {
    		return;
    	}
		// clickHandler an allen Elementen entfernen
		var $referto = $($helper.data('referto'));
		$.each($referto, function(i, element) {
			var $element = $(element);
			$element.removeData('belongs2helper');
			$element.unbind( "click", olHelpSystem.onClickElement );
		});
		delete helpers[domid];
    }
	
	// Datenbereinigung und Server Bescheid melden
	olHelpSystem.clearUp = function($helper) {
        
		// ID/SALT speichern
		var data = new Object();
		data.salt = $helper.attr('id');
		data._token = $helper.data('csrf');
		var uid = $helper.data('uid');
		// store in browser storage, if not loggedin
		if (uid == 0) {
			uid = data._token;
			sessionStorage.setItem("helpsystem_" + data.salt , "seen");
			sessionStorage.setItem("helpsystem_" + data.salt + '_' + uid, "seen");
		}
		// ausblenden
	    $helper.fadeOut("slow", function() {
			// helper aus Liste und DOM entfernen
	        removeHelperFromList(data.salt);
			// zeige nächste Hilfe
			olHelpSystem.showNextHelp();
		});
		// Server Bescheid sagen, wenn eingeloggter User
		if (uid != 0)
		{
			$.post("/user/helpsystem/seen", data, function() {
						if (debug) 
						{
							console.log("help system saved!");
						}
					})
					.fail(function(transport){
						console.log("Error saving help system status!" + transport.statusText);
						console.log(transport.responseText);
					});
			};
		}
	
	// show Next Help
	olHelpSystem.showNextHelp = function() {
		// walkthrough
		var helper_list = Object.keys(helpers);
		if (helper_list.length == 0) {
            if (debug) console.log("No help left on this page");
			return;
		}
		var $nextHelper = undefined;
		$.each(helpers, function(i, $helper){
			if (typeof($nextHelper) == 'undefined') {
				$nextHelper = $helper;
			}
			// store in browser storage
			var uid = $helper.data('uid');
			if (uid == 0) {
				uid = $helper.data('csrf');
			}
			// check seen session browser storage
		    var alreadySeen = sessionStorage.getItem("helpsystem_" + $helper.attr('id') + '_' + uid);
		    if ((typeof(alreadySeen) != 'undefined') && (alreadySeen == 'seen')) {
				if (debug) console.log("helper " + $helper.attr('id') + " referto " + ($($helper.data('referto')) ?  $($helper.data('referto')).attr('id') : '?') + " im browser session storage -> überspringen");
				if ($nextHelper == $helper) {
					$nextHelper = undefined;
				}
	        	return;
            }
			var $referto = $($helper.data('referto'));
			if (!$referto.is(':visible')) {
				if (debug) console.log("helper " + $helper.attr('id') + " referto " + $referto.attr('id') + " nicht sichtbar -> überspringen");
				// Eintrag in helper_list überspringen, setze nextHelper
				$referto.visibilityChanged({
				    callback: function(element, visible, initialLoad) {
				       // do something here
				       // element: the element in question
				       // visible: (bool) if the element is currently visible
				       // initialLoad: (bool) if the callback is being trigger for the
				       //   first time on an element (i.e. on page load)
				    	if (visible) {
				    		olHelpSystem.showNextHelp();
				    	}
				    },
				    runOnLoad: false,
				    frequency: 100
				});
				if ($nextHelper == $helper) {
					$nextHelper = undefined;
				}
				return;
			}
			// suche kleinste Prio der Elemente, die auch noch im DOM sind und referto sichtbar ist
			if ($nextHelper.data('prio') > $helper.data('prio')) {
				$nextHelper = $helper;
			}
		});
		var $helper = $nextHelper;
		if (typeof($helper) == 'undefined') {
            if (debug) console.log("helper undefined:", helpers);
            return;
		}
		// falls Timingprobleme bestehen, wid dieser bereits angezeigt
		if ($helper.is(':visible')) {
            if (debug) console.log("helper already visible:" + $helper.attr('id'));
			return;
		}
		if (debug) console.log("Help-System: will show helper",  $helper.data());
		var $referto = $($helper.data('referto'));
		if ($referto.length == 0) {
		    if (debug) console.log("Help-System: Referenz fehlt");
            removeHelperFromList($helper.attr('id'));
            olHelpSystem.showNextHelp();
		    return;
    	}
		var atLeastVisibleOne = false;
		$.each($referto, function(i, element) {
			if ($(element).is(':visible')) {
				atLeastVisibleOne = true;
				return false;
			}
		});
		if (!atLeastVisibleOne) {
			// unbind on referto nicht nötig, da auch refer aus DOM entfernt
			if (debug) console.log("einziger helper " + $helper.attr('id') + " kein referto " + $helper.data('referto') + " nicht sichtbar");
			return;
		}
		// beim Klick auf das element finden wir dann Helper
		$.each($referto, function(i, element) {
			var $element = $(element);
			$element.data('belongs2helper', $helper);
			$element.bind( "click", olHelpSystem.onClickElement );
		});
		if ($referto.length > 1) {
		    if (debug) console.log("Help-System: mehrere Referenzen gefunden, nehme den 1. zur Positionierung");
		    $referto = $($referto[0]);
	    }
		// .position() uses position relative to the offset parent, 
		var adjacent = $helper.data('adjacent');

		var customOffset = $helper.data('offset');

		var helperPos = {'position':'absolute', 'zindex:': 5000};
		// if (debug) console.log('refer.offset:',$referto.offset(),', refer.position:', $referto.position())
	    var position = $referto.position();    
		var margin = 10;
		switch (adjacent) {
			case "top":
				helperPos.left = position.left + 'px';
				helperPos.top = (position.top - $helper.outerHeight() - margin) + 'px';
				break;
			case "left":
				helperPos.left = (position.left - $helper.outerWidth() - margin) + 'px';
				helperPos.top = position.top  + 'px';
				break;
			case "bottom":
				helperPos.left = position.left + 'px';
				helperPos.top = (position.top + $referto.outerHeight() + margin) + 'px';
				break;
			case "right":
				helperPos.left = (position.left + $referto.outerWidth() + margin) + 'px';
				helperPos.top = position.top + 'px';
				break;
		}
	    // helper anzeigen beim dom element
		if (debug) console.log("Helpsystem: show at pos:" , helperPos);

		if (customOffset.top != undefined)  {
			helperPos.top = parseInt(helperPos.top) + customOffset.top + "px";
		}
		if (customOffset.left  != undefined) {
			helperPos.left = parseInt(helperPos.left) + customOffset.left + "px";
			console.log("left")
		}

		console.log("after", helperPos, "custom", customOffset)

		$helper.css(helperPos).fadeIn("slow");
		
		var arrowOffset = $helper.data('arrowoffset');
		if (!arrowOffset) {
			arrowOffset = {left:0, top:0};
		}
		
		if ($helper.outerWidth() + $helper.offset().left > window.innerWidth) {

			var offset = ($helper.outerWidth()- ((window.innerWidth - $helper.offset().left))) + 10;

			console.log("offset", offset);
			$helper.css('left', (parseInt(helperPos.left) - offset) + 'px');
			arrowOffset.left = offset;
		}
		if (arrowOffset.left != 0) {
			$helper.find('.helpsystem-arrow').css('margin-left', (arrowOffset.left - 12)+"px");
		}
		if (arrowOffset.top != 0) {
			$helper.find('.helpsystem-arrow').css('margin-top', arrowOffset.top+"px");
		}

		console.log($helper.get(0));
	};
		
}( window.olHelpSystem = window.olHelpSystem || {}, jQuery )); 