window.olNavigationModules = window.olNavigationModules || {};

// constructor navigation module: time
window.olNavigationModules.time = (function(olNavTime, $, packets, subcall, undefined) {
    var _season = -1;
    var _week = -1;
    var _enabled = {season: true, week: true};

    // navigation, dropdowns, inputs, etc.
    var $nav, $season, $week;

    olNavTime.init = function(config) {
        $nav = $('#navTime');
        $season = $('#dropdown-navSeasons.dropdown', $nav);
        $week = $('#dropdown-navWeeks.dropdown', $nav);

        if (typeof config.season === typeof 0 && config.season > 0) {
            $season.dropdown().selectByValue(config.season);
            _season = config.season;
        }
        if (typeof config.week === typeof 0 && config.week > 0) {
            $week.dropdown().selectByValue(config.week);
            _week = config.week;
        }
        if ($.isPlainObject(config.enabled)) {
            if (typeof config.enabled.season === typeof 0) {
                _enabled.season = config.enabled.season === 1;
                if (config.enabled.season !== 1)
                    $season.dropdown().disable();
            }
            if (typeof config.enabled.week === typeof 0) {
                _enabled.season = config.enabled.week === 1;
                if (config.enabled.week !== 1)
                    $week.dropdown().disable();
            }
        }
    };

    packets.time = function() {
        var data = {};

        if (_season > 0)
            data.season = _season;
        if (_week > 0)
            data.week = _week;

        return data;
    };

    olNavTime.enabled = function(enable, type) {
        if (typeof enable === typeof undefined)
            return;
        if (typeof type === typeof undefined)
            type = 'all';

        if (enable) {
            if (type === 'all' || type === 'season')
                $season.dropdown().enable();
            if (type === 'all' || type === 'week')
                $week.dropdown().enable();
        } else {
            if (type === 'all' || type === 'season')
                $season.dropdown().disable();
            if (type === 'all' || type === 'week')
                $week.dropdown().disable();
        }
    };

    olNavTime.reset = function() {
        var season = olTimeHelper.getCurrentSeason();
        $season.dropdown().selectByValue(season);
        _season = season;

        var week = olTimeHelper.getCurrentWeek();
        $week.dropdown().selectByValue(week);
        _week = week;
    };

    olNavTime.onChangeSeason = function(val) {
        if (typeof val !== typeof 0 || val <= 0)
            return;

        _season = val;
        subcall();
    };

    olNavTime.onChangeWeek = function(val) {
        if (typeof val !== typeof 0 || val <= 0)
            return;

        _week = val;
        subcall();
    };
});
