// TODO Should be olMessageSystem
var globalSelectedUser = {id: 0, type: 0, online: 1};
(function(messageSystem, $, undefined) {

    var emojione = window.emojione;
    var globalIsLarge = true; //large messenger
    var loadMorePage = 0;
    var inboxIsActive = false;
    var showRoomMessageIsActive = false;
    var initialized = false;
    var globalMessageWindowPos;
    var justOneTime = 0;
    var timerfactor = 1;
    var sum = 0;
    //var messagetypes = [2, 4, 5, 10, 11, 12, 13,14,15]; //Anzuzeigende Messagetypes
    var messagetypes = olConfig.messageTypes; // Anzuzeigende Messagetypes
    var maxCharacter = 1000; //maximale Anzahl einzugebender Zeichen im Chat
    var messageContentHeight = 0;
    var messageContentHeightText = 0;
    var scrollSet = 0; // Unterscheidung Aufruf einzelne Nachricht, Nachrichtenliste
    var reset = 0; // reset = 1, wenn Nachrichtenfenster neu geoeffnet wird
    var chat = 0; // Chatfenster chat = 1
    var smallFirst = 1;// Nachrichten bei Small beim ersten Aufurf anzeigen
    var typingTimeout = 0;
    var lastTyping = 0;
    var audio;
    var audioEnabled = false;
    var statusArray = {};
    var enableMsgTutorial = false;

    messageSystem.init = function(config) {
        if (!olEcho.enabled) {
            //olMsgBox.msg("Fehler im Chatsystem", "Es gab einen Fehler beim Laden des Chatsystems. Chat ist vorrübergehend nicht verfügbar.");
            return;
        }
        enableMsgTutorial = config.msgTutorial;

        audio = {}
        audio.newConversation = new Audio();
        audio.newConversation.src = olConfig.image_host + "sounds/messenger_notification_01.mp3";
        audio.newConversation.onended = function(ev) {
            ev.target.currentTime = 0;
        }
        audio.newMessage = new Audio();
        audio.newMessage.src = olConfig.image_host + "sounds/messenger_notification_02.mp3";
        audio.newMessage.onended = function(ev) {
            ev.target.currentTime = 0;
        }

        olMobileApp.listen('closeMessenger', function() {
            messageSystem.closeMessageWindow();
            messageSystem.toggle();
        });

        olMobileApp.listen('toggleMessenger', function() {
            messageSystem.showMessages();
            messageSystem.toggle();
        });

        messageSystem.loadMessageSystem();
        messageSystem.userlistFilter();
        messageSystem.closeMessageWindow()
    };

    messageSystem.initMessageWindow = function() {
        $("#messenger-tiny-container").removeClass("showMessage");
        $("#messenger-large-container").removeClass("showMessage");
        var messageWindow = $("#message-window");
        messageSystem.emojiInput();
        messageWindow.draggable({handle: $('.message-head')});
        messageWindow.resizable();
        var userList = $('#messagesystem-userlist');
        if (userList.length > 0)
        {
            globalMessageWindowPos = userList.offset();
        }
    };

    messageSystem.loadMessageSystem = function() {
        $.ajax({
            url: '/messenger/init',
            type: 'GET',
            success: function(data) {
                $('#messenger').html(data);
                messageSystem.onLoadSuccess();
                if (globalIsLarge && !$('body').hasClass('ol-xs')) {
                    messageSystem.upsize();
                    $("#messenger-large-container").hide();
                    $("#messenger-large-background").hide();
                    initialized = true;
                } else {
                    messageSystem.downsize();
                    $("#messenger-tiny-container").hide();
                    $("#messenger-tiny-background").hide();
                    initialized = true;
                }
            }
        });
    };

    messageSystem.backToMessageList = function() {
        if (!globalIsLarge) {
            $('.message-head').show();
        }

        $('#messagelist-container').show();
        $('#showRoomMessageList').hide();
        $('#showRoomMessageList').html('');
        $('.ol-messenger-head-delete-all').show();
        
        showRoomMessageIsActive = false;
        inboxIsActive = true;
        messageSystem.inboxRefresh();

        // Auskommentierung verhindert erneuten Aufbau der Nachrichtenliste
        //messageSystem.messageWindow(undefined, messagetypes); //andere Nachrichten
    };
    
    messageSystem.handleTyping = function(data) {
        if ($(".message-window").is(':visible') && (globalSelectedUser.id == data.sourceId)) {
            showTyping();
        }
    };
    
    function showTyping() {
        if (typingTimeout == 0) {
            $('.message-window .typing-indicator').show();
        }
        clearTimeout(typingTimeout);
        typingTimeout = setTimeout(hideTyping, 5000);
    }
    
    function hideTyping() {
        $('.message-window .typing-indicator').hide();
        clearTimeout(typingTimeout);
        typingTimeout = 0;
    }
    
    messageSystem.handleOnlineStatus = function(data) {
        data = data.data;
        statusArray[data.userId] = data.online;

        updateOnlineStatus();
    };

    function updateOnlineStatus () {
        $('.messagesystem-selectable-element').removeClass('is-online');

        for (var uid in statusArray) {
            if (statusArray[uid]) {
                $('#selectable-user-'+uid).addClass('is-online');
            }
        }

        // console.log(statusArray[globalSelectedUser.id], globalSelectedUser.id)

        if (statusArray[globalSelectedUser.id]) {
            $('#message-window').addClass('is-online');
        } else {
            $('#message-window').removeClass('is-online');
        } 
    }
    
    messageSystem.handleMessage = function(data) {
        data = data.data;
        // console.log("New Message on Websocket", data);
        
        var userId = (olUid == data.message.senderUserId) ? data.message.receiverUserId : data.message.senderUserId;
        
        if ($(".message-window").is(':visible') && (globalSelectedUser.id === userId)) {
            // Handle messages of current conversation
            if (audioEnabled) 
                audio.newMessage.play()
            // console.log("Refreshing Chat");
            messageSystem.chatRefresh(userId);
            hideTyping();
        } else {
            // Handle other messages
            if (audioEnabled) {
                audio.newConversation.play();
            }
            messageSystem.updateMessageCountAndOnlineStatusIntern();
        
            if ((inboxIsActive && $(".message-window").is(':visible')) || showRoomMessageIsActive) {
                messageSystem.inboxRefresh();
            }
        }
        
        messageSystem.headerMessagecounters();
    };

    messageSystem.loadUserList = function(activeUserId) {
        var filterVal = $("#messagesystem-filters > div").attr("data-value");
        $.get('/messenger/userlist', function(data) {
            $('#messagesystem-userlist-container').html(data);
            if (filterVal === "0" || filterVal === 0) {
                messageSystem.userlistFilter();
            } else if (filterVal === "3" || filterVal === 3) {
                messageSystem.userlistFilter(0);
            } else {
                messageSystem.userlistFilter(parseInt(filterVal));
            }
        }).done(function() {
            if (activeUserId !== 'undefined') {
                messageSystem.activeUserlistButton($("#selectable-user-" + activeUserId + " > button"));
            };
            messageSystem.updateMessageCountAndOnlineStatusIntern();
        });
    };

    messageSystem.onLoadSuccess = function() {
        if ($('#messagesystem-userlist').length > 0) {
            $("#messagesystem-userlist").draggable({handle: $('#messagesystem-userlist-head')});

            audioEnabled = !$('.messenger-sound-icons').hasClass('sound-off');
            
            olEcho.userChannel().listen('ChatMessage', messageSystem.handleMessage);
            olEcho.userChannel().listen('UserTyping', messageSystem.handleTyping);
            olEcho.userChannel().listen('OnlineStateUpdate', messageSystem.handleOnlineStatus)
            
            messageSystem.updateMessageCountAndOnlineStatus(); //direkter Aufruf zur Ermittlung nicht gelesener Nachrichten -> erster Aufruf nach 15000
            messageSystem.initMessageWindow();
            messageSystem.messageWindow(undefined, messagetypes);
            messageSystem.activeUserlistButton($("#selectable-news > button"));
            
            var suppressAnimation = true;
            if (location.pathname == "/") {
                suppressAnimation = false;
            }
            messageSystem.updateMessageCountAndOnlineStatusIntern(false, suppressAnimation); // sofortiger direkter Aufruf , obiger Aufruf updateMessageCountAndOnlineStatus startet erst nach 15000
        }
    };

    messageSystem.showMessages = function() {
        reset = 1;
    };

    messageSystem.messageWindow = function(userId, types, messageId, templateId, relationType) {
        $('.ol-messenger-head-delete-all').hide();
        $("#messenger-tiny-container").removeClass("showMessage");
        $("#messenger-large-container").removeClass("showMessage");
        if ($(".messagecount").html() == '') {
            $(".messagecount").html(0);
        }
        var messageWindow = $("#message-window");
        inboxIsActive = false;
        loadMorePage = 0;
        if (!globalIsLarge) {
            globalMessageWindowPos = messageSystem.getElementPos($('#messagesystem-userlist'));
        }
        globalSelectedUser.id = userId;
        globalSelectedUser.type = relationType;

        if (typeof relationType !== 'undefined' && typeof userId !== 'undefined') { //from user list
            messageSystem.activeUserlistButton($("#selectable-user-" + userId + " > button"));
            messageSystem.resetMessagecount(userId);
            messageSystem.loadChatContent(userId, messageWindow, relationType);
        } else if (typeof userId !== 'undefined') { //from user search
            $('#user-search-results').hide();
            messageSystem.activeUserlistButton($("#selectable-user-" + userId + " > button"));
            messageSystem.loadUserList(userId);
            messageSystem.loadChatContent(userId, messageWindow);
        } else if (typeof messageId !== 'undefined' && typeof types !== 'undefined') { // specific message
            $('.list' + messageId).css('color', '#b2b2b2');
            if (!$('.list' + messageId).hasClass('ol-message-already-read')) {
                $('.list' + messageId).addClass('ol-message-already-read');
            }
            scrollSet = 1; // Aufruf einer Nachricht zum Anzeigen
            messageSystem.resetMessagecount(0, $('.systemContentBig.messageListEntryId' + messageId).hasClass('ol-message-already-read'));
            messageSystem.loadMessage(messageId, templateId, messageWindow, types);
        } else if (typeof types !== 'undefined') { //specific types
            scrollSet = 0; // Aufruf einer Nachricht zum Anzeigen
            messageSystem.resetMessagecount(0);
            messageSystem.loadInbox(types, messageWindow);
            $('.ol-messenger-head-delete-all').show();
        } else if (typeof messageId !== 'undefined') { //message in specific template
            messageSystem.loadMessage(messageId, templateId, messageWindow);
        } else {
            $('.ol-messenger-head-delete-all').show();
        }
        //messageSystem.resetMessagecount(userId);
        if (!globalIsLarge) {
            messageSystem.setMessageWindowPos(globalMessageWindowPos);
        }

        messageSystem.headerMessagecounters();
    };

    messageSystem.isLarge = function() {
        //return window.OL_APP_MODE || globalIsLarge;
        return olAppInfo.APP_MODE || globalIsLarge;
    };
    
    messageSystem.loadChatContent = function(userId, messageWindow, relationType) {
        messageContentHeight = 0;
        var url;
        if (globalIsLarge) {
            url = '/messenger/user/' + userId + '/4';
        } else {
            url = '/messenger/user/' + userId + '/5';
        }
        if (userId == 1) {
            url = '/messenger/user/' + userId + '/6';
        }
        messageSystem.addLoadingAnimation();
        
        $.get(url,function(data) {
            globalSelectedUser.id = userId;

            messageWindow.removeClass('is-online');
            messageWindow.show();
            messageWindow.html(data);
            messageSystem.emojiInput(relationType);
            $('#messagesystem-usermeta-container').find('.enemie > span').each(function(index, element) {
                setStateFontColor(element, $(element).attr('data-state'));
            });
            messageSystem.chatRefresh(userId);
            var msgcon = $('#message-container');
            msgcon.scrollTop(msgcon[0].scrollHeight);
            if (globalIsLarge) {
                $(".message-head").hide();
                $('#messagesystem-usermeta-container').removeClass('collapse');
            } else {
                messageWindow.draggable({handle: $('.message-head'), containment: 'window'});
                messageWindow.resizable();
                $("#messagesystem-userlist").toggle();
            }
            if (!olGUI.isMobileOrTabletDevice())
                $("#message-content").data("emojioneArea").editor.focus();
            loadMorePage = 1;
            msgcon.scroll(messageSystem.loadMoreMessages);

            if (statusArray[userId]) messageWindow.addClass('is-online');
        }).done(function() {
            messageSystem.removeLoadingAnimation();
            updateOnlineStatus();
        }).error(function() {
            messageSystem.removeLoadingAnimation();
            updateOnlineStatus();
        });
    };

    messageSystem.loadMoreChatContent = function() {
        var userId = globalSelectedUser.id;
        var msgcon = $("#message-container");
        $.get("/messenger/show/more/" + userId + "/" + loadMorePage, function(data) {
            if (data !== 'undefined' && data !== "") {
                var msgcon = $("#message-container");
                var scrollHeight = msgcon[0].scrollHeight;
                $("#message-container .chat").prepend(data);
                msgcon.scrollTop(msgcon[0].scrollHeight - scrollHeight);
                loadMorePage++;
            }
        });
    };

    messageSystem.loadMoreMessages = function() {
        var msglcon = $("#messagelist-container");
        //if (inboxIsActive && (msglcon[0].scrollHeight <= (msglcon.scrollTop() + msglcon.innerHeight())) && !justOneTime) {//news 

        if (inboxIsActive && (msglcon.scrollHeight <= (msglcon.scrollTop() + msglcon.innerHeight()))) {//news 
            messageSystem.loadMoreInbox();
        } else if ($("#message-container").scrollTop() == 0) {//chat
            messageSystem.loadMoreChatContent();
        }
    };

    messageSystem.loadUserMeta = function(userId) {
        if (userId !== 0) {
            var metaCont = $('#messagesystem-usermeta-container');
            $.get('/userrelations/usermeta/' + userId + '/' + globalIsLarge, function(data) {

                metaCont.html(data);
                metaCont.find('.enemie > span').each(function(index, element) {
                    setStateFontColor(element, $(element).attr('data-state'));
                });
            });
        }
    };

    messageSystem.loadInbox = function(types, messageWindow) {
        if (justOneTime == 0) {
            justOneTime = 1; // loadInbox nur nach Rueckgabe der Daten vom Server 
            var messageInboxLoad = true;
            if ($(".message-window").is(':visible')) {
                messageSystem.addLoadingAnimation();
            }

            var scrollTop;
            $.post('/messenger/inbox', {types: types, page: loadMorePage}, function(data) {
                scrollTop = $('#messagelist-container').scrollTop();
                messageSystem.toMessageWindow(data, messageWindow);
                inboxIsActive = true;
                messageSystem.inboxRefresh();
                if (globalIsLarge) {
                    $(".message-head").hide();
                } else {
                    //$("#messagesystem-userlist").hide();
                }
                loadMorePage = 1;
                $('#messagelist-container').scroll(messageSystem.loadMoreMessages);
            }).done(function() {
                justOneTime = 0; // Server hat Daten gesendet
                messageSystem.removeLoadingAnimation();
                messageInboxLoad = false;
                if (typeof data == 'undefined' || data == "") {
                    var usr = $("#messagecount-user-0");
                    if (document.getElementById('messagecount-user-0').innerHtml == "0") {
                        usr.removeClass('ol-badge');
                        usr.text('');
                    }
                }
                $('#messagelist-container').scrollTop(scrollTop);
            }).error(function() {
                justOneTime = 0; // Server hat Daten gesendet
                messageSystem.removeLoadingAnimation();
                messageInboxLoad = false;
                // im Fehlerfall, Server gibt keine Daten zurueck
                // window.location.assign("/");
            });
        }
    };

    messageSystem.loadMoreInbox = function() {
        if (justOneTime == 0) {
            justOneTime = 1; // loadInbox nur nach Rueckgabe der Daten vom Server 
            var msglcon = $('#messagelist-container:last-child');
            $.post('/messenger/inbox', {page: loadMorePage}, function(data) {
                if (loadMorePage > 0) {
                    $('#messagelist-container').last().append(data);
                }
            }).success(function() {
                inboxIsActive = true;
                loadMorePage++;
            }).done(function() {
                justOneTime = 0; // Server hat Daten gesendet
                messageSystem.removeLoadingAnimation();
            }).error(function() {
                justOneTime = 0; // Server hat Daten gesendet
                messageSystem.removeLoadingAnimation();
            });
        }
    };

    messageSystem.loadMessage = function(messageId, templateId, messageWindow, type) {
        $("#messenger-large-container").addClass("showMessage");
        messageSystem.addLoadingAnimation();
        var params = {messageId: messageId, templateId: templateId, type: type};
        $.get("/messenger/show", params, function(data) {
            messageWindow = $("#showRoomMessageList");
            messageSystem.toMessageWindow(data, messageWindow);
        }).success(function(data) {
            if (data == 'false') {
                olMsgBox.msg(Lang.trans('js/messenger.hint'), Lang.trans('js/messenger.msgDeletedMsg'), messageSystem.messageWindow(undefined, messagetypes));
            } else {
                messageSystem.toMessageWindow(data, messageWindow);
            }
        }).done(function() {
            messageSystem.removeLoadingAnimation();
        }).error(function() {
            messageSystem.removeLoadingAnimation();
        });
    };

    messageSystem.toMessageWindow = function(data, messageWindow) {
        $("#messagelist-container").hide();
        if (chat == 0){
            if ((scrollSet == 0) || (reset == 1)) {
                $('#showRoomMessageList').html('');
                messageWindow.html(data);
                messageWindow.show();
            }
            if (scrollSet == 1) {
                messageWindow.html(data);
                messageWindow.show();
                $('.message-head').hide();
                if (!globalIsLarge) {
                    $(".system-message-send-message-box systemContentSmall").show();
                }
            }
        } else {
            chat = 0;
        }
        //messageWindow.show();
        messageSystem.emojiInput();
        messageWindow.draggable({handle: $(".message-head"), containment: 'window'});
        messageWindow.resizable();
        if (globalIsLarge) {
            $(".message-head").hide();
        } else {
            $("#messagesystem-userlist").toggle();
        }
        if (!globalIsLarge && (reset == 1)) {
            messageSystem.closeMessageWindow();
            reset = 0;
        }
    };

    messageSystem.userlistFilter = function(type) {
        if ([0, 1, 2].includes(type)) {
            $("[name^='userrelation-type-']").hide();
            $("[name='userrelation-type-" + type + "']").show();
        } else if (type == 'ignore') {
            $("[name^='userrelation-type-']").hide();
            $("[name^='userrelation-type-']").filter("[ignore='1']").show();
        } else {
            $("[name^='userrelation-type-']").show();
        }
    };

    messageSystem.messagelistCheckbox = function(type) {
        $("[name='" + type + "-message']").toggle();
    };

    // Einbinden emojione.Area
    messageSystem.emojiInput = function(relationType) {
        if ($("#message-input").length > 0) {
            window.emojioneVersion = "2.1.1";
            $("#message-content").emojioneArea({
                container: "#emojione-content",
                hideSource: true,
                autocomplete: false,
                placeholder: Lang.trans('js/messenger.msg'),
                sprite: true,
                inline: true,
                pickerPosition: "top",
                tonesStyle: "bullet",
                events: {
                    keyup: function(editor, event) {
                        var userId = $("#message-content").attr("receiver");
                        if (event.which == 13) {
                            event.stopPropagation();
                            messageSystem.sendMessage(userId, this.getText());
                        } else {
                            messageSystem.sendTyping(userId);
                        }
                        countChar(this);
                    }
                }
            });
        }
    };
    
    messageSystem.sendTyping = function(target) {
        var now = new Date().getTime();
        if (now-lastTyping < 2000) return;
        lastTyping = now;
        $.post('/messenger/typing', {"target": target});
    };

    /*Character Counter */
    function countChar(val) {
        // Setzen der messageContentHeight
        if (messageContentHeight == 0) {
            messageContentHeight = $('#message-container').height();
            messageContentHeightText = $('#message-input').height();
            messageContentHeightTextTmp = messageContentHeightText;
        }
        var len = val.getText().length;

        $('#chars').text(Lang.trans('js/messenger.n_characters', { n: maxCharacter - len }));

        // Unterschiedliche Darstellungen, wenn Zeichenanzahl abnimmt
        // if ((1000 - len) > 50) {
        //     $('#chars').css('color', 'black');
        // }
        if ((1000 - len) < 51) {
            $('#chars').addClass('ol-orange');
        }
        if ((1000 - len) < 0) {
            $('#chars').addClass('ol-red');
        }

        // Anpassung der Hoehe der Chatbox
        if (messageContentHeightTextTmp != $('#message-input').height()) {
            var newHeight = messageContentHeight - ($('#message-input').height() - messageContentHeightText) - 10;
            $('#message-container').height(newHeight);
            var messageContentHeightTextTmp = $('#message-input').height();
        }

        var msgcon = $('#message-container');
        msgcon.scrollTop(msgcon[0].scrollHeight);
    }

    messageSystem.updateMessageCountAndOnlineStatus = function() {
        // Aufruf Funktion zur Aktualisierung des Timers
        //var intervalID = window.setInterval(messageSystem.updateMessageCountAndOnlineStatusIntern, 15000);
    };
    
            
    // Ermitteln ungelesener Nachrichten und Online User
    messageSystem.updateMessageCountAndOnlineStatusIntern = function(callback, suppressAnimation) {
        sum = 0;
        $.get("/messagecount/onlinestatus", function(data, textStatus, jqXHR) {
            if (jqXHR.getResponseHeader('Onlineliga-Processing') == 'true') {
                window.location.href = '/';
            }

            for (var idx in data) {
                var uid = parseInt(idx);
                statusArray[uid] = (data[idx].online && data[idx].online != 99) ? true : false;
            }

            $.each(data, function(key, value) {
                //// Wenn value.online == 2 ausgewaehlter Chatpartner online
                //if (value.online == 2) {
                //    // $("#user-onlinestatus").text("online");
                //    $(".message-window").addClass('is-online');
                //}
                //if (value.online == 99) {
                //    // $("#user-onlinestatus").text("online");
                //    $(".message-window").removeClass('is-online');
                //}

                //if user is not in list, refresh
                if ($(".messagecount").html() == '') {
                    $(".messagecount").html(0);
                }
                // Wenn key == 0 Nachrichten von onlineliga.de
                if (key == 0) {
                    var count = value.count;
                    if (count == null) {
                        count = 0;
                    }
                    $(".messagecount").html(count);
                    if (count !== 0) {
                        $("#messagecount-user-0").addClass('ol-badge');
                        $("#messagecount-user-0").html(count);
                    } else {
                        $("#messagecount-user-0").html('');
                    }
                    if (count == 1) {
                        $("#moreMessages").hide(); //bei 1 'en' ausblenden -> Nachricht
                    } else {
                        $("#moreMessages").show(); // bei <>1 einblenden -> Nachrichten
                    }                                                       
                }

                if (key > 0 && value.online < 2) {
                    if (!$("#messagecount-user-" + key).length) {
                        messageSystem.loadUserList(globalSelectedUser.id);
                    } else {
                        var usr = $("#messagecount-user-" + key);
                        if (!isNaN(value.count)) {
                            sum += value.count;
                        }
                        //set onlinestatus
                        usr.data("onlinestatus", value.online);
                        //messageSystem.setOnlineStatus(key, value.online);
                        //on new messages, push user element to top
                        var actualCount = $(usr).text();
                        if ((actualCount !== 'undefined') && (actualCount < value.count)) {
                            $('#selectable-news').after($('#selectable-user-' + key));
                        }

                        //add messagecount, wenn nicht vorhanden    
                        if (!document.body.classList.contains('ol-badge')) {
                            usr.addClass('ol-badge');
                        }
                        usr.text(value.count);
                    }
                    if (value.important > 0) {
                        messageSystem.openImportant();
                    }
                }
            });

            updateOnlineStatus();
        }).always(function() {
            if (!suppressAnimation) {
                messageSystem.headerMessagecounters();
            }
            if (typeof callback == "function") {
                callback();
            }
        });  
    };

    // Messagecounter um einen reduzieren, Aufruf beim Anzeigen/ Loeschen einer Systemnachricht
    messageSystem.messageCounterMinusOne = function($message) {
        // $message Object welches die Funktion aufgerufen hat -> button
        // Eltern -> Elternelement ist der Eintrag der Nachrichtenliste
        // enthaelt diese 'ol-message-already-read' wurde die Nachricht bereits gelsesen
        if (!$message.parent().parent().hasClass('ol-message-already-read')) {
            // Nachricht wurde nicht gelesen
            // Nachrichtenzaehler neu ermitteln            
            messageSystem.updateMessageCountAndOnlineStatusIntern();

        };
        setTimeout(function() {
            messageSystem.updateMessageCountAndOnlineStatusIntern();
        }, 200);    
    };   
    
    
    messageSystem.openImportant = function() {
        messageSystem.upsize();
        var messageWindow = $("#message-window");
        $.get('/messenger/getimportant', function(data) {
            messageSystem.toMessageWindow(data, messageWindow);
        }).done(function() {
            inboxIsActive = true;
            if (globalIsLarge) {
                $(".message-head").hide();
            } else {
                $("#messagesystem-userlist").toggle();
            }
        });
    };

    messageSystem.setOnlineStatus = function(uid, online) {
        if ($(".message-window").is(':visible') && (globalSelectedUser.id === parseInt(uid))) {
            if (online) {
                // $("#user-onlinestatus").text("online");
                $(".message-window").addClass('is-online');
            } else {
                // $("#user-onlinestatus").text("");
                $(".message-window").removeClass('is-online');
            }
        }
        if (online) {
            $('#selectable-user-' + uid).addClass('is-online');
        }
    };

    var lastMessages = {};
    var uniqueStateClassesCount = 0;
    messageSystem.headerMessagecounters = function() {
        var badge = "";
        //$("#messagesystem-userlist-head > .messagesystem-state-counters").empty();
        var stateClasses = $("[class^='ol-state-primary-color-']")
            .filter(".user-messagecount")
            .filter(function() {
                return $(this).text().length != 0;
            })
            .map(function() {
                return $(this).attr('class').split(" ")[0];
            });
        var uniqueStateClasses = $.unique(stateClasses);
        messageSystem.bundleHeaderMessageCountersFrom(stateClasses.length);
        var gSum = 0;
        var updateNeeded = uniqueStateClassesCount != uniqueStateClasses.length;
        var firstState = 0;
        $.each(uniqueStateClasses, function(key, value) {
            var sum = 0;
            $.each($("." + value).filter(".user-messagecount"), function() {
                sum += parseInt($(this).text()) || 0;
            });
            gSum += sum;
            if (sum > 0) {
                badge += "<div class='" + value + " ol-badge'>" + (sum > 99 ? '..' : sum) + "</div>";
            }
            if (!lastMessages.hasOwnProperty(value) || lastMessages[value] != sum) {
                updateNeeded = true;
                setTimeout(function() {
                    updateHeaderIconAnimation(sum, value);
                }, 1);
            }
            lastMessages[value] = sum;
            // console.log(value);
            if (!firstState && value != "ol-state-primary-color-0") firstState = value;
        });
        if (updateNeeded) {
            $(".messagesystem-state-counters").html(badge);
        }
        uniqueStateClassesCount = uniqueStateClasses.length;


        // Uebernehmen der Gesamtnachrichten fuer Nachrichtenfenster
        $(".messagecount").html(gSum).attr('class', 'messagecount badge ' + firstState);
        olMobileApp.updateMessageCount(gSum);

        if (gSum == 1) {
            $("#moreMessages").hide(); //bei 1 'en' ausblenden -> Nachricht
        } else {
            $("#moreMessages").show(); // bei <>1 einblenden -> Nachrichten
        }
        // changes font-size depending on characters ( Daniel )
        var messageCount = $('#mobile-message-count').html();        
        if (messageCount >= 0 && messageCount <= 9 ) {
            $('#mobile-message-count').addClass('one-digit');
        } else if(messageCount >= 10 && messageCount <= 99) {
            $('#mobile-message-count').addClass('double-digits');
        } else if(messageCount >= 100) {
           $('#mobile-message-count').addClass('three-digits');
        }
        var messageCountMessenger = $('.user-messagecount').html();
        if (messageCountMessenger >= 0 && messageCountMessenger <= 9 ) {
            $('.user-messagecount').addClass('one-digit');
        } else if(messageCount >= 10 && messageCount <= 99) {
            $('.user-messagecount').addClass('double-digits');
        } else if(messageCount >= 100) {
           $('.user-messagecount').addClass('three-digits');
        }
           
        messageSystem.messageCountTitle(messageCount);
    };

    // Message count in title 
    messageSystem.messageCountTitle = function(count) {
        var pageTitle = document.title.replace(/^\(\d+\)\s+/, '');
        if (count > 0) {
            document.title = '(' + count + ') ' + pageTitle;
        } else {
            document.title = pageTitle;
        }
    };

    messageSystem.bundleHeaderMessageCountersFrom = function(numberOfStateClasses) {
        $("#messagesystem-toggle-btn").find(".messagecount-zero").hide();
        if (numberOfStateClasses < 4) {
            if (numberOfStateClasses < 1) {
                $("#messagesystem-toggle-btn").find(".messagecount-zero").show();
            }
            $("#messagesystem-toggle-btn").find(".messagesystem-state-counters").show();
            $("#messagesystem-toggle-btn").find(".messagecount").hide();
        } else {
            $("#messagesystem-toggle-btn").find(".messagesystem-state-counters").hide();
            $("#messagesystem-toggle-btn").find(".messagecount").show();
        }

        if (!globalIsLarge) {
            if ((numberOfStateClasses < 4)) {
                $("#messenger-tiny-container").find(".messagesystem-state-counters").show();
                $("#messenger-tiny-container").find(".messagecount").hide();
            } else {
                $("#messenger-tiny-container").find(".messagesystem-state-counters").hide();
                $("#messenger-tiny-container").find(".messagecount").show();
            }
        } else {
            $("#messenger-large-container").find(".messagesystem-state-counters").show();
        }
    };

    messageSystem.resetMessagecount = function(userId, alreadyRead) {
        if (typeof alreadyRead === typeof undefined)
            alreadyRead = false;
        var usr = $("#messagecount-user-" + userId);

        if (!alreadyRead) {
            if (userId == 0) {
                var actualCount = $(usr).text();
                actualCount--;
                if (actualCount < 0 && userId == 0) {
                    usr.removeClass('ol-badge');
                    usr.empty();
                }
            } else {
                usr.removeClass('ol-badge');
                usr.empty();
            }
        }
    };
    
    messageSystem.prependToMessagelist = function(element) {
        if (typeof element !== 'undefined') {
            element.prependTo(element.parent());
            $("#selectable-news").prependTo(element.parent());
        }
    };

    function blendInChatMsgs() {
        setTimeout(function() {
            $('.message-chat-entry').addClass('visible');
        }, 1);
    }

    messageSystem.sendMessage = function(receiverUserId, content, relationType) {
        // Chat Nachricht uerber Icon versendet  
        if (typeof content === 'undefined') {
            var content = $("#message-content").val();
        }

        relationType = 0;

        // Pruefen ob Chatpartner in Freundesliste oder nicht
        // wenn in Freundeslist Element vorhanden
        var elementId = "messagecount-user-" + receiverUserId;
        if (document.getElementById(elementId)) {
            relationType = 1;
        }

        // Chat Nachrichten mit mehr als n Zeichen werden nicht verschickt
        if (content.length > maxCharacter) {
            olMsgBox.msg(Lang.trans('js/messenger.systemMsg'), Lang.trans('js/messenger.errorMaxCharsReached'));
        } else if ($.trim(content) != '') {
            var type = $("#type").val();
            var logo = receiverUserId % 5 + 1;
            var now = new Date($.now());

            var contentHtml = olGUI.markupEscape(content);  
            var chatmessage = $.parseHTML('<li class="message-chat-entry self"><div style="display:block; max-width: 80%;"><div class="messagetime">' + Lang.trans('js/messenger.msgDateTime', { dateTime: olRealtimeHelper.formatDatePHP(now, Lang.trans('js/messenger.date_format_medium_time'))}) + '</div> <div class="msg msg-self-color">'+ contentHtml +'</div></li>');
            chatmessage = $(chatmessage).prop('outerHTML');

            $('.emojionearea-editor').empty();
            $('#message-container > .chat').append(emojione.toImage(chatmessage));
            blendInChatMsgs();
            var msgcon = $('#message-container');
            msgcon.scrollTop(msgcon[0].scrollHeight);
            $.post("/messenger/store", {
                receiverUserId: parseInt(receiverUserId),
                content: content, // performs Common::markupEscape(content)
                type: type,
                relationType: relationType
            }).fail(function() {
                $('#message-container > .chat').append('<li class="message-chat-entry self">' + Lang.trans('js/messenger.errorMsgNotSend') + '</li>');
                blendInChatMsgs();
            }).success(function(response) {
                if (response == 'ignore') {
                    var chatmessage = '<li class="message-chat-entry self"><div style="display:block; "><div class="messagetime">' + Lang.trans('js/messenger.msgDateTime', { dateTime: olRealtimeHelper.formatDatePHP(now, Lang.trans('js/messenger.date_format_medium_time'))}) + '</div> <div class="msg msg-self-color msg-blocked"><i>' + Lang.trans('js/messenger.errorMsgBlocked') + '</i></div></li>';
                    $('.emojionearea-editor').empty();
                    $('#message-container > .chat').append(emojione.toImage(chatmessage));
                    blendInChatMsgs();
                    var msgcon = $('#message-container');
                    msgcon.scrollTop(msgcon[0].scrollHeight);
                }
                messageSystem.loadUserList(receiverUserId);

                $('#selectable-news').after($('#selectable-user-' + receiverUserId));
            });
        }
    };

    messageSystem.deleteMessage = function(messageId, type, element) {
        var scrollTop = $('#messagelist-container').scrollTop();
        $.ajax({
            type: 'POST',
            url: "/messenger/delete",
            data: {
                messageId: messageId,
                type: type
            },
            fail: (function() {
                $('#selectable-news > button').click();
            }),
            success: function(data) {
                $('#selectable-news > button').click();
                messageSystem.updateMessageCountAndOnlineStatusIntern(function() {
                    // console.log("scrolltop", scrollTop);
                    $('#messagelist-container').scrollTop(scrollTop);
                });
            }
        });
        $(element).removeClass(element);
    };

    messageSystem.inboxRefresh = function() {
        $.get("/messenger/list/refresh", function(data) {
            var msgcon = $('#messagelist-container');
            var atTop = msgcon.scrollTop === 0;
            $('#messagelist-container .ol-messenger-head').after(data);
            if (atTop) {
                msgcon.scrollTop();
            }
            if (data > "") {
                $("div").remove(".messenger-tutorial-message");
            }
        //}).always(function() {
        //    /*setTimeout(function() {
        //        if ((inboxIsActive && $(".message-window").is(':visible')) || showRoomMessageIsActive) {
        //            messageSystem.inboxRefresh();
        //        }
        //    }, 5000 * timerfactor);*/
        });
    };

    messageSystem.chatRefresh = function(userId) {
        $.post("/messenger/refresh", {userId: userId}, function(data) {
            var msgcon = $('#message-container');
            var atBottom = msgcon[0].scrollTop + msgcon[0].clientHeight === msgcon[0].scrollHeight;
            $('#message-container > .chat').append(data);
            blendInChatMsgs();
            if (atBottom) {
                msgcon.scrollTop(msgcon[0].scrollHeight);
            }
        //}).always(function() {
        //    /*setTimeout(function() {
        //        if ($(".message-window").is(':visible') && (globalSelectedUser.id === userId)) {
        //            // messageSystem.resetMessagecount(userId);
        //            messageSystem.chatRefresh(userId);
        //        }
        //    }, 2000 * timerfactor);*/
        });
    };
    
    messageSystem.upsize = function() {
        var messageWindow = $("#message-window");
        if (!globalIsLarge) {
            globalMessageWindowPos = messageSystem.getElementPos($('#message-window'));
        }

        $(".message-head").hide();
        $('#messenger-large-container').show();
        $('#messenger-large-background').show();
        $("#messagesystem-userlist").show();
        //move userlist
        $("#messenger").appendTo("#messenger-large-container > .row > .col-lg-4");
        $("#messagesystem-userlist").addClass("messagesystem-userlist-large").removeClass("messagesystem-userlist");
        $("#messagesystem-userlist-container").parent().removeClass("messagesystem-userlist-tiny-wrapper");
        $("#messagesystem-userlist-container").parent().addClass("messagesystem-userlist-large-wrapper");
        //move messagewindow
        messageWindow.removeAttr("style");
        messageWindow.appendTo("#messenger-large-container > .row > .col-lg-8");
        messageWindow.show();
        messageWindow.draggable("disable");
        messageWindow.resizable("disable");
        //header 
        $("#messagesystem-search").show();
        $("#messagesystem-userlist-head").prependTo("#messenger-large-container");
        $("#messagesystem-userlist-head > .hide-on-tiny.header-text > .messagecount").show();
        $('#messagesystem-usermeta-container').empty();
        $('#messagesystem-usermeta-container').removeClass('collapse');
        globalIsLarge = true;
        messageSystem.messageWindow(globalSelectedUser.id, undefined, undefined, undefined, globalSelectedUser.type);
        messageSystem.headerMessagecounters();
    };

    messageSystem.downsize = function() {
        var messageWindow = $("#message-window");
        //move userlist
        $("#messenger").appendTo("#messenger-tiny-container");
        $("#messagesystem-userlist").addClass("messagesystem-userlist").removeClass("messagesystem-userlist-large");
        $("#messagesystem-userlist-container").parent().removeClass("messagesystem-userlist-large-wrapper");
        $("#messagesystem-userlist-container").parent().addClass("messagesystem-userlist-tiny-wrapper");
        //move messagewindow
        messageWindow.appendTo("#messenger-tiny-container");
        messageWindow.draggable("enable");
        messageWindow.resizable("enable");
        messageWindow.removeAttr("style");
        messageSystem.setMessageWindowPos(globalMessageWindowPos);
        messageWindow.show();
        //header
        $("#messagesystem-userlist-head").prependTo("#messagesystem-userlist");
        $("#messagesystem-userlist").draggable({handle: $('#messagesystem-userlist-head'), containment: 'window'});
        $('#messenger-large-container').hide();
        $('#messenger-large-background').hide();
        $('#message-window').hide();
        globalIsLarge = false;
        messageSystem.headerMessagecounters();
    };

    messageSystem.activeUserlistButton = function(element) {
        //document.getElementById("backButton").style.display = "none";
        $("#messenger-tiny-container").removeClass("showMessage");
        $("#messenger-large-container").removeClass("showMessage");
        var li = $(element).parent();
        $(element).addClass("active");
        li.siblings().children().removeClass("active");
        if (!$(element).is("#selectable-news")) {
            $("#selectable-news").removeClass("active");
        }

    };

    messageSystem.toggleSize = function() {
        messageContentHeight = 0;
        if (globalIsLarge === true) {
            olGUI.unlockScroll()
            messageSystem.downsize();
        } else {
            messageSystem.upsize();
            olGUI.lockScroll($('#messagesystem-userlist-container'), $('#messagelist-container'));
        }
        $("#messagesystem-userlist-head .messagesystem-resize").toggleClass('icon-ol-msgicon-1').toggleClass('icon-ol-msgicon-2_darkgrey');
    };

    var isVisible = false;
    messageSystem.toggle = function(boolean) {
        if (initialized === false) return;
        if (typeof boolean === typeof undefined) {
            isVisible = !isVisible;
        } else {
            isVisible = boolean;
        }
        // // Anzeige der neuen Nachrichten bei Aufruf des Messenger
        // // unabhaengig von der vorheren Anzeige
        //if (isVisible && inboxIsActive)
        //{
        //messageSystem.messageWindow(undefined, messagetypes);
        //}
        messageSystem.messageWindow(undefined, messagetypes);

        if (globalIsLarge) {
            if (isVisible)
            {
                if(enableMsgTutorial)
                    olLeagueLicence.update({ type: 'SHOW_TUTORIAL', name: 'messenger' });
                olGUI.lockScroll($('#messagesystem-userlist-container'), $('#messagelist-container'));
            } else
            {
                olGUI.unlockScroll();
            }
            $("#messenger-large-container").fadeToggle(250);
            $("#messenger-large-background").fadeToggle(250);
        } else {
            if (isVisible && $('body').hasClass('ol-xs')) {
                if (enableMsgTutorial) {
                    olLeagueLicence.update({ type: 'SHOW_TUTORIAL', name: 'messenger' });
                }
                olGUI.lockScroll($('#messagesystem-userlist-container'), $('#messagelist-container'));
            } else {
                olGUI.unlockScroll();
            }
            $('#messenger-tiny-container').fadeToggle(250);
        }
        
        var mobileBottomNav = $('.ol-mobile-bottom-nav-section');

        if (isVisible) {
            hideMobileNav();
            olMobileApp.hideBottomNav();
            olMobileApp.hideHeader();
            mobileBottomNav.hide();
        } else {
            olMobileApp.showHeader();
            olMobileApp.showBottomNav();
            mobileBottomNav.show();
        }
    };

    messageSystem.getElementPos = function(element) {
        var pos;
        if (!element.is(":visible")) {
            element.show();
            pos = element.offset();
            element.hide();
        } else {
            pos = element.offset();
        }
        return pos;
    };

    messageSystem.closeMessageWindow = function() {
        var pos = messageSystem.getElementPos($("#message-window"));
        $("#messagesystem-userlist").show();
        $("#messagesystem-userlist").offset({top: pos.top, left: pos.left});
        if(!globalIsLarge && smallFirst == 0){
            $("#message-window").css('display', 'none');
            $("#messagesystem-userlist").css('display','block');
        } else {
            $("#messagesystem-userlist").css('display','none');   
        }
        smallFirst = 0;
    };

    messageSystem.setMessageWindowPos = function(pos) {
        var messageWindow = $("#message-window");
        if (!messageWindow.is(":visible")) {
            messageWindow.show();
            messageWindow.offset({top: pos.top, left: pos.left});
            messageWindow.hide();
        } else {
            messageWindow.offset({top: pos.top, left: pos.left});
        }
    };

    messageSystem.addLoadingAnimation = function() {
        if (globalIsLarge) {
            if ($("#message-window").is(':visible')) {
                addLoadingAnimationToElement("#message-window", {overlay: true});
            }
        } else {
            if ($("#messagesystem-userlist").is(':visible')) {
                addLoadingAnimationToElement("#messagesystem-userlist", {overlay: true});
            }
        }
    };
    
    messageSystem.removeLoadingAnimation = function() {
        if (globalIsLarge) {
            removeLoadingAnimationFromElement("#message-window");
        } else {
            removeLoadingAnimationFromElement("#messagesystem-userlist");
        }
    };

    messageSystem.openChatWithUser = function(userId) {
        chat = 1;
        messageSystem.toggle();
        messageSystem.messageWindow(userId);
    };

    var lastMessageCounter = 0;
    var lastAnimationOngoing = false;
    function updateHeaderIconAnimation(sum, stateColorClass) {
        if (lastAnimationOngoing || $('.tempIcon' + newColorClass).length > 0)
            return;
        if (sum > lastMessageCounter) {
            var newColorClass = stateColorClass;
            var newCounterElement = $("<div class='" + stateColorClass + " ol-badge messenger-newmsg-animation-big-init tempIcon" + stateColorClass + "'>" + sum + "</div>");
            newCounterElement.appendTo($('.ol-banner-content'));
            lastAnimationOngoing = true;
            setTimeout(function() {
                $('.tempIcon' + newColorClass).addClass('messenger-newmsg-animation-big');
                setTimeout(function() {
                    lastAnimationOngoing = false;
                    $('.tempIcon' + newColorClass).remove();
                }, 1100 * timerfactor);
            }, 100);
        }
        lastMessageCounter = sum;
    }

    $(document).on('click', '.messenger-sound-icons', function() {
        $(this).toggleClass('sound-off');
        audioEnabled = $(this).hasClass('sound-off') ? 0 : 1;
        $.post('/messenger/audioToggle', {'enabled': audioEnabled});
    });

    messageSystem.deleteChat = function(uid) {
        $.post('/userrelations/hide', {'relatedUserId': uid}, function() {
            messageSystem.loadUserList();
            setTimeout(function() {
                $("#message-window").css('display','none');
            }, 100);
        });
    };
    
    messageSystem.deleteAllMessages = function() {
        olMsgBox.questionById('questionDeleteAllMsgs', function() {
            $.post('/messenger/deleteall', function() {
                messageSystem.messageWindow(undefined, messagetypes);
                messageSystem.updateMessageCountAndOnlineStatusIntern();
            });
        });
    };

    messageSystem.deleteButton = function(ev) {
        ev.stopPropagation();

        var id = $(ev.target).parents('.messagesystem-selectable-element').attr('id');
        var uid = id.split("-")[2];
        // console.log(id, uid, id.split("-"));
        var deleteCallback = function() {
            messageSystem.deleteChat(uid);
        }
        olMsgBox.question(Lang.trans('js/messenger.deleteUser'), Lang.trans('js/messenger.questionDeleteUser'), deleteCallback, function() {}, Lang.trans('js/messenger.yes'), Lang.trans('js/messenger.cancel'));
    };

}(window.messageSystem = window.messageSystem || {}, jQuery));

$(function() {
    jQuery.fn.center = function() {
        this.css("position", "absolute");
        this.css("top", Math.max(0, (($(window).height() - $(this).outerHeight()) / 2) +
                $(window).scrollTop()) + "px");
        this.css("left", Math.max(0, (($(window).width() - $(this).outerWidth()) / 2) +
                $(window).scrollLeft()) + "px");
        return this;
    };    
});
