// TODO Move to namespace object

var updateTicketPriceTimer;

function initializeStadiumSettings() {
    $(document).on('keyup', '.stadion-wrapper input', function(event) { if (event.keyCode == 13) $(this).blur(); })
    
    $(function(eReady) {
        $('.ticketPriceInput, .rangeValueShow').on('focus', function() { $(this).select() });

        $('#ticketPriceType').on('change', function(e) {
            $('.stadion-wrapper').addClass($(this)[0].checked ? 'ol-friendly-price-type' : 'ol-league-price-type');
            $('.stadion-wrapper').removeClass(!($(this)[0].checked) ? 'ol-friendly-price-type' : 'ol-league-price-type');
            if ($(this)[0].checked) {
                $('.leagueTicketPrice').addClass('d-none');
                $('.friendlyTicketPrice').removeClass('d-none');
            } else {
                $('.friendlyTicketPrice').addClass('d-none');
                $('.leagueTicketPrice').removeClass('d-none');
            }
            changeTicketPriceType();
            
            updateRentStandActivation(!olGUI.isPremiumUser() ? true : !$(this)[0].checked);
        });
        $('.ticketPriceInput').on('keyup', function(e) {
            var thisObj = $(this);
            clearTimeout(updateTicketPriceTimer);
            updateTicketPriceTimer = setTimeout(function() {
                $(thisObj).trigger('change');
            }, 3000);
        }).on('change', function() {
            var rangeMin = $(this).attr('data-min-ticketprice');
            rangeMin = rangeMin == '' || isNaN(rangeMin) ? 0 : parseFloat(rangeMin);
            var rangeMax = $(this).attr('data-max-ticketprice');
            rangeMax = rangeMax == '' || isNaN(rangeMax) ? 0 : parseFloat(rangeMax);

            var value = $(this).val();
            value = value.replace(/\./g, '');
            value = value.replace(/\,/g, '.');
            value = value == '' || isNaN(value) ? 0 : parseFloat(value);
            value = Math.round(value);
            value = Math.min(5000, value);
            value = Math.max(1, value);
            //value = addZeroes(value);
            value = numberFormat(value);
            $(this).val(value);
            
            updateTicketPrice($(this));
        });
        var oldRangeValue = 0;
        var myRangeChangeTimer;
        $('.myRange').on('input', function(e) {
            var value = $(this).val();

            //value = addZeroes(value);
            value = numberFormat(value);
            $(this).parent().find('.rangeValueShow').val(value);

            var rangeValueShowObj = $(this).parent().find('.rangeValueShow');
            clearTimeout(myRangeChangeTimer);
            myRangeChangeTimer = setTimeout(function() {
                $(rangeValueShowObj).trigger('change');
            }, 1000);
        });
        $('.rangeValueShow').on('keyup', function(e) {
            var thisObj = $(this);
            clearTimeout(updateTicketPriceTimer);
            updateTicketPriceTimer = setTimeout(function() {
                $(thisObj).trigger('change');
            }, 3000);
        }).on('change', function(e) {
            var myRangeObj = $(this).parent().parent().find('.myRange');
            var rangeMin = $(myRangeObj).attr('min');
            rangeMin = rangeMin == '' || isNaN(rangeMin) ? 0 : parseInt(rangeMin);
            var rangeMax = $(myRangeObj).attr('max');
            rangeMax = rangeMax == '' || isNaN(rangeMax) ? 0 : parseInt(rangeMax);

            rangeMin = 1;
            rangeMax = 5000;

            var value = $(this).val();
            value = value.replace(/\./g, '');
            value = value.replace(/\,/g, '.');
            value = value == '' || isNaN(value) ? 0 : parseFloat(value);
            value = Math.round(value);
            if (value > rangeMax) {
                value = rangeMax;
            } else if (value < rangeMin) {
                value = rangeMin;
            }
            //value = addZeroes(value);
            value = numberFormat(value);

            var rangeValue = value.replace(/\./g, '');
            rangeValue = rangeValue.replace(/\,/g, '.');
            rangeValue = rangeValue == '' || isNaN(rangeValue) ? 0 : parseFloat(rangeValue);
            $(myRangeObj).val(rangeValue);

            $(this).val(value);
            
            updateTicketPrice($(this));
        });
        $('svg').css('cursor', 'pointer');
        $('#backLink, svg').on('click', function(e) {
            var stadiumId = $('#stadiumId').val();
            // var ticketPriceType = $('#ticketPriceType')[0].checked ? 1 : 0;
            var ticketPriceType = 0;
            var settingsURL = '';
            if (typeof settingsURLVar != 'undefined') {
                settingsURL = settingsURLVar;
            }
            settingsURL = decodeURIComponent(settingsURL);
            settingsURL = settingsURL.replace(':stadiumId', stadiumId);
            settingsURL = settingsURL.replace(':ticketPriceType', ticketPriceType);

            if (typeof addLoadingAnimationToElementNoTimeOut == 'function' && $('#standardLoadingDiv').length) {
                addLoadingAnimationToElementNoTimeOut($('#standardLoadingDiv'));
            }
            $.ajax({
                type: 'get',
                url: settingsURL,
                success: function(data){
                    if (typeof removeLoadingAnimationFromElement == 'function' && $('#standardLoadingDiv').length) {
                        removeLoadingAnimationFromElement($('#standardLoadingDiv'));
                    }
                    var data = $(data).filter('#settingsContent').html();
                    $("#settingsContent").html(data);
                },
                error: function(jqXHR, exception) {
                    console.error("Exception while getting url:", exception)
                    //window.location.reload();
                }
            });
        });
        $('.svgStadiumBlock').click(function(ev) {
            stadiumSettingsClickOnStands(ev, this);
        });

        $('.settingsTab').on('click', function(e) {
            var stadiumId = $(this).attr('data-id');

            // var ticketPriceType = $('#ticketPriceType')[0].checked ? 1 : 0;
            var ticketPriceType = 0;
            var settingsURL = '';
            if (typeof settingsURLVar != 'undefined') {
                settingsURL = settingsURLVar;
            }
            settingsURL = decodeURIComponent(settingsURL);
            settingsURL = settingsURL.replace(':stadiumId', stadiumId);
            settingsURL = settingsURL.replace(':ticketPriceType', ticketPriceType);

            if (typeof addLoadingAnimationToElementNoTimeOut == 'function' && $('#standardLoadingDiv').length) {
                addLoadingAnimationToElementNoTimeOut($('#standardLoadingDiv'));
            }
            $.ajax({
                type: 'get',
                url: settingsURL,
                success: function(data){
                    if (typeof removeLoadingAnimationFromElement == 'function' && $('#standardLoadingDiv').length) {
                        removeLoadingAnimationFromElement($('#standardLoadingDiv'));
                    }
                    var data = $(data).filter('#settingsContent').html();
                    $("#settingsContent").html(data);
                },
                error: function(jqXHR, exception) {
                    console.error("Error getting url:", exception)
                    //window.location.reload();
                }
            });

            return false;
        });

        $('input[type="range"]').on("touchmove",function(e){
            // To stop scrolling at the time of range moving
        });
        
        $('.rent-stand-row').on('click', function(e) {
            if($(e.target).is( "input" )) return;
            setTimeout(function() {
                if (stadiumSettingChangeIsOngoing) return;
                stadiumSettingsClickOnStands(e, $(e.target).closest('.rent-stand-row'));
            }, 0);
        });
       
        var stadiumSettingChangeIsOngoing = false;
        $('.rentStandsOptions input').on('change', function(e) {
            stadiumSettingChangeIsOngoing = true;
            e.stopPropagation();
            e.preventDefault();
            setTimeout(function() { stadiumSettingChangeIsOngoing = false; }, 1);
            var before = $(e.target).is(':checked');
            var parentTR = $(e.target).closest('.rent-stand-row'); 
            parentTR.addClass('ol-loading');
            addLoadingAnimationToElement(parentTR, { 'size': 'small' });
            $(e.target).attr('disabled', 'disabled');
            var modDescId = $(e.target).attr('data-mod-desc-id')
            // $.post('/toggleRentStand', {'modificationDescriptionId': modDescId, 'enable': $(e.target).is(':checked'), 'league': $('#ticketPriceType')[0].checked ? '0' : '1'}, function(result, textStatus, jqXHR) {
            $.post('/toggleRentStand', {'modificationDescriptionId': modDescId, 'enable': $(e.target).is(':checked'), 'league': '1'}, function(result, textStatus, jqXHR) {
                if (jqXHR.status == 200) {
                    var input = parentTR.find('input');
                    input.attr('data-id', result.modificationId);
                    parentTR.attr('data-modificationId', result.modificationIdBase64);
                    
                    if ($(e.target).is(':checked')) {
                        parentTR.addClass('rent-stands-active');
                        parentTR.removeClass('rent-stands-inactive');
                        parentTR.find('.toggle-btn-right-text').addClass('color001');
                        parentTR.find('.toggle-btn-left-text').removeClass('color001');
                        if ($(input).val() <= 1) {
                            $(input).val($('.basicStadiumStands input.leagueTicketPrice').first().val());
                            //$(input).trigger('change');
                        }
                    } else {
                        parentTR.addClass('rent-stands-inactive');
                        parentTR.removeClass('rent-stands-active');
                        parentTR.find('.toggle-btn-left-text').addClass('color001');
                        parentTR.find('.toggle-btn-right-text').removeClass('color001');
                    }
                    // var target = $('#ticketPriceType')[0].checked ? 'data-active-friendly' : 'data-active-league';
                    var target = 'data-active-league';
                    // var otherTarget = !($('#ticketPriceType')[0].checked) ? 'data-active-friendly' : 'data-active-league';
                    var otherTarget = 'data-active-league';
                    parentTR.attr(target, $(e.target).is(':checked') ? 1 : 0);
                    if(!olGUI.isPremiumUser())
                        parentTR.attr(otherTarget, $(e.target).is(':checked') ? 1 : 0);
                    updateRentStandCapacities();
                } else {
                    $(e.target). prop("checked", before);
                }
                parentTR.removeClass('ol-loading');
                $(e.target).removeAttr('disabled');
                removeLoadingAnimationFromElement(parentTR);
                stadiumSettingsUpdateRentStandsCount();
            });
        });

        scrollArrowPosition();
        var overflowDivOffset = $('.stadium-right-pnl-inner').offset();
        $('.stadium-right-pnl-inner').on('touchmove', function(e) {
            var currentOverflowDivOffset = $(this).offset();
            if (currentOverflowDivOffset.left < overflowDivOffset.left) {
                $('.scrollArrow').hide();
            }
        });
        $('.stadium-right-pnl').on('scroll', function(e) {
            $('.scrollArrow').hide();
        });
        $('.scrollArrow').on('click', function(e) {
            $(this).hide();
        });
    });
}

function updateTicketPrice(obj) {
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    var saveTicketPriceURL = '';
    if (typeof saveTicketPriceURLVar != 'undefined') {
        saveTicketPriceURL = saveTicketPriceURLVar;
    }
    var id = $(obj).attr('data-id');
    var type = $(obj).attr('data-type');
    var value = $(obj).val();
    $.ajax({
        url: saveTicketPriceURL,
        type: 'POST',
        data: {
            _token: CSRF_TOKEN,
            id : id,
            type : type,
            value : value,
        },
        dataType: 'JSON',
        success: function(data) {
            if (data.status == false) {
                olMsgBox.msgById('invalidData');
            }
        },
        error: function(jqXHR, exception) {
            console.error("Error getting url:", exception)
            //window.location.reload();
        }
    });
}

function changeUrl(url) {
    if (typeof (history.pushState) != "undefined") {
        var obj = { Url: url };
        history.pushState(obj, '', obj.Url);
    }
}

function updateRentStandActivation(leagueActivated) {
    $('.rent-stand-row').each(function(index, row) {
        var $row = $(row);
        var activated = (leagueActivated ? $row.attr('data-active-league') : $row.attr('data-active-friendly'));
        if (activated == 1) {
            $row.addClass('rent-stands-active');
            $row.removeClass('rent-stands-inactive');
            $row.find('.ckToggl').prop("checked", true);
            $row.find('.toggle-btn-right-text').addClass('color001');
            $row.find('.toggle-btn-left-text').removeClass('color001');
        } else {
            $row.addClass('rent-stands-inactive');
            $row.removeClass('rent-stands-active');
            $row.find('.ckToggl').prop("checked", false);
            $row.find('.toggle-btn-left-text').addClass('color001');
            $row.find('.toggle-btn-right-text').removeClass('color001');
        }
    });
    if ($('.rent-stand-row').length > 0)
        updateRentStandCapacities();
}

function changeTicketPriceType() {
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    var changeTicketPriceTypeURL = '';

    if (typeof changeTicketPriceTypeURLVar != 'undefined') {
        changeTicketPriceTypeURL = changeTicketPriceTypeURLVar;
    }
    var ticketPriceType = $('#ticketPriceType')[0].checked ? 1 : 0;
    $.ajax({
        url: changeTicketPriceTypeURL,
        type: 'POST',
        data: {
            _token: CSRF_TOKEN,
            ticketPriceType : ticketPriceType,
        },
        dataType: 'JSON',
        success: function(data) { 
            //console.log(data);
        },
        error: function(jqXHR, exception) {
            console.log(exception);
        }
    });
}


function numberFormat(x) {
    x = x.toString().replace(/\./g, ',');
    x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return x;
}

function addZeroes(num) {
    var value = Number(num);
    var res = num.toString().split('.');
    value = !isNaN(value) && value != '' ? value : 0;
    if(res.length == 1 || res[1].length < 3 || res[1].length > 2) {
        value = value.toFixed(2);
    }

    return value;
}


function scrollArrowPosition() {
    var right = parseInt($('.ol-table-striped-7').width()) - parseInt($('.stadium-right-pnl').width());
    if (right > 0) {
        right -= 30;
        $('.scrollArrow').css('right', right);
    } else {
        $('.scrollArrow').hide();
    }
}

function stadiumSettingsInitBP() {
    $('.ticket-price-settings')
        .on('mouseenter', function(e) {
                $('.ticket-price-settings:not(.fixActive)').removeClass('active');
                var id = $(this).attr('data-id');
                $('.ticket-price-settings[data-id="' + id + '"]').addClass('active');
            })
        .on('mouseleave', function(e) {
            if (!$(this).hasClass('fixActive')) {
                $(this).removeClass('active');
            }
        });
}

function updateRentStandCapacities() {
    var mobileDevice = olGUI.getBootstrapDeviceSize() == 'ol-xs' || olGUI.getBootstrapDeviceSize() == 'ol-sm';
    var sum = 0;
    $('#standsLoadingDiv').find((mobileDevice ? '.ol-mobile-stadium-settings-table-body ' : 'table ') + '.rent-stand-row.rent-stands-active').each(function(index, tr)
    {
        sum = sum + parseInt($(tr).attr('data-value-capacity'));
    });
    $('.ol-rent-stand-extra').attr('data-value', sum);    
    $('.stands-util-div').text(olGUI.numberWithPoints(sum));
    $('#rentStandsSum').text(olGUI.numberWithPoints(sum));
    
    stadiumSettingsUpdateRentStandsCount();
}

function stadiumSettingsUpdateRentStandsCount() {
    var mobileDevice = olGUI.getBootstrapDeviceSize() == 'ol-xs' || olGUI.getBootstrapDeviceSize() == 'ol-sm';
    var numberOfActiveRentStands = $('#standsLoadingDiv').find((mobileDevice ? '.ol-mobile-stadium-settings-table-body ' : 'table ') + '.rent-stand-row.rent-stands-active').length;
    var factor = (4 - numberOfActiveRentStands) / 4;
    if(factor === 0)
        $('.basicStadiumStands').addClass('disabled-price');
    else
        $('.basicStadiumStands').removeClass('disabled-price');
    $('.default-stands-util-div').html(factor * 500);
}

function stadiumSettingsClickOnStands(ev, element) {
    ev.stopPropagation();
    if ($(element).hasClass('rent-stands-active') || ($(element).hasClass('constructionBlock') || $(element).hasClass('builtBlock'))) {
        var stadiumId = $('#stadiumId').val();
        var dataModificationID = $(element).attr('data-modificationid');
        if (dataModificationID && dataModificationID != 0) {
            // var ticketPriceType = $('#ticketPriceType')[0].checked ? 1 : 0;
            var ticketPriceType = 0;

            var settingsDetailURL = '';
            if (typeof settingsDetailURLVar != 'undefined') {
                settingsDetailURL = settingsDetailURLVar;
            }
            settingsDetailURL = decodeURIComponent(settingsDetailURL);
            settingsDetailURL = settingsDetailURL.replace(':dataModificationID', dataModificationID);
            settingsDetailURL = settingsDetailURL.replace(':stadiumId', stadiumId);
            settingsDetailURL = settingsDetailURL.replace(':ticketPriceType', ticketPriceType);

            if (typeof addLoadingAnimationToElementNoTimeOut == 'function' && $('#standardLoadingDiv').length) {
                addLoadingAnimationToElementNoTimeOut($('#standardLoadingDiv'));
            }
            $.ajax({
                type: 'get',
                url: settingsDetailURL,
                success: function(data) {
                    if (typeof removeLoadingAnimationFromElement == 'function' && $('#standardLoadingDiv').length) {
                        removeLoadingAnimationFromElement($('#standardLoadingDiv'));
                    }
                    $("#settings_top_content").html(data);
                },
                error: function(jqXHR, exception) {
                    console.error("Error getting url:", exception)
                    //window.location.reload();
                }
            });
        }
    }
    updateRentStandCapacities();
}