ol.userSelection = new Object();
ol.userLocation = new Object();
ol.loading = false;

matchday = null;
season = null; 
leagueLevel = null;
stateId = null;
districtId = null;
leagueId = null;
currentMatchday = null;
currentSeason = null;
filterType = null;
statisticType = null;

function initMatchdayTable(userLocation, type) { 
    ol.userLocation = userLocation;
    ol.statisticType = type;
    ol.matchdayTableType = 1;
    ol.userSelection.page = 1;
    ol.loading = false;
}

function getLeagueNavigationData(data) {
    selection = $.ol.leagueNavigation.getSelection();
    data.season = selection.season;
    data.matchday = selection.matchday;
    data.leagueLevel = selection.leagueLevel;
    data.leagueId = selection.leagueId;
    data.stateId = selection.stateId;
    data.leagueMatchday = selection.leagueMatchday;
    data.type = ol.matchdayTableType;
    return data;
}

function getTopPlayerDropdownData(data) {
    if ($("#topPlayerTeamDropdown").length)
        data.userId = $("#topPlayerTeamDropdown").dropdown().value();
    else
        data.userId = 0;
        
    if ($("#topPlayerPositionDropdown").length)
        data.position = $("#topPlayerPositionDropdown").dropdown().value();
    else
        data.position = 0;
    if(typeof ol.topPlayerRatingType === 'undefined') ol.topPlayerRatingType = 1;
    data.total = ol.topPlayerRatingType;
}

function toggleButtonTopPlayerTotalChanged(type) {
    data = new Object();
    ol.topPlayerRatingType = type;
    getLeagueNavigationData(data);
    getTopPlayerDropdownData(data);
    data.nav = false;
    ol.userSelection.page = 1;
    loadMatchdayTableTopPlayerContent($("#topplayerTableContent"), data, false);
}

function toggleButtonCardsChanged(type) {
    data = new Object();
    getLeagueNavigationData(data);    
    ol.cardType = type;
    data.nav = false;
    ol.userSelection.page = 1;
    addUrlParams('?subPage=/cards/'+type);
    loadMatchdayTableCardsContent($("#cardsTableContent"), data, false);
}

function dropdownTopPlayerTeamChanged(val) {
    data = new Object();
    getLeagueNavigationData(data);
    getTopPlayerDropdownData(data);
    data.userId = val;
    data.nav = false;
    ol.userSelection.page = 1;
    loadMatchdayTableTopPlayerContent($("#topplayerTableContent"), data, false);
}

function dropdownTopPlayerPositionChanged(val) {
    data = new Object();
    getLeagueNavigationData(data);
    getTopPlayerDropdownData(data);
    data.position = val;
    data.nav = false;
    ol.userSelection.page = 1;
    loadMatchdayTableTopPlayerContent($("#topplayerTableContent"), data, false);
}

function loadMatchdayTableContent(selection, matchdayTableType, callback, resetPages, loadNav, element) {
    if (typeof loadNav === "undefined") loadNav = true;
    if (typeof resetPages === "undefined") resetPages = true;
    if (resetPages) {
        ol.userSelection.page = 1;
        ol.loading = false;
    }
    var innerCallback = function() { olAds.loadAxContainers(true); ol.loading = false; if(callback) callback();  };
    var target = $("#ol-table");
    if (typeof matchdayTableType !== "undefined") {
        ol.matchdayTableType = matchdayTableType;
        target = $("#ol-table-content");
    } else
        ol.matchdayTableType = 1;
    var scroll = $(window).scrollTop();
    
    data = new Object();
    data.season = selection.season;
    data.matchday = selection.matchday;
    data.leagueLevel = selection.leagueLevel;
    data.leagueId = selection.leagueId;
    data.stateId = selection.stateId;
    data.leagueMatchday = selection.leagueMatchday;
    data.type = ol.matchdayTableType;
    data.nav = loadNav;
    data.navId = "matchdayTable";
    ol.loading = true;
    
    if (selection.leagueId === false || selection.leagueId == null) {
        $("#noLeagueFound").show();
        $("#leagueFound").hide();
        $("#ol-table-content").hide();
        return;
    }

    var suburl = $(element).attr('data-sub-url') || '';
    addUrlParams('?subPage='+suburl);
    switch (ol.statisticType) {
        case 'RANKING':
            loadMatchdayTableLeagueTableContent(target, data, innerCallback);
            break;
        case 'SCORER':
            loadMatchdayTableScorerContent(target, data, innerCallback);
            break;
        case 'GOALGETTER':
            loadMatchdayTableGoalgetterContent(target, data, innerCallback);
            break;
        case 'TOPPLAYER':
            loadMatchdayTableTopPlayerContentPreperation(target, data, innerCallback);
            break;
        case 'ELEVEN_OF_THE_DAY':
            loadMatchdayTableElevenOfTheDayContent(target, data, innerCallback);
            break;
        case 'CARDS':
            loadMatchdayTableCardsContent(target, data, innerCallback);
            break;
        case 'STADIUMS':
            loadMatchdayTableStadiumsContent(target, data, innerCallback);
            break;
    }
}

function loadMatchdayTableSponsors() {
    var $anchor = $('#ol-table-content');
    addLoadingAnimationToElement($anchor, {size: 'small'});
    $.get('/matchdaytable/sponsors', function(html) {
        $anchor.html(html);
        addUrlParams('?subPage=/matchdaytable/sponsors');
        removeLoadingAnimationFromElement($anchor);
    }).fail(function() {
        removeLoadingAnimationFromElement($anchor);
        console.log("*** /matchdaytable/sponsors failed");
    });
}

function loadMatchdayTableElevenOfTheDayContent(target, data, callback) {
    data.navId = 'matchdayTable'
    var clbk = function() { 
        removeLoadingAnimationFromElement(target);
        $(window).scrollTop(scroll);
        if (callback) callback();
    }
    
    addLoadingAnimationToElement(target);
    target.getLoad("/matchdaytable/elevenofthedaypitch", data, clbk);
}

function loadMatchdayTableLeagueTableContent(target, data, callback) {
    var clbk = function() { 
        removeLoadingAnimationFromElement(target);
        $(window).scrollTop(scroll);
        if(callback) callback();
    }
    
    addLoadingAnimationToElement(target);
    if (data.matchday == 35) {
        target.getLoad("/matchdaytable/relegation", data, clbk);
    } else {
        target.getLoad("/matchdaytable/leaguetable", data, clbk);
    }
}

function loadMatchdayTableStadiumsContent(target, data, callback) {
    data.navId = 'matchdayTable'
    var clbk = function() { 
        removeLoadingAnimationFromElement(target);
        $(window).scrollTop(scroll);
        if(callback) callback();
    }
    
    addLoadingAnimationToElement(target);
    target.getLoad("/matchdaytable/stadiumstable", data, clbk);
}


function loadMatchdayTableCardsContent(target, data, callback) {
    if (ol.userSelection.page === false) return;
    data.page = ol.userSelection.page;
    
    if(typeof ol.cardType === typeof undefined) ol.cardType = 'yellow';
    data.cardType = ol.cardType;
    
    append = false;
    data.navId = 'matchdayTable'
    if (data.page == 1) {
    } else {
        append = true;
        target = $("#cardsTableContent");
    }
    var clbk = function(response) {
        if (response.length == 0)  {
            ol.userSelection.page = false;
            $("#divNoMorePlayer").show();
        }
        if (ol.userSelection.page == 1)
             removeLoadingAnimationFromElement(target);
        else
            target.removeClass("ol-table-content-loading");
        $(window).scrollTop(scroll);
        if (callback) callback();
    }
    if (ol.userSelection.page == 1)
        addLoadingAnimationToElement(target);
    else
        target.addClass("ol-table-content-loading");
    
    target.getLoad("/matchdaytable/cardstable", data, clbk, append);
}

function loadMatchdayTableScorerContent(target, data, callback) {
    if (ol.userSelection.page === false) return;
    data.page = ol.userSelection.page;
    append = false;
    if (data.page == 1) {
    } else {
        append = true;
        target = $("#scorerTableBody");
    }
    var clbk = function(response) {
        if (response.length == 0) {
            ol.userSelection.page = false;
            $("#divNoMorePlayer").show();
        }
        if (ol.userSelection.page == 1)
             removeLoadingAnimationFromElement(target);
        else
            target.removeClass("ol-table-content-loading");
        $(window).scrollTop(scroll);
        if (callback) callback();
    }
    if (ol.userSelection.page == 1)
        addLoadingAnimationToElement(target);
    else
        target.addClass("ol-table-content-loading");
    
    target.getLoad("/matchdaytable/scorertable", data, clbk, append);
}

function loadMatchdayTableGoalgetterContent(target, data, callback) {
    if (ol.userSelection.page === false) return;
    data.page = ol.userSelection.page;
    append = false;
    if (data.page == 1) {
    } else {
        append = true;
        target = $("#scorerTableBody");
    }
    var clbk = function(response) {
        if (response.length == 0) {
            ol.userSelection.page = false;
            $("#divNoMorePlayer").show();
        }
        if (ol.userSelection.page == 1)
             removeLoadingAnimationFromElement(target);
        else
            target.removeClass("ol-table-content-loading");
        $(window).scrollTop(scroll);
        if(callback) callback();
    }
    if (ol.userSelection.page == 1)
        addLoadingAnimationToElement(target);
    else
        target.addClass("ol-table-content-loading");
    
    target.getLoad("/matchdaytable/goalgettertable", data, clbk, append);
}

function loadMatchdayTableTopPlayerContentPreperation(target, data, callback) {
    getTopPlayerDropdownData(data);
    loadMatchdayTableTopPlayerContent(target, data, callback);
};

function loadMatchdayTableTopPlayerContent(target, data, callback) {
    if (ol.userSelection.page === false) return;
    lastMatchday = data.matchday;
    data.page = ol.userSelection.page;
    data.navId = 'matchdayTable'
    append = false;
    if(data.page == 1) {
    } else {
        append = true; 
        target = $("#topplayerTableContent");
    }
    var clbk = function(response) {
        if (response.length == 0) {
            ol.userSelection.page = false;
            $("#divNoMorePlayer").show();
        }
        if (ol.userSelection.page == 1)
             removeLoadingAnimationFromElement(target);
        else
            target.removeClass("ol-table-content-loading");
        $(window).scrollTop(scroll);
        if (callback) callback();
        ol.loading = false;
    }
    if (ol.userSelection.page == 1)
        addLoadingAnimationToElement(target);
    else
        target.addClass("ol-table-content-loading");
    target.getLoad("/matchdaytable/topplayertable", data, clbk, append);
}

function toggleLineDiagram(checkbox, userId, diagramName) {
    if ($(checkbox).prop('checked')) {
        lineDiagram.diagrams[diagramName].showLine(userId);
    } else {
        lineDiagram.diagrams[diagramName].hideLine(userId);
    }
    
    $("#ol-dia-checkboxes").find("input[type='checkbox']").each(function(index, element) {
        var uid = $(element).val();
        var $filter = $('#filterPill' + uid);
        var $checkbox = $("#diaCheckboxLine" + uid);
        if ($(element).prop("checked")) {
            color = lineDiagram.diagrams[diagramName].getLineColor(uid);
            $checkbox.css("border-width", 0);
            $checkbox.css("border-bottom", "solid 3px " + color);
            $('#checkboxContainer' + uid).find('i').css("background", color);
            $filter.css("background", color);
            $filter.closest('.ol-transfer-filter-pill').show();
        } else {
            $checkbox.css("border-width", 0);
            $filter.closest('.ol-transfer-filter-pill').hide();
        }
    });
}

function removeDiagramFilter(attrId) {
    $('#checkbox'+attrId).click();
}

function onHoverLine(name, hoverIn) {
}

function onHoverOut() {
    if (olGUI.isMobileOrTabletDevice()) return;
    $("#ol-dia-tooltip").fadeOut(200);
}

var mobileHoverTimer = 0;

function onHoverPoint(mousePos, hoverIn, name, property) {
    if (hoverIn) {
        var matchday = (mousePos.x + 1);
        var rank = (mousePos.y);
        pointPos = lineDiagram.diagrams[name].pointToAbs(mousePos.x, mousePos.y);
        var matchId = matchResults[property][matchday];
        var match = matchResults["matches"][matchId];
        var valid = true;
        if (match["user1TeamName"] === null) {
            match["user1TeamName"] = " - ";
        }
        if (match["user2TeamName"] === null) {
            match["user2TeamName"] = " - ";
        }
        if (match["goals_player1"] === null || match["goals_player2"] === null)
            valid = false; 
        
        
        $("#ol-dia-tooltip").css("left", 50);
        $("#ol-dia-tooltip").css("top", 50);  
        
        $('#ol-dia-tooltip').css('width', 'auto');
        
        // $("#diaTooltipMatchday").html(matchday);
        $("#diaTooltipMatchday").html(Lang.trans('js/match.n_matchday', { n: matchday }));
        // $("#diaTooltipRank").html(rank);
        $("#diaTooltipRank").html(Lang.trans('js/match.n_rank', { n: olGUI.formatOrdinal(rank) }));
                
        $("#diaTooltipLogo1").html($('#diaUserBadge' + match["user1Id"]).clone().show());

       // $("#diaTooltipLogo2").addClass("ol-matchday-testlogo" + (match["user2Id"] % 5 + 1));
        $("#diaTooltipLogo2").html($('#diaUserBadge' + match["user2Id"]).clone().show());
        
        
        $("#diaTooltipUser1").removeClass("ol-bold");
        $("#diaTooltipUser2").removeClass("ol-bold"); 
        $("#diaTooltipUser1").html(match["user1TeamName"]);
        $("#diaTooltipUser2").html(match["user2TeamName"]);
        if (property == match["user1Id"])
            $("#diaTooltipUser1").addClass("ol-bold");
        if (property == match["user2Id"])
            $("#diaTooltipUser2").addClass("ol-bold");
        
        result = (valid ? (match["goals_player1"] + " : " + match["goals_player2"] 
                + " (" + match["goals_first_half_user1"] + " : " + match["goals_first_half_user2"] + ")") : " - : - (- : -)");
        
        $("#diaTooltipMatchResult").html(result);
        $("#ol-dia-tooltip").css("width", $("#ol-dia-tooltip").width() * 1.1);
        
        var left = pointPos.x - $("#ol-dia-tooltip").width() / 2;
        var top = pointPos.y + $("#ol-dia-tooltip").height() / 2;
        
        var parentOffset = $("#ol-dia-tooltip").parent().offset();
        var elementBottom = (top + parentOffset.top + $("#ol-dia-tooltip").height() + 50);
        var elementLeft = left + parentOffset.left;
        var elementRight = elementLeft + $("#ol-dia-tooltip").width();
                
        var screenBottom = $(window).scrollTop() + $(window).height();
        if (screenBottom < elementBottom)
            top -= elementBottom - screenBottom;
        if (elementLeft < 0)
            left += Math.abs(elementLeft);
        if ($(window).width() < elementRight)
            left -= (elementRight - $(window).width());
        
        $("#ol-dia-tooltip").css("left", left);
        $("#ol-dia-tooltip").css("top", top);   
        
        $("#ol-dia-tooltip").removeClass(function(index, css) { 
            return (css.match (/(^|\s)ol-state-color-light-\S+/g) || []).join(' ');
        });
        $("#ol-dia-tooltip").addClass("ol-state-color-light-" + ol.userSelection.stateId);

        $("#ol-dia-tooltip").fadeIn(200);

        if (olGUI.isMobileOrTabletDevice()) {
            clearTimeout(mobileHoverTimer);
            mobileHoverTimer = setTimeout(function() {
                $("#ol-dia-tooltip").fadeOut(200);
            }, 2500)
        }
    }
}