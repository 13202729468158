function onlineligaInfoView() {
    
    $('.premium-benefit-overview-detail').mouseenter(function() {
        $(this).addClass('animated');
    });

    $('.premium-benefit-overview-detail').mouseleave(function() {
        var par = $(this);
        setTimeout(function() {
            par.removeClass('animated');
        }, 0);
    });
    
    $.fn.allchange = function(callback) {
        var me = this;
        var last = "";
        var infunc = function() {
            var text = $(me).val();
            if (text != last) {
                last = text;
                callback();
            }
            setTimeout(infunc, 100);
        }
        setTimeout(infunc, 100);
    };

    function signup(no) {
        no = ($('#register-form1').css('display') == 'none' ? 2 : 1);

        if ($("#email" + no).attr("data-status") == "ok" && $("#teamname" + no).attr("data-status") == "ok") {
            $("#signup-loading").fadeIn();
            var data = new Object();
            data.operation = "signup";
            data.email = $("#email" + no).val().trim();
            data.teamname = $("#teamname" + no).val();
            data.invitationcode = $("#invitationCode" + no).val()
            $.getJSON("/signup.php", data, function(json) {
                $("#signup-loading").fadeOut();
                if (json.status == "ok") {
                    ga('send', 'event', 'registration', 'signup');
                    fbq('track', 'CompleteRegistration');
                    $('.sendbutton1').fadeOut(400);
                    $('#register-page').fadeOut(400);
                    $("#register-success").fadeIn(400);
                    $('html, body').animate({
                        scrollTop: $('#register-success').offset().top
                    }, 500);
                    setTimeout(function() { 
                        $("#register-success").fadeOut(400);
                        $('.content-head').fadeIn(400);
                        $('.register-button-real').hide();
                    }, 10000);
                } else {
                    $("#teamname" + no).val("");
                    $("#email" + no).val("");
                }
            });
        }
    }

    function _resize() {
        $(".autoscale").each(function(index, element) {
            var opt = $(element).attr("data-align");
            if (typeof opt === typeof undefined || opt === false) opt = false;
            var height = $(element).attr("data-height");
            var width = $(element).attr("data-width");
            var scale = $(element).attr("data-scale");
            var offsetTop = $(element).attr("data-offset-top");
            var heightOffset = $(element).attr("data-height-offset");
            var minHeight = $(element).attr("data-min-height");
            if (typeof minHeight === typeof undefined || minHeight === false) minHeight = false;
            if (typeof heightOffset === typeof undefined || heightOffset === false) heightOffset = 0;
            if (typeof offsetTop === typeof undefined || offsetTop === false) offsetTop = false;
            heightOffset = parseInt(heightOffset);
            var fitToScreen = $(element).attr("data-fittoscreen");
            if (typeof fitToScreen === typeof undefined || fitToScreen === false) fitToScreen = false;
            var aspectRation = width / height;
            var viewportWidth = $( window ).width();
            var viewportHeight = $( window ).height() + heightOffset;

            if (minHeight !== false)
                viewportHeight = Math.max(minHeight, viewportHeight);

            var aspectRatioWidth = width / viewportWidth;
            var aspectRatioHeight = height / viewportHeight;

            var imgWidth;
            var imgHeight;
            var imgHeightScaled;
            var imgWidthScaled;

            if ((fitToScreen == "true" && viewportWidth/width < viewportHeight/height) 
             || aspectRatioWidth < aspectRatioHeight) {
                imgWidth = viewportWidth * scale;
                imgHeight = imgWidth / aspectRation;
            } else {
                imgHeight = viewportHeight * scale;
                imgWidth = imgHeight * aspectRation * scale;
            }
            var imgHeightScaled = imgHeight * scale;
            var imgWidthScaled = imgWidth * scale;
            $(element).width(imgWidthScaled);
            $(element).height(imgHeightScaled);
            if (opt === false || opt === "center") {
                if (offsetTop !== false)
                    $(element).css("margin-top", 0);
                else
                    $(element).css("margin-top", parseInt((viewportHeight - imgHeightScaled) * 0.5));

                $(element).css("margin-left", parseInt((viewportWidth - imgWidthScaled) * 0.5));
            } else if (opt === "left") {
                if (offsetTop !== false)
                    $(element).css("margin-top", 0);
                else
                    $(element).css("margin-top", parseInt((imgHeight - imgHeightScaled) * 0.5));
                $(element).css("margin-left", 0);
            } else if (opt === "right") {
                if (offsetTop !== false)
                    $(element).css("margin-top", 0);
                else
                    $(element).css("margin-top", parseInt((imgHeight - imgHeightScaled) * 0.5));
                $(element).css("margin-left", "auto");
                $(element).css("margin-right", "0");
                $(element).css("right", "0");
            } else {
            }
        });

        $('#landingPageContent').height($('.background-image').height());
    }

    var timeOutTeamName = false;
    function checkTeamName(no) {
        if (timeOutTeamName !== false) clearTimeout(timeOutTeamName);
        timeOutTeamName = setTimeout(function() { 
            $("#teamname" + no).attr("data-status", "fail");
            var data = new Object();
            data.teamname = $("#teamname" + no).val();
            if (!data.teamname) return;
            if (data.teamname.length == 0) {
                $("#teamname" + no).attr("data-status", "ok");
                $("#teamnameOk").hide();
                $("#teamnameDuplicate").hide();
                $("#teamnameWrong").hide();
                return;
            }
            data.operation = "validateteamname";
            $.getJSON("/signup.php", data, function(json) {
                if (json.status == "ok") {
                    $("#teamname" + no).attr("data-status", "ok");
                    $("#teamnameOk" + no).show();
                    $("#teamnameDuplicate" + no).hide();
                    $("#teamnameWrong" + no).hide();
                } else if (json.status == "duplicate") {
                    $("#teamnameDuplicate" + no).show();
                    $("#teamnameWrong" + no).hide();
                    $("#teamnameOk" + no).hide();
                } else if (json.status == "wrong") {
                    $("#teamnameWrong" + no).show();
                    $("#teamnameDuplicate" + no).hide();
                    $("#teamnameOk" + no).hide();
                }
            });
        }, 500);
    }

    var timeOutEmail = false;
    function checkEmail(no) {
        var email = $("#email" + no).val().trim();
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            if (timeOutEmail !== false) clearTimeout(timeOutEmail);
            timeOutEmail = setTimeout(function() {
                $("#email" + no).attr("data-status", "fail");
                var data = new Object();
                data.email = $("#email" + no).val();
                data.operation = "validateemail";
                $.getJSON("/signup.php", data, function(json) {
                    if (json.status == "ok") {
                        $("#email" + no).attr("data-status", "ok");
                        $("#emailOk" + no).show();
                        $("#emailDuplicate" + no).hide();
                        $("#emailWrong" + no).hide();
                    } else if (json.status == "duplicate") {
                        $("#emailDuplicate" + no).show();
                        $("#emailWrong" + no).hide();
                        $("#emailOk" + no).hide();
                    } else if (json.status == "wrong") {
                        $("#emailWrong" + no).show();
                        $("#emailDuplicate" + no).hide();
                        $("#emailOk" + no).hide();
                    }
                });
            }, 500);
        } else {
            $("#emailWrong" + no).show();
            $("#emailDuplicate" + no).hide();
            $("#emailOk" + no).hide();
        }
    }

    $(function() {
        $("#email1").allchange(function() { checkEmail(1); } );

        $("#teamname1").allchange(function() { checkTeamName(1); } );

        $("#email1").on("keypress", function(event) {               
            if (event.keyCode === 13) {
                $("#email1").blur();
                event.preventDefault();
            }
        });

        $("#teamname1").on("keypress", function(event) {               
            if (event.keyCode === 13) {
                $("#teamname1").blur();
                event.preventDefault();
            }
        });

        $("#invitationCode1").on("keypress", function(event) {               
            if (event.keyCode === 13) {
                $("#invitationCode1").blur();
                event.preventDefault();
            }
        });


        $("#email1").blur(function() {
            checkEmail(1);
        });

        $("#teamname1").blur(function() {
            checkTeamName(1);
        });

        var bigScreen = ($(window).width() > 1050);

        function _updateNav() {
            var scrollTop = $(window).scrollTop();
            if (bigScreen) {
                if (scrollTop >= 100) {
                    $(".head-nav").css('background-color', 'rgba(255, 255, 255, 1)');
                    $(".ol-nav-logo").css('opacity', '1');
                } else {
                    $(".head-nav").css('background-color', 'rgba(255, 255, 255, 0)');
                    $(".ol-nav-logo").css('opacity', '0');
                }
            } else {
                $(".head-nav").css('background-color', 'rgba(255, 255, 255, 1)');
                $(".ol-nav-logo").css('opacity', '1');
            }
        }

        $(window).resize(function() {
            if ($(window).width() > 1050 && $(window).width() > 768) {
                bigScreen = true;
            } else {
                bigScreen = false;
            }
            _updateNav();
        });

        $(document).scroll(function() {
            _updateNav();

            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            var scrollInOffset = $(window).height() * 0.0;
            var bottomScreen = $(window).height() + $(window).scrollTop() - scrollInOffset;

            $('.blend-in-group:not(.blended-in)').each(function() {
                var elemTop = $(this).offset().top;
                if ($(this).hasClass('blend-in-zoom'))
                    elemTop -= parseInt($(this).outerHeight());

                if (elemTop < docViewBottom) {
                    $(this).addClass('blended-in');
                    $(this).find('.blend-in').addClass('blended-in');
                }
            });

            $('.blend-in:not(.blended-in)').each(function() {
                var elemTop = $(this).offset().top;
                if ($(this).hasClass('blend-in-zoom'))
                    elemTop -= parseInt($(this).outerHeight());

                if (elemTop < docViewBottom) {
                    $(this).addClass('blended-in');
                }
            });

            $('.blended-in').each(function() {
                var elemTop = $(this).offset().top;
                if ($(this).hasClass('blend-in-zoom'))
                    elemTop -= parseInt($(this).outerHeight());

                if (elemTop > docViewBottom) {
                    $(this).removeClass('blended-in');
                }
            });
        }); 

        //$(".carousel").swiperight(function() {
        //    $(this).carousel('prev');
        //});
        //$(".carousel").swipeleft(function() {
        //    $(this).carousel('next');
        //});
        _resize();

        var sideMenu = false;
        $("#navigation, .ol-nav-menu > div > a").click(function() {
            if (!sideMenu) {
                $(".fixed-content").animate({left: "-300px"});
                $(".ol-nav-menu").animate({right: "-30px"});
                sideMenu = true;
            } else {
                $(".fixed-content").animate({left: "0px"});
                $(".ol-nav-menu").animate({right: "-330px"});
                sideMenu = false;     
            }
        });
    });

    $(window).on('resize', _resize);

    $(document).on('click', 'a[href^="#landingPageContent"]', function(event) {
        event.preventDefault();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });

    var player;
    var first = true;
    var apiReady = false;

    function onPlayerReady(event) {
        event.target.playVideo();
    }

    function onPlayerStateChange(state) {
        if (state.data == -1 && first) {
            first = false;
            ga('send', 'event', 'trailer', 'play');
        }

        if (state.data == 0 || state.data == 2) {
            ytplayer = $('#ytplayer').detach(); 
            $('#video-overlay').fadeOut(400);
            $('#register-button').fadeIn(400);
        }
    }

    function onYouTubeIframeAPIReady() {
        apiReady = true;
        player = new YT.Player('ytplayer', {
            height: $('#ytplayer').height(),
            width: $('#ytplayer').width(),
            videoId: 'zuXR2bOc9Ck',
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
        });
    }

    function initYTPlayer() {
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        if (apiReady)
            onYouTubeIframeAPIReady();
    }

}