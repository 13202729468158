// use the resources/views/misc/pieDiagram.blade.php template

(function(pieDiagram, $, undefined) {
    pieDiagram.init  = function(name, config) {
		var canvasId = "#ol-pie-diagram-" + name;
        var canvas = $(canvasId).get(0);	
		if (!canvas) {
			console.warn("Canvas with ID "+canvasId+" not found for pie diagram");
			return;
		}
        var context;
		
        var colors = config.colors;
        var angles = [];

		var i, valueSum = 0;
		for (i in config.values) {
			valueSum += Math.abs(config.values[i]);
		}
		for (i in config.values) {
			angles[i] = (config.values[i]/valueSum) * 2*Math.PI;
		}

        resizeCanvas();

		window.addEventListener('resize', resizeCanvas, false);
		// add ability to manually update diagram
		canvas.olDiagram = {
			update: function() {
				if (requestAnimationFrame) {
					requestAnimationFrame(resizeCanvas);
				} else {
					window.setTimeout(resizeCanvas, 10);
				}
			}
		};
        
        function resizeCanvas() {
			var scale = Math.max(window.devicePixelRatio, 1);
			var rect = canvas.getBoundingClientRect();
			canvas.width = rect.width*scale;
			canvas.height = rect.height*scale;
			context = canvas.getContext('2d');
//			context.scale(scale, scale);
            drawDiagram();
        }
        
        function drawDiagram() {
			var width = context.canvas.width;
			var height = context.canvas.height;
			var centerX = width * 0.5;
			var centerY = height * 0.5;
			var radius = Math.min(centerX, centerY);

            context.clearRect(0, 0, width, height);

			var color, toAngle, fromAngle = -Math.PI*0.5;	// start from top
			for (var i in angles) {
				context.beginPath();
				context.moveTo(centerX, centerY);
				toAngle = fromAngle + angles[i];
				context.arc(centerX, centerY, radius, fromAngle, toAngle);
				context.closePath();
				color = colors[i];
				if (!color) {
					color = "#ff00ff";	// default color
				}
				context.fillStyle = color;
				context.fill();
				fromAngle = toAngle;
			}
        }
    }
}( window.pieDiagram = window.pieDiagram || {}, jQuery ));