ol = new Object(); // namespace <- deprecated

var mobileReadyURLs = [
    "/", 
    "matchdaytable/matchdaytable",
    "matchdaytable/goalgetter",
    "matchdaytable/scorer",
    "matchdaytable/topplayer",
    "matchdaytable/cards",
    "matchdaytable/stadiums",
    "matchdaytable/elevenoftheday",
    "schedule",
    "team/overview",
    "team/squad",
    "team/lineup",
    "team/tactic",
    'team/training',
    '/match',
    'player/overview',
    '/player/overview',
    'statistics/ranking',
    'office/finance',
    'office/contracts',
    'office/training',
    'office/youthplayer',
    'configurator',
    'configurator/.*',
    'mystadium',
    'stadium/settings',
    'new-stadium',
    'dashboard',
    'office/settings'

];

/* Show user's balance in header for these URLs */
var mobileBalanceURLs = [
    'configurator',
    'stadium/settings',
    'office/contracts',
    'office/training',
    'office/youthplayer',
    'transferlist/gettransferlistview',
    'transferlist/getbidsoverview',
    'transferlist/getoffersoverview'
];

(function() {
    
    // == GLOBAL DECLERATIONS == 
    TouchMouseEvent = {
        DOWN: "touchmousedown",
        UP: "touchmouseup",
        MOVE: "touchmousemove"
    }
    
    // == EVENT LISTENERS == 
    var onMouseEvent = function(event) {
        var type;
        
        switch (event.type) {
            case "mousedown": type = TouchMouseEvent.DOWN; break;
            case "mouseup":   type = TouchMouseEvent.UP;   break;
            case "mousemove": type = TouchMouseEvent.MOVE; break;
            default: 
                return;
        }
        
        var touchMouseEvent = normalizeEvent(type, event, event.pageX, event.pageY);      
        $(event.target).trigger(touchMouseEvent); 
    }
    
    var onTouchEvent = function(event) {
        var type;
        
        switch (event.type) {
            case "touchstart": type = TouchMouseEvent.DOWN; break;
            case "touchend":   type = TouchMouseEvent.UP;   break;
            case "touchmove":  type = TouchMouseEvent.MOVE; break;
            default: 
                return;
        }
        
        var touch = event.originalEvent.touches[0];
        var touchMouseEvent;
        
        if (type == TouchMouseEvent.UP) 
            touchMouseEvent = normalizeEvent(type, event, null, null);
        else 
            touchMouseEvent = normalizeEvent(type, event, touch.pageX, touch.pageY);
        
        $(event.target).trigger(touchMouseEvent); 
    }
    
    // == NORMALIZE == 
    var normalizeEvent = function(type, original, x, y) {
        return $.Event(type, {
            pageX: x,
            pageY: y,
            originalEvent: original
        });
    }
    
    // == LISTEN TO ORIGINAL EVENT == 
    var jQueryDocument = $(document);
   
    if ("ontouchstart" in window) {
        jQueryDocument.on("touchstart", onTouchEvent);
        jQueryDocument.on("touchmove", onTouchEvent);
        jQueryDocument.on("touchend", onTouchEvent); 
    } else {
        jQueryDocument.on("mousedown", onMouseEvent);
        jQueryDocument.on("mouseup", onMouseEvent);
        jQueryDocument.on("mousemove", onMouseEvent);
    }
    
})();

function initXHRPoolManagement() {
    $(function() {
        window.xhrPool = [];
        window.xhrPool.abortAll = function() {
            $(this).each(function(i, jqXHR) {   //  cycle through list of recorded connection
                jqXHR.abort();  //  aborts connection
                window.xhrPool.splice(i, 1); //  removes from list by index
            });
        }
        $.ajaxSetup({
            beforeSend: function(jqXHR) { window.xhrPool.push(jqXHR); }, //  annd connection to list
            complete: function(jqXHR) {
                var i = window.xhrPool.indexOf(jqXHR);   //  get index for current connection completed
                if (i > -1) window.xhrPool.splice(i, 1); //  removes from list by index
            }
        });
    })
}

function init(autoSelect, loggedIn) {
    if (loggedIn === typeof undefinied) loggedIn=false;
    autoNavSelectionMapping = JSON.parse(autoSelect).autoNavSelectionMapping;
    
    // CSRF protection
    $.ajaxPrefilter(function(options, originalOptions, xhr) { 
        var xcsrf = (typeof options.xcsrf === typeof undefined ? true : options.xcsrf);
        // this will run before each request
        var token = $('input[name="_token"]').val(); // or _token, whichever you are using
        if (token && xcsrf) {
            return xhr.setRequestHeader('X-CSRF-TOKEN', token); // adds directly to the XmlHttpRequest Object
        }
    }); 
    
    olTimeCounter.init();
    initXHRPoolManagement();

    $('*[data-toggle="radio-collapse"]').each(function() {
        $(this).on('change', function() {
            $parent = $(this).attr("data-parent");
            $target = $(this).attr("data-target");
            $($parent).find('.collapse').collapse('hide'); 
            $($target).collapse('toggle')
        });
    });
    
    // bootstrap accordion workaround
    $('.ol-accordion').each(function(index, obj) {
        $(obj).on('show.bs.collapse', function() {
            $(this).find(".in").collapse('hide');
        }); 
    });
    
    // missing string includes support for ie11
    if (!String.prototype.includes) {
        String.prototype.includes = function(search, start) {
            if (typeof start !== 'number') {
                start = 0;
            }

            if (start + search.length > this.length) {
                return false;
            } else {
                return this.indexOf(search, start) !== -1;
            }
        };
    }
    
    // missing array includes support for ie11
    if (!Array.prototype.includes) {
        Object.defineProperty(Array.prototype, 'includes', {
            value: function(searchElement, fromIndex) {
                if (this == null) {
                    throw new TypeError('"this" is null or not defined');
                }

                // 1. Let O be ? ToObject(this value).
                var o = Object(this);

                // 2. Let len be ? ToLength(? Get(O, "length")).
                var len = o.length >>> 0;

                // 3. If len is 0, return false.
                if (len === 0) {
                    return false;
                }

                // 4. Let n be ? ToInteger(fromIndex).
                //    (If fromIndex is undefined, this step produces the value 0.)
                var n = fromIndex | 0;

                // 5. If n ≥ 0, then
                //  a. Let k be n.
                // 6. Else n < 0,
                //  a. Let k be len + n.
                //  b. If k < 0, let k be 0.
                var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

                function sameValueZero(x, y) {
                    return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
                }

                // 7. Repeat, while k < len
                while (k < len) {
                    // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                    // b. If SameValueZero(searchElement, elementK) is true, return true.
                    if (sameValueZero(o[k], searchElement)) {
                        return true;
                    }
                    // c. Increase k by 1. 
                    k++;
                }

                // 8. Return false
                return false;
            }
        });
    }
    
    // missing array find support for ie11
    if (!Array.prototype.find) {
        Object.defineProperty(Array.prototype, 'find', {
            value: function(predicate) {
                // 1. Let O be ? ToObject(this value).
                if (this == null) {
                    throw new TypeError('"this" is null or not defined');
                }

                var o = Object(this);

                // 2. Let len be ? ToLength(? Get(O, "length")).
                var len = o.length >>> 0;

                // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                if (typeof predicate !== 'function') {
                    throw new TypeError('predicate must be a function');
                }

                // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                var thisArg = arguments[1];

                // 5. Let k be 0.
                var k = 0;

                // 6. Repeat, while k < len
                while (k < len) {
                    // a. Let Pk be ! ToString(k).
                    // b. Let kValue be ? Get(O, Pk).
                    // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                    // d. If testResult is true, return kValue.
                    var kValue = o[k];
                    if (predicate.call(thisArg, kValue, k, o)) {
                        return kValue;
                    }
                    // e. Increase k by 1.
                    k++;
                }

                // 7. Return undefined.
                return undefined;
            },
            configurable: true,
            writable: true
        });
    }    
    
    initializeWidgets(); 
    initNav();
    initGetLoad(); 
    
    olAnchorNavigation.init();
    setTimeout(function() {
        if (olGUI.isMobileOrTabletDevice()) {
            $(lastElement || '#subnav_nav_matchday_table').show();
        }    
    }, 0);

    //Fingerprint init
    if (loggedIn) {
        olFingerprint.init();
    }
    
    var htmlTag = document.documentElement;
    var setDark = function() {
        localStorage.setItem('theme', 'dark-theme')
        $(htmlTag).removeClass('inherit-theme dark-theme light-theme');
        $(htmlTag).addClass('dark-theme');
        $.post('/office/settings/savecheckbox' , { name : 'DARK_MODE' , value : 1} , null , 'json' );
    };
    var setLight = function() {
        localStorage.setItem('theme', 'light-theme')
        $(htmlTag).removeClass('inherit-theme dark-theme light-theme');
        $(htmlTag).addClass('light-theme');
        $.post('/office/settings/savecheckbox' , { name : 'DARK_MODE' , value : 0} , null , 'json' );
    };

    function handleColorTheme() {
        var theme = localStorage.getItem('theme');

        // if no theme is set in user settings & no theme is saved in localStorage
        // (inherit-theme will only be set when user is logged in and has no theme set)
        if ($(htmlTag).hasClass('inherit-theme') && theme === null) {
            //check for system theme prefrence
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                // dark-theme:
                setDark();
            } else {
                // light-theme:
                setLight();
            }
        } else if ($(htmlTag).hasClass('inherit-theme') && theme !== null) {
            // if theme is already saved in localStorage but not db            
            theme == 'dark-theme' ? setDark() : setLight();
        }
    }

    if ($(htmlTag).hasClass('system-theme')) {
        // look for changes in theme.
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function(event) {            
            event.matches ? setDark() : setLight();
        });
    }

    handleColorTheme();
}

function parseURLParams(search) {
    var params = (search || location.hash || location.search);
    var idxQ = params.lastIndexOf('?');
    var idxA = params.lastIndexOf(';');
    var obj = {}, param;
    

    if (idxQ > -1) {
        if (idxA === -1 || idxA < idxQ)
            idxA = params.length;

        params = params.slice(idxQ + 1, idxA).split('&');
        for (var i = 0; i < params.length; i++) {
            if (params[i] === "")
                continue;

            param = params[i].split('=');
            obj[decodeURIComponent(param[0])] = decodeURIComponent(param[1]);
        }
    }

    return obj;
}

function loadPageContent(url, target, data, callback, reloadAds) {
    if (typeof data == "undefined")
        data = {};
    if (typeof callback == "undefined")
        callback = false;
    
    addLoadingAnimationToElement(target);
    clbk = function()  { 
        removeLoadingAnimationFromElement(target); 
        if (callback != false) callback(); 
    };
    
    unloadPage(target);
    $(target).getLoad(url, data, clbk, false, reloadAds);
}

function onUnloadPage(callback, target) {
    // no need to remove the onload event handler because of jquerys '.one()'
    if (typeof target === typeof undefined)
        target = $("#ol-root")
    
    $(target).one('unloadPage', callback);
}

function onUnloadGlobal(callback) {
    console.log("UnloadCallback:", callback)
    unloadPageHandlers.push(callback);
}

var unloadPageHandlers = [];
function unloadPage(target) {
    $(target).trigger('unloadPage');

    console.log("unloading", target);
    console.log(unloadPageHandlers);

    for (var x = 0; x < unloadPageHandlers.length; x++) {
        var callback = unloadPageHandlers[x];
        if (typeof callback === "function") {
            callback();
        }
    }

    unloadPageHandlers = [];
}

var stickyElementsCache = { elements: {}, scrollTop: {}, stickyPageReady: false };

function loadPage(url, data, callback, customLoadingOverlayTarget, post, autoNavSelection, newStickyUrl, lastStickyUrl, stickyScrollTo, instantLoadingAnimation) {
    if (typeof autoNavSelection === typeof undefined)
        autoNavSelection = true;
    if (typeof lastStickyUrl === typeof undefined)
        lastStickyUrl = false;
    if (typeof newStickyUrl === typeof undefined)
        newStickyUrl = false;
    if (typeof instantLoadingAnimation === typeof undefined)
        instantLoadingAnimation = false;
    
    if (typeof post === typeof undefined)
        post = false;
    
    if (!customLoadingOverlayTarget)
        customLoadingOverlayTarget = "#ol-root";
    
    if (typeof callback === "undefined")
        callback = false;
    
    var target = $("#ol-root");
	
    customLoadingOverlayTarget = $(customLoadingOverlayTarget);
    if (customLoadingOverlayTarget.length == 0)
            customLoadingOverlayTarget = target;
    addLoadingAnimationToElement(customLoadingOverlayTarget, instantLoadingAnimation ? { instant: true } : {});
    
	ol.userSelection.page = 1;
    if (url.substr(url.length - 10) !== "map/inline") {
        $('body').attr('data-page-url', url);

        var baseURL = url.split("?")[0];

        if (baseURL[0] == "/") {
            baseURL = baseURL.slice(1);
        }
        mobileUnlocked = false;
        for (var x = 0;  x < mobileReadyURLs.length;  x++) {
            var regex = new RegExp("^" + mobileReadyURLs[x] + "$");
            if (regex.test(baseURL)) {
                mobileUnlocked = true;
                break;
            } 
        }
        if (mobileUnlocked) {
            $('body').addClass('mobile-ready');
        } else {
            $('body').removeClass('mobile-ready');
        }

        if (mobileBalanceURLs.indexOf(baseURL) != -1) {
            $('body').addClass('show-nav-balance');
            olMobileApp.showBalance();
        } else {
            $('body').removeClass('show-nav-balance');
            olMobileApp.hideBalance();
        }
        
        $("body").removeClass("ol-nav-map");
        $("#ol-root").show();
        $("#ol-footer").show();
        if (ol.navMap === true) {
            mapIntegration.setMapActive(false);
        }
        
        function _autoNavSelection() {
            var questionmarkIndex = url.indexOf('?');
            var semicolonIndex = url.indexOf(';');
            var routeUrl = questionmarkIndex >= 0 ? url.substring(0, questionmarkIndex) : url;
            var vars = questionmarkIndex >= 0 ? url.substring(questionmarkIndex, semicolonIndex >= 0 ? semicolonIndex : url.length) : "";

            
            var target = autoNavSelectionMapping[0].nav;
            var subTarget = undefined;
            onClickNavigationItem("#subnav_nav_matchday_table", $("." + autoNavSelectionMapping[0].nav), false, "#" + autoNavSelectionMapping[0].subNav);
            $(autoNavSelectionMapping).each(function(index, object) {
                if(routeUrl.search(object.url) >= 0)
                {
                    target = object.nav;
                    subTarget = object.subNav;
                    if (object.hasOwnProperty('subNav'))
                        onClickNavigationItem("#subnav_" + object.nav, $("." + object.nav), false, "#" + object.subNav);
                    else if (object.hasOwnProperty('subTarget'))
                        onClickNavigationItem(object.target + "" + vars, $("." + object.nav), false, false);
                    else
                        onClickNavigationItem(object.target, $("." + object.nav), false, false);
                        
                    return false;
                }
            });

            olMobileApp.navigated(target, subTarget);
        }
        
        var clbk = function(text, error) {
            if (error === 'success') {
                setWindowScroll(0);
                if (autoNavSelection)
                    _autoNavSelection();
            }
            
            if (callback !== false) {
                callback();
                removeLoadingAnimationFromElement(customLoadingOverlayTarget); 
            } else {
                removeLoadingAnimationFromElement(customLoadingOverlayTarget); 
            }
            if (error === 'success')
                stickyElementsCache.stickPageReady = true;
            
            olMobileApp.loadStop();
        };
        if (lastStickyUrl !== false && stickyElementsCache.stickPageReady) {
            var $stickyPageElement = stickyElementsCache.elements.hasOwnProperty(lastStickyUrl) ? stickyElementsCache.elements[lastStickyUrl] : false;
            if($stickyPageElement !== false) stickyElementsCache.elements[lastStickyUrl] = false;
            stickyElementsCache.elements[lastStickyUrl] = $stickyPageElement;
            stickyElementsCache.scrollTop[lastStickyUrl] = $(window).scrollTop();
            var temp = $(target).children().clone();
            stickyElementsCache.elements[lastStickyUrl] = $(target).children().detach();
            $(target).append(temp);
        }
        unloadPage(target);
        stickyElementsCache.stickPageReady = false;
        if (newStickyUrl !== false) {
            var stickyElement = stickyElementsCache.elements.hasOwnProperty(newStickyUrl) ? stickyElementsCache.elements[newStickyUrl] : false;
            if (stickyElement !== false) {
                target.children().remove();
                target.append(stickyElement);
                stickyElementsCache.elements[newStickyUrl] = false;
                setTimeout(function() {
                    clbk('', 'success');
                    $(document).triggerHandler('ajaxComplete');
                    
                    if (stickyScrollTo) {
                        $(document).trigger('stickyLoad:' + newStickyUrl);
                        $(window).scrollTop(stickyElementsCache.scrollTop[newStickyUrl]);
                        if(stickyElementsCache.scrollTop[newStickyUrl] > 0)
                            closeSubnavigation();
                    } else {
                        $(document).trigger('stickyLoadNoScroll:' + newStickyUrl);
                    }
                }, 0);
                return;
            }
        }
        if (window.xhrPool) window.xhrPool.abortAll();
        if (post) {
            if (typeof data !== "undefined")
                target.load(url, data, clbk);
            else
                target.load(url, clbk); 
        } else {
            if (typeof data !== "undefined") {
                data.headers = {'SponsorBanner': 'reset'};
                target.getLoad(url, data, clbk);
            } else {
                target.getLoad(url, {'headers': {'SponsorBanner': 'reset'}}, clbk); 
            }
        }
    } else {
        $('body').attr('data-page-url', url);
        var clbk = function(text, error) {
			setWindowScroll(0);
                $("body").addClass("ol-nav-map");
                $("#ol-root").html("");
                $("#ol-root").hide();
                $("#ol-footer").hide();
                closeSubnavigation();
                removeLoadingAnimationFromElement(customLoadingOverlayTarget); 
                mapIntegration.setMapActive(true);
            if (callback !== false) {
                olMobileApp.loadStop();
                        callback();
                }
                
            if (error === 'success')
                stickyElementsCache.stickPageReady = true;

            olLeagueLicence.update({  'name' : 'map', 'type' : 'SHOW_TUTORIAL' });
        };
        
        if (lastStickyUrl !== false && stickyElementsCache.stickPageReady) {
            var $stickyPageElement = stickyElementsCache.elements.hasOwnProperty(lastStickyUrl) ? stickyElementsCache.elements[lastStickyUrl] : false;
            if($stickyPageElement !== false) stickyElementsCache.elements[lastStickyUrl] = false;
            stickyElementsCache.elements[lastStickyUrl] = $stickyPageElement;
            stickyElementsCache.scrollTop[lastStickyUrl] = $(window).scrollTop();
            var temp = $(target).children().clone();
            stickyElementsCache.elements[lastStickyUrl] = $(target).children().detach();
            $(target).append(temp);
        }
        stickyElementsCache.stickPageReady = false;
        unloadPage(target);
        if (mapIntegration.map===null) {
            url = url+window.location.search;
            if (post) {
                if (typeof data !== "undefined")
                    $("#ol-map-root").load(url, data, clbk);
                else
                    $("#ol-map-root").load(url, clbk); 
            } else {
                if (typeof data !== "undefined")
                    $("#ol-map-root").getLoad(url, data, clbk);
                else
                    $("#ol-map-root").getLoad(url, clbk); 
            }
        } else {
            clbk();
        }
    }
}

function directPageLoaded(url) {
    $('body').attr('data-page-url', url);
    if (url.substr(url.length-10) === "map/inline") return;

    var baseURL = url.split("?")[0];
    if (baseURL[0] == "/") {
        baseURL = baseURL.slice(1);
    }
    mobileUnlocked = false;
    for (var x = 0;  x < mobileReadyURLs.length;  x++) {
        var regex = new RegExp("^"+mobileReadyURLs[x]+"$");
        if (regex.test(baseURL)) {
            mobileUnlocked = true;
            break;
        }
    }
    if (mobileUnlocked) {
        $('body').addClass('mobile-ready');
    } else {
        $('body').removeClass('mobile-ready');
    }

    if (mobileBalanceURLs.indexOf(baseURL) != -1) {
        $('body').addClass('show-nav-balance');
        olMobileApp.showBalance();
    } else {
        $('body').removeClass('show-nav-balance');
        olMobileApp.hideBalance();
    }

    $("body").removeClass("ol-nav-map");
    $("#ol-root").show();
    $("#ol-footer").show();
    if (ol.navMap === true) {
        mapIntegration.setMapActive(false);
    }

    olAds && olAds.loadAxContainers && olAds.loadAxContainers(true); //DBG
}

function getWindowScroll() {
    var scroll;
    if (olAppInfo.APP_PLATFORM == "iphone") {
        scroll = $('#scrollRoot').scrollTop();
    } else {
        scroll = $('html').scrollTop() + $('body').scrollTop();
    }

    return scroll;
}

function setWindowScroll(pixels) {
    if (olAppInfo.APP_PLATFORM == "iphone") {
        $('#scrollRoot').scrollTop(pixels);
    } else {
        $(window).scrollTop(pixels);
        $('html').scrollTop(pixels);
        $('body').scrollTop(pixels);
    }
}

function initGetLoad() {
    $.fn.disable = function(disable) {
        if(this.hasClass('ol-disabled') && !disable)
            this.removeClass('ol-disabled').addClass('ol-not-disabled');
        else if(disable)
            this.addClass('ol-disabled').removeClass('ol-not-disabled');
        return this;
    };
    
    $.fn.toggleDisable = function() {
        if(this.hasClass('ol-disabled'))
            this.removeClass('ol-disabled').addClass('ol-not-disabled');
        else
            this.addClass('ol-disabled').removeClass('ol-not-disabled');
        return this;
    };

    // Alternatively takes an options object containing url, params, callback, append, reloadAds
    $.fn.getLoad = function(urlOrOptions, params, callback, append, reloadAds) {
        var url;
        if (typeof urlOrOptions === "object") {
            url = urlOrOptions.url;
            params = urlOrOptions.params;
            callback = urlOrOptions.callback;
            append = urlOrOptions.append;
            reloadAds = urlOrOptions.reloadAds;
        } else {
            url = urlOrOptions;
        }
        console.log("getLoad", url);

        var selector, type, response, appendToElement = false,
            self = this,
            off = url.indexOf(" ");

        if (off > -1) {
            selector = jQuery.trim(url.slice(off));
            url = url.slice(0, off);
        }

        if (typeof append !== "undefined")
            appendToElement = append;
        // If it's a function
        var headers = {};
        if (jQuery.isFunction(params)) {

            // We assume that it's the callback
            callback = params;
            params = undefined;

            // Otherwise, build a param string
        } else if (jQuery.isPlainObject(params)) {
            headers = params.headers || {};
            delete params.headers;
        }

        // If we have elements to modify, make the request
        if (self.length > 0) {
            return jQuery.ajax({
                url: url,

                // If "type" variable is undefined, then "GET" method will be used.
                // Make value of this field explicit since
                // user can override it through ajaxSetup method
                type: "GET",
                headers: headers,
                dataType: "html",
                data: params
            }).success(function(responseText) {

                // Save response for use in complete callback
                response = arguments;
                var content = selector ?
                    // If a selector was specified, locate the right elements in a dummy div
                    // Exclude scripts to avoid IE 'Permission Denied' errors
                    jQuery("<div>").append(jQuery.parseHTML(responseText)).find(selector) :
                    // Otherwise use the full result
                    responseText;
                
                if (appendToElement) {
                    //self.css("height", self.innerHeight() + "px");
                    self.append(content);
                } else {
                    self.html(content);
                }

                var reloadAll = reloadAds || (!appendToElement && self.attr('id') == 'ol-root');

                setTimeout(function() {
                    console.log("* Ads Debug | Reloading Ax Container from refresh", reloadAll);
                    //olAds.loadAxContainers(reloadAll).then(function(r) {
                    var p = olAds.loadAxContainers(reloadAll);
                    if (!p || !p.then) {
                        console.log('* Ads Debug |  loadAxContainers result not thenable', p);
                        //document.dispatchEvent(new Event('olAdsLoaded'));
                        return;
                    }
                    p.then(function(r) {
                        console.log("* Ads Debug | Loaded Containers:", r);
                    }).catch(function(error) {
                        console.error("* Ads Debug | Error loading Containers:", error);
                    }).finally(function() {
                        var event = new Event('olAdsLoaded');
                        document.dispatchEvent(event);
                    });
                }, 0)

                // If the request succeeds, this function gets "data", "status", "jqXHR"
                // but they are ignored because response was set above.
                // If it fails, this function gets "jqXHR", "status", "error"
            }).always(function(jqXHR, status) {

                if (callback) {
                    self.each(function() {
                        callback.apply(this, response || [jqXHR.responseText, status, jqXHR]);
                    });
                }

                console.log("getLoad done", status)

                var event = new Event('olContentChanged');
                dispatchEvent(event);
            });
        }

        return this;
    };

    $.fn.isInViewport = function() {
        return isInViewport(this);
    };
}

function getDocHeight() {
    if (olAppInfo.APP_MODE && olAppInfo.APP_PLATFORM == "iphone") {
        return document.getElementById('scrollRoot').scrollHeight;
    }

    var D = document;
    return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
    );
}

function isInViewport(target) {
    var offset = $(target).offset();
    
    if (olAppInfo.APP_MODE && olAppInfo.APP_PLATFORM == "iphone") {
        if (!offset) {
            return false;
        }
        return offset.top > 0 && offset.top < $('#scrollRoot').height();
    }
    if (typeof offset === typeof undefined)
        return false;
    var elemTop = offset.top;
    var elemBot = elemTop + $(target).outerHeight();
    var viewTop = getWindowScroll();
    var viewBot = viewTop + $(window).height();
    return (elemTop > viewTop && elemTop < viewBot) || (elemBot > viewTop && elemBot < viewBot);
}

function inViewport($el) {
    if ($el.size() == 0) return 0;

    if (olAppInfo.APP_MODE && olAppInfo.APP_PLATFORM == "iphone") {
        var offset = $el.offset();
        if (!offset) {
            return false;
        }
        return offset.top > 0 && offset.top < $('#scrollRoot').height();
    }

    var elH = $el.outerHeight(),
        H   = $(window).height(),
        r   = $el[0].getBoundingClientRect(), t=r.top, b=r.bottom;
    return Math.max(0, (t > 0 ? Math.min(elH, H-t) : (b<H?b:H)));
}

var currentLoadingAnimationTarget = false;
var loadingAnimationOngoing = false;

var requestUpdateLoadingAnimationOverlay = false;
var updateLoadingAnimationOverlay = function() {
    if (currentLoadingAnimationTarget !== false && loadingAnimationOngoing === true) {
        if ($(currentLoadingAnimationTarget).length > 0) {    
            var target = currentLoadingAnimationTarget;
            var offset = $(target).offset();
            var width = $(target).outerWidth();
            var height = $(target).outerHeight();

            $('#overlayLoadingElement2').offset(offset);
            $('#overlayLoadingElement2').width(width);
            $('#overlayLoadingElement2').height(height);

            var visibleHeight = inViewport($(target));
            if (visibleHeight < $(target).outerHeight()) {
                if (getWindowScroll() < offset.top) {
                    visibleHeight = visibleHeight * 0.5;
                } else {
                    visibleHeight = visibleHeight * 0.5 + ($(target).outerHeight() - visibleHeight);
                }
            } else {
                visibleHeight = $(target).outerHeight() * 0.5;
            }
            offset.top += visibleHeight;
            $('#overlayLoadingElement').offset(offset);
            $('#overlayLoadingElement').width(width);
            $('#overlayLoadingElement').height(height);
        }
        requestUpdateLoadingAnimationOverlay = requestAnimationFrame(updateLoadingAnimationOverlay);
    } else {
        setTimeout(function() {updateLoadingAnimationOverlay}, 10);
    };
};


$(function() {
    if (requestUpdateLoadingAnimationOverlay !== false) cancelAnimationFrame(requestUpdateLoadingAnimationOverlay);
    requestUpdateLoadingAnimationOverlay = requestAnimationFrame(updateLoadingAnimationOverlay);
});

var loadingAnimationTimer = false;

function _addLoadingAnimationToElement(target, opt)
{
    if ($(target).size() == 0) {
        console.warn('[WARN] Trying to add loading animation to non-existing element.', target)
        return false;
    }
    loadingAnimationOngoing = true;
    loadingAnimationTimer = false;
    var size = "medium";
    var overlay = false;
    if (typeof opt !== "undefined") {
        if (opt.hasOwnProperty("size"))
            size = opt.size;
        if (opt.hasOwnProperty("overlay"))
            overlay = opt.overlay;
    }
    
    if (size == "medium") {
        $('#overlayLoadingElement')
            .removeClass("ol-page-loading-small")
            .addClass("ol-page-loading");
    } else if (size == "small") {
        $('#overlayLoadingElement')
            .removeClass("ol-page-loading")
            .addClass("ol-page-loading-small");
    } else if (size == 'button') {
        $(target).addClass("ol-button-loading");
        return;
    }
    
    $("#overlayLoadingElement2").removeClass('ol-page-loading-overlay-fade-in');
    $("#overlayLoadingElement").removeClass('ol-page-loading-fade-in');
    $('#overlayLoadingElement').show();
    $('#overlayLoadingElement2').show();
    
    if (overlay) {
        $("#overlayLoadingElement2").css("z-index", 10000);
        $("#overlayLoadingElement").css("z-index", 10000);
    } else {
        $("#overlayLoadingElement2").css("z-index", 2);
        $("#overlayLoadingElement").css("z-index", 2);
    }
    
    var offset = $(target).offset();
    var width = $(target).outerWidth();
    var height = $(target).outerHeight();
    
    $('#overlayLoadingElement2').offset(offset);
    $('#overlayLoadingElement2').width(width);
    $('#overlayLoadingElement2').height(height);
    
    var visibleHeight = inViewport($(target));
    if (visibleHeight < $(target).outerHeight()) {
        if (getWindowScroll() < offset.top) {
            visibleHeight = visibleHeight * 0.5;
        } else {
            visibleHeight = visibleHeight * 0.5 + ($(target).outerHeight() - visibleHeight);
        }
    } else {
        visibleHeight = $(target).outerHeight() * 0.5;
    }
    //if(visibleHeight < 45)
    //    visibleHeight = (visibleHeight - 45) * 0.5
    //visibleHeight = Math.max(90/2, visibleHeight);
    offset.top += visibleHeight;
    $('#overlayLoadingElement').offset(offset);
    $('#overlayLoadingElement').width(width);
    $('#overlayLoadingElement').height(height);
    currentLoadingAnimationTarget = target;
    functionId = window.setTimeout(function() {
        $("#overlayLoadingElement2").addClass('ol-page-loading-overlay-fade-in');
        $("#overlayLoadingElement").addClass('ol-page-loading-fade-in');
        window.clearTimeout(functionId);
    }, 1);
}


function addLoadingAnimationToElementNoTimeOut(target, opt) {
    if(loadingAnimationTimer != false) clearTimeout(loadingAnimationTimer);
    loadingAnimationTimer = 0;
    _addLoadingAnimationToElement(target, opt);
}


function addLoadingAnimationToElement(target, opt) {
    if (loadingAnimationTimer != false) clearTimeout(loadingAnimationTimer);
    if (typeof opt !== "undefined" && opt.hasOwnProperty('instant') && opt.instant === true)
         _addLoadingAnimationToElement(target, opt);
    else
        loadingAnimationTimer = setTimeout(function() { _addLoadingAnimationToElement(target, opt); }, 2000);
}

$.fn.addLoadingAnimation = function(timeout) {
    if (timeout) {
        addLoadingAnimationToElement(this);
    } else {
        addLoadingAnimationToElementNoTimeOut(this);
    }
    return this;
}

function removeLoadingAnimationFromElement(target) {    
    if (loadingAnimationTimer != false)  {
        clearTimeout(loadingAnimationTimer);
        loadingAnimationTimer = false;
        return;
    }
    loadingAnimationOngoing = false;
    var offset = $(target).offset();
    var width = $(target).outerWidth();
    var height = $(target).outerHeight();
    $('#overlayLoadingElement2').offset(offset);
    $('#overlayLoadingElement2').width(width);
    $('#overlayLoadingElement2').height(height);
    $("#overlayLoadingElement2").removeClass('ol-page-loading-overlay-fade-in');
    $("#overlayLoadingElement").removeClass('ol-page-loading-fade-in');
    $(target).removeClass("ol-button-loading");
    functionId = window.setTimeout(function() {    
        $("#overlayLoadingElement2").hide();
        $("#overlayLoadingElement").hide();
        window.clearTimeout(functionId);
    }, 150);
}

$.fn.removeLoadingAnimation = function() {
    removeLoadingAnimationFromElement(this);
    return this;
}

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
       // Edge (IE 12+) => return version number
       return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

function initValueBars() {
    $(".ol-value-bar").each(function(index, element) {
        var spanElement = $(element).find("div.ol-value-bar-color > span");
        $(element).show();
        spanElement.css("width", $(element).outerWidth());
    });
    if (detectIE() !== false) {
        $(".ol-value-bar").each(function() {
            var bar = $(this);
            if (parseInt(bar.attr("data-value")) > 60) {
                bar.find(".ol-value-bar-value").css("color", "white");
            } else {
                bar.find(".ol-value-bar-value").css("color", "black");
            }
        });
    }
}

function scaleIcons() {
    $(".ol-scale-icon").each(function(index, element) {
        var height = $(element).attr("data-target-height");
        var icon = $(element).find("span");
        var factor = height / icon.outerHeight();
        $(element).css("transform", "scale(" + factor + ")");
        $(icon).css("margin-top", -(icon.outerHeight() - height) + "px");
        $(element).css("height", height + "px");
    });
}

function getStateColor(userStateId, secondary) {
    if (typeof secondary === typeof undefined)
        secondary = false;
    
    var cls = "ol-state-primary-color-";
    if (secondary)
        cls = "ol-state-secondary-color-";
    
    var helper = $("<div>");
    var lineColor = helper.appendTo("body").addClass(cls + userStateId).css("background-color");
    helper.remove();
    return lineColor;
}

function setStateFontColor(element, userStateId){
    element.style.color = getStateColor(userStateId);
}

if (olAppInfo.APP_MODE && olAppInfo.APP_PLATFORM == 'iphone') {
    var el = document.querySelector("html");
    el.addEventListener('scroll', function(ev) {
        document.dispatchEvent(ev);
    })
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// IE11 polyfill
// See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
if (!String.prototype.startsWith) {
    Object.defineProperty(String.prototype, 'startsWith', {
        value: function(search, rawPos) {
            var pos = rawPos > 0 ? rawPos|0 : 0;
            return this.substring(pos, pos + search.length) === search;
        }
    });
}

function openSubPage(scroll){ 
    if (scroll === undefined) scroll = false;

    var args = parseURLParams(location.search);
    if (!args.subPage || args.subPage === 'undefined') args.subPage = '';

    if (args.subPage) {           
        var $btn = $('button[data-sub-url="'+args.subPage+'"]');

        if(!$btn.hasClass('active')){
            $btn.click();
            if (scroll && $btn.length) {
                $btn[0].scrollIntoView({behavior: "smooth", block: "start"});
            }
        }
        
    } else {
        window.history.replaceState({}, "", location.href.replace(/([?&])subPage=[^&#]*/g, '$1').replace(/\?&/g, '?').replace(/\?(?=#|;|$)/g, '').replace(/&(?=#|;|$)/g, ''));
    }
}

function addUrlParams(param) {
    if (!param || param == '?subPage=') return;
    //window.history.replaceState({}, "", location.pathname+location.hash.replace(/\?.*$/, '')+param);
    window.history.replaceState({}, "", location.href.replace(/\?.*?(?=#|;|$)/g, '')+param);
}

function openSupportForm() {
    var supportForm = document.getElementById('contactForm');
    if(supportForm) {
        supportForm.scrollIntoView({behavior: "smooth", block: "start"});
    } else {
        olOverlayWindow.load('/support/form');
    }
}