(function(olOverlayWindow, $, undefined)
{
    var _callbackEnter = false;
    var _callbackEsc = false;
    var _open = false;
    var maxWidth = false;
    var borders = true;
    var background = 'white';
    var force = false;
    var handler = false;
    var maxHeight = false;
    var closeIcon = true;
    var fullscreen = false;

    $(document).keyup(function(e)
    {
        if(_open && e.keyCode == 13 && _callbackEnter !== false)
            _callbackEnter(e);
        else if(_open && e.keyCode === 27 && _callbackEsc !== false)
            _callbackEsc(e);
    });

    // NOTE: Differs from olOverlayWindow.load (without the extensions). Intentional?
    olOverlayWindow.postLoad = function(url, data, callback, callbackEnter, callbackEsc)
    {
        if(typeof callbackEnter !== typeof undefined) _callbackEnter = callbackEnter;
        else _callbackEnter = false;
        if(typeof callbackEsc !== typeof undefined) _callbackEsc = callbackEsc;
        else _callbackEsc = false;
        if(typeof callback === typeof undefined) callback = false;
        addLoadingAnimationToElement($("#ol-root"));
        $.post(url, data, function(response)
        {
            if(typeof response === 'object')
            {
                var json = response;
                removeLoadingAnimationFromElement($("#ol-root"));
                if(typeof json.type !== typeof undefined)
                    olMsgBox.json(json, callback, callback);
            }
            else
            {
                var title = $(".ol-overlay-window-content #overlayTitle").text();
                var subtitle = $(".ol-overlay-window-content #overlaySubtitle").text();
                var opts = $('#overlayOptions');
                $(".ol-overlay-window-content").html(response);
                $('.ol-overlay-window-header-icon').remove();
                if(opts.length == 0)
                {
                    maxWidth = false;
                    borders = true;
                }
                removeLoadingAnimationFromElement($("#ol-root"));
                olOverlayWindow.open(title, subtitle);
                if(callback !== false) callback(response);
            }
        });
    };

    // Sets header title back to the value specified in content
    olOverlayWindow.setTitleFromContent = function()
    {
        var title = $(".ol-overlay-window-content #overlayTitle").text();
        if (title) $(".ol-overlay-window-header .ol-overlay-window-header-title").text(title);
    }

    olOverlayWindow.setContent = function(content)
    {
        $(".ol-overlay-window-content").html(content);
        $('.ol-overlay-window-header-icon').remove();
        executeResizeListeners();
    }

    olOverlayWindow.loadOver = function(parent, url, data, callback, callbackEnter, callbackEsc)
    {
       // todo  get it from tranfer list
    };

    // Same as olOverlay.load with given HTML instead of url to call
    olOverlayWindow.html = function(html, callback, callbackEnter, callbackEsc, options) {
        if (!options) options = {};
        options.html = html;
        olOverlayWindow.load('', undefined, callback, callbackEnter, callbackEsc, options);
    };
    var _wasOpen = false;
    olOverlayWindow.showFadingLock = function()
    {
        _wasOpen = _open;
        if(_open) return;
        olOverlayWindow.open('', '', false, true);
        $('#overlayWindow .ol-page-content-width').hide();
    }

    olOverlayWindow.hideFadingLock = function()
    {
        if(_wasOpen) return;
        olOverlayWindow.close();
    }
    
    // If options.html is true then `url` is the HTML to display.
    // Otherwise a GET call of `url` is performed and the action depends on the response:
    // - a JSON object without 'type' field but with 'err' and/or 'html' field to show a MsgBox with this error text (if 'err' is not empty) or otherwise to display the HTML (if 'html' is not empty)
    // - a JSON object with a 'type' field ('msg', ...) to call MsgBox.json with it
    // - JSON parsable text to call MsgBox.json with the converted object
    // - HTML to display
    olOverlayWindow.load = function(url, data, callback, callbackEnter, callbackEsc, options)
    {
        if(typeof callbackEnter !== typeof undefined) _callbackEnter = callbackEnter;
        else _callbackEnter = false;
        if(typeof callbackEsc !== typeof undefined) _callbackEsc = callbackEsc;
        else _callbackEsc = false;
        if(typeof callback === typeof undefined) callback = false;
        if(typeof options === typeof undefined) options = false;
        var wasOpen = _open;
        var _t = false;
        if(_open)
            olOverlayWindow.addLoadingAnimation();
        else
        {
            if(options !== false && typeof options.loadingAnimationTarget !== typeof undefined)
            {
                var size = (typeof options.loadingAnimationSize !== typeof undefined) ? options.loadingAnimationSize : 'medium';
                _t = $(options.loadingAnimationTarget);
                if(typeof options.loadingAnimationNoTimeout !== typeof undefined && options.loadingAnimationNoTimeout)
                    addLoadingAnimationToElementNoTimeOut($(options.loadingAnimationTarget), { 'size': size });
                else
                    addLoadingAnimationToElement($(options.loadingAnimationTarget), { 'size': size });
            } else if (options && options.maxwidth) {
                maxwidth = options.maxwidth;
            }
            else
            {
                _t = $("#ol-root");
                addLoadingAnimationToElement($("#ol-root"));
            }
        }

        $('.ol-mobile-bottom-nav-section').fadeOut();
        hideMobileNav();

//        $.get(url, data, function(response)
//        {
//            try
//            {
//                if(response !== null && typeof response === 'object')
//                {
//                    removeLoadingAnimationFromElement(_t);
//                    olMsgBox.json(response);
//                }
//                else
//                {
//                    var json = $.parseJSON(response);
//                    removeLoadingAnimationFromElement(_t);
//                    if(typeof json.type !== typeof undefined)
//                        olMsgBox.json(json);
//                }
//            } catch (e)
//            {
//                var opts = $('#overlayOptions');
//                if(opts.length == 0)
//                {
//                    maxWidth = false;
//                    borders = true;
//                }
//                $(".ol-overlay-window-content").html(response);
//                $('.ol-overlay-window-header-icon').remove();
//                var title = $(".ol-overlay-window-content #overlayTitle").text();
//                var subtitle = $(".ol-overlay-window-content #overlaySubtitle").text();
//                olOverlayWindow.open(title, subtitle);
//                $('.ol-overlay-window-content').waitForImages(true).done(function()
//                {
//                    if(wasOpen)
//                        olOverlayWindow.removeLoadingAnimation();
//                    else
//                        removeLoadingAnimationFromElement(_t);
//                    if(callback !== false) callback(response);
//                });
//            }
//        });

        function showHtml(response) {
            var opts = $('#overlayOptions');
            if (opts.length == 0) {
                maxWidth = false;
                borders = true;
            }
            $('#overlayWindow .ol-page-content-width').show();
            $(".ol-overlay-window-content").html(response);
            $('.ol-overlay-window-header-icon').remove();
            var title = $(".ol-overlay-window-content #overlayTitle").text();
            var subtitle = $(".ol-overlay-window-content #overlaySubtitle").text();
            olOverlayWindow.open(title, subtitle);
            $('.ol-overlay-window-content').waitForImages(true).done(function() {
                if (wasOpen) {
                    olOverlayWindow.removeLoadingAnimation();
                } else {
                    removeLoadingAnimationFromElement(_t);
                }
                if (callback !== false) callback(response);
            });
        }

        var dfltHeader = (options && options.header)? (options.header ||'') : '', 
            dfltYes = (options && options.yes)? (options.yes || undefined) : undefined;

        if (options && options.html) {
            showHtml(options.html);
        } else {
            $.get(url, data, function(response) {
                try {
                    if (response !== null && typeof response === 'object') {
                        removeLoadingAnimationFromElement(_t);
                        if (!response.type && response.err) {
                            olMsgBox.json({type: 'msg', content: response.err, header: dfltHeader, yes: dfltYes});
                        } else if (!response.type && response.html) {
                            showHtml(response.html);
                        } else {
                            olMsgBox.json(response);
                        }
                    } else {
                        var json = $.parseJSON(response);
                        removeLoadingAnimationFromElement(_t);
                        if (typeof json.type !== typeof undefined)
                            olMsgBox.json(json);
                    }
                } catch (e) {
                    showHtml(response);
                }
            });
        }
    };

    function _readOptions()
    {
        maxWidth = $('#overlayOptions').attr('data-max-width');
        if(typeof maxWidth === typeof undefined) maxWidth = false;
        borders = $('#overlayOptions').attr('data-borders');
        if(typeof borders === typeof undefined || borders === false) borders = true;
        else borders = (borders === 'false' ? false : true);
        background = $('#overlayOptions').attr('data-background-color');
        if(typeof background === typeof undefined || background === false) background = 'white';
        force = $('#overlayOptions').attr('data-force');
        if(typeof force === typeof undefined || force === false) force = false;
        maxHeight = $('#overlayOptions').attr('data-max-height'); // z.B. "0.9" = 90% der verfügbaren Fensterhöhe
        if (maxHeight === undefined) maxHeight = false;
        closeIcon = $('#overlayOptions').attr('data-close-icon');
        if (closeIcon === undefined) closeIcon = true;
        else closeIcon = (closeIcon === 'false' ? false : true);
        fullscreen = $('#overlayOptions').attr('data-fullscreen'); // z.B. "0.9" = 90% der verfügbaren Fensterhöhe
        if (fullscreen === undefined) fullscreen = false;
    };

//    olOverlayWindow.isOpen = function() {
//        return _open;
//    }

    olOverlayWindow.open = function(title, subtitle, suppressAnimation, onlyBackground)
    {
        if (suppressAnimation === undefined) suppressAnimation = false;
        if (onlyBackground === undefined) onlyBackground = false;

        olMobileApp.hideBottomNav();
        olMobileApp.hideHeader();

        $("#overlayWindow").removeClass('shrink');
        $("#overlayWindow").hide();
        _readOptions();
        $(".ol-overlay-window").css("height", "auto");
        if(typeof title === typeof undefined) title = false;
        if(typeof subtitle === typeof undefined) subtitle = false;

        if(title !== false) $(".ol-overlay-window-header-title").text(title);
        else $(".ol-overlay-window-header-title").text("");

        if(subtitle !== false) $(".ol-overlay-window-header-subtitle").text(subtitle);
        else $(".ol-overlay-window-header-subtitle").text("");

        var windowHeight = $(window).height();

        var previousCss  = $("#overlayWindow").attr("style");
        $("#overlayWindow").css({
            position:   'absolute',
            display:    'block'
        });

        var defaultWidth = (maxWidth !== false ? maxWidth : 800);
        $(".ol-overlay-window").css('width', Math.min($(window).width(), defaultWidth) + 'px');

        if(borders === false)
        {
            $('.ol-overlay-window-header').hide();
            $('.ol-overlay-window-content').css('padding', 0);
            $(".ol-overlay-window").css('border', 'none');
        }
        else
        {
            $('.ol-overlay-window-header').show();
            $('.ol-overlay-window-content').css('padding', 'auto');
            $(".ol-overlay-window").css('border', 'auto');
        }
        
        if(closeIcon === false)
        {
            $('.ol-overlay-window-header-close').hide();
        }
        else
        {
            $('.ol-overlay-window-header-close').show();
        }

        var overlayHeight = $(".ol-overlay-window").height();

        if(!onlyBackground)
            olGUI.lockScroll($('#overlayWindow'));

        $("#overlayWindow").attr("style", previousCss ? previousCss : "");

        if(fullscreen) $('#overlayWindow').addClass('ol-overlay-fullscreen');
        else $('#overlayWindow').removeClass('ol-overlay-fullscreen');

        if(background === 'black') $('#overlayWindow').addClass('ol-overlay-bg-black');
        else $('#overlayWindow').removeClass('ol-overlay-bg-black');

        if(windowHeight > overlayHeight)
        {
            var diff = (windowHeight - overlayHeight) * 0.5;
            $(".ol-overlay-window").css("top", diff);
            $("#overlayWindow").css("position", "fixed");
            $("#overlayWindow").css("overflow-y", "visible");
        }
        else
        {
            $(".ol-overlay-window").css("top", 0);
            $(".ol-overlay-window").css("overflow", "visible");
            $("#overlayWindow").css("overflow-y", "scroll");
            $("#overlayWindow").css("position", "fixed");
        }

        if(_open)
            $("#overlayWindow").show();
        else
            $("#overlayWindow").show();
        _open = true;
        //$(".ol-overlay-window").draggable({ handle: ".ol-overlay-window-header" });

        executeResizeListeners();
        $('.ol-mobile-bottom-nav-section').fadeOut();

        if (!suppressAnimation)
        {
            setTimeout(function(){ //ads class for the overlay animation
                $("#overlayWindow").addClass('open');
                $('#overlayWindow').scrollTop(0);
            }, 0);
        }
    };

    olOverlayWindow.isOpen = function()
    {
        return $('#overlayWindow').hasClass('open');
    };


    olOverlayWindow.resize = function()
    {
        olOverlayWindow.reposition();
        executeResizeListeners();
    };

    // Sets vertical position and overflow of the overlay window.
    // (Original resize action, without executing resize listeners)
    olOverlayWindow.reposition = function()
    {
        $(".ol-overlay-window").css("height", "auto");
        var windowHeight = $(window).height();
        var overlayHeight = $(".ol-overlay-window").height();

        var defaultWidth = (maxWidth !== false ? maxWidth : 800);
        $(".ol-overlay-window").css('width', Math.min($(window).width(), defaultWidth) + 'px');

        if(windowHeight > overlayHeight)
        {
            var diff = (windowHeight - overlayHeight) * 0.5;
            $(".ol-overlay-window").css("top", diff);
            $("#overlayWindow").css("position", "fixed");
            $("#overlayWindow").css("overflow-y", "visible");
        }
        else
        {
            $(".ol-overlay-window").css("top", 0);
            $(".ol-overlay-window").css("overflow", "visible");
            $("#overlayWindow").css("overflow-y", "scroll");
            $("#overlayWindow").css("position", "fixed");
        }
    };

    $(window).resize(olOverlayWindow.resize);


    // Falls das Overlay offen ist, werden sich Inhalt und Titel gemerkt
    // und bei Schließen des folgenden Overlays wiederhergestellt.
    // `initialize` kann zur Reinitialisierung des Inhalt benutzt werden.
    // ACHTUNG: Das funktioniert nur einmal!!!!
    olOverlayWindow.restoreContentOnClose = function(initialize)
    {
        if (!olOverlayWindow.isOpen()) return;

        var html = $(".ol-overlay-window-content").html();
        var title = $(".ol-overlay-window-content #overlayTitle").text();
        var subtitle = $(".ol-overlay-window-content #overlaySubtitle").text();

        olOverlayWindow.closeDisabled = true; // nicht schließen...
        olOverlayWindow.addCloseListener(function() // ...und nachher wiederherstellen
        {
            olOverlayWindow.setContent(html);
            olOverlayWindow.open(title, subtitle, true);
            if (typeof initialize === 'function') initialize();
        });
    };


    var _resizeListeners = [];
    function executeResizeListeners()
    {
        for (var i = 0;  i < _resizeListeners.length;  i++)
        {
            var listener = _resizeListeners[i];
            if (typeof listener !== 'function') return;
            listener.call(olOverlayWindow);
        }
    };

    // Registers `listener` as a resize listener to be called after resize.
    // All resize listeners will automatically be removed on close.
    olOverlayWindow.addResizeListener = function(listener)
    {
        if (typeof listener !== 'function') return;
        _resizeListeners.push(listener);
    };

    // Removes the resize listener `listener` if present.
    olOverlayWindow.removeResizeListener = function(listener)
    {
        var i = _resizeListeners.indexOf(listener);
        if (i < 0) return;
        _resizeListeners.splice(i, 1);
    };

    // Removes all resize listeners
    olOverlayWindow.removeResizeListeners = function()
    {
        _resizeListeners = [];
    };


    var _closeListeners = [];
    function executeCloseListeners()
    {
        var callbacks = _closeListeners;
        _closeListeners = [];
        for (var i = 0;  i < callbacks.length;  i++)
        {
            var callback = callbacks[i];
            if (typeof callback !== 'function') return;
            callback.call(olOverlayWindow);
        }

        olOverlayWindow.removeResizeListeners();
    };

    // Registers `listener` to be called (once) after close.
    // Listeners will automatically be removed and close reenabled.
    olOverlayWindow.addCloseListener = function(callback)
    {
        if (typeof callback !== 'function') return;
        _closeListeners.push(callback);
    };

    // Removes all close listeners
    olOverlayWindow.removeCloseListeners = function()
    {
        _closeListeners = [];
    };


    // Closes the overlay unconditionally without executing close listeners.
    olOverlayWindow.forceClose = function()
    {
        olOverlayWindow.closeDisabled = false;
        olOverlayWindow.removeCloseListeners();
        olOverlayWindow.close();
    };


    olOverlayWindow.closeDisabled = false; // once!!!
    olOverlayWindow.close = function()
    {
        if (!olOverlayWindow.closeDisabled)
        {
            $("#overlayWindow").removeClass('open');
            _open = false;
            olGUI.unlockScroll()

            // the class shrink removes the overlay
            // remove the overlay with delay to let the closing animation finish
            // animation timings are set in _overlayWindows.scss
            setTimeout(function() {
                $("#overlayWindow").addClass('shrink');
            }, 400);

            $("#overlayWindow").show();

            olMobileApp.showBottomNav();
            olMobileApp.showHeader();
            $('.ol-mobile-bottom-nav-section').fadeIn();
        }
        olOverlayWindow.closeDisabled = false;
        executeCloseListeners(); // sets olOverlayWindow.closeDisable = false
    };


    olOverlayWindow.addLoadingAnimation = function()
    {
        addLoadingAnimationToElement($("#overlayWindow .ol-overlay-window"), {overlay: true});
    };

    olOverlayWindow.removeLoadingAnimation = function()
    {
        removeLoadingAnimationFromElement($("#overlayWindow .ol-overlay-window"), {overlay: true});
    };

}( window.olOverlayWindow = window.olOverlayWindow || {}, jQuery ));