"use strict";
(function(olSquad, $, undefined ) 
{    
    var playerSelectedFilter = false;
    var playerPositionFilter = 0;
    var playerContractDurationFilter = -1;
    
    function updatePlayerRows(callUpdate)
    {
        if(typeof callUpdate === typeof undefined) callUpdate = true;
        var countChecked = 0;
        $(".player-contracts-row").each(function(index, element)
        {
            var playerSelected = true; 
            
            checkPlayerSelected = $(this).find('input[type="checkbox"]').prop('checked');
            if(checkPlayerSelected)
                ++countChecked;
            if(playerSelectedFilter)
            { 
                playerSelected = $(this).find('input[type="checkbox"]').prop('checked');
            }
            
            playerPosition = true;
            if(playerPositionFilter != 0)
            {
                var playerPos = parseInt($(this).attr("data-player-positions"));
                playerPosition = ((playerPositionFilter & playerPos) === playerPositionFilter);
            }
            
            var playerContractDuration = true;
            if(playerContractDurationFilter != -1)
            {
                var attr = $(this).attr("data-player-contract-duration");
                if(typeof attr !== typeof undefined && attr !== false)
                {
                    var duration = parseInt(attr);
                    playerContractDuration = playerContractDurationFilter == duration;
                }
            }
            
            if(playerSelected && playerPosition && playerContractDuration)
                $(this).addClass('visible').show();
            else
                $(this).removeClass('visible').hide();
        }); 
        
        $(".ol-squad-category").each(function(index, element)
        {
            var count = $(this).find('.player-contracts-row.visible').length;
            if(count > 0) $(this).show();
            else $(this).hide();
        });
        
        if(countChecked > 0)
        {
            $('#playerFilterCheckbox').show();
        }
        else
        {
            $('#playerFilterCheckbox').hide();
        }
        
        if($(".player-contracts-row.visible").length == 0)
        {
            playerSelectedFilter = false;
            $('.ol-player-row input[type="checkbox"]').prop('checked', false);
            if($('#checkboxFilterPlayer').prop('checked'))
            {
                if(callUpdate)
                    $('#checkboxFilterPlayer').click();
            }
            else if(callUpdate) updatePlayerRows(false);
        }
        
    }
    
    olSquad.filterByPlayerContractDuration = function(element)
    {
        playerContractDurationFilter = parseInt($(element).parent().attr("data-value"));
        updatePlayerRows();
    };

    olSquad.filterByPlayerContractDurationRelaunch = function(value)
    {
        playerContractDurationFilter = parseInt(value);
        updatePlayerRows();
    };
    
    olSquad.filterByPlayerPosition = function(element)
    {
        playerPositionFilter = parseInt($(element).parent().attr("data-value"));
        updatePlayerRows();
    };
    
    olSquad.onClickPlayerVisibilityCheckbox = function()
    {
        updatePlayerRows();
    };
    
    olSquad.onChangeFilterPlayer = function(checked)
    {
        playerSelectedFilter = checked;
        updatePlayerRows();
    };
    
    olSquad.resetFilter = function()
    {
        playerSelectedFilter = false;
        playerPositionFilter = 0;
        playerContractDurationFilter = -1;
    };
    
    olSquad.tabTo = function(sub) {
        var $area = $('#myTeamSquad'),
            $anchor = $area,
            data = {userId: $anchor.attr('data-uid')},
            suburl = '/team/squad/'+sub;

        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get(suburl, data, function(html) {
            addUrlParams('?subPage='+suburl)
            $area.html(html);
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** tabTo failed");
        });
    };

}( window.olSquad = window.olSquad || {}, jQuery ));