"use strict";
(function(olTeamSimpleBadgeSelection, $, undefined) 
{  
    olTeamSimpleBadgeSelection.uploadCheckBadge = function() 
    {
        var self = this;
        olMsgBox.questionById(
                'logoUploadCheck',
                function() {
                    olTeamSimpleBadgeSelection.uploadSelectedBadge();
                },
                function() {
                }
        );
    }
    
    olTeamSimpleBadgeSelection.uploadSelectedBadge = function() {
        var badgeId = $(".register-form-badge-big").attr('data-value');
        $.ajax({
            type: "POST",
            url: '/team/teamLogoChange',
            dataType: 'html',
            data: {
                //for authentification
                badgeId: badgeId,
                badgeType: 'SIMPLE',
                //_token: "{{ csrf_token() }}",
            },
            'success': function(data) 
            {
                var response = jQuery.parseJSON(data);
                if(response.code === 200)
                {
                    //change small badge    
                    selectorClass = ".icon-badge_"+response['oldBadgeId']+"_min";
                    $(selectorClass).addClass("icon-badge_"+response['badgeId']+"_min");
                    $(selectorClass).removeClass("icon-badge_"+response['oldBadgeId']+"_min");                   
                    //change big badge
                    selectorClass = ".icon-badge_"+response['oldBadgeId']+"_max";
                    $(selectorClass).addClass("icon-badge_"+response['badgeId']+"_max");
                    $(selectorClass).removeClass("icon-badge_"+response['oldBadgeId']+"_max");
                    
                    olMsgBox.msgById("logoUploadSuccess", function() { location.reload(true); });
                    olOverlayWindow.close();
                }
                else
                {
                    olMsgBox.msgById("logoUploadFailed");
                }
            },
            'error': function(data) {
                
                olMsgBox.msg(Lang.trans('js/team.error'), Lang.trans('js/team.errorBadgeNotSaved'));
            }     
        });
    }    
}( window.olTeamSimpleBadgeSelection = window.olTeamSimpleBadgeSelection || {}, jQuery ));