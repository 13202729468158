(function(olAnalytics, $, undefined) {
    olAnalytics.pageview = function(pageTitle, pagePath) {
        if (typeof pageTitle !== typeof "")
            return;
        if (typeof pagePath !== typeof "")
            return;
        var idx = pagePath.indexOf('/');
        if (idx > 0) pagePath = '/' + pagePath;

        gtag('config', olAnalyticsData.property_id, {
//            'page_title': pageTitle,
            'page_path': pagePath,
            'cutom_map': olAnalyticsData.custom_map,
            'allow_ad_personalization_signals': olAnalyticsData.allow_ad_signals
        });
    };

    olAnalytics.event = function(action, category, label, add_data, non_interaction) {
        if (typeof action !== typeof "")
            return false;
        if (typeof category !== typeof "")
            return false;
        if (typeof label !== typeof "")
            return false;

        var data = {
            'event_category': category,
            'event_label': label
        };
        if ($.isPlainObject(add_data))
            $.extend(data, add_data);

        if (typeof non_interaction === typeof true)
            data.non_interaction = non_interaction;

        gtag('event', action, data);
        return true;
    };
}(window.olAnalytics = window.olAnalytics || {}, jQuery));

