(function(olFinance, $, undefined )
{
    olFinance.seasonOverview = function(season){
        //check if account is selected
        if(season === false)
            season = $('#dropdown-matchday-table-season-matchdayTable').dropdown().value();
        element = '#dropdown-matchday-table-season-matchdayTable2';
        if ( $(element).css('display') == 'none' || $(element).css("visibility") == "hidden"){
            //olAnchorNavigation.loadSubPage('office/financeOverview', '#financeContent', { season: season });
            olFinance.seasonOverviewCurrent(season); //CK ToCheck
            //$("#dropdown-matchday-table-season-matchdayTable2").hide();
        } else{
            // show acoount value selected season
            olFinance.statusAccount(season);
        }
    };

    olFinance.seasonOverviewCurrent = function(season){
        if(season === false)
            season = $('#dropdown-matchday-table-season-matchdayTable').dropdown().value();
        // olAnchorNavigation.loadSubPage('office/financeOverview', '#financeContent', { season: season });  
 
        var $anchor = $('#financeContent');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get('/office/financeOverview', {season: season}, function(html) {
            $('#financeContent').html(html);
            $('.ol-button-toggle').removeClass('active');
            $('.ol-button-toggle[data-sub-url="/office/financeOverview"]').addClass('active');
            addUrlParams('?subPage=office/financeOverview');
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** statusAccount failed");
        });
    };

    olFinance.statusAccount = function(season){
        if(season === false)
            season = $('#dropdown-matchday-table-season-matchdayTable').dropdown().value();
        //olAnchorNavigation.loadSubPage('office/statusAccount', '#financeContent', { season: season });
        $( "#dropdown-matchday-table-season-matchdayTable2" ).show();
        $( '.accountValues').html(Lang.trans('js/office.allRevenues'));

        var $anchor = $('#financeContent');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get('/office/statusAccount', {season: season}, function(html) {
            $('#financeContent').html(html);
            addUrlParams('?subPage=/office/statusAccount');
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** statusAccount failed");
        });
    };

    function _initStatusAccount(season)
    {
        $load = $('#userAccountValues');
        if (!$load.hasClass('loading') || !$load.hasClass('end'))
        {
            if($(window).scrollTop() + $(window).height() >= (getDocHeight() - $('.ol-footer').height()))
            {
                //call the script for next values
                olFinance.insertStatusAccount(season);
            }
        }
    }

    var _statusAccountBind = false;

    olFinance.initStatusAccount = function(season)
    {
        if(_statusAccountBind !== false)
            $(window).unbind('scroll', _statusAccountBind);
        _statusAccountBind = function() { _initStatusAccount(season); };
        $(window).on('scroll', _statusAccountBind);
        onUnloadPage(function() { $(window).unbind('scroll', _statusAccountBind); });
    };

    olFinance.transferMarket = function(){
         // olAnchorNavigation.loadSubPage('office/getbidsoverview', '#financeContent');
         $( "#dropdown-matchday-table-season-matchdayTable2" ).hide();
 
         var $anchor = $('#financeContent');
         addLoadingAnimationToElement($anchor, {size: 'small'});
         $.get('/office/getbidsoverview', function(html) {
             $('#financeContent').html(html);
             addUrlParams('?subPage=/office/getbidsoverview');
             removeLoadingAnimationFromElement($anchor);
         }).fail(function() {
             removeLoadingAnimationFromElement($anchor);
             console.log("*** statusAccount failed");
        });
    }

    olFinance.financeNotification = function(){
        // olAnchorNavigation.loadSubPage('office/financeNotification', '#financeContent');
        $( "#dropdown-matchday-table-season-matchdayTable2" ).hide();
 
        var $anchor = $('#financeContent');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get('/office/financeNotification', function(html) {
            $('#financeContent').html(html);
            addUrlParams('?subPage=/office/financeNotification');
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** statusAccount failed");
        });
    }

    olFinance.financeMortgage = function(){
        // olAnchorNavigation.loadSubPage('office/financeMortgage', $('.financeContent'));
        $( "#dropdown-matchday-table-season-matchdayTable2" ).hide();
 
        var $anchor = $('#financeContent');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get('/office/financeMortgage', function(html) {
            $('#financeContent').html(html);
            addUrlParams('?subPage=/office/financeMortgage');
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** statusAccount failed");
        });
    }

    olFinance.appendFinanceContent = function(result){
        if(result != '') // no data -> no work
        {
            $("#userAccountValues").append(result);
            olTimestamp = olTimestamp - 28;
            //set next olTimestamp for next startweek fetching new data
            $('#accountSumValue').attr('olTimestamp',olTimestamp);
            $('#accountOverviewFooterMatchdayDesc').html($('.ol-account-overview-matchday-desc').last().text());
            $('#accountOverviewFooterWeekDesc').html($('.ol-account-overview-week-desc').last().text());
            $('#dayBefore').html($('.ol-account-overview-date-desc').last().text());
            // weekValue
            sum ='#sum'+olTimestamp;
            weekValue = $(sum).attr('value');
            // accountValue
            accountValue = $('#accountValue').attr('value');
            // new accountValue
            newAccountValue = parseFloat(accountValue)-parseFloat(weekValue);
            $('#accountValue').attr('value',newAccountValue);
            $('#accountValue').html(olGUI.numberWithPoints(newAccountValue));
            //$('#dayBefore').html( $(sum).attr('dayBefore')); // fetch dayBefore and show it
            $('#userAccountValues').removeClass('loading'); // work ready, loading workflow ended -> time for next loading
        }
    }
    var olTimestamp = 0;
    olFinance.insertStatusAccount = function(season)
    {
        olTimestamp = $('#accountSumValue').attr('olTimestamp'); // start to fetch new data
        $load = $('#userAccountValues');
        if (!$load.hasClass('loading')){  // if no data is loading -> do it
        $load.addClass('loading');
        $.ajax({
            type: "POST",
            url: '/office/loadNextAccountData',
            dataType: 'html',
            data: {
                season: season,
                olTimestamp: olTimestamp
                },
            success: olFinance.appendFinanceContent,
            error: function(request,status,errorThrown) {
                //
            }
        });
        }

    }

    // account show all values
    olFinance.showAllValues = function(){
        $('.negative-value').show();
        $('.finance-row:not(.negative-value)').show();
    }

    // account show positive values, hide negative values
    olFinance.showPositiveValue = function(){
        $('.negative-value').hide();
        $('.finance-row:not(.negative-value)').show();
    }

    // account show negative values, hide positive values
    olFinance.showNegativeValue = function(){
        $('.negative-value').show();
        $('.finance-row:not(.negative-value)').hide();
    }


    olFinance.showFirstStartInfo = function() {
        var $var = $('#finance-showStartInfo');
        var show = (Number($var.val()) == 1);
        if (!show) return;

        olOverlayWindow.addCloseListener(function() {
            // set info shown setting
            var data = {name: 'finance-firstStart-infoShown', value: 1};
            $.post('/user/settings/change', data, function(rc) {$var.val(1);});
        });
        olOverlayWindow.load('/office/finance/info', undefined, function(rc) {
            $('#financeInfoOverlay .finance-info-visual-image').addClass('show');
        });
    }


    olFinance.creditPayBack = function(mortgageId, value) {
        var payBackValue = olGUI.stripNumericString($('#inputPayBackMortgage' + mortgageId).val());
        var data = {payBackValue: payBackValue, mortgageId : mortgageId};
        $.post('/office/finance/creditPayBack', data, function(res) {
            if (res.check === 'success') {
                olFinance.creditPayedBackTrue(value);
            } else {
                olMsgBox.json(res);
            }
        }).error(olFinance.creditPayedBackFalse);
    }

    olFinance.creditPayBackMessageBox = function(mortgageId, textheader, text) {
        var payBackValue = $('#inputPayBackMortgage' + mortgageId).val();
        payBackValue = olGUI.stripNumericString(payBackValue);
        var paidMortgageValue = olGUI.stripNumericString($('#paidMortgageValue').val());

        if (payBackValue <= 0) {
            olMsgBox.msgById('payBackMortgageMinValue', undefined, {'_value': olGUI.formatCurrency2(0, 0)});
            return;
        }

        // compare the values
        if (payBackValue <= paidMortgageValue) {
            olMsgBox.questionById('payBackMortgageQuestion', function() {
                olFinance.creditPayBack(mortgageId, olGUI.formatCurrency(payBackValue)); //TODO formatCurrency2?
            }, undefined, {'_value': olGUI.formatCurrency2(payBackValue)});
        }
    };

    olFinance.creditPayedBackTrue = function(value) {
        olFinance.financeMortgage();
        olGUI.updateLiquidFunds();
        olMsgBox.msgById("payBackMortgageSuccess", undefined, {'_value': olGUI.addCurrencySymbol(value)});
    }

    olFinance.creditPayedBackFalse = function() {
        olMsgBox.msgById("payBackMortgageFailed");
    }

    olFinance.onCollapseSalaryDetails = function(season, week, element, targetId) {
        var data = { 'week': week, 'season': season };

        var target = $('#' + targetId);
        var $parent = $(element);
        // Auf-/Zuklappen
        var $row = target;
        var $icon = $parent.children().first();
        $parent.toggleClass('active-overview');
        var wasCollapsed = $parent.hasClass('active-overview');
        var dataLoaded = $icon.hasClass('dataLoaded');

        if (!wasCollapsed)
        {
            $row.toggle("slow");
            $icon.removeClass('active-overview');
            return; // nur wenn aufgeklappt wird
        }
        var $anchor = $parent;
        if(!dataLoaded)
        {
            addLoadingAnimationToElement($anchor, {'size': 'small'});
            $.get('/office/user/salary', data, function(rc) {
                // Inhtalt anzeigen
                $row.html(rc);

                $icon.addClass('active-overview');
                $icon.addClass('dataLoaded');

                initializePopovers('.ol-player-detail-results-content .ol-player-out');
                removeLoadingAnimationFromElement($anchor);
            }).fail(function() {
                removeLoadingAnimationFromElement($anchor);
                console.log("*** salaryDetails failed:", data);
            });
        }
        else
        {
            $row.toggle("slow");
            $icon.addClass('active-overview');
        }
    };

    olFinance.responsiveAdvertisement = function() {
        // when opening office/finance get the width of the ad and set width of the content
        // so that the content can be responsive
        if ($('body[data-page-url="office/finance"]')) {
            if ($("#financeAdWrapper").size() > 0) {
                var adWidth = $("#financeAdWrapper").width();
                var financeContentWidth = "calc(100% - " + adWidth + "px)";
                $("#financeContent").css('width', financeContentWidth);
            } else {
                $("#financeContent").css('width', "");
            }
        }
    }

}( window.olFinance = window.olFinance || {}, jQuery ));