(function(olSponsorLeague, $, undefined) {
    
    olSponsorLeague.onClick = function(sponsorURL, sponsorURLMobile, name, element) 
    {
        // estimate if responsive or desktop banner has been clicked
        var wasMobileBanner = $(element).parent().hasClass('ol-league-sponsored-by') && 
            ($(element).parent().parent().hasClass('ol-league-sponsoring-only-xs')
            || olGUI.getBootstrapDeviceSize() === 'ol-xs');
       
        var action = 'clicked';
        var category = 'sponsorings-custom';
        var label = 'sponsoring-league-' + name + '-clicked';

        var data = {
            season: olTimeHelper.getCurrentSeason(),
            week: olTimeHelper.getCurrentWeek(),
            matchday: olTimeHelper.getCurrentMatchday()
        };
        
        var url = wasMobileBanner ?  sponsorURLMobile : sponsorURL;

        olAnalytics.event(action, category, label, data);
        
        if (APP_MODE) {
            olMobileApp.openExternal(url);
            event.preventDefault();
            return false;
        } else {
            window.open(url, '_blank');
            event.preventDefault();
        }
        event.stopPropagation();
    };

}(window.olSponsorLeague = window.olSponsorLeague || {}, jQuery));
