(function(olSponsorInfo, $, undefined) {
    olSponsorInfo.onSponsorRegisterBtnClick = function() {
        closeSubnav();

        if (!olGUI.isLoggedIn() || !$('body').hasClass('ol-sponsor')) {
            olOverlayWindow.load('/sponsor/registrationform')

            olAnalytics.event('clicked', 'sponsor', 'sponsor-open-registration');
            if (olConfig.env === 'production') {
                fbq('track', 'SponsorOpenRegistration');
                //if (typeof _tfa !== 'undefined') _tfa.push({notify: 'event', name: 'sponsor_open_registration', id: 1270864});
            }
        } else {
            olAnchorNavigation.load('sponsor/sponsoring/mainsponsor')
        }
    };

    olSponsorInfo.onSponsorConsultingBtnClick = function() {
        olAnalytics.event('clicked', 'sponsor', 'sponsor-open-consulting');
        if (olConfig.env === 'production') {
            fbq('track', 'SponsorOpenConsulting');
            //if (typeof _tfa !== 'undefined') _tfa.push({notify: 'event', name: 'sponsor_open_consulting', id: 1270864});
        }
    };

    olSponsorInfo.mainSponsoring = function() {
        var $anchor = $('#sponsorContainer');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get('/sponsor/overview', function(html) {
            $anchor.html(html);
            addUrlParams('?subPage=/mainSponsor');
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** mainSponsoring failed");
        });
    }

    olSponsorInfo.stadiumMarketing = function() {
        var $anchor = $('#sponsorContainer');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get('/sponsor/stadium', function(html) {
            $anchor.html(html);
            addUrlParams('?subPage=/stadiumMarketing');
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** stadiumMarketing failed");
        });
    }

    olSponsorInfo.leagueMarketing = function() {
        var $anchor = $('#sponsorContainer');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get('/sponsor/league', function(html) {
            $anchor.html(html);
            addUrlParams('?subPage=/leagueRevenue');
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** leagueMarketing  failed");
        });
    }

    
}(window.olSponsorInfo = window.olSponsorInfo || {}, jQuery));
