(function() {
    // Android / Chrome

    var deferredPrompt;

    window.addEventListener('beforeinstallprompt', function(e) {
        var lastDismiss = parseInt(localStorage.getItem('ol-pwa-dialogue-dismissed'));
        if (lastDismiss && lastDismiss + 3600 * 1000 > (new Date()).getTime())
            return; // Wait 60 minutes between prompts
        deferredPrompt = e;
        //$(".pwa-install-prompt").addClass('show-prompt');
    });

    //function pwaInstall() {
    //    $(".pwa-install-prompt").removeClass('show-prompt');
    //    deferredPrompt.prompt();
    //}
    //
    //function pwaDismiss() {
    //    $(".pwa-install-prompt").removeClass('show-prompt');
    //    localStorage.setItem('ol-pwa-dialogue-dismissed', new Date().getTime())
    //}
    //
    //$(document).on('click', '.pwa-install-prompt button:first-child', pwaInstall);
    //$(document).on('click', '.pwa-install-prompt button:last-child', pwaDismiss);


    // iOS / Safari
    $(function() {
        if (olUid === undefined) return;

        var lastDismiss = parseInt(localStorage.getItem('ol-ios-dialogue-dismissed'));
        if (lastDismiss && lastDismiss + (24 * 3600 * 1000) > (new Date()).getTime())
            return; // Wait 1 day between prompts

        localStorage.setItem('ol-ios-dialogue-dismissed', new Date().getTime())

        if (!olAppInfo.APP_MODE && olGUI.isIOS()) {
            if (("standalone" in window.navigator) && !window.navigator.standalone) {
                olOverlayWindow.load('/misc/iosoverlay');
            }
        }
    });

    function iosDismissLong (ev) {
        localStorage.setItem('ol-ios-dialogue-dismissed', new Date().getTime() + 1000 * 3600 * 24 * 365) // 1 year
        olOverlayWindow.close();
    } 

    $(document).on('click', '.ios-install-overlay-no', iosDismissLong)

})();