window.olNavigationModules = window.olNavigationModules || {};

// constructor navigation module: league
window.olNavigationModules.league = (function(olNavLeague, $, packets, subcall, undefined) {
    var _league = {min: 1, max: 6};
    var _subcallid = 0;

    // navigation, dropdowns, inputs, etc.
    var $nav, $league;

    olNavLeague.init = function(config) {
        $nav = $('#navLeague');
        $league = $('#slider-navLeague .ol-double-slider', $nav);

        if (typeof config.league !== typeof undefined && $.isPlainObject(config.league)) {
            if (typeof config.league.max === typeof 0 && config.league.max > 0) {
                _league.max = config.league.max;
                if (typeof config.league.min === typeof 0 && config.league.min > 0) {
                    _league.min = config.league.min;
                } else {
                    _league.min = config.league.max;
                }
            }
        }
    };

    packets.league = function() {
        var data = {};

        if (_league.min > 0 && _league.max > 0)
            data.league = _league;

        return data;
    };

    olNavLeague.reset = function() {
        _league.min = 1;
        _league.max = 6;
        _subcallid = 0;

        $league.bootstrapSlider('setValue', [1, 6], true, false);
    };

    olNavLeague.onChangeLeague = function(min, max) {
        if (typeof min === typeof "")
            min = parseInt(min);
        if (typeof max === typeof "")
            max = parseInt(max);

        if (typeof min !== typeof 0 || min <= 0)
            return;
        if (typeof max !== typeof 0 || max <= 0)
            return;

        _league.min = min;
        _league.max = max;

        setTimeout(function(callid) {
            if (_subcallid === callid) {
                _subcallid = 0;
                subcall();
            }
        }, 500, ++_subcallid);
    };
});
