(function(olEcho, $) {

    var echo;
    olEcho.enabled = false;
    
    function init(cfg) {
        if (typeof(io) == "undefined" || (typeof WEBSOCKETS_DISABLED !== typeof undefined && WEBSOCKETS_DISABLED)) {
            console.log("Websockets Disabled.")
            return;
        }
        
        olEcho.enabled = true;
        
        config = cfg;
        echo = new Echo({
            broadcaster: 'socket.io',
            host: window.location.hostname
        });
        
        echo.channel('{{domain}}:ol-global').listen('debug', function(ev) {
           console.log(ev); 
        });

        olEcho.echo = echo;
    };
    
    olEcho.userChannel = function() {
        if (!olEcho.enabled) return false;
        return echo.private('{{domain}}:ol-user.'+olUid);
    }
    
    olEcho.globalChannel = function() {
        if (!olEcho.enabled) return false;
        return echo.channel('{{domain}}:ol-global');
    }

    olEcho.channel = function(channel) {
        if (!olEcho.enabled) return false;

        console.log("ECHO: Registering event on channel: ", channel);
        return echo.channel(channel);
    }
    
    $(init);

}(window.olEcho = window.olEcho || {}, jQuery));
