(function(olLiveTickerReportListener, $, undefined) {

    var DEBUG = true; // DBG
    
    var goalAudio = new Audio('/sounds/match-effect-goal2.mp3');
    var popupAudio = new Audio('/sounds/mixkit-long-pop.wav');
    var matchData = null;

    /**
     * Init matchreport styles
     *
     * @param minute
     */
    olLiveTickerReportListener.init = function(minute) {
        var $trs = $('tr.matchresult-content');
        $trs.each(function(i, el) {
            var entryMinute = $(el).attr('minute');
            if (entryMinute <= minute) {
                $(el).fadeIn().addClass('ticker-entry-shown');
            }
        });
    };

    /**
     * Set scores values for current match day.
     *
     * @param data
     */
    olLiveTickerReportListener.setData = function(data) {
        if (DEBUG) console.log('LT Debug | olLiveTickerMatchtableScoreListener.setData:', data);
        matchData = data;
    };

    /**
     * Listener to minute change event.
     *
     * @param minute
     * @param usingVisualAndSoundEffects
     */
    olLiveTickerReportListener.minuteChangeEvent = function(minute, usingVisualAndSoundEffects) {
        if (DEBUG) console.log('LT Debug | olLiveTickerReportListener.minuteChangeEvent(', minute, ',', usingVisualAndSoundEffects, ')');
        
        var trsHidden = $('tr.matchresult-content:hidden');
        var matchScore = $('.matchScore');
        
        if(trsHidden.length > 0) {
            for(var i = trsHidden.length-1; i >= 0; i--) {
                if ($(trsHidden[i]).attr('minute') <= minute) {
                    $(trsHidden[i]).fadeIn().addClass('ticker-entry-shown');
        
                    if (usingVisualAndSoundEffects
                        && olLiveTickerTimer.getSettings('liveTickerGoalAlarm') === 1
                        && ($(trsHidden[i]).attr('resulttype') === 'goal'
                            || $(trsHidden[i]).attr('resulttype') === 'penaltyGoal')) {
                        goalAudio.play();
                    }
                    else if (usingVisualAndSoundEffects && olLiveTickerTimer.getSettings('liveTickerAudio') === 1 ) {
                        popupAudio.play();
                    }
      
                    if ($(trsHidden[i]).attr('matchscore') != '') {
                        matchScore.text($(trsHidden[i]).attr('matchscore'));
                        if ($(trsHidden[i]).attr('minute') >= 45) { // show halftime score after min 45
                            var intermediateScore = $('.intermediateScore');
                            // intermediateScore.text("("+$(trsHidden[i]).attr('matchscore')+")");
                            // Get halftime score from halftime event
                            intermediateScore.text("("+$('tr.matchresult-content[minute="45"]').attr('matchscore')+")");
                        }
                    }
                }
            }
        }
    };

    /**
     * Update score and rating tables while the page was reloaded.
     *
     * @param minute
     */
    olLiveTickerReportListener.updateViewUntilCurrentMinute = function(minute) {
        $(function() {
            olLiveTickerReportListener.init(minute);
        })
         
        if (DEBUG) console.log('LT Debug | olLiveTickerReportListener.updateViewUntilCurrentMinute('+minute+')');
         
        var isTablesExisted = setInterval(function() {
            if ($('tr.matchresult-content').length > 0) {
                clearInterval(isTablesExisted);
      
                var trsShown = $('tr.matchresult-content:visible');
                var j = 0;
                var lastMatchScore = '';
                var matchScore = $('.matchScore');
        
                // Show the last score in match.
                while (lastMatchScore.length === 0 && j < trsShown.length) {
                    lastMatchScore = $(trsShown[j]).attr('matchscore');
                    if (lastMatchScore !== '') {
                        matchScore.text(lastMatchScore);
                    }
                    j++;
                }
            }
        }, 1000);
    };

    olLiveTickerReportListener.loadLiveTable = function(selector, params) {
        $(selector).getLoad('/matchdaytable/leaguetable', {
            leagueId: params.leagueId,
            type: params.type, 
            nav: params.nav, 
            leagueMatchday: params.leagueMatchday, 
            season: params.season, 
            matchday: params.matchday, 
            leagueLevel: params.leagueLevel, 
            navId: params.navId,
            stateId: params.stateId,
        });
    };

    olLiveTickerReportListener.toggleLiveTickerTable = function() {
        $btn = $('.ol-liveticker-table-toggle-button');
        $ticker = $('#liveTickerTicker, #liveTickerTickerHead');
        $liveTable = $('#liveTable, #liveTableHead');

        $ticker.each(function(i, el) {
            $(el).toggleClass('col-md-7 col-md-12');
        });
        $liveTable.each(function(i, el) {
            $(el).toggleClass('col-md-5 col-md-0');
        });
        $btn.toggleClass('active');
    };

})(window.olLiveTickerReportListener = window.olLiveTickerReportListener || {}, jQuery);
