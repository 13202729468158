(function(olLiveTickerPopup, $, undefined) {

    //olLiveTickerPopup.showLiveTickerModeSelectionPopup = function() {
    //    olOverlayWindow.load('/liveticker/popupmodeselection', undefined, function() {});
    //};

    olLiveTickerPopup.showFinishLiveModePopup = function() {
        olOverlayWindow.load('/liveticker/popupfinish', undefined, function() {});
    };

    // Set currentMode (1 = Live Mode)
    olLiveTickerPopup.setLiveTickerCurrentMode = function(mode) {
        var data = {
            //name: 'liveTickerCurrentMode',
            value: mode? 1 : 0,
            _token: $('meta[name="csrf-token"]').attr('content')
        };
        $.post('/liveticker/setmode', data, function(response) {
            var url = (mode == 1)? '/matchdaytable/matchdaytable' : '/';
            window.location.replace(url);
        }).error(function(err) {
            console.log("*** setLiveTickerCurrentMode failed:", err);
        });
    };

    olLiveTickerPopup.finishLiveMode = function() {
        olLiveTickerTimer.finish();
    };

}(window.olLiveTickerPopup = window.olLiveTickerPopup || {}, jQuery));
