"use strict";
/**
 * Copyright Christian Kubitza
 * christian@ck3d.de
 * 2020
 */
(function(olLocation, $) {

    olLocation.console = SwitchableConsole("Location", true, false, '#ddeeff', '#555555');

    var updateLocationUrl = '/geo/updateLocation';
    var apiUrl = 'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyD5shxbSZVKnbvCO-4qo0ytdSutOs1jxrU';

    function setLocation(data) {
        // olLocation.console.log("setLocation()", data);
        $.ajax({
            url: updateLocationUrl,
            type: 'POST',
            dataType: 'json',
            data: data,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        }).done(function(data) {
            // olLocation.console.log('done', data);
            if (data && data.changed) {
                var hash = window.location.hash;
                if (!hash || hash.length <= 1) {
                    if (olGUI.isSponsorUser) {
                        hash = '#url=sponsor/settings/settings';
                    } else {
                        hash = '#url=landingpage';
                    }
                }
                // ask to reload only for specific pages
                if (
                    (hash.lastIndexOf('#url=landingpage', 0) === 0)
                    || (hash.lastIndexOf('#url=news', 0) === 0)
                ) {
                    olLocation.console.log("location updated, ask for reload");
                    olMsgBox.question(Lang.trans('js/misc.location'), Lang.trans('js/misc.msgLocationChanged'), function() {
                        window.location.reload();
                    }, function() { }, Lang.trans('js/misc.yes'), Lang.trans('js/misc.no'));
                } else {
                    olLocation.console.log("location updated but no reload required");
                }
            }
        }).fail(function(jqXHR, textStatus, errorThrown) {
            olLocation.console.log('set location failed', textStatus);
        });
    };

    function getLocationFromBrowser() {
        if ("geolocation" in navigator) {
            // var watchPositionId = navigator.geolocation.watchPosition(function(position) {
            navigator.geolocation.getCurrentPosition(function(position) {
                // success
                setLocation({
                    type: 'browser',
                    time: position.timestamp,
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    acc: position.coords.accuracy
                });
            }, function() {
                // access to location not granted...
                olLocation.console.log('geolocation access not granted, try with api...');
                getLocationFromAPI();
            }, {
                enableHighAccuracy: false,
                // maximumAge        : 30000,
                // timeout           : 5000
            });
        } else {
            olLocation.console.log('geolocation access not available, try with api...');
            getLocationFromAPI();
        }
    };

    function getLocationFromAPI() {
        // from ip with google
        $.ajax({
            url: apiUrl,
            data: JSON.stringify({
                "considerIp": "true",
            }),
            dataType: "json",
            method: "POST",
            contentType: 'application/json',
            processData: false,
            beforeSend: function() {}, // don't send Laravels X-Socket-Id Header, as Google doesn't like it
        }).done(function( data ) {
            // setLocation({geolocation:data});
            setLocation({
                type: 'api',
                time: Date.now(),
                lat: data.location.lat,
                lng: data.location.lng,
                acc: data.accuracy
            });
        }).fail(function( jqXHR, textStatus, errorThrown ) {
            olLocation.console.warn("no api geo info available");
            // olLocation.console.log(textStatus, errorThrown);
        });
    };

    function init() {
        if (window.olLocationReliability === undefined) return;
        if (window.olLocationReliability < 2) {
            olLocation.console.log('reliability bad or improvable, get location from browser...');
            getLocationFromBrowser();
        }
    };

    $(init);

}(window.olLocation = window.olLocation || {}, jQuery));
