(function(olSponsorLogoEditor, $, undefined) {

    // Creates a new SponsorLogoEditor with `options` and returns the instance
    olSponsorLogoEditor.create = function(targetSelector, options) {
        return new SponsorLogoEditor(targetSelector, options);
    };

    function pctToZoom(pct) {
        pct -= 0.5;
        pct *= 2;

        pct = 1 - pct;

        pct *= 0.9;
        pct += 0.1;

        return pct;
    }

    function zoomToPct(z) {
        z -= 0.1;
        z /= 0.9;
        z = 1 - z;
        z /= 2;
        z += 0.5
        return z;
    }

    function SponsorLogoEditor(targetSelector, options) {
        if (options === undefined) options = {};
        $.extend(this.options, options);
//console.log('SponsorLogoEditor: options:',this.options);
        var iw = this.options.imageWidth, ih = this.options.imageHeight,
            fw = this.options.frameWidth, fh = this.options.frameHeight;
        var imageEditorOptions = {
            canvasWidth: iw + 2*fw,
            canvasHeight: ih + 2*fh,
            canvas2Width: iw + 2*fw - 50, //?
            canvas2Height: ih + 2*fh - 50, //?
            frameWidth: fw,
            frameHeight: fh
        };

        //this.inputElement = undefined;

        // Find dialog/overlay
        this.$targetElement = $(targetSelector);

        // Find Editor and Previews
        this.editor = this.$targetElement.find('.editor')[0];
        this.previews = this.$targetElement.find('.preview').get();

        // Set up ImageEditor
        var imageEditor = olImageEditor.create(imageEditorOptions);
        imageEditor.addEditor(this.editor);
        imageEditor.setPreviewBorder(imageEditor.getEditorPreview(this.editor), 5);
        $(this.previews).each(function(i, preview) {
            imageEditor.addPreview(preview);
            imageEditor.setPreviewBorder(preview, 5);
        });
        this.imageEditor = imageEditor;

        // Initialize zoom bar
        var $slider = this.$targetElement.find('#slider');
        $slider.bootstrapSlider({tooltip: 'hide'});

        function updateImage(event) {
            var dataset = event.target.dataset,
                value = parseInt(event.value, 10),
                min = parseInt(dataset.sliderMin, 10),
                max = parseInt(dataset.sliderMax, 10),
                relValue = value - min,
                range = max - min,
                pctValue = relValue / range;
            var z = pctToZoom(pctValue);
//console.log('slider: updateImage: pctValue='+pctValue+', z='+z);
            imageEditor.zoomCentered(z);
        }

        $slider.bootstrapSlider().on('slide', updateImage.bind(this));
        $slider.bootstrapSlider().on('slideStop', updateImage.bind(this));
        $slider.trigger('change');
        this.$slider = $slider;

        this.sliderMinValue = parseInt($slider.attr('data-slider-min'), 10);
        this.sliderMaxValue = parseInt($slider.attr('data-slider-max'), 10);
        this.sliderRange = this.sliderMaxValue - this.sliderMinValue;
        this.sliderGetValue = function() { return $slider.bootstrapSlider('getValue'); };
        this.sliderSetValue = function(value) { return $slider.bootstrapSlider('setValue', value); };
        this.sliderSetPctValue = function(z) {
//console.log('sliderSetPctValue('+z+')');
            var value = this.sliderMinValue + zoomToPct(z)*this.sliderRange;
//console.log('slider.setValue('+value+')');
            return $slider.bootstrapSlider('setValue', value);
        };


        // Assign event handlers to buttons
        this.$yesBtn = this.$targetElement.find('.logo-editor-button-ok');
        this.$yesBtn.on('click', function(event) {
            // Ask for confirmation, then do upload
            var $button = $(event.target);
            $button.addClass('ol-button-loading');
            olDialog.message('#msgLogoUploadCheck', null, this._upload.bind(this));
        }.bind(this));

        this.$noBtn = this.$targetElement.find('.logo-editor-button-cancel');
        this.$noBtn.on('click', this._hideEditor.bind(this));

        this.$resetBtn = this.$targetElement.find('.logo-editor-button-reset');
        this.$resetBtn.on('click', this.resetImageZoomAndPosition.bind(this));

        this.$selectBtn = this.$targetElement.find('.logo-editor-button-select');
        this.$selectBtn.on('click', this.show.bind(this));

        // Initialize
        this._hideEditor();
    }

    SponsorLogoEditor.prototype = {
        fileReader: false,
        fileDialogIsClosed: false,
        fileDialogClosedHandle:  false,

        inputElement: null,
        $targetElement: null, // dialog/overlay element
        editor: null, // editor element
        previews: null,// previews element
        $slider: null, // Slider element

        imageEditor: null, // ImageEditor instance
        imageId: 0,

        options: {
            imageWidth: 150,
            imageHeight: 150,
            frameWidth: 100,
            frameHeight: 100
        }
    };
    SponsorLogoEditor.prototype.constructor = SponsorLogoEditor;

    SponsorLogoEditor.prototype.resetImageZoomAndPosition = function() {
//console.log('> resetImageZoomAndPosition');
        this.imageEditor.resetTransform();
        this.$slider.bootstrapSlider('setValue', 0);
        var f = this.imageEditor.centerImage();
        f = 1 - zoomToPct(f);
        console.log(f);
        this.sliderSetPctValue(pctToZoom(f));
//console.log('< resetImageZoomAndPosition');
    };

    // Set already stored image `imageData` or none if null
	SponsorLogoEditor.prototype.setImage = function(imageData) {
        if (imageData) {
            this.imageEditor.assignImage(imageData.url, imageData.zoom, imageData.position_x, imageData.position_y);
            this.sliderSetPctValue(imageData.zoom);
            this.imageId = imageData.id;
        } else {
            this.imageId = 0;
            this.imageEditor.clearImage();
            this.selectFile();
        }
	};

    // Starting point:
    // Ask for confirmation and then let user select file (or close overlay otherwise)
    SponsorLogoEditor.prototype.show = function(closeOverlayOnCancel) {
        if (closeOverlayOnCancel === undefined) closeOverlayOnCancel = true;
        //// Confirm and then select file
        //olDialog.confirm('#msgLogoUploadHint', null,
        //    this.selectFile.bind(this),
        //    function() {
        //        if (closeOverlayOnCancel) olOverlayWindow.close();
        //    });
        //
        this.selectFile();
    };

    // Show file selector and then load image into editor
    SponsorLogoEditor.prototype.selectFile = function() {
        this.fileDialogIsClosed = false;

        var inputElement = document.createElement('input');
        inputElement.type = 'file';
//        inputElement.accept = 'image/*';
        inputElement.accept = 'image/png,image/jpeg';
        inputElement.setAttribute('id', 'fileInput');
        inputElement.style.display = 'none';
        inputElement.onchange = this._onFileSelected.bind(this);
        this.inputElement = inputElement;
        inputElement.click();

        document.body.onfocus = function() {
            document.body.onfocus = null;
            setTimeout(function() {
                if (!this.fileDialogIsClosed) {
                    //olOverlayWindow.close();
                }
            }.bind(this), 300);
        }.bind(this);
    }

    // Checks the selected file, loads it and then displays image in editor
    SponsorLogoEditor.prototype._onFileSelected = function(event) {
        var file = event.target.files[0];
        this.fileDialogIsClosed = true;

        fileReader = new FileReader();
        fileReader.onload = function() {
            var result = fileReader.result;
            // Verify file and then display the image
            this._verifyFile(result, function(ok) {
                if (ok) {
                    // Load image file
                    this.imageId = 0;
                    this._hideEditor();
                    this.imageEditor.load(result, function() {
                        this.resetImageZoomAndPosition();
                        this._showEditor();
                    }.bind(this));
                } else {
                    console.log("File verification failed:", result);
                    this._showInvalidFileDialog(); //TODO added
                }
            }.bind(this));
        }.bind(this);
        fileReader.readAsDataURL(file);
    };

    // Checks if the image `file` is within the given width/height and file size limits.
    // Calls `callback` with true or false according to check result.
    SponsorLogoEditor.prototype._verifyFile = function(file, callback) {
        var maxWidth  = olSponsorSettings.config('logo_width_max', 2048);
        var maxHeight = olSponsorSettings.config('logo_height_max', 2048);
        var maxSize   = olSponsorSettings.config('logo_size_max', 5000*1000);

        if (file.size > maxSize) {
            callback(false); //TODO wasn't called before!
//            return false; //TODO wasn't used above
        }

        var img = new Image();
        img.onload = function() {
            if (img.width > maxWidth || img.height > maxHeight) {
                callback(false);
            } else {
                //this.targetElement.toggle(); // now above
                callback(true);
            }
        }.bind(this);
        img.src = file;
    }

    // Show invalid message and then let the select a new file
    SponsorLogoEditor.prototype._showInvalidFileDialog = function() {
        olDialog.confirm('#msgLogoInvalidFile', null,
            function() {
                window.setTimeout(this.show.bind(this), 100);
            }.bind(this), true);
    };

    SponsorLogoEditor.prototype._showEditor = function() {
        this.$targetElement.css('display', 'block');
    };

    SponsorLogoEditor.prototype._hideEditor = function() {
        this.$targetElement.css('display', 'none');
    };

    // Performs the actual upload
    SponsorLogoEditor.prototype._upload = function() {
        var callback = this._onFileUploaded.bind(this);
        var data = new FormData();
        data.append('id', this.imageId);
        if (this.imageId <= 0) {
            data.append('file', this.inputElement.files[0]);
        }
        data.append('zoom', this.imageEditor.zoom());
        data.append('move', this.imageEditor.position_xy());
        data.append('_method', 'POST');
//for(var key of data.keys()) console.log('_upload: data: '+key+': '+data.get(key));
        jQuery.ajax({
            method: 'POST',
            type: 'POST',
            url: '/sponsor/images',
            data: data,
            cache: false,
            contentType: false,
            processData: false,
            //succes: rc, textStatus, xhr
            //error:  xhr, textStatus, errorThrown
            success: function(rc, status, xhr) {
                console.log('upload success:', rc, status, xhr);
                this.$yesBtn.removeClass('ol-button-loading');
                var success = (rc && rc.code == 200);
                callback(success, rc);//, rc, status, xhr);
            }.bind(this),
            error: function(xhr, status, error) {
                console.log('upload error:', error, status, xhr);
                this.$yesBtn.removeClass('ol-button-loading');
                callback(false, error);//, error, status, xhr);
            }.bind(this)
        });
    }

    // Called when upload has finished (successfull if ok=true)
    SponsorLogoEditor.prototype._onFileUploaded = function(ok, rc) {
        if (ok) {
            if (typeof this.options.onsuccess === 'function') {
                // Custom reaction on completed upload & change
                this.options.onsuccess(rc.value);
            } else {
                // Fallback: Success message & page reload
                olDialog.message('#logoUploadSuccess', null, function() {
                    location.reload(true);
                });
            }
        } else {
            // Show message with reason of failure
            var err = (rc && rc.error)? rc.error : (typeof rc === 'string')? rc : '';
            olDialog.message('#msgLogoUploadFailed', {"data-err": err});
        }
    };

}(window.olSponsorLogoEditor = window.olSponsorLogoEditor || {}, jQuery));
