(function(olBackgroundEditor, $, undefined)
{
    var fileReader = false;
    var initialized = false;
    var config;
    var uploading = false;
    
    /*
     * Create editor elements and initialize if needed
     */
    olBackgroundEditor.create = function(cfg)
    {
        config = cfg;
        
        var targetSelector = config.targetSelector;
        
        var self = olBackgroundEditor;
        olBackgroundEditor.inputEl = undefined;
        olBackgroundEditor.target = targetSelector;

        // Find dialog/overlay
        olBackgroundEditor.targetJq = $(olBackgroundEditor.target);
        olBackgroundEditor.targetEl = olBackgroundEditor.targetJq[0];

        // Find Editor and Previews
        olBackgroundEditor.editor = olBackgroundEditor.targetJq.find('.editor').get(0);
        olBackgroundEditor.preview = olBackgroundEditor.targetJq.find('.preview')[0];

        // Set up SIE
        olTeamBackgroundEditor.create({"stateId": config.stateId, "decoration": {color: "rgb(255,0,0)", pattern: true}, teamColors: config.teamColors});
        olBackgroundEditor.sie = olTeamBackgroundEditor;
        olBackgroundEditor.sie.createEditor(olBackgroundEditor.editor);
        
        // Find scrollbar
        olBackgroundEditor.slider = olBackgroundEditor.targetJq.find('#slider');
        olBackgroundEditor.slider.bootstrapSlider({ 'tooltip' : 'hide' });
        
        function zoomHandler (ev) {
            var range = parseInt(ev.target.dataset.sliderMax) - parseInt(ev.target.dataset.sliderMin);
            var newZoom = (parseInt(ev.value) / range);
            newZoom = 1+newZoom;
            self.sie.zoom(newZoom);
        }
        olBackgroundEditor.slider.bootstrapSlider().on("slideStop", zoomHandler).on("slide", zoomHandler);

        olBackgroundEditor.slider.trigger("change");


        if (!initialized) {
            // Find confirm and cancel
            olBackgroundEditor.buttons = {
                'yes': $('#saveBackgroundButton'),
                'no': $('#abortBackgroundButton'),
            }
            olBackgroundEditor.buttons.yes.on("click", function(ev) {
                _upload()

            });
            olBackgroundEditor.buttons.no.on("click", function(ev) {
                _hideEditor();
            });

            // Background Selection
            $('.editor-background-buttons button').click(function(ev) {
                $('.editor-background-buttons button').removeClass('active');
                $(this).addClass('active');
                _changeDecoration($(this).data('decoration'));
            });
            
            $('.pattern-toggle-container .ol-checkbox input').change(function(ev) {
                olBackgroundEditor.sie.setPattern(ev.target.checked);
            })
        }
        initialized = true;
    };
    
    /*
     * Relay a decoration change to the image editor
     */
    function _changeDecoration(color, pattern) {
        olBackgroundEditor.sie.changeDecoration(color);
    }
    
    /*
     * Unhide the editor
     */
    olBackgroundEditor.show = function(closeOverlayOnCancel) {
        if(typeof closeOverlayOnCancel === typeof undefined)
            closeOverlayOnCancel = true;
        var self = olBackgroundEditor;
         _selectFile();
    };
    
    /*
     * Open the file select input
     */
    var fileDialogIsClosed = false;
    function _selectFile() {
        fileDialogIsClosed = false;
        olBackgroundEditor = olBackgroundEditor;
        var self = olBackgroundEditor;
        olBackgroundEditor.inputEl = document.createElement('input');
        olBackgroundEditor.inputEl.style.display = 'none';
        olBackgroundEditor.inputEl.type = 'file';
        olBackgroundEditor.inputEl.setAttribute("id", "fileInput");
        olBackgroundEditor.inputEl.accept = 'image/*';
        olBackgroundEditor.inputEl.onchange = function(ev) {
            fileDialogIsClosed = true;
            _onFileSelected(ev);
        };
        olBackgroundEditor.inputEl.click();
        
        document.body.onfocus = function()
        {
            document.body.onfocus = null;
            setTimeout(function()
            {
                if(!fileDialogIsClosed)
                    olOverlayWindow.close();
            }, 300);
        };
        
    }
    
    /*
     * Called when user selects a file
     */
    function _onFileSelected(event) {
        olBackgroundEditor = olBackgroundEditor;
        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        var reader = new FileReader(event);
        
        var input = event.target;

        fileReader = new FileReader();
        fileReader.onload = function()
        {
            var result = fileReader.result;
            _verifyFile(input, result, function(r)
            {
                if(r)
                {
                    var urlCreator = window.URL || window.webkitURL;
                    // Load image file
                    olBackgroundEditor.sie.load(result, function()
                    {
                        _showEditor();
                    });
                } else {
                    _hideEditor();
                }
            });
        };
        fileReader.readAsDataURL(input.files[0]);


    }
    
    function _verifyFile(input, file, callback) {
        if (config.maxFileSize && input.files[0].size > config.maxFileSize) {
            callback(false);
            olMsgBox.msgById('imageFileSizeExceeded');
            return;
        }
        
        var img = new Image();

        img.onload = function(){
            if (img.width > config.maxDimensions[0] || img.height > config.maxDimensions[1]) {
                callback(false);
                olMsgBox.msgById('imageDimensionsExceeded');
                return;
            }
            $('.ol-team-logo-editor').toggle();
            callback(true);
        };
        img.src = file;
    }

    function _showEditor() {
        olMobileApp.hideHeader();
        $('.team-background-editor-darken').show();
        olBackgroundEditor.targetEl.style.display = "block";
        olBackgroundEditor.sie.refreshRender();
    }

    /*
     * Closes the editor and resets its elements
     */
    function _hideEditor() {
        olMobileApp.showHeader();
        $('.team-background-editor-darken').hide();
        olBackgroundEditor.targetEl.style.display = "none";
        olBackgroundEditor.targetJq.find('.editor').empty();
    }

    /*
     * Upload the selected file and position to the server
     */
    function _upload() {
        
        if (uploading) return;
        addLoadingAnimationToElementNoTimeOut($('#olTeamOverviewHeader'))
        uploading = true;
        
        // Collect data and put them into a form object
        var imageData = olTeamBackgroundEditor.getData()
        var data = new FormData();
        data.append('file', olBackgroundEditor.inputEl.files[0]);
        data.append('zoom', imageData.zoom);
        data.append('move', imageData.position);
        data.append('pattern', imageData.decoration.pattern);
        data.append('color', imageData.decoration.colorName);
        data.append('_method', 'POST');

        // Send request
        jQuery.ajax({
            'method': 'POST',
            'type': 'POST',
            'url': '/team/teamVisualUpload',
            'data': data,
            'cache': false,
            'contentType': false,
            'processData': false,
            'success': function(result) {
                _uploadSuccess(result);
            },
            'error': function(ev) {
                _uploadError(ev);
            },
            'complete': function(ev) {
                uploading = false;
                removeLoadingAnimationFromElement($('#olTeamOverviewHeader'))
            }
        });
        
    }
    
    /*
     * Called when an image is uploaded successfully
     */
    function _uploadSuccess(result) 
    {   
        
        var imageData = olTeamBackgroundEditor.getData()
        
        if (result.success) {
            _hideEditor();
            
            olTeamLogoNavigation.setVisualUploadDelay();

            olTeamOverview.init( { 
                stateId : config.stateId,
                visual: {
                    type: 'CUSTOM',
                    file: result.value.file,
                    position: imageData.position,
                    zoom: imageData.zoom,
                    decoration: imageData.decoration
                },
                canvasElement : document.getElementById("userVisualCanvas"),
                loadingHeaderElement : $("#olTeamOverviewHeader")
            });
        } else {
            _uploadError();
        }
    }
    
    /*
     * Called when there's an error while uploading
     */
    function _uploadError(ev) {
        olMsgBox.msgById('visualUploadFailed');
    }
    
}(window.olBackgroundEditor = window.olBackgroundEditor || {}, jQuery));