(function(self, $, undefined) {
    var globalTimeFormat = 'H:mm';
    var globalTimezoneOffset;
    var globalTimezoneOffsetInMs;

    self.init = function(config) {
        if (typeof config !== 'object') return;

        if (config.format) self.setTimeFormat(config.format);
        if (config.offset) self.setBaseTimezoneOffset(config.offset);
    }
    
    self.setTimeFormat = function(format) {
        if (typeof format !== 'string') return;
        globalTimeFormat = format;
    };

    self.setBaseTimezoneOffset = function(offset) {
        if (typeof offset !== 'string' || isNaN((offset = parseInt(offset)))) return;

        globalTimezoneOffset = offset;
        globalTimezoneOffsetInMs = offset * 60 * 1000;
    };

    self.strMillisecondsToHourMinute = function(milliseconds) {
        if (typeof milliseconds === typeof undefined) milliseconds = Date.now();
        var epoch = new Date(0), now = new Date();
        var dms = (epoch.getTimezoneOffset() - now.getTimezoneOffset()) * 60000; // tz offset in ms between epoch and now
        return self.formatDate(new Date(parseInt(milliseconds) + dms - globalTimezoneOffsetInMs), globalTimeFormat);
    };

    self.formatTime = function(date, format, utc) {
        if (typeof format !== 'string') format = globalTimeFormat;
        return self.formatDate(date, format, utc);
    };

    // Format date/time with PHP format strings
    self.formatDatePHP = function(date, format, utc) {
        var MMMM = ["\x00", Lang.trans('js/misc.MMMM-1'), Lang.trans('js/misc.MMMM-2'), Lang.trans('js/misc.MMMM-3'), Lang.trans('js/misc.MMMM-4'), Lang.trans('js/misc.MMMM-5'), Lang.trans('js/misc.MMMM-6'), Lang.trans('js/misc.MMMM-7'), Lang.trans('js/misc.MMMM-8'), Lang.trans('js/misc.MMMM-9'), Lang.trans('js/misc.MMMM-10'), Lang.trans('js/misc.MMMM-11'), Lang.trans('js/misc.MMMM-12')];
        var MMM = ["\x01", Lang.trans('js/misc.MMM-1'), Lang.trans('js/misc.MMM-2'), Lang.trans('js/misc.MMM-3'), Lang.trans('js/misc.MMM-4'), Lang.trans('js/misc.MMM-5'), Lang.trans('js/misc.MMM-6'), Lang.trans('js/misc.MMM-7'), Lang.trans('js/misc.MMM-8'), Lang.trans('js/misc.MMM-9'), Lang.trans('js/misc.MMM-10'), Lang.trans('js/misc.MMM-11'), Lang.trans('js/misc.MMM-12')];
        var dddd = ["\x02", Lang.trans('js/misc.DDDD-1'), Lang.trans('js/misc.DDDD-2'), Lang.trans('js/misc.DDDD-3'), Lang.trans('js/misc.DDDD-4'), Lang.trans('js/misc.DDDD-5'), Lang.trans('js/misc.DDDD-6'), Lang.trans('js/misc.DDDD-7')];
        var ddd = ["\x03", Lang.trans('js/misc.DDD-1'), Lang.trans('js/misc.DDD-2'), Lang.trans('js/misc.DDD-3'), Lang.trans('js/misc.DDD-4'), Lang.trans('js/misc.DDD-5'), Lang.trans('js/misc.DDD-6'), Lang.trans('js/misc.DDD-7')];

        function ii(i, len) {
            var s = i + "";
            len = len || 2;
            while (s.length < len)
                s = "0" + s;
            return s;
        }

        var d = utc ? date.getUTCDate() : date.getDate();
        format = format.replace(/(^|[^\\])d/g, "$1" + ii(d));
        format = format.replace(/(^|[^\\])j/g, "$1" + d);

        var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
        format = format.replace(/(^|[^\\])l/g, "$1" + dddd[0]);
        format = format.replace(/(^|[^\\])D/g, "$1" + ddd[0]);
        format = format.replace(/(^|[^\\])w/g, "$1" + day-1);

        var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
        format = format.replace(/(^|[^\\])F/g, "$1" + MMMM[0]);
        format = format.replace(/(^|[^\\])M/g, "$1" + MMM[0]);
        format = format.replace(/(^|[^\\])m/g, "$1" + ii(M));
        format = format.replace(/(^|[^\\])n/g, "$1" + M);

        var y = utc ? date.getUTCFullYear() : date.getFullYear();
        format = format.replace(/(^|[^\\])Y/g, "$1" + y);
        format = format.replace(/(^|[^\\])y/g, "$1" + y.toString().substr(2, 2));

        var H = utc ? date.getUTCHours() : date.getHours();
        format = format.replace(/(^|[^\\])H/g, "$1" + ii(H));

        var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
        format = format.replace(/(^|[^\\])h/g, "$1" + ii(h));
        format = format.replace(/(^|[^\\])g/g, "$1" + h);
        
        var m = utc ? date.getUTCMinutes() : date.getMinutes();
        format = format.replace(/(^|[^\\])i/g, "$1" + ii(m));
        
        var s = utc ? date.getUTCSeconds() : date.getSeconds();
        format = format.replace(/(^|[^\\])s/g, "$1" + ii(s));

        var T = H < 12 ? "AM" : "PM";
        format = format.replace(/(^|[^\\])a/g, "$1" + T.toLowerCase());
        
        format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
        format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

        format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
        format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

        format = format.replace(/\\(.)/g, "$1");

        return format;
    };

    self.formatDate = function(date, format, utc) {
        var MMMM = ["\x00", Lang.trans('js/misc.MMMM-1'), Lang.trans('js/misc.MMMM-2'), Lang.trans('js/misc.MMMM-3'), Lang.trans('js/misc.MMMM-4'), Lang.trans('js/misc.MMMM-5'), Lang.trans('js/misc.MMMM-6'), Lang.trans('js/misc.MMMM-7'), Lang.trans('js/misc.MMMM-8'), Lang.trans('js/misc.MMMM-9'), Lang.trans('js/misc.MMMM-10'), Lang.trans('js/misc.MMMM-11'), Lang.trans('js/misc.MMMM-12')];
        var MMM = ["\x01", Lang.trans('js/misc.MMM-1'), Lang.trans('js/misc.MMM-2'), Lang.trans('js/misc.MMM-3'), Lang.trans('js/misc.MMM-4'), Lang.trans('js/misc.MMM-5'), Lang.trans('js/misc.MMM-6'), Lang.trans('js/misc.MMM-7'), Lang.trans('js/misc.MMM-8'), Lang.trans('js/misc.MMM-9'), Lang.trans('js/misc.MMM-10'), Lang.trans('js/misc.MMM-11'), Lang.trans('js/misc.MMM-12')];
        var dddd = ["\x02", Lang.trans('js/misc.DDDD-1'), Lang.trans('js/misc.DDDD-2'), Lang.trans('js/misc.DDDD-3'), Lang.trans('js/misc.DDDD-4'), Lang.trans('js/misc.DDDD-5'), Lang.trans('js/misc.DDDD-6'), Lang.trans('js/misc.DDDD-7')];
        var ddd = ["\x03", Lang.trans('js/misc.DDD-1'), Lang.trans('js/misc.DDD-2'), Lang.trans('js/misc.DDD-3'), Lang.trans('js/misc.DDD-4'), Lang.trans('js/misc.DDD-5'), Lang.trans('js/misc.DDD-6'), Lang.trans('js/misc.DDD-7')];

        function ii(i, len) {
            var s = i + "";
            len = len || 2;
            while (s.length < len)
                s = "0" + s;
            return s;
        }

        var y = utc ? date.getUTCFullYear() : date.getFullYear();
        format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
        format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
        format = format.replace(/(^|[^\\])y/g, "$1" + y);

        var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
        format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
        format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
        format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
        format = format.replace(/(^|[^\\])M/g, "$1" + M);

        var d = utc ? date.getUTCDate() : date.getDate();
        format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
        format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
        format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
        format = format.replace(/(^|[^\\])d/g, "$1" + d);

        var H = utc ? date.getUTCHours() : date.getHours();
        format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
        format = format.replace(/(^|[^\\])H/g, "$1" + H);

        var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
        format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
        format = format.replace(/(^|[^\\])h/g, "$1" + h);
        format = format.replace(/(^|[^\\])g/g, "$1" + h); // php DateTime::format, see https://www.php.net/manual/de/datetime.format.php

        var m = utc ? date.getUTCMinutes() : date.getMinutes();
        format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
        format = format.replace(/(^|[^\\])m/g, "$1" + m);
        format = format.replace(/(^|[^\\])i/g, "$1" + ii(m)); // php DateTime::format, see https://www.php.net/manual/de/datetime.format.php

        var s = utc ? date.getUTCSeconds() : date.getSeconds();
        format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
        format = format.replace(/(^|[^\\])s/g, "$1" + s);

        var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
        format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
        f = Math.round(f / 10);
        format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
        f = Math.round(f / 10);
        format = format.replace(/(^|[^\\])f/g, "$1" + f);

        var T = H < 12 ? "AM" : "PM";
        format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
        format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

        var t = T.toLowerCase();
        format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
        format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));
        format = format.replace(/(^|[^\\])a/g, "$1" + t); // php DateTime::format, see https://www.php.net/manual/de/datetime.format.php

        var tz = -date.getTimezoneOffset();
        var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
        if (!utc) {
            tz = Math.abs(tz);
            var tzHrs = Math.floor(tz / 60);
            var tzMin = tz % 60;
            K += ii(tzHrs) + ":" + ii(tzMin);
        }
        format = format.replace(/(^|[^\\])K/g, "$1" + K);

        var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
        format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
        format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

        format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
        format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

        format = format.replace(/\\(.)/g, "$1");

        return format;
    };
})(window.olRealtimeHelper = window.olRealtimeHelper || {}, jQuery);