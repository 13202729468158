(function(olContracts, $, undefined) {

    olContracts.setIsSummerBreak = function(isSummerBreak) {
        olContracts.isSommerBreak = isSummerBreak;
    };

    olContracts.isSommerBreak = false;
    olContracts.sliderChange = function(value) {
        $('.contract-duration-tick').removeClass('active');
        $('#overlayContractDuration' + value).addClass('active');
        $('#newDuration').text(parseInt($('#newDuration').attr('data-duration')) + parseInt(value) - (olContracts.isSommerBreak ? 1 : 0));
    };

    olContracts.applyFilter = function() {
        var position = $('#dropdownposition').dropdown().value();
        var duration = $('#dropdownDuration').dropdown().value();
    };

    function _animateNegotiationItems() {
        setTimeout(function() {
            var first = $('div.ol-negotiation-item-anim-start:not(.ol-negotiation-item-anim-end)').first();
            if (first) {
                first.addClass('ol-negotiation-item-anim-end');
                setTimeout(function() {
                    _animateNegotiationItems();
                }, 300);
            }
        }, 1);
    }

    function _reloadPage() {
        var $anchor = $('#ol-root');
        var position = $('#dropdownposition').attr('data-value');
        var duration = $('#dropdownDuration').attr('data-value');
        
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get('/office/contracts', function(html) {
            $anchor.html(html);

            //if dropdown was selected, restore filter when reloading content
            if(position || duration) {
                if (typeof position === typeof undefined) position = 0;
                if (typeof duration === typeof undefined) duration = -1;
                var $selectorPos = $('#dropdownposition ul li[data-value="' + position +'"] a');
                var $selectorDur = $('#dropdownDuration ul li[data-value="' + duration +'"] a');
  
                $('#dropdownposition').dropdown().setText($selectorPos.text());
                $('#dropdownposition').dropdown().setValue(position);
                $('#dropdownDuration').dropdown().setText($selectorDur.text());
                $('#dropdownDuration').dropdown().setValue(duration); 

                olSquad.filterByPlayerPosition($selectorPos);
                olSquad.filterByPlayerContractDuration($selectorDur);
            }
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** officeContracts failed");
        });
    }

    olContracts.onClickContractExtensionButton = function(contractId) {
        $target = $('#contractExtensionPlayerId' + contractId);
        olOverlayWindow.load('/office/contracts/extension?contractId=' + contractId, undefined, undefined, undefined, undefined, {
            loadingAnimationTarget: $target,
            loadingAnimationSize: 'button',
            loadingAnimationNoTimeout: true,
        });
    };

    olContracts.saveTermination = function(playerId, counterProp) {
        if (typeof counterProp === typeof undefined) counterProp = false;

        var $div = $('#ol_contracts_window_input');
        var $requirement = $('input[name="requirement"]', $div);
        $requirement = olGUI.stripNumericString($requirement.val());

        if ($requirement < 0) return;

        var data = {
            playerId: playerId,
            requirement: $requirement,
        };

        if (counterProp !== false) {
            var lastOffer = $('.ol-contracts').children().last();
            data.requirement = $(lastOffer).attr('data-requirement');
        }
        if (data.requirement.length == 0) return;

        var url = '/office/contracts/termination/offer';
        var clbk = function() {
            olOverlayWindow.addLoadingAnimation();
            $.ajax({
                cache: false,
                url: url,
                data: data,
                dataType: 'json',
                success: function(data) {
                    olOverlayWindow.removeLoadingAnimation();

                    var $neg_table = $('.table.ol-contracts');
                    $('.ol-contract-hint').fadeOut(100);
                    $('#ol_contracts_window_player_main_table').fadeOut(100);
                    $neg_table.removeClass('hidden');
                    if (typeof data.type !== typeof undefined && data.type == 'msg') {
                        olMsgBox.json(data, function() {
                            if (data.hint == 'success') {
                                olGUI.updateLiquidFunds();
                                olOverlayWindow.close();
                                _reloadPage();
                            }
                        });
                        return;
                    }

                    if (data.accept <= 0) {
                        $('#buttonAcceptLast').fadeIn(100, function() {
                            if (data.accept == -1) $('#contractExtensionErrorInfo').addClass('not-accepted');
                            else {
                                $('#ol_contracts_window_input').fadeOut(100);
                                $('#buttonOffer').fadeOut(100, function() {
                                    $('#contractExtensionErrorInfo').addClass('cancled');
                                    $('#contractExtensionErrorInfo').removeClass('not-accepted');
                                    $('#negotiation-cancel').hide();
                                    $('#cancelled-negotiation').show();
                                    $('#buttonOffer').hide();
                                    $('.contract-negotiation-overlay-wrapper').addClass('ol-negotiation-canceled');
                                });
                            }

                            if (typeof data.neg_player_first !== typeof undefined) {
                                // counter proposal
                                var $rowPlayer = $(data.neg_player_first);
                                $neg_table.append($rowPlayer);
                            }

                            // add user proposal
                            var $rowUser = $(data.neg_user);
                            $neg_table.append($rowUser);

                            // counter proposal
                            var $rowPlayer = $(data.neg_player);
                            $neg_table.append($rowPlayer);

                            olOverlayWindow.resize();
                            _animateNegotiationItems();

                            $('#contractNegotiationTable').scrollTop($('#contractNegotiationTable').height());
                        });
                    }
                    $('.contract-negotiation-table-wrapper').scrollTop($('.contract-negotiation-table-wrapper').height());
                },
            });
        };

        //check if input contains a value
        if (counterProp) {
           clbk();
        } else if ($requirement) {
            olMsgBox.question(Lang.trans('js/office.terminationHeadline'), 
                Lang.trans('js/office.terminationText', {sum: olGUI.formatCurrency2($requirement, 0)}), 
                clbk, false, Lang.trans('js/office.terminationOffer'));
        } else {
            olMsgBox.msg(Lang.trans('js/office.terminationHeadline'), Lang.trans('js/office.terminationEmpty'));
        }
    };

    olContracts.saveExtend = function(playerId, counterProp) {
        if (typeof counterProp === typeof undefined) counterProp = false;
        var $div = $('#ol_contracts_window_input');
        var $salary = $('input[name="salary"]');
        var $duration = $('input[name="duration"]');

        var data = {
            playerId: playerId,
            salary: olGUI.stripNumericString($salary.val()),
            duration: $duration.val(),
        };

        if (counterProp !== false) {
            var lastOffer = $('.ol-contracts').find('.ol-negotiation-item-anim-start').last();
            data.salary = $(lastOffer).attr('data-salary');
            data.duration = $(lastOffer).attr('data-duration');
        }
        if (data.salary.length == 0 || isNaN(data.salary)) return;

        var url = '/office/contracts/extension/offer';
        olOverlayWindow.addLoadingAnimation();
        $.ajax({
            cache: false,
            url: url,
            data: data,
            dataType: 'json',
            success: function(data) {
                olOverlayWindow.removeLoadingAnimation();
                if (olGUI.investCheckFailed(data)) {
                    olMsgBox.json(data);
                    return;
                } else if (data.check == "failed" && !data.hint == "success") {
                    olMsgBox.json(data);
                    return;
                }

                var $neg_table = $('.table.ol-contracts');
                $('.ol-contract-hint').fadeOut(100);                
                $neg_table.removeClass('hidden');
                if (typeof data.type !== typeof undefined && data.type == 'msg') {
                    olMsgBox.json(data, function() {
                        if (data.hint == 'success') {
                            olOverlayWindow.close();
                            _reloadPage();
                        }
                    });
                    return;
                }

                if (data.accept <= 0) {
                    $('#buttonAcceptLast').show();
                    if (data.accept == -1) {
                        $('#contractExtensionErrorInfo').addClass('not-accepted');
                    } else {
                        $('#contractExtensionErrorInfo').addClass('cancled');
                        $('#contractExtensionErrorInfo').removeClass('not-accepted');
                        $('#ol_contracts_window_input').hide();
                        $('#buttonOffer').hide();
                        $('#negotiation-cancel').hide();
                        $('#cancelled-negotiation').show();
                        $('.contract-negotiation-overlay-wrapper').addClass('ol-negotiation-canceled');
                    }

                    // add user proposal
                    var $rowUser = $(data.neg_user);
                    $neg_table.append($rowUser);

                    // counter proposal
                    var $rowPlayer = $(data.neg_player);
                    $neg_table.append($rowPlayer);

                    _animateNegotiationItems();
                    $('#contractNegotiationTable').scrollTop($('#contractNegotiationTable').height());
                    olOverlayWindow.resize();
                }
            },
        });
    };
})((window.olContracts = window.olContracts || {}), jQuery);
