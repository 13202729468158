(function(olACP, $, undefined ) {

    var debug = false;

    //#region --- Misc #1 ---

    olACP.initCSRFToken = function() {
        // CSRF protection
        $.ajaxPrefilter(function(options, originalOptions, xhr) {
            var xcsrf = (typeof options.xcsrf === typeof undefined ? true : options.xcsrf);
            // this will run before each request
            var token = $('input[name="_token"]').val(); // or _token, whichever you are using
            if (token && xcsrf) {
                return xhr.setRequestHeader('X-CSRF-TOKEN', token); // adds directly to the XmlHttpRequest Object
            }
        });
    }



    olACP.disableUserEditing = function(userId) {
        olACP.saveUserEditing(userId,0);
    }

    olACP.enableUserEditing = function(userId) {
        olACP.saveUserEditing(userId,1);
    }

    olACP.resetUserEditing = function(userId) {
        olACP.saveUserEditing(userId,-1);
    }

    olACP.saveUserEditing = function(userId,value) {
        $.ajax({
            type: "POST",
            url: '/acp/user/saveUserEditing',
            dataType: 'html',
            data: {
                //for authentification
                //_token: "{{ csrf_token() }}",
                userId : userId,
                value : value
            },
            success: function() {
                olACP.showUser(userId);
            }
        });
    }



    olACP.disableUserWarning = function(userId) {
        olACP.saveUserWarning(userId,0);
    }

    olACP.enableUserWarning = function(userId) {
        olACP.saveUserWarning(userId,1);
        var bannedUsername = $('.row.username > div:nth-child(3)').html();
        var bannedEmail = $('.row.email > div:nth-child(3)').html();
        var string = "mailto:"+ bannedEmail +"?subject="+bannedUsername+"%20Multiaccount%20&body=Hallo%20"+ bannedUsername +",%0D%0A%0D%0Ahiermit%20wirst%20du%20wegen%20Verstoßes%20gegen%20unsere%20Spielregeln%20verwarnt.%0D%0ABeim%20nächsten%20Verstoß%20wirst%20du%20umgehend%20und%20ohne%20weitere%20Vorwarnung%20vom%20Spielbetrieb%20ausgeschlossen.%20Diese%20Sperre%20ist%20dann%20endgültig%20und%20unumkehrbar!%20Bitte%20unterlasse%20das%20Handeln%20mit%20Spielern,%20die%20dieselbe%20Internetverbindung%20oder%20das%20gleiche%20Gerät%20nutzen%20und%20lösche%20etwaige%20Multi-Accounts%20-%20es%20gibt%20mehr%20als%20genügend%20andere%20Manager,%20mit%20denen%20du%20Spieler%20transferieren%20kannst.";
        window.open( string , "_blank");
        //window.location.href = string;
    }

    olACP.resetUserWarning = function(userId) {
        olACP.saveUserWarning(userId,-1);
    }

    olACP.saveUserWarning = function(userId,value) {
        $.ajax({
            type: "POST",
            url: '/acp/user/saveUserWarning',
            dataType: 'html',
            data: {
                //for authentification
                //_token: "{{ csrf_token() }}",
                userId : userId,
                value : value
            },
            success:  function() {
                olACP.showUser(userId);
            }
        });
    }



    olACP.disableCheaterWarning = function(userId) {
        olACP.saveCheaterWarning(userId,0);
    }

    olACP.enableCheaterWarning = function(userId) {
        olACP.saveCheaterWarning(userId,1);
    }

    olACP.resetCheaterWarning = function(userId) {
        olACP.saveCheaterWarning(userId,-1);
    }

    olACP.saveCheaterWarning = function(userId,value) {
        $.ajax({
            type: "POST",
            url: '/acp/user/saveCheaterWarning',
            dataType: 'html',
            data: {
                //for authentification
                //_token: "{{ csrf_token() }}",
                userId : userId,
                value : value
            },
            success: function() {
                olACP.showUser(userId);
            }
        });
    }



    olACP.changeUserFunds = function(userId) {
        var $funds = $('#fundsAdjustmentValue');
        //console.log("Value of $Funds - Value " + $funds.val());
        var fundAdjustmentValue = $funds.val();

        var $funds = $('#fundsAdjustmentMessage');
        //console.log("Value of $Funds - Message " + $funds.val());
        var fundAdjustmentMsg = $funds.val();


        var replacements = {};

        //data = { userid : userid , fundAdjustment : fundAdjustment };

        // Later with msgBox
        // olMsgBox.questionById('confirmUserFunds', function() { olACP.saveUserFunds(data); } , olACP.closeMsgBoxAndReturnToUser  , replacements, false)
        $.ajax({
            type: "POST",
            url: '/acp/user/changeUserFunds',
            dataType: 'html',
            data: {
                //for authentification
                //_token: "{{ csrf_token() }}",
                userId : userId,
                fundAdjustmentValue : fundAdjustmentValue,
                fundAdjustmentMsg   : fundAdjustmentMsg
            },
            success: function(result) {
                showChangedFunds(result,userId)
            }
        });
    }

    function showChangedFunds(result,userId) {
        //alert('Der Kontostand wurde geändert!');
        olACP.showUser(userId);
        $('#panelFundsAdjustment').html(result);
    }


    olACP.showMeAlert = function() {
        alert("Komischer Alert ausm olACP!");
    }

    olACP.postJSON = function(url, data, callback) {
        $.post(url, data , function(response) {
            olMsgBox.json(response , callback);
        });
    }

    olACP.postJSONold = function(url, data, callback) {
        return jQuery.ajax({
            'type': 'POST',
            'url': url,
            'contentType': 'application/json',
            'data': data,
            'dataType': 'json',
            'success': callback
        });
    }

    olACP.changeUserAttribute = function(userId , key) {
        $.ajax({
            type: "POST",
            url: '/acp/user/changeUserAttribute',
            dataType: 'html',
            data: {
                //for authentification
                //_token: "{{ csrf_token() }}",
                userId : userId,
                key : key
            },
            success: showChangedRow
        });
    }

    function showChangedRow(result) {
        var jsonArray = JSON.parse(result);
        //console.log(jsonArray.toString());
        jsonArray.forEach(function(jsonItem) {
            $(jsonItem[0]).html(jsonItem[1]);
        });

    }

    olACP.confirmUserAttribute = function(userId, key, elem, keyValue) {
        if (key == "username" || key == "Username") {
            var value = $('.row.username input[name=username]').val();
            //console.log('Confirming key Username mit Value ' + value);
        } else if (key != "badgeType" && key != "visualType") {
            var value = $('input[name=' + key + ']').val();
            //console.log('Confirming key Badge/Visual mit Value ' + value);
        } else {
            var value = 'ein zufälliges';
            //console.log('Confirming key else mit Value ' + value);
        }

        if (key == "deletestamp") {
            var banned = $('.row.deletestampinfo').html();
            if (banned == 0) {
                var bannedUsername = $('.row.username > div:nth-child(3)').html();
                var bannedEmail = $('.row.email > div:nth-child(3)').html().replace(/['"]+/g, '');
                olMsgBox.question('User Sperrung', 'Möchtest du den User wirklich sperren?', function() { olACP.saveUserAttribute(userId,key); window.location.href = "mailto:"+ bannedEmail +"?subject="+bannedUsername+"%20Multiaccount%20&body=Hallo%20"+ bannedUsername +",%0D%0A%0D%0AAufgrund%20eines%20schweren%20Verstoßes%20gegen%20unsere%20Spielregeln%20wurdest%20du%20vom%20Spielbetrieb%20ausgeschlossen.%20Diese%20Sperre%20ist%20endgültig%20und%20unumkehrbar!%20Du%20kannst%20dich%20natürlich%20mit%20einem%20neuen%20Team%20anmelden,%20verzichte%20dann%20bitte%20auf%20Verstöße%20gegen%20unsere%20Spielregeln%20-%20Wir%20werden%20es%20in%20jedem%20Fall%20mitbekommen." } ,  olACP.closeMsgBoxAndReturnToUser , 'JA', 'NEIN' )
            } else {
                olMsgBox.question('User Sperrung', 'Möchtest du den User wirklich entsperren?', function() { olACP.saveUserAttribute(userId,key); },  olACP.closeMsgBoxAndReturnToUser , 'JA', 'NEIN' )
            }
        } else if (key == "commentBlocked") {
            confirmTitle = $(elem).attr("data-confirmTitle");
            confirm = $(elem).attr("title") + "?";
            olMsgBox.question(confirmTitle, confirm, function() { olACP.saveUserAttribute(userId,key,keyValue); },  olACP.closeMsgBoxAndReturnToUser );
        } else {
            var keydesc = $('.row.'+key+ ' :nth-child(1)').html();
            var replacements = {
                '[keydesc]': keydesc,
                '[value]':   value,
            };

            olMsgBox.questionById('confirmUserAttribute', function() { olACP.saveUserAttribute(userId,key); } , olACP.closeMsgBoxAndReturnToUser  , replacements, false)
        }
    }



    olACP.saveUserAttribute = function(userId , key, keyValue) {
        if (key == "username" || key == "Username") {
            //console.log('key is username oder sowas!');
            var value = $('.row.username input[name=username]').val();
        } else if (key == "commentBlocked"){
            var value = keyValue;
        } else if (key != "badgeType" && key != "visualType") {
            //console.log('key is badge/Visual!');
            var value = $('input[name=' + key + ']').val();
        } else {
            //console.log('key is else!');
            var value = 1;
        }

        //console.log('key =>' + key );
        //console.log('key =>' + key + ' / Value ' + value );

        if (key == "deletestamp") {
            $.ajax({
                type: "POST",
                url: '/acp/user/saveUserAttribute',
                dataType: 'json',
                data: {
                    //for authentification
                    //_token: "{{ csrf_token() }}",
                    userId : userId,
                    value : value,
                    key : key
                },
                success: showSavedRow
            });
        } else {
            $.ajax({
                type: "POST",
                url: '/acp/user/saveUserAttribute',
                dataType: 'json',
                data: {
                    //for authentification
                    //_token: "{{ csrf_token() }}",
                    userId : userId,
                    value : value,
                    key : key
                },
                success: showSavedRow
            });
        }
    }

    function showSavedRow(result) {
        //console.log(result);
        //var jsonArray = JSON.parse(result);
        var jsonArray = result;
        //console.log(jsonArray.toString());
        jsonArray.forEach(function(jsonItem) {
            $(jsonItem[0]).html(jsonItem[1]);
        });
    }

    olACP.closeMsgBoxAndReturnToUser = function() {
        $('#msgBox').fadeOut(300);
    }

    olACP.closeMsgBox = function(userId) {
        $('#msgBox').fadeOut(300);
        olACP.showUser(userId)
    }

    olACP.showUser = function(uid) {
        $.ajax({
            type: "POST",
            url: '/acp/user/searchusername',
            dataType: 'html',
            data: {
                //for authentification
                //_token: "{{ csrf_token() }}",
                uid: uid
            },
            success: olACP.showUserData
        });
    }

    olACP.showUser2 = function(uid, csrfToken) {
        $.ajax({
            type: "POST",
            url: '/acp/user/searchusername',
            dataType: 'html',
            data: {
                //for authentification
                _token: csrfToken,
                uid: uid
            },
            success: olACP.showUserData
        });
    }

    olACP.showUserData = function(result) {
        //document.getElementById('userContent').innerHTML = result;
        $('#userContent').html(result);
    }


    olACP.resendConfirmationCode = function(userid) {
        var callbackSuccessEmailChange = function() {
        };
        data = {userid: userid};
        var url = "/acp/user/resendConfirm";
        olMsgBox.postLoad(url, data, callbackSuccessEmailChange , 'OKAY');
    };


    function showButtonForChange(result) {
        var jsonArray = JSON.parse(result);
        $(jsonArray[0]).html(jsonArray[1]);
    }


    olACP.closeMsgBox = function() {
        $("#msgBox").fadeOut(300);
    }



    olACP.changeVisual = function(userId) {
        $.ajax({
            type: "POST",
            url: '/acp/user/changeVisual',
            dataType: 'html',
            data: {
                //for authentification
                //_token: "{{ csrf_token() }}",
                userId : userId
            },
            success: showChangedRow
        });
    }

    olACP.saveRandomVisual = function(userId) {
        olACP.saveVisual(userId , true);
    }

    olACP.saveVisual = function(userId , random) {
        if (random == false) {
            var visualId = document.querySelector("#visualId").value;
        } else if (random == true) {
            var visualId = Math.floor(Math.random() * Math.floor(36));
            visualId++;
        } else {
            var visualId = random;
        }

        var data = {
            //for authentification
            //_token: "{{ csrf_token() }}"
            userId: userId,
            visualId : visualId
        };

        olACP.showUser(userId);

        olACP.postJSON('/acp/user/saveVisual', data, function(responseData) {
            if (responseData.type == "msg") {
                olMsgBox.json(responseData);
            } else if (responseData.type == "question") {
                olMsgBox.json(responseData , showVisualChangedMsg , function() { } );
            }
        });
    }

    olACP.showSuspicious = function(susId) {
        $.ajax({
            type: "POST",
            url: '/acp/violation/suspicious/detail',
            dataType: 'html',
            data: {
                //for authentification
                //_token: "{{ csrf_token() }}",
                susId : susId
            },
            success: olACP.showSusDetail
        });
    }

    olACP.showSusDetail = function(result) {
        document.getElementById('userContent').innerHTML = result;
    }


    //function showSusDetail(result) {
    //    var jsonArray = JSON.parse(result);
    //    jsonArray.forEach(function(jsonItem) {
    //        $(jsonItem[0]).html(jsonItem[1]);
    //    });
    //}

    olACP.showEveryProcessedTransfer = function() {
        $('[data-processedTransfer]').removeClass('hidden');
    }

    olACP.showEveryProcessedTransferBuy = function() {
        $('[data-processedTransfer=2]').addClass('hidden');
        $('[data-processedTransfer=3]').removeClass('hidden');
    }

    olACP.showEveryProcessedTransferSell = function() {
        $('[data-processedTransfer=2]').removeClass('hidden');
        $('[data-processedTransfer=3]').addClass('hidden');
    }



    olACP.showEveryTransfer = function() {
        $('[data-multi-trade=0]').addClass('hidden');
    }

    olACP.hideEveryTransfer = function() {
        $('[data-multi-trade=0]').removeClass('hidden');
    }

    //// NOTE: "donate" means here "add premium days"
    //olACP.donatePremium = function(userId) {
    //    var $days = $('[name=daysToDonate]');
    //    //console.log("Value of daysToDonate - Message " + $days.val());
    //    var days = $days.val();
    //
    //    var data = {userId: userId, days: days};
    //    $.post('/acp/user/donatePremium', data, function(rc) {
    //        if (rc.err) {
    //           olMsgBox.msg('Premium-Anlage-Fehler', '<b>Premium wurde nicht angelegt!</b><br />'+rc.err);
    //        } else {
    //            showUser(userId);
    //        }
    //    }, 'json').fail(function(error) {
    //        console.log('*** donatePremium failed', error);
    //        olMsgBox.msg('Premium-Anlage-Fehler', '<b>Premium wurde nicht angelegt!</b><br />Der Aufruf löste eine Exception aus!');
    //    });
    //}
    //
    //
    //olACP.changePlusState = function(userId, on) {
    //    var data = {userId: userId, on: on? 1 : 0};
    //    $.post('/acp/user/changePlus', data, function(rc) {
    //        if (rc.err) {
    //           olMsgBox.msg('Plus-Status-Fehler', '<b>Plus-Flag wurde nicht verändert!</b><br />'+rc.err);
    //        } else {
    //            olACP.showUserForPremium(userId);
    //        }
    //    }, 'json').fail(function(error) {
    //        console.log('*** changePlusState failed', error);
    //        olMsgBox.msg('Plus-Status-Fehler', '<b>Plus-Flag wurde nicht verändert!</b><br />Der Aufruf löste eine Exception aus!');
    //    });
    //
    //};
    //
    //olACP.showUserForPremium = function(userId) {
    //    $('#panelPremiumAdjustment').css('opacity', 0.5);
    //    olACP.showUser(userId);
    //};
    //
    //olACP.togglePremiumList = function(event, suffix) {
    //    var $target = $(event.target),
    //        hide = $target.prop('checked'),
    //        suffix = $target.attr('data-suffix');
    //
    //    var suffixes = $('input[type="checkbox"].toggleSuffix').map(function(i, o) {
    //        var $o = $(o),
    //            suffix = $o.attr('data-suffix'),
    //            hide = $o.prop('checked');
    //        return hide? suffix : null;
    //    }).get();
    //    //console.log('suffixes: '+suffixes.join(' '));
    //
    //    $('#acpUserPremiumList .premium-row').each(function(i, o) {
    //        var $o = $(o);
    //        var hide = false;
    //        for (var i = 0;  i < suffixes.length;  i++) {
    //            if ($o.hasClass('premium-row-'+suffixes[i])) {
    //                hide = true;
    //                break;
    //            }
    //        }
    //        $o.css('display', hide? 'none' : 'block');
    //    });
    //
    //    //$('#acpUserPremiumList .premium-row.premium-row-'+suffix).css('display', hide? 'none' : 'block');
    //}


    olACP.openWindowWithPost = function(url, data) {
        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = url;
        form.style.display = "none";

        for (var key in data) {
            var input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = data[key];
            //console.log('Neues Attribut (' + key + ') mit dem Wert' + data[key] + '!');
            form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    olACP.showUserAndVio = function(userId, violationId) {
        olACP.showUser(userId);
        var url = '/acp/violation/violations/details?violationId=' + violationId;
        window.open(url , '_blank');
    }

    olACP.showCheaterViewHint = function() {
        $(".cheaterViewHint").removeClass("hidden");
    }

    olACP.showTransferViewHint = function() {
        $(".transferViewHint").removeClass("hidden");
    }

    olACP.showCheaterView = function(userId) {
        var target = $(".panel-body.multiacc-content");
        addLoadingAnimationToElement(target, { 'size': 'small', 'instant': true });
        var userId = userId;
        var identDuration = $("[name=identDuration]").val();
        var data = { userId: userId , allTransfers: 1 , identlength : identDuration };
        var url = '/acp/user/getViewCheater';
        olACP.showWantedView(data,url,target);
    }

    olACP.showDetailedIdents = function(userId) {
        $(".panel.panel-detailedIdents").removeClass('hidden');
        var target = $(".panel-body.detailedIdents-content");
        //addLoadingAnimationToElement(target, { 'size': 'small', 'instant': true });
        var userId = userId;
        var data = { userId: userId };
        var url = '/acp/user/getViewDetailedIdents';
        olACP.showWantedView(data,url,target);
    }

    olACP.showDetailedIdentsSummary = function(userId) {
        $(".panel.panel-detailedIdents-summary").removeClass('hidden');
        var target = $(".panel-body.detailedIdents-summary");
        //addLoadingAnimationToElement(target, { 'size': 'small', 'instant': true });
        var userId = userId;
        var data = { userId: userId , summary : 1 };
        var url = '/acp/user/getViewDetailedIdents';
        olACP.showWantedView(data,url,target);
    }

    olACP.showProcessedTransferView = function(userId) {
        var target = $(".panel-body.processedTransfer-content");
        addLoadingAnimationToElement(target, { 'size': 'small', 'instant': true });
        var userId = userId;
        var season = $("#processedTransferSeason").val();
        //console.log('Season ist' + season);
        var data = { userId: userId , allTransfers: 1 , season : season };
        var url = '/acp/user/getViewProcessedTransfer';
        olACP.showWantedView(data,url,target);
    }

    olACP.showTransferView = function(userId) {
        var target = $(".panel-body.transfer-content");
        addLoadingAnimationToElement(target, { 'size': 'small', 'instant': true });
        var userId = userId;
        var data = { userId: userId , allTransfers: 1 };
        var url = '/acp/user/getViewTransfer';
        olACP.showWantedView(data,url,target);
    }


    olACP.showLooserView = function() {
        var target = $("#panelAbandonedGames");
        addLoadingAnimationToElement(target, { 'size': 'small', 'instant': true });
        var ordertype = $("[name=ordertype]").find('option:selected').val();
        var data = { ordertype: ordertype };
        var url = '/acp/user/abandonedGamesList';
        olACP.showWantedView(data,url,target);
    }

    //#endregion ------------------------------------------------------------------------------------------------------
    //#region --- User comments ---

    olACP.showUserComments = function(userId) {
        $("#user_comments_count").html('');
        olACP.userCommentsArray = [];
        $("#user_comments_scroll_container").remove();
        var target = $(".panel-body.comment-content");
        addLoadingAnimationToElement(target, { 'size': 'small', 'instant': true });
        
        $.ajax({
            type: "GET",
            url: '/acp/comment/getUserComments',
            dataType: 'JSON',
            data: {userId: userId},
            success: function(result) {
                olACP.userCommentsArray.push.apply(olACP.userCommentsArray, result);
                olACP.userCommentsArray.sort(function(a,b){
                    return new Date(b.createdTime) - new Date(a.createdTime);
                });
                $("#user_comments_count").html(' (' + olACP.userCommentsArray.length + ')');
                var data = { userId: userId };
                $.ajax({
                    type: "POST",
                    url: '/acp/user/getViewUserComments',
                    dataType: 'html',
                    data: data,
                    success: function(result) { 
                        removeLoadingAnimationFromElement(target);
                        showRequestedResults(result);
                    },
                    error: function() {
                        removeLoadingAnimationFromElement(target);
                    }
                });
            },
            error: function() {
                removeLoadingAnimationFromElement(target);
            }
        })

    };

    // list the comments of a user in the acp user overview
    olACP.listUserComments = function(page, pageSize){
        page = page || 1;
        pageSize = pageSize || parseInt($('#user_comments_page_size').val(),10);
        $(".row.user_comment_row").remove();

        if (olACP.userCommentsArray.length <= pageSize || pageSize === 0){
            $(".row.comments_paging").hide();
        }

        // filter the comments array and build the DOM
        olACP.userCommentsArray
        .filter(function(row, index){
            var comments_paging_start = (page-1)*pageSize;
            var comments_paging_end = page*pageSize;
            return pageSize === 0 || (index >= comments_paging_start && index < comments_paging_end);
        })
        .forEach(function(c){
            var current_season = olTimeHelper.getCurrentSeason();
            var current_matchday = olTimeHelper.getCurrentMatchday();
            var comment_season = 0;
            var comment_link = "";
            var comment_scope_match = c.table.match(/\.ol_comment_system_([^_]+)_/);
            var comment_season_match = c.table.match(/^onlineliga_leagues_(\d+)\./);
            if (comment_season_match && comment_season_match.length){
                comment_season = comment_season_match[1];
            }
            var comment_scope = comment_scope_match && comment_scope_match.length ? comment_scope_match[1] : null;
            switch(comment_scope){
                case "player":
                    comment_link = "olAnchorNavigation.loadNewTab('/player/overview', { playerId : "  + c.referenceId + ", targetPageCommentId : " + c.commentId + " })";
                    break;
                case "team":
                    comment_link = "olAnchorNavigation.loadNewTab('team/overview', { userId : "  + c.referenceId + ", targetPageCommentId : " + c.commentId + " })";
                    break;
                case "league":
                    var comment_matchday = current_matchday;
                    if (current_season < comment_season){
                        comment_matchday = 34;
                    }
                    else if (current_season > comment_season){
                        comment_matchday = 1;
                    }
                    comment_link = "olAnchorNavigation.loadNewTab('/matchdaytable/matchdaytable', { season : " + comment_season + ", matchday: " + comment_matchday + ", leagueId : " + c.referenceId + ", targetPageCommentId : " + c.commentId + " })";
                    break;
                case "match":
                    comment_link = "olAnchorNavigation.loadNewTab('/match', { season : " + comment_season + ", matchId : "  + c.referenceId + ", targetPageCommentId : " + c.commentId + " })";
                    break;
                case "news":
                    comment_link = "olAnchorNavigation.loadNewTab('#url=news/article', { season : " + comment_season + ", newsId : "  + c.referenceId + ", targetPageCommentId : " + c.commentId + " })";
                    break;
            }
            var rowDiv = $('<div id="user_comment_' + c.commentUniqueId + '" class="row user_comment_row' + (c.deletedTime != '0000-00-00 00:00:00' ?' comment_deleted':'') + '"></div>');
            var ctrlDiv = $('<div class="col-xs-2" />');
            rowDiv.append('<div class="col-xs-2">' + c.formattedCreatestamp + '</div>');
            rowDiv.append('<div class="col-xs-8">' + c.text + '</div>');
            ctrlDiv.append('<span class="fa fa-external-link fa-2x" style="cursor:pointer" onclick="' + comment_link + '"></span>');
            var delVisibility = "hidden";
            var repVisibility = "hidden";
            if (c.deletedTime == '0000-00-00 00:00:00') { 
                delVisibility = "visbile";
            }
            if (c.reportCount > 0) {
                repVisibility = "visbile";
            }
            ctrlDiv.append('<span class="fa fa-warning fa-2x" style="cursor:help;visibility:' + repVisibility + '" title="' + Lang.trans('js/comment.n_reports', { n: c.reportCount })  + '"></span>');   
            ctrlDiv.append('<span class="fa fa-trash-o fa-2x delete_comment" data-uniqueId="' + c.commentUniqueId + '" data-commentId="' + c.commentId + '" style="cursor:pointer;visibility:' + delVisibility + '" title="' + Lang.trans('js/comment.delete_comment')  + '"></span>');    
            rowDiv.append(ctrlDiv);
            $(".row.comments_paging").before(rowDiv);
        });
        
        // add event to delete comments
        $("#user_comments_scroll_container").on("click", "span.delete_comment", function(){
            var el = $( this );
            olComment.deleteComment(el.attr("data-commentId"), el.attr("data-uniqueId"), function(data){
                if(data.ok){
                    var parentDiv = el.parent().parent();
                    if (!parentDiv.hasClass("comment_deleted")){
                        parentDiv.addClass("comment_deleted");
                    }
                    el.css("visibility","hidden");
                }
            });
        });

        currentPage = page;
        // paging UI
        if (currentPage == 1){
            $(".row.comments_paging > div > div:first-child").hide();
        } else {
            $(".row.comments_paging > div > div:first-child").show();
        }
        if (olACP.userCommentsArray.length <= pageSize * currentPage){
            $(".row.comments_paging > div > div:nth-child(2)").hide();
        } else {
            $(".row.comments_paging > div > div:nth-child(2)").show();
        }
    };

    //#endregion
    //#region --- Comment reports ---

    olACP.initCommentReports = function(){

        $("div.ol-ranking-paging").on('click', "a.report_paging", function(){
            var el = $( this );
            $("input#form_page").val(el.attr('data-page'))
            $("form#report_filter_form")[0].submit();
        })
        $( "#comment_report_content" ).on( "click", "a.delete_comment", function() {
            var el = $( this );
            var row = el.parent().parent().parent();
            if (!confirm(el.attr("title") + "?")){
                return;
            }
            olComment.deleteComment(el.attr("data-commentId"), el.attr("data-uniqueId"), function(data){
                if(data.ok){
                    el.removeClass("show_comment_link_1");
                    el.addClass("show_comment_link_");
                    row.children('div').eq(1).addClass("danger")
                    var el2 = el.parent().find("a.undelete_comment").eq(0);
                    el2.removeClass("show_comment_link_");
                    el2.addClass("show_comment_link_1");
                    var params = {};
                    params.commentId = el.attr("data-commentId");
                    params.context = el.attr("data-context"); 

                    olACPComments.closeReportByCommentId(params, function(resp){
                        if (resp.ok){
                            row.children('div').eq(1).addClass("comment_report-closed");
                            row.find("a.close_report").eq(0).removeClass("show_comment_link_1");
                            row.find("a.close_report").eq(0).addClass("show_comment_link_");
                        }
                    });
                }
            });
        });
        $( "#comment_report_content" ).on( "click", "a.undelete_comment", function() {
            var el = $( this );
            olComment.deleteComment(el.attr("data-commentId"), el.attr("data-uniqueId"), function(data){
                if (data.ok){
                    el.removeClass("show_comment_link_1");
                    el.addClass("show_comment_link_");
                    //el.parent().removeClass("danger");
                    var el2 = el.parent().find("a.warn_comment").eq(0);
                    el2.removeClass("show_comment_link_");
                    el2.addClass("show_comment_link_1");
                }
            });
        });
        $( "#comment_report_content" ).on( "click", "a.close_report", function() {
            var el = $( this );
            var params = {};
            params.commentId = el.attr("data-commentId");
            params.context = el.attr("data-context"); 

            olACPComments.closeReportByCommentId(params, function(resp){
                if (resp.ok){
                    var row = el.parent().parent().parent();
                    row.children('div').eq(1).addClass("comment_report-closed");
                    el.removeClass("show_comment_link_1");
                    el.addClass("show_comment_link_");
                }
            });
            
        });
        
    };

    //#endregion ------------------------------------------------------------------------------------------------------
    //#region --- Misc #2 ---

    olACP.showWantedView2 = function(data,url) {
        var userId = data.userId;

        $.post(url, data, function(rc) {
            if (rc.err) {
               olMsgBox.msg('Premium-Anlage-Fehler', '<b>Premium wurde nicht angelegt!</b><br />' + rc.err);
            } else {
                showRequestedResults(result)
            }
        }, 'json').fail(function(error) {
            console.log('*** showWantedView2 failed', error);
            olMsgBox.msg('Premium-Anlage-Fehler', '<b>Premium wurde nicht angelegt!</b><br/>Der Aufruf löste eine Exception aus!');
        });
    }


    olACP.showWantedView = function(data,url,target) {
        $.ajax({
            type: "POST",
            url: url,
            dataType: 'html',
            data: data,
            success: function(result) { removeLoadingAnimationFromElement(target); showRequestedResults(result); },
            error: function() { removeLoadingAnimationFromElement(target); }
        });
    }

    olACP.setLoadingAnimationForSelector = function(targets) {
        targets.forEach(function(target) {
            addLoadingAnimationToElement(target);
        });
    }

    function getRequestedResult(result, idx){
        var jsonArray = JSON.parse(result);
        return jsonArray && jsonArray.length && jsonArray[0].length > idx ? jsonArray[0][idx] : null;
    }

    function showRequestedResults(result) {
        var jsonArray = JSON.parse(result);
        jsonArray.forEach(function(jsonItem) {
            $(jsonItem[0]).html(jsonItem[1]);
        });
    }

    //#endregion ------------------------------------------------------------------------------------------------------
    //#region --- From clean-up ---

    olACP.resetSearch = function() {
        document.getElementById('search').value = '';
    };


    olACP.nextMessages = function(url) {
        $.ajax({
            type: "get",
            url: '/acp/messenger/dynmessagesend',
            dataType: 'html',
            data: {
                //for authentification
                page: url
            },
            success: function(tbody){
                document.getElementById('navigation').innerHTML = tbody;
            }
        });
    };

    //#endregion ------------------------------------------------------------------------------------------------------
    //#region --- Messenger ---

    var acpMessageProgressInterval;

    olACP.sendMessage = function(messageId) {
        var data = {
            _token: $('#sendmsgToken').val(),
            messageId: $('#sendmsgMessageId').val(),
            userName: $('#sendmsgUsername').val(),
            absender: $('#absender').val(),
            recipients: $('#recipients').val()
        };
        $('#demo, #cancel').addClass('disabled').attr('disabled', 'disabled');
        $.post('/acp/messenger/sendmessage', data, function(result) {
            acpMessageProgressInterval = setInterval(function() {
                trackMessageSendProgress(result.msgId);
            }, 500);
        }, 'json');
    };

    function trackMessageSendProgress(messageId) {
        $.get('/acp/messenger/sendprogress/'+messageId, function(res) {
            if (!res || res.remaining <= 0) {
                clearInterval(acpMessageProgressInterval);
                $('.acp-messenger-progressbar-wrapper').fadeOut();
                var title = $('#messageSendSuccess').attr('data-title'),
                    text = $('#messageSendSuccess').attr('data-text');
                olMsgBox.msg(title, text, function() {location.href = '/acp/messenger/overview'});
                return;
            }
            $('.acp-progressbar-label-done').text(res.total - res.remaining);
            $('.acp-progressbar-label-total').text(res.total);
            $('.acp-messenger-progressbar-inner').css('width', (100 * (res.total - res.remaining)/res.total) + '%');
            $('.acp-messenger-progressbar-wrapper').fadeIn();
        })
    }

    //#endregion ------------------------------------------------------------------------------------------------------
    //#region --- Premium/Plus ---

    olACP.reloadPanel = function(panelSel, userId) {
        $(panelSel).css('opacity', 0.5);
        olACP.showUser(userId);
    };

    olACP.reloadPremium = function(userId) {
        olACP.reloadPanel('#panelPremiumAdjustment', userId);
    };


    // Add new premium/plus
    olACP.addPremium = function(event) {
        var $target = $(event.target),
            $params = $target.closest('.paramsContainer');
        var data = {
            userId: $('input[name=userId]', $params).val(),
            days: $('input[name=days]', $params).val(),
            kind: $('input[name=kind]', $params).val()
        }
        $.post('/acp/user/addPremium', data, function(rc) {
            if (rc.err) {
               olDialog.message('#premiumAddError-'+data.kind, {"data-err": rc.err});
            } else {
                olDialog.message('#premiumAddSuccess-'+data.kind, null, function() {
                    olACP.reloadPremium(data.userId);
                });
            }
        }, 'json').fail(function(error) {
            console.log('*** addPremium failed', error, data);
            olDialog.message('#premiumAddException-'+data.kind);
        });
    }


    // Remove all premium of userId for the given reason
    olACP.decreaseAllPremium = function(event) {
        var $target = $(event.target),
            $params = $target.closest('.paramsContainer');
        var $reasonSelect = $('select[name=reason]', $params);
        var reasonText = $reasonSelect.find('option:selected').text();
        var data = {
            userId: $('input[name=userId]', $params).val(),
            reason: $reasonSelect.val(),
            kind: $('input[name=kind]', $params).val()
        };

        olDialog.confirm('#premiumDecreaseAllConfirm-'+data.kind, {
            'data-reason': reasonText
        }, function() {
            $.post('/acp/user/premium/decreaseall', data, function(rc) {
                if (rc.err) {
                    olDialog.message('#premiumDecreaseAllError-'+data.kind, {'data-err': rc.err});
                } else {
                    olDialog.message('#premiumDecreaseAllSuccess-'+data.kind, null, function() {
                        olACP.reloadPremium(data.userId);
                    });
                }

            }, 'json').fail(function(error) {
                console.log('*** olACP.decreaseAllPemium failed', error, data);
                olDialog.message('#premiumDecreaseAllException-'+data.kind);
            });
        });
    };

    // Remove premium days for the given reason
    olACP.decreasePremium = function(event) {
        var $target = $(event.target),
            $params = $target.closest('.paramsContainer');
        var $reasonSelect = $('select[name=reason]', $params);
        var reasonText = $reasonSelect.find('option:selected').text();
        var data = {
            userId: $('input[name=userId]', $params).val(),
            days: Number($('input[name=days]', $params).val()),
            reason: $reasonSelect.val(),
            kind: $('input[name=kind]', $params).val()
        };

        if (isNaN(data.days) || data.days <= 0) {
            olDialog.message('#premiumDecreaseDaysError-'+data.kind);
            return;
        }

        olDialog.confirm('#premiumDecreaseConfirm-'+data.kind, {
            'data-reason': reasonText,
            'data-days': data.days
        }, function() {
            $.post('/acp/user/premium/decrease', data, function(rc) {
                if (rc.err) {
                    olDialog.message('#premiumDecreaseError-'+data.kind, {'data-err': rc.err});
                } else {
                    olDialog.message('#premiumDecreaseSuccess-'+data.kind, {
                        'data-days': data.days
                    }, function() {
                        olACP.reloadPremium(data.userId);
                    });
                }

            }, 'json').fail(function(error) {
                console.log('*** olACP.decreasePemium failed', error, data);
                olDialog.message('#premiumDecreaseException-'+data.kind);
            });
        });
    };


    // Set plus flag for userId to on
    olACP.changePremState = function(userId, on) {
        var data = {userId: userId, on: on? 1 : 0};
        $.post('/acp/user/changePrem', data, function(rc) {
            if (rc.err) {
               olDialog.message('#premSetStateError', {"data-err": rc.err})
            } else if (rc.curPrem == rc.prevPrem) {
                olDialog.message('#premSetStateUnchanged'+(rc.endlessPrem? 'Endless' : ''));
            } else {
                olACP.reloadPremium(userId);
            }
        }, 'json').fail(function(error) {
            console.log('*** changePremState failed', error);
            olDialog.message('#premSetStateException')
        });
    };

    // Set plus flag for userId to on
    olACP.changeEndlessState = function(userId, on) {
        var data = {userId: userId, on: on? 1 : 0};
        $.post('/acp/user/changeEndless', data, function(rc) {
            if (rc.err) {
               olDialog.message('#setEndlessStateError', {"data-err": rc.err})
            } else {
                olACP.reloadPremium(userId);
            }
        }, 'json').fail(function(error) {
            console.log('*** changeEndlessState failed', error);
            olDialog.message('#setEndlessStateException')
        });
    };

    // Set plus flag for userId to on
    olACP.changePlusState = function(userId, on) {
        var data = {userId: userId, on: on? 1 : 0};
        $.post('/acp/user/changePlus', data, function(rc) {
            if (rc.err) {
               olDialog.message('#plusSetStateError', {"data-err": rc.err})
            } else {
                olACP.reloadPremium(userId);
            }
        }, 'json').fail(function(error) {
            console.log('*** changePlusState failed', error);
            olDialog.message('#plusSetStateException')
        });
    };


    // Hide entries according to selected checkboxes
    olACP.togglePremiumList = function(event) {
        var $target = $(event.target);

        var suffixes = $('input[type="checkbox"].toggleSuffix').map(function(i, o) {
            var $o = $(o),
                suffix = $o.attr('data-suffix'),
                hide = $o.prop('checked');
            return hide? suffix : null;
        }).get();
        //console.log('suffixes: '+suffixes.join(' '));

        $('#acpUserPremiumList .premium-row').each(function(i, o) {
            var $o = $(o);
            var hide = false;
            for (var i = 0;  i < suffixes.length;  i++) {
                if ($o.hasClass('premium-row-'+suffixes[i])) {
                    hide = true;
                    break;
                }
            }
            $o.css('display', hide? 'none' : 'block');
        });

        //$('#acpUserPremiumList .premium-row.premium-row-'+suffix).css('display', hide? 'none' : 'block');
    }

    //#endregion ------------------------------------------------------------------------------------------------------
    //#region --- Licence ---

    olACP.onClickLicencePartActive = function(event) {
        var $target = $(event.target),
            $input = $target,
            active = $input.prop('checked')? 1 : 0,
            id = $input.attr('data-id');
        var data = {id: id, active: active};
        $('#btn-part-userflag-update').prop('disabled', true);
        $.post('/acp/licence/changeActive', data, function(rc) {
            if (rc.err) {
                olDialog.message('#licencePartActiveError', {"data-err": rc.err});
            } else if (rc.newValue != active) {
                olDialog.message('#licencePartActiveSync', {"data-err": rc.err}, function() {
                    location.reload();
                });
            } else {
                //location.reload();
                $('#btn-part-userflag-update').prop('disabled', false);
            }
        }, 'json').fail(function(error) {
            console.log('*** changeLicencePartActive failed', error);
            olDialog.message('#licencePartActiveException', {"data-err": error.status+' '+error.responseText}, function() {
                location.reload();
            });
        });
    };

    olACP.onClickLicenceUpdate = function(event) {
        olDialog.confirm('#licenceUpdateConfirm', null, function() {
            $.post('/acp/licence/update', {}, function(rc) {
                if (rc.err) {
                    olDialog.message('#licenceUpdateError', {"data-err": rc.err});
                } else {
                    olDialog.message('#licenceUpdateSuccess', {"data-count": rc.count});
                }
            }, 'json').fail(function(error) {
                console.log('*** onClickLicenceUpdate failed', error);
                olDialog.message('#licenceUpdateException', {"data-err": error.status+' '+error.responseText})
            });
        });
    };

    // Grant a single licence part for a user
    olACP.onClickUserLicencePartGrant = function(event) {
        var $target = $(event.target),
            checked = $target.prop('checked'),
            partId = $target.attr('data-id'),
            partName = $target.attr('data-name'),
            userId = $target.attr('data-userId');

        // Only granting is allowed
        if (!checked) {
            olDialog.message('#licenceUngrant');
            $target.prop('checked', true);
            return;
        }

        //var numTotal = $('.section-licence input[type=checkbox]').length;
        //var numChecked = $('.section-licence input[type=checkbox][checked]').length;
        //var complete = (numChecked >= numTotal);

        olDialog.confirm('#licenceGrantConfirm', {'data-name': partName}, function() {
            var data = {userId: userId, partId: partId};
            $.post('/acp/licence/grant', data, function(rc) {
                if (rc.err) {
                    olDialog.message('#licenceGrantError', {"data-err": rc.err, 'data-name': partName});
		            $target.prop('checked', !checked); // reset on error
                } else {
                    //olDialog.message('#licenceGrantSuccess', {'data-name': partName}, function() {
                    	olACP.reloadPanel('#panelLicenceAdjustment', userId);
                   	//});
                }
            }, 'json').fail(function(error) {
                console.log('*** onClickUserLicencePartGrant failed', error);
                olDialog.message('#licenceGrantException', {"data-err": error.status+' '+error.responseText, 'data-name': partName})
	            $target.prop('checked', !checked); // reset on exception
            });
        }, function() {
            $target.prop('checked', !checked); // reset on cancel
        });
    };

    // Grant all licence parts for a user
    olACP.onClickUserLicenceGrantAll = function(event) {
        var $target = $(event.target),
            userId = $target.attr('data-userId');

        olDialog.confirm('#licenceGrantallConfirm', null, function() {
            var data = {userId: userId};
            $.post('/acp/licence/grantall', data, function(rc) {
                if (rc.err) {
                    olDialog.message('#licenceGrantallError', {"data-err": rc.err});
                } else {
                   	olACP.reloadPanel('#panelLicenceAdjustment', userId);
                }
            }, 'json').fail(function(error) {
                console.log('*** onClickUserLicencePartGrantall failed', error);
                olDialog.message('#licenceGrantallException', {"data-err": error.status+' '+error.responseText})
            });
        });
    };

    olACP.countCharacters = function(field, maxChars) {
        var input = document.getElementById(field);
        var count = input.value.length;
        var counter = document.getElementById(field + 'Counter');
        counter.innerHTML = count+'/'+maxChars;

        if(count > maxChars) {
            $(counter).css('color', 'red')
            $(input).attr('style', 'border-color: red !important');
            $('.btn-primary').prop("disabled", true);                
        } else {
            $(counter).css('color', 'black')
            $(input).css('border-color', 'black');

            if($('#name, #title').val().length <= 50 && $('#description').val().length <= 255) {
                $('.btn-primary').prop("disabled", false);
            }    
        }
    }

    //#endregion ------------------------------------------------------------------------------------------------------
    //#region --- Scheduler ---

    olACP.askReally = function(url) {
        if (confirm("Are you sure?"))
            window.location.replace(url);
    };
    
    //function openWorkflowLogs(workflowId) {
    //    window.location.href = "logs?page=1&workflowId=" + workflowId;
    //}
    
    olACP.initWorkflowTimeline = function() {
        $( "input[id^='timepicker']" ).each(function() {
            $(this).timepicker('setTime', $(this).attr("data-default-time"));
        });
    };
    
    var day = 0;
    var workflowTimestamp = 0;
    var timeline = {selected: {}};
    var items = {};
    
    olACP.initTemplateTimeline = function() {
        $('#workflowTimepicker').timepicker({
            minuteStep: 1,
            showMeridian: false,
            showSeconds: true,
            secondStep: 1,
            defaultTime: '00:00:00'
        });
        $('#workflowTimepicker').timepicker().on('changeTime.timepicker', function(e) {
            workflowTimestamp = e.time.minutes * 60 + e.time.hours * 60 * 60;
        });
        
        $(".acp_day").click(function() {
            day = $(this).attr("dayId");
            if (timeline["selected"].hasOwnProperty(day) && timeline["selected"][day]) {
                timeline["selected"][day] = false;
                $(this).removeClass("acp_selected");
            } else {
                timeline["selected"][day] = true;
                $(this).addClass("acp_selected");
            }
        });
        
        $(".day_item").click(function(event) {
            event.stopPropagation();
            itemId = $(this).attr("data-item-id");
            if (items.hasOwnProperty(itemId) && items[itemId]) {
                items[itemId] = false;
                $(this).removeClass("acp_selected");
            } else {
                items[itemId] = true;
                $(this).addClass("acp_selected");
            }
        });
    };
    
    olACP.addWorkflowToDays = function() {
        day = false;
        $("#overlay").fadeIn();
        $(".modalOverlay").fadeIn();
    };
    
    olACP.addWorkflowToDay = function(d) {
        day = d;
        $("#overlay").fadeIn();
        $(".modalOverlay").fadeIn();
    };
    
    olACP.deleteWorkflowFromDays = function() {
        var data = {
            itemIds: Object.keys(items)
        };
        $.post("/acp/scheduler/timelinetemplates/items/delete", data, function(response) {
            window.location.href = data.redirect;
            window.location.reload(true);
        });
    };
    
    olACP.closeWorkflowMenu = function() {
        $("#overlay").fadeOut();
        $(".modalOverlay").fadeOut();
    };
    
    olACP.addWorkflowMenu = function() { 
        workflowId = $("#selectWorkflow").val();
        times = $("#workflowTimepicker").val();
        
        data = {
            timelineTemplateId: $("input[name='timelineTemplateId']").val(),
            workflowId: workflowId,
            days: [],
            time: times,
            executionType: $("input[name='executionType']:checked").val()
        };
        if (day === false) {
            for (var iday in timeline["selected"]) {
                if (timeline["selected"].hasOwnProperty(iday)) {
                    if (timeline["selected"][iday])
                        data.days.push(iday);
                }
            }
        } else {
            data.days.push(day);
        }
        
        $.post("/acp/scheduler/timelinetemplates/items/create", data, function(response) {
            window.location.href = data.redirect;
            window.location.reload(true);
        });
    };
    
    olACP.deleteItem = function(id) {
        var data = {
            itemIds: [id]
        };
        $.post("/acp/scheduler/timelinetemplates/items/delete", data, function(response) {
            window.location.href = data.redirect;
            window.location.reload(true);
        });
    };
    
    olACP.collapseAllDays = function() {
        $('#accordion .panel-collapse').collapse('toggle');
    };
    

    olACP.initExecutions = function() {
        $('.timepicker_select').each(function() {
            $(this).timepicker('setTime', $(this).attr("data-default-time")); 
        });

        $('button.loading').each(function(index, element) {
            $(element).button('loading');
            var executionId = $(element).attr('data-value');
            setStopExecutionInterval(executionId);
         });
         olACP.updateWorkflowTimelineDropdown();
    };
    
    olACP.stopExecution = function(executionId) {
        $.post('/acp/scheduler/executions/stop/' + executionId);
        setStopExecutionInterval(executionId);
    };
    
    function setStopExecutionInterval(executionId) {
        $('#stopBtnId' + executionId).button('loading');
        var interval = setInterval(function() {
            $.get('/acp/scheduler/executions/stop/' + executionId, function(response) {
                if (response.state == 1) {
                    $('#stopBtnId' + executionId).button('reset');
                    $('#stopBtnId' + executionId).removeClass('btn-danger').addClass('btn-success');
                    if (interval) clearInterval(interval);
                }
            });
        }, 5000); 
    }
    
    olACP.updateWorkflowTimelineDropdown = function() {
        $('.currentExecutionOrder').each(function(index, element) {
            var selectedElement = $(element).find(":selected");
            var workflowId = $(selectedElement).attr('data-workflow-id');
            var parent = $(selectedElement).parents('.execution-panel');
            parent.find('.workflowTimelineOrderNr option').hide();
            parent.find('.workflowTimelineOrderNr').find('*[data-workflow-id="' + workflowId + '"]').show();
            parent.find('.workflowTimelineOrderNr').find('option:not([style*="display: none"]):eq(0)').prop('selected', true);
        });
    }


    olACP.onChangeWorkflowTimelineSelectModule = function(select) {
        commandLine = $(select).find(":selected").attr("data-commandline");
        $("#moduleCommandLine").html(commandLine);
    };
    
    olACP.onClickReplaceWorkflowInTimeline = function(templateId, replace, replaceWithId) {
        olACP.askReally("/acp/scheduler/timelinetemplates/workflow/replace/" + templateId + "/" + replace + "/" + replaceWithId);
    };
    
    olACP.onClickResetPattern = function(patternId) {
        location.href="/acp/pattern/reset/" + patternId + "/" + $("#captcha" + patternId).val();
    };
    
    olACP.onClickDeletePattern = function(patternId) {
        location.href="/acp/pattern/delete/" + patternId + "/" + $("#captcha" + patternId).val();
    };
    
    olACP.onClickLoadDay = function(day, id) {
        if ($("#accordionDay" + day).children().length == 0) {
            $("#accordionDay" + day).load("/acp/scheduler/logs/show/day/" + id + "/" + day, function() {
            });
        }
    };
    
    olACP.onClickLoadModule = function(id) {
        if ($("#accordion_" + id).children().length == 0) {
            $("#accordion_" + id).load("/acp/scheduler/logs/show/logid/" + id, function() {
            });
        }
    };
    


    
    //#endregion ------------------------------------------------------------------------------------------------------

}(window.olACP = window.olACP || {}, jQuery));
