// File for basic image editing.
// Zoom, Move, with overlay showing which areas are cropped.

(function(olTeamBackgroundEditor, $, undefined)
{
    var config, canvas, patternRepeat;
    var decoration = {color: "", pattern: true}
    olTeamBackgroundEditor.create = function(cfg) {
        config = cfg;
        
        // Load pattern for overlay
        patternRepeat = new Image(700, 308);
        patternRepeat.crossOrigin = "Anonymous";
        patternRepeat.src = olConfig.image_host + 'imgs/uservisuals/state_' + config.stateId + '_small.png';  
        
        // Input data
        olTeamBackgroundEditor.input = {
            'url': undefined,
            'image': undefined
        }
        
        olTeamBackgroundEditor.editors = new Map();

        // tmpFileName
        olTeamBackgroundEditor.tmpFileName = "";
        
        olTeamBackgroundEditor.changeDecoration(config.decoration.color, config.decoration.pattern);
    };
    
    olTeamBackgroundEditor.changeDecoration = function(colorName, pattern) {
        
        switch (colorName.toLowerCase()) {
            case "state":
                color = olGUI.getStatePrimaryColor(config.stateId);
                break;
            case "secondary":
                color = config.teamColors[1];
                break;
            case "primary":
                color = config.teamColors[0];
                break;
            case "white":
                color = "rgb(255,255,255)";
                break;
            default:
                color = "rgb(0,0,0)";
                break;
        }
        decoration.color = color;
        decoration.colorName = colorName;
        
        if (pattern !== undefined) decoration.pattern = pattern;
        
        renderOverlay();
    }
    
    olTeamBackgroundEditor.togglePattern = function() {
        decoration.pattern = !decoration.pattern;
        renderOverlay();
    }
    olTeamBackgroundEditor.setPattern = function(pattern) {
        decoration.pattern = pattern;
        renderOverlay();
    }
    
    olTeamBackgroundEditor.load = function(imageURL, readyCallback) {
        if ((imageURL == undefined) || (typeof (imageURL) != 'string')) {
            throw "Invalid URL";
        }
        
        if(typeof readyCallback === typeof undefined)
            readyCallback = false;

        olTeamBackgroundEditor.input.url = imageURL;
        var tempImg = new Image();

        tempImg.onload = function() {
            if(readyCallback !== false)
                readyCallback();
        };
            
        tempImg.src = imageURL;
        olTeamBackgroundEditor.input.image = tempImg;
        
        olTeamBackgroundEditor.preview.image.src = imageURL;
        olTeamBackgroundEditor.preview.image.style.width = $('.imagewrapper').width() + "px";
    };
    
    // Set zoom
    olTeamBackgroundEditor.zoom = function(zoom) {
        if (zoom == undefined) {
            return olTeamBackgroundEditor.zoom;
        } else if (zoom <= 10) {
            var preview = olTeamBackgroundEditor.preview;
            if (!preview) return;
            
            preview.image.style.width = $('.imagewrapper').width()*zoom + "px";
            
            olTeamBackgroundEditor.currentZoom = zoom;
            
            limitMovement()
        }
        
    }

    function _handleMouseDown(event) {
        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el == undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }

        var editor = olTeamBackgroundEditor.editors.get(el);
        editor.drag.enabled = true;
        editor.drag.x = event.layerX;
        editor.drag.y = event.layerY;
        editor.handler.style.cursor = "grabbing";
        editor.handler.classList.add("dragging");
        // TODO: Add and remove MouseMove, MouseLeave handlers on MouseDown and MouseUp/MouseLeave (Battery saving and speed improvements)
    }

    function _handleMouseUp(event) {
        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el == undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }

        var editor = olTeamBackgroundEditor.editors.get(el);
        editor.drag.enabled = false;
        editor.drag.x = event.layerX;
        editor.drag.y = event.layerY;
        editor.handler.style.cursor = "grab";
        editor.handler.classList.remove("dragging");
    }
    
    var prevX = 0;
    var prevY = 0;
    function _handleMouseMove(event) {
        var movementX = (prevX ? event.screenX - prevX : 0)
        var movementY = (prevY ? event.screenY - prevY : 0)

        prevX = event.screenX;
        prevY = event.screenY;
        
        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el == undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }

        if (!olTeamBackgroundEditor.editors.get(el).drag.enabled) {
            return;
        }

        var preview = olTeamBackgroundEditor.preview;
        preview.image.style.left = parseInt(preview.image.style.left)+movementX+"px";
        preview.image.style.top = parseInt(preview.image.style.top)+movementY+"px";
        
        limitMovement();
    }
    
    /*
     * Called after any change in zoom or position.
     * Makes sure that the image always covers its parent.
     */
    function limitMovement() {
        var preview = olTeamBackgroundEditor.preview;
        var offsetLeft = $(preview.image).parent().css('left');
        offsetLeft = parseInt(offsetLeft);
        
        var parentWidth = $(preview.image).parent().width()
        var imageWidth = parseInt(preview.image.style.width);
        var imageX = parseInt(preview.image.style.left);
        
        var parentHeight = $(preview.image).parent().height()
        var imageHeight = $(preview.image).height()
        var imageY = parseInt(preview.image.style.top);

        if (imageX > 0) {
            preview.image.style.left = 0;
        } else if ((imageX + imageWidth) < parentWidth) {
            preview.image.style.left = (parentWidth - imageWidth) + "px";
        }
        if (imageY > 0) {
            preview.image.style.top = 0;
        } else if ((imageY + imageHeight) < parentHeight) {
            preview.image.style.top = (parentHeight - imageHeight) + "px";
        }
        
        if (imageWidth < parentWidth) {
            preview.image.style.width = parentWidth + "px";
        }
    }

    function _handleMouseEnter(event) {
        _handleMouseUp(event);
    }

    function _handleMouseLeave(event) {
        _handleMouseUp(event);
    }

    function _handleTouchStart(event) {
        prevX = event.touches[0].pageX;
        prevY = event.touches[0].pageY;
    }

    function _handleTouchMove(event) {
        var movementX = (prevX ? event.touches[0].pageX - prevX : 0)
        var movementY = (prevY ? event.touches[0].pageY - prevY : 0)

        prevX = event.touches[0].pageX;
        prevY = event.touches[0].pageY;
        
        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el == undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }
        // FIXME: Take into account Zoom and editor size, so that mouse moves align with the actual cursor.
        var preview = olTeamBackgroundEditor.preview;
        preview.image.style.left = parseInt(preview.image.style.left)+movementX+"px";
        preview.image.style.top = parseInt(preview.image.style.top)+movementY+"px";
        
        event.preventDefault();
        
        limitMovement();
    }

    function _handleTouchCancel(event) {
        // TODO
        // https://w3c.github.io/touch-events/#touches-and-targettouches-of-a-touchevent
    }

    function _handleTouchEnd(event) {
        // TODO
        // https://w3c.github.io/touch-events/#touches-and-targettouches-of-a-touchevent
    }

    olTeamBackgroundEditor.createEditor = function(element) {

        var elHandler = document.createElement('div');
        elHandler.classList.add("handler");
        elHandler.style.display = "block";
        elHandler.style.position = "absolute";
        elHandler.style.top = "0px";
        elHandler.style.bottom = "0px";
        elHandler.style.left = "0px";
        elHandler.style.right = "0px";
        elHandler.style.background = "transparent";
        elHandler.style.border = "0 solid";
        elHandler.style.margin = "0";
        elHandler.style.padding = "0";
        elHandler.style.cursor = "grab";
        elHandler.onmousedown = _handleMouseDown;
        elHandler.onmouseup = _handleMouseUp;
        elHandler.onmousemove = _handleMouseMove;
        elHandler.onmouseenter = _handleMouseEnter;
        elHandler.onmouseleave = _handleMouseLeave;
        elHandler.ontouchstart = _handleTouchStart;
        elHandler.ontouchend = _handleTouchEnd;
        elHandler.ontouchmove = _handleTouchMove;
        elHandler.ontouchcancel = _handleTouchCancel;
        elHandler.source = olTeamBackgroundEditor;

        var elRender = document.createElement('div');
        elRender.classList.add("render");
        elRender.style.display = "block";
        elRender.style.position = "absolute";
        elRender.style.top = "0px";
        elRender.style.bottom = "0px";
        elRender.style.left = "0px";
        elRender.style.right = "0px";
        elRender.style.background = "transparent";
        elRender.style.border = "0 solid transparent";
        elRender.style.margin = "0",
        elRender.style.padding = "0";
        elRender.source = olTeamBackgroundEditor;

        var elContainer = document.createElement('div');
        elContainer.classList.add("simpleimageeditor");
        elContainer.classList.add("editor");
        elContainer.style.display = "block";
        elContainer.style.position = "relative";
        elContainer.style.background = "transparent";
        elContainer.style.border = "0 solid transparent";
        elContainer.style.margin = "0",
        elContainer.style.padding = "0";
        elContainer.style.width = "100%";
        elContainer.style.height = "100%";
        elContainer.appendChild(elRender);
        elContainer.appendChild(elHandler);
        elContainer.source = olTeamBackgroundEditor;

        element.appendChild(elContainer);
        olTeamBackgroundEditor.editors.set(element, {
            'container': elContainer,
            'handler': elHandler,
            'render': elRender,
            'drag': {
                'enabled': false,
                'x': 0,
                'y': 0,
            },
        });

        return olTeamBackgroundEditor.createPreview(elRender);
    }

    olTeamBackgroundEditor.createPreview = function(element) {
        var elImage = document.createElement('img');
        elImage.classList.add("image");
        elImage.setAttribute("id", "imageSrc");
        elImage.style.display = "block";
        elImage.style.position = "absolute";
        elImage.style.left = "0";
        elImage.style.top = "0";

        elImage.source = olTeamBackgroundEditor;

        var canvasOverlay = document.createElement('canvas');
        canvasOverlay.classList.add("canvasBlur");
        canvasOverlay.setAttribute("id", "canvasBlur");
        canvasOverlay.style.width = "1903";
        canvasOverlay.style.height = "308";
        canvasOverlay.source = olTeamBackgroundEditor;

        var elOverlay = document.createElement('div');
        elOverlay.classList.add("overlay");
        elOverlay.style.display = "block";
        elOverlay.style.position = "absolute";
        elOverlay.style.width = "100%";
        elOverlay.style.height = "100%";
        elOverlay.style.top = "0px";
        elOverlay.style.bottom = "0px";
        elOverlay.style.left = "0px";
        elOverlay.style.right = "0px";
        elOverlay.style.background = "transparent";
        elOverlay.style['border-width'] = "0";
        elOverlay.style.margin = "0";
        elOverlay.style.padding = "0";
        elOverlay.appendChild(canvasOverlay);
        elOverlay.source = olTeamBackgroundEditor;

        var elContainer = document.createElement('div');
        elContainer.classList.add("simpleimageeditor");
        elContainer.classList.add("preview");
        elContainer.style.display = "block";
        elContainer.style.position = "relative";
        elContainer.style.background = "transparent";
        elContainer.style.border = "0 solid transparent";
        elContainer.style.margin = "0",
        elContainer.style.padding = "0";
        elContainer.style.width = "100%";
        elContainer.style.height = "100%";
        
        var elImageWrapper = document.createElement('div');
        elImageWrapper.classList.add("simpleimageeditor");
        elImageWrapper.classList.add("imagewrapper");
        
        $('.ol-team-background-editor .render').append(elImageWrapper);
        elImageWrapper.appendChild(elImage);

        elContainer.appendChild(elOverlay);
        elContainer.source = olTeamBackgroundEditor;
        element.appendChild(elContainer);
        olTeamBackgroundEditor.preview = {
            'container': elContainer,
            'overlay': elOverlay,
            'image': elImage
        };

        const canvasBlur = document.getElementsByClassName('canvasBlur');
        canvasBlur[0].height = 308;
        canvasBlur[0].width = 1903;
        canvas = canvasBlur[0];
        
        renderOverlay()
        
        return elOverlay;
    };
    
    olTeamBackgroundEditor.refreshRender = function() {
        renderOverlay();
    };
    
    function renderOverlay() {
        if (!canvas) return;
        
        if (decoration.pattern) {
            $('.editor-background-buttons').addClass('show-pattern');
        } else {
            $('.editor-background-buttons').removeClass('show-pattern');
        }
        
        var imageBaseHeight = 308;
        var imageBaseWidth = olConfig.contentWidth;
        var parentsWidth = $('.ol-team-background-editor').outerWidth();
        var parentsHeight = $('.ol-team-background-editor').outerHeight();
        
        canvas.width = parentsWidth;
        
        var size = Math.min(1.0, parentsHeight / imageBaseHeight);
        offsetLeft = parseInt((parentsWidth - imageBaseWidth * size) * 0.5);
        var visibleImageWidth = parentsWidth > (imageBaseWidth * size) ? (imageBaseWidth * size) : parentsWidth;
        var color = decoration.color;
        var primaryColorArray = olGUI.convertRGBStringToArray(color);
        var darkColorFactor = 1;
        var darkColorArray = [parseInt(primaryColorArray[0] * darkColorFactor), parseInt(primaryColorArray[1] * darkColorFactor), parseInt(primaryColorArray[2] * darkColorFactor)];
        
        var ctx = canvas.getContext("2d");
        ctx.globalAlpha = 1;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // create backing canvas
        var gradientCanvas = document.createElement('canvas');
        gradientCanvas.width = canvas.width;
        gradientCanvas.height = canvas.height;
        var gradientContext = gradientCanvas.getContext('2d');
        var resizeGradientCanvas = document.createElement('canvas');
        resizeGradientCanvas.width = 800 * size;
        resizeGradientCanvas.height = imageBaseHeight * size;
        var resizeGradientContext = resizeGradientCanvas.getContext('2d');
        
        if (decoration.pattern) {     
            resizeGradientContext.fillStyle = "black";
            resizeGradientContext.fillRect(0,0, 800 * size, imageBaseHeight * size)
            resizeGradientContext.drawImage(patternRepeat, 0, 0, 800 * size, imageBaseHeight * size);
            
            if (decoration.color == "rgb(255,255,255)") {
                resizeGradientContext.globalCompositeOperation='difference';
                resizeGradientContext.fillStyle='white';
                resizeGradientContext.fillRect(0,0,canvas.width,canvas.height);
            }
        } else {
            resizeGradientContext.fillStyle = decoration.color;
            resizeGradientContext.fillRect(0,0, 800 * size, imageBaseHeight * size)
        }
        
        var pat = gradientContext.createPattern(resizeGradientCanvas, "repeat");
        var patternOffset = Math.abs(offsetLeft) - 800 * size;
        gradientContext.translate(offsetLeft, 0);
        gradientContext.rect(patternOffset, 0, canvas.width + (800 * size - offsetLeft), canvas.height);
        gradientContext.fillStyle = pat;
        gradientContext.fill();
        gradientContext.translate(-offsetLeft, 0);
        

        gradientContext.globalCompositeOperation = "destination-out";
        var gradient = gradientContext.createLinearGradient((offsetLeft < 0 ? 0 : offsetLeft), 0, (offsetLeft < 0 ? 0 : offsetLeft) + visibleImageWidth, 0);
        gradient.addColorStop(0, "rgba(255, 255, 255, 0.0)");
        gradient.addColorStop(0.1, "rgba(255, 255, 255, " + (0.5) + ")");
        gradient.addColorStop(0.25, "rgba(255, 255, 255, " + (1.0) + ")");
        gradient.addColorStop(0.75, "rgba(255, 255, 255, " + (1.0) + ")");
        gradient.addColorStop(0.9, "rgba(255, 255, 255, " + (0.5) + ")");
        gradient.addColorStop(1.0, "rgba(255, 255, 255, 0.0)");
        
        gradientContext.fillStyle = gradient;
        gradientContext.fillRect((offsetLeft < 0 ? 0 : offsetLeft), 0, (offsetLeft < 0 ? 0 : offsetLeft) + visibleImageWidth, canvas.height);


        
        // draw to final canvas

        ctx.globalCompositeOperation = "source-over";

        //ctx.fillStyle = "rgb(" + darkColorArray[0] + "," + darkColorArray[1] + "," + darkColorArray[2] + ")";
        //ctx.fillRect(0, 0, canvas.width, canvas.height);

        

        var transparent = "rgba(0,0,0,0)";
        
        gradient = ctx.createLinearGradient((offsetLeft < 0 ? 0 : offsetLeft), 0, (offsetLeft < 0 ? 0 : offsetLeft) + visibleImageWidth, 0);
        
        // https://stackoverflow.com/questions/30196043/how-to-display-a-smoother-gradient-in-html5-canvas
        function easeInOut(t) {return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1}
        for(var t = 0; t <= 1; t += 0.02) {    // convert linear t to "easing" t:
            gradient.addColorStop(t*0.2, "rgba(" + darkColorArray[0] + ", " + darkColorArray[1] + ", " + darkColorArray[2] + "," + (1-easeInOut(t)) + ")");
        }
        
        gradient.addColorStop(0.2, transparent);
        gradient.addColorStop(0.8, transparent);
        
        for(var t = 0; t <= 1; t += 0.02) {    // convert linear t to "easing" t:
            gradient.addColorStop(0.8+t*0.2, "rgba(" + darkColorArray[0] + ", " + darkColorArray[1] + ", " + darkColorArray[2] + "," + easeInOut(t) + ")");
        }
        
        ctx.fillStyle = "rgba(" + darkColorArray[0] + ", " + darkColorArray[1] + ", " + darkColorArray[2] + ", 1)";
        ctx.fillRect(0,0, offsetLeft, canvas.height);
        ctx.fillRect(canvas.width-offsetLeft, 0, canvas.width, canvas.height);
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        console.log("editor", canvas.width, canvas.width-offsetLeft)

        ctx.globalAlpha = (0.25);
        ctx.drawImage(gradientCanvas, 0, 0);
        
        $('.imagewrapper').css('left',offsetLeft+"px");
        $('.handler').css('left',offsetLeft+"px");
    }
    
    /*
     * Collect current settings and return as object
     */
    olTeamBackgroundEditor.getData = function() {
        var image = olTeamBackgroundEditor.preview.image;
       
        var imageRect = image.getBoundingClientRect()       
        var wrapperRect = $('.imagewrapper').get(0).getBoundingClientRect()
        
        var x = imageRect.x - wrapperRect.x;
        var y = imageRect.y - wrapperRect.y;
        
        var zoom = imageRect.width / image.naturalWidth;
        
        return {
            "position": [x, y],
            "zoom": zoom,
            "decoration": decoration
        }
    }
    
}(window.olTeamBackgroundEditor = window.olTeamBackgroundEditor || {}, jQuery));
