(function(playerOverview, $, undefined ) 
{    
    var callback = false;
    playerOverview.onClickPerformanceData = function(element, playerId, season, event, userId)
    {
        var elementId = 'collapse' + season + '_' + userId;
        var element = $('#' + elementId);
        if(element.html().length == 0)
        {
            loading = $(element).find("table");
            addLoadingAnimationToElement(loading, {size: "small"});

            element.getLoad('/player/performancedata/season', { playerId: playerId, season: season, userId: userId }, function()
            {
                element.collapse('toggle');
                removeLoadingAnimationFromElement(loading);
                var numberOfChildren = $('#'+elementId + ' .collapse-row').length;
                if(numberOfChildren >= 20)
                {
                    $('#'+elementId).addClass('ol-full-table');                    
                }              
                playerOverview.toggleLeagueCheckbox();
            });
            event.stopPropagation(); 
        }
        $('#'+elementId).toggleClass('closing');
    };
    
    playerOverview.toggleLeagueCheckbox = function()
    {
        var checked = $('#performanceToggle').is(':checked');
        var checkedMobile = $('#performanceToggleMobile').is(':checked');
        if(checked || checkedMobile)
        {
            $('.ol-player-performance-table-wrapper.ol-friendly').show();
            $('.ol-player-performance-table-matchdata.ol-global-stats').show();
            $('.ol-player-performance-table-matchdata.ol-league-stats').hide();            
            $('.ol-player-performance-overall-headline.ol-global-stats').show();
            $('.ol-player-performance-overall-headline.ol-league-stats').hide();            
        }
        else
        {
            $('.ol-player-performance-table-wrapper.ol-friendly').hide();
            $('.ol-player-performance-table-matchdata.ol-global-stats').hide();
            $('.ol-player-performance-table-matchdata.ol-league-stats').show();
            $('.ol-player-performance-overall-headline.ol-global-stats').hide();
            $('.ol-player-performance-overall-headline.ol-league-stats').show();
        }
    };

    playerOverview.loadSubPage = function(url, playerId) {
        var $anchor = $('#olTeamOverviewContent');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get(url+'?playerId='+playerId, function(html) {
            $anchor.html(html);
            removeLoadingAnimationFromElement($anchor);
            //add params to url
            addUrlParams('?playerId='+playerId+'&subPage='+url);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("***" + url + " failed");
        });
    }
}( window.playerOverview = window.playerOverview || {}, jQuery ));