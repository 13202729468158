"use strict";
(function(olSadm, $, undefined) {

    olSadm.page = '';



    olSadm.initPage = function() {
        //
    };
    

    function addLoadingAnimationToButton($btn, $anchor) {
        if ($anchor === undefined || !$anchor.length) $anchor = $('#sadmContainer');
        addLoadingAnimationToElement($anchor, {size: 'small', instant: true});
        $btn.addClass('ol-button-loading');
    }

    function removeLoadingAnimationFromButton($btn, $anchor) {
        if ($anchor === undefined || !$anchor.length) $anchor = $('#sadmContainer');
        removeLoadingAnimationFromElement($anchor);
        $btn.removeClass('ol-button-loading');
    }


    olSadm.onEditSponsor = function(event) {
        var $btn = $(event.target),
            sponsorId = $btn.attr('data-sponsorId');
        editSponsor($btn, sponsorId);
    };

    function editSponsor($btn, sponsorId) {
        var data = {sponsorId: sponsorId};
//        console.log('sadm/edit: data:', data);

        addLoadingAnimationToButton($btn);
        $.post('/sadm/edit', data, function(rc) {
            //removeLoadingAnimationFromButton($btn);
            var url = (sponsorId > 0)? '/#url=sponsor/settings/settings' : '/#url=sadm';
            location.assign(url);
            location.reload();

        }, 'json').fail(function() {
            removeLoadingAnimationFromButton($btn);
            console.log("*** editSponsor failed:", data);
            olDialog.message(sponsorId? '#sadmErrorOpEdit' : '#sadmErrorOpUnedit');
        });

    };


    olSadm.onDeactivateSponsor = function(event) {
        var $btn = $(event.target),
            sponsorId = $btn.attr('data-sponsorId');
        activateSponsor($btn, sponsorId, 'deactivate');
    };

    olSadm.onActivateSponsor = function(event) {
        var $btn = $(event.target),
            sponsorId = $btn.attr('data-sponsorId');
        activateSponsor($btn, sponsorId, 'activate');
    };

    function activateSponsor($btn, sponsorId, op) {
        var Op = (op == 'deactivate')? 'Deactivate' : 'Activate';
        var op2 = (op == 'deactivate')? 'activate' : 'deactivate';
        $btn2 = $btn.parent().find('.ol-sadm-sponsors-btn-'+op2);

        var data = {sponsorId: sponsorId};
        console.log('sadm/'+op+': data:', data);
        
        olDialog.confirmExt($btn, null, function() {
            addLoadingAnimationToButton($btn);
            $.post('/sadm/'+op, data, function(rc) {
                console.log('sadm/'+op+': rc:', rc);
                removeLoadingAnimationFromButton($btn);

                if (rc.err) {
                    olDialog.message('#sadmErrorOp'+Op, {'data-err': rc.err});
                    return;
                }
                
                $btn.prop('disabled', true);
                $btn2.prop('disabled', false);
                olSadm.filterSponsorList();

            }, 'json').fail(function() {
                removeLoadingAnimationFromButton($btn);
                console.log("*** activateSponsor("+op+") failed:", data);
                olDialog.message('#sadmErrorOp'+Op);
            });
        });
    }
    
    
    olSadm.onClickInvisibleForRandom = function(event) {
        var $btn = $(event.target),
            sponsorId = $btn.attr('data-sponsorId'),
            invisible = $btn.hasClass('fa-eye-slash');
//console.log('olSadm.onClickInvisibleForRandom: sponsorId='+sponsorId+', invisible='+invisible);
        var data = {sponsorId: sponsorId, invisible: invisible? 0 : 1};
//console.log('olSadm.onClickInvisibleForRandom: data:', data);
        addLoadingAnimationToButton($btn);
        $.post('/sadm/invisible', data, function(rc) {
//console.log('rc:',rc);
            removeLoadingAnimationFromButton($btn);
            $btn.removeClass('fa-eye fa-eye-slash').addClass(rc.invisible? 'fa-eye-slash' : 'fa-eye');
            
        }, 'json').fail(function(error) {
            removeLoadingAnimationFromButton($btn);
            console.log('*** /sadmin/invisible failed:', error);
            olDialog.message('#sadmErrorOpInvisible');
        })
    };


    olSadm.onCreateSponsor = function(event) {
        var $btn = $(event.target);
        createSponsorDialog($btn);
    };
    
    var createForm = {
        
        getData: function() {
            return {
                email: $('#olSadmCreateFormEmail').val(),
                name: $('#olSadmCreateFormName').val(),
                bannerUrl: $('#olSadmCreateFormBannerURL').val(),
                url: $('#olSadmCreateFormUrl').val(),
                pool: $('#olSadmCreateFormSponsorPoolId').val()
            };
        },
        
        copyDataToAssistantData: function() {
            var assistant = $('#olDialogParam').data('assistant');
            var data = this.getData();
            for (var key in data) {
                assistant.data[key] = data[key];
            }
        },
        
        setValid: function(valid) {
            if (valid) {
                $('#olSadmCreateFormEmailError').html('');
                $('#olSadmCreateFormNameError').html('');
            }
            this.disableNext(!valid);
        },
        
        setErrors: function(errors) {
            if (!errors) errors = {};
            
            var $emailError = $('#olSadmCreateFormEmailError'), 
                $nameError = $('#olSadmCreateFormNameError');
            var emailErr = errors.email || '',
                nameErr = errors.name || '',
                valid = !emailErr && !nameErr;
            $emailError.html(emailErr);
            $nameError.html(nameErr);
            this.setValid(valid);
            return !valid;
        },
        
        disableNext: function(disabled) {
            var assistant = $('#olDialogParam').data('assistant');
            assistant.disableNext(disabled);
        },
        
        onChange: function(event) {
            var data = this.getData();
            var valid = data.name;
            this.setValid(valid);
        }
        
    };
    
    function createSponsorDialog($btn) {
        olDialog.assistant(['#sadmCreateForm'], null, {
            data: {
                email: '',
                name: '',
                firstName: '', //unused
                lastName: '' //unused
            },
            success: function() {
                createSponsorWith($btn, this.data);
            },
            cancel: null,
            init: function() {
                createForm.disableNext(true);
            },
            next: function() {
                createForm.copyDataToAssistantData();
                $.post('/sadm/create/check', this.data, function(rc) {
//                    console.log('sadm/create/check:', rc);
                    createForm.setErrors(rc.errors);
                    if (rc.ok) this.gotoSuccess();
                }.bind(this));
            },
            navMode: 'none'
        });
    }

    olSadm.onChangeCreateFormField = function(event) {
        createForm.onChange(event);
    };
    
    function createSponsorWith($btn, data) {
//        console.log('createSponsorWith: ', data);

        addLoadingAnimationToButton($btn);
        $.post('/sadm/create', data, function(rc) {
            removeLoadingAnimationFromButton($btn);
//            console.log('sadm/create: rc:', rc);

            if (rc.err) {
                olDialog.messageExt('#sadmErrorOpCreate', {"data-err": rc.err});
                return;
            }

            console.log("data", data);
            if (data.pool && data.pool != -1 && data.pool != "-1") {
                olAnchorNavigation.reload();
            } else {
                editSponsor($btn, rc.sponsorId);
            }

        }, 'json').fail(function() {
            removeLoadingAnimationFromButton($btn);
            console.log("*** createSponsorWith failed:", data);
            olDialog.messageExt('#sadmErrorOpCreate');
        });

    };
    
    
    olSadm.filterSponsorList = function(event) {
        var $target = event? $(event.target) : null,
            $parent = $target? $target.parent() : null,
            timeout = $target? $target.attr('data-timeout') || 0 : 0;
        
        var liField = '', liValue;
        if ($parent && $parent.is('li')) {
            liField = $parent.attr('data-field') || '';
            liValue = $parent.attr('data-value');
        }
        
        var data = {
            active: (liField == 'active')? liValue : $('#olSadmFilterActive').dropdown().value(),
            name: $('#olSadmFilterName').val(),
            region: (liField == 'region') ? liValue : $('#olSadmFilterRegion').dropdown().value(),
            pools: (liField == 'pools') ? liValue : $('#olSadmFilterPool').dropdown().value()
        };
console.log("Filter: ", data);
        
        filterSponsorListQueue.add(data, timeout);
    };
    
    var filterSponsorListQueue = {
        _xparam: null,
        _timer: null,
        _timeout: 2000,
        
        clear: function() {
            this._xparam = null;
            if (this._timer) {
                clearTimeout(this._timer);
                this._timer = null;
            }
        },
        
        add: function(data, timeout) {
//console.log("*** add ("+timeout+"):", data)
            if (timeout === undefined) timeout = this._timeout;
            
            var xparam = this._toXparam(data);
            if (xparam === null) return;
            if (this._xparam && (xparam.isBefore(this._xparam) || xparam.equals(this._xparam))) return;

            this.clear();
            this._xparam = xparam;
            this._timer = setTimeout(this._sendRequest.bind(this), timeout);
        },
        
        _sendRequest: function() {
            if (!this._xparam) return;
//console.log("--> Request @"+this._xparam.data.timestamp+":", this._xparam.data);
            
            var $table = $('#sadmSponsorList');
            $anchor = $table;
            addLoadingAnimationToElement($anchor, {size: 'small', instant: true});
            $.get('/sadm/list', this._xparam.data, function(rc) {
//console.log("--- Request @"+this._xparam.data.timestamp+": ", this._xparam.data);
//console.log("<-- Response @"+rc.timestamp);
                if (this._xparam && rc.timestamp == this._xparam.data.timestamp) {
//                    console.log("<<< Response valid")
                    $table.html(rc.html);
                    this.clear();
//                } else {
//                    var reason = !this._xparam?                            'no pending request'
//                               : !rc.timestamp?                            "response timestamp missing"
//                               : Number.isNaN(Number(rc.timestamp))?       "response timestamp'"+rc.timestamp+"' invalid"
//                               : (rc.timestamp != this._xparam.timestamp)? "'"+rc.timestamp+" != '"+this._xparam.data.timestamp+"'"
//                               :                                           "unknown reason";
//console.log("<<< Response ignored: "+reason);
                }
                removeLoadingAnimationFromElement($anchor);

            }.bind(this), 'json').fail(function() {
                removeLoadingAnimationFromElement($anchor);
                console.log("*** filterSponsorList failed:", this._xparam.data);
                this.clear();
            }.bind(this));            
        },
        
        _toXparam: function(data) {
            if (!data || typeof data !== 'object') return null;
            return {
                data: {
                    active: data.active,
                    name: data.name,
                    region: data.region,
                    timestamp: Date.now(),
                    pools: data.pools
                },
                
                equals: function(xparam) {
                    return (this.data.active == xparam.active)
                        && (this.data.name == xparam.name)
                        && (this.data.region == xparam.region);
                },
                
                isBefore: function(xparam) {
                    return (this.data.timestamp < xparam.timestamp);
                }
            };
        }
    };
    
    
    olSadm.onReserveInvoiceId = function(event) {
        var $btn = $(event.target);
        addLoadingAnimationToButton($btn);
        $.post('/sadm/invoice/reserve', {}, function(rc) {
            removeLoadingAnimationFromButton($btn);
            showInvoiceId(rc? rc.invoiceId : '');
        }).fail(function() {
            removeLoadingAnimationFromButton($btn);
            console.log('*** olSadm.onReserveInvoiceId failed');
            showInvoiceId('');
        });
    };
    
    function showInvoiceId(invoiceId) {
        if (invoiceId) {
            olDialog.message('#sadmSuccessReserveInvoiceId', {"data-invoiceid": invoiceId});
        } else {
            olDialog.message('#sadmErrorReserveInvoiceId');
        }
    }

    olSadm.createSponsorPool = function(event) {
        var $btn = $(event.target);
        addLoadingAnimationToButton($btn);
        $.post('/sadm/pools/create', {name: $('.ol-sadm-pool-create-name').val(), factor: $('.ol-sadm-pool-create-factor').val()}, function(rc) {
            removeLoadingAnimationFromButton($btn);
            olOverlayWindow.load('/sadm/pools');
        }).fail(function() {
            removeLoadingAnimationFromButton($btn);
            console.log('*** olSadm.addSponsorPool failed');
        });
    }

    olSadm.deleteSponsorPool = function(event) {
        var $btn = $(event.target);
        addLoadingAnimationToButton($btn);
        $.post('/sadm/pools/delete', {id: $btn.attr('data-pool-id')}, function(rc) {
            removeLoadingAnimationFromButton($btn);
            olOverlayWindow.load('/sadm/pools');
        }).fail(function() {
            removeLoadingAnimationFromButton($btn);
            console.log('*** olSadm.deleteSponsorPool failed');
        });
    }

    olSadm.addSponsorToPool = function(sponsorInfoId, sponsorPoolId) {
        $.post('/sadm/pools/assign', {sponsorInfoId: sponsorInfoId, sponsorPoolId: sponsorPoolId}, function(rc) {
            olMsgBox.msg('Erfolg', 'Pool erfolgreich geändert.');
        }).fail(function() {
            olMsgBox.msg('Fehler', 'Pool konnte nicht geändert werden.');
            console.log('*** olSadm.deleteSponsorPool failed');
        });
    }

    olSadm.onChangeSponsorPoolFactor = function(el) {
        $(el).addClass('disabled');
        $.post('/sadm/pools/modify', { id: $(el).attr('data-pool-id'), factor: $(el).val() }, function(rc) {
            $(el).removeClass('disabled');
            $(el).parent().parent().find('i').css('opacity', 1).animate({ 'opacity': 0 }, 5000); 
        }).fail(function() {
            olMsgBox.msg('Fehler', 'Pool konnte nicht geändert werden.');
        });
    }

    olSadm.onChangeSponsorPoolActive = function(el) {
        if (el.checked) {
            $.post('/sadm/pools/activate', {id: $(el).attr('data-pool-id')}, function(rc) {
            }).fail(function() {
                olMsgBox.msg('Fehler', 'Pool konnte nicht geändert werden.');
            });
        } else {
            $.post('/sadm/pools/deactivate', {id: $(el).attr('data-pool-id')}, function(rc) {
            }).fail(function() {
                olMsgBox.msg('Fehler', 'Pool konnte nicht geändert werden.');
            });
        }

    } 
    var mobileOnlyLastValue;
    olSadm.onChangeSponsorPoolMobileOnly = function(el) {
        if (!mobileOnlyLastValue) {
            mobileOnlyLastValue = $(el).find('option[selected]').val();
        }
        var val = $(el).val();
        el.disabled = true;
        $.post('/sadm/pools/mobile/set', { id: $(el).attr('data-pool-id'), mobileOnlySetting: $(el).val() }, function(rc) {
            mobileOnlyLastValue = val;
        }).fail(function() {
            el.value = mobileOnlyLastValue;
            olMsgBox.msg('Fehler', 'Pool konnte nicht geändert werden.');
        }).always(function() {
            el.disabled = false;
        });
    }
    
}(window.olSadm = window.olSadm || {}, jQuery));
