// TODO Move to namespace object

var oldDataInitialCount = 0;
var oldRoofNumber = 0;
var oldBoxNumber = 0;
var olStadiumEditorCall = 0;

$.fn.tooltipApply = function() {
    $(this).attr('data-toggle', 'tooltip');
    var title = $('#curveroofcheckmessage').val();
    $(this).attr('data-original-title', title);
    /*$('[data-toggle="tooltip"]').tooltip({
        template: '<div class="tooltip tooltip-custom-class"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
    });*/
};

$.fn.tooltipRemove = function() {
    $(this).attr('data-toggle', '');
    $(this).attr('data-original-title', '');
    //$('[data-toggle="tooltip"]').tooltip({
    //    template: '<div class="tooltip tooltip-custom-class"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
    //});
};

function initializeConfigurator(config) {
    if (config === undefined) config = {currencySymbol: '€', currencySymbolAfter: 1};

    $(function(eReady) {
        manageContentHeight();
        reloadArchitecturePreview();
        calculateAllCapacity();
        var oldData = '';
        var stadiumNameTimer;
        $('#stadiumName')
        .on('focus', function(e) {
            oldData = $(this).val();
            $(this).select();
        })
        .on('change', function(e) {
            if ($(this).val().trim() != '') {
                if ($('#newStadiumFlg').val() == 0) {
                    var thisObj = $(this);
                    clearTimeout(stadiumNameTimer);
                    stadiumNameTimer = setTimeout(function() {
                        var stadiumId = $('#stadiumId').val();
                        var stadiumName = $(thisObj).val();
                        var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
                        var url = '';
                        if (typeof updateStadiumNameURL != 'undefined') {
                            url = updateStadiumNameURL;
                        }

                        $.ajax({
                            url: url,
                            type: 'POST',
                            data: {
                                _token: CSRF_TOKEN,
                                stadiumId : stadiumId,
                                stadiumName : stadiumName,
                            },
                            dataType: 'JSON',
                            success: function(data) { 
                                //console.log(data);
                            },
                            error: function(jqXHR, exception) {
                                console.log("exception", exception);
                            }
                        });
                    }, 1000);
                }
            } else {
                $(this).val(oldData);
            }
        })
        .on('keyup', function(e) {
            if (![16, 17, 18].includes(e.which)) {
                if ($(this).val() != '') {
                    $(this).trigger('change');
                }
            }
        });

        $('.businessButton').on('click', function(e) {
            var elementID = $(this).attr('id');
            var standCode = elementID.replace('stadium_business_button_', '');

            if ($('#stadium_business_range_' + standCode).attr('disabled')) {
                return false;
            }

            if ($(this).find('.buttonStatus').hasClass('fa-plus')) {
                $('#stadium_business_row_' + standCode)
                .removeClass('d-none')
                .hide()
                .fadeIn(300);

                $('#stadium_business_hr_row_' + standCode).css('display', 'block');
                $('#stadium_row_' + standCode).addClass('border-bottom-none');
                $('#stadium_business_row_' + standCode).addClass('border-top-none');
                if ($('#stadium_box_button_' + standCode).find('.buttonStatus').hasClass('fa-minus')) {
                    $('#stadium_business_row_' + standCode).addClass('border-bottom-none');
                }
                
                $(this).find('.buttonStatus').removeClass('fa-plus').addClass('fa-minus');
            } else {
                $('#stadium_business_row_' + standCode)
                .addClass('d-none')
                .hide();

                $('#stadium_business_hr_row_' + standCode).hide();
                $('#stadium_business_row_' + standCode).removeClass('border-top-none');
                $('#stadium_business_row_' + standCode).removeClass('border-bottom-none');

                if (!$('#stadium_box_button_' + standCode).find('.buttonStatus').hasClass('fa-minus')) {
                    $('#stadium_row_' + standCode).removeClass('border-bottom-none');
                }
                
                $(this).find('.buttonStatus').addClass('fa-plus').removeClass('fa-minus');

                $('#stadium_business_range_' + standCode).val(0);
                $('#stadium_business_range_' + standCode).myRangeInput();
            }
        });

        $('.boxButton').on('click', function(e) {
            var elementID = $(this).attr('id');
            var standCode = elementID.replace('stadium_box_button_', '');

            if ($('#stadium_box_range_' + standCode).attr('disabled') || $('#stadium_box_range_' + standCode).attr('data-minValue') > 0 || $(this).hasClass('noCollapse')) {
                return false;
            }

            if ($(this).find('.buttonStatus').hasClass('fa-plus')) {
                $('#stadium_box_row_' + standCode)
                .removeClass('d-none')
                .hide()
                .fadeIn(300);

                $('#stadium_box_hr_row_' + standCode).css('display', 'block');
                $('#stadium_row_' + standCode).addClass('border-bottom-none');
                $('#stadium_box_row_' + standCode).addClass('border-top-none');
                $('#stadium_business_row_' + standCode).addClass('border-bottom-none');
                
                $(this).find('.buttonStatus').removeClass('fa-plus').addClass('fa-minus');

                if ($(this).hasClass('boxInclusive')) {
                    //$(this).addClass('noCollapse');
                }
            } else {
                $('#stadium_box_row_' + standCode)
                .addClass('d-none')
                .hide();

                $('#stadium_box_hr_row_' + standCode).hide();
                $('#stadium_box_row_' + standCode).removeClass('border-top-none');
                $('#stadium_business_row_' + standCode).removeClass('border-bottom-none');
                if (!$('#stadium_business_button_' + standCode).find('.buttonStatus').hasClass('fa-minus')) {
                    $('#stadium_row_' + standCode).removeClass('border-bottom-none');
                }
                
                $(this).find('.buttonStatus').addClass('fa-plus').removeClass('fa-minus');

                $('#stadium_box_range_' + standCode).val(0);
                $('#stadium_box_range_' + standCode).myRangeInput();
            }
        });

        $('.roofCheck').on('click', function(e) {
            var elementID = $(this).attr('id');
            var standCode = elementID.replace('stadium_roof_', '');

            if ($(this).hasClass('disabled-checkbox')) {
                return false;
            }

            if (!$('#stadium_roof_' + standCode)[0].checked) {
                if ($(this).hasClass('roofBuilt')) {
                    return false;
                }
            }

            //----Roof and Box construction at a time restriction start
            var boxChangeFlg = false;
            for (var dependentStandCode in stadiumModificationData) {
                if (
                    stadiumModificationData.hasOwnProperty(dependentStandCode) &&
                    stadiumModificationData[dependentStandCode]['modRoofDependence'].length &&
                    $('#stadium_range_' + dependentStandCode).attr('data-initial') != 1 &&
                    dependentStandCode == standCode
                ) {
                    for (var key in stadiumModificationData[dependentStandCode]['modRoofDependence']) {
                        if (stadiumModificationData[dependentStandCode]['modRoofDependence'].hasOwnProperty(key)) {
                            var targetShortCode = stadiumModificationData[dependentStandCode]['modRoofDependence'][key];
                            if ($('#stadium_range_' + targetShortCode).attr('data-initial') != 1) {
                                var oldBoxValue = $('#stadium_old_box_' + targetShortCode).val();
                                oldBoxValue = oldBoxValue == '' || isNaN(oldBoxValue) ? 0 : parseInt(oldBoxValue);
                                var currentBoxValue = $('#stadium_box_range_' + targetShortCode).val();
                                currentBoxValue = currentBoxValue == '' || isNaN(currentBoxValue) ? 0 : parseInt(currentBoxValue);
                                if (oldBoxValue != currentBoxValue && $('#svg_input_' + targetShortCode).val() > 2) {
                                    boxChangeFlg = true;
                                }
                            }
                        }
                    }
                }
            }
            if (boxChangeFlg == true || $('#svg_input_' + standCode).val() > 2) {
                var oldBoxValue = $('#stadium_old_box_' + standCode).val();
                oldBoxValue = oldBoxValue == '' || isNaN(oldBoxValue) ? 0 : parseInt(oldBoxValue);
                var currentBoxValue = $('#stadium_box_range_' + standCode).val();
                currentBoxValue = currentBoxValue == '' || isNaN(currentBoxValue) ? 0 : parseInt(currentBoxValue);

                var oldHasRoof = $('#stadium_old_roof_' + standCode).val();
                oldHasRoof = oldHasRoof == '' || isNaN(oldHasRoof) ? 0 : parseInt(oldHasRoof);
                var currentHasRoof = $('#stadium_roof_' + standCode)[0].checked;

                if ((boxChangeFlg == true || oldBoxValue != currentBoxValue) && oldHasRoof != currentHasRoof) {
                    return false;
                }
            }
            //----Roof and Box construction at a time restriction end

            //----Roof checking from DB start
            var isRoof = $(this)[0].checked;
            for (var dependentStandCode in stadiumModificationData) {
                if (
                    stadiumModificationData.hasOwnProperty(dependentStandCode) &&
                    stadiumModificationData[dependentStandCode]['modRoofDependence'].length &&
                    $('#stadium_range_' + dependentStandCode).attr('data-initial') != 1 &&
                    dependentStandCode == standCode
                ) {
                    for (var key in stadiumModificationData[dependentStandCode]['modRoofDependence']) {
                        if (stadiumModificationData[dependentStandCode]['modRoofDependence'].hasOwnProperty(key)) {
                            var targetShortCode = stadiumModificationData[dependentStandCode]['modRoofDependence'][key];
                            if ($('#stadium_range_' + targetShortCode).attr('data-initial') != 1) {
                                $('#stadium_roof_' + targetShortCode)[0].checked = isRoof;
                            }
                        }
                    }
                }
            }
            //----Rood checking from DB end

            roofBuildChecking();

            if ($('#stadium_paid_span_' + standCode).length) {
                $('#stadium_paid_span_' + standCode).hide();
            }
            $('#stadium_cost_span_' + standCode).removeClass('d-none');

            calculateAllCapacity();

            if (checkStandConfiguration($('#stadium_range_' + standCode))) {
                $('#stadiumConfiguratorSave').removeClass('disabled-button');
            } else {
                $('#stadiumConfiguratorSave').addClass('disabled-button');
            }
        });

        $('.myRange').on('input', function(e, checkmarkFlg) {
            var elementID = $(this).attr('id');
            var standCode = elementID.replace('stadium_range_', '');

            var thisObj = $(this);
            if ($(thisObj).attr('data-initial') == 1) {
                var parentRow = $(thisObj).parent().parent().parent().parent();
                var dataID = $(parentRow).attr('data-id');

                //----stadium seat allocation start
                $(thisObj).attr('data-initial', 0);

                if (!checkmarkFlg) {
                    //----Roof checking from DB start
                    for (var dependentStandCode in stadiumModificationData) {
                        if (
                            stadiumModificationData.hasOwnProperty(dependentStandCode) &&
                            stadiumModificationData[dependentStandCode]['modRoofDependence'].length &&
                            $('#stadium_range_' + dependentStandCode).attr('data-initial') != 1
                        ) {
                            var isRoof = $('#stadium_roof_' + dependentStandCode)[0].checked;
                            var hasRoofBuilt = $('#stadium_roof_' + dependentStandCode).hasClass('roofBuilt');
                            for (var key in stadiumModificationData[dependentStandCode]['modRoofDependence']) {
                                if (stadiumModificationData[dependentStandCode]['modRoofDependence'].hasOwnProperty(key)) {
                                    var targetShortCode = stadiumModificationData[dependentStandCode]['modRoofDependence'][key];
                                    if ($('#stadium_range_' + targetShortCode).attr('data-initial') != 1) {
                                        $('#stadium_roof_' + targetShortCode)[0].checked = isRoof;
                                        if (hasRoofBuilt) {
                                            $('#stadium_roof_' + targetShortCode).addClass('roofBuilt');
                                        }
                                    }
                                }
                            }
                        }
                    }
                    //----Rood checking from DB end
                }

                $('#stadium_deselect_icon_' + standCode).show();

                setTimeout(function() {
                    $('.svgStadiumBlock').each(function() {
                        if ($(this).attr('data-id') == dataID) {
                            $(this).attr('data-deselect', 1);
                        }
                    });
                }, 100);

                $(thisObj).myRangeInput();
                //----stadium seat allocation end
            } else {
                $(thisObj).myRangeInput();
            }
        });

        $('.businessRange').on('input', function(e) {
            $(this).myRangeInput();
        });

        $('.myBoxRange').on('input', function(e) {
            $(this).myRangeInput();
        });

        $('.stadiumInputField').on('change', function(e) {
            var val = $(this).val();
            if ($(this).hasClass('rangeValueShowSeat')) {
                val = Math.floor(val*=1.5);
            }
            if ($(this).hasClass('rangeValueShowStand')) {
                val = $(this).attr('max')-val;
            }
            var range = $(this).parents('.range-slidecontainer').find('input[type="range"]')
            range.val(val);
            range.myRangeInput();
        })

        $('.stadiumInputField').on('focus click', function(e) {
            $(this).select();
        })

        $.fn.myRangeInput = function() {
            var factorSeats = constantData.FACTOR_SEATS;
            factorSeats = factorSeats == '' || isNaN(factorSeats) ? 0 : parseFloat(factorSeats);
            
            var factorBusiness = constantData.FACTOR_BUSINESS;
            factorBusiness = factorBusiness == '' || isNaN(factorBusiness) ? 0 : parseFloat(factorBusiness);
            
            var factorBox = constantData.FACTOR_BOX;
            factorBox = factorBox == '' || isNaN(factorBox) ? 0 : parseFloat(factorBox);

            var elementID = $(this).attr('id');
            var standCode = '';

            var seatsInBox = $('#seatsInBox').val();
            seatsInBox = seatsInBox == '' || isNaN(seatsInBox) ? 0 : parseInt(seatsInBox);

            if ($(this).hasClass('myRange')) {
                standCode = elementID.replace('stadium_range_', '');
            } else if ($(this).hasClass('businessRange')) {
                standCode = elementID.replace('stadium_business_range_', '');
            } else if ($(this).hasClass('myBoxRange')) {
                standCode = elementID.replace('stadium_box_range_', '');

                var currentBoxValue = $('#stadium_box_range_' + standCode).val();
                    currentBoxValue = currentBoxValue == '' || isNaN(currentBoxValue) ? 0 : parseInt(currentBoxValue);

                //----Box demolisation restriction start
                var dataMinValue = $('#stadium_box_range_' + standCode).attr('data-minValue');
                dataMinValue = dataMinValue == '' || isNaN(dataMinValue) ? 0 : parseInt(dataMinValue);
                if (currentBoxValue < dataMinValue) {
                    $('#stadium_box_range_' + standCode).val(dataMinValue);
                }
                //----Box demolisation restriction end

                //----Roof and Box construction at a time restriction start
                if ($('#svg_input_' + standCode).val() > 2) {
                    var oldBoxValue = $('#stadium_old_box_' + standCode).val();
                    oldBoxValue = oldBoxValue == '' || isNaN(oldBoxValue) ? 0 : parseInt(oldBoxValue);

                    var oldHasRoof = $('#stadium_old_roof_' + standCode).val();
                    oldHasRoof = oldHasRoof == '' || isNaN(oldHasRoof) ? 0 : parseInt(oldHasRoof);
                    var currentHasRoof = $('#stadium_roof_' + standCode)[0].checked;

                    if (oldHasRoof != currentHasRoof && oldBoxValue != currentBoxValue) {
                        $('#stadium_box_range_' + standCode).val(oldBoxValue);
                    }
                }
                //----Roof and Box construction at a time restriction end
            }

            var rangeValueShowStand = $('#stadium_stand_value_' + standCode);
            var rangeValueShowSeat = $('#stadium_seat_value_' + standCode);

            var inputStandValue = $(rangeValueShowStand).val();
            inputStandValue = inputStandValue.replace(/[.,]/g, '');
            inputStandValue = inputStandValue == '' || isNaN(inputStandValue) ? 0 : parseInt(inputStandValue);
            var inputSeatValue = $(rangeValueShowSeat).val();
            inputSeatValue = inputSeatValue.replace(/[.,]/g, '');
            inputSeatValue = inputSeatValue == '' || isNaN(inputSeatValue) ? 0 : parseInt(inputSeatValue);

            var businessRangeValue = $('#stadium_business_range_' + standCode).length ? $('#stadium_business_range_' + standCode).val() : '0';
            businessRangeValue = businessRangeValue.replace(/[.,]/g, '');
            businessRangeValue = businessRangeValue == '' || isNaN(businessRangeValue) ? 0 : parseInt(businessRangeValue);
            var boxRangeValue = $('#stadium_box_range_' + standCode).length ? $('#stadium_box_range_' + standCode).val() : '0';
            boxRangeValue = boxRangeValue.replace(/[.,]/g, '');
            boxRangeValue = boxRangeValue == '' || isNaN(boxRangeValue) ? 0 : parseInt(boxRangeValue);

            var rangeObj = $('#stadium_range_' + standCode);
            if (inputStandValue == 0 && inputSeatValue == 0 && businessRangeValue == 0 && boxRangeValue == 0) {
                var defaultStandSplit = $(rangeObj).attr('data-defaultStandSplit');
                defaultStandSplit = defaultStandSplit == '' || isNaN(defaultStandSplit) ? 0 : parseFloat(defaultStandSplit);
                if (defaultStandSplit > 0) {
                    var rangeObjMax = $(rangeObj).attr('max');
                    rangeObjMax = rangeObjMax == '' || isNaN(rangeObjMax) ? 0 : parseFloat(rangeObjMax);
                    var defaultSeatSplit = (1 - defaultStandSplit);
                    var defaultStandSplitValue = rangeObjMax * defaultSeatSplit;
                    $(rangeObj).val(defaultStandSplitValue);

                    if ($('#stadium_box_range_' + standCode).length && $('#boxInclusive').val() == 1) {
                        setTimeout(function() {
                            //$('#stadium_box_button_' + standCode).trigger('click');
                            $('#stadium_box_button_' + standCode).addClass('boxInclusive');
                            $('#stadium_box_range_' + standCode).attr('min', $('#stadium_box_range_' + standCode).val());
                        }, 300);
                        $('#stadium_box_range_' + standCode).val($('#stadium_box_range_' + standCode).attr('max'));
                    }
                }
            } else if (inputStandValue == 0 && inputSeatValue == 0) {
                $(rangeObj).val(0);
            }

            var value = $(rangeObj).val();
            value = value == '' || isNaN(value) ? 0 : parseInt(value);
            
            var maxCapacity = $('#stadium_max_capacity_' + standCode).val();
            maxCapacity = maxCapacity == '' || isNaN(maxCapacity) ? 0 : parseInt(maxCapacity);

            var standValueMin = $(rangeValueShowStand).attr('min');
            standValueMin = standValueMin == '' || isNaN(standValueMin) ? 0 : parseInt(standValueMin);
            var standValueMax = $(rangeValueShowStand).attr('max');
            standValueMax = standValueMax == '' || isNaN(standValueMax) ? 0 : parseInt(standValueMax);

            
            var seatValueMin = $(rangeValueShowSeat).attr('min');
            seatValueMin = seatValueMin == '' || isNaN(seatValueMin) ? 0 : parseInt(seatValueMin);
            var seatValueMax = $(rangeValueShowSeat).attr('max');
            seatValueMax = seatValueMax == '' || isNaN(seatValueMax) ? 0 : parseInt(seatValueMax);

            var rangeMin = $(rangeObj).attr('min');
            rangeMin = rangeMin == '' || isNaN(rangeMin) ? 0 : parseInt(rangeMin);
            var rangeMax = $(rangeObj).attr('max');
            rangeMax = rangeMax == '' || isNaN(rangeMax) ? 0 : parseInt(rangeMax);

            var standValue = standValueMax - value;

            if (standValue < 0) {
                standValue = 0;
            }
            
            if (inputSeatValue == 0 && !this.is(rangeObj)) {
                standValue = maxCapacity
            };
            
            var seatValue = Math.round((maxCapacity - standValue) / factorSeats);
            
            
            var businessSeatValue = 0;
            if ($('#stadium_business_row_' + standCode).length) {
                var businessRangeShow = $('#stadium_business_value_' + standCode);
                
                var businessRange = $('#stadium_business_range_' + standCode);
                businessSeatValue = $(businessRange).val();
                businessSeatValue = businessSeatValue == '' || isNaN(businessSeatValue) ? 0 : parseInt(businessSeatValue);

                seatValue = Math.round(((seatValue * factorSeats) - (businessSeatValue * factorBusiness)) / factorSeats);

                var businessSeatValueTxt = numberFormat(businessSeatValue);
                $('#stadium_business_value_' + standCode).val(businessSeatValueTxt);

                var seatBusinessValue = Math.round(businessSeatValue * factorBusiness);
                var seatBusinessValueTxt = numberFormat(seatBusinessValue);
                $('#stadium_seat_business_value_' + standCode).val(seatBusinessValueTxt);
            }

            var boxSeatValue = 0;
            if ($('#stadium_box_row_' + standCode).length) {
                var boxRangeShow = $('#stadium_box_value_' + standCode);
                
                var boxRange = $('#stadium_box_range_' + standCode);
                boxSeatValue = $(boxRange).val();
                boxSeatValue = boxSeatValue == '' || isNaN(boxSeatValue) ? 0 : parseInt(boxSeatValue);

                seatValue = Math.round(((seatValue * factorSeats) - (boxSeatValue * seatsInBox * factorBox)) / factorSeats);

                var boxSeatValueTxt = numberFormat(boxSeatValue);
                $('#stadium_box_value_' + standCode).val(boxSeatValueTxt);

                var seatsInBox = $('#seatsInBox').val();
                seatsInBox = seatsInBox == '' || isNaN(seatsInBox) ? 0 : parseInt(seatsInBox);
                var seatBoxValue = Math.round(boxSeatValue * seatsInBox);
                var seatBoxValueTxt = numberFormat(seatBoxValue);
                $('#stadium_seat_box_value_' + standCode).val(seatBoxValueTxt);
            }

            if (seatValue > seatValueMax) {
                seatValue = seatValueMax;
            }
            
            if (seatValue < 0) {
                standValue = Math.round(standValue - (((0 - seatValue) * factorSeats)));
                $(rangeObj).val(rangeMax - standValue);
                seatValue = 0;
            }

            var standValueTxt = numberFormat(standValue);
            var seatValueTxt = numberFormat(seatValue);

            $(rangeValueShowStand).val(standValueTxt);
            $(rangeValueShowSeat).val(seatValueTxt);

            if ($('#stadium_paid_span_' + standCode).length) {
                $('#stadium_paid_span_' + standCode).hide();
            }
            $('#stadium_cost_span_' + standCode).removeClass('d-none');

            calculateAllCapacity();

            if (checkStandConfiguration(rangeObj)) {
                $('#stadiumConfiguratorSave').removeClass('disabled-button');
            } else {
                $('#stadiumConfiguratorSave').addClass('disabled-button');
            }

            if (typeof unlockLevel == 'function') {
                setTimeout(function() {
                    unlockLevel(rangeObj);
                }, 10);
            }

            if (typeof activeAllStand == 'function' && $('#selectLevelAllStand').val() == 1) {
                activeAllStand($(this));
            }
        }

        var oldBusinessRangeValue;
        $('.businessRange').on('focus', function(e) {
            var value = $(this).val();
            value = value == '' || isNaN(value) ? 0 : parseInt(value);
            oldBusinessRangeValue = value;
        });

        $('#stadiumConfiguratorSave').on('click', function(e) {
            var obj = $(this);

            var totalRowCount = 0;
            if (typeof totalRowCountVar != 'undefined') {
                totalRowCount = totalRowCountVar;
            }

            var olMsgBoxTotalStandTotal = 0;
            var olMsgBoxTotalSeatTotal = 0;
            var olMsgBoxTotalBusinessTotal = 0;
            var olMsgBoxTotalBoxTotal = 0;
            var olMsgBoxTotalCostTotal = 0;

            for(var i=1; i<=totalRowCount; i++){

                var olMsgBoxTotalStand = 0;
                var olMsgBoxTotalSeat = 0;
                var olMsgBoxTotalBusiness = 0;
                var olMsgBoxTotalBox = 0;

                $(".rangeValueShowStand_"+i).each(function() {
                    standValueTotal = $(this).val();
                    standValueTotal = standValueTotal.replace(/[.,]/g, '');
                    standValueTotal = standValueTotal == '' || isNaN(standValueTotal) ? 0 : parseInt(standValueTotal);
                    olMsgBoxTotalStand += +standValueTotal; 
                });

                $(".rangeValueShowSeat_"+i).each(function() {
                    seatValueTotal = $(this).val();
                    seatValueTotal = seatValueTotal.replace(/[.,]/g, '');
                    seatValueTotal = seatValueTotal == '' || isNaN(seatValueTotal) ? 0 : parseInt(seatValueTotal);
                    olMsgBoxTotalSeat += +seatValueTotal; 
                });

                $(".businessRangeShow_"+i).each(function() {
                    businessValueTotal = $(this).val();
                    businessValueTotal = businessValueTotal.replace(/[.,]/g, '');
                    businessValueTotal = businessValueTotal == '' || isNaN(businessValueTotal) ? 0 : parseInt(businessValueTotal);
                    olMsgBoxTotalBusiness += +businessValueTotal; 
                });

                $(".boxRangeShow_"+i).each(function() {
                    boxValueTotal = $(this).val();
                    boxValueTotal = boxValueTotal.replace(/[.,]/g, '');
                    boxValueTotal = boxValueTotal == '' || isNaN(boxValueTotal) ? 0 : parseInt(boxValueTotal);
                    olMsgBoxTotalBox += +boxValueTotal; 
                });

                var olMsgBoxTotalStandTxt = numberFormat(olMsgBoxTotalStand);
                $('.olMsgBoxTotalStand_'+i).html(olMsgBoxTotalStandTxt);

                var olMsgBoxTotalSeatTxt = numberFormat(olMsgBoxTotalSeat);
                $('.olMsgBoxTotalSeat_'+i).html(olMsgBoxTotalSeatTxt);

                var olMsgBoxTotalBusinessTxt = numberFormat(olMsgBoxTotalBusiness);
                $('.olMsgBoxTotalBusiness_'+i).html(olMsgBoxTotalBusinessTxt);

                var olMsgBoxTotalBoxTxt = numberFormat(olMsgBoxTotalBox);
                $('.olMsgBoxTotalBox_'+i).html(olMsgBoxTotalBoxTxt);

                var olMsgBoxTotalCost = $("#stadiumTotalCost_"+i).html();
                $('.olMsgBoxTotalCost_'+i).html(addCurrency(olMsgBoxTotalCost));

                if (olMsgBoxTotalCost == 0){
                    $("#olMsgBoxRpw_"+i).hide();
                } else {
                    $("#olMsgBoxRpw_"+i).show();
                }

                olMsgBoxTotalStandTotal += +olMsgBoxTotalStand;
                olMsgBoxTotalSeatTotal += +olMsgBoxTotalSeat;
                olMsgBoxTotalBusinessTotal += +olMsgBoxTotalBusiness;
                olMsgBoxTotalBoxTotal += +olMsgBoxTotalBox;

                olMsgBoxTotalCost = olMsgBoxTotalCost.replace(/[.,]/g, '');
                olMsgBoxTotalCost = olMsgBoxTotalCost == '' || isNaN(olMsgBoxTotalCost) ? 0 : parseInt(olMsgBoxTotalCost);
                olMsgBoxTotalCostTotal += +olMsgBoxTotalCost;
            }

            var olMsgBoxTotalStandTotalTxt = numberFormat(olMsgBoxTotalStandTotal);
            $('.olMsgBoxTotalStandTotal').html(olMsgBoxTotalStandTotalTxt);

            var olMsgBoxTotalSeatTotalTxt = numberFormat(olMsgBoxTotalSeatTotal);
            $('.olMsgBoxTotalSeatTotal').html(olMsgBoxTotalSeatTotalTxt);

            var olMsgBoxTotalBusinessTotalTxt = numberFormat(olMsgBoxTotalBusinessTotal);
            $('.olMsgBoxTotalBusinessTotal').html(olMsgBoxTotalBusinessTotalTxt);

            var olMsgBoxTotalBoxTotalTxt = numberFormat(olMsgBoxTotalBoxTotal);
            $('.olMsgBoxTotalBoxTotal').html(olMsgBoxTotalBoxTotalTxt);

            var olMsgBoxTotalCostTotalTxt = numberFormat(olMsgBoxTotalCostTotal);
            $('.olMsgBoxTotalCostTotal').html(addCurrency(olMsgBoxTotalCostTotalTxt));

            var previousTotalDays = $('#previousTotalDays').val();
            previousTotalDays = previousTotalDays == '' || isNaN(previousTotalDays) ? 0 : parseInt(previousTotalDays);
            var allTotalDays = $('#allTotalDays').val();
            allTotalDays = allTotalDays == '' || isNaN(allTotalDays) ? 0 : parseInt(allTotalDays);
            var allTotalDaysWithOld = previousTotalDays + allTotalDays;
            getTimerData(allTotalDaysWithOld);

            operatingLevelArr.forEach(function(value, level) {
                var valueTxt = numberFormat(value);
                $('.olMsgBoxTotalOperatingCost_' + level).html(addCurrency(valueTxt));
            })

            var totalOperatingCosts = operatingLevelArr.reduce(function(a, b) {return a+b}, 0);
            totalOperatingCosts = Math.max(150*constantData.REALTIME_DAYS_PER_SEASON, totalOperatingCosts);
            $('.olMsgBoxTotalOperatingTotal').html(addCurrency(numberFormat(totalOperatingCosts)));

            if (!$(obj).hasClass('disabled-button')) {
                olMsgBox.largeQuestionById('purchaseConfirmation',
                    function() {
                        setTimeout(function() {
                            var stadiumConfiguratorFormData = new FormData($('#stadiumConfiguratorForm')[0]);
                            var newStadiumFlag =  $('#newStadiumFlg').val();
                            stadiumConfiguratorFormData.append('newStadiumFlg', $('#newStadiumFlg').val());
                            stadiumConfiguratorFormData.append('stadiumName', $('#stadiumName').val());
                            stadiumConfiguratorFormData.append('stadiumDescriptionId', $('#stadiumDescriptionId').val());

                            var stadiumConfiguratorSaveURL = '';
                            if (typeof stadiumConfiguratorSaveURLVar != 'undefined') {
                                stadiumConfiguratorSaveURL = stadiumConfiguratorSaveURLVar;
                            }
                            stadiumConfiguratorSaveURL = decodeURIComponent(stadiumConfiguratorSaveURL);

                            if (!$(obj).hasClass('disabled-button')) {
                                $(obj).addClass('disabled-button');
                                if (typeof addLoadingAnimationToElementNoTimeOut == 'function' && $('#configurator-accordion').length) {
                                    addLoadingAnimationToElementNoTimeOut($('#configurator-accordion'));
                                }
                                $.ajax({
                                    type: 'post',
                                    url: stadiumConfiguratorSaveURL, 
                                    data: stadiumConfiguratorFormData,
                                    processData: false,
                                    contentType: false,
                                    success: function(data){
                                        if (typeof removeLoadingAnimationFromElement == 'function' && $('#configurator-accordion').length) {
                                            removeLoadingAnimationFromElement($('#configurator-accordion'));
                                        }
                                        $(obj).removeClass('disabled-button');
                                        if (data.hint && data.hint === "investCheck")
                                        {
                                            olMsgBox.json(data);
                                            return;
                                        }
                                        
                                        if (data.status) {
                                            $('#stadiumId').val(data.stadiumId);
                                            $('#newStadiumFlg').val(0);
                                            
                                            if (newStadiumFlag == 1)
                                            {
                                                olMsgBox.msgById('purchaseSuccessMessage', 
                                                    function() {},
                                                    {'[stadium_name]' : data.stadiumName}
                                                );
                                            }
                                            else
                                            {
                                                olMsgBox.msgById('purchaseUpgradeSuccessMessage',
                                                    function() {},
                                                    {'[stadium_name]' : $('#stadiumName').val()}
                                                );
                                            }

                                            var configuratorURL = '';
                                            if (typeof configuratorURLVar != 'undefined') {
                                                configuratorURL = configuratorURLVar;
                                            }
                                            if (data.newStadiumFlg == '1') {
                                                olAnchorNavigation.load(configuratorURL);
                                                return false;
                                            }

                                            configuratorURL = decodeURIComponent(configuratorURL);
                                            olGUI.updateLiquidFunds();

                                            $.ajax({
                                                type: 'get',
                                                url: configuratorURL,
                                                success: function(data){
                                                    var data = $(data).filter('#configuratorContent').html();
                                                    $("#configuratorContent").html(data);
                                                },
                                                error: function(jqXHR, exception) {
                                                    console.error("Exception", exception)
                                                }
                                            });
                                        } else {
                                            if (data.error == 'userbalance') {
                                                olMsgBox.msgById('insufficientFund');
                                            } else if (data.error == 'FEATURE_LOCKED') {
                                                olMsgBox.msgById('notUnlocked')
                                            } else if (data.error == 'ALREADY_BUILDING') {
                                                olMsgBox.msgById('alreadyBuilding');
                                            } else {
                                                olMsgBox.msgById('invalidData');
                                            }
                                        }
                                    }
                                });
                            }
                        }, 500);
                    },
                    function() {
                        //Cancel
                    },
                    {'[stadium_name]' : $('#stadiumName').val()}
                );
            }
        });

        $('.svgBlock').on('click', function(e) {
            if (!$(this).hasClass('svgDeactivatedBlock') && $(this).attr('cursor') != 'default') {
                var dataValue = $(this).attr('data-value');

                $('.ticket-price-grid:not(.fixActive)').each(function(index) {
                    if ($(this).attr('data-value') == dataValue) {
                        $(this).addClass('active');
                    } else {
                        $(this).removeClass('active');
                    }
                });
            }
        });

        $('input[type="range"]').on("touchmove",function(e){
            // Do not remove. This is to stop scrolling at the time of range moving
        });

        window.addEventListener('touchmove', function(e) {
            if (e.target.type != 'range') {
                $('#listViewDiv').css('overflow', 'auto');
            } else {
                $('#listViewDiv').css('overflow', 'hidden');
            }
        }, {passive: true});

        var scrollTimer;
        $("#listViewDiv")
        .on("scroll", function(e) {
            $('.scrollArrow').stop(true, true).fadeOut();
            window.clearTimeout(scrollTimer);
            if ((e.currentTarget.scrollLeft + $(this).width()) >= e.currentTarget.scrollWidth) {
                $('.scrollArrow').stop(true, true).fadeOut();
            } else {
                scrollTimer = setTimeout(function() {
                    $('.scrollArrow').stop(true, true).fadeIn(300);
                }, 50);
            }
        });
    });

    $(function() {
        var headerBackgroundImage = '';
        if (screen.width <= 767) {
            headerBackgroundImage = $('.headerBackgroundImage').attr('data-image-src-mobile');
        } else {
            headerBackgroundImage = $('.headerBackgroundImage').attr('data-image-src');
        }
        $('.headerBackgroundImage').css('background-image', 'url(' + headerBackgroundImage + ')')
    });

    //$(function(){
    //    //$('[data-toggle="tooltip"]').popover({
    //    //    template: '<div class="tooltip tooltip-custom-class"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
    //    //    trigger: 'hover'
    //    //});
    //    //$('.roof-tooltip').tooltipApply();
    //});
}


function numberFormat(x) {
    x = x.toString().replace(/\./g, ',');
    x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return x;
}

function addCurrency(value) {
    return olGUI.addCurrencySymbol(value);
}


var operatingLevelArr;
function calculateAllCapacity() {
    var capacityLevelArr = [];
    var costBlockArr = [];
    var costBoxArr = [];
    var costLevelArr = [];

    operatingLevelArr = [];

    var withoutRoofStand = 0,
        withRoofStand = 0,
        withoutRoofSeat = 0,
        withRoofSeat = 0,
        withoutRoofBusiness = 0,
        withRoofBusiness = 0,
        withoutRoofBox = 0,
        totalBox = 0;
    
    var seatsInBox = $('#seatsInBox').val();
    seatsInBox = seatsInBox == '' || isNaN(seatsInBox) ? 0 : parseInt(seatsInBox);
    
    var factorSeats = constantData.FACTOR_SEATS;
    factorSeats = factorSeats == '' || isNaN(factorSeats) ? 0 : parseFloat(factorSeats);
    
    var factorBusiness = constantData.FACTOR_BUSINESS;
    factorBusiness = factorBusiness == '' || isNaN(factorBusiness) ? 0 : parseFloat(factorBusiness);
    
    var factorBox = constantData.FACTOR_BOX;
    factorBox = factorBox == '' || isNaN(factorBox) ? 0 : parseFloat(factorBox);
    
    var factorPlanningBuilding = constantData.FACTOR_PLANNING_BUILDING;
    factorPlanningBuilding = factorPlanningBuilding == '' || isNaN(factorPlanningBuilding) ? 0 : parseFloat(factorPlanningBuilding);
    
    var constTimeBZ = constantData.CONST_TIME_BZ;
    constTimeBZ = constTimeBZ == '' || isNaN(constTimeBZ) ? 0 : parseFloat(constTimeBZ);
    
    var constTimeMaxStands = constantData.CONST_TIME_Max_Stands;
    constTimeMaxStands = constTimeMaxStands == '' || isNaN(constTimeMaxStands) ? 0 : parseFloat(constTimeMaxStands);
    
    var additionBox1 = constantData.ADDITION_BOX_1;
    additionBox1 = additionBox1 == '' || isNaN(additionBox1) ? 0 : parseFloat(additionBox1);
    
    var additionBoxEven = constantData.ADDITION_BOX_EVEN;
    additionBoxEven = additionBoxEven == '' || isNaN(additionBoxEven) ? 0 : parseFloat(additionBoxEven);
    
    var additionBoxMaxPercent = constantData.ADDITION_BOX_MAX_PERCENT;
    additionBoxMaxPercent = additionBoxMaxPercent == '' || isNaN(additionBoxMaxPercent) ? 0 : parseFloat(additionBoxMaxPercent);
    
    var priceConstant = constantData.PRICE_CONSTANT;
    priceConstant = priceConstant == '' || isNaN(priceConstant) ? 0 : parseFloat(priceConstant);
    
    var factorStandsWithoutRoof = constantData.FACTOR_STANDS_WITHOUT_ROOF;
    factorStandsWithoutRoof = factorStandsWithoutRoof == '' || isNaN(factorStandsWithoutRoof) ? 0 : parseFloat(factorStandsWithoutRoof);
    
    var factorStandsWithRoof = constantData.FACTOR_STANDS_WITH_ROOF;
    factorStandsWithRoof = factorStandsWithRoof == '' || isNaN(factorStandsWithRoof) ? 0 : parseFloat(factorStandsWithRoof);
    
    var factorSeatsWithoutRoof = constantData.FACTOR_SEATS_WITHOUT_ROOF;
    factorSeatsWithoutRoof = factorSeatsWithoutRoof == '' || isNaN(factorSeatsWithoutRoof) ? 0 : parseFloat(factorSeatsWithoutRoof);
    
    var factorSeatsWithRoof = constantData.FACTOR_SEATS_WITH_ROOF;
    factorSeatsWithRoof = factorSeatsWithRoof == '' || isNaN(factorSeatsWithRoof) ? 0 : parseFloat(factorSeatsWithRoof);
    
    var factorBusinessWithoutRoof = constantData.FACTOR_BUSINESS_WITHOUT_ROOF;
    factorBusinessWithoutRoof = factorBusinessWithoutRoof == '' || isNaN(factorBusinessWithoutRoof) ? 0 : parseFloat(factorBusinessWithoutRoof);
    
    var factorBusinessWithRoof = constantData.FACTOR_BUSINESS_WITH_ROOF;
    factorBusinessWithRoof = factorBusinessWithRoof == '' || isNaN(factorBusinessWithRoof) ? 0 : parseFloat(factorBusinessWithRoof);
    
    var financesFactorBox = constantData.FINANCES_FACTOR_BOX;
    financesFactorBox = financesFactorBox == '' || isNaN(financesFactorBox) ? 0 : parseFloat(financesFactorBox);
    
    var realtimeDaysPerSeason = constantData.REALTIME_DAYS_PER_SEASON;
    realtimeDaysPerSeason = realtimeDaysPerSeason == '' || isNaN(realtimeDaysPerSeason) ? 0 : parseFloat(realtimeDaysPerSeason);
    
    var kp = $('#kp').val();
    kp = kp == '' || isNaN(kp) ? 0 : parseFloat(kp);
    
    var kpSitz = $('#kpSitz').val();
    kpSitz = kpSitz == '' || isNaN(kpSitz) ? 0 : parseFloat(kpSitz);
    
    var kpBus = $('#kpBus').val();
    kpBus = kpBus == '' || isNaN(kpBus) ? 0 : parseFloat(kpBus);

    var kpLog = $('#kpLog').val();
    kpLog = kpLog == '' || isNaN(kpLog) ? 0 : parseFloat(kpLog);

    var dpPer = $('#dpPer').val();
    dpPer = dpPer == '' || isNaN(dpPer) ? 0 : parseFloat(dpPer);

    var dataInitialCount = 0;
    var roofNumber = 0;
    var boxNumber = 0;
    var totalValueStands = 0;
    var totalValueSeats = 0;

    $('.stadium-distribution-span').text("0");

    var globalSeatConfiguration = {
        standing: 0,
        sitting: 0,
        business: 0,
        box: 0,
        standingRoof: 0,
        sittingRoof: 0,
        businessRoof: 0,
        boxRoof: 0,
        roofOnlyStanding: 0,
        roofOnlySitting: 0,
        roofOnlyBusiness: 0,
        roofOnlyBox: 0,
    };
    var modificationAtAll = false;
    $('.myRange').each(function(index) {
        var elementID = $(this).attr('id');
        var standCode = elementID.replace('stadium_range_', '');
        var hasRoof = $('#stadium_roof_' + standCode)[0].checked;
        var level = $(this).attr('data-level');

        if ($(this).attr('data-initial') == 1) {
            dataInitialCount ++;
        }
        if (hasRoof) {
            roofNumber ++;
        }
        
        var oldStandValue = $('#stadium_old_stand_' + standCode).val();
        oldStandValue = oldStandValue == '' || isNaN(oldStandValue) ? 0 : parseInt(oldStandValue);
        
        var oldSeatValue = $('#stadium_old_seat_' + standCode).val();
        oldSeatValue = oldSeatValue == '' || isNaN(oldSeatValue) ? 0 : parseInt(oldSeatValue);
        
        var oldBusinessValue = $('#stadium_old_business_' + standCode).val();
        oldBusinessValue = oldBusinessValue == '' || isNaN(oldBusinessValue) ? 0 : parseInt(oldBusinessValue);
        
        var oldBoxValue = $('#stadium_old_box_' + standCode).val();
        oldBoxValue = oldBoxValue == '' || isNaN(oldBoxValue) ? 0 : parseInt(oldBoxValue);
        
        var newConstruction = false;
        if (oldStandValue == 0 && oldSeatValue == 0 && oldBusinessValue == 0 && oldBoxValue == 0) {
            newConstruction = true;
        }

        var standValue = 0;
        if ($('#stadium_stand_value_' + standCode).length) {
            standValue = $('#stadium_stand_value_' + standCode).val();
            standValue = standValue.replace(/[.,]/g, '');
            standValue = standValue == '' || isNaN(standValue) ? 0 : parseInt(standValue);
        }

        var seatValue = 0;
        if ($('#stadium_seat_value_' + standCode).length) {
            seatValue = $('#stadium_seat_value_' + standCode).val();
            seatValue = seatValue.replace(/[.,]/g, '');
            seatValue = seatValue == '' || isNaN(seatValue) ? 0 : parseInt(seatValue);
        }

        var businessValue = 0;
        if ($('#stadium_business_value_' + standCode).length) {
            businessValue = $('#stadium_business_value_' + standCode).val();
            businessValue = businessValue.replace(/[.,]/g, '');
            businessValue = businessValue == '' || isNaN(businessValue) ? 0 : parseInt(businessValue);
        }

        var boxValue = 0;
        if ($('#stadium_box_value_' + standCode).length) {
            boxValue = $('#stadium_box_value_' + standCode).val();
            boxValue = boxValue.replace(/[.,]/g, '');
            boxValue = boxValue == '' || isNaN(boxValue) ? 0 : parseInt(boxValue);
        }
        
        if (boxValue != 0) {
            boxNumber ++;
        }

        var oldRoofValue = $('#stadium_old_roof_' + standCode).val();
        oldRoofValue = oldRoofValue == '' || isNaN(oldRoofValue) ? 0 : parseInt(oldRoofValue);

        var stadiumDuration = $('#stadium_duration_' + standCode).val();
        stadiumDuration = stadiumDuration == '' || isNaN(stadiumDuration) ? 0 : parseInt(stadiumDuration);

        var isStandInConstruction = $('#svg_input_' + standCode).val();
        isStandInConstruction = isStandInConstruction == '' || isNaN(isStandInConstruction) ? 0 : parseInt(isStandInConstruction);

        //---Seat calculation start
        var totalLevelCapacity = standValue + (seatValue * factorSeats) + (businessValue * factorBusiness) + (boxValue * (factorBox * seatsInBox));
        var totalLevelAttendance = standValue + seatValue + businessValue + (boxValue * seatsInBox);

        if (!capacityLevelArr[level]) {
            capacityLevelArr[level] = 0;
        }
        capacityLevelArr[level] += totalLevelAttendance;

        totalValueStands +=  standValue;
        totalValueSeats += seatValue + businessValue + (boxValue * seatsInBox);


        var levelValueStands = parseInt($('#stadiumDistributionStand_'+level).text().replace(".", "")) + standValue;
        $('#stadiumDistributionStand_'+level).html(numberFormat(levelValueStands));

        var levelValueSeats = parseInt($('#stadiumDistributionSeat_'+level).text().replace(".", "")) + seatValue + businessValue + (boxValue * seatsInBox);
        $('#stadiumDistributionSeat_'+level).html(numberFormat(levelValueSeats)); 

        //---Seat calculation end

        seatsInBox = $('#seatsInBox').val();
        seatsInBox = seatsInBox == '' || isNaN(seatsInBox) ? 0 : parseInt(seatsInBox);

        //---Time calculation start
        var target = standValue + (seatValue * factorSeats) + (businessValue * factorBusiness) + (seatsInBox * boxValue * factorBox );
        var construction = 0;
        if (constTimeMaxStands != 0) {
            construction = (constTimeBZ / constTimeMaxStands) * target;
        }
        var additionalBoxCharge = 0;
        if (boxValue > 0) {
            additionalBoxCharge += (additionBox1 / 100);
        }
        if (boxValue > 1) {
            additionalBoxCharge += (boxValue - 1) * (additionBoxEven / 100);
        }
        if (additionalBoxCharge > (additionBoxMaxPercent / 100)) {
            additionalBoxCharge = (additionBoxMaxPercent / 100);
        }
        var planning = construction * (factorPlanningBuilding + additionalBoxCharge);
        var constTime = construction + planning;
        if (!hasRoof) {
            constTime = constTime * ((100 - dpPer) / 100);
        }
        constTime = Math.ceil(constTime);
        
        //----Old seats of stadium start
        var standDiff = standValue - oldStandValue;
        var seatDiff = seatValue - oldSeatValue;
        var businessDiff = businessValue - oldBusinessValue;
        var boxDiff = boxValue - oldBoxValue;
        
        if (oldStandValue != standValue 
         || oldSeatValue != seatValue 
         || oldBusinessValue != businessValue 
         || oldBoxValue != boxValue 
         || oldRoofValue != hasRoof) {
            modificationAtAll = true;
            if (oldStandValue == 0 
             && oldSeatValue == 0 
             && oldBusinessValue == 0 
             && oldBoxValue == 0 
             /*&& oldRoofValue == 0*/) {
                $('#stadium_duration_' + standCode).val(constTime);
                
                if (hasRoof) {
                    globalSeatConfiguration.sittingRoof += seatValue;
                    globalSeatConfiguration.standingRoof += standValue;
                    globalSeatConfiguration.businessRoof += businessValue;
                    globalSeatConfiguration.boxRoof += boxValue;
                } else {
                    globalSeatConfiguration.sitting += seatValue;
                    globalSeatConfiguration.standing += standValue;
                    globalSeatConfiguration.business += businessValue;
                    globalSeatConfiguration.box += boxValue;
                }
            } else {
                if (oldRoofValue != hasRoof && hasRoof) {
                    globalSeatConfiguration.roofOnlySitting += oldSeatValue;
                    globalSeatConfiguration.roofOnlyStanding += oldStandValue;
                    globalSeatConfiguration.roofOnlyBusiness += oldBusinessValue;
                    globalSeatConfiguration.roofOnlyBox += oldBoxValue;    
                }
                
                if (oldBoxValue != boxValue || oldRoofValue != hasRoof) {
                    $('#stadium_duration_' + standCode).val(7);
                } else {
                    $('#stadium_duration_' + standCode).val(0);
                }
                
                // modification change -> estimate box building time
                if (boxDiff > 0) {                
                    if (hasRoof) {
                        globalSeatConfiguration.boxRoof += boxDiff;
                    } else {
                        globalSeatConfiguration.box += boxDiff;
                    }
                }
            }
        } else {
            if (isStandInConstruction != 3) { //For In-construction do nothing but if undoing the modification change make it in previous state
                $('#stadium_duration_' + standCode).val(0);
            }
        }
        //---Time calculation end

        //----Old seats of stadium end

        //----Demolition cost calculation start
        var demolishStandingCost = 0;
        var demolishSittingCost = 0;
        var demolishBusinessCost = 0;
        var demolishBoxCost = 0;

        if (standDiff < 0) {
            demolishStandingCost = Math.abs(standDiff) * kp;
        }
        if (seatDiff < 0) {
            demolishSittingCost = Math.abs(seatDiff) * kpSitz;
        }
        if (businessDiff < 0) {
            demolishBusinessCost = Math.abs(businessDiff) * kpBus;
        }
        if (boxDiff < 0) {
            demolishBoxCost = Math.abs(boxDiff) * kpLog;
        }
        if (oldRoofValue == 0) {
            demolishStandingCost -= ((demolishStandingCost * dpPer) / 100);
            demolishSittingCost -= ((demolishSittingCost * dpPer) / 100);
            demolishBusinessCost -= ((demolishBusinessCost * dpPer) / 100);
            demolishBoxCost -= ((demolishBoxCost * dpPer) / 100);
        }
        demolishStandingCost = (demolishStandingCost * 0.5 / 100);
        demolishSittingCost = (demolishSittingCost * 2 /100);
        demolishBusinessCost = (demolishBusinessCost * 2.5 / 100);
        demolishBoxCost = (demolishBoxCost * 5 /100);
        //----Demolition cost calculation end

        //---Cost calculation start
        var standingCost = 0;
        var sittingCost = 0;
        var businessCost = 0;
        var boxCost = 0;

        if (newConstruction) {        
            if (standDiff > 0) { 
                standingCost = standDiff * kp;
            }
            if (seatDiff > 0) {
                sittingCost = seatDiff * kpSitz;
            }
            if (businessDiff > 0) {
                businessCost = businessDiff * kpBus;
            }
            if (boxDiff > 0) {
                boxCost = boxDiff * kpLog;
            }
        } else {      
            if (standDiff > 0) {
                standingCost = standDiff * kp* constantData.SEATING_CONVERSION_FACTOR;
            }
            if (seatDiff > 0) {
                sittingCost = seatDiff * kpSitz * constantData.SEATING_CONVERSION_FACTOR;
            }
            if (businessDiff > 0) {
                businessCost = businessDiff * kpBus * constantData.SEATING_BUSINESS_CONVERSION_FACTOR;
            }
        }

        if (oldRoofValue == 0) {
            standingCost -= ((standingCost * dpPer) / 100);
            sittingCost -= ((sittingCost * dpPer) / 100);
            businessCost -= ((businessCost * dpPer) / 100);
            boxCost -= ((boxCost * dpPer) / 100);
        }

        if (oldRoofValue == 0 && hasRoof) {
            var standingRoofCost = ((standValue * kp) * dpPer / 100);
            var sittingRoofCost = ((seatValue * kpSitz) * dpPer / 100);
            var businessRoofCost = ((businessValue * kpBus) * dpPer / 100);
            var boxRoofCost = ((boxValue * kpLog) * dpPer / 100);
            
            standingCost += standingRoofCost;
            sittingCost += sittingRoofCost;
            businessCost += businessRoofCost;
            boxCost += boxRoofCost;
        }

        // Demolition cost addition
        standingCost += demolishStandingCost;
        sittingCost += demolishSittingCost;
        businessCost += demolishBusinessCost;
        boxCost += demolishBoxCost;

        var totalCost = standingCost + sittingCost + businessCost + boxCost;
        totalCost = Math.round(totalCost);

        if (!costLevelArr[level]) {
            costLevelArr[level] = 0;
        }
        costLevelArr[level] += totalCost;

        if (!costBlockArr[standCode]) {
            costBlockArr[standCode] = 0;
        }
        costBlockArr[standCode] += totalCost;

        if (!costBoxArr[standCode]) {
            costBoxArr[standCode] = 0;
        }
        costBoxArr[standCode] += boxCost;

        var totalCostTxt = numberFormat(totalCost);
        $('#stadium_block_cost_' + standCode).html(totalCostTxt);

        if (oldStandValue != standValue 
         || oldSeatValue != seatValue 
         || oldBusinessValue != businessValue 
         || oldBoxValue != boxValue 
         || oldRoofValue != hasRoof) {
            $('#stadium_paid_span_' + standCode).hide();
            $('#stadium_cost_span_' + standCode).removeClass('d-none');
        } else {
            $('#stadium_paid_span_' + standCode).show();
            $('#stadium_cost_span_' + standCode).addClass('d-none');
        }

        if ($('#stadium_box_cost_' + standCode).length) {
            var boxCostTxt = numberFormat(boxCost);
            $('#stadium_box_cost_' + standCode).html(boxCostTxt);
            if (boxCost != 0) {
                $('#stadium_box_cost_block_' + standCode).show();
            } else {
                $('#stadium_box_cost_block_' + standCode).hide();
            }
        }
        //---Cost calculation end

        //---Operating cost calculation start
        if ($('#stadium_roof_' + standCode)[0].checked) {
            withRoofStand += standValue;
            withRoofSeat += seatValue;
            withRoofBusiness += businessValue;
        } else {
            withoutRoofStand += standValue;
            withoutRoofSeat += seatValue;
            withoutRoofBusiness += businessValue;
        }

        var category = parseInt($('#runningCostsFactor').val()) || 1;
        var costsPerWeekGameDay;
        if ($('#stadium_roof_' + standCode)[0].checked) {
            costsPerWeekGameDay  = (standValue          * factorStandsWithRoof);
            costsPerWeekGameDay += (seatValue           * factorSeatsWithRoof);
            costsPerWeekGameDay += (businessValue       * factorBusinessWithRoof);
            costsPerWeekGameDay += (boxValue*seatsInBox * financesFactorBox);

            costsPerWeekGameDay *= priceConstant ;
            costsPerWeekGameDay *= category;

            costsPerWeekGameDay = costsPerWeekGameDay == '' || isNaN(costsPerWeekGameDay) ? 0 : parseFloat(costsPerWeekGameDay);
        } else {
            costsPerWeekGameDay  = (standValue          * factorStandsWithoutRoof);
            costsPerWeekGameDay += (seatValue           * factorSeatsWithoutRoof);
            costsPerWeekGameDay += (businessValue       * factorBusinessWithoutRoof);
            costsPerWeekGameDay += (boxValue*seatsInBox * financesFactorBox);

            costsPerWeekGameDay *= priceConstant ;
            costsPerWeekGameDay *= category;

            costsPerWeekGameDay = costsPerWeekGameDay == '' || isNaN(costsPerWeekGameDay) ? 0 : parseFloat(costsPerWeekGameDay);
            
        }

        costsPerWeekGameDay = Math.max(0, costsPerWeekGameDay);
        
        var costsPerYear = costsPerWeekGameDay * realtimeDaysPerSeason;

        costsPerYear = costsPerYear == '' || isNaN(costsPerYear) ? 0 : Math.round(costsPerYear);
        operatingLevelArr[level] = operatingLevelArr[level] || 0;
        operatingLevelArr[level] += costsPerYear;

        totalBox += boxValue;
        //---Operating cost calculation end
    });
    
    // global construction time calc
    
    //---Time calculation start
    var target = globalSeatConfiguration.standing + (globalSeatConfiguration.sitting * factorSeats) + (globalSeatConfiguration.business * factorBusiness) + (seatsInBox * globalSeatConfiguration.box * factorBox );
    var targetRoof = globalSeatConfiguration.standingRoof + (globalSeatConfiguration.sittingRoof * factorSeats) + (globalSeatConfiguration.businessRoof * factorBusiness) + (seatsInBox * globalSeatConfiguration.boxRoof * factorBox );
    var targetRoofOnly = globalSeatConfiguration.roofOnlyStanding + (globalSeatConfiguration.roofOnlySitting * factorSeats) + (globalSeatConfiguration.roofOnlyBusiness * factorBusiness) + (seatsInBox * globalSeatConfiguration.roofOnlyBox * factorBox );
     
    var boxTotalValue = globalSeatConfiguration.box + globalSeatConfiguration.boxRoof;
    
    var construction = 0, constructionRoof = 0, constructionRoofOnly = 0;
    if (constTimeMaxStands != 0) {
        construction = (constTimeBZ / constTimeMaxStands) * target;
        constructionRoof = (constTimeBZ / constTimeMaxStands) * targetRoof;
        constructionRoofOnly = (constTimeBZ / constTimeMaxStands) * targetRoofOnly;
    }
    var additionalBoxCharge = 0;
    if (boxTotalValue > 0) {
        additionalBoxCharge += (additionBox1 / 100);
    }
    if (boxTotalValue > 1) {
        additionalBoxCharge += (boxTotalValue - 1) * (additionBoxEven / 100);
    }
    if (additionalBoxCharge > (additionBoxMaxPercent / 100)) {
        additionalBoxCharge = (additionBoxMaxPercent / 100);
    }
    var planning = construction * (factorPlanningBuilding + additionalBoxCharge);
    var planningRoof = constructionRoof * (factorPlanningBuilding + additionalBoxCharge);
    var planningRoofOnly = constructionRoofOnly * (factorPlanningBuilding + additionalBoxCharge);
    
    var constTime = (construction + planning) * ((100 - dpPer) / 100);
    var constTimeRoof = (constructionRoof + planningRoof);
    var constTimeRoofOnly = (constructionRoofOnly + planningRoofOnly) * (dpPer / 100);
    
    constTime = Math.ceil(constTime + constTimeRoof + constTimeRoofOnly);
    if (modificationAtAll)
        constTime = Math.max(constTime, 7);
    $('.total-distribution-seats').html(numberFormat(totalValueSeats));
    $('.total-distribution-stands').html(numberFormat(totalValueStands));
    
    var pct = Math.round(100*(totalValueStands)/(totalValueStands+totalValueSeats));
    $('.ol-stadium-pct-bar-left').css('width', pct+"%");
    $('.ol-stadium-pct-text').first().text(pct+"%");
    $('.ol-stadium-pct-text').last().text((100-pct)+"%");

    //---Construction time calculation for level start
    var timeLevelArr = [];
    var maxDays = 0;
    $('.rangeValueShowStand').each(function(index) {
        var elementID = $(this).attr('id');
        var standCode = elementID.replace('stadium_stand_value_', '');
        var level = $(this).attr('data-level');

        var stadiumDuration = $('#stadium_duration_' + standCode).val();
        stadiumDuration = stadiumDuration == '' || isNaN(stadiumDuration) ? 0 : parseInt(stadiumDuration);

        if (!timeLevelArr[level]) {
            timeLevelArr[level] = 0;
        }
        timeLevelArr[level] += stadiumDuration;

        if (stadiumDuration > maxDays) {
            maxDays = stadiumDuration;
        }
    });
    $('#maxDays').val(maxDays);
    //---Construction time calculation for level end

    //---All Seat calculation start
    var allBlockTotalCapacity = 0;
    capacityLevelArr.forEach(function(value, level) {
        allBlockTotalCapacity += value;
        var valueTxt = numberFormat(value);
        $('#stadiumTotalCapacity_' + level).html(valueTxt);
    });
    var allBlockTotalCapacityTxt = numberFormat(allBlockTotalCapacity);
    $('#allBlockTotalCapacity').html(allBlockTotalCapacityTxt);
    $('#olMsgBoxTotalCapacity').html(allBlockTotalCapacityTxt);
    //---All Seat calculation end

    //---All Time calculation start
    var allTotalDays = 0;
    timeLevelArr.forEach(function(value, level) {
        allTotalDays += value;

        var weeks = Math.ceil(value / 7);
        var valueTxt = numberFormat(weeks);
        $('#stadiumConstructionTime_' + level).html(valueTxt);
        if (weeks > 1) {
            $('#stadiumConstructionTimeWeek_' + level).hide();
            $('#stadiumConstructionTimeWeeks_' + level).show();
        } else {
            $('#stadiumConstructionTimeWeeks_' + level).hide();
            $('#stadiumConstructionTimeWeek_' + level).show();
        }
    });
    allTotalDays = constTime;
    $('#allTotalDays').val(allTotalDays);

    var allTotalWeeks = Math.ceil(allTotalDays / 7);
    var allTotalWeeksTxt = numberFormat(allTotalWeeks);

    $('#allBlockConstructionTime').html(allTotalWeeksTxt);
    $('#olMsgBoxConstructionTime').html(allTotalWeeksTxt);
    if (allTotalWeeks > 1) {
        $('#allBlockConstructionTimeWeek').hide();
        $('#allBlockConstructionTimeWeeks').show();

        $('#olMsgBoxConstructionTimeWeek').hide();
        $('#olMsgBoxConstructionTimeWeeks').show();
    } else {
        $('#allBlockConstructionTimeWeeks').hide();
        $('#allBlockConstructionTimeWeek').show();
        
        $('#olMsgBoxConstructionTimeWeeks').hide();
        $('#olMsgBoxConstructionTimeWeek').show();
    }
    //---All Time calculation end

    //---Discount apply start
    var allTotalCost = 0;
    costLevelArr.forEach(function(value, level) {
        allTotalCost += value;
    });
    var consoleData = [];
    costLevelArr = [];
    var totalDiscountAmount = getDiscountAmount(allTotalCost);
    consoleData['allTotalCost'] = allTotalCost;
    consoleData['totalDiscountPer'] = allTotalCost != 0 ? totalDiscountAmount * 100 / allTotalCost : 0;
    consoleData['totalDiscountAmount'] = totalDiscountAmount;
    consoleData['z_stands'] = [];

    var totalBlockDiscount = 0;
    for (var standCode in costBlockArr) {
        if (costBlockArr.hasOwnProperty(standCode)){
            var value = costBlockArr[standCode];

            var level = $('#stadium_range_' + standCode).attr('data-level');

            var amountPer = allTotalCost != 0 ? (value / allTotalCost) : 0;
            var blockDiscountAmount = amountPer * totalDiscountAmount;
            var newBlockCost = Math.round(value - blockDiscountAmount);

            totalBlockDiscount += (value - newBlockCost);

            if (!costLevelArr[level]) {
                costLevelArr[level] = 0;
            }
            costLevelArr[level] += newBlockCost;

            consoleData['z_stands'][standCode] = [];
            consoleData['z_stands'][standCode]['actual'] = value;
            consoleData['z_stands'][standCode]['withDiscount'] = newBlockCost;

            var totalCostTxt = numberFormat(newBlockCost);
            $('#stadium_block_cost_' + standCode).html(totalCostTxt);

            var boxCost = costBoxArr[standCode];
            var boxAmountPer = allTotalCost != 0 ? (boxCost / allTotalCost) : 0;
            var boxDiscountAmount = boxAmountPer * totalDiscountAmount;
            var newBoxCost = Math.round(boxCost - boxDiscountAmount);

            consoleData['z_stands'][standCode]['z_boxActual'] = boxCost;
            consoleData['z_stands'][standCode]['z_boxWithDiscount'] = newBoxCost;

            if ($('#stadium_box_cost_' + standCode).length) {
                var newBoxCostTxt = numberFormat(newBoxCost);
                $('#stadium_box_cost_' + standCode).html(newBoxCostTxt);
                if (newBoxCost != 0) {
                    $('#stadium_box_cost_block_' + standCode).show();
                } else {
                    $('#stadium_box_cost_block_' + standCode).hide();
                }
            }
        }
    }
    //---Discount apply end

    //---All Cost calculation start
    var allTotalCost = 0;
    costLevelArr.forEach(function(value, level) {
        allTotalCost += value;
        var valueTxt = numberFormat(value);
        $('#stadiumTotalCost_' + level).html(valueTxt);
    });
    consoleData['newAllTotalCost'] = allTotalCost;
    consoleData['totalBlockDiscount'] = totalBlockDiscount;
    consoleData['totalDiscountDifference'] = consoleData['totalDiscountAmount'] - consoleData['totalBlockDiscount'];

    
    var allTotalCostTxt = numberFormat(allTotalCost);
    $('#allBlockTotalCost').html(allTotalCostTxt);

    var userBalance = $('#userBalance').val();
    userBalance = userBalance == '' || isNaN(userBalance) ? 0 : parseFloat(userBalance);

    if (allTotalCost > userBalance) {
        $('#allBlockTotalCost').parent().addClass('insufficient-fund');
        $('#allBlockTotalCost').parent().parent().addClass('insufficient-fund');
    } else {
        $('#allBlockTotalCost').parent().removeClass('insufficient-fund');
        $('#allBlockTotalCost').parent().parent().removeClass('insufficient-fund');
    }
    //---All Cost calculation end

    //--- All Operating cost calculation start
    var category = $('#runningCostsFactor').val() || 1;
    var costsPerWeekGameDay = 
        (withoutRoofStand * factorStandsWithoutRoof) 
        + (withRoofStand * factorStandsWithRoof) 
        + (withoutRoofSeat * factorSeatsWithoutRoof) 
        + (withRoofSeat * factorSeatsWithRoof) 
        + (withoutRoofBusiness * factorBusinessWithoutRoof) 
        + (withRoofBusiness * factorBusinessWithRoof) 
        + (totalBox * financesFactorBox);

    //console.log("before multiplier", costsPerWeekGameDay);

    costsPerWeekGameDay *=  priceConstant * category;

    //console.log("after multiplier", costsPerWeekGameDay);
    costsPerWeekGameDay = costsPerWeekGameDay == '' || isNaN(costsPerWeekGameDay) ? 0 : parseFloat(costsPerWeekGameDay);
    costsPerWeekGameDay = Math.max(150, costsPerWeekGameDay);
    var costsPerYear = costsPerWeekGameDay * realtimeDaysPerSeason;
    //console.log("per year", costsPerYear);
    costsPerYear = costsPerYear == '' || isNaN(costsPerYear) ? 0 : Math.round(costsPerYear);
    var costsPerYearTxt = numberFormat(costsPerYear);
    $('#allBlockOperatingCost').html(costsPerYearTxt);
    //---All Operating cost calculation end

    showHideLegend();

    roofBuildChecking();

    //----Preview loading start
    if (oldDataInitialCount != dataInitialCount || oldRoofNumber != roofNumber || oldBoxNumber != boxNumber) {
        reloadArchitecturePreview();
    }
    oldDataInitialCount = dataInitialCount;
    oldRoofNumber = roofNumber;
    oldBoxNumber = boxNumber;
    //----Preview loading end
}

function changeUrl(url) {
    if (typeof (history.pushState) != "undefined") {
        var obj = {Url: url};
        history.pushState(obj, '', obj.Url);
    }
}

function startStadiumAlert(rangeObj, checkmarkFlg) {
    if (!checkmarkFlg) checkmarkFlg = false;
    $(rangeObj).trigger('input', [checkmarkFlg]);
}

function showHideLegend() {
    var declined = constructionPossible = active = construction = built = 0;
    $('.svgStadiumBlock').each(function() {
        switch(true) {
          case $(this).hasClass('activeBlock'):
            active = 1;
            break;
          case $(this).hasClass('disabledBlock'):
            declined = 1;
            break;
          case $(this).hasClass('conPossibleBlock'):
            constructionPossible = 1;
            break;
          case $(this).hasClass('constructionBlock'):
            construction = 1;
            break;
          case $(this).hasClass('builtBlock'):
            built = 1;
            break;
        }
    });

    if (declined) {
        $('#icons_legend_declined').fadeIn(300);
    } else {
        $('#icons_legend_declined').hide();
    }
    if (constructionPossible) {
        $('#icons_legend_constructionPossible').fadeIn(300);
    } else {
        $('#icons_legend_constructionPossible').hide();
    }
    if (active) {
        $('#icons_legend_active').fadeIn(300);
    } else {
        $('#icons_legend_active').hide();
    }
    if (construction) {
        $('#icons_legend_construction').fadeIn(300);
    } else {
        $('#icons_legend_construction').hide();
    }
    if (built) {
        $('#icons_legend_built').fadeIn(300);
    } else {
        $('#icons_legend_built').hide();
    }
}

function roofBuildChecking() {
    //----Roof restriction checking from DB start
    for (var targetShortCode in stadiumModificationData) {
        if (stadiumModificationData.hasOwnProperty(targetShortCode) 
         && stadiumModificationData[targetShortCode]['modRoofRestriction'].length) {
            var hasParentRoof = true;
            for (var key in stadiumModificationData[targetShortCode]['modRoofRestriction']) {
                if (stadiumModificationData[targetShortCode]['modRoofRestriction'].hasOwnProperty(key)) {
                    var dependentStandCode = stadiumModificationData[targetShortCode]['modRoofRestriction'][key];
                    if (!$('#stadium_roof_' + dependentStandCode)[0].checked) {
                        hasParentRoof = false;
                    }
                }
            }
            if (hasParentRoof) {
                $('#stadium_roof_checkmark_' + targetShortCode).css('opacity', '');
                $('#stadium_roof_checkmark_' + targetShortCode).tooltipRemove();
            } else {
                $('#stadium_roof_' + targetShortCode)[0].checked = false;
                $('#stadium_roof_checkmark_' + targetShortCode).css('opacity', 0.6);
                $('#stadium_roof_checkmark_' + targetShortCode).tooltipApply();
            }
        }
    }

    $('.roof-tooltip').tooltipApply();
    //----Rood restriction checking from DB end
}

var reloadArchitecturePreviewTimer;
function reloadArchitecturePreview() {
    clearTimeout(reloadArchitecturePreviewTimer);
    reloadArchitecturePreviewTimer = setTimeout(function(){
        if (['1', '2', '3', '4', '5'].includes($('#stadiumDescriptionId').val())) {
            var editorData = [];
            if (typeof editorAllData != 'undefined') {
                editorData = editorAllData;
            }

            if (typeof editorAllData != 'undefined') {
                var standArr = [];
                var standId = [];
                var standRoofArr = [];
                var standBoxArr = [];
                $('.svgStadiumBlock').each(function() {
                    var standCode = $(this).attr('id');
                    var level = $(this).attr('data-level');
                    var modDescriptionId = $('#stadium_modDescriptionId_' + standCode).val();

                    standId[standCode] = modDescriptionId;

                    standArr[standCode] = 0;
                    standBoxArr[standCode] = 0;

                    switch(true) {
                      case $(this).hasClass('disabledBlock'):
                        standArr[standCode] = 0;
                        break;
                      case $(this).hasClass('activeBlock'):
                        standArr[standCode] = 2;
                        break;
                      case $(this).hasClass('constructionBlock'):
                        standArr[standCode] = 3;
                        break;
                      case $(this).hasClass('builtBlock'):
                        standArr[standCode] = 4;
                        break;
                    }
                    if ($('#stadium_box_range_' + standCode).length && $('#stadium_box_range_' + standCode).val() != 0) {
                        standBoxArr[standCode] = 1;
                    }
                });

                $('.svgStadiumBlock').each(function() {
                    var standCode = $(this).attr('id');
                    var level = $(this).attr('data-level');
                    standRoofArr[standCode] = $('#stadium_roof_' + standCode)[0].checked;
                });

                if ($('#stadiumDescriptionId').val() == 3) {
                    if (standArr['N3'] >= 2) {
                        standArr['N2'] = 0;
                        standArr['O'] = 0;
                        if (standBoxArr['O']) {
                            standBoxArr['O'] = 0;
                            standBoxArr['N3'] = 1;
                        }
                    } else if (standArr['N2'] >= 2) {
                        standArr['O'] = 0;
                        if (standBoxArr['O']) {
                            standBoxArr['O'] = 0;
                            standBoxArr['N2'] = 1;
                        }
                    }
                }

                var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
                var getArchitecturePreviewImageUrl = '';
                if (typeof getArchitecturePreviewImageUrlVar != 'undefined') {
                    getArchitecturePreviewImageUrl = getArchitecturePreviewImageUrlVar;
                }
                var stadiumDescriptionId = $('#stadiumDescriptionId').val();

                var modificationArr = new Object();

                for (var modCode in standArr) {
                    if (standArr.hasOwnProperty(modCode)){
                        if (standArr[modCode] > 1) {
                            var modId = standId[modCode];
                            var dataArr = new Object();
                            dataArr['roof'] = 0;
                            dataArr['box'] = 0;
                            if (standRoofArr[modCode]) {
                                dataArr['roof'] = 1;
                            }
                            if (standBoxArr[modCode]) {
                                dataArr['box'] = 1;
                            }
                            modificationArr[modId] = dataArr;
                        }
                    }
                }

                $.ajax({
                    url: getArchitecturePreviewImageUrl,
                    type: 'POST',
                    data: {
                        _token: CSRF_TOKEN,
                        stadiumDescriptionId : stadiumDescriptionId,
                        modificationArr : modificationArr,
                    },
                    dataType: 'JSON',
                    success: function(data) { 
                        editorData.visuals = data;
                        editorData.noFade = true;
                        //$('#stadiumLoading').show();
                        clearTimeout(olStadiumEditorCall);
                        olStadiumEditor.init(editorData);
                    },
                    error: function(jqXHR, exception) {
                        console.error("Exceptions:", exception);
                    }
                });
            }
        }
    }, 10);
}

function getTimerData(totalDays) {
    var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');
    var getTimerDataUrl = '';
    if (typeof getTimerDataUrlVar != 'undefined') {
        getTimerDataUrl = getTimerDataUrlVar;
    }
    $.ajax({
        url: getTimerDataUrl,
        type: 'POST',
        data: {
            _token: CSRF_TOKEN,
            totalDays : totalDays,
        },
        dataType: 'JSON',
        success: function(data) {
            if (data) {
                $('.ol-msg-box-content').find('.seasonData').html(data.season);
                $('.ol-msg-box-content').find('.weekData').html(data.week);
                $('.ol-msg-box-content').find('.weekDataWeeks').hide();
                $('.ol-msg-box-content').find('.weekDataWeek').show();
                $('.ol-msg-box-content').find('.matchdayData').html(data.matchday);
            }
        },
        error: function(jqXHR, exception) {
            console.error("Exception", exception)
        }
    });
}

function getDiscountAmount(totalCost) {
    var costsReductionValue = constantData.COSTS_REDUCTION_VALUE;
    costsReductionValue = costsReductionValue == '' || isNaN(costsReductionValue) ? 0 : parseFloat(costsReductionValue);
    var costsReductionAdditionallyUpperlimit = constantData.COSTS_REDUCTION_ADDITIONALLY_UPPERLIMIT;
    costsReductionAdditionallyUpperlimit = costsReductionAdditionallyUpperlimit == '' || isNaN(costsReductionAdditionallyUpperlimit) ? 0 : parseFloat(costsReductionAdditionallyUpperlimit);
    var costsReductionAdditionallyPercent = constantData.COSTS_REDUCTION_ADDITIONALLY_PERCENT;
    costsReductionAdditionallyPercent = costsReductionAdditionallyPercent == '' || isNaN(costsReductionAdditionallyPercent) ? 0 : parseFloat(costsReductionAdditionallyPercent);
    var costsReductionAdditionallyLowerlimit = constantData.COSTS_REDUCTION_ADDITIONALLY_LOWERLIMIT;
    costsReductionAdditionallyLowerlimit = costsReductionAdditionallyLowerlimit == '' || isNaN(costsReductionAdditionallyLowerlimit) ? 0 : parseFloat(costsReductionAdditionallyLowerlimit);

    var totalDiscount = 0;
    var discountPer = 0;
    var oneM = 1000000;
    if (totalCost <= costsReductionAdditionallyUpperlimit) {
        discountPer = (10 / costsReductionValue) * totalCost;
    } else if (totalCost <= costsReductionAdditionallyLowerlimit) {
        discountPer = ((10 + ((10 / (costsReductionAdditionallyLowerlimit - costsReductionAdditionallyUpperlimit)) * totalCost)) - (10 / 3));
    } else {
        discountPer = 20;
    }

    totalDiscount = discountPer * totalCost / 100;

    return totalDiscount;
}

function manageContentHeight() {
    $('.stadiumData').each(function() {
        if ($(this).find('.leftTitle').innerHeight() > $(this).find('.rightTitle').innerHeight()) {
            $(this).find('.rightTitle').css('min-height', $(this).find('.leftTitle').innerHeight());
        } else if ($(this).find('.leftTitle').innerHeight() < $(this).find('.rightTitle').innerHeight()) {
            $(this).find('.leftTitle').css('min-height', $(this).find('.rightTitle').innerHeight());
        }

        if ($(this).find('.leftSubTitle').innerHeight() > $(this).find('.rightSubTitle').innerHeight()) {
            $(this).find('.rightSubTitle').css('min-height', $(this).find('.leftSubTitle').innerHeight());
        } else if ($(this).find('.leftSubTitle').innerHeight() < $(this).find('.rightSubTitle').innerHeight()) {
            $(this).find('.leftSubTitle').css('min-height', $(this).find('.rightSubTitle').innerHeight());
        }

        if ($(this).find('.firstLeftContent').innerHeight() > $(this).find('.firstRightContent').innerHeight()) {
            $(this).find('.firstRightContent').css('min-height', $(this).find('.firstLeftContent').innerHeight());
        } else if ($(this).find('.firstLeftContent').innerHeight() < $(this).find('.firstRightContent').innerHeight()) {
            $(this).find('.firstLeftContent').css('min-height', $(this).find('.firstRightContent').innerHeight());
        }

        if ($(this).find('.secondLeftContent').innerHeight() > $(this).find('.secondRightContent').innerHeight()) {
            $(this).find('.secondRightContent').css('min-height', $(this).find('.secondLeftContent').innerHeight());
        } else if ($(this).find('.secondLeftContent').innerHeight() < $(this).find('.secondRightContent').innerHeight()) {
            $(this).find('.secondLeftContent').css('min-height', $(this).find('.secondRightContent').innerHeight());
        }

        if ($(this).find('.thirdLeftContent').innerHeight() > $(this).find('.thirdRightContent').innerHeight()) {
            $(this).find('.thirdRightContent').css('min-height', $(this).find('.thirdLeftContent').innerHeight());
        } else if ($(this).find('.thirdLeftContent').innerHeight() < $(this).find('.thirdRightContent').innerHeight()) {
            $(this).find('.thirdLeftContent').css('min-height', $(this).find('.thirdRightContent').innerHeight());
        }

        if ($(this).find('.fourthLeftContent').innerHeight() > $(this).find('.fourthRightContent').innerHeight()) {
            $(this).find('.fourthRightContent').css('min-height', $(this).find('.fourthLeftContent').innerHeight());
        } else if ($(this).find('.fourthLeftContent').innerHeight() < $(this).find('.fourthRightContent').innerHeight()) {
            $(this).find('.fourthLeftContent').css('min-height', $(this).find('.fourthRightContent').innerHeight());
        }
    });
}