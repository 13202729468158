(function(self, $) {
    var season = 0;
    var matchday = 0;
    var week = 0;
    var day = 0;

    var RealtimeDaysTillSeasonHalfTime = 17; // TODO need to be set via init
    var RealtimeDaysPerSeason = 44; // TODO need to be set via init

    $(document).ajaxSuccess(function(event, xhr, settings) {
        var balance;

        var newSeason = xhr.getResponseHeader('ONLINELIGA-Season');
        var newMatchday = xhr.getResponseHeader('ONLINELIGA-Matchday');
        var newWeek = xhr.getResponseHeader('ONLINELIGA-Week');
        var newDay = xhr.getResponseHeader('ONLINELIGA-Day');
        var newBalance = xhr.getResponseHeader('ONLINELIGA-Balance');

        if (newSeason) season = parseInt(newSeason);
        if (newMatchday) matchday = parseInt(newMatchday);
        if (newWeek) week = parseInt(newWeek);
        if (newDay) day = newDay;
        if (newBalance) balance = parseInt(newBalance);

        olMobileApp.updateHeader({
            balance: balance,
            day: day,
            season: season
        });
    });

    self.getCurrentMatchday = function() {
        return matchday;
    };

    self.getCurrentSeason = function() {
        return season;
    };

    self.getCurrentWeek = function() {
        return week;
    };

    self.weekToWeekOverflow = function(week) {
        if (typeof week === typeof undefined)
            return;

        week = parseInt(week);
        return ((week - 1) % RealtimeDaysPerSeason) + 1;
    };

    self.weekToMatchdayOverflow = function(week) {
        if (typeof week === typeof undefined)
            return;

        week = parseInt(week);
        return self.weekToMatchday(self.weekToWeekOverflow(week));
    };

    self.weekToMatchday = function(week) {
        if (typeof week === typeof undefined)
            return;

        week = parseInt(week);
        if (week <= RealtimeDaysTillSeasonHalfTime)
            return week;
        else if (week <= (RealtimeDaysTillSeasonHalfTime + 3))
            return 17;
        else
            return Math.min(34, week - 3);
    };

    self.init = function(data) {
        if (data.week) {
            week = data.week;
        }

        if (data.season) {
            season = data.season;
        }

        if (data.matchday) {
            matchday = data.matchday;
        }
    };
})(window.olTimeHelper = window.olTimeHelper || {}, jQuery);