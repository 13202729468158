"use strict";
(function(olSponsorSettings, $, undefined) {

    olSponsorSettings.page = '';
    var buttonSelector = '.ol-sponsor-input-button button:visible:not(.sponsor-settings-save-all):not(.ol-sponsor-input-field-image-marker):not(.button-sponsor-preview):not(#sponsorInputButton0_logoId)';



    //--- Einstellungen > Einstellungen ---

    olSponsorSettings.initSettings = function() {

        olSponsorSettings.page = 'settings';

        // Season info
        olSponsorSettings.selectSeason(0);

        // GeoId-Autocomplete
        $('.ol-sponsor-input-field-text[data-hiddenId]').each(function(i, o) {
            var $input = $(o);
            var $hidden = $('#'+$input.attr('data-hiddenId'));
            var $button = $('#'+$input.attr('data-buttonId'));
            $input.typeahead({
                minLength: 1,
                limit: 10,
                items: 100,
                matcher: function(item) {return true;},
                updater: function(item) {
                    $hidden.val(item.id);
                    $input.attr('data-typeahead-value', item.name);
                    $input.typeahead('close');
                    $input.addClass('typeaheadSelected');
                    $button.prop('disabled', false);
                    return item;
                }
                //,afterSelect: function(item) {console.log('afterSelect:', item);}
            });
        });
        if (!window.olSponsorWizardRan) {
            olSponsorSettings.wizardStep();
        }
    };

    function checkSaveallButton() {
        if ($(buttonSelector).size() == 0) {
            $('.sponsor-settings-save-all').hide();
            olSponsorSettings.wizardStep();
            olAnchorNavigation.clearConfirmationCheck();
        }
    }

    olSponsorSettings.wizardStep = function() {
        var url = '/sponsor/settings/checkfields';

        if (!window.olSponsorWizardRan) {
            url += '?ignoreLongText=true';
        }

        $.get(url, function(res) {
            if (res.err == "") return;
            if (window.olSponsorWizardStep && res.step <= window.olSponsorWizardStep) {
                return;
            }

            window.olSponsorWizardStep = res.step;
            olMsgBox.question(res.err, res.missingText, function() {
                olAnchorNavigation.load(res.url, function() {
                    if (res.scrollTo) {
                        olGUI.scrollTo(res.scrollTo, 150);
                    } else {
                        olGUI.scrollTo('.ol-sponsor-input-field-error:visible', 150);
                    }
                });
            }, function() {}, res.btn);
        });

        window.olSponsorWizardRan = true;
    }

    olSponsorSettings.onClickPreviewButton = function() {
        if ($('.nologo:visible').size() > 0 && $("#sponsorInputField_external_media").val() == "") {
            olMsgBox.msg(Lang.trans('js/sponsor.preview'), Lang.trans('js/sponsor.msgUploadLogoFirst'), removeLoadingAnimationFromElement);
        } else {
            olOverlayWindow.load('/sponsor/sponsoring/preview', {}, removeLoadingAnimationFromElement);
        }
    }

    olSponsorSettings.onSelectSeason = function(event) {
        var $element = $(event.target);
        var season = Number($element.attr('data-value'));
        olSponsorSettings.selectSeason(season);
    };

    olSponsorSettings.selectSeason = function(season) {
        olSponsorSettings.refreshSeasonHead(season);
    }

    olSponsorSettings.refreshSeasonHead = function(season) {
        var data = {season: season};

        $anchor = $('.ol-sponsor-settings-head');
        addLoadingAnimationToElement($anchor, { size: 'small' });

        $.get('/sponsor/seasoninfo', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);

            $('#sponsorSettingsSeasonInfo_numTeamsTotal').text(olGUI.formatNumber(rc.numTeamsTotalFmt));
            //console.log(rc);
            if (rc.numTeamsNow != -1) {
                $('#sponsorSettingsSeasonInfo_numTeamsNow').text(olGUI.formatNumber(rc.numTeamsNowFmt));
                $('#sponsorSettingsSeasonInfo_numTeamsTotal').show()
                $('.teams-now-label').show();
                $('.finance-account-overview-box').removeClass('old-season');
            } else {
                $('#sponsorSettingsSeasonInfo_numTeamsNow').text(olGUI.formatNumber(rc.numTeamsTotalFmt));
                $('#sponsorSettingsSeasonInfo_numTeamsTotal').hide()
                $('.teams-now-label').hide();
                $('.finance-account-overview-box').addClass('old-season');
            }

            $('#sponsorSettingsSeasonInfo_numViews').text(olGUI.formatNumber(rc.numViewsFmt));

            if (rc.cpm > 0) {
                $('.cpm-label').hide();
                $('#sponsorSettingsSeasonInfo_projectedViews').text(olGUI.formatNumber(rc.projectedViews)).show();
                $('#sponsorSettingsSeasonInfo_cpm').show().text(rc.cpmFmt2);
            } else {
                $('#sponsorSettingsSeasonInfo_cpm').hide();
                $('#sponsorSettingsSeasonInfo_projectedViews').text(olGUI.formatNumber(rc.projectedViews)).hide();

                if (rc.numViews > 0) {
                    $('.cpm-label').text(Lang.trans('js/sponsor.isBeenCalculated'));
                } else {
                    $('.cpm-label').text('-');
                }
                $('.cpm-label').show();
            }
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** selectSeason failed:", data);
        });
    }



    //--- Einstellungen > Mein Konto ---

    olSponsorSettings.initAccount = function() {
        olSponsorSettings.page = 'account';

        // Season info
        //TODO preselect
        olSponsorSettings.selectSeason(0);
    };


    olSponsorSettings.unlockSponsor = function() {
        $.post('/sponsor/settings/adminunlock', function() {
            olDialog.message('#msgSponsorUnlockSuccess', null, function() {
                olAnchorNavigation.reload();
            });
        }).fail(function() {
            console.log('*** unlockSponsor failed!')
            olDialog.message('#msgSponsorUnlockError');
        });
    }



    //--- input sections ---

    olSponsorSettings.onChangeInputField = function(event) {
        var $element = $(event.target);
        var $input = $element,
            $button = $('#'+$element.attr('data-buttonId'));
        var oldValue = $input.attr('data-value'),
            value = $input.val();
        var changed = (oldValue != value);

        setSaveState($button, changed? 'save' : '');
        if (!changed) $input.removeClass('ol-sponsor-input-field-error').removeClass('unsaved');;

        if (changed) {
            $input.addClass('unsaved');
            $('.sponsor-settings-save-all').show();

            olAnchorNavigation.addConfirmationCheck();
        }

        var hiddenId = $input.attr('data-hiddenId');
        if (hiddenId) {
            var oldValue2 = $input.attr('data-typeahead-value');
            var changed2 = (oldValue2 != value);
            $button.removeClass('typeaheadOpen');
            if (changed2) {
                var $hidden = $('#'+hiddenId);
                setSaveState($button, 'save');
                $button.addClass('typeaheadOpen');
                var data = {location: value};
                $.get('/sponsor/settings/getlocation', data, function(rc) {
                    $input.data('typeahead').source = rc;
                    if (!$input.hasClass('typeaheadSelected'))
                        $input.typeahead('val', 'cookie')
                              .trigger('input')
                              .typeahead('open');
                }, 'json');
            } else {
                checkSaveallButton()
            }
        }
        return changed;
    };

    olSponsorSettings.onKeyInputField = function(event) {
        var element = event.target,
            $input = $(element),
            $button = $('#'+$input.attr('data-buttonId'));
        var hiddenId = $input.attr('data-hiddenId'),
            isTextarea = (element.tagName.toLowerCase() == 'textarea');

        if (isTextarea) olSponsorSettings.countCharsFor(element);

        var typeaheadWasOpen = hiddenId && $button.hasClass('typeaheadOpen');
        if (hiddenId) $input.removeClass('typeaheadSelected');

        var isNavKey = (event.key == 'ArrowUp' || event.key == 'ArrowDown');
        var changed = isNavKey? false : olSponsorSettings.onChangeInputField(event);
        if (event.key == 'Enter') {
            if (typeaheadWasOpen) {
                // User Enter for selecting the value
                //var $hidden = $('#'+hiddenId);
                var item = $input.typeahead('getActive');
                $input.val(item.name);
            } else if (changed) {
                if (isTextarea && !event.ctrlKey) return;
                if (hiddenId) $input.typeahead('close');
                event.preventDefault();
                event.stopPropagation();
                saveInputField($input, $button);
            }
        }
    };

    olSponsorSettings.countCharsFor = function(element) {
        var $element = $(element);
        var n = $element.val().length;
        var max = Number($element.attr('data-maxLength')) || 0;
        if (max <= 0) return;

        var $count = $element.nextAll('.ol-sponsor-input-field-count');
        if ($count.length == 0) return;

        var text = $('#msgCharCount').html()
                   .replace(/:cur/, n)
                   .replace(/:max/, max);
        $count.html(text);
    };

    olSponsorSettings.onSaveInputField = function(event) {
        var $element = $(event.target);
        var $input = $('#'+$element.attr('data-inputId')),
            $button = $element;
        saveInputField($input, $button);
    };

    function saveInputField($input, $button) {
        var hiddenId = $input.attr('data-hiddenId');
        var data = {
            name: $input.prop('name'),
            value: $input.val(),
            required: ($input.attr('data-required') == 1)? 1 : 0,
            id: hiddenId? Number($('#'+hiddenId).val()) : 0
        };

        if (data.required && data.value.trim() == '') {
            var text = $('#msgInputEmpty').html();
            $input.addClass('ol-sponsor-input-field-error');
            $input.val($input.data('value'));
            $input[0].focus();
            setSaveState($button, 'error', text);
            return;
        }

        $input.prop('disabled', true);
        $input.removeClass('ol-sponsor-input-field-error');

        var $anchor = $input.closest('.ol-sponsor-input-section');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.post('/sponsor/settings/setdata', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
            if (rc.err) {
                $input.prop('disabled', false);
                $input.addClass('ol-sponsor-input-field-error');
                $input[0].focus();

                setSaveState($button, 'error', olTrans.tmlToHtml(rc.err));
            } else {
                var text = $('#msgInputSaved').html();
                $input.attr('data-value', data.value);
                $input.prop('disabled', false);
                setSaveState($button, 'success');

                if (data.name == 'companyname') {
                    $('.ol-nav-team-banner .ol-nav-team-name').html(data.value);
                } else if (data.name == 'logoId') {
                    //$('.ol-nav-team-banner .ol-nav-team-logo-left').html(...);
                }

                $input.removeClass('unsaved');
                checkSaveallButton()
            }

            if (hiddenId) $input.typeahead('close');
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** saveInputField failed:", data);
        });
    };

    // also for input groups
    var msgTimeout = 4000;
    var msgTimer = null;
    function setSaveState($button, state, errText) {
        if (state === undefined) state = '';
        if (errText === undefined) errText = $('#msgInputSaveError').text();

        var $success = $('#'+$button.attr('data-successId'));
        var $error = $('#'+$button.attr('data-errorId'));
        var setTimer = false;

        if (state == 'success') {
            $button.prop('disabled', true);
            $success.css('display', 'inline-block');
            $error.css('display', 'none');
            setTimer = true;
            checkSaveallButton()
        } else if (state == 'error') {
            $button.prop('disabled', true);
            $success.css('display', 'none');
            $('.ol-sponsor-input-field-message-text', $error).html(errText);
            $error.css('display', 'inline-block');
            setTimer = true;
        } else if (state == 'save') {
            $button.prop('disabled', false);
            $success.css('display', 'none');
            $error.css('display', 'none');
        } else {
            $button.prop('disabled', true);
            checkSaveallButton()
            $success.css('display', 'none');
            $error.css('display', 'none');
        }

        if (setTimer) {
            if (msgTimer) clearTimeout(msgTimer);
            msgTimer = setTimeout(function() {
                $success.css('display', 'none');
                $error.css('display', 'none');
            }, msgTimeout);
        }
    }


    olSponsorSettings.onEditImage = function(event) {
        var $element = $(event.target);
        var $img = $('#'+$element.attr('data-imageId')),
            $button = $element;

        olOverlayWindow.load('/sponsor/settings/editlogo');
    }

    olSponsorSettings.onLogoChanged = function(rc) {
        olDialog.messageOld($('#msgLogoChanged').html(), function() {
            var items = {
                small: {
                    $frame: $('.ol-nav-sponsor-logo-small .ol-nav-sponsor-logo-frame'),
                    $img: $('.ol-nav-sponsor-logo-small .ol-nav-sponsor-logo-frame img'),
                    $placeholder: $('.ol-nav-sponsor-logo-small + .icon-company-placeholder'),
                    data: rc.small,
                },
                medium: {
                    $frame: $('.ol-nav-sponsor-logo-medium .ol-nav-sponsor-logo-frame'),
                    $img: $('.ol-nav-sponsor-logo-medium .ol-nav-sponsor-logo-frame img'),
                    $placeholder: $('.ol-nav-sponsor-logo-medium + .icon-company-placeholder'),
                    data: rc.medium
                },
                big: {
                    $frame: $('#sponsorInputImage_logoId').parent(),
                    $img: $('#sponsorInputImage_logoId'),
                    $placeholder: null,
                    data: rc.big
                }
            };
//
            // Update canvas
            var $item = $('.ol-nav-team-banner.ol-team-name.ol-nav-sponsor-name canvas.sponsor-logo-responsive');
            $item.removeClass('initialized');
            $item.attr('data-offsetX', rc.responsive.offsetX);
            $item.attr('data-offsetY', rc.responsive.offsetY);
            $item.attr('data-zoom', rc.responsive.zoom);
            $item.attr('data-src', rc.responsive.url);
            $item.attr('data-height', rc.responsive.height);
            $item.attr('data-width', rc.responsive.width);
            $item.show();
            olSponsorLogoResponsive.init();

            $('.icon-company-placeholder').hide();

            // Update logo displays
            for (var kind in items) {
                var item = items[kind], data = item.data;
                item.$frame.css({
                    width: data.frameWidth,
                    height: data.frameHeight
                });
                item.$img.css({
                    transformOrigin: 'left top',
                    transform: 'scale('+data.zoom+')',
                    width: data.width+'px',
                    height: data.height+'px',
                    marginLeft: data.offsetX+'px',
                    marginTop: data.offsetY+'px'
                });
                item.$img.attr('src', data.url);
                item.$img.removeClass('sponsor-logo-default');

                if (item.$placeholder) {
                    item.$placeholder.css('display', 'none');
                    item.$frame.parent().css('display', 'inline-block');
                }
            }

            // Remove missing marking
            var $input = $('#sponsorInputField_logoId'),
                $frameDiv = $input.parent().find('.ol-sponsor-input-field-image'),
                $frameBorder = $frameDiv.find('.ol-sponsor-input-field-image-frame-border');
//                $frame = $frameDiv.find('.ol-sponsor-input-field-image-frame');
            $frameDiv.removeClass('nologo');
            $frameBorder.removeClass('ol-sponsor-input-field-error');

            // Show logo and button in section
            $('.ol-sponsor-input-field-image-marker').removeClass('nologo').addClass('haslogo');

            // Close editor overlay when ready
            olOverlayWindow.close();
            setTimeout(olSponsorSettings.wizardStep, 200);
        });
    };


    olSponsorSettings.onChangeInputGroupField = function(event) {
        var $field = $(event.target),
            $group = $field.closest('.ol-sponsor-input-section'),
            $button = $group.find('.ol-sponsor-input-button button');

        var changed = inputGroupFieldsHaveChanged($group);

        setSaveState($button, changed? 'save' : '');
        if (!changed) {
            $field.removeClass('ol-sponsor-input-field-error').removeClass('unsaved');
        } else {
            $field.addClass('unsaved');
            $('.sponsor-settings-save-all').show();
            olAnchorNavigation.addConfirmationCheck();
        }

        return changed;
    };

    olSponsorSettings.onChoiceInputGroupField = function(event) {
        var $li_a = $(event.target),
            $field = $li_a.closest('.ol-sponsor-input-field-choice'),
            $choice = $field.find('.ol-dropdown-text'),

            $group = $field.closest('.ol-sponsor-input-section'),
            $button = $group.find('.ol-sponsor-input-button button'),

            value = $li_a.attr('data-value'),
            label = $li_a.text().trim();

        $choice.attr('data-value', value);
        $choice.attr('data-label', label);
        $choice.html(label);

        var changed = inputGroupFieldsHaveChanged($group);

        setSaveState($button, changed? 'save' : '');
        if (!changed) {
            $field.removeClass('ol-sponsor-input-field-error').removeClass('unsaved');
        } else {
            $field.addClass('unsaved');
            $('.sponsor-settings-save-all').show();
            olAnchorNavigation.addConfirmationCheck();
        }

        return changed;
    };

    olSponsorSettings.onKeyInputGroupField = function(event) {
        var $element = $(event.target),
            $group = $element.closest('.ol-sponsor-input-section');
        var changed = olSponsorSettings.onChangeInputGroupField(event);
        if (event.key == 'Enter' && changed) {
            event.preventDefault();
            event.stopPropagation();
            saveInputGroup($group);
        }
    };

    function getInputGroupFieldValue($field) {
        var value = '';
        if ($field && $field.length) {
            var type = $field.attr('data-type') || '';
            if (type == 'choice') {
                value = $field.find('.ol-dropdown-text').attr('data-value') || '';
            } else {
                value = $field.val().trim();
            }
        }
        return value;
    }

    function getInputGroupFieldInfo($field) {
        var info = {
            $field: $field,
            $input: $field,
            name: '',
            type: '',
            value: '',
            label: '',
            oldValue: '',
            required: 0,
            // oldRequired: 0,
            // requiredChanged: 0,
            minLength: -1,
            maxLength: -1,
            changed: false,

            setValue: function(value) {},
            setOldValue: function(value) {}
        };
        if ($field && $field.length) {
            info.type = $field.attr('data-type') || '';
            if (info.type == 'choice') {
                var $choice = $field.find('.ol-dropdown-text');
                info.$input = $choice;
                info.label = $choice.attr('data-label');
                info.value = $choice.attr('data-value');
                info.oldValue = $field.attr('data-value');

                info.setValue = function(value, label) {
                    this.$input.attr('data-value', value);
                    this.$input.attr('data-label', label);
                    this.$input.text(label);
                };
            } else /*info.type == 'text'*/ {
                // info.label = '';
                info.value = $field.val().trim();
                info.oldValue = $field.attr('data-value');

                info.setValue = function(value) {
                    this.$field.val(value);
                };
            }
            info.name = $field.attr('name') || '';

            // info.oldRequired = (parseInt($field.attr('data-required')) != 0)? 1 : 0;
            var requiredIf = jsonOf($field.attr('data-requiredIf'));
            var requiredIfNot = jsonOf($field.attr('data-requiredIfNot'));
            if (requiredIf) {
                var req = true;
                for (var key in requiredIf) {
                    if (!key) continue;
                    var value = requiredIf[key];
                    var $rfield = $('.ol-sponsor-input-group-field[name="'+key+'"]');
                    if (!$rfield.length) continue;
                    var rvalue = getInputGroupFieldValue($rfield);
                    req = req && (rvalue == value);
                }
                info.required = req? 1 : 0;
                info.$field.attr('data-required', info.required);
            } else if (requiredIfNot) {
                var req = true;
                for (var key in requiredIfNot) {
                    if (!key) continue;
                    var value = requiredIfNot[key];
                    var $rfield = $('.ol-sponsor-input-group-field[name="'+key+'"]');
                    if (!$rfield.length) continue;
                    var rvalue = getInputGroupFieldValue($rfield);
                    req = req && (rvalue != value);
                }
                info.required = req? 1 : 0;
                info.$field.attr('data-required', info.required);
            } else {
                info.required = (parseInt($field.attr('data-required')) != 0)? 1 : 0;
            }
            // info.requiredChanged = (info.oldRequired != info.required);

            info.minLength = parseInt($field.attr('data-minLength'));
            if (isNaN(info.minLength)) info.minLength = -1;

            info.maxLength = parseInt($field.attr('data-maxLength'));
            if (isNaN(info.maxLength)) info.maxLength = -1;

            info.setOldValue = function(value) {
                this.$field.attr('data-value', value);
            };

            info.changed = (info.value != info.oldValue);
        }
		//console.log('info:',info);
        return info;
    }

    function inputGroupFieldsHaveChanged($group) {
        var $fields = $group.find('.ol-sponsor-input-group-field');
        for (var i = 0;  i < $fields.length;  i++) {
            // var $field = $fields.eq(i),
            //     type = $field.attr('data-type'),
            //     value = (($type == 'choice')? $field.attr('data-value') : $field.val()).trim(),
            //     $oldValue = ($type == 'choice')? $field.closest('.ol-sponsor-input-field-choice') : $field,
            //     oldValue = $oldValue.attr('data-value');
            // if (value != oldValue) return true;
            var $field = $fields.eq(i),
                info = getInputGroupFieldInfo($field);
            // if (info.changed) return true;
            if (info.changed) return true;
        }
        return false;
    }
	
	// Updates required status and error marker if name's value changed
	function updateInputGroupFieldsDependencies(name) {
		if (!name) return;
		var $allFields = $('.ol-sponsor-input-group-field');
		for (var i = 0;  i < $allFields.length;  i++) {
			var $field = $allFields.eq(i),
			    requiredIf = jsonOf($field.attr('data-requiredIf')),
			    requiredIfNot = jsonOf($field.attr('data-requiredIfNot'));
			//console.log('dependencies('+name+') // requiredIf:',requiredIf);
			if (requiredIf) {
                for (var key in requiredIf) {
                    if (key == name) {
                        var info = getInputGroupFieldInfo($field);
                        const error = Boolean(info.required && !info.value);
                        info.$field.toggleClass('ol-sponsor-input-field-error', error);
                        break;
                    }
                }    
            } else if (requiredIfNot) {
                for (var key in requiredIfNot) {
                    if (key == name) {
                        var info = getInputGroupFieldInfo($field);
                        const error = Boolean(info.required && !info.value);
                        info.$field.toggleClass('ol-sponsor-input-field-error', error);
                        break;
                    }
                }
            }
		}
	}

    olSponsorSettings.onSaveInputGroup = function(event) {
        var $element = $(event.target),
            $group = $element.closest('.ol-sponsor-input-section');
        saveInputGroup($group);
    };

    function saveInputGroup($group) {
        var $fields = $group.find('.ol-sponsor-input-group-field'),
            $button = $group.find('.ol-sponsor-input-button button');

        var data = {}, numChanges = 0, numEmpties = 0;
        for (var i = 0;  i < $fields.length;  i++) {
            var $field = $fields.eq(i),
                info = getInputGroupFieldInfo($field);

            // info.$field.toggleClass('ol-sponsor-input-field-error', info.required && !info.value);
            updateInputGroupFieldsDependencies(info.name);
            info.$field.removeClass('ol-sponsor-input-field-error');
            if (!info.changed) continue;

            if (info.required && info.value == '') {
                var text = $('#msgInputEmpty').html();
                info.$field.addClass('ol-sponsor-input-field-error');
                info.setValue(info.oldValue);
                info.$input.focus();
                setSaveState($button, 'error', text);
                numEmpties++;
            }

            data[info.name] = info.value;
            data[info.name+'_required'] = info.required;
            data[info.name+'_minLength'] = info.minLength;
            data[info.name+'_maxLength'] = info.maxLength;
            numChanges++;
        }
        //console.log('saveInputGroup: data:', data);
        if (numChanges == 0) return false;
        if (numEmpties > 0) return false;

        $fields.prop('disabled', true);
        $fields.removeClass('ol-sponsor-input-field-error');

        var $anchor = $group;
        addLoadingAnimationToElement($anchor, {size: 'small', instant: true});
        $.post('/sponsor/settings/setgroupdata', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
            //console.log('saveInputGroup: rc:', rc);
            var errs = rc;
            if (errs.length == 0) {
                var text = $('#msgInputSaved').html();
                $fields.each(function(i, field) {
                    // var $field = $(field);
                    // $field.attr('data-value', $field.val());
                    var info = getInputGroupFieldInfo($(field));
                    info.setOldValue(info.value);
                });
                $fields.prop('disabled', false);
                $fields.removeClass('unsaved');
                setSaveState($button, 'success');
            } else {
                $fields.removeClass('ol-sponsor-input-field-error')
                $fields.prop('disabled', false);
                var errTexts = [];
                for (var i = 0;  i < errs.length;  i++) {
                    var err = errs[i];
                    var $field = $fields.filter('[name="'+err.field+'"]');
                    $field.addClass('ol-sponsor-input-field-error');
                    if (i == 0) $fields[0].focus();
                    errTexts.push(err.err);
                }
                var errText = errTexts.join(', ') || $('#msgInputSaveError').text();
                setSaveState($button, 'error', olTrans.tmlToHtml(errText));
            }
            checkSaveallButton();
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** saveInputGroup failed:", data);
        });
        return true;
    }

    olSponsorSettings.saveAllInputs = function(event) {
        $(buttonSelector).each(function(el) {
            $(this).click();
        });
    }


    olSponsorSettings.onClickButtonPasswordChange = function(event) {
        olOverlayWindow.load('/sponsor/settings/password', {});
    }

    olSponsorSettings.onClickGeneratePassword  = function(event) {
        olMsgBox.question(
            Lang.trans('js/sponsor.resetPwd'),
            Lang.trans('js/sponsor.msgGeneratNewPwd'),
            function() { olOverlayWindow.postLoad('/sponsor/settings/generatepassword'); },
            function() { },
            Lang.trans('js/sponsor.yes'),
            Lang.trans('js/sponsor.cancel')
        );
    }

    olSponsorSettings.onKeyChangePasswordField = function(event) {
        var $field = $(event.target),
            $button = $('#btnChangePassword');

        var enabled = updateChangePasswordButton();
        if (event.key == 'Enter' && enabled) {
            olSponsorSettings.onChangePassword(event);
        }
    };

    function updateChangePasswordButton() {
        var f = document.getElementById('changePasswordForm');
        var empty = !f.old_password.value
                 || !f.new_password.value
                 || !f.new_password2.value;
        $('#btnChangePassword').prop('disabled', empty);
        return !empty;
    };

    olSponsorSettings.onChangePassword = function(event) {
        if (!updateChangePasswordButton()) return;

        var f = document.getElementById('changePasswordForm');
        var data = {
            old_password: f.old_password.value,
            new_password: f.new_password.value,
            new_password2: f.new_password2.value,
        };

        $('#btnChangePassword').addClass('ol-button-loading');

        var $anchor = $('#changePasswordForm');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.post('/sponsor/settings/password/change', data, function(rc) {
            removeLoadingAnimationFromElement($anchor);
            $('#btnChangePassword').removeClass('ol-button-loading');
            if (rc.err) {
                var err = olTrans.textToHtml(rc.err);
                $('#changePasswordError').html(err);
            } else {
                olDialog.messageOld($('#msgPasswordChanged').html(), function() {
                    olOverlayWindow.close();
                });
            }
        }, 'json').fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** onChangePassword failed:", data);
        });
    };

    olSponsorSettings.onSponsorScroll = function(event) {

        if ($(document).scrollTop() >= 425) {
            $('.ol-sponsor-data-costs').addClass('sticky');
        } else {
            $('.ol-sponsor-data-costs').removeClass('sticky');
        }
    }



//    var tmlOps = {
//        bold:   {tag: 'b', open: '<b>', close: '</b>', start: '!!', end: '!!'},
//        italic: {tag: 'i', open: '<i>', close: '</i>', start: '??', end: '??'}
//    };
//    olSponsorSettings.onClickTmlAreaButton = function(event) {
//        console.log('olSponsorSettings.onClickTmlAreaButton:', event);
//        var $element = $(event.target).closest('button'),
//            $input = $('#'+$element.attr('data-inputId')),
//            input = $input[0],
//            op = $element.attr('data-op');
//        console.log(op, $input);
//        if (!$input.length || !op) return;
//
//        var info = tmlOps[op] || null;
//        console.log('info:', info);
//        if (!info) {
//            console.log("invalid op '"+op+"'");
//            return;
//        }
//        var sel = window.getSelection();
//        console.log('sel:', sel);
//        if (!sel || !sel.rangeCount) return;
//        if (!input.contains(sel.anchorNode)) return;
//        var range = sel.getRangeAt(0);
//        if (!range) return;
//
//        var container = range.commonAncestorContainer,
//            parent = (container.nodeType == 3)? container.parentNode : container;
////      if (parent.tagName != 'DIV') parent = parent.parentNode;
//        var tag = parent.tagName.toLowerCase();
//        if (tag == 'div') tag = '';
//        console.log('range:', range);
//        console.log('container:', container);
//        console.log('parent:', parent);
//        console.log('tag:', tag);
//
//        if (tag == info.tag) {
//            var html = parent.innerHTML
//                             .replace(new RegExp(info.open, 'g'), '')
//                             .replace(new RegExp(info.close, 'g'), '');
//            console.log('before:', parent.innerHTML);
//            console.log('after:', html);
//            parent.outerHTML = html;
//        } else if (range.startContainer == range.endContainer) {
//            range.surroundContents(document.createElement(info.tag));
//        } else {
//            // not (yet) supported
////            var html = parent.innerHTML.replace(/<\/?[^/]+>/g, '');
////            console.log('before:', parent.innerHTML);
////            console.log('after:', html);
////            parent.outerHTML = html;
//        }
//        //range.collapse();
//        console.log('html:', input.innerHTML);
//    };



    //--- Tools ---

    // Return the sponsor config setting for `key` (without 'onlineliga.sponsor.' prefix).
    // Return `dflt` if the key is not in the config object.
    // If the key parameter is left out, return the whole sponsor config object.
	olSponsorSettings.config = function(key, dflt) {
        if (dflt === undefined) dflt = 0;
		var $config = $('#config-onlineliga-sponsor');
		var config = JSON.parse($config.attr('data-value'));
		if (!key) return config;
        return (key in config)? config[key] : dflt;
	}

    // Returns JSON value of `s` or `dflt` if invalid or undefined.
    function jsonOf(s, dflt) {
        if (dflt === undefined) dflt = null;
        var rc = dflt;
        try {
            if (s === undefined) return dflt;
            rc = JSON.parse(s);
        } catch (exc) {
            rc = dflt;
        }
        return rc;
    }


}(window.olSponsorSettings = window.olSponsorSettings || {}, jQuery));
