
/*
    Partner Specific Logic for SnackMedia
*/
(function(self, $, undefined) {
    self.init = function() {
        return;
    }

    self.canShowAds = function() {
        return typeof killSlot == "function" && typeof refreshBid  == "function";
    }

    self.getSpecialSlots = function(slotIds) {
        return [];
    }

    self.renderAds = function(slotIds) {
        return new Promise(function(resolve, reject) {
            killSlot(slotIds);
            refreshBid(slotIds);
            resolve();
        })
    }

    self.requestSpecialAds = function(slotIds) {
        return;
    }


    self.renderSpecialAds = function(slotIds) {
        return;
    }

    self.prepareSlot = function(slot) {
        var childContainerId, slotId;

        // Check if slot is displayed
        if (slot[0].offsetHeight == 0 && slot[0].offsetWidth == 0) {
            console.log("* Ads Debug | Skipping slot", slot[0], "because it is not rendered.");
            return null;
        }

        slotId = slot.data('name');
        childContainerId = slotId;

        slot.html("<div id='" + childContainerId + "'></div>");

        return childContainerId;
    };

})(olAdProviderSnack = window.olAdProviderSnack || {}, jQuery);

