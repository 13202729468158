/*
    Partner Specific Logic for Sportplatz media
*/
(function(self, $, undefined) {
    self.init = function() {
        console.log("* Ads Debug | SPM init");
    };

    // Prepares a slot to load an ad, empties the slot if there is already something loaded
    self.prepareSlot = function(slot) {
        console.log("* Ads Debug | SPM prepareSlot");
    };

    self.canShowAds = function() {
        console.log("* Ads Debug | SPM canShowAds: true");
        return true;
    };

    self.getSpecialSlots = function(slotIds) {
        console.log("* Ads Debug | SPM getSpecialSlots:", slotIds);
        return [];
    };

    self.renderAds = function(slotIds) {
        // Requests and renders ads in the given slotIds
        console.log("* Ads Debug | SPM renderAds:", slotIds);
        return null;
    };

    self.renderSpecialAds = function(specialSlots) {
        console.log("* Ads Debug | SPM renderSpecialAds:", specialSlots);
    };



    //--- Specific functions ---

    self.renderInfinitySlot = function(tagIndex) {
        console.log('* Ads Debug | Infinity | renderInfinitySlot('+tagIndex+')');
        if (olAppInfo.APP_MODE) {
            console.log('* Ads Debug | Infinity | *** APP_MODE => skipping');
            return;
        }

        // Infinity-Function available?
        if (typeof newInfinityAdUnit !== 'function') {
            console.log('* Ads Debug | Infinity | *** newInfinityAdUnit missing');
            setTimeout(function() {
                console.log('* Ads Debug | Infinity | --- Retrying after 2s...');
                self.renderInfinitySlot(tagIndex);
            }, 2000);
            return;
        }

        // Remove old slots on reload
        if (tagIndex == 1) {
            console.log('* Ads Debug | Infinity | --- if tagIndex=1');
            if (typeof adHandler === 'undefined' || typeof adHandler.service === 'undefined' || typeof adHandler.service.getAdUnitsByTag !== 'function') {
                console.log('* Ads Debug | Infinity | *** adHandler.service.getAdUnitsByTag missing');
                console.log('* Ads Debug | Infinity | *** adHandler:', adHandler);
                if (typeof adHandler !== 'undefined') console.log('* Ads Debug | Infinity | *** adHandler.service:', adHandler.service);
                if (typeof adHandler !== 'undefined' && 'service' in adHandler) console.log('* Ads Debug | Infinity | *** adHandler.service.getAdUnitsByTag:', adHandler.service.getAdUnitsByTag);
                return;
            }
            var adUnits = adHandler.service.getAdUnitsByTag('infinity');
            console.log('* Ads Debug | Infinity | --- adUnits:', adUnits);
            var nax = $('.ax-container').filter(function(i,o) { return /^infinity-\d+$/.test(o.id); }).length;
            console.log('* Ads Debug | Infinity | --- n='+adUnits.length+', nax='+nax);
            if (!adUnits) {
                console.log('* Ads Debug | Infinity | *** adUnits missing');
                return;
            }
            adUnits.disable('adUnit');
            adUnits.destroy();
            console.log('* Ads Debug | Infinity | ==> Removed old infinity slots');
        }

        // Add infinity slot
        console.log('* Ads Debug | Infinity | --- Calling newInfinityAdUnit...');
        newInfinityAdUnit(tagIndex);
        console.log('* Ads Debug | Infinity | ==> Added infinity slot #'+tagIndex);
    };

})(olAdProviderSpm = window.olAdProviderSpm || {}, jQuery);
