(function(olAnchorNavigation, $, undefined) {

    var lastHash = '';
    var lastMainUrl = '';
    var lastMainPlanUrl = '';
    var lastSubUrl = false;
    var isSticky = false;
    var stickyUrls = {};
    var except = ['postregister', 'selectleague'];

    function stripDomain(url) {
        //var prefixPos = url.search('de/');
        //if (prefixPos >= 0)
        //    url = url.substring(prefixPos + 3);
        var m = url.match(/({{domainRegex}})\//);
        if (m) {
            url = url.substring(m.index + m[0].length);
            return url;
        }

        var m = url.match(/\.(local|ofa)\//);
        if (m) {
            url = url.substring(m.index + m[0].length);
        }
        return url;
    }

    olAnchorNavigation.init = function() {
        setInterval(function() {
            if (lastHash != location.hash)
                onAnchorHasChanged();
        }, 100);
        $(function() {
            onAnchorHasChanged();
        });

        olMobileApp.listen('navigate', function(data) {
            olAnchorNavigation.load(data.target);
        });
        olMobileApp.listen('reload', function(data) {
            olAnchorNavigation.reload();
        });
        olMobileApp.listen('backButton', function(data) {
            if (olOverlayWindow.isOpen()) {
                olOverlayWindow.close();
            } else {
                window.history.back();
            }
        });
    };

    olAnchorNavigation.insertOrUpdateUrlParameter = function(url, name, value) {
        var href = location.href;
        var needle = url + "?" + name + "=";
        var index = href.indexOf(needle);
        if (index > 0) {
            index += needle.length;
            var end = href.indexOf(';', index);
            if (end > 0) {
                href = href.substring(0, index) + value + href.substring(end);
            }
        } else {
            var index = href.search(url);
            if (index > 0) {
                index += url.length;
                href = href.substring(0, index) + "?" + name + "=" + value + href.substring(index);
            }
        }
        if (location.href !== href) {
            var hashIdx = href.lastIndexOf('#');
            var hash = hashIdx >= 0 ? href.slice(hashIdx) : '';
            lastHash = hash;
            location.href = href;
        }
    };

    function _updateToggleButton(url) {
        var cut = url.search("\\?");
        url = (cut > 0 ? url.substring(0, cut) : url);
        if ($('.ol-button-toggle[data-sub-url="' + url + '"]').length > 0) {
            $('.ol-button-toggle.active').removeClass('active');
            $('.ol-button-toggle[data-sub-url="' + url + '"]').addClass('active');
        }
    }

    function _executeSubUrl(hash) {
        var subUrlStart = hash.search(";suburl=");
        var subUrl = (subUrlStart > 0 ? hash.substring(subUrlStart + 8) : false);

        var urlStart = hash.search("url=");
        var url = hash.substring(urlStart + 4);
        console.info('Url: ' + url);

        var targetStart = hash.search("target=");
        var targetString = hash.substring(targetStart);
        var target = false;
        if (targetStart >= 0) {
            targetEnd = targetString.indexOf(";", targetStart);
            target = targetString.substring(7, (targetEnd >= 0 ? targetEnd : undefined));
            console.info('Custom target: ' + target);
        }
        lastSubUrl = url;
        loadPageContent(url, $('#' + target), undefined, function() {
            _updateToggleButton(url);
            olMobileApp.loadStop();
        });
    }

    function _executeUrl(hash, force, callback, stickyScrollTo) {
        var subUrlStart = hash.search(";suburl=");
        var urlHash = subUrlStart >= 0 ? hash.substring(0, subUrlStart) : hash.substring(0);

        var targetStart = urlHash.search("target=");
        var targetString = urlHash.substring(targetStart);
        var target = false;
        if (targetStart >= 0) {
            var targetEnd = targetString.indexOf(";", targetStart);
            target = targetString.substring(7, (targetEnd >= 0 ? targetEnd : undefined));
            if (target == '')
                target = false;
            //console.info('Custom target: ' + target);
        }

        var subUrl = (subUrlStart > 0 ? hash.substring(subUrlStart + 8) : false);
        var subUrlRemoved = false;
        if (subUrl === false && lastSubUrl !== false) {
            var subUrlRemoved = true;
            lastSubUrl = false;
        }
        var urlStart = urlHash.search("url=");
        if (urlStart < 0)
            return;
        var urlEnd = urlHash.indexOf(";", urlStart + 4);
        var url = urlHash.substring(urlStart + 4, (urlEnd >= 0 ? urlEnd : undefined));
        var plainUrlEnd = url.indexOf("?");
        var plainUrl = url.substring(0, (plainUrlEnd >= 0 ? plainUrlEnd : undefined));
        plainUrlEnd = plainUrl.indexOf("/", plainUrl.length - 1);
        plainUrl = plainUrl.substring(0, (plainUrlEnd >= 0 ? plainUrlEnd : undefined));
        console.info('Url (Param): ' + url);
        console.info('Url (Plain): ' + plainUrl);
        var callbk = callback;
        if (subUrl !== false) {
            console.info('Sub-Url: ' + subUrl);
            callbk = function() {
                olGUI.unlockScroll();
                _executeSubUrl(subUrl);
                if (typeof callback == "function") {
                    callback();
                }
            };
        }


        isSticky = isStickyUrl(plainUrl);
        if (isSticky)
            stickyUrls[plainUrl] = true;

        var stickyLastUrl = lastMainPlanUrl;
        lastUrlWasSticky = stickyUrls.hasOwnProperty(stickyLastUrl);
        newUrlIsSticky = stickyUrls.hasOwnProperty(plainUrl);

        console.log(lastMainUrl === url && !force)
        if (lastMainUrl === url && subUrlRemoved === false && !force) {
            if (typeof callbk == "function")
                callbk();
        } else {
            olMobileApp.loadStart();
            var event = new Event('olBeforePageChange');
            dispatchEvent(event);
            var clbk = function() {
                olAnalytics.pageview('', plainUrl);
                var event = new Event('olAfterPageChange');
                dispatchEvent(event);
                if ($.isFunction(callbk)) callbk();
            };

            console.log("loading", url);
            if(lastMainUrl !== url)
                olLeagueLicence.minimizeOverlayWindow();
            lastMainUrl = url;
            lastMainPlanUrl = plainUrl;
            lastSubUrl = false;
            setTimeout(function() {
                olCookieConsentSelection.navUpdate(url);
            }, 1);
            loadPage(url, undefined, clbk, (target !== false ? '#' + target : undefined), false, true, (newUrlIsSticky ? plainUrl : false), (lastUrlWasSticky ? stickyLastUrl : false), stickyScrollTo);
        }
    }

    function onAnchorHasChanged(force, callback, stickyScrollTo) {
        // direct call
        var route = location.pathname.replace(/^\//, '');
        var direct = !olAppInfo.APP_MODE && directLoadURLs.indexOf(route) >= 0;
        if (direct) {
            directPageLoaded(route);
            return;
        }
    
        // #url=
        if(typeof stickyScrollTo === typeof undefined) stickyScrollTo = true;
        var href = location.href;
        var hashIdx = href.lastIndexOf('#');
        var hash = hashIdx >= 0 ? href.slice(hashIdx) : '';
        lastHash = hash;
        if (hash.search('#') < 0) {
            if (location.pathname.length <= 1) {
                if (olConfig.isSponsorUser) {
                    hash = '#url=sponsor/settings/settings';
                } else {
                    hash = '#url=landingpage';
                }
            }

            if (force) {
                if (typeof callback === "function")
                    callback();
                location.reload();
                return;
            }
        }

        if (force) {
            var ignore = false;
            $(except).each(function(index, element) {
                if (location.href.indexOf(element) >= 0) {
                    ignore = true;
                    return false;
                }
            });
            if (ignore)
                return;
        }
        _executeUrl(hash, force, callback, stickyScrollTo);
    }

    olAnchorNavigation.loadSubPage = function(url, target, data, force) {
        if (data === undefined) data = false;
        if (data !== false) data = $.param(data);

        url = stripDomain(url);


        var hash = location.hash;
        var currentSubPage = hash.search('suburl');
        if (currentSubPage > 0)
            hash = hash.substring(0, currentSubPage - 1);
        if (hash.search('#') < 0)
            hash = '#';
        var newUrl = hash + ';suburl=' + 'target=' + $(target).attr('id') + ";url=" + url;
        if (data !== false)
            newUrl += "?" + data;

        window.location = newUrl;

        //if (force)
        //    onAnchorHasChanged(true);

        return false;
    };

    olAnchorNavigation.reload = function(callback) {
        onAnchorHasChanged(true, callback);
    };

    function isStickyUrl(url) {
        var result = false;
        $(autoNavSelectionMapping).each(function(index, object) {
            if (url.search(object.url) >= 0) {
                if (object.hasOwnProperty('subNav')) {
                    result = object.sticky;
                    return;
                }
            }
        });
        return result;
    };

    var confirmationCheckTitle, confirmationCheckText;
    window.confirmCheckActive = false;
    olAnchorNavigation.addConfirmationCheck = function(title, text) {
        if (!title) {
            title = Lang.trans('js/misc.warning');
        }
        if (!text) {
            text = Lang.trans('js/misc.msgUnsavedChanges');
        }
        confirmationCheckTitle = title;
        confirmationCheckText = text;
        window.confirmCheckActive = true;
    }

    olAnchorNavigation.clearConfirmationCheck = function() {
        window.confirmCheckActive = false;
    }

    function askConfirmation(url, data, customOverlayTaget, force) {
        olMsgBox.question(confirmationCheckTitle, confirmationCheckText, function() {
            window.confirmCheckActive = false;
            olAnchorNavigation.load(url, data, customOverlayTaget, force);
        }, function() {}, Lang.trans('js/misc.yes'), Lang.trans('js/misc.no'));
    }

    var directLoadURLs = olLayoutArrayRoutes.data;
    olAnchorNavigation.load = function(url, data, customOverlayTarget, force) {
        // update header information on user action if needed
        olGUI.updateHeaderInformations();
        if (confirmCheckActive) {
            askConfirmation(url, data, customOverlayTarget, force);
            return;
        }
        confirmCheckActive = false;

        if (url == "/") {
            url = "landingpage";
        }

        var callback = false;
        if (typeof data === "function") {
            callback = data;
            data = false;
        }

        if (data === undefined)
            data = false;
        if (data !== false)
            data = $.param(data);
        if (force === undefined)
            force = false;

        url = stripDomain(url);
        var route = url.replace(/\?.*$/, '').replace(/^\//, '');

        var newUrl;
        var direct = false;
        if (olAppInfo.APP_MODE || directLoadURLs.indexOf(route) < 0) {
            newUrl = '/#url=' + url;
            if (typeof customOverlayTarget === "string") {
                var target = customOverlayTarget.replace('#', '');
                if (target) newUrl += ';target='+target;
            }
        } else {
            newUrl = '/' + url.replace(/^\//, '');
            direct = true;
        }
        if (data !== false) {
            newUrl += '?' + data;
        }

        hideMobileNav();
        window.location = newUrl;
        if (direct) return true; //CK

        onAnchorHasChanged(force, callback, false);
        return false;
    };

    //same as olAnchorNavigation.load but opens in a new tab
    olAnchorNavigation.loadNewTab = function(url, data, customOverlayTarget, force) {
        // update header information on user action if needed
        olGUI.updateHeaderInformations();
        if (confirmCheckActive) {
            askConfirmation(url, data, customOverlayTarget, force);
            return;
        }
        confirmCheckActive = false;

        if (url == "/") {
            url = "landingpage";
        }

        var callback = false;
        if (typeof data === "function") {
            callback = data;
            data = false;
        }

        if (data === undefined)
            data = false;
        if (data !== false)
            data = $.param(data);
        if (force === undefined)
            force = false;

        url = stripDomain(url);
        var route = url.replace(/\?.*$/, '').replace(/^\//, '');

        var newUrl;
        var direct = false;
        if (olAppInfo.APP_MODE || directLoadURLs.indexOf(route) < 0) {
            newUrl = '/#url=' + url;
            if (typeof customOverlayTarget === "string") {
                var target = customOverlayTarget.replace('#', '');
                if (target) newUrl += ';target='+target;
            }
        } else {
            newUrl = '/' + url.replace(/^\//, '');
            direct = true;
        }
        if (data !== false) {
            newUrl += '?' + data;
        }

        hideMobileNav();
        window.open(newUrl, '_blank').focus();
        if (direct) return true; //CK

        onAnchorHasChanged(force, callback, false);
        return false;
    };

}(window.olAnchorNavigation = window.olAnchorNavigation || {}, jQuery));