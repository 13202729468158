(function(olYouthPlayer, $, undefined) {

    var youthPlayerTable = 1;

    function loadProgressBar(time) {
        var targetValue = parseFloat($(".ol-progressbar-large").attr("data-value")) + 0.01; // due to rounding error at $.animate
        $("#progressBar").width(targetValue + "%");
        $("#progressBarExpander").width((100 - targetValue) + "%");
        var width = $(".ol-progressbar-large").width();
        //console.info(width);
        var relWidth = (width * 0.09) < 150 ? (110 / width * 100) : 0;
        var left = targetValue > 80 ? Math.min(targetValue - Math.max(relWidth, 9)) : targetValue;
        if (time === 0) {
            $("#progressBarValue").css({
                "-webkit-transition": "none",
                "-moz-transition": "none",
                "-o-transition": "none",
                "transition": "none"
            });
        }
        $("#progressBarValue").css("left", left + "%");
        $({countNum: 0}).animate({countNum: targetValue}, {
            duration: time,
            easing: 'easeOutQuad',
            step: function() {
                var value = Math.floor(this.countNum * 10) / 10;
                $("#progressBarValue span:first-child").html(value + "%");
            },
            complete: function() {
                // set the value to the exact (rounded) value
                // because the value set in function above isnt always correct
                $("#progressBarValue span:first-child").html(targetValue.toFixed(1) + "%");
            }
        });
    }

    olYouthPlayer.init = function(relYouthAcademyValue, relStaffValue, relScoutingValue) {
        var targetValue = $(".ol-progressbar-large").attr("data-value") / 100;
        if ($(".ol-progressbar-large").width() * targetValue * relYouthAcademyValue < 50) {
            $("#progressBarYouthAcademyText").addClass('ol-youth-single-letter');
            $(".ol-youth-short-l").css('display', 'inline');
        }
        if ($(".ol-progressbar-large").width() * targetValue * relStaffValue < 50) {
            $("#progressBarStaffText").addClass('ol-youth-single-letter');
            $(".ol-youth-short-p").css('display', 'inline');
        }
        if ($(".ol-progressbar-large").width() * targetValue * relScoutingValue < 50) {
            $("#progressBarScoutingText").addClass('ol-youth-single-letter');
            $(".ol-youth-short-s").css('display', 'inline');
        }

        var selectedElement = $("#dropdownPlayerAttribute").dropdown().selectedElement();
        olGUI.onClickDropdownPlayerAttribute($(selectedElement).attr("data-value"), $(selectedElement).attr("data-type"), $(selectedElement).attr("data-unit"));
    };

    olYouthPlayer.showOverview = function() {
        loadProgressBar(2000);
    };

    olYouthPlayer.scoutWizardOnClickExecute = function() {
        var data = new Object();
        data.value = olGUI.stripNumericString($("#sliderInput").val());
        data.prioritiesChanged = prioritiesChanged ? 1 : 0;
        data.priorities = priorities;
        data.execute = 0;

        olOverlayWindow.addLoadingAnimation();

        $.getJSON('/office/youthplayer/scouting/modification', data, function(responseData) {
            olOverlayWindow.removeLoadingAnimation();
            if (responseData.check === "failed")
                olMsgBox.json(responseData);
            if (responseData.check === "success")
                olMsgBox.json(responseData, function() {
                    data.execute = 1;
                    $.getJSON('/office/youthplayer/scouting/modification', data, function() {
                        loadPage('/office/youthplayer', undefined, undefined, '');
                        olOverlayWindow.close();
                    });
                });
        });
    };
    
    olYouthPlayer.scoutWizardOnClickClose = function() {
        if (prioritiesChanged) {
            olMsgBox.json({
                'type': 'question',
                'header': Lang.trans('js/office.priority'),
                'content': Lang.trans('js/office.questionSureDiscardChanges'),
                'yes': Lang.trans('js/office.discard'),
                'no': Lang.trans('js/office.back')
            }, olOverlayWindow.close);
        } else {
            olOverlayWindow.close();
        }
    };

    olYouthPlayer.academyWizardOnClickExecute = function() {
        var data = new Object();
        data.value = olGUI.stripNumericString($("#sliderInput").val());
        data.execute = 0;

        olOverlayWindow.addLoadingAnimation();

        $.getJSON('/office/youthplayer/academy/modification', data, function(responseData) {
            olOverlayWindow.removeLoadingAnimation();
            if (responseData.check === "failed")
                olMsgBox.json(responseData);
            if (responseData.check === "success")
                olMsgBox.json(responseData, function() {
                    data.execute = 1;
                    $.getJSON('/office/youthplayer/academy/modification', data, function() {
                        olGUI.updateLiquidFunds();
                        loadPage('/office/youthplayer', undefined, undefined, '');
                        olOverlayWindow.close();
                    });
                });
        });
    };

    olYouthPlayer.contractWizardOnClickExecute = function(playerId, exec) {
        var data = new Object();
        data.value = olGUI.stripNumericString($("#inputSalary").val());
        data.duration = 2;//olGUI.stripNumericString($("#slider").val());
        data.youthPlayerId = playerId;
        data.execute = exec || 0;

        olOverlayWindow.addLoadingAnimation();

        $.getJSON('/office/youthplayer/contract/negotiation', data, function(responseData) {
            olOverlayWindow.removeLoadingAnimation();
            if (responseData.check === "invalid") {
                olMsgBox.json(responseData);
            }
            if (responseData.check === "done") {
                olMsgBox.json(responseData, function() {
                    loadPage('/office/youthplayer', undefined, function() {
                        olOverlayWindow.close();
                    });
                });
            }
            if (responseData.check === "stop") {
                olMsgBox.json(responseData);
            }
            if (responseData.check === "failed") {
                olMsgBox.json(responseData, function() {
                    $("#slider").val(responseData.duration);
                    olYouthPlayer.contractWizardOnClickExecute(playerId);
                });
            }
            if (responseData.check === "success")
                olMsgBox.json(responseData, function() {
                    data.execute = 1;
                    $.getJSON('/office/youthplayer/contract/negotiation', data, function(responseData2) {
                        olMsgBox.json(responseData2, function() {
                            loadPage('/office/youthplayer');
                            olOverlayWindow.close();
                        });
                    });
                });
        });
    };

    olYouthPlayer.staffWizardOnClickExecute = function() {
        var data = new Object();
        data.value = olGUI.stripNumericString($("#sliderInput").val());
        data.execute = 0;

        olOverlayWindow.addLoadingAnimation();

        $.getJSON("/office/youthplayer/staff/modification", data, function(responseData) {
            olOverlayWindow.removeLoadingAnimation();
            if (responseData.check === "failed")
                olMsgBox.json(responseData);
            if (responseData.check === "success")
                olMsgBox.json(responseData, function() {
                    data.execute = 1;
                    $.getJSON('/office/youthplayer/staff/modification', data, function() {
                        loadPage('/office/youthplayer', undefined, undefined, '');
                        olOverlayWindow.close();
                    });
                });
        });
    };

    olYouthPlayer.initContractWizard = function(stateId, daysPerSeason) {
        olGUI.colorizeStateColorElements(stateId);
        olGUI.colorizeStateColorElements(stateId, "color", true, ".ol-dark-state-color");
        olGUI.colorizeStateColorElements(stateId, "background-color", true, ".ol-dark-state-bg-color");

        $("#slider2").bootstrapSlider({scale: 'linear', tooltip: 'hide'});
        $("#slider2").slider().on("change", function(slideEvt) {
            var value = parseInt(slideEvt.value.newValue);
            $("#sliderInput").val(olGUI.numberWithPoints(value));
            $("#newValuePerWeek").text(olGUI.numberWithPoints(Math.ceil(value / daysPerSeason)));
        });
    };

    var timeOut = false;
    var lastRequest = false;

    olYouthPlayer.initWizard = function(stateId, daysPerSeason, academy, interpolation) {
        if (typeof academy === typeof undefined || academy === false)
            academy = false;
        else interpolation = JSON.parse(interpolation);
        olGUI.colorizeStateColorElements(stateId);
        olGUI.colorizeStateColorElements(stateId, "color", true, ".ol-dark-state-color");
        olGUI.colorizeStateColorElements(stateId, "background-color", true, ".ol-dark-state-bg-color");
        var max = $("#slider").attr("data-slider-max");
        var min = $("#slider").attr("data-slider-min");
        var diff = max - min;
        //if(academy)
        $("#slider").bootstrapSlider({scale: 'linear', tooltip: 'hide'});
        // else
        //     $("#slider").bootstrapSlider({ ticks: [min, (min + 0.5 * diff), max], ticks_positions: [0, 75, 100]});
        $("#slider").slider().on("change", function(slideEvt) {
            var minFirstStep = $(this).attr('data-slider-min-first-step');
            var value = parseInt(slideEvt.value.newValue);
            if (typeof minFirstStep !== typeof undefined && minFirstStep !== false) {
                if (value > 0 && value < minFirstStep)
                    slideEvt.value.newValue = value = minFirstStep;
                if (value < 0 && value > -minFirstStep)
                    slideEvt.value.newValue = value = -minFirstStep;
            }
            var newValue = parseInt($("#overallValue").attr("data-value")) + value;
            $("#sliderInput").val(olGUI.numberWithPoints(value));
            $("#newValue").text(olGUI.numberWithPoints(newValue));
            if (academy === false) {
                var totalMin = $(this).attr('data-slider-total-min');
                if (newValue > 0 && newValue < totalMin) {
                    slideEvt.value.newValue = -$("#overallValue").attr("data-value");
                    value = parseInt(slideEvt.value.newValue);
                    newValue = parseInt($("#overallValue").attr("data-value")) + value;
                    $("#sliderInput").val(olGUI.numberWithPoints(value));
                    $("#newValue").text(olGUI.numberWithPoints(newValue));
                }

                $("#newValueRunningCostsTotal").text(olGUI.numberWithPoints(newValue));
                $("#newValueRunningCosts").text(olGUI.numberWithPoints(value));
            } else {
                var value = parseFloat(slideEvt.value.newValue);
                var newValue = parseFloat($("#currentConstructionValue").length > 0 ? $("#currentConstructionValue").attr("data-value") : 0) + value;
                var runningCosts = 0;
                if (newValue >= interpolation.costs.thresh)
                    runningCosts = Math.floor(interpolation.costs.m * newValue + interpolation.costs.b + 0.5);
                else
                    runningCosts = Math.floor(interpolation.costs.m0 * newValue + interpolation.costs.b0 + 0.5);

                if (timeOut != false) {
                    clearTimeout(timeOut);
                    timeOut = false;
                    removeLoadingAnimationFromElement($('#playerDowngradeValueElement'));
                }

                if (value >= 0) {
                    $('#playerBuildingTime').show();
                    $('#playerDowngradeValue').hide();
                } else {
                    $('#playerBuildingTime').hide();
                    $('#playerDowngradeValue').show();
                    $('#playerDowngradeValueElement').html('');
                    $('#playerDowngradeValue').addClass('loading');
                    timeOut = setTimeout(function() {
                        if (lastRequest !== false) lastRequest.abort();
                        lastRequest = $('#playerDowngradeValueElement').getLoad('/office/youthplayer/academy/downgradefunds', {'value': value}, function(jqXHR, status, error) {
                            if (status != "abort")
                                $('#playerDowngradeValue').removeClass('loading');
                        });
                    }, 1000);
                }

                var lowX = 0, lowY = 0;
                var highX = 0, highY = 0;
                $(interpolation.duration).each(function(index, element) {
                    if (element[0] > Math.abs(value)) {
                        highX = element[0];
                        highY = element[1];
                        return false;
                    }
                    lowX = element[0];
                    lowY = element[1];
                });

                var m = (lowY - highY) / (lowX - highX);
                var b = lowY - m * lowX;
                var duration = m * value + b;

                var seasons = Math.floor(duration);
                var weeks = (duration - seasons) * daysPerSeason;
                var specialCase = false;
                if (seasons == 1 && weeks === 0)
                    specialCase = seasons * daysPerSeason;
                if (seasons == 0)
                    weeks = Math.max(1, weeks);
                weeks = Math.ceil(weeks);
                if (value > 0 && !specialCase && seasons > 0 && weeks > 0) {
                    $("#constructionTimeSeasons").show();
                    $("#constructionTimeSeasons").text(seasons);
                    if (seasons > 1) {
                        $("#constructionTimeDescSeasons").show();
                        $("#constructionTimeDescSeason").hide();
                    } else {
                        $("#constructionTimeDescSeasons").hide();
                        $("#constructionTimeDescSeason").show();
                    }
                } else {
                    $("#constructionTimeDescSeasons").hide();
                    $("#constructionTimeDescSeason").hide();
                    $("#constructionTimeSeasons").hide();
                }
                if (value > 0 && (weeks > 0 || specialCase !== false)) {
                    if (specialCase !== false)
                        weeks = specialCase;
                    $("#constructionTimeWeeks").text(weeks);
                    $("#constructionTimeWeeks").show();

                    if (weeks > 1) {
                        $("#constructionTimeDescWeeks").show();
                        $("#constructionTimeDescWeek").hide();
                    } else {
                        $("#constructionTimeDescWeeks").hide();
                        $("#constructionTimeDescWeek").show();
                    }
                } else {
                    $("#constructionTimeDescWeeks").hide();
                    $("#constructionTimeDescWeek").hide();
                    $("#constructionTimeWeeks").hide();
                }
                if (seasons > 0 && weeks > 0 && !specialCase)
                    $("#constructionTimeDescSeasonComma").show();
                else
                    $("#constructionTimeDescSeasonComma").hide();
                if (value <= 0) {
                    $("#constructionTimeDescSeasonComma").hide();
                    $("#constructionTimeDescInstant").show();
                } else {
                    $("#constructionTimeDescInstant").hide();
                }

                if (seasons > 0 && weeks == 0 && !specialCase) {
                    $("#constructionTimeSeasons").show();
                    $("#constructionTimeSeasons").text(seasons);

                    $("#constructionTimeDescWeeks").hide();
                    $("#constructionTimeDescWeek").hide();
                    
                    $("#constructionTimeDescSeasons").show();
                    $("#constructionTimeDescSeason").hide();
                }

                $('#constructionTimeDescNothing').hide();

                if (value == 0) {
                    $("#constructionTimeDescInstant").hide();
                    $('#constructionTimeDescNothing').show();
                }

                var curr = $("#newValueRunningCostsTotal").attr('data-current-running-costs');
                $("#newValueRunningCostsTotal").text(olGUI.numberWithPoints(runningCosts));
                $("#newValueRunningCosts").text(olGUI.numberWithPoints((runningCosts - curr)));
                $("#newValueTotal").text(olGUI.numberWithPoints(newValue));

                var newValuePerWeek = (value < 0 ? -1 : 1) * Math.ceil(runningCosts / daysPerSeason);
                $("#newValuePerWeek").text(olGUI.numberWithPoints(newValuePerWeek));
            }
        });
    };

    $(document).on('keyup', '.ol-wizard-input', function(event) {
        var val = olGUI.stripNumericString($(this).val());
        $(this).val(olGUI.numberWithPoints(val));
    });

    $(document).on('blur', '.ol-wizard-input', function(event) {
        var value = olGUI.stripNumericString($(this).val());
        if (value <= $(this).attr("max") && value >= $(this).attr("min")) {
            $(this).val(olGUI.numberWithPoints(value));
            $("#slider").bootstrapSlider('setValue', value);
            var event = jQuery.Event("slide");
            event.value = value;
            $('#slider').trigger(event);
        } else
            $(this).val(olGUI.numberWithPoints($("#slider").bootstrapSlider('getValue')));
    });

    olYouthPlayer.scoutWizardOnBlurInput = function(element) {
    };

    var priorities = {};
    var prioritiesInitial = {};
    var prioritiesChanged = false;
    olYouthPlayer.initDetailsWizard = function(prios) {
        priorities = {};
        prioritiesInitial = {};
        prioritiesChanged = false;

        $.each(prios, function(prio, pos) {
            priorities['prio' + prio] = pos;
            prioritiesInitial['prio' + prio] = pos;
        });
    };

    olYouthPlayer.onPriorityDropdownChange = function(element) {
        var prio = $(element).data('prio');
        var value = $(element).data('value');
        priorities['prio' + prio] = value;

        _updatePriorities();
    };

    olYouthPlayer.onPriorityUpClicked = function(element) {
        var prio = $(element).data('prio');
        if (prio <= 1) return;

        _swapPriorities(prio, prio - 1);
        _updatePriorities();
    };

    olYouthPlayer.onPriorityDownClicked = function(element) {
        var prio = $(element).data('prio');

        var length;
        for (length = 6; length > 0; length--)
            if (priorities.hasOwnProperty('prio' + length)) break;
        if (prio >= length) return;

        _swapPriorities(prio, prio + 1);
        _updatePriorities();
    };

    function _swapPriorities(prio1, prio2) {
        var pos1 = priorities['prio' + prio1];
        var pos2 = priorities['prio' + prio2];

        priorities['prio' + prio1] = pos2;
        priorities['prio' + prio2] = pos1;
    }

    olYouthPlayer.onPriorityDeleteClicked = function(element) {
        var prio = $(element).data('prio');

        var length;
        for (length = 6; length > 0; length--)
            if (priorities.hasOwnProperty('prio' + length)) break;
        for (var i = prio; i < length; i++)
            priorities['prio' + i] = priorities['prio' + (i + 1)];
        if (prio <= length)
            delete priorities['prio' + length];

        _updatePriorities();
    };

    function _updatePriorities() {
        var $priorities = $('#ol-youth-player-details');
        addLoadingAnimationToElement($priorities, {size: "small"});

        $.get('/office/youthplayer/scouting/priorities', priorities, function(view) {
            for (var i = 1; i <= 6; i++) {
                if (prioritiesInitial["prio" + i] !== priorities["prio" + i]) {
                    prioritiesChanged = true;
                    break;
                }
                prioritiesChanged = false;
            }
            $priorities.html(view);
            olYouthPlayer.makePrioritiesSortable();
            removeLoadingAnimationFromElement($priorities);
        });
    }

    olYouthPlayer.makePrioritiesSortable = function() {
        var container = '#ol-youth-player-detail-priorities';
        var entries = '.ol-priority-headerrow';
        var $container = $(container);
        var $entries = $(entries, $container);

        if (!$container.hasClass('sortable')) return;
        $container.removeClass('sortable');
        if ($entries.length <= 1) return;
        $container.addClass('sortable');

        $(container).sortable({
            containment: '#ol-youth-player-details',
            axis: 'y',
            handle: '.drag-handle',
            cursor: 'move',
            start: function(ev, ui) {
                $(ui.item).addClass('sortable-dragging');
            },
            stop: function(ev, ui) {
                $(ui.item).removeClass('sortable-dragging');
                _handlePriorityOrderChange();
            }
        });
    };

    function _handlePriorityOrderChange() {
        var $priorities = $('#ol-youth-player-detail-priorities > .ol-priority-headerrow');
        var oldPrios = $.extend(true, {}, priorities);
        var i = 1;
        $priorities.each(function() {
            priorities['prio' + i] = oldPrios['prio' + $(this).data('priority')];
            $('.ol-priority-text', this).text($(this).data('lang') + i++);
        });
        _updatePriorities();
    }

}(window.olYouthPlayer = window.olYouthPlayer || {}, jQuery)); 