window.olNavigationModules = window.olNavigationModules || {};

// constructor navigation module: search
window.olNavigationModules.search = (function(olNavSearch, $, packets, subcall, undefined) {
    var _search = "";
    var _min = -1;
    var _max = -1;
    var _user = true;
    var _team = true;
    var _league = true;
    var _subcallid = 0;

    // navigation, dropdowns, inputs, etc.
    var $nav;
    var $search;

    olNavSearch.init = function(config) {
        $nav = $('#navSearch');
        $search = $('#inputSearchKeyword', $nav);

        olNavSearch.reset();

        if (typeof config.max === typeof 0 && config.max > 0) {
            _max = config.max;
            if (typeof config.min === typeof 0 && config.min > 0 && config.min <= config.max) {
                _min = config.min;
            } else {
                _min = config.max;
            }
        }
        if (typeof config.search === typeof "" && config.search.length >= _min) {
            var s = config.search.substr(0, _max - 1);
            $search.val(s);
            _search = s;
        }
        if (typeof config.user === typeof 0) {
            _user = (config.user === 0) ? false : true;
        }
        if (typeof config.team === typeof 0) {
            _team = (config.team === 0) ? false : true;
        }
        if (typeof config.league === typeof 0) {
            _league = (config.league === 0) ? false : true;
        }
    };

    packets.search = function() {
        var data = {};

        if (_min <= 0 || _max <= 0 || _search.length < _min)
            return data;
        if (!_user && !_team && !_league)
            return data;

        data.text = _search.substr(0, _max - 1);
        data.user = _user ? 1 : 0;
        data.team = _team ? 1 : 0;
        data.league = _league ? 1 : 0;
        data = {search: data};

        return data;
    };

    olNavSearch.reset = function() {
        _search = "";
        _subcallid = 0;

        $search.val("");
    };

    olNavSearch.onChangeSearch = function(val) {
        if (typeof val !== typeof "" || (val.length > 0 && val.length < _min))
            return;

        _search = val.substr(0, _max - 1);

        setTimeout(function(callid) {
            if (_subcallid === callid) {
                _subcallid = 0;
                subcall();
            }
        }, 500, ++_subcallid);
    };
});
