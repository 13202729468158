(function(olTransferList, $, undefined )
{
    var maxFilterCount = 3;
    var currentFilterCount = 0;
    var currentView = false;
    var currentOfferOverview = false;
    var bidsViewOngoing = true;
    var playerViewCloseAfterActionCallback = false;
    var playerViewActionPerformed = false;
    var multiAccountWarning = false;
    // obj. to save filter in Session
    var saveTransferFilter = {
        attr: [], //filter attribute ID
        values: [], //filter values
        pos: false, //position filter
        freeAgents: false, //Free Agents filter (Sofortkauf) checkbox
        endedOffers: false, //Ended Offers filter checkbox
        liquidity: false //Liquidity filter checkbox
    };
    
    // update local obj. with saved filter from session:
    if (sessionStorage.getItem("transferFilter") !== null) {
        var transferFilter = JSON.parse(sessionStorage.transferFilter); 
        saveTransferFilter.filterCount = transferFilter.filterCount;
        saveTransferFilter.attr = transferFilter.attr;
        saveTransferFilter.values = transferFilter.values;
        saveTransferFilter.pos = transferFilter.pos; 
        saveTransferFilter.freeAgents = transferFilter.freeAgents;
        saveTransferFilter.endedOffers = transferFilter.endedOffers;
        saveTransferFilter.liquidity = transferFilter.liquidity;
    }
    
    function getTransferListWrapperSelector() {
        return $('#transferListWrapper' + currentView);
    }

    function getTransferListSelector() {
        return $('#transferList' + currentView);
    }

    // Layout uses seperate inputs for mobile/desktop, make selectors dynamic
    function getSalarySelector() {
        // return olGUI.getBootstrapDeviceSize() == 'ol-xs' ? '#salaryMobile' : '#salary';
        return '#salary';
    }

    function getTransferFeeSelector() {
        // return olGUI.getBootstrapDeviceSize() == 'ol-xs' ? '#transferFeeMobile' : '#transferFee';
        return '#transferFee';
    }

    function getAttributeSelector() {
        // return olGUI.getBootstrapDeviceSize() == 'ol-xs' ? '#dropdownTransferListPlayerAttributes2' : '#dropdownTransferListPlayerAttributes';
        return '#dropdownTransferListPlayerAttributes';
    }

    // auto update
    // numeric input
    var timeout = false;

    function _updateResultCounter(newNumber) {
        var oldCount = parseInt(olGUI.stripNumericString($("#resultCount").text()));
        $({countNum: oldCount}).animate({countNum: newNumber}, {
            duration: 1000,
            easing:'easeOutQuad',
            step: function()  {
                var newCount = parseInt(this.countNum);
                $("#resultCount").text(olGUI.numberWithPoints(newCount));
            },
            complete: function() {
                var newCount = parseInt(this.countNum);
                $("#resultCount").text(olGUI.numberWithPoints(newCount));
            }
        });
    }

    function _checkForAutoUpdate(element) {
        if (!$(element).parent().hasClass("ol-double-slider-label")) return;
        var value = $(element).val();
        var min = 0, max = 0;
        min = $(element).attr("data-min");
        var minNumberCount = min.length;
        max = $(element).attr("data-max");
        var maxNumberCount = max.length;
        var valNumberCount = value.length;
        if(timeout) clearTimeout(timeout);
        value = parseInt(value);
        min = parseInt(min);
        max = parseInt(max);
        if (value >= min && value <= max) {
            if ((min == 0 && max == 100 && value > 10) || valNumberCount == maxNumberCount) {
                olTransferList.search();
            } else {
                timeout = setTimeout(olTransferList.search, 2000);
            }
        }
    }

    $(document).on('keyup', '.ol-transfer-list-numeric-input', function(event) {
        setTimeout(function() {
            _checkForAutoUpdate(event.target);
        }, 1);
    });

    $(document).on('blur', '.ol-transfer-list-numeric-input', function(event) {
        setTimeout(function() {
            _checkForAutoUpdate(event.target);
        }, 1);
    });

    $(document).on('change', ".ol-transfer-list-double-slider", function(event) {
        var inputFrom = $(event.target).closest(".ol-tranfer-list-filter").find(".ol-double-slider-input-from");
        var inputTo = $(event.target).closest(".ol-tranfer-list-filter").find(".ol-double-slider-input-to");
        var values = $(event.target).val().split(",");
        $(inputFrom).val(olGUI.numberWithPoints(values[0]));
        $(inputTo).val(olGUI.numberWithPoints(values[1]));
    });

    $(document).on('click', ".olTableHeaderColumnToggle", function(event)  {
        setTimeout(function() {
            var parent = $(event.target).closest('.olTableHeaderColumnToggle');
            var dataToggle = parent.attr('data-toggle');
            if (dataToggle && dataToggle !== 'none') {
                olTransferList.sort.by = $(event.target).attr("data-value");
                olTransferList.sort.sorting = $(event.target).attr("data-toggle");

                olTransferList.search(false);
            }
        }, 2);
    });

    var lastBackToTopState = false;
    $("#backToTransferTop").hide();
    function transferListScroll () {
        if (!document.getElementById('backToTransferTop')) {
            return;
        }

        var scrollTop = getWindowScroll();
        var targetHeight;
        if (olAppInfo.APP_MODE && olAppInfo.APP_PLATFORM == "iphone") {
            targetHeight = getDocHeight();
        } else if (olAppInfo.APP_MODE) {
            targetHeight =  $(document).height() - (200 + $(window).height());
        } else {
            targetHeight = $(document).height() - ($('.ol-footer').height() + $(window).height());
        }

        if ($('html, body').scrollTop() > 100 && scrollTop <= targetHeight)  {
            $('#backToTransferTop').fadeIn();
        } else {
            $('#backToTransferTop').fadeOut();
        }
        //console.info(scrollTop + " - " + targetHeight);
        if (scrollTop > targetHeight) {
            if (!lastBackToTopState) {
                $('#backToTransferTopSolid').fadeIn();
                $('#backToTransferTop').fadeOut();
                lastBackToTopState = true;
            }
        } else {
            if (lastBackToTopState) {
                $('#backToTransferTop').fadeIn();
                $('#backToTransferTopSolid').fadeOut();
                lastBackToTopState = false;
            }
        }
    };

    if (olAppInfo.APP_MODE && olAppInfo.APP_PLATFORM == "iphone") {
        $('#scrollRoot').scroll(transferListScroll);
    } else {
        $(window).scroll(transferListScroll);
    }


    var lastStickySortConfig = false;
    var stickyFilterName = false;
    var lastRequest = false;
    var autoLoading = false;
    var beforeBlurInputValue = false;
    var transferLists = new Object();
    olTransferList.init = function(cV, config, sticky, reset) {
        if(typeof config === typeof undefined) config = false;
        if(typeof sticky === typeof undefined) sticky = false;
        if(typeof reset === typeof undefined) reset = false;
        if(typeof refresh === typeof undefined) refresh = false;        

        //save transferfilter
        //free agents filter (sofortkauf)
        $("#checkboxFreeAgents").on('change', function(){
            saveTransferFilter.freeAgents = $("#checkboxFreeAgents").is(':checked');
            sessionStorage.setItem('transferFilter', JSON.stringify(saveTransferFilter)); // update session
        });
        //endet Offers
        $("#checkboxOffersEnded").on('change', function(){
            saveTransferFilter.endedOffers = $("#checkboxOffersEnded").is(':checked');
            sessionStorage.setItem('transferFilter', JSON.stringify(saveTransferFilter)); // update session
        });
        //liquidity
        $("#checkboxLiquidity").on('change', function(){
            saveTransferFilter.liquidity = $("#checkboxLiquidity").is(':checked');
            sessionStorage.setItem('transferFilter', JSON.stringify(saveTransferFilter)); // update session
        });

        var firstInit = (sticky === false || reset || (sticky !== false && !transferLists.hasOwnProperty(sticky)));
        if (sticky !== false)
            transferLists[sticky] = true;
        if (firstInit || sticky === false) {
            $(window).scroll(onScrollToScreen);

            function onScrollToScreen() {
                if ($("#olAutoLoading").length == 0) return;
                if ($(window).scrollTop() + $(window).height() >= (getDocHeight() - $('.ol-footer').height())) {
                    olTransferList.search(true);
                }
            }
        }
        currentView = cV;
        if (firstInit === false) return;

        if (sticky) {
            stickyFilterName = currentView;
            $(document).on( "stickyLoadNoScroll:transferlist/gettransferlistview", function() {
                currentView = stickyFilterName;
                if (lastStickySortConfig !== false)
                    olTransferList.sort = lastStickySortConfig;
                setTimeout(function() {
                    olTransferList.search();
                }, 0);
            });
            $(document).on('stickyLoad:transferlist/gettransferlistview', function() {
                var offerId = parseURLParams()['offerId'];
                if (typeof offerId === 'undefined' || isNaN((offerId = parseInt(offerId)))) {
                    setTimeout(olTransferList.hidePlayerView, 0);
                } else {
                    olTransferList.hidePlayerView(false);
                    setTimeout(olTransferList.showPlayerView, 0, offerId);
                }
            });
        }

        copyOfTheTable = false;

        bidsViewOngoing = true;
        $('#backToTransferTop, #backToTransferTopSolid').on("touchmousedown", function() {
            $('html, body').animate({
                scrollTop: 0
            }, 400);
            return false;
        });

        var page = 0;
        var noMoreResults = false;
        if (config && config.sort) {
            $('div.olTableHeaderColumnToggle[data-value=' + config.sort.by + ']').attr('data-toggle', config.sort.sorting);
            olTransferList.sort = config.sort;
        } else {
            olTransferList.sort = { by : "tableHeaderRemainingTime", sorting : "asc" };
        }
        if (sticky) {
            lastStickySortConfig = olTransferList.sort;
        }
        var lastSearch = false;
        var bAnimationIsOngoing = true;

        if (currentView == 'transferList') {
            if (olGUI.isPremiumUser()) {
                maxFilterCount = 3;
            } else {
                maxFilterCount = 1;
            }
            currentFilterCount = 0;

            // init transfer fee
            //filter marketvalue
            var transferFilter = saveTransferFilter; 
            var valueFromMv;
            var valueToMv;
            var tfInput = $('#playerAttributeFiltertransferFee').find(".ol-double-slider");
            var to = tfInput.attr("data-slider-max");
            var from = tfInput.attr("data-slider-min");
            if (transferFilter.values.length === 0) { //when no filter values where saved use default, else use saved values
                valueFromMv = from;
                valueToMv = to;
            } else {
                valueFromMv = transferFilter.values[0][0];
                valueToMv = transferFilter.values[0][1];
            }           
            tfInput.attr("data-slider-step", 1);
            tfInput.attr("data-slider-value", ("[" + valueFromMv + "," + valueToMv + "]"));  
            tfInput.bootstrapSlider( {} );
            tfInput.bootstrapSlider().on("slideStop", function(ev) {
                olTransferList.search();
            });
            tfInput.trigger("change");

            var toInput = $('#playerAttributeFiltertransferFee').find(".ol-double-slider-input-to");
            $(toInput).val(olGUI.numberWithPoints(valueToMv));
            $(toInput).attr("data-target", "#transferFeeSlider");
            $(toInput).attr("data-min", from);
            $(toInput).attr("data-max", to);
            var fromInput = $('#playerAttributeFiltertransferFee').find(".ol-double-slider-input-from");
            $(fromInput).val(olGUI.numberWithPoints(valueFromMv));
            $(fromInput).attr("data-target", "#transferFeeSlider");
            $(fromInput).attr("data-min", from);
            $(fromInput).attr("data-max", to);

            $('.transfer-add-filter-addbutton').on("touchmouseup", function(e) {
                setTimeout(function() {
                    if (!$('#dropdownPlayerAttribute').hasClass('open')) {
                        // Kill click event:
                        e.stopPropagation();
                        // Toggle dropdown if not already visible:
                        $('#dropdownPlayerAttribute').addClass('open');
                    }
                }, 1);
            });
        };

        _updatePlayerAttributes();

        $(window).scroll();

        function _startAnimation() {
            bAnimationIsOngoing = true;

            function _animateEntry() {
                var nextEntry = $('.transfer-list-entry-transform-start').first();
                if (bAnimationIsOngoing !== false && nextEntry) {
                    nextEntry.removeClass('transfer-list-entry-transform-start');
                    nextEntry.addClass('transfer-list-entry-transform-end');
                    setTimeout(_animateEntry, 50);
                } else {
                    bAnimationIsOngoing = false;
                }
            }
            _animateEntry();
        }
        _startAnimation();

        function _updatePlayerAttributes() {
            var selectedElement = $(getAttributeSelector()).dropdown().selectedElement();
            // olGUI.onClickDropdownPlayerAttribute($(selectedElement).attr("data-value"), $(selectedElement).attr("data-type"), $(selectedElement).attr("data-unit"));
            olGUI.updatePieChartValue($(selectedElement).attr("data-value"), $(selectedElement).attr("data-type"),  $(selectedElement).attr("data-unit"), $(selectedElement).attr("data-name"));
        }

        function _resetSortingVars() {
            autoload = false;
            page = 0;
            lastSearch = false;
            noMoreResults = false;
            $("#noMoreResults").hide();
        }
        
        olTransferList.addPlayerAttributeFilter = function() {
            var attrId = $("#dropdownPlayerAttribute").dropdown().value();
            if (attrId == -1 || currentFilterCount >= maxFilterCount) {
                return;
            }
            ++currentFilterCount;
            var li = $("#dropdownPlayerAttribute #attrFilterId" + attrId);
            var name = li.text();

            var ended = $('#checkboxOffersEnded').is(':checked') ? true : false;

            var fromActive = li.attr("data-from-active");
            var toActive = li.attr("data-to-active");
            var fromEnded = li.attr("data-from-ended");
            var toEnded = li.attr("data-to-ended");

            var from = li.attr("data-from" + (ended ? '-ended' : '-active'));
            var to = li.attr("data-to" + (ended ? '-ended' : '-active'));
            var unit = li.attr("data-unit");

            if (attrId == "preferredFoot") {
                saveTransferFilter.filterCount = currentFilterCount;
                saveTransferFilter.attr.push('foot');
                sessionStorage.setItem('transferFilter', JSON.stringify(saveTransferFilter));
                var input = $('#playerAttributeFilterpreferredFoot').find(".ol-slider");
                input.bootstrapSlider({ scale: 'linear' });
                input.bootstrapSlider().on("change", function(ev) {
                    var val = parseInt(input.val());
                    var labels =input.attr('data-labels').split(',');
                    $('#preferredFootLabel').html(labels[val + 1]);                    
                    olTransferList.search();
                });
                $('#playerAttributeFilterpreferredFoot').appendTo('#olTransferListFilterList').show();
                olTransferList.updateDropdown();
                 // add mobile transferfilterpill
                createFilterPill(name, attrId, from, $('#preferredFootLabel').html());
                return;
            }

            if ($("#playerAttributeFilter" + attrId).length > 0)
                return;

            var inputId = attrId + "slider";
            $("#playerAttributeFilterTemplate").show();
            var clone = $("#playerAttributeFilterTemplate").clone();
            $("#playerAttributeFilterTemplate").hide();
            clone.attr("id", "playerAttributeFilter" + attrId);
            clone.attr("data-attr-id", attrId);
            clone.find(".ol-player-attribute-filter-name").text(name);
            clone.find(".ol-double-slider-label-unit").text(unit);
            $("#olTransferListFilterList").append(clone);

            var input = clone.find(".ol-double-slider");
            input.attr("id", inputId);
            input.attr("data-slider-min-ended", fromEnded);
            input.attr("data-slider-max-ended", toEnded);
            input.attr("data-slider-min-active", fromActive);
            input.attr("data-slider-max-active", toActive);
            input.attr("data-slider-min", from);
            input.attr('data-attribute-id', attrId);
            input.attr("data-slider-max", to);
            input.attr("data-slider-step", 1);
            input.attr("data-slider-value", ("[" + from + "," + to + "]"));
            input.bootstrapSlider( (Math.abs(to) > 1000) ? { scale: 'logarithmic' } : {} );
            input.bootstrapSlider().on("slideStop", function(ev) {
                olTransferList.search();
            });
            input.trigger("change");

            var toInput = $("#playerAttributeFilter" + attrId).find(".ol-double-slider-input-to");
            $(toInput).val(olGUI.numberWithPoints(to));
            $(toInput).attr("data-target", "#" + inputId);
            $(toInput).attr("data-min", from);
            $(toInput).attr("data-max", to);
            var fromInput = $("#playerAttributeFilter" + attrId).find(".ol-double-slider-input-from");
            $(fromInput).val(olGUI.numberWithPoints(from));
            $(fromInput).attr("data-target", "#" + inputId);
            $(fromInput).attr("data-min", from);
            $(fromInput).attr("data-max", to);
            olTransferList.updateDropdown();

            // add mobile transferfilterpill
            createFilterPill(name, attrId, from, to);

            // save Filter in Session
            saveTransferFilter.filterCount = currentFilterCount;
            saveTransferFilter.attr.push(attrId);
            sessionStorage.setItem('transferFilter', JSON.stringify(saveTransferFilter));
        };

        function resetTransferFeeFilter() {
            var $transferFeeSliderWrapper = $('#playerAttributeFiltertransferFee');
            var $transferFeeSlider = $('#transferFeeSlider');
            var dataMaxValueActive = $transferFeeSlider.attr('data-slider-max-active');
            var dataValue = '0,'+ dataMaxValueActive;
            $transferFeeSlider.attr('data-value', dataValue)
            $transferFeeSlider.attr('data-slider-value', '['+dataValue+']')
            $transferFeeSlider.val(dataValue);
            $transferFeeSliderWrapper.find('.ol-double-slider-input-from').val(0);
            $transferFeeSliderWrapper.find('.ol-double-slider-input-to').val(dataMaxValueActive);
            $transferFeeSliderWrapper.find('.min-slider-handle').css('left', '0%');
            $transferFeeSliderWrapper.find('.max-slider-handle').css('left', '100%');
            
            olTransferList.search();
        }

        function createFilterPill(name, attrId, from, to, foot) {
            foot = typeof from === "undefined" ? true : false;
            if (foot) {
                name = Lang.trans('js/transferlist.foot');
                attrId = 'preferredFoot';
            }
            var $div = $("<div></div>");
            $div.addClass('ol-transfer-filter-pill');
            var divText = foot ? $('#preferredFootLabel').html() : from+'-'+to;
            $div.text(name+': '+ divText);
            $div.attr('id', 'filterPill'+attrId);
            $div.on("click", function(){
                if(attrId == 'transferFee') {
                    resetTransferFeeFilter();
                    $('#filterPill'+attrId).remove();
                } else {
                    olTransferList.closeStdFilter($('[data-attr-id="'+attrId+'"]').find('.filter-close-button'));
                }
            });
            $('.ol-mobile-filter-summary').append($div);
        }

        function updateFilterPill(attrId, from, to , foot) {
            var $filter = $('#filterPill'+attrId);
            var name = $('#playerAttributeFilter'+attrId).find('.ol-player-attribute-filter-name').html();
            var divText = foot ? foot : from+'-'+to;
            $filter.text(name+': '+ divText);
        }
        
        // restore saved Transferfilter from Session - Function will be run when the transfermarket is loaded
        olTransferList.savedTransferfilter = function() {              
            var transferFilter = saveTransferFilter;

            //attribute Filter
            for (var i = 0; i < transferFilter.filterCount; i++ ) {
                var filterId = i + 1;
                if(transferFilter.attr.length === 0 || transferFilter.filterCount === undefined){ // stop when no filters where saved
                    break;
                }
                var attrId = transferFilter.attr[i];
                if (attrId == -1 || currentFilterCount >= maxFilterCount) {
                    return;
                }
                ++currentFilterCount;
                var li = $("#dropdownPlayerAttribute #attrFilterId" + attrId);
                var name = li.text();

                var ended = $('#checkboxOffersEnded').is(':checked') ? true : false;                    

                var fromActive = li.attr("data-from-active");
                var toActive = li.attr("data-to-active");
                var fromEnded = li.attr("data-from-ended");
                var toEnded = li.attr("data-to-ended");

                var from = li.attr("data-from" + (ended ? '-ended' : '-active'));
                var to = li.attr("data-to" + (ended ? '-ended' : '-active'));
                var unit = li.attr("data-unit");
            
                if (attrId == "foot") 
                {
                    var footInput = $('#playerAttributeFilterpreferredFoot').find(".ol-slider");
                    var labels = footInput.attr('data-labels').split(',');
                    var filterValues = transferFilter.values? transferFilter.values[filterId] : undefined;
                    var selectedFoot = 0;
                    if (transferFilter.values.length !== 0 && filterValues !== undefined) {
                        selectedFoot = parseInt(transferFilter.values[filterId][1]);
                    }
                    footInput.bootstrapSlider({ scale: 'linear' });            
                    footInput.bootstrapSlider().on("change", function(ev) {
                        var val = parseInt(footInput.val());
                        $('#preferredFootLabel').html(labels[val + 1]);
                        olTransferList.search();
                    });
                    $('#playerAttributeFilterpreferredFoot').appendTo('#olTransferListFilterList').show();
                    footInput.val(selectedFoot);
                    footInput.attr('value', selectedFoot);
                    footInput.attr('data-value', selectedFoot);
                    $('#preferredFootLabel').html(labels[selectedFoot + 1]);  
                    
                    olTransferList.updateDropdown();      

                    // add mobile transferfilterpill
                    createFilterPill(name, attrId, from, to, labels[selectedFoot + 1]);
                }
                else
                {
                    if ($("#playerAttributeFilter" + attrId).length > 0)
                        return;

                    var inputId = attrId + "slider";
                    $("#playerAttributeFilterTemplate").show();
                    var clone = $("#playerAttributeFilterTemplate").clone();
                    $("#playerAttributeFilterTemplate").hide();
                    clone.attr("id", "playerAttributeFilter" + attrId);
                    clone.attr("data-attr-id", attrId);
                    clone.find(".ol-player-attribute-filter-name").text(name);
                    clone.find(".ol-double-slider-label-unit").text(unit);
                    $("#olTransferListFilterList").append(clone);    
                    
                    // dobule slider filter
                    var valueFrom;
                    var valueTo;                    
                    var filterValues = transferFilter.values? transferFilter.values[filterId] : undefined;
                    if (transferFilter.values.length !== 0 && filterValues !== undefined) { //when no filter values where saved use default, else use saved values
                        valueFrom = transferFilter.values[filterId][0];
                        valueTo = transferFilter.values[filterId][1];
                    } else {
                        valueFrom = from;
                        valueTo = to;                       
                    }

                    
                    var input = clone.find(".ol-double-slider");
                    input.attr("id", inputId);
                    input.attr("data-slider-min-ended", fromEnded);
                    input.attr("data-slider-max-ended", toEnded);
                    input.attr("data-slider-min-active", fromActive);
                    input.attr("data-slider-max-active", toActive);
                    input.attr("data-slider-min", from);
                    input.attr('data-attribute-id', attrId);
                    input.attr("data-slider-max", to);
                    input.attr("data-slider-step", 1);
                    input.attr("data-slider-value", ("[" + valueFrom + "," + valueTo + "]"));
                    input.bootstrapSlider( (Math.abs(to) > 1000) ? { scale: 'logarithmic' } : {} );
                    input.bootstrapSlider().on("slideStop", function(ev) {
                        olTransferList.search();
                    });
                    input.trigger("change");

                    var toInput = $("#playerAttributeFilter" + attrId).find(".ol-double-slider-input-to");
                    $(toInput).val(olGUI.numberWithPoints(valueTo));
                    $(toInput).attr("data-target", "#" + inputId);
                    $(toInput).attr("data-min", from);
                    $(toInput).attr("data-max", to);
                    var fromInput = $("#playerAttributeFilter" + attrId).find(".ol-double-slider-input-from");
                    $(fromInput).val(olGUI.numberWithPoints(valueFrom));
                    $(fromInput).attr("data-target", "#" + inputId);
                    $(fromInput).attr("data-min", from);
                    $(fromInput).attr("data-max", to);
                    olTransferList.updateDropdown();  
                    // add mobile transferfilterpill
                    createFilterPill(name, attrId, from, to);
                }
            }

            //checkbox filter
            if (transferFilter.freeAgents == true && $("#checkboxFreeAgents").is(':checked') == false) {
                $('[data-sub-url="immidiatebuy"]').click();
                olGUI.setTabButtonsActive(1);
            }
            if (transferFilter.endedOffers == true && $("#checkboxOffersEnded").is(':checked') == false) {
                $('[data-sub-url="endedAuctions"]').click();
                olGUI.setTabButtonsActive(2);
            }
            if (transferFilter.liquidity == true && $("#checkboxLiquidity").is(':checked') == false) {
                $("#checkboxLiquidity").click();                  
            }

            //Position Dropdown
            if (transferFilter.pos !== false) {
                var filterText = $('#dropdownPlayerPosition [data-value="'+transferFilter.pos+'"] a').text();
                $('#dropdownPlayerPosition').dropdown().setText(filterText);
                $('#dropdownPlayerPosition').dropdown().setValue(transferFilter.pos);
            }
            olTransferList.search();
        };

        olTransferList.onChangeOffersEnded = function() {
            var ended = $('#checkboxOffersEnded').is(':checked') ? true : false;

            $('.ol-double-slider').each(function(index, input) {
                input = $(input);
                if (input.parents('.ol-tranfer-list-filter').attr('id') != 'playerAttributeFilterTemplate') {
                    var attrId = input.attr('data-attribute-id');
                    var from = parseInt(input.attr("data-slider-min" + (ended ? '-ended' : '-active')));
                    var to = parseInt(input.attr("data-slider-max" + (ended ? '-ended' : '-active')));

                    var value = input.val().split(',');
                    value[0] = Math.max(from, parseInt(value[0]));
                    value[1] = Math.min(to, parseInt(value[1]));
                    input.data('bootstrapSlider').options.max = to;
                    input.data('bootstrapSlider').options.min = from;

                    var toInput = $("#playerAttributeFilter" + attrId).find(".ol-double-slider-input-to");
                    $(toInput).attr("data-min", from);
                    $(toInput).attr("data-max", to);
                    $(toInput).val(olGUI.numberWithPoints(value[1]));
                    var fromInput = $("#playerAttributeFilter" + attrId).find(".ol-double-slider-input-from");
                    $(fromInput).attr("data-min", parseInt(from));
                    $(fromInput).attr("data-max", parseInt(to));
                    $(fromInput).val(olGUI.numberWithPoints(value[0]));

                    $(input).bootstrapSlider('setValue', value);
                    input.trigger("change");
                }
            });

            var target = $(".olTableHeaderColumnToggle[data-value='tableHeaderRemainingTime']");
            target.attr('data-toggle', 'asc');
            target.click();
        };

        olTransferList.closePreferredFootFilter = function() {
            $('#playerAttributeFilterpreferredFoot').insertAfter('#olTransferListFilterList').hide();
            olTransferList.search();
            --currentFilterCount;
            olTransferList.updateDropdown();

            //remove saved foot filter from session
            var index = saveTransferFilter.attr.indexOf('foot');
            saveTransferFilter.attr.splice(index, 1);
            --saveTransferFilter.filterCount; 
            sessionStorage.setItem('transferFilter', JSON.stringify(saveTransferFilter)); // update session
        };

        olTransferList.closeStdFilter = function(element) {
            var $filter = $(element).parent().parent().parent();
            var attrId = $filter.attr('data-attr-id');

            $('#filterPill'+attrId).remove();

            $filter.remove();
            olTransferList.search();
            --currentFilterCount;
            olTransferList.updateDropdown();

            //remove filter from Session
            var filter = $filter.attr('id'); //get id of filter that will be removed
            if (filter == 'playerAttributeFilterpreferredFoot') {
                var index = saveTransferFilter.attr.indexOf('foot');
            } else {
                var regex = /\d+/g; // selects only number of str
                var id = filter.match(regex); // returns player attribute filter id (as array)
                var index = saveTransferFilter.attr.indexOf(id.toString()); // find id of selected filter in session obj.
            }
            saveTransferFilter.attr.splice(index, 1); // remove id from selected filter in obj.
            saveTransferFilter.values.splice(index, 1); // remove saved values from selected filter in obj.
            --saveTransferFilter.filterCount; // update filter count
            sessionStorage.setItem('transferFilter', JSON.stringify(saveTransferFilter)); // update session
            
        }

        olTransferList.reset = function() {
            sessionStorage.removeItem('transferFilter');
            saveTransferFilter = {
                filterCount: 0,
                attr: [],
                values: [],
                pos: false,
                freeAgents: false,
                endedOffers: false,
                liquidity: false,
            };
            loadPage('/transferlist/gettransferlistview', {reset: true}, undefined, undefined, undefined, undefined, false, undefined, undefined, true);            
        };

        olTransferList.search = function(autoload, loadUntil, preSelectedOfferId) {
            bAnimationIsOngoing = false;
            if (typeof loadUntil === typeof undefined || loadUntil === false) {
                loadUntil = false;
            }
            if (!loadUntil && (typeof autoload === typeof undefined || autoload === false)) {
                _resetSortingVars();
            }
            if (typeof preSelectedOfferId === typeof undefined || preSelectedOfferId === false) {
                preSelectedOfferId = false;
            }
            if (noMoreResults === false) {
            } else {
                return;
            }

            if (autoload && autoLoading) return;
            if (autoload) {
                autoLoading = true;
                ++page;
            }
            data = new Object();
            data.attrFilter = new Object();
            data.custom = new Object();
            data.sort = olTransferList.sort;
            data.freeAgentsOnly = $("#checkboxFreeAgents").is(':checked') ? "true" : "false";
            data.offersEnded = $('#checkboxOffersEnded').is(':checked') ? "true" : "false";
            data.liquidityCheck = $('#checkboxLiquidity').is(':checked') ? "true" : "false";
            data.currentView = currentView;
            data.custom['position'] = 0;
            if (currentView !== 'transferList' && !bidsViewOngoing)
                data.offersEnded = "true";

            if (currentView === 'watchList') {
                // data.watchListFilter = $('#dropdownWatchListFilter').dropdown().value();
                data.watchListFilter = $('#watchlistBtns .ol-tab-button.active').val();
                if(data.watchListFilter === 'ended')
                    data.offersEnded = 'true';
            }

            var position = $("#dropdownPlayerPosition").dropdown().value();
            if (position > 0) {
                data.custom['position'] = position;
                saveTransferFilter.pos = position; // save seleceted Position
                sessionStorage.setItem('transferFilter', JSON.stringify(saveTransferFilter)); // update session
            }
            saveTransferFilter.values = [];
            $(".ol-tranfer-list-filter").each(function(index, element) {
                element = $(element);
                var attrId = element.attr("data-attr-id");
                if (element.attr("id") == "playerAttributeFilterTemplate") {
                    return true;
                } else if (element.css("display") != "none") {
                    var values = 0;
                    if (element.find(".ol-double-slider").length > 0){ //player attr.
                        values = element.find(".ol-double-slider").val().split(",");
                        //save filer values in Session
                        saveTransferFilter.values.push(values);
                        sessionStorage.setItem('transferFilter', JSON.stringify(saveTransferFilter)); // update session

                        // mobile filter pills
                        var isFeeFilter = attrId == 'transferFee';
                        var from = isFeeFilter ? olGUI.formatCurrency2(values[0], 0) : values[0];
                        var to = isFeeFilter ? olGUI.formatCurrency2(values[1], 0) : values[1];
                        if($('#filterPill' + attrId).length == 0) {
                            var filterName = element.find('.ol-player-attribute-filter-name').html();
                            var min = $('#transferFeeSlider').attr('data-slider-min');
                            var max = $('#transferFeeSlider').attr('data-slider-max');
                            
                            if(isFeeFilter && min == values[0] && max == values[1]) {
                                return
                            } else {
                                createFilterPill(filterName, attrId, from, to, false);
                            }

                        } else {
                            updateFilterPill(attrId, from, to, false);
                        }
                    } else {//foot
                        values = element.find(".ol-slider").val();
                        saveTransferFilter.values.push(['foot', values]);
                        sessionStorage.setItem('transferFilter', JSON.stringify(saveTransferFilter));

                        updateFilterPill(attrId, 0, 0, element.find("#preferredFootLabel").html());
                    }
                    if ($.isNumeric(attrId)) {
                        data.attrFilter[attrId] = values;
                    } else {
                        data.custom[attrId] = values;
                    }
                }
            });
            data.page = page;
            if (loadUntil)
                data.until = true;
            if (data.sort.by == "tableHeaderPlayerAttribute")
                data.sort.byId = $(getAttributeSelector()).dropdown().value(); // byId is right!
            if (lastSearch === JSON.stringify(data)) {
                return;
            }
            lastSearch = JSON.stringify(data);

            if (!loadUntil && autoload)
                addLoadingAnimationToElementNoTimeOut($("#olAutoLoading"), { size: "small" });
            else if (!loadUntil)
                addLoadingAnimationToElementNoTimeOut(getTransferListSelector());

            if (lastRequest !== false) {
                autoLoading = false;
                lastRequest.abort();
                lastRequest = false;
            }

            if (currentView === 'transferList')
                lastStickySortConfig = olTransferList.sort;

            lastRequest = (getTransferListSelector().getLoad('/transferlist/gettransferlist', data, function(response) {
                lastRequest = false;
                if (preSelectedOfferId != false)
                    $('#transferListItem' + preSelectedOfferId).addClass('transfer-focus');
                autoLoading = false;
                if (typeof response === typeof undefined) return;
                if (response.length === 0) {
                    noMoreResults = true;
                    $("#noMoreResults").fadeIn();
                }
                if (!loadUntil && autoload)
                    removeLoadingAnimationFromElement($("#olAutoLoading"));
                else if (!loadUntil)
                    removeLoadingAnimationFromElement(getTransferListSelector());
                getTransferListSelector().removeClass('loading');
                _updatePlayerAttributes();
                if (!autoload && !loadUntil) {
                    var newNumber = 0;
                    if (response.length > 0) {
                        var numberOfRows = $("#maxNumberOfRows");
                        if (numberOfRows.length > 0)
                            newNumber = parseInt(olGUI.stripNumericString($("#maxNumberOfRows").attr("data-value")));
                        else
                            newNumber = 0;
                    }
                    _updateResultCounter(newNumber);
                }
                _startAnimation();
                olTimeCounter._updateTimeCounter();
            }, autoload));
        };

        function _animateNegotiationItems() {
            setTimeout(function() {
                var first = $('div.ol-negotiation-item-anim-start:not(.ol-negotiation-item-anim-end)').first();
                if (first) {
                    first.addClass('ol-negotiation-item-anim-end');
                    setTimeout(function() {
                        _animateNegotiationItems();
                    }, 300);
                }
            }, 1);
        }

        olTransferList.onClickHireFreeAgentPlayerOverlay = function(offerId) {
            //var data = new Object();
            //data.offerId = offerId;
            //data.duration = $('#overlaySliderDuration').val();
            //data.salary = olGUI.stripNumericString($('#overlaySalary').val());
            //data.overlay = 'false';
            var data = {
                offerId: offerId,
                duration: $('#overlaySliderDuration').val(),
                salary: olGUI.stripNumericString($('#overlaySalary').val()),
                overlay: 'false'
            };
            try {
                data.captcha = grecaptcha.getResponse();
            } catch (e) {
                data.captcha = false;
            }
            _hireFreeAgentPlayerOverlayRequest(data);
        };

        function _hireFreeAgentPlayerOverlayRequest(data) {
            $.get('/transferlist/hirefreeagentplayer', data, function(responseData) {
                //removeLoadingAnimationFromElement();
                if (typeof responseData === 'object') {
                    olGUI.updateLiquidFunds();
                    olMsgBox.json(responseData, function() {
                        if (responseData.hint === "ended") {
                            olTransferList.closeAgentFreeNegotiationOverlay(data.offerId),
                            olTransferList.hidePlayerView();
                            olTransferList.search();
                        }
                        if (responseData.hint === "success") {
                            olTransferList.closeAgentFreeNegotiationOverlay(data.offerId);
                            olTransferList.showPlayerView(data.offerId);
                        }
                    });
                    if (responseData.hint === 'canceled') {
                        $('#overlayPlayerCancelledNegotiation').show();
                        $('#overlayOfferNotAccepted').hide();
                        $('#buttonClickHireFreeAgentPlayerNewOfferOverlay').hide();
                    }
                } else {
                    $('#buttonClickHireFreeAgentPlayerAcceptLastOfferOverlay').show();
                    $('#negotiationWrapper').append(responseData);
                    $("#negotiationWrapper").scrollTop($("#negotiationWrapper")[0].scrollHeight);
                    _animateNegotiationItems();
                }
            }).fail(refreshCaptcha);
        }

        olTransferList.onClickHireFreeAgentPlayerAcceptLastOfferOverlay = function(offerId) {
            var lastElement = $('.ol-contract-negotiation-player-entry');
            if (lastElement.length > 0) {
                var data = new Object();
                lastElement = lastElement.last();
                data.offerId = offerId;
                data.overlay = 'false';
                data.duration = lastElement.attr('data-duration');
                data.salary = lastElement.attr('data-salary');
                _hireFreeAgentPlayerOverlayRequest(data);
            }
        };

        olTransferList.onClickHireFreeAgentPlayer = function(offerId, returnToNegotiation, transferFee) {
            if (returnToNegotiation === undefined)
                returnToNegotiation = false;
            var data = {
                offerId: offerId,
                overlay: 'true'
            };

            try {
                data.captcha = grecaptcha.getResponse();
            } catch (e) {
                data.captcha = false;
            }

            if (returnToNegotiation == true) {
                data.duration = false;
                data.salary = false;
            } else {
                data.duration = $('#sliderDuration').val();
                data.salary = olGUI.stripNumericString($(getSalarySelector()).val());
            }

            //addLoadingAnimationToElement($('#ol-root'));
            olMsgBox.questionById('transferFeeInformationFreeAgent', function() {
                $.get('/transferlist/hirefreeagentplayer', data, function(responseData) {
                    //removeLoadingAnimationFromElement();
                    if (typeof responseData === 'object') {
                        olGUI.updateLiquidFunds();
                        olMsgBox.json(responseData, function() {
                            if (responseData.hint === "ended"){ olTransferList.hidePlayerView(); olTransferList.search(); }

                            if (responseData.hint === "success") {
                                olTransferList.showPlayerView(data.offerId);
                            }
                        });
                    } else {
                        olOverlayWindow.setContent(responseData);
                        olOverlayWindow.open();
                        $("#negotiationWrapper").scrollTop($("#negotiationWrapper")[0].scrollHeight);
                        $("#overlaySliderDuration").bootstrapSlider({scale: 'linear', tooltip: 'hide'});
                        $("#overlaySliderDuration").slider().on('change', function(ev) {
                            var value = $(this).val();
                            var label = $("#overlayContractDuration" + value);
                            if (label) {
                                $("#overlayContractDuration .contract-duration-tick").removeClass("active");
                                label.addClass("active");
                            }
                        });
                    }
                }).fail(refreshCaptcha);

            }, function() {}, {
                '[transferFee]': olGUI.formatCurrency2(transferFee, 0),
                '[salary]': olGUI.formatCurrency2(data.salary, 0)
            });
        };

        function refreshCaptcha(response) {
            try {
                grecaptcha.reset();
            } catch (e) {
                console.log("No Captcha.");
            }
        }

        olTransferList.onMouseDownMakeOffer = function() {
            beforeBlurInputValue = olGUI.stripNumericString($(getTransferFeeSelector()).val());
        };

        olTransferList.onClickMakeOffer = function(offerId, ignoreMultiAccountHint) {
            if(typeof ignoreMultiAccountHint === typeof undefined) ignoreMultiAccountHint = false;
            //var data = new Object();
            //data.offerId = offerId;
            //data.duration = $("#sliderDuration").val();
            //data.transferFee = olGUI.stripNumericString($(getTransferFeeSelector()).val());
            var data = {
                offerId: offerId,
                duration: $("#sliderDuration").val(),
                transferFee: olGUI.stripNumericString($(getTransferFeeSelector()).val())
            };
            if(beforeBlurInputValue !== false && data.transferFee !== beforeBlurInputValue) return;
            data.salary = olGUI.stripNumericString($(getSalarySelector()).val());

            if (multiAccountWarning && ignoreMultiAccountHint === false) {
                olMsgBox.questionById('bidMultiAccountWarning', function() {
                    olTransferList.onClickMakeOffer(offerId, true);
                });
                return true;
            }
            /* data-max-bid-restriction */
            if (data.transferFee > parseInt($(getTransferFeeSelector()).attr('data-max-bid-restriction'))) {
                olMsgBox.msgById('maxTransferFeeInputMsg');
                return;
            }
            var attr = $(getTransferFeeSelector()).attr('data-min-transfer-fee');
            if (typeof attr !== typeof undefined && attr !== false && data.transferFee < parseInt($(getTransferFeeSelector()).attr('data-min-transfer-fee'))) {
                olMsgBox.msgById('minTransferFeeInformation', function() {}, {
                    '[minTransferFee]' : olGUI.formatCurrency2($(getTransferFeeSelector()).attr('data-min-transfer-fee')),
                    '[liquidFunds]' : olGUI.formatCurrency2($(getTransferFeeSelector()).attr('data-max'))
                });
                return;
            }

            olMsgBox.questionById('transferFeeInformation', function() {
                addLoadingAnimationToElement($('ol-root'));
                $.getJSON("/transferlist/bid", data, function(responseData) {
                    removeLoadingAnimationFromElement($('ol-root'));
                    if (responseData.check === "failed")
                        olMsgBox.json(responseData);
                    if (responseData.check === "success")
                        olMsgBox.json(responseData, function() {
                            olGUI.updateLiquidFunds();
                            olTransferList.showPlayerView(offerId);
                            olTransferList.search(false, true, offerId);
                            olOverlayWindow.close();
                        });
                    });
                }, function() {}, {
                    '[transferFee]': olGUI.formatCurrency2(data.transferFee, 0),
                    '[salary]': olGUI.formatCurrency2(data.salary, 0)
                });
        };

        olTransferList.onClickShowTransferList = function() {
            addLoadingAnimationToElement($("#ol-root"));
            $("#transferListContent").getLoad('/transferlist/gettransferlisttableview', function() {
                removeLoadingAnimationFromElement($("#ol-root"));
            });
        };

        olTransferList.onClickShowCreateOffer = function() {
            addLoadingAnimationToElement($("#ol-root"));
            $("#transferListContent").getLoad('/transferlist/getcreateofferview', function() {
                removeLoadingAnimationFromElement($("#ol-root"));
                var selectedElement = $("#dropdownPlayerAttribute").dropdown().selectedElement();
                olGUI.onClickDropdownPlayerAttribute($(selectedElement).attr("data-value"), $(selectedElement).attr("data-type"), $(selectedElement).attr("data-unit"));
            });
        };

        olTransferList.onClickCreateOffer = function() {
            if (currentSelectedPlayer === false) return;

            //var data = new Object();
            //data.playerId = currentSelectedPlayer;
            //data.duration = $("#sliderDuration").val();
            //data.transferFee = $("#sliderTransferFee").val();
            //data.visibility = $("#dropdownVisibility").dropdown().value();
            var data = {
                playerId: currentSelectedPlayer,
                duration: $("#sliderDuration").val(),
                transferFee: $("#sliderTransferFee").val(),
                visibility: $("#dropdownVisibility").dropdown().value()
            };

            function _inline() {
                olOverlayWindow.addLoadingAnimation();
                $.getJSON("/transferlist/createoffer", data, function(responseData) {
                    olOverlayWindow.removeLoadingAnimation();
                    if (responseData.check === "failed")
                        olMsgBox.json(responseData);
                    if (responseData.check === "success")
                        olMsgBox.json(responseData, function() {
                            olOverlayWindow.close();
                            olTransferList.search();
                        });
                });
            }

            olMsgBox.questionById('createOfferInformation', function() {
                var marketValue = olGUI.stripNumericString($('#marketValue').html());
                if (marketValue > data.transferFee) {
                    setTimeout(function() {
                        olMsgBox.questionById('lowMinTransferFee', function() {
                            _inline();
                        }, function() {},
                        {
                            '[transferFee]': olGUI.formatCurrency2(data.transferFee, 0)
                        });
                    }, 300);
                } else {
                    _inline();
                }
            }, function() {}, { 
                    '[player_name]': $('#dropdownPlayerIds').find('.dropdown-toggle > .ol-dropdown-text') .contents().filter(function() {return this.nodeType === 3;}).text().trim(),
                    '[transferFee]': olGUI.formatCurrency2(data.transferFee, 0),
                    '[duration]': Lang.transChoice('js/misc.day_days', data.duration, {d: data.duration})
                }
            );
        
        };

        olTransferList.onClickDropdownBids = function(salary, transferFee, duration) {
            $("#bidSalary").text(olGUI.numberWithPoints(salary));
            $("#bidTransferFee").text(olGUI.numberWithPoints(transferFee));
            $("#bidDuration").text(olGUI.numberWithPoints(duration));
        };

        olTransferList.onClickCreateOfferOverlayView = function() {
            addLoadingAnimationToElement($('ol-root'));
            olOverlayWindow.load('/transferlist/getcreateofferoverlayview', function() {
                removeLoadingAnimationFromElement($('ol-root'));
            });
        }


        var nextTwoTransferPhases = false;
        olTransferList.initOfferOverlayView = function(daysPerSeason, daysTillSeasonHalfTime, daysPerSeasonHalfTime, currentWeek, currentSeason, str1, str2, nextTransferPhaseJson, daysForDecision) {
            nextTwoTransferPhases = JSON.parse(nextTransferPhaseJson);
            $("#sliderTransferFee").bootstrapSlider({ scale: 'linear', tooltip: 'hide'});
            $("#sliderTransferFee").slider().on("change", function(slideEvt) {
                var value = parseInt(slideEvt.value.newValue);
                $("#sliderTransferFeeInput").val(olGUI.numberWithPoints(value));
            });

            $("#sliderDuration").bootstrapSlider({ scale: 'linear', tooltip: 'hide'});
            $("#sliderDuration").slider().on("change", function(slideEvt) {
                var value = parseInt(slideEvt.value.newValue);
                var diff = daysPerSeason - currentWeek;
                var daysInNextSeason = value - diff;

                if (daysInNextSeason <= 0) {
                    $("#transferEntryEndSeason").text(currentSeason);
                    var currentSeasonWeek = value + currentWeek;
                    if (currentSeasonWeek < (daysTillSeasonHalfTime + daysPerSeasonHalfTime + 1))
                        $("#transferEntryEnd").text(str1);
                    else
                        $("#transferEntryEnd").text(str2);
                } else {
                    $("#transferEntryEndSeason").text(currentSeason + 1);
                    var currentSeasonWeek = value + currentWeek;
                    if (daysInNextSeason < (daysTillSeasonHalfTime + daysPerSeasonHalfTime + 1))
                        $("#transferEntryEnd").text(str1);
                    else
                        $("#transferEntryEnd").text(str2);
                }

                var offerEndingDate = new Date();
                if (offerEndingDate.getHours() >= 0 && offerEndingDate.getHours() <= 4) {
                    offerEndingDate.setDate(offerEndingDate.getDate() + value - 1);
                    offerEndingDate.setHours(23, 59, 0, 0);
                } else {
                    offerEndingDate.setDate(offerEndingDate.getDate() + value);
                }

                var endTimeAsString = Lang.trans('js/transferlist.endTime', {
                    day: olGUI.pad(offerEndingDate.getDate(), 2),
                    month: olGUI.pad(offerEndingDate.getMonth() + 1, 2),
                    year: offerEndingDate.getFullYear(),
                    hour: olGUI.pad(offerEndingDate.getHours(), 2),
                    minute: olGUI.pad(offerEndingDate.getMinutes(), 2),
                });
                $('#endingDate').html(endTimeAsString);

                var firstPeriod = (value <= nextTwoTransferPhases[0].to && (nextTwoTransferPhases[0].to - value) < daysForDecision);
                var secondPeriod = (value <= nextTwoTransferPhases[1].to && (nextTwoTransferPhases[1].to - value) < daysForDecision);
                if (firstPeriod || secondPeriod) {
                    var timeUntil = false;
                    if (firstPeriod)
                        timeUntil = nextTwoTransferPhases[0].to;
                    if (secondPeriod)
                        timeUntil = nextTwoTransferPhases[1].to;

                    var processTime = new Date();
                    processTime.setDate(processTime.getDate() + timeUntil + 1);
                    processTime.setHours(0, 0, 0, 0);

                    var hours = parseInt(Math.abs(processTime - offerEndingDate) / 36e5);
                    if (hours <= 48) {
                        $('#decisionTimeHours').html(hours);
                        $('#decisionTimeWarning').show();
                    }
                } else {
                    $('#decisionTimeWarning').hide();
                }

                $("#sliderDurationInput").val(olGUI.numberWithPoints(value));
            });
        };
        var currentSelectedPlayer = false;
        olTransferList.onClickCreateOfferOverviewPlayer = function(playerId, event) {
            
            var data = JSON.parse($('#player' + playerId).attr('data-offer-player'));
            data.maxDuration = parseInt(data.maxDuration);
            data.maxTransferFee = parseInt(data.maxTransferFee);
            data.minTransferFee = parseInt(data.minTransferFee);

            if (data.maxDuration <= 0) {
                olMsgBox.msgById('durationError');
                event.stopPropagation();
                event.preventDefault();
                return;
            }
            currentSelectedPlayer = playerId;

            var _showPlayerDetails = function() {
                $('#lineUpPlayer' + currentSelectedPlayer).addClass('ol-player-details-selected');
            }

            $('#createOfferOverviewPanel').fadeIn();
            $('.ol-player-details-selected').removeClass('ol-player-details-selected');
            if ($('#lineUpPlayer' + playerId).length === 0) {
                olOverlayWindow.addLoadingAnimation();
                $.get('/transferlist/createofferoverlayplayerdetail', { 'playerId': playerId }, function(content) {
                    olOverlayWindow.removeLoadingAnimation();
                    $('#transferListCreateOfferOverlayPlayerDetailContainer').append(content);
                    setTimeout(function() {
                        _showPlayerDetails();
                        $('#createOfferTabButtons').show();
                        olGUI.tabButtonsInit('createOfferBtns');
                        olOverlayWindow.resize();
                    }, 10);

                });
            } else {
                _showPlayerDetails();
            }

            data.minDuration = parseInt(data.minDuration);

            $('#sliderDurationInput').attr('data-max', data.maxDuration);
            $('#sliderDuration').attr("data-slider-max", data.maxDuration);

            $('#sliderDurationInput').attr('data-min', data.minDuration);
            $('#sliderDuration').attr("data-slider-min", data.minDuration);

            $('#sliderDuration').data('bootstrapSlider').options.max = data.maxDuration;
            $('#sliderDuration').data('bootstrapSlider').options.min = data.minDuration;
            $('#sliderDuration').bootstrapSlider('setValue', data.minDuration);

            $('#sliderDurationMinVal').html(data.minDuration);
            $('#sliderDurationMaxVal').html(data.maxDuration);

            var event = jQuery.Event("change");
            event.value = new Object();
            event.value.newValue = data.minDuration;
            $('#sliderDuration').trigger(event);

            $('#sliderTransferFeeInput').attr('data-max', data.maxTransferFee);
            $('#sliderTransferFee').attr('data-slider-max', data.maxTransferFee);

            $('#sliderTransferFeeInput').attr('data-min', data.minTransferFee);
            $('#sliderTransferFee').attr('data-slider-min', data.minTransferFee);

            $('#sliderTransferFee').data('bootstrapSlider').options.max = data.maxTransferFee;
            $('#sliderTransferFee').data('bootstrapSlider').options.min = data.minTransferFee;
            $('#sliderTransferFee').bootstrapSlider('setValue', parseInt(data.marketValue));

            $('#sliderTransferFeeMinVal').html(olGUI.formatCurrency(data.minTransferFee, 0));
            $('#sliderTransferFeeMaxVal').html(olGUI.formatCurrency(data.maxTransferFee, 0));
            var event = jQuery.Event("change");
            event.value = new Object();
            event.value.newValue = parseInt(data.marketValue);
            $('#sliderTransferFee').trigger(event);

            //$('#salary').html(data.salary);
            //$('#marketValueSalary').html(data.marketValueSalary);
            $('#marketValue').html(olGUI.numberWithPoints(data.marketValue));
        };

        olTransferList.handleCreateOfferButtons = function(button) {
            var $playerData = $('#playerDataCol');
            var $offerData = $('#playerOfferCol');

            switch(button) {
                case 'playerData':
                    $playerData.show();
                    $offerData.hide();
                    olOverlayWindow.resize();
                    break;
                case 'offerData':
                    $playerData.hide();
                    $offerData.show();
                    break;
            }
        }

        olTransferList.onClickCreateOfferOverviewPlayerWithWarning = function(playerId, event, warn) {
            if (warn) {
                var $target = $(event.target);
                var title = $target.attr('data-msg-title'),
                    text = $target.attr('data-msg-text');
                olMsgBox.msg(title, text);
                event.preventDefault();
                event.stopPropagation();
            } else {
                olTransferList.onClickCreateOfferOverviewPlayer(playerId, event);
            }
        };

        olTransferList.refreshPlayerView = function(offerId) {
            olTransferList.showPlayerView(offerId, playerViewCloseAfterActionCallback, playerViewActionPerformed);
        };

        olTransferList.onClickAcceptBid = function(bidId, offerId, transferFee, playerName, teamName, multiWarning) {
            if (typeof multiWarning === typeof undefined) multiWarning = false;
            //var data = new Object();
            var data = {};
            if (multiWarning) {
                olMsgBox.questionById('offerTeamManagerAcceptMultiWarning', function() {
                    olTransferList.onClickAcceptBid(bidId, offerId, transferFee, playerName, teamName, false);
                });
                return;
            }

            olMsgBox.questionById('managerSelect', function() {
                data.bidId = bidId;
                addLoadingAnimationToElement($("#ol-root"));
                $.getJSON("/transferlist/acceptbid", data, function(responseData) {
                    removeLoadingAnimationFromElement($("#ol-root"));
                    if (responseData.check === "failed")
                        olMsgBox.json(responseData);
                    if (responseData.check === "success")
                        olMsgBox.json(responseData, function() {
                            olGUI.updateLiquidFunds();
                            olTransferList.showPlayerView(offerId, playerViewCloseAfterActionCallback, true);
                        });
                });
            }, false,  {
                '[player]': playerName,
                '[transferFee]': olGUI.formatCurrency2(transferFee, 0),
                '[team]': teamName
            });
        };

        olTransferList.initBidView = function(stateId, daysPerSeason) {
            daysPerSeason = parseInt(daysPerSeason);
            olGUI.colorizeStateColorElements(stateId);
            olGUI.colorizeStateColorElements(stateId, "color", true, ".ol-dark-state-color");
            olGUI.colorizeStateColorElements(stateId, "background-color", true, ".ol-dark-state-bg-color");

            $("#sliderTransferFee").bootstrapSlider({ scale: 'linear', tooltip: 'hide'});
            $("#sliderTransferFee").slider().on("change", function(slideEvt) {
                var value = parseInt(slideEvt.value.newValue);
                $("#sliderTransferFeeInput").val(olGUI.numberWithPoints(value));
                var current = $("#liquidFunds").attr("data-value");
                var newVal = parseInt(current) - value;
                $("#liquidFunds").text(olGUI.numberWithPoints(newVal));
            });

            $("#sliderSalary").bootstrapSlider({ scale: 'linear', tooltip: 'hide'});
            $("#sliderSalary").slider().on("change", function(slideEvt) {
                var value = parseInt(slideEvt.value.newValue);
                $("#sliderSalaryInput").val(olGUI.numberWithPoints(value));
                var current = $("#salarySum").attr("data-value");
                var newVal = parseInt(current) + value;
                var perWeek = parseInt(newVal / daysPerSeason + 0.5);
                var salaryPerWeek = parseInt(value / daysPerSeason + 0.5);
                $(getTransferFeeSelector()).text(olGUI.numberWithPoints(value));
                $("#salaryPerWeek").text(olGUI.numberWithPoints(salaryPerWeek));
                $("#salarySum").text(olGUI.numberWithPoints(newVal));
                $("#salarySumPerWeek").text(olGUI.numberWithPoints(perWeek));
            });
            $("#sliderDuration").bootstrapSlider({ scale: 'linear', tooltip: 'hide'});
            $("#sliderDuration").slider().on("change", function(slideEvt) {
                var value = parseInt(slideEvt.value.newValue);
                $("#snapTarget").find("span").removeClass("active");
                $($("#snapTarget").find("span").get(value - 1)).addClass("active");
            });
            $("#snapTarget span").click(function(event) {
                var val = parseInt($(event.target).text());
                $("#sliderDuration").bootstrapSlider('setValue', val);
                var event = jQuery.Event("change");
                event.value = new Object();
                event.value.newValue = val;
                $("#sliderDuration").trigger(event);
            });
        };

        var playerViewOpen = false;

        olTransferList.initPlayerView = function(originalValue, showMultiAccountWarning, offerId, dynamicCounterId) {
            if(typeof dynamicCounterId === typeof undefined) dynamicCounterId = false;
            multiAccountWarning = showMultiAccountWarning;
            $('#playerView').addClass('show');
            $("#sliderDuration").bootstrapSlider({scale: 'linear', tooltip: 'hide'});

            $("#sliderDuration").attr("data-old-value", originalValue);

            $("#sliderDuration").slider().on('change', function(ev) {
                var value = $(this).val();
                var label = $("#contractDuration" + value);
                if (label) {
                    $(".contract-duration-tick").removeClass("active");
                    label.addClass("active");
                }
            });

            if (dynamicCounterId !== false) {
                function transferListPlayerViewRefresh() {
                    if (playerViewOpen) {
                        olTransferList.refreshPlayerView(offerId);
                    }
                };
                $(dynamicCounterId).on('olCounterDone', transferListPlayerViewRefresh);
            }

            // convert transfer ending datetime to local timezone

            $("div.transfer-auction-end-date[data-utc]").each(function(i, el){
                var utcDate = $(el).attr("data-utc");
                var timeFormat = $(el).attr("data-time_format");
                var messageDateTime = new Date(utcDate);
                if (messageDateTime){
                    var texts = $(el).contents().toArray().filter(function(c){ return c.nodeType === Node.TEXT_NODE}); 
                    if (texts.length > 0) {
                        texts[0].nodeValue = " " + olRealtimeHelper.formatDatePHP(messageDateTime, timeFormat) + " ";
                    }
                }
            });

        };

        olTransferList.closeAgentFreeNegotiationOverlay = function(offerId) {
            olTransferList.showPlayerView(offerId);
            olOverlayWindow.close();
        };
        var lastScrollTop = false;

        olTransferList.showPlayerView = function(offerId, callbackCloseAfterAction, playerViewActionApplied) {
            playerViewOpen = true;

            if (typeof callbackCloseAfterAction === typeof undefined)
                callbackCloseAfterAction = false;
            if (typeof playerViewActionApplied === typeof undefined)
                playerViewActionApplied = false;
            playerViewActionPerformed = playerViewActionApplied;
            playerViewCloseAfterActionCallback = callbackCloseAfterAction;
            currentOfferOverview = offerId;
            $('#backToTransferTop').fadeOut();
            $('.transfer-player-list-table-row.focus').removeClass('focus');
            $('#transferListRow' + offerId).addClass('focus');
            var isAnimationDone = false;
            var isLoadingDone = false;
            function _showPlayerView() {
                if (isAnimationDone && isLoadingDone) {
                    loading = true;
                    getTransferListWrapperSelector().css('position', 'absolute');
                    //var minHeight = $('#playerViewContent').height();
                    //$('#transferListContent').css('', minHeight);
                    removeLoadingAnimationFromElement($('#ol-root'));
                }
            }
            lastScrollTop = $(document).scrollTop();
            var scrollTarget = (olAppInfo.APP_PLATFORM == "iphone") ? '#scrollRoot' : 'html, body';
            $(scrollTarget).animate({
                scrollTop: $('#transferListContent').offset().top - $('#ol-header-container').height() - 20
            }, 500, function() { isAnimationDone = true; _showPlayerView(); });
            addLoadingAnimationToElement($('#ol-root'));

            autoLoading = true;
            $('#playerView').show();
            $('#playerView').getLoad('/transferlist/getplayerview', { offerId: offerId }, function() {
                isLoadingDone = true;
                _showPlayerView();
            });

            //save opend UserId to share the link
            sessionStorage.setItem('transferPlayer', offerId);
        };

        olTransferList.hidePlayerView = function(scrollBack) {
            playerViewOpen = false;

            if (typeof scrollBack === typeof undefined) scrollBack = true;
            autoLoading = false;
            if (playerViewActionPerformed && playerViewCloseAfterActionCallback !== false)
                playerViewCloseAfterActionCallback();
            loading = false;
            $('#playerView').removeClass('show');
            $('#transferListContent').height('auto');
            if (scrollBack) {
                setTimeout(function() {
                    var transferListElement;
                    if (olGUI.getBootstrapDeviceSize() === 'ol-xs')
                        transferListElement = $('.mobile-transfer-player-list-row[data-offer-id=' + currentOfferOverview + ']').first();
                    else
                        transferListElement = $('#transferListItem' + currentOfferOverview);

                    $('#playerView').hide();
                    getTransferListWrapperSelector().css('position', 'relative');
                    var scrollTarget = (olAppInfo.APP_PLATFORM == "iphone") ? '#scrollRoot' : 'html, body';
                    $(scrollTarget).animate({
                        scrollTop: (transferListElement.offset().top - transferListElement.outerHeight() - 20)
                    }, 500, function() {});
                }, 300);
            }

            sessionStorage.removeItem('transferPlayer');
        };

        olTransferList.updateDropdown = function() {
            var liElements = $("#dropdownPlayerAttribute").find("ul").children();
            $(".divider").show();
            liElements.each(function(index, element) {
                var attrId = $(element).attr("data-value");
                if ($("#playerAttributeFilter" + attrId).length > 0 && $("#playerAttributeFilter" + attrId).css("display") != "none") {
                    $(element).hide();
                } else if($("#playerAttributeFilter" + attrId).length == 0) {
                    $(element).show();
                } else if(typeof attrId !== typeof undefined && attrId !== false) {
                    $(element).show();
                }
            });

            if (currentFilterCount >= maxFilterCount) {
                $('.transfer-filter-content-click-area').addClass('disabled').parent().addClass('premium-locked');
            } else {
                $('.transfer-filter-content-click-area').removeClass('disabled').parent().removeClass('premium-locked');
            }
            /*
            var selectedVal = $("#dropdownPlayerAttribute").dropdown().value();
            var selectedItem = $("#dropdownPlayerAttribute").dropdown().elementByValue(selectedVal);
            if(selectedItem.length > 0 && ($(selectedItem).css('display') == 'none'))
            {
                liElements.each(function(index, element)
                {
                    if(!($(element).css('display') == 'none') && !$(element).hasClass("divider"))
                    {
                        $("#dropdownPlayerAttribute").dropdown().selectByValue($(element).attr("data-value"));
                        return false;
                    }
                });
            }
            */
            $('#dropdownPlayerAttribute').dropdown().setText($('#dropdownPlayerAttribute').attr('data-default'));
            $('#dropdownPlayerAttribute').dropdown().setValue($('#dropdownPlayerAttribute').attr('data-default-value'));
        };
        if (olAppInfo.APP_MODE && olAppInfo.APP_PLATFORM == "iphone") {
            $('#scrollRoot').scroll(onScrollToScreen);
        } else {
            $(window).scroll(onScrollToScreen);
        }

        function onScrollToScreen() {
            console.log("scrollscreen");
            if ($("#olAutoLoading").length == 0) return;

            var loadMore = false;
            if (olAppInfo.APP_MODE && olAppInfo.APP_PLATFORM == "iphone") {
                loadMore = getWindowScroll() + $(window).height() >= (getDocHeight() - 200);
            } else {
                loadMore = $(window).scrollTop() + $(window).height() >= (getDocHeight() - ($('#ax-billboard-bottom').height() + $('.ol-footer').height()));
            }

            if (loadMore) {
                if (olTransferList.search) {
                    olTransferList.search(true);
                }
            }
        }


        olTransferList.onClickOffersOverviewOngoingButton = function() {
            olAnchorNavigation.load('/transferlist/getoffersoverview', undefined, '');
        };

        olTransferList.onClickBidsOverviewOngoingButton = function() {
            getTransferListWrapperSelector().getLoad('/transferlist/getbidssuboverview');
        };

        olTransferList.onClickBidsOverviewEndedButton = function() {
            /*
            bidsViewOngoing = false;
            olTransferList.search();
            url, target, data, force
            */
            getTransferListWrapperSelector().getLoad('/transferlist/getbidsendedoverview');
        };

        olTransferList.onClickOffersOverviewEndedButton = function() {
            /*
            bidsViewOngoing = false;
            olTransferList.search();
            */
            addLoadingAnimationToElement(getTransferListWrapperSelector());
            getTransferListWrapperSelector().load('/transferlist/getoffersendedoverview', function() {
                 removeLoadingAnimationFromElement(getTransferListWrapperSelector());
            });
        };
        if (currentView == 'transferList') {olTransferList.savedTransferfilter();} // restore saved filters when on transferlist
    };

    olTransferList.openBidOverviewAndShowOfferView = function(offerId) {
        olAnchorNavigation.load(olTransferList.getTransferListUrl(offerId));
    };

    olTransferList.getTransferListUrl = function(offerId) {
        return '/transferlist/gettransferlistview?offerId=' + offerId;
    };

    olTransferList.openOfferOverviewAndShowOfferView = function(offerId) {
        loadPage('/transferlist/getoffersoverview', {}, function() {
            olTransferList.showPlayerView(offerId);
        });
    };

    function _hideAllOverviewRows() {
        if (copyOfTheTable === false)
            copyOfTheTable = $('#transferListContentWrapper').html();
        else
            $('#transferListContentWrapper').html(copyOfTheTable);
    }

    var copyOfTheTable = false;

    olTransferList.onClickOverviewHeaderDropdownShowAll = function() {
        _hideAllOverviewRows();
        var count = $('.transfer-player-list-table-row:not(.transfer-player-list-table-header)').length;
        _updateResultCounter(count);
    };

    olTransferList.onClickOverviewHeaderDropdownShowAccepted = function() {
        _hideAllOverviewRows();
        var count = $('.transfer-player-list-table-row.ol-offer-accepted').length;
        _updateResultCounter(count);
        $('.transfer-player-list-table-row:not(.ol-offer-accepted):not(.transfer-player-list-table-header)').detach();
    };

    olTransferList.onClickOverviewHeaderDropdownShowNotAccepted = function() {
        _hideAllOverviewRows();
        var count = $('.transfer-player-list-table-row.ol-offer-not-accepted').length;
        _updateResultCounter(count);
        $('.transfer-player-list-table-row:not(.ol-offer-not-accepted):not(.transfer-player-list-table-header)').detach();
    };

    olTransferList.onClickOverviewHeaderDropdownShowPending = function() {
        _hideAllOverviewRows();
        var count = $('.transfer-player-list-table-row.ol-offer-pending').length;
        _updateResultCounter(count);
        $('.transfer-player-list-table-row:not(.ol-offer-pending):not(.transfer-player-list-table-header)').detach();
    };

    olTransferList.onClickOverviewHeaderDropdownShowDone = function() {
        _hideAllOverviewRows();
        var count = $('.transfer-player-list-table-row.ol-offer-done').length;
        _updateResultCounter(count);
        $('.transfer-player-list-table-row:not(.ol-offer-done):not(.transfer-player-list-table-header)').detach();
    };

    olTransferList.markSuspicious = function(offerId, element) {
        $.post('/transferlist/marksuspicious', {offerId: offerId, suspicious: element.checked ? 1 : 0}, function(res) {
            if (res.suspicious == 1) {
                $(element).parent().find('.ol-checkbox-label').addClass('checked');
                $(element).attr('checked', true);
            } else {
                $(element).parent().find('.ol-checkbox-label').removeClass('checked');
                $(element).attr('checked', false);
            }
            olTransferList.refreshPlayerView(offerId);
        });
    }

    olTransferList.adminDeleteCheck = function(bidId, offerId) {
        olMsgBox.questionById('adminConfirmDelete', function() {olTransferList.adminDeleteBid(bidId, offerId)});
    }

    olTransferList.adminDeleteBid = function(bidId, offerId) {
        $.post('/transferlist/admindelete', {bidId: bidId}, function(res) {
            olMsgBox.json(res, function() {
                olTransferList.refreshPlayerView(offerId);
            });
        });
    }

    olTransferList.updateWatchListButton = function(element) {
        if ($('#transferListwatchList .watch-list-checkbox-widget:visible input:checked').length > 0) {
            $('.ol-transfer-list-watch-list-button').fadeIn();
        } else {
            $('.ol-transfer-list-watch-list-button').fadeOut();
        }
    };

    olTransferList.watchListToggleCheckAll = function(element) {
        $('#transferListwatchList .watch-list-checkbox-widget input').prop('checked', $(element).is(':checked'));
        olTransferList.updateWatchListButton();
    };

    olTransferList.removeFromWatchList = function() {
        olPlayerWatchList.removeFromWatchList($('#transferListwatchList .watch-list-checkbox-widget:visible input:checked')
        .map(function() {
            return $(this).attr('data-player-watch-list-id');
        }).get(), olTransferList.search);
    };

    olTransferList.handleTabFilters = function(filter) {
        var $offersEnded = $('#checkboxOffersEnded');
        var $freeAgents = $('#checkboxFreeAgents');

        switch(filter) {
            case 'auctions':
                if($offersEnded.is(':checked')) {
                    $offersEnded.click();
                }
                if ($freeAgents.is(':checked')) {
                    $freeAgents.click();
                }
                break;
            case 'immidiateBuy':
                if(!$freeAgents.is(':checked')) {
                    $freeAgents.click();
                }
                if($offersEnded.is(':checked')) {
                    $offersEnded.click();
                }
                break;
            case 'ended':
                if(!$offersEnded.is(':checked')) {
                    $offersEnded.click();
                }
                if ($freeAgents.is(':checked')) {
                    $freeAgents.click();
                }
                break;                
        }
    }

    olTransferList.togglePlayerView = function(view) {
        var $playerDetails = $('.ol-transferlist-playerview-offer-player-details');
        var $playerAttr = $('.ol-transferlist-playerview-player-attr');
        var $playerBid = $('.ol-transferlist-playerview-offer');
        var $bids = $('#playerViewContent');
        var hidden = 'hidden-xs hidden-sm';

        switch(view) {
            case 'playerData':
                $playerDetails.removeClass(hidden);
                $playerAttr.addClass(hidden);
                $playerBid.addClass(hidden);
                $bids.addClass(hidden);
                break;
            case 'playerAttr':
                $playerDetails.addClass(hidden);
                $playerAttr.removeClass(hidden);
                $playerBid.addClass(hidden);
                $bids.addClass(hidden);
                break;
            case 'bid':
                $playerDetails.addClass(hidden);
                $playerAttr.addClass(hidden);
                $playerBid.removeClass(hidden);
                $bids.addClass(hidden);
                break;
            case 'userBids':
                $playerDetails.addClass(hidden);
                $playerAttr.addClass(hidden);
                $playerBid.addClass(hidden);
                $bids.removeClass(hidden);
                break;
        }    
    }


}( window.olTransferList = window.olTransferList || {}, jQuery ));
