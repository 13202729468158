(function(olUserSearch, $, undefined) {
    var _timeout = null;
    var _keyword;
    var _ref;

    olUserSearch.onClickUser = function(team, uid, level) {
        if (typeof team === typeof undefined || uid <= 0)
            return;
        if (typeof _ref === typeof undefined)
            return;

        var $ref = $(_ref);
        var $input = $('#inputUserSearchKeyword', $ref);
        var $dpFeature = $('#inputUserSearchDoublePremiumFeature', $ref);
        var $chosen = $('#inputUserSearchChoosed', $ref);
        var $team = $(team);
        $('div.ol-liga-font', $team).remove();

        $input.val(uid);
        $input.trigger('changedirect', [parseInt(uid), parseInt(level)]);
        $chosen.find('.ol-chosen').html($team.html());

        $input.hide();
        $chosen.show();
        if ($dpFeature) $dpFeature.hide();

        hideSuggestions($ref);
    };

    olUserSearch.onClickUserDelete = function(ref) {
        if (typeof _ref === typeof undefined && typeof ref === typeof undefined)
            return;
        
        if (ref.indexOf('#') < 0)
            ref = '#' + ref;

        var $ref = $(ref || _ref);
        var $input = $('#inputUserSearchKeyword', $ref);
        var $dpFeature = $('#inputUserSearchDoublePremiumFeature', $ref);
        var $chosen = $('#inputUserSearchChoosed', $ref);

        $input.val("");
        $input.trigger('changedirect', [0, -1]);
        $chosen.find('.ol-chosen').html("");

        $input.show();
        $chosen.hide();
        if ($dpFeature) $dpFeature.show();
    };

    olUserSearch.search = function(keyword, ref) {
        if (ref.indexOf('#') < 0)
            ref = '#' + ref;

        var $ref = $(ref);
        var timeout = 500;

        if (typeof keyword === typeof undefined) {
            keyword = $('#inputUserSearchKeyword', $ref).val();
            timeout = 0;
        }

        if (typeof keyword === typeof undefined || keyword.length < 3)
            return;
        _keyword = keyword;
        _ref = ref;

        if (_timeout !== null)
            return;

        _timeout = setTimeout(function() {
            if (_keyword && _keyword.length >= 3) {
                $.ajax({
                    type: 'GET',
                    url: "/api/user/search",
                    data: {
                        keyword: _keyword
                    },
                    success: function(data) {
                        showSuggestions(data, $ref);
                    }
                });
            } else {
                closeSuggestions($ref);
            }
            _timeout = null;
        }, timeout);
    };

    function showSuggestions(content, ref) {
        var $ref = $(ref);
        var $sugg = $('#inputUserSearchSuggestions', $ref);
        $sugg.html(content).show();
        $sugg.on('touchmousedown', function(ev) {
            ev.stopPropagation();
        });
        $(window).one("touchmousedown", function() {
            $sugg.off('touchmousedown');
            setTimeout(function() {
                hideSuggestions($ref);
            }, 500);
        });
    }

    function hideSuggestions(ref) {
        $('#inputUserSearchSuggestions', $(ref)).hide();
    }

    function closeSuggestions(ref) {
        $('#inputUserSearchSuggestions', $(ref)).empty().hide();
    }
}(window.olUserSearch = window.olUserSearch || {}, jQuery));
