// TODO Move to namespace object

var isRequestDone = true;
var olStadiumEditorCall;

var tooltipsFlg = false;
var newToolTips;

$(function() {
    if (tooltipsFlg == false) {
        tooltipsFlg = true;
        newToolTips = $.fn.tooltip;
    }
    $.fn.tooltipNew = newToolTips;
});


(function(olStadiumEditor, $, undefined) {
    var lastLoadingContext = 0;
    
    olStadiumEditor.init = function(config) {
        var visuals = {};
        var canvas, ctx;
        
        var renderOrderArr = [];
        
        var primaryColor = Color.RGBtoHSL(config.primaryColor);
        var secondaryColor = Color.RGBtoHSL(config.secondaryColor);
        
        var colorTypePrimary = _getColorType(primaryColor);
        var colorTypeSecondary = _getColorType(secondaryColor);
        
        $('#editorImage').stop(true, true).show();

        if (document.getElementById('editor')) {
            _initCanvas();
            _loadVisuals();
        }

        function _getColorType(hsl) {
            if (hsl[2] > 0.75 && hsl[1] < 0.25) {
                return 2; // white
            } else if (hsl[2] < 0.1) {
                return 0; // black
            } 
            return 1;
        }
        
        function _drawVisuals(loadingContext) {
            if (loadingContext < lastLoadingContext) return;
            function sortNumber(a,b) {
                return a - b;
            }
            
            var renderOrderSet = renderOrderArr.filter(function(elem, index, self) {
                return index == self.indexOf(elem);
            });
            renderOrderSet.sort(sortNumber);
            //clear context
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle="rgba(200,200,200,0.1)";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
                
            for (var i = 0;  i < renderOrderSet.length;  i++) {
                var elements = visuals[renderOrderSet[i]];
                $.each(elements, function(index, image) {
                    ctx.drawImage(image,0,0);
                });
            }

            if ($('#editorImage').length) {
                if (loadingContext < lastLoadingContext) return;
                if ($('#recentStadium').height() < 100) {
                    $('#recentStadium').height($('#editorImage').height());
                }
                $('#editorImage').height('auto');
                
                $('#editorImage').fadeOut(2000, function() {
                    var _editorImageElement = document.getElementById('editorImage');
                    var _editorElement = document.getElementById('editor');
                    if(_editorImageElement !== null && _editorElement !== null)
                        _editorImageElement.src = _editorElement.toDataURL();
                });
            }
            
            $('#editor').fadeIn(2000);
        }
        
        function _colorize(image) {
            var colorCanvas = document.createElement('canvas');
            colorCanvas.width = canvas.width;
            colorCanvas.height = canvas.height;
            var cCtx = colorCanvas.getContext('2d');

            cCtx.clearRect(0, 0, canvas.width, canvas.height);
            cCtx.drawImage(image, 0, 0);
            
            var imgData = cCtx.getImageData(0, 0, canvas.width, canvas.height);
            var data = imgData.data;
            
            for (var i = 0;  i < data.length;  i += 4) {
                var red = data[i];
                var green = data[i+1];
                var blue = data[i+2];
                var alpha = data[i+3];
                
                var imgRGB = [red, green, blue];
                
                if (alpha < 1) continue;
                var hsl = Color.RGBtoHSL([red, green, blue]);
                if (hsl[1] > 0.1) {
                    if ((hsl[0] >= 0.0 && hsl[0] < 0.35) || hsl[0] > 0.95) {
                        // yellow
                        rgb = _colorizePixel(primaryColor, colorTypePrimary, hsl, imgRGB);
                        data[i] = rgb[0];
                        data[i+1] = rgb[1];
                        data[i+2] = rgb[2];
                    } else if((hsl[0] > 0.66 && hsl[0] <= 0.95)) {
                        // magenta
                        rgb = _colorizePixel(secondaryColor, colorTypeSecondary, hsl, imgRGB);
                        data[i] = rgb[0];
                        data[i+1] = rgb[1];
                        data[i+2] = rgb[2];
                    }
                }
            }
            cCtx.putImageData(imgData, 0, 0);
            var image2 = new Image();
            image2.src = colorCanvas.toDataURL();
            return image2;
        }
        
        function _colorizePixel(targetColorHSL, targetColorType, srcPixelHSL, imgRGB) {
            switch (targetColorType) {
                case 0:
                    return _colorizeBlack(targetColorHSL, srcPixelHSL, imgRGB);
                    break;
                case 1:
                    return _colorizeColor(targetColorHSL, srcPixelHSL, imgRGB);
                    break;
                case 2:
                    return _colorizeWhite(targetColorHSL, srcPixelHSL, imgRGB);
                    break;
            }
        }
        
        function _colorizeBlack(targetColorHSL, srcPixelHSL, imgRGB) {
            var lerp = srcPixelHSL[1];
            var lerpInv = (1.0 - lerp);
            
            var newHSL = new Array();
            newHSL[0] = targetColorHSL[0];
            newHSL[1] = targetColorHSL[1] * srcPixelHSL[1];
            newHSL[2] = Math.min(Math.max(targetColorHSL[2], 0.1), srcPixelHSL[2]);//targetColorHSL[2]* 0.2 + srcPixelHSL[2] * 0.8;
            newHSL[2] = newHSL[2] * lerp + lerpInv * srcPixelHSL[2];
            
            var rgb = Color.HSLtoRGB(newHSL);
            return rgb;
        }
        
        function _colorizeWhite(targetColorHSL, srcPixelHSL, imgRGB) {
            var lerp = srcPixelHSL[1];
            var lerpInv = (1.0 - lerp);
            
            var newHSL = new Array();
            newHSL[0] = targetColorHSL[0];
            newHSL[1] = targetColorHSL[1] * srcPixelHSL[1];
            newHSL[2] = srcPixelHSL[2] + targetColorHSL[2] * srcPixelHSL[2] * 1.9;//targetColorHSL[2]* 0.2 + srcPixelHSL[2] * 0.8;
            newHSL[2] = newHSL[2] * lerp + lerpInv * srcPixelHSL[2];
            
            var rgb = Color.HSLtoRGB(newHSL);
            return rgb;
        }
        
        function _colorizeColor(targetColorHSL, srcPixelHSL, imgRGB) {
            var lerp = srcPixelHSL[1];
            var lerpInv = (1.0 - lerp);
            
            var newHSL = new Array();
            newHSL[0] = targetColorHSL[0];
            newHSL[1] = targetColorHSL[1] * srcPixelHSL[1]; 
            newHSL[2] = Math.min(targetColorHSL[2], srcPixelHSL[2]);
            if (targetColorHSL[2] > 0.5) {
                factor = 1.0 + 0.4 * (1.0 - targetColorHSL[2]) * 2;
                newHSL[2] *= factor;
            }
            newHSL[2] = newHSL[2] * lerp + lerpInv * srcPixelHSL[2];
            
            var rgb = Color.HSLtoRGB(newHSL);
            return rgb;
        }
        
        function _lerpHSL(value1, weight1, value2, weight2) {
            if (weight2 > 0) {
                var diff = (value1 > value2 ? (value1 - value2) : (value2 - value1));
                if (Math.abs(diff) > 0.51) {
                    if (value1 < value2) 
                        value1 += 1.0;
                    else 
                        value2 += 1.0;
                    var val =(value1 * weight1 + value2 * weight2) % 1.0;
                    return val;
                }
            }
            return value1 * weight1 + value2 * weight2;
        }
        
        function _initCanvas() {
            canvas = document.getElementById('editor');
            ctx = canvas.getContext('2d');

            if ($('#editorImage').length) {
                $('#editorImage').height($('#stadiumPreviewLoading').height());
                $('#recentStadium').height($('#stadiumPreviewLoading').height());

                canvas.width= /*1310;//*/$("#recentStadium").width();
                canvas.height= /*700;//*/$("#recentStadium").height();

                var aspectRatio = $("#recentStadium").height() / $("#recentStadium").width();

                canvas.width = Math.max(1380, 700 * (1/aspectRatio));
                canvas.height =  Math.min(700, 1380 * aspectRatio);

                console.log("dimensions:", $("#recentStadium").width(), $("#recentStadium").height());
                console.log("canvas dimensons:", canvas.width, canvas.height);
            } else {
                canvas.width= $("#recentStadium").width();
                canvas.height= $("#recentStadium").height();
            }

            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        
        var stdmRenderOrder = 100;
        var callTimer;
        var totalVisual = 0;
        var loadCounter = 0;
        function _loadVisual(renderOrder, visual, loadingContext) {
            if (typeof visuals[renderOrder] === 'undefined') {
                visuals[renderOrder] = new Array();
            }
            
            if (visual.filename != null) {
                var image = new Image();
                image.crossOrigin = "Anonymous";
                if (visual.loadingImg) {
                    image.src = config.config.baseUrl + visual.filename;
                } else if (visual.filename == 'dp') {
                    // I realize this is a shitty bandaid fix, but I couldn't figure out why the script was trying
                    // to load the directory with no filename, so I'm just doing this instead
                    setTimeout(function() {
                        loadCounter++;
                        if (loadCounter >= totalVisualNew) {
                            clearTimeout(callTimer);
                            callTimer = setTimeout(function() {
                                lastCall(loadingContext);
                            }, 1);
                        }
                    }, 200)
                    return;
                } else {
                    image.src = config.config.imgBaseUrl + visual.filename;
                }
                var totalVisualNew = totalVisual;
                $(image).load(function(err) { 
                    loadCounter ++;
                    if(visual.colorize)
                        image = _colorize(image);
                    stdmRenderOrder ++;
                    
                    setTimeout(function() {
                        $("#imageLoader").append(image);
                        visuals[renderOrder].push(image)
                        renderOrderArr.push(renderOrder);
                        if (loadCounter >= totalVisualNew) {
                            clearTimeout(callTimer);
                            callTimer = setTimeout(function() {
                                lastCall(loadingContext);
                            }, 1);
                        }
                    }, stdmRenderOrder);
                }).error(function(err) {
                    loadCounter ++;
                    if (loadCounter >= totalVisualNew) {
                        clearTimeout(callTimer);
                        callTimer = setTimeout(function() {
                            lastCall(loadingContext);
                        }, 1);
                    }
                });
            }
        }
        
        function _loadVisuals() {
            var loadingContext = ++lastLoadingContext;
            var maxVisuals = Object.keys(config.visuals).length;
            var count = 0;

            totalVisual = maxVisuals;

            $.each(config.visuals, function(renderOrder, elements) {
                count++;
                var maxElements = elements.length;

                $.each(elements, function(index, visual) {
                    _loadVisual(renderOrder, visual, loadingContext);
                });
            });
        }

        function lastCall(loadingContext) {
            $("#recentStadium").css("visibility","visible");
            if ($('#stadiumLoading').length) {
                if (typeof removeLoadingAnimationFromElement == 'function' && $('#stadiumPreviewLoading').length) {
                    removeLoadingAnimationFromElement($('#stadiumPreviewLoading'));
                }
                $('#stadiumLoading').fadeOut(600);
            }

            _drawVisuals(loadingContext);
        }
    };
    
}(window.olStadiumEditor = window.olStadiumEditor || {}, jQuery)); 