window.olNavigationModules = window.olNavigationModules || {};

(function(olNavigationHeader, $, undefined) {
    var _suburl = '';
    var _target = '';
    var _packets = {};
    var _subcall = function() {
        if (_suburl.length <= 0 || _target.length <= 0)
            return;

        var data = {};
        Object.values(_packets).forEach(function(packet) {
            if ($.isFunction(packet)) {
                $.extend(data, packet());
            } else if ($.isPlainObject(packet)) {
                $.extend(data, packet);
            } else {
                console.log("Unknown packet type!", packet);
            }
        });
        data.subcall = 1;

        olAnchorNavigation.loadSubPage(_suburl, _target, data);
    };

    // init navigation
    olNavigationHeader.init = function(config) {
        if (typeof config.url === typeof "" && config.url.length > 0) {
            _suburl = config.url;
            if (_suburl.indexOf('/') !== 0)
                _suburl = '/' + _suburl;
        }
        if (typeof config.target === typeof "" && config.target.length > 0) {
            _target = config.target;
            if (_target.indexOf('#') !== 0)
                _target = '#' + _target;
        }
    };

    // reset navigation
    olNavigationHeader.reset = function(reload) {
        Object.keys(window.olNavigationModules).forEach(function(module) {
            var navModule = 'olNav' + module.charAt(0).toUpperCase() + module.slice(1);
            if (typeof olNavigationHeader[navModule] !== typeof undefined)
                olNavigationHeader[navModule].reset();
        });

        if (reload === true)
            olNavigationHeader.reload();
    };

    // reload
    olNavigationHeader.reload = function() {
        _subcall();
    };

    // init navigation modules
    olNavigationHeader.initModuleTime = function(config) {
        olNavigationHeader.initModule('time', config);
    };

    olNavigationHeader.initModuleLeague = function(config) {
        olNavigationHeader.initModule('league', config);
    };

    olNavigationHeader.initModuleGeolocation = function(config) {
        olNavigationHeader.initModule('geo', config);
    };

    olNavigationHeader.initModuleSearch = function(config) {
        olNavigationHeader.initModule('search', config);
    };

    olNavigationHeader.initModuleToggle = function(config) {
        olNavigationHeader.initModule('toggle', config);
    };

    olNavigationHeader.initModule = function(module, config) {
        if (typeof module !== typeof "")
            return;
        if (typeof window.olNavigationModules[module] === typeof undefined)
            return;

        var navModule = 'olNav' + module.charAt(0).toUpperCase() + module.slice(1);
        if (typeof olNavigationHeader[navModule] === typeof undefined)
            olNavigationHeader.registerModule(module);

        olNavigationHeader[navModule].init(config);
    };

    // register navigation module
    olNavigationHeader.registerModule = function(module) {
        if (typeof module !== typeof "")
            return;
        if (typeof window.olNavigationModules[module] === typeof undefined)
            return;

        var navModule = 'olNav' + module.charAt(0).toUpperCase() + module.slice(1);
        window.olNavigationModules[module](olNavigationHeader[navModule] = olNavigationHeader[navModule] || {}, $, _packets, _subcall, undefined);
    };

    var _loader = {};
    olNavigationHeader.registerAutoloader = function(events, url, loader, target, parent, success, failed) {
        if (typeof events !== "string" || events.length <= 0)
            return;
        if (typeof url !== "string" || url.length <= 0)
            return;
        if (typeof loader !== "string" || loader.length <= 0)
            return;
        if (typeof target !== "string" || target.length <= 0)
            return;

        if (typeof parent === "string" && parent.length > 0) {
            loader = parent + " " + loader;
            target = parent + " " + target;
        } else if ($.isFunction(parent)) {
            failed = success;
            success = parent;
            parent = undefined;
        }

        _loader[loader] = {events: events, url: url, loading: false};
        function _autoload() {
            var $loader = $(loader);
            if ($loader.length > 0 && $loader.isInViewport() && !_loader[loader].loading) {
                _loader[loader].loading = true;

                addLoadingAnimationToElementNoTimeOut($loader, {size: "small"});

                var page = parseInt($loader.data('page'));

                var data = parseURLParams();
                delete data.subcall;

                data.subpage = page;
                data.subload = 1;

                $.get(url, data, function(response) {
                    if (response.success) {
                        if (response.autoloading) {
                            $loader.data('page', ++page);
                        } else {
                            $(window).off(events, _autoload);
                            $('div', $loader).removeAttr('hidden');
                        }

                        $(target).append(response.content);

                        if ($.isFunction(success))
                            success();
                    } else {
                        if ($.isFunction(failed))
                            failed();
                    }
                    removeLoadingAnimationFromElement($(loader));
                    _loader[loader].loading = false;
                });
            }
        }

        $(window).on(events, _autoload);
        onUnloadPage(function() {
            $(window).off(events, _autoload);
            delete _loader[loader];
        });
    };
}(window.olNavigationHeader = window.olNavigationHeader || {}, jQuery));

