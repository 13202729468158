(function(olSupport, $, undefined) {
    var typeSubject;
    var strSubject;
    var strMailto;

    olSupport.sendRequest = function() {
        var email = $('#ol-support-email').val();
        var msg = $('#ol-support-msg').val();

        if (typeof typeSubject === typeof undefined
            || typeof email === typeof undefined
            || email === ""
            || typeof msg === typeof undefined
            || msg === "") {
            error(typeSubject, msg, email);
            return;
        }

        $.post('/support/request', {'subject': typeSubject, 'email': email, 'msg': msg}, function(data) {
            if (data) {
                if (data.success) {
                    olSupport.resetForm();
                    olMsgBox.msg(Lang.trans('js/support.contactForm'), Lang.trans('js/support.successMsg'));
                } else if (data.error) {
                    if (data.error.msgempty)
                        olMsgBox.msg(Lang.trans('js/support.hint')+':', Lang.trans('js/support.hintEmptyMsg'));
                    else if (data.error.cdnotyet)
                        olMsgBox.msg(Lang.trans('js/support.hint')+':', Lang.choice('js/support.hintTime', data.error.cdtime, { n: data.error.cdtime }));
                    else
                        olMsgBox.msg(Lang.trans('js/support.hint')+':', Lang.trans('js/support.failSend'));
                }
            }
        });
    };

    olSupport.mailRequest = function() {
        var email = $('#ol-support-email').val();
        var msg = $('#ol-support-msg').val();

        if (typeof typeSubject === typeof undefined
            || typeof email === typeof undefined
            || email === ""
            || typeof msg === typeof undefined
            || msg === "") {
            error(typeSubject, msg, email);
            return;
        }

        var mailto = strMailto;
        var addr = 'mailto:' + mailto + '?subject=' + strSubject + '&body=' + msg;
        if (typeof email !== typeof undefined)
            addr = addr + '&bcc=' + email;

        window.open(encodeURI(addr));
        olSupport.resetForm();
    };

    olSupport.setSubject = function(elem, type, mail) {
        typeSubject = type;
        strSubject = Lang.trans('js/support.subject', { topic: $(elem).text() });
        strMailto = mail;
    };

    olSupport.resetForm = function() {
        $('#ol-support-email:not([disabled])').val("");
        $('#ol-support-msg').val("");
    };

    function error(typeSubject, msg, email) {
        var err = "";
        if (typeof typeSubject === typeof undefined)
            err += Lang.trans('js/support.errorSubject');
        if (typeof email === typeof undefined || email === "")
            err += Lang.trans('js/support.errorEmail');
        if (typeof msg === typeof undefined || msg === "")
            err += Lang.trans('js/support.errorMsg');
        err += "<br>";
        olMsgBox.msg(Lang.trans('js/support.hint')+':', err);
    }
}(window.olSupport = window.olSupport || {}, jQuery));
