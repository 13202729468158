/*(function() { 
    var tableSelector;
    $(document).on('touchclick', '.player-info-control-wrapper', function(ev) {
        var $target = $(ev.target);
        var left = false;
        if ($target.hasClass('ol-left')) left = true;

        step(left);
    });

    var touchStartX = 0;
    var touchMove = 0;
    var oldTouch = false;
    function touchScrollSteps(ev) {
        var event = ev.originalEvent;
        if (ev.type == "touchstart") {
            touchStartX = event.touches[0].clientX;
            return;
        }

        if (ev.type == "touchmove") {
            touchMove = event.touches[0].clientX - touchStartX;

            // Set a lower limit so minor touches don't move the div
            if (Math.abs(touchMove) > 30 && !oldTouch) {

                if (touchMove > 0) {
                    step(true);
                }  else {
                    step(false);
                }
                oldTouch = true;
            }

            return;
        }

        // Remove the div if needed
        if (ev.type == "touchend") {
            oldTouch = false;
        }
    }

    //$(document).on("touchstart touchmove touchend", '.ol-player-evolution-table-inner', touchScrollSteps);


    function step(left) {
        // Cache selector for performance
        if (!tableSelector) tableSelector = $('.ol-player-evolution-table-main');

        var step = window.innerWidth/2 - 58;
        var current = tableSelector.scrollLeft()
        current = Math.round(current/step);
        
        if (left) {
            current -= 1;
        } else {
            current += 1;
        }

        var target = (current * step);

        tableSelector.animate({"scrollLeft":(target)}, 200);
    }

})();*/