// use the resources/views/comment/comment.blade.php template
// use the resources/views/comment/comment_single.blade.php template

(function(olComment, $, undefined) {
    var debug = false;
    var mayWriteCache = {};
    var scrollToQuestionLine = null;
    var scrollToAnswerLine = null;
    var measureSize = false;
    var maxCharacter = 2000;
    //maximale Anzahl einzugebender Zeichen im Kommentar
    /**
     * Initialisieren des Widgets
     *   - formatieren der Kommentarzeile als emoji
     *   - formatieren der Texte aller Kommentare
     */
    olComment.initWidget = function(uniqueId, placeholder, mayWrite, hasNext) {
        var $container = $('#' + uniqueId);
        mayWriteCache[uniqueId] = [mayWrite ? 1 : 0, placeholder];
        if (mayWrite && !hasNext) {
            $('.comment_input_question_line').show();
            olComment.initEmojione($container, 'question', placeholder);
        }
        $('.comment_text', $container).each(function(i, elem) {
            var $div = $(elem);
            $div.html(olComment.formatText($div, $div.html(), true));
            $div.slideDown(200, 'swing');
        });
        var $widget = $container.closest('.comment_widget');
        // DOMÄnderung
        olComment.adapt2Screen($widget);
        // auch nach resize
        window.addEventListener('resize', function() {
            olComment.adapt2Screen($widget);
        });
    };
    olComment.onUnload = function($container) {
        var $widget = $container.closest('.comment_widget');
        window.removeEventListener('resize', function() {
            olComment.adapt2Screen($widget);
        });
    };

    /**
     * Layoutänderung aufgrund von Screensize
     */
    olComment.adapt2Screen = function($container) {
        var ss = olGUI.getBootstrapDeviceSize();
        if (debug)
            console.log('deviceSize:' + ss);
        // ab welcher Bildschirmbreite wird das compressed MobileLayout gezeigt
        var $widget = $container;
        var doMobileLayout = ($widget.width() < 700);
        if (!$container.hasClass('comment_widget')) {
            $widget = $container.closest('.comment_widget');
        }
        if (debug)
            console.log(ss, 'widget-width:' + $widget.width());
        // baue die Anzahl Spalten des Kommentarbereiches um, wenn in Mobile
        // bei Erstaufruf und jedem Nachladen: neuer Kommentar, borherige-alle anzeigen
        $('.comment_single_answer > table > tbody > tr:nth-Child(2)', $container).each(function(i, row) {
            var $row = $(row);
            var $children = $row.children();
            if (($children.length == 3) && doMobileLayout) {
                // Spalte verbreitern
                $children.first().attr('colspan', 2);
                // letzte Spalte weg
                $children.last().remove();
            }
            if (($children.length == 2) && !doMobileLayout) {
                // Spalte colspan raus
                $children.first().removeAttr('colspan');
                // letzte Spalte wieder ran
                $row.append($('<td />', {'class': 'comment_menu_column'}));
            }
        });
        $('.comment_input_answer_line > table > tbody > tr', $container).each(function(i, row) {
            var $row = $(row);
            var $children = $row.children();
            if (($children.length == 4) && doMobileLayout) {
                // Spalte verbreitern
                $children.first().next().attr('colspan', 2);
                // letzte Spalte weg
                $children.last().remove();
            }
            if (($children.length == 3) && !doMobileLayout) {
                // Spalte colspan raus
                $children.first().next().removeAttr('colspan');
                // letzte Spalte wieder ran
                $row.append($('<td />', {'class': 'comment_menu_column'}));
            }
        });
        // wenn Befehl von ganz oben :-) -> resize oder Erstaufruf
        if ($container.hasClass('comment_widget')) {
            $widget.data('ss', ss);
            $widget.data('max-width-question', $('.comment_input_question_line .comment_input_question', $widget).next().width());
            $widget.removeAttr('data-max-width-answer');
            $('.comment_single_answer, .comment_single_question', $widget).each(function(i, comment) {
                var $comment = $(comment);
                var $div = $('.comment_text', $comment);
                var maxWidth = parseInt($comment.width()) - parseInt($('table>tbody>tr:first-child>td:first-child', $comment).width()) - parseInt($div.css('padding-left')) - parseInt($div.css('padding-right'));
                if (debug)
                    console.log($comment.attr('class') + ':' + $comment.width(), 'td:first:' + $('table>tbody>tr:first-child>td:first-child', $comment).width(), 'padding-left:' + $div.css('padding-left'), 'padding-right:' + $div.css('padding-right'), 'max-width' + maxWidth);
                if (maxWidth > 0)
                    $div.css('max-width', maxWidth);
                // $div.width(maxWidth);
            });
        }
    };
    /**
     * Initialisieren einer Textarea als emoji Eingabe
     *   - setzen eines vordefinierten Textes
     *   - setzen der Cursorposition
     *   - setzen der Breite mit Erstdarstellung, damit lange Texte nicht das Layout zerschiessen
     */
    olComment.initEmojione = function($container, type, placeholder, pretext) {
        var $domAnchor = $('.comment_input_' + type, $container);
        var cursorPos = 0;
        if (!$domAnchor.hasClass('emojionearea')) {
            if ($domAnchor.html() != $domAnchor.text()) {
                $domAnchor.html(emojione.toShort($domAnchor.html()));
            }
            if ((type == 'answer') && (typeof (pretext) != 'undefined')) {
                $domAnchor.text(pretext);
            }
            $domAnchor.emojioneArea({'placeholder': placeholder,
                // keydown, bei keypress wäre zwar Enter korrekt gehandlt aber auch im Text
                'events': {'keydown': function($editor, event) {
                        if ((event.which == 13) && !event.ctrlKey && !event.shiftKey) {
                            // popup der Shortcuts bei Doppelpunkt, darf nicht sichtbar sein
                            if (!$('ul[data-strategy="emojionearea"]').is(':visible')) {
                                event.preventDefault();
                                if (typeof ($domAnchor.next().data('issending')) == 'undefined') {
                                    //$domAnchor.next().data('issending', 1);
                                    if ($editor.parent().next().hasClass('comment_send_button')) {
                                        $editor.parent().next().trigger('click');
                                    } else {
                                        $('.comment_send_button', $editor.closest('td').next()).trigger('click');
                                    }
                                }
                            }
                        }

                    },
                    'keyup': function($editor, event) {
                        countChar(this, type);
                    }

                }
            });
            cursorPos = $domAnchor[0].emojioneArea.getText().length;
        } else {
            if ((type == 'answer') && (typeof (pretext) != 'undefined')) {
                var old = $domAnchor[0].emojioneArea.getText();
                old = old.replace(/^@.*?: /, '');
                pretext = pretext + old;
                $domAnchor[0].emojioneArea.setText(pretext);
                cursorPos = pretext.length;
            } else {
                cursorPos = $domAnchor[0].emojioneArea.getText().length;
            }
        }
        var $editor = $('.emojionearea-editor', $domAnchor.next());
        // besonderer Umstand: nach Sprung und alle Antworten anzeigen
        // steht hier der placeholder der Fragen, stezen des der Antworten
        $editor.attr('placeholder', placeholder);
        // nur wenn wirklich etwas in der Eingabezeile steht
        // Effekt war beim Init der Focus und Scroll zu dem Feld
        // Effekt beim Bearbeiten und Antworte aber okay
        if (cursorPos > 0) {
            olGUI.setCurrentCursorPosition($editor, cursorPos);
        }
        // darf nicht größer werden als erlaubtes maximum
        // beim erstellen, 1. Frage und 1. Antwort merken
        // wegen späterer überlanger texte
        var $widget = $domAnchor.closest('.comment_widget');
        if (typeof ($widget.data('max-width-' + type)) == 'undefined') {
            // Sonderfall: es kann sein, dass es eine überlange Antwort gibt, die bearbeitet wird
            // dann existiert hier noch kein Wert, wenn diese als erstes geöffnet wird
            // manuell question um linken Rand (29px) verringern)
            if ((type == 'answer') && ($domAnchor.next().width() > $widget.data('max-width-question'))) {
                $widget.data('max-width-' + type, (parseInt($widget.data('max-width-question')) - 29));
            } else {
                $widget.data('max-width-' + type, $domAnchor.next().width());
            }
        }
        if($widget.data('max-width-' + type) > 0)
        {
            $domAnchor.next().css('max-width', $widget.data('max-width-' + type));
        }
        return $domAnchor.next();
    };


    /*Character Counter */
    function countChar($editor, type) {
        // Setzen der messageContentHeight
        var len = $editor.getText().length;

        $charCount = $editor.editor;

        $charLength = $charCount.parent().next().children('.chars_' + type);

        $charLength.text(Lang.trans('js/comment.n_characters', { n: maxCharacter - len }));

        //$('.chars_'+type).text((maxCharacter - len) + ' Zeichen');

        // Unterschiedliche Darstellungen, wenn Zeichenanzahl abnimmt
        if ((2000 - len) > 50) {
            $('.chars_' + type).css('color', 'black');
        }
        if ((2000 - len) < 51) {
            $('.chars_' + type).css('color', 'orange');
        }
        if ((2000 - len) < 0) {
            $('.chars_' + type).css('color', 'red');
        }

    }

    /**
     * Liken eines Kommentars
     *   - verwalten der Anzeige
     *   - sichtbar und Popup
     */
    olComment.onClickCommentLike = function(element, uniqueId, commentId) {
        var likeId;
        var $element = $(element);
        if ($element.hasClass('comment_likeit')) {
            $element.removeClass('comment_likeit');
            likeId = 0;
        } else {
            $element.addClass('comment_likeit');
            likeId = 1;
        }
        var fd = new Object();
        fd.uniqueId = uniqueId;
        fd.commentId = commentId;
        fd.likeId = likeId;
        $.ajax({
            'type': "POST",
            'url': "/comment/like?" + Date.now(), // vermeide caching
            'data': fd,
            'dataType': 'json',
            'success': function(data) {
                if (data.ok) {
                    if (debug)
                        console.log("like comitted!");
                    var $div = $element.parent().children().last();
                    // div war schon eingeblendet
                    if ($div.hasClass('comment_single_like_info')) {
                        // Dein Like war der letzte Like
                        if (!data.cnt) {
                            if (typeof (data.likeInfo) != 'undefined') {
                                $div.prev().remove();
                                $div.replaceWith($(data.likeInfo));
                            }
                        } else {
                            if (typeof (data.popupInfo) != 'undefined') {
                                $($div).last().html(data.popupInfo);
                            }
                            if (typeof (data.cntInfo) != 'undefined') {
                                var $span = $div.prev();
                                var $icon = $('span', $span);
                                $span.animate({'font-size': '14pt'}, 200, function() {
                                    $span.animate({'font-size': '11pt'}, 200);
                                    $span.empty().append($icon);
                                    $span.html($span.html() + ' ' + data.cntInfo);
                                });
                            }
                        }
                    } else {
                        // $div is der letzte span mit der Info: sei der erster, dem ...
                        if (typeof (data.likeInfo) != 'undefined') {
                            $div.replaceWith($(data.likeInfo));
                        }
                    }
                } else {
                    if (debug)
                        console.log('Fehler beim liken');
                }
            },
            'failure': function(transport) {
                if (debug)
                    console.log(transport.responseText);
            }
        });
    };

    /**
     * Helper den sichtbaren Browserbereich zu bestimmen
     * wird für das Scrollen benötigt
     */
    function getBrowserHeight() {
        var myWidth = 0, myHeight = 0;
        if (typeof (window.innerWidth) == 'number') {
            //Non-IE
            myWidth = window.innerWidth;
            myHeight = window.innerHeight;
        } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
            //IE 6+ in 'standards compliant mode'
            myWidth = document.documentElement.clientWidth;
            myHeight = document.documentElement.clientHeight;
        } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
            //IE 4 compatible
            myWidth = document.body.clientWidth;
            myHeight = document.body.clientHeight;
        }
        return [myWidth, myHeight];
    }

    /**
     * Helper scrollt zu einem jQuery-Element und zeigt diese ggf auch an
     */
    function scrollCommentLineTo($element, showIt, callAfterScroll) {
        var bdim = getBrowserHeight();
        if ($element.length > 0) {
            var scrollTo = $element.offset().top - (bdim[1] / 2) + 100;
            if (debug)
                console.log($element.offset().top, bdim, scrollTo);
            $('html, body').animate({
                scrollTop: scrollTo
            }, 600, 'swing', function() {
                if (showIt) {
                    $element.slideDown(400, 'swing');
                }
                $element.focus();
                if (typeof (callAfterScroll) == 'function') {
                    callAfterScroll();
                }
            });
        }
    }

    /**
     * Scrollen zum Kommentar und highlight somehow
     */
    olComment.highlight = function(uniqueId, commentId) {
        var $comment = $('#' + commentId, $('#' + uniqueId));

        var cid = $comment.data('colorstateid');
        var highlightCommentary = function() {
            window.setTimeout(function() {
                $comment.addClass('ol-state-primary-color-' + cid, 1000, 'linear', function() {
                    $comment.removeClass('ol-state-primary-color-' + cid, 1000, 'linear');
                });
            }, 800);
        };
        scrollCommentLineTo($comment, false, highlightCommentary);
    };

    /**
     * Scrollen zur Kommentarzeile für neue Kommentare
     */
    olComment.onClickComment = function(uniqueId) {
        // check ob Kommentarzeile noch ausgeblendet ist und nächste angeklickt werden kann
        var $containerId = $('#' + uniqueId);
        var $footerDiv = $('> .comment_content_footer', $containerId);
        if ($footerDiv.length > 0) {
            scrollToQuestionLine = function() {
                olComment.onClickComment(uniqueId);
            };
            var $span = $('span.comment_link', $footerDiv);
            $span.trigger('onclick');
            scrollCommentLineTo($span, false);
        } else {
            scrollCommentLineTo($('.emojionearea-editor', $containerId), false);
        }
    };

    /**
     * Anzeigen der Liste der User, die einen Kommentar gelikt haben
     */
    olComment.onHoverCommentLikeShowUser = function(element, commentId) {
        var $element = $(element);
        // Anzeige 0 Managern gefällt das ausblenden
        if ($element.text().trim() == '') {
            return;
        }
        var $popup = $('#like_list_' + commentId);
        var position = $element.position();
        var margin = 10;
        var popupPos = {'position': 'absolute',
            'zindex:': 5000,
            'left': position.left + 'px',
            'top': (position.top - $popup.outerHeight() - margin) + 'px'
        };
        $popup.css(popupPos).fadeIn("slow");
    };

    /**
     * Ausblenden der Liste der User, die einen Kommentar gelikt haben
     */
    olComment.onUnhoverCommentLikeShowUser = function(commentId) {
        var $popup = $('#like_list_' + commentId);
        $popup.hide();
    };

    /**
     * Aufklappen des vollständigen Kommentars, "Weiterlesen"
     */
    olComment.onClickCommentShowMore = function(element) {
        var $anchor = $(element);
        var $span = $anchor.next();
        // hidden span Text holen und löschen anzeigen
        var addText = /*emojione.toImage(*/$span.html();//);
        // Punkte raus
        $anchor.prev().remove();
        // Weiterlesen raus
        $anchor.remove();
        // hidden span ersetzen - formatiert wurde Text vorher schon :-)
        $span.replaceWith(addText);
    };

    function estimateLines(ctx, text, max_width) {
        var lines = [];
        var i, j, result, manualLF;

        // Start Kalkulation
        while (text.length) {
            // Zeile von hinten ermitteln
            for (i = 0;  i < text.length;  i++) {
                if ((ctx.measureText(text.substr(0, i)).width > max_width) || (text[i] == "\n")) {
                    break;
                }
                // Comment-Markup
                var next = olGUI.markupHtmlConsume(text.substr(i)), n = next.length;
                if (n > 1) i += n-1;
            }
            
            manualLF = false;
            if (text[i] == "\n") {
                i++;
                manualLF = true;
            }
            
            result = text.substr(0, i);
            j = 0;
            //if (!manualLF) {
            //    // noch text über?
            //    if (i !== text.length) {
            //        // word grenze
            //        for (j = 0; result.indexOf(" ", j) !== - 1; j = result.indexOf(" ", j) + 1)
            //            ;
            //    }
            //} else {
            //    if (debug) console.log("manueller Zeilenumbruch gefunden");
            //}
            if (!manualLF && i < text.length) {
                // word grenze
                for (var k = 0;  k < result.length;  k += olGUI.markupHtmlConsume(result.substr(k)).length) {
                	var ch = result.charAt(i);
             		if (ch == ' ') j = k+1;
	            }
            }
	        	
            
            // Zeilen sammeln
            var part = result.substr(0, j || result.length);
            lines.push(part);
            if (debug) console.log(lines.length, part.length, text.length);
            text = text.substr(part.length, text.length);
        }
        return lines;
    }
    /**
     * formatiert den Text, dass berücksichtigt wird:
     * * manuelle Zeilenumbrüche durch User
     * * superlange Eingaben werden umgebrochen, (css: hyphens) wenn die Breite erreicht ist
     * param text enthält schon <br> - das sind manuelle Umbrüche
     *       In der Datenbank stehen nur manuelle Umbrüche aber mit \n
     */
    olComment.formatText = function($div, text, doReadMore) {
        var maxLines = $div.data('maxlines');
        if (typeof (maxLines) == 'undefined') {
            maxLines = 1000000;
        }
        var showMore = $div.data('showmore');
        text = text.replace(/<br>/g, "\n");
        // $div.parent().closest('div') => ist div.comment_single_question / comment_single_answer
        var $table = $div.parent().closest('table');
        // Breite bestimmen
        var maxWidth = parseInt($table.parent().width()) - parseInt($('tbody>tr:first-child>td:first-child', $table).width()) - parseInt($div.css('padding-left')) - parseInt($div.css('padding-right'));
        if (debug)
            console.log('   => Für Text: ' + text.substring(0, 40) + ' -> Breite:' + $div.parent().closest('div').width() + ' (' + $div.parent().closest('div').attr('class') + ') maxWidth: ' + maxWidth);
        if (maxWidth > 0)
            $div.css('max-width', maxWidth);
        // $div.width(maxWidth);
//	    text = text.replace(/\n/g, "<br>");
        text = text.replace(/<br>/g, "\n");
        // $div.css('line-height') liefert bereits px, ob wohl in css in pt angegeben
        var $canvas = $('<canvas />', {'width': $div.width(), 'height': 'auto'});
        var ctx = $canvas[0].getContext('2d');
        ctx.font = '13pt "Exo 2", sans-serif';
        var lines = estimateLines(ctx, text, parseInt($div.css('max-width')) + 6);
        var lineCount = lines.length;
        if (debug)
            console.log("  => lines:" + lineCount + ", max-width:" + $div.css('max-width'));
        if ((lineCount > maxLines) && doReadMore) {
            //var pretext = [];
            //while (pretext.length < maxLines - 1) {
            //    pretext.push(lines.shift());
            //}
            //var next = lines.shift();
            //var part = Math.min(next.length, Math.max(next.length / 3, 4));
            //pretext = pretext.join('') + next.substr(0, part);
            //var posttext = next.substr(part) + lines.join('');
            var pretext = lines.slice(0, maxLines).join("");
            var posttext = lines.slice(maxLines).join("");
            // Steuerung einfügen
            text = pretext + '<span>... </span>';
            text += '<span class="comment_link" onClick="olComment.onClickCommentShowMore(this)">' + showMore + '</span>';
            text += '<span style="display:none">' + posttext + '</span>';
        }
        text = text.replace(/\n/g, "<br>");
        //text = emojione.toImage(text);
        return text;
    };

    /**
     * Funktion für Vorherige anzeigen , Alle anzeigen
     * zeige Vorherige bis zu 50 (onlineliga.comment.max_comments_page)
     * zum Laden der Antworten ist commentId gesetzt, commentId=0 zum Laden der Hauptebene der Kommentare
     */
    olComment.onClickCommentShowPage = function(element, uniqueId, commentId, page, containerId, succAnimClbk, failClbk) {
        addLoadingAnimationToElement(element, {'size': 'small'});
        // Server Bescheid sagen
        var $widget = $(element).closest('.comment_widget');
        var $containerId = $('#' + containerId);
        var version = parseInt($widget.data('version'));
        var pageinfo = $containerId.data('pageinfo');
        var $firstComment = $('.comment_content_body', $widget).children().first();
        var fd = new Object();
        fd.uniqueId = uniqueId;
        fd.commentId = commentId;
        fd.containerId = containerId;
        fd.page = page; // (b)ackward, (f)orward, (a)ll
        fd.pageinfo = pageinfo;
        fd.leagueId = $widget.data('leagueid');
        fd.matchdayLine = $widget.data('matchdayline');
        fd.dw = $widget.data('dw');
        fd.color = $widget.data('color');
        fd._token = $widget.data('csrf');
        fd.fm = $firstComment.data('md'); // Spieltag des ersten angezeigten Kommentars
        if (typeof version !== typeof undefined && version === 2) {
            var count = $containerId.data('count');

            fd.count = count;
            fd.v2 = true;
        }
        $.ajax({
            'type': "POST",
            'url': "/comment/load?" + Date.now(), // vermeide caching
            'data': fd,
            'dataType': 'json',
            'success': function(data) {
                if (data.ok) {
                    if (data.pageinfo) {
                        $containerId.attr("data-pageinfo", data.pageinfo);
                        $containerId.data("pageinfo", data.pageinfo);
                    }
                    if (fd.v2 && fd.v2 === true && data.count) {
                        $containerId.attr("data-count", data.count);
                        $containerId.data("count", data.count);
                    }
                    if (debug)
                        console.log("comments loaded!");
                    // $containerId.children()[0] => comment_content_header
                    if (!data.header || (data.header == '')) {
                        $containerId.children('.comment_content_header').hide();
                        if (fd.v2 && fd.v2 === true)
                            $widget.find('.comment-widget-data').data('hasMore', 0);
                    } else {
                        $containerId.children('.comment_content_header').html(data.header);
                    }
                    var $body = $(data.body);
                    $body.hide();
                    // aus Kommentarsicht sieht man unten noch die comment_content_body der Antworten -> first()
                    if (fd.page[0] == 'f') {
                        $containerId.children('.comment_content_body').last().append($body);
                        // Nächste entfernen, da immer alle geladen werden
                        $('> .comment_content_footer', $containerId).remove();
                        if (fd.v2 && fd.v2 === true)
                            $widget.find('.comment-widget-data').data('hasNext', 0);
                        if (commentId == 0) {
                            // wenn Schreibrechte bestanden, dann 
                            if (mayWriteCache[containerId].length && mayWriteCache[containerId][0]) {
                                $('.comment_input_question_line', $containerId).show();
                                olComment.initEmojione($containerId, 'question', mayWriteCache[containerId][1]);
                            }
                        } else {
                            // parent besorgen und Schreibrechte prüfen
                            var parentContainerId = $containerId.closest('.comment_content').attr('id');
                            if (mayWriteCache[parentContainerId].length && mayWriteCache[parentContainerId][0]) {
                                $('.comment_input_answer_line', $containerId).show();
                                olComment.initEmojione($containerId, 'answer', 'Antworten');

                            }
                        }
                    } else {
                        $containerId.children('.comment_content_body').first().prepend($body);
                    }
                    $body.slideDown(400, 'swing', function() {
                        window.setTimeout(function() {
                            // leere Antworten wieder ausblenden
                            $('div.comment_indent_answers.ac0', $body.parent()).hide();
                            if (scrollToQuestionLine) {
                                // es wurde aus einer Sprungsituation auf Kommentieren geklickt
                                // dazu müssen erst mal alle Kommentare geladen werden
                                // dann zum Eingabe scrollen
                                scrollToQuestionLine();
                                scrollToQuestionLine = null;
                            } else if (scrollToAnswerLine) {
                                // es wurde aus einer Sprungsituation auf Antworten geklickt
                                // dazu müssen erst mal alle Antworten geladen werden
                                // dann zum Eingabe scrollen
                                scrollToAnswerLine();
                                scrollToAnswerLine = null;
                            } else {
                                // zum neu eingefügten Bereich scrollen
                                // muss nicht, da man eh oben bei Vorherige / Alle aneziegn ist und klickt
//						        scrollCommentLineTo($body);
                            }
                            if (measureSize) {
                                if (typeof succAnimClbk !== typeof undefined && jQuery.isFunction(succAnimClbk))
                                    succAnimClbk();
                                measureSize = false;
                            }
                        }, 500);
                    });
                    // formatieren und einblenden des Textes
                    var $commentTexts = $('.comment_text', $body);
                    var count = $commentTexts.length;
                    $commentTexts.each(function(i, div) {
                        if (!(--count))
                            measureSize = true;

                        var $div = $(div);
                        $div.html(olComment.formatText($div, $div.html(), true));
                        $div.show();
                        //slideDown(200, 'swing');
                    });
                    olComment.adapt2Screen($body.parent());
                    if (data.script) {
                        try {
                            eval(data.script);
                        } catch (e) {
                            if (debug)
                                console.log("Error eval Script:" + data.script);
                        }
                    }
                    if (fd.v2 && fd.v2 === true)
                        lazyLoader.loadImages();
                    removeLoadingAnimationFromElement(element);
                } else {
                    if (debug)
                        console.log("Fehler beim Anzeigen der nächsten Seite von Kommentaren");
                }
            },
            'failure': function(transport) {
                removeLoadingAnimationFromElement(element);
                if (debug)
                    console.log("Error loading comments!" + transport.statusText);
                if (debug)
                    console.log(transport.responseText);
                if (typeof failClbk !== typeof undefined && jQuery.isFunction(failClbk))
                    failClbk();
            }
        });
    };

    /**
     * wenn ausserhalb des Menus geklickt wird, muss dieses geschlossen werden
     */
    olComment.onClickCommentMenuOut = function(event) {
        var $overlay = $(event.currentTarget);
        var $menu = $('.comment_menu_content:visible', $overlay.parent());
        $menu.fadeOut("slow");
        $overlay.unbind('click', olComment.onClickCommentMenuOut);
        $overlay.hide();
    };

    /**
     * Menuhandler
     * * Klick auf 3 Punkte zum Öffnen des Menus menuCommandID = 'menu'
     * * Klick auf Menupunkte
     */
    olComment.onClickCommentMenu = function(element, menuId, menuCommandId, answerOfComment, commentId) {
        var $element = $(element);
        var $widget = $element.closest('.comment_widget');
        var $overlay = $('#comment_menu_modal', $widget);
        // open menu
        if (menuCommandId == 'menu') {
            var $menus = $('.comment_menu_content', $widget);
            var $menu = $($menus[menuId]);
            // Menu nicht öffnen, wenn der aktuelle Kommentar gerade schon bearbeitet wrd
            if ($element.data('isediting') == 1) {
                return;
            }
            // 2. Klick auf Punke, schliesst das Menu
            if (($menu.data('commentId') == commentId) && $menu.is(':visible')) {
                $menu.fadeOut("slow");
                return;
            }
            $menu.data('answerOfComment', answerOfComment);
            $menu.data('commentId', commentId);
            $menu.data('dotElement', $element);
            // Textänderung delete / undelete
            if (menuId == 0) {
                var $span = $menu.children().last();
                //data-undelete wird nur beim isCommentSystemModerator hinzugefügt
                if ($span.data('undelete')) {
                    // set delete / undelete
                    var $comment_div = $element.closest('table').parent();
                    if ($comment_div.hasClass('comment_deleted')) {
                        $span.text($span.data('undelete'));
                    } else {
                        $span.text($span.data('delete'));
                    }
                }
            }
            var position = $element.position();
            position.left = position.left + $element.width() - $menu.width();
            var ss = olGUI.getBootstrapDeviceSize();
            if (menuId == 0) {
                //edit, delete
                position.left -= 15;
                if (ss == 'ol-xs') {
                    position.left -= 10;
                }
            } else {
                // report
                position.left -= 24;
                if (ss == 'ol-xs') {
                    position.left -= 2;
                }
            }
            position.top -= 6;
            var margin = 10;
            var popupPos = {'position': 'absolute',
                'zindex:': 5000,
                'left': position.left + 'px',
                'top': (position.top + $element.outerHeight() + margin) + 'px'
            };
            $menu.css(popupPos).fadeIn("slow");
            $overlay.show();
            $overlay.bind('click', olComment.onClickCommentMenuOut);
            // do menu command
        } else {
            var $menu = $element.parent();
            var answerOfComment = $menu.data('answerOfComment');
            var commentId = $menu.data('commentId');
            var $dots = $menu.data('dotElement');
            var $table = $dots.closest('table');
            var type = ($table.parent().hasClass('comment_single_question') ? 'question' : 'answer');
            $menu.fadeOut();
            $overlay.hide();
            var $div = $table.closest('.comment_content');
            var fd = new Object();
            fd.commentId = commentId;
            fd.uniqueId = $div.attr('id');
            fd.type = type;
            switch (menuCommandId) {
                case 'edit':
                    var $div = $('.comment_text', $table);
                    var $divHidden = $('.comment_unformatted', $table);
                    if ($div.next().hasClass('emojionearea')) {
                        // already opened
                        return;
                    }
                    $dots.data('isediting', 1);
                    // Kopiere für Abbrechen der Bearbeitung
                    var $span = $('<span />', {'class': 'comment_formatted','style': 'display:none', 'width': $div.width(), 'max-width': $div.css('max-width')});
                    $span.html($div.html());
                    $span.insertBefore($div);
                    // Weiterlesen klicken, damit der ganze Text sichtbar wird
                    var $readMore = $('.comment_link', $div);
                    // ganzen Text öffnen
                    if ($readMore.length > 0) {
                        $readMore.trigger('click');
                    }
                    $div.hide();
                    var $input = $('<textarea />', {'class': 'comment_input_' + type});
                    $input.html($divHidden.html().trim().replace(/<br>/g, "\n"));
                    $div.replaceWith($input);
                    var $emojiDiv = olComment.initEmojione($table, type, '');
                    var $editor = $('.emojionearea-editor', $emojiDiv);

                    // Zaehler verbleibende Zeichen
                    var $divChars = $('<div>', {class: 'ol-comment-infos', style: 'font-size: 10pt; text-align: right; margin-right: 5px;'});
                    var $spanChars = $('<span>', { class: 'chars_' + type, style: 'font-size: 10pt; font-weight: 700', html: Lang.trans('js/comment.n_characters', { n: maxCharacter }) });
                    var formatting = document.querySelector('#hiddenFormatting').innerHTML;
                    $divChars.append(formatting);
                    $divChars.append($spanChars);
                    var $editorParent = $editor.parent().parent();
                    $editorParent.append($divChars);
                    var len = $editor.text().length;
                    $charLength = $editor.parent().next().children('.chars_' + type);

                    $charLength.text(Lang.trans('js/comment.n_characters', { n: maxCharacter - len }));
                    $editor.focus();

                    // Breite setzen
//					$input.next().attr('style', 'float:left; width: ' + ($input.parent().width() - 52) + 'px !important');
                    // $input.closest('td').css('padding-top', '4px');
                    // aus div.comment_single_question|answer die Farbe für SendButton holen
                    var colorStateId = $table.parent().data('colorstateid');
                    // senden Button einblenden
                    var $span = $('<span />', {'class': 'comment_send_button ol-state-primary-color-' + colorStateId, 'float': 'right'});
                    $span.bind('click', function() {
                        olComment.onClickCommentSend(this, fd.uniqueId, answerOfComment, fd.commentId);
                    });
                    var $icon = $('<span />', {'class': 'icon-icon_send'});
                    $span.append($icon);
                    // an nächsten td anhängen
                    var $td_buttons = $input.parent().next();
                    $td_buttons.append($span);
                    // Cancel Button
                    var $span = $('<span />', {'class': 'comment_cancel_button'});
                    $span.bind('click', function() {
                        olComment.onClickCommentEditClear(this);
                    });
                    var $icon = $('<span />', {'class': 'icon-close_button_black'});
                    $span.append($icon);
                    $td_buttons.append($span);
                    break;
                case 'delete':
                    olComment.sendMenuCommand($dots, 'DELETE', 'delete', fd, function(data) {
                        if (data.ok) {
                            $div = $table.parent();
                            if (data.commentCountInfo) {
                                // Update Anzahl Kommentare
                                $('.comment_count', $div.closest('.comment_widget')).html(data.commentCountInfo);
                            }
                            if (data.hidecomment) {
                                $divMatchdayLine = null;
                                // bei 1. Ebene auch Antworten löschen
                                if ($div.hasClass('comment_single_question')) {
                                    $div.next().remove();
                                    if ($div.prev().hasClass('comment_matchday_line')) {
                                        $divMatchdayLine = $div.prev();
                                    }
                                    // $div.next() ist nun nächste Frage oder null, wenn es die letzte war
                                    if ($div.next().hasClass('comment_single_question')) {
                                        $divMatchdayLine = null;
                                    }
                                }
                                // nun Kommentar ausblenden
                                $div.slideUp(400, 'swing', function() {
                                    // und entfernen
                                    $div.remove();
                                });
                                if ($divMatchdayLine) {
                                    // nun Kommentar ausblenden
                                    $divMatchdayLine.slideUp(400, 'swing', function() {
                                        // und entfernen
                                        $divMatchdayLine.remove();
                                    });
                                }
                            } else {
                                // bei undelete einfach nur Klasse entfernen
                                if (data.deleted) {
                                    $div.addClass('comment_deleted');
                                } else {
                                    $div.removeClass('comment_deleted');
                                }
                            }
                        } else {
                            console.log('error on delete');
                        }
                    });
                    break;
                case 'report':
                    olComment.sendMenuCommand($dots, 'POST', 'report', fd, function(data) {
                        if (data.ok) {
                            if (debug)
                                console.log('comment reported');
                        } else {
                            console.log('error on report');
                        }
                    });
                    break;
            }
        }
    };

    /**
     * worker function for menu commands: delete, report
     * Serveraufruf, Fehlersteuerung, Warteanzeige (ein/aus)
     */
    olComment.sendMenuCommand = function(element, type, command, data, callback) {
        addLoadingAnimationToElement(element, {'size': 'small'});
        // report / delete
        $.ajax({
            'type': type,
            'url': "/comment/" + command + "?" + Date.now(), // vermeide caching
            'data': data,
            'dataType': 'json',
            'success': function(data) {
                if (data.ok) {
                    callback(data);
                    removeLoadingAnimationFromElement(element);
                    if (debug)
                        console.log("menu command " + command + " success!");
                } else {
                    if (debug)
                        console.log("Fehler beim command");
                }
            },
            'failure': function(transport) {
                removeLoadingAnimationFromElement(element);
                if (debug)
                    console.log("Error loading comments!" + transport.statusText);
                if (debug)
                    console.log(transport.responseText);
            }
        });
    };

    olComment.onClickCommentAnswer = function(element, commentId, answerOf, placeholder) {
        var $footerDiv;
        var $element = $(element);
        // erst prüfen, ob schon alle Antworten da sind (könnte Sprung sein)
        if (answerOf == 0) {
            // Antwort auf Kommentar
            $footerDiv = $('> .comment_content_footer', $element.closest('.comment_single_question').next());
        } else {
            // Antwort auf Antwort
            $footerDiv = $element.closest('.comment_answer_body').next();
            if (!$footerDiv.hasClass('comment_content_footer')) {
                $footerDiv = [];
            }
        }
        if ($footerDiv.length == 1) {
            scrollToAnswerLine = function() {
                olComment.onClickCommentAnswer(element, commentId, answerOf, placeholder);
            };
            var $span = $('span.comment_link', $footerDiv);
            $span.trigger('onclick');
            scrollCommentLineTo($span, false);
        } else {
            olComment.onClickCommentAnswerWork(element, commentId, answerOf, placeholder);
        }
    };
    /**
     * Klick auf "Antworten"
     * * einblenden der Eingabezeile
     */
    olComment.onClickCommentAnswerWork = function(element, commentId, answerOf, placeholder) {
        var $div, pretext;
        var $element = $(element);
        if (answerOf == 0) {
            // Antwort auf Kommentar
            pretext = '';
            $div = $element.closest('.comment_single_question').next().children().last();
            // falls der Antwortbereich noch augeblendet ist => 1. Antwort
            $div.closest('.comment_indent_answers').show();
        } else {
            // Antwort auf Antwort
            pretext = '@' + $('.ol-user-name', $element.closest('.comment_single_answer')).text().trim() + ": ";
            $div = $element.closest('.comment_single_answer').parent().parent().children().last();
        }
        // nun Antwortzeile einblenden
        $div.slideDown(400, 'swing');
        // als emoji initialisieren
        olComment.initEmojione($div, 'answer', placeholder, pretext);
        // hinscrollen
        scrollCommentLineTo($('.emojionearea-editor', $div), true);
    };

    /**
     * Aufräumen der GUI nach Bearbeitung nach Senden und auch Abbrechen
     */
    olComment.onClickCommentEditClear = function(element, newText) {
        var $element = $(element);
        // $td ist das td mit dem Button
        var $td = $element.closest('td');
        // isediting loeschen
        var $tr = $td.parent().prev();
        var $dots = $('div', $('td', $tr).last());
        $dots.data('isediting', 0);
        // Abstand wieder herstellen
        // $td.css('padding-top', '0px');
        // is Cancel -> text aus backup holen
        var isCancel = false;
        var $span = $td.prev().children().closest('.comment_formatted');
        var attr = {'class': 'comment_text', 'width': $span.width(), 'max-width': $span.css('max-width')};
        if (typeof (newText) == 'undefined') {
            newText = $span.html();
            isCancel = true;
        }
        $td.empty();
        $td.prev().empty();
        var $div = $('<div />', attr);
        $div.hide();
        $td.prev().append($div);
        // wenn Bearbeitet, dann dem Darstellungsbereich den neuen Text mitgeben
        // wenn Cancel, dann ist es der alte aus dem Backup-Span
        if (!isCancel) {
            newText = olComment.formatText($div, newText.replace(/\n/g, '<br>').trim(), false);
        }
        $div.html(newText);
        $div.slideDown(400, 'swing');
    };

    /**
     * sende Kommentar
     */
    olComment.onClickCommentSend = function(element, containerId, answerOf, commentId) {
        var $element = $(element);
        //console.log($element);
        $element.addClass('disabled');
        // Check maxCharacter        
        // Inhalt des Eingabefeldes (textarea)
        $textarea = $('textarea', $element.parent().prev()); // Selector bestimmen
        var content = $textarea[0].emojioneArea.getText().replace(/^[\s\r\n]+|[\s\r\n]+$/gm, ''); // Inhalt des Eingabefeldes
        if (content.length > maxCharacter) {
            olMsgBox.msg(Lang.trans('js/comment.sysMessage'), Lang.trans('js/comment.maxCharsReached'));
        } else {
            addLoadingAnimationToElement(element, {'size': 'small'});
            if (typeof (commentId) == 'undefined') {
                commentId = 0;
            }
            var $containerId = $('#' + containerId);
            // element is send button
            $element = $(element);
            // für Bearbeiten (dynamisch DOM) okay
            var $el = $element.prev().prev();
            // falls nicht gefunden, dann Darstellung aus blade mit Tabelle
            if ($el.length == 0) {
                // Antworten $element.parent().prev().first()
                // Edit $element.parent().prev().first().next() // Backup müsste übersprungen werden
                $el = $('textarea', $element.parent().prev());
            }
            var pageinfo = $containerId.data('pageinfo');

            var fd = new Object();
            fd.uniqueId = containerId;
            fd.commentId = commentId;
            fd.answerOf = answerOf;
            fd.pageinfo = pageinfo;
            var $widget = $element.closest('.comment_widget');
            fd.leagueId = $widget.data('leagueid');
            fd.matchdayLine = $widget.data('matchdayline');
            fd.dw = $widget.data('dw');
            var $lastComment = $('.comment_content_body', $widget).children().last().prev();
            fd.lm = $lastComment.data('md'); // Spieltag des letzten angezeigten Kommentars
            fd.color = $widget.data('color');
            fd.ss = $widget.data('ss');
            var textWIcon = $el[0].emojioneArea.getText().replace(/^[\s\r\n]+|[\s\r\n]+$/gm, '');
            // leere Texte nicht abschicken
            if ((textWIcon.trim().length == 0) || (textWIcon.trim().match(/^@\w+ :$/))) {
                var $editor = $('.emojionearea-editor', $el.next());
                removeLoadingAnimationFromElement(element);
                olGUI.setCurrentCursorPosition($editor, textWIcon.trim().length);
                $editor.focus();
                return;
            }
            // fd.comment = olGUI.markupEscape(emojione.toShort(textWIcon).replace(/<br>/g, "\n"));
            fd.comment = emojione.toShort(textWIcon).replace(/<br>/g, "\n");
            var waitForServer = true;
            // bei Edit gleich GUI ändern oder erst mit Rückgabe?
            if (commentId > 0) {
                //waitForServer = false;
            }
            var type = ((answerOf > 0) ? 'answer' : 'question');
            $.ajax({
                'type': "POST",
                'url': "/comment/send?" + Date.now(), // vermeide caching
                'data': fd,
                'dataType': 'json',
                'success': function(data) {
                    if (data.ok) {
                        if (data.pageinfo) {
                            $containerId.attr("data-pageinfo", data.pageinfo);
                            $containerId.data("pageinfo", data.pageinfo);
                        }
                        if (!waitForServer) {
                            return;
                        }
                        if (data.commentCountInfo) {
                            // Update Anzahl Kommentare
                            $('.comment_count', $element.closest('.comment_widget')).html(data.commentCountInfo);
                            $('.comment_empty_list', $element.closest('.comment_content')).remove();
                        }
                        if (data.comment) {
                            var $comment = $(data.comment);
                            if (commentId > 0) {
                                // Bearbeitung eines Kommentars ersetzen, inaktiv, da bei Edit derzeit waitForServer=false
                                $element.closest('.comment_single').parent().replaceWith($comment);
                            } else {
                                $comment.hide();
                                // neuer Kommentar
                                var $line = $element.closest('.comment_input_' + type + '_line');
                                // einfügen in comment_body
                                $line.prev().append($comment);
                                if (type == 'answer') {
                                    // klassenkennzeichen leerer Antworten löschen
                                    $line.parent().removeClass('ac0');
                                }
                                // td leeren
                                var $td = $element.parent().prev();
                                $td.empty();
                                // und neue input einsetzen
                                var $input = $('<textarea />', {'class': 'comment_input_' + type, 'id': 'comment-content'});
                                // max Zeichenlaenge anzeigen                                
                                var $div = $('<div>', {class: 'ol-comment-infos', style: 'font-size: 10pt; text-align: right; margin-right: 5px;'});
                                var $span = $('<span>', { class: 'chars_' + type, style: 'font-size: 10pt; font-weight: 700', html: Lang.trans('js/comment.n_characters', { n: maxCharacter }) });
                                var formatting = document.querySelector('#hiddenFormatting').innerHTML;
                                $div.append(formatting);
                                $div.append($span);

                                $td.append($input);
                                $td.append($div);
                                // die Anzahl Antworten kommt zurück, also wurde Antwort gesendet
                                // Antwortzeile wird dann ausgeblendet, Hauptkommentar zeile bleibt und wird initialisiert
                                if ((typeof (data.answerCount) != 'undefined') && (data.answerCount == 0)) {
                                    $line.hide();
                                } else {
                                    olComment.initEmojione($('#' + containerId), type, data.placeholder);
                                }
                                // einblenden des Kommentars
                                $comment.slideDown(200, 'swing', function() {
                                    if (type == 'question') {
                                        // ausblenden des letzten Elements des Kommentars
                                        $($comment[$comment.length - 1]).hide();
                                    }
                                    // formatieren und einblenden des Textes
                                    var $div = $('.comment_text', $comment);
                                    $div.html(olComment.formatText($div, $div.html(), false));
                                    $div.slideDown(200, 'swing');
                                    // Anpassungen ans Layout
                                    olComment.adapt2Screen($('#' + containerId).closest('.comment_widget'));
                                });
                            }
                        } else {
                            if (debug)
                                console.log("no comment data returned");
                        }
                        if (debug)
                            console.log("comment saved!");
                    } else {
                        if (data.errMsg) {
                            olMsgBox.msg(Lang.trans('js/comment.error'), data.errMsg);
                        }
                        console.log("comment save error!");
                    }

                },
                'error': function(transport) {
                    console.log("Error loading comments!" + transport.statusText);
                    console.log(transport.responseText);
                },
                'complete': function() {
                    removeLoadingAnimationFromElement(element);
                    $element.removeClass('disabled');
                }
            });
            if (!waitForServer) {
                olComment.onClickCommentEditClear(element, textWIcon);
            }
        }
    };

    olComment.deleteComment = function(commentId, uniqueId, callback){
        var params = {};
        params.commentId = commentId; 
        params.uniqueId = uniqueId;
        olComment.sendMenuCommand(null, 'DELETE', 'delete', params, callback);
    };

    olComment.setTeamAccess = function(val) {
        $.post('/comment/setTeamAccess', {'friendsOnly': val}, function() {

        });
    };

}(window.olComment = window.olComment || {}, jQuery)); 