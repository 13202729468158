"use strict";
(function(olOfficeSettings, $, undefined) {

    olOfficeSettings.page = '';

    olOfficeSettings.reload = function() {
        loadPage('office/settings');
    };

    olOfficeSettings.initSite = function() {
        $('#inputFirstName').keypress(function(){
            $('.account-firstname-wrapper .icon-close_button_darkgrey_tiny').fadeIn();
        });
        $('#inputLastName').keypress(function(){
            $('.account-lastname-wrapper .icon-close_button_darkgrey_tiny').fadeIn();
        });
        $('#inputFavClub').keypress(function(){
            $('.account-favteam-wrapper .icon-close_button_darkgrey_tiny').fadeIn();
        });
        $('#accountSubstitute').keypress(function(){
            $('.account-substitute-wrapper .icon-close_button_darkgrey_tiny').fadeIn();
        });

        $("#2fa-success-icon").hide();
        $("#notooltips-success-icon").hide();

    };
    // Input value löschen (inputName = Id des Inputs)
    olOfficeSettings.deleteInputValue = function(inputName) {
        var elem = document.getElementById(inputName);
        elem.value = "";
        olOfficeSettings.OfficeSettingsTextSaveOnKeyUp(elem);
    }
    
    olOfficeSettings.onCheckboxClickHandler = function(element) {
        var checkboxValue = element.checked;
        checkboxValueString = checkboxValue.toString();

        if (element.id == "toggleDebugView") {
            if (checkboxValue) {
                $(".ol-office .ol-office-toggleDebugView").addClass("bold");
                $("#toggleDebugView-success-icon").attr("style","display:inline-block;");
            } else {
                $(".ol-office .ol-office-toggleDebugView").removeClass("bold");
                $("#2toggleDebugView-success-icon").hide();
            }
        }

        if (element.id == "2FA_ENABLED") {
            if (checkboxValue) {
                $(".ol-office .ol-office-2fa").addClass("bold");
                $("#2fa-success-icon").attr("style","display:inline-block;");
            } else {
                $(".ol-office .ol-office-2fa").removeClass("bold");
                $("#2fa-success-icon").hide();
            }
        }

        if (element.id == "checkbox-disableTooltips") {
            if (checkboxValue) {
                $(".ol-office .ol-office-ntt").addClass("bold");
                $("#ntt-success-icon").attr("style","display:inline-block;");
            } else {
                $(".ol-office .ol-office-ntt").removeClass("bold");
                $("#ntt-success-icon").hide();
            }
        }

        if (element.id == "DARK_MODE") {
            if (checkboxValue) {
                localStorage.setItem('theme', 'dark-theme');
            } else {
                localStorage.setItem('theme', 'light-theme');
            }
        }

        $.post('/office/settings/savecheckbox' , { name : element.id , value : checkboxValue ? 1 : 0} , null , 'json' );
    };

    olOfficeSettings.updateModeButtons = function() {
        var $bg = $('.ol-mode-selection-btn-bg');
        var $btns = $('.ol-mode-selection-button');
        var index = $btns.index($('.ol-mode-selection-button.selected'));
        var left = index * 80+'px';
        $bg.css('left', left)
    }

    olOfficeSettings.selectColorTheme = function(val) {
        var htmlTag = document.documentElement;
        var setLight = function() {
            localStorage.setItem('theme', 'light-theme');
            $(htmlTag).removeClass('inherit-theme dark-theme light-theme');
            $(htmlTag).addClass('light-theme');
            $.post('/office/settings/savecheckbox' , { name : 'DARK_MODE' , value : 0} , null , 'json' );
            $('.ol-mode-selection-button').removeClass('selected');
            $('button[data-value="light"]').addClass('selected');
            olOfficeSettings.updateModeButtons();
        };
        var setDark = function() {
            localStorage.setItem('theme', 'dark-theme')
            $(htmlTag).removeClass('inherit-theme dark-theme light-theme');
            $(htmlTag).addClass('dark-theme');
            $.post('/office/settings/savecheckbox' , { name : 'DARK_MODE' , value : 1} , null , 'json' );
            $('.ol-mode-selection-button').removeClass('selected');
            $('button[data-value="dark"]').addClass('selected');
            olOfficeSettings.updateModeButtons();
        };

        if(val == 0) {
            setLight();
            $.post('/office/settings/savecheckbox' , { name : 'DARK_MODE_SYSTEM' , value : 0} , null , 'json' );
        } else if(val == 1) {
            setDark();
            $.post('/office/settings/savecheckbox' , { name : 'DARK_MODE_SYSTEM' , value : 0} , null , 'json' );
        } else {
            //System
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                // dark-theme:
                setDark();
            } else {
                // light-theme:
                setLight();
            }
            $('.ol-mode-selection-button').removeClass('selected');
            $('button[data-value="system"]').addClass('selected');
            olOfficeSettings.updateModeButtons();
            $.post('/office/settings/savecheckbox' , { name : 'DARK_MODE_SYSTEM' , value : 1} , null , 'json' );
        }
    }

    olOfficeSettings.OfficeSettingsTextSaveOnKeyUp = function(element) {
        var jsondata = { id : element.id , value: element.value };
        var jsonstring = JSON.stringify(jsondata);

        //console.log('id:' + element.id);

        if (element.id == 'inputFirstName' || element.id == 'inputLastName') {
            if (element.id == 'inputFirstName') {
                var cb = $('#firstnameSaveCheck').removeClass('hidden');
            }
            if (element.id == 'inputLastName') {
                var cb = $('#lastnameSaveCheck').removeClass('hidden');
            }
            $.post('/office/settings/saveTextInput', {jsonstring: jsonstring}, cb, 'json');
        } else {
            $.post('/office/settings/saveTextInput', {jsonstring: jsonstring}, null , 'json');
        }
    };

    olOfficeSettings.zombieToggle = function(element) {
        var zombieValue = element.checked;
        var confirmBtn = document.getElementById('inactiveConfirm');
        var data = {
            zombieEnabled: zombieValue? 0 : 1,
            _token: $('meta[name="csrf-token"]').attr('content')
        };
        $.post('/office/settings/saveZombie', data, null, 'json');

        if(confirmBtn) {
            $(confirmBtn).toggleClass('hidden');
        }

        zombieValue ? $('#zombieWarning').hide() : $('#zombieWarning').show();  


        // if (!zombieValue) {
        //     $('.account-settings-headline.deactivation').css('color','white');
        //     $('.account-settings-headline.deactivation').css('background-color','#A80F0F');
        //     $('.account-settings-info-text.deactivation').css('color','#A80F0F');
        //     $('.zombie-team-info-wrapper .account-settings-notifications-font').css('color','#000000');
        // } else {
        //     $('.account-settings-headline.deactivation').css('color','black');
        //     $('.account-settings-headline.deactivation').css('background-color','#e2e2e2');
        //     $('.account-settings-info-text.deactivation').css('color','#000000');
        //     $('.zombie-team-info-wrapper .account-settings-notifications-font').css('color','#A80F0F');
        // }
    };

    olOfficeSettings.deactivateDuringSeason = function(value) {
        if (value == 1) {
            $('.account-deletion').removeClass('visible');
            $('.account-deletion-in-progress').addClass('visible');
            $('#userSelfDeletion').show();
            olOfficeSettings.deactivationToggle(1);
        } else if (value == 0) {
            $('.account-deletion').addClass('visible');
            $('.account-deletion-in-progress').removeClass('visible');
            $('#userSelfDeletion').hide();
            olOfficeSettings.deactivationToggle(0);
        }
    };

    olOfficeSettings.deactivateDuringSummer = function(value) {
        olMsgBox.question(Lang.trans('js/office.attention'), Lang.trans('js/office.questionSureToDelete1') + '<br><br><b>' + Lang.trans('js/office.questionSureToDelete2') + '</b>', function() {
            $.ajax({
                url: 'office/settings/saveDeactivation',
                data:  { value : 1 },
                type: 'POST'
            }).always(function() {
                window.location = "/";
            });
        }, false, Lang.trans('js/office.deactivate'), Lang.trans('js/office.cancel'));
    };

    olOfficeSettings.deactivationToggle = function(value) {
        //var deactivationValue = false;
        //if (value == 1) { var deactivationValue = true; }
        var deactivationValue = (value == 1);
        $.post('/office/settings/saveDeactivation', {value: deactivationValue? 1 : 0}, null, 'json');
    };

    olOfficeSettings.checkMyPasswords = function() {
        var pw = $('#accountPasswordNew');
        $("#OfficeChangePWButton").attr("disabled", true);

        var pw1 = $('#accountPasswordNew').val();
        var pw2 = $('#accountPasswordRepeat').val();
        var pwOld = $('#accountPasswordOld').val();

        if (pw1 != undefined && pw2 != undefined && pwOld != undefined) {
            if (pw1.length < $("#pwLengthStateInfo").attr("pwMin") || pw1.length > $("#pwLengthStateInfo").attr("pwMax")) {
                $("#pwLengthStateInfo").show();
                updateState($("#accountPasswordNew"), true);
            } else {
                $("#pwLengthStateInfo").hide();
                updateState($("#accountPasswordNew"), false);

                if (pw2.length < $("#pwLengthStateInfo").attr("pwMin") || pw2.length > $("#pwLengthStateInfo").attr("pwMax")) {
                    updateState($("#accountPasswordRepeat"), false);
                    $("#pwLengthStateInfo").show();
                } else {
                    $("#pwLengthStateInfo").hide();
                    updateState($("#accountPasswordRepeat"), true);

                    if (pw1 == pw2) {
                        $("#pwEqualInfo").hide();
                        $("#OfficeChangePWButton").removeAttr("style");
                        $("#OfficeChangePWButton").attr("disabled", false);
                    } else {
                        updateState($("#accountPasswordNew"), false);
                        updateState($("#accountPasswordRepeat"), false);
                        $("#pwEqualInfo").show();
                    }
                }
            }
        }
    }


    olOfficeSettings.onChangeCheckPasswords = function() {
        var pw = $('#accountPasswordNew');
        $("#pw2TypingHint").hide();
        $("#pwLengthStateInfo").hide();
        $("#pwEqualInfo").hide();

        var pw1 = $('#accountPasswordNew').val();
        var pw2 = $('#accountPasswordRepeat').val();
        var pwOld = $('#accountPasswordOld').val();

        pwInputChanged = true;
        if (pwTimer === null) {
            pwTimer = window.setInterval(function() {
                if (pwInputChanged) {
                    pwInputChanged = false;
                    var pw1 = $('#accountPasswordNew').val();
                    var pw2 = $('#accountPasswordRepeat').val();
                    if (pw1 != undefined || pw2 != undefined) {
                        if (pw1.length < $("#pwLengthStateInfo").attr("pwMin") || pw1.length > $("#pwLengthStateInfo").attr("pwMax")) {
                            $("#pwLengthStateInfo").show();
                            updateState($("#accountPasswordNew"), true);
                            $("#OfficeChangePWButton").attr("disabled", true);
                            //console.log('pw1 nicht gut');
                        } else {
                            $("#pwLengthStateInfo").hide();
                            updateState($("#accountPasswordNew"), false);
                            if (pw2 == undefined) {
                                $("#pw2TypingHint").show();
                            } else {
                                if (pw2.length < $("#pwLengthStateInfo").attr("pwMin") || pw2.length > $("#pwLengthStateInfo").attr("pwMax")) {
                                    updateState($("#accountPasswordRepeat"), false);
                                    $("#pwLengthStateInfo").show();
                                    $("#OfficeChangePWButton").attr("disabled", true);
                                    //console.log('pw2 nicht gut' + pw1 + '/' + pw2 + '!');
                                } else {
                                    $("#pwLengthStateInfo").hide();
                                    updateState($("#accountPasswordRepeat"), true);
                                    if (pw1 == pw2) {
                                        $("#pwEqualInfo").hide();
                                        $("#OfficeChangePWButton").removeAttr("style");
                                        $("#OfficeChangePWButton").attr("disabled", false);
                                    } else {
                                        updateState($("#accountPasswordNew"), false);
                                        updateState($("#accountPasswordRepeat"), false);

                                        $("#OfficeChangePWButton").attr("disabled", true);
                                        $("#pwEqualInfo").show();
                                    }
                                }
                            }
                        }
                    }
                }
            }, 1000);
        }
    };

    olOfficeSettings.onBlurInputEmail = function(element) {
        olOfficeSettings.onChangeInputEmail(element);
    };

    olOfficeSettings.onChangeInputEmail = function(element) {
        emailInputChanged = true;
        email = $(element).val();
        if (emailTimer === null) {
            emailTimer = window.setInterval(function() {
                emailInputChanged = true;
                if (emailInputChanged) {
                    emailInputChanged = false;
                    email = $("#accountEmailNew").val();
                    $("#accountChangeEmailButton").attr('disabled' , true);
                    if (validateEmail(email)) {
                        verifyEmail(email);
                    } else {
                        updateState($("#accountEmailNew"), "hide");
                        $("#accountEmailNew").addClass("inputStateInvalid");
                        $("#accountEmailNew").removeClass("inputStateValid");
                        updateState($("#accountEmailNew"), false);
                        $("#OfficeChangeEmailButton").attr('disabled' , true);
                    }
                }
            }, 1000);
        }
    };


    olOfficeSettings.deleteFavoriteClub = function() {
        var jsondata = {favClubId: -1};
        var jsonstring = JSON.stringify(jsondata);

        $("#inputFavClub").removeAttr("value");
        $("#inputFavClub").attr("placeholder",Lang.trans('js/office.myFavoriteClub'));

        $.post('/office/settings/saveFavClub' , {jsonstring: jsonstring}, null , 'json');

        bPostRegister['inputStateFavClub'] = true;
        updateState($("#inputStateFavClub"), true);
    };

    olOfficeSettings.inputFavoriteClubOnKeyUp = function(value) {
        $("hiddenInputFavClubId").val(-1);
        if (value.length == 0) {
            bPostRegister['inputStateFavClub'] = true;
            updateState($("#inputStateFavClub"), true);
        } else {
            bPostRegister['inputStateFavClub'] = false;
            updateState($("#inputStateFavClub"), false);
        }

        var test = $("#hiddenInputFavClubId").val();
        if (test != -1) {
            var jsondata = {favClubId: test};
            var jsonstring = JSON.stringify(jsondata);

            $.post('/office/settings/saveFavClub' , { jsonstring : jsonstring} , null , 'json' );

            bPostRegister['inputStateFavClub'] = true;
            updateState($("#inputStateFavClub"), true);
        }
    };

    function updateState(stateElement, valid) {
        if (valid === "hide") {
            $("#emailStateInfo").html("");
        } else if (valid) {
            $(stateElement).removeClass("inputStateInvalid");
            $(stateElement).addClass("inputStateValid");
        } else {
            $(stateElement).addClass("inputStateInvalid");
            $(stateElement).removeClass("inputStateValid");
        }
    }

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    function verifyEmail(email) {
        var payload = {email: email};
        $.getJSON("/auth/isvalidemail", payload, function(data) {
            if (data['valid']) {
                //console.log('Valid Email');
                bEmailValid = true;
                $("#accountEmailNew").removeClass("inputStateInvalid");
                $("#accountEmailNew").addClass("inputStateValid");
                $("#OfficeChangeEmailButton").attr('disabled' , false);
            } else {
                bEmailValid = false;
                //console.log('NO Valid Email!');
                $("#accountEmailNew").addClass("inputStateInvalid");
                $("#accountEmailNew").removeClass("inputStateValid");
                $("#OfficeChangeEmailButton").attr('disabled' , true);
            }
            //console.log('ErrorData' + data['error'] + '!');
            $infoText = $("#emailStateInfo");
            $infoText.html(data['error']);
            if(data['valid']) {
                $infoText.addClass('emailValid');
            } else {
                $infoText.removeClass('emailValid');
            }
            $infoText.show();
            window.clearInterval(emailTimer);
            emailTimer = null;
        });
    }

    var emailTimer = null;
    var emailInputChanged = false;

    var pwTimer = null;
    var pwInputChanged = false;

    olOfficeSettings.resetTooltips = function() {
        $('#OfficeResetTooltipsButton').attr('disabled', true);
        var url = "/office/settings/resetTooltips";
        var data = { };
        $('#checkbox-disableTooltips').prop('checked',false);
        olMsgBox.postLoad(url , data, function() { $('#OfficeResetTooltipsButton').attr('disabled', false); } , Lang.trans('js/office.okay') )
    };



    olOfficeSettings.changePassword = function() {
        addLoadingAnimationToElement("#OfficeChangePWButton" , option)
        var option = { size : 'small' };
        var pw1 = $('#accountPasswordNew').val();
        var pw2 = $('#accountPasswordRepeat').val();
        var pwOld = $('#accountPasswordOld').val();
        var data = { pwold : pwOld , pw1 : pw1 , pw2 : pw2  };

        var callbackNo = function() { removeLoadingAnimationFromElement("#OfficeChangePWButton" , { size: 'small' } ); };

        var url = "/office/settings/changePassword";
        olMsgBox.postLoad(url , data, olOfficeSettings.savePassword , callbackNo , Lang.trans('js/office.yes'), Lang.trans('js/office.no') )
    };

    olOfficeSettings.savePassword = function() {
        var pw1 = $('#accountPasswordNew').val();
        var pw2 = $('#accountPasswordRepeat').val();
        var pwOld = $('#accountPasswordOld').val();

        var data = {pwold: pwOld, pw1: pw1, pw2: pw2};
        var callbackSuccessPWChange = function() {
            removeLoadingAnimationFromElement("#OfficeChangePWButton" , { size: 'small' } );
            $("#accountPasswordOld").val("");
            $("#accountPasswordNew").val("");
            $("#accountPasswordRepeat").val("");
            $("#OfficeChangePWButton").attr('disabled' , true);
        };

        //var url = "/office/settings/savePassword";
        //olMsgBox.postLoad(url , data, callbackSuccessPWChange , Lang.trans('js/office.okay') )
        olMsgBox.postLoad('/office/settings/savePassword', data, callbackSuccessPWChange, Lang.trans('js/office.okay') )
    };

    olOfficeSettings.changeEmail = function() {
        addLoadingAnimationToElement("#OfficeChangeEmailButton" , option)
        var email       = $('#accountEmail').val();
        var emailNew    = $('#accountEmailNew').val();
        var data = { email: email , emailNew : emailNew };
        var option = { size : 'small' };

        var callbackSuccessEmailChange = function() {
            removeLoadingAnimationFromElement("#OfficeChangeEmailButton" , { size: 'small' } );
            $("#accountPasswordRepeat").val("");
            $("#OfficeChangeEmailButton").attr('disabled' , true);
        }

        //var url = "office/settings/saveEmail";
        //olMsgBox.postLoad(url , data, callbackSuccessEmailChange , Lang.trans('js/office.okay') )
        olMsgBox.postLoad('/office/settings/saveEmail', data, callbackSuccessEmailChange, Lang.trans('js/office.okay'));
    };

    olOfficeSettings.updateColorSelectorTimer = function() {
        if (typeof colorSelectorCloserTimer !== typeof undefined)
            clearTimeout(colorSelectorCloserTimer);
        colorSelectorCloserTimer = setTimeout(closeColorSelector, 15000);
    };

    function closeColorSelector() {
        olOfficeSettings.focusToColor(this);
        $('#teamColorParent').removeClass('primary');
        $('#teamColorParent').removeClass('secondary');
    }

    olOfficeSettings.focusToColor = function(element) {
        var catId = $(element).attr('data-cat');
        var index = $(element).attr('data-color-index');
        $('.active.ol-register-form-colorgrid-block').removeClass('active');
        $('#primarycolorCategory' + catId).find('.index' + index).addClass('active');
        $('.active.ol-register-form-colorgrid-block-categorie').removeClass('active');
        $('.color-cat-' + catId).addClass('active');
    };

    olOfficeSettings.focusToColor2 = function(element) {
        var catId = $(element).attr('data-cat');
        var index = $(element).attr('data-color-index');
        $('.active.ol-register-form-colorgrid-block2').removeClass('active');
        $('#colorCategory' + catId).find('.index' + index).addClass('active');
        $('.active.ol-register-form-colorgrid-block-categorie').removeClass('active');
        $('.color-cat-' + catId).addClass('active');
    };


    olOfficeSettings.showOnePrimaryCategory = function(element) {
        $('#primarycolorCategory1').hide();
        $('#primarycolorCategory2').hide();
        $('#primarycolorCategory3').hide();
        $('#primarycolorCategory4').hide();
        $('#primarycolorCategory5').hide();
        $('#primarycolorCategory6').hide();
        $('#primarycolorCategory7').hide();
        $('#primarycolorCategory8').hide();
        $('#primarycolorCategory' + $(this).attr('data-cat')).show();
    };

    olOfficeSettings.showOneSecondaryCategory = function(element) {
        $('#colorCategory1').hide();
        $('#colorCategory2').hide();
        $('#colorCategory3').hide();
        $('#colorCategory4').hide();
        $('#colorCategory5').hide();
        $('#colorCategory6').hide();
        $('#colorCategory7').hide();
        $('#colorCategory8').hide();
        $('#colorCategory' + $(this).attr('data-cat')).show();
    };

    olOfficeSettings.validateProlongPremium = function() {
        //var data = { };
        //var url = "/office/settings/confirmToggleProlongPremium";
        //olMsgBox.postLoad(url , data, olOfficeSettings.closeMsgBox , Lang.trans('js/office.yes') );
        olMsgBox.postLoad('/office/settings/confirmToggleProlongPremium', {}, olOfficeSettings.closeMsgBox, Lang.trans('js/office.yes'));
    };

    olOfficeSettings.toggleProlongPremium = function() {
        //var data = { };
        //var url = "/office/settings/toggleProlongPremium";
        //olMsgBox.postLoad(url , data, function() { setTimeout(olOfficeSettings.validateProlongPremium, 0); }, function() { olOfficeSettings.closeMsgBox(); olOfficeSettings.togglePremiumCheckbox(); } , Lang.trans('js/office.yes') , Lang.trans('js/office.no') );
        olMsgBox.postLoad('/office/settings/toggleProlongPremium', data, function() { 
            setTimeout(olOfficeSettings.validateProlongPremium, 0); 
        }, function() { 
            olOfficeSettings.closeMsgBox(); 
            olOfficeSettings.togglePremiumCheckbox(); 
        }, Lang.trans('js/office.yes'), Lang.trans('js/office.no'));
    };

    olOfficeSettings.closeMsgBox = function() {
        $("#msgBox").fadeOut(300);
    };

    olOfficeSettings.togglePremiumCheckbox = function() {
        $('#checkbox-premiumAccount').prop('checked', !$('#checkbox-premiumAccount').prop('checked'));
    };



    olOfficeSettings.postJSON = function(url, data, callback) {
        return jQuery.ajax({
            'type': 'POST',
            'url': url,
            'contentType': 'application/json',
            'data': data,
            'dataType': 'json',
            'success': callback
        });
    };


    olOfficeSettings.postHTML = function(url, data, callback) {
        return jQuery.ajax({
            'type': 'POST',
            'url': url,
            'contentType': 'application/json',
            'data': data,
            'dataType': 'html',
            'success': callback
        });
    };



    olOfficeSettings.checkVoucherCode = function() {
        var voucherCode = $('#voucherCode').val();
        var data = { voucherCode : voucherCode };

        //console.log("voucher Code " + voucherCode + "!");
        //console.log("Data " + JSON.stringify(data) + " !");
        var jsonstring = JSON.stringify(data);

        olOfficeSettings.postJSON("office/voucher/check", jsonstring, function(responseData) {
            if (responseData.check == "success") {
                $('#voucherCode').val('');
            }

            if (responseData.type == "msg") {
                olMsgBox.json(responseData);
            }

            if (responseData.type == "question") {
                olMsgBox.json(responseData, function() {}, function() {});
            }
        });
    };


    function normalizeTeamname(name) {
        return name.replace(/\s+/g, ' ').trim()
    }

    olOfficeSettings.checkTeamName = function() {
        var value = normalizeTeamname($('#accountTeamnameNew').val());

        $("#teamnameChangeSuccessInfo").removeClass('hidden');
        $("#teamnameChangeFailedInfo").addClass('hidden');
        $("#teamnameStateInfo").addClass('ol-register-form-error');
        $("#teamnameStateInfo").removeClass('ol-office-form-success');
        if (value != '') {
            $("#teamnameStateInfo").html(Lang.trans('js/office.checkingClubname', { name: value }));
        } else {
            $("#teamnameStateInfo").html('');
        }
        $("#OfficeChangeTeamnameButton").attr('disabled' , true);

        if (typeof (inputTimer) != 'undefined') {
            if (inputTimer !== null) clearTimeout(inputTimer);
        }

        inputTimer = window.setTimeout(function() {
            var url = "/office/settings/isvalidteamname";
            var data = { teamname : value };

            callback = olOfficeSettings.validateNewTeamname;

            var jsonstring = JSON.stringify(data);

            olOfficeSettings.postJSON(url, jsonstring, callback);
        } , 1000);
    };


    olOfficeSettings.validateNewTeamname = function(response) {
        if (response.valid) {
            $("#teamnameChangeSuccessInfo").addClass('hidden');
            $("#teamnameChangeFailedInfo").removeClass('hidden');
            $("#teamnameStateInfo").addClass('ol-office-form-success');
            $("#teamnameStateInfo").removeClass('ol-register-form-error');
            $("#teamnameStateInfo").html(response.error);
            $("#OfficeChangeTeamnameButton").attr('disabled' , false);
        } else {
            $("#teamnameChangeSuccessInfo").removeClass('hidden');
            $("#teamnameChangeFailedInfo").addClass('hidden');
            $("#teamnameStateInfo").addClass('ol-register-form-error');
            $("#teamnameStateInfo").removeClass('ol-office-form-success');
            $("#teamnameStateInfo").html(response.error);
            $("#OfficeChangeTeamnameButton").attr('disabled' , true);
        }
    };

    olOfficeSettings.saveTeamnameChange = function() {
        var value = normalizeTeamname($('#accountTeamnameNew').val());

        var url = "/office/settings/changeTeamname";
        var data = { teamname : value };

        olMsgBox.postLoad( url , data, function(value) {
            var value = normalizeTeamname($('#accountTeamnameNew').val());
            //console.log('own JS Function will be loaded with param ' + value);
            olOfficeSettings.reloadAfterTeamnameChange(value);
        } , Lang.trans('js/office.okay') )
    };



    olOfficeSettings.changeTeamname = function() {
        var oldTeamname = $("#accountTeamname").val();
        var newTeamname = normalizeTeamname($("#accountTeamnameNew").val());
        var costs = $("#OfficeChangeTeamnameButton[data-cost]").attr('data-cost');

        //msgbox for Confirmation
        var replacements = {
            '[oldTeamname]' : oldTeamname,
            '[newTeamname]' : newTeamname,
            '[costs]' : costs,
        };

        olMsgBox.questionById('confirmTeamnameChange', olOfficeSettings.saveTeamnameChange , function() {}, replacements );
    };


    olOfficeSettings.reloadAfterTeamnameChange = function(name) {
        //#ol-header-container .ol-nav-team-banner.ol-team-name > div.ol-nav-team-name
        //Just one Selector for teamname in Header!
        $("#ol-header-container .ol-nav-team-banner.ol-team-name > div.ol-nav-team-name").html(name);
        olAnchorNavigation.load('/office/settings', undefined, '#OfficeContent', true);
    }

    olOfficeSettings.loadSettings = function(){
        var $anchor = $('#officeContent');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get('/office/settings?small=true', function(html) {
            $('#officeContent').html(html);
            addUrlParams('?subPage=/office/settings?small=true');
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** loadSettings failed");
        });
    }

    olOfficeSettings.loadCouponCode = function(){
        var $anchor = $('#officeContent');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get('/office/voucher', function(html) {
            $('#officeContent').html(html);
            addUrlParams('?subPage=/office/voucher');
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("*** loadCouponCode failed");
        });
    }

    olOfficeSettings.loadInviteCode = function(){
        var $anchor = $('#officeContent');
        addLoadingAnimationToElement($anchor, {size: 'small'});
        $.get('/office/referrals', function(html) {
            $('#officeContent').html(html);
            addUrlParams('?subPage=/office/referrals');
            removeLoadingAnimationFromElement($anchor);
        }).fail(function() {
            removeLoadingAnimationFromElement($anchor);
            console.log("***loadInviteCode failed");
        });
    }

    olOfficeSettings.sendInvitationEmail = function() {
        var email = $('#invitations-friend-email').val();
        var msg = $('.invitations-friend-email-msg').val();

        $.post('/office/referrals/send', {
            'email': email,
            'msg': msg
        }, function(res) {
            if (res.error) {
                $('.invitations-error-box').removeClass('show');
                $('.invitations-error-box > span').text(res.error_string);
                setTimeout(function() {
                    $('.invitations-error-box').addClass('show');
                }, 0)
            } else {
                $('#invitations-friend-email').val('');
                olMsgBox.msgById('email_sent', $('.invitations-list').load('office/referrals/list'), {
                    ':email': email
                });
            }
        });
    }

    olOfficeSettings.copyInvLink = function() {
        var input = $('.invitations-link-input').get(0);

        $('.invitations-copied-hint').removeClass('show-text');

        setTimeout(function() {
            $('.invitations-copied-hint').addClass('show-text');
        }, 0)

        input.disabled = false;
        input.select();
        input.setSelectionRange(0, 999999);
        document.execCommand("copy");
        input.disabled = true;
    }

    olOfficeSettings.flashIcons = function() {
        var delay = 0;
        $('.invitation-success > .icon-premium-reward-check').each(function() {
            var _this = this;
            setTimeout(function() {
                $(_this).addClass('show-icon');
            }, delay);

            delay += 550;
        })

        $(document).off('scroll', olOfficeSettings.flashIcons);
    }
    
    var invitationsFilterClass = '';
    olOfficeSettings.filterInvitations = function(arg) {

        $('.invitations-list').removeClass(invitationsFilterClass);

        invitationsFilterClass = '';
        if (arg != '') {
            invitationsFilterClass = 'only-' + arg;
            $('.invitations-list').addClass(invitationsFilterClass);
        }
    }

}(window.olOfficeSettings = window.olOfficeSettings || {}, jQuery));