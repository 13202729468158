"use strict";
(function(olCookieConsentSelection, $, undefined) {
    var _initWelect = false;
    var _welectAvailable = false;
    var _welectUrl = false;
    var _allCookiesAccepted = false;
    var _adblockActive = true;
    var _vendorList = true;
    var _checkedOnce = false;
    var _isClean = false;
    var _welectVideoBailExpireUpdateTime = false;
    var _welectVideoBailExpireTime = false;
    var _intervalTime = false;
    var _intervalTimeMax = false;

    olCookieConsentSelection.close = function() {
        $('#cookieConsentSelection').remove();
        olGUI.unlockScroll();
        olMobileApp.showBottomNav();
        olMobileApp.showHeader();
    };

    olCookieConsentSelection.setVendorList = function(vendorList) {
        _vendorList = vendorList;
    };

    olCookieConsentSelection.setWelectInterval = function(intervalTime, intervalTimeMax) {
        _intervalTime = intervalTime;
        _intervalTimeMax = intervalTimeMax;
    };

    olCookieConsentSelection.setWelectBailExpireTime = function(time) {
        var now = new Date();
        _welectVideoBailExpireTime = new Date(now.getTime() + time * 1000);
        _welectVideoBailExpireUpdateTime = new Date();
    };

    olCookieConsentSelection.initSettings = function() {
        if (_allCookiesAccepted && !_adblockActive) {
            $('#settings-plus').hide();
        }
        olCookieConsentSelection.initWelect();
    };

    olCookieConsentSelection.navUpdate = function(url) {
        if (_welectBailActive()) return;
        if (!_checkedOnce || url.search('premium') >= 0 || url.search('hp_return') >= 0) return;
        _adblockActive = olGUI.isAdblockActive(); // update status
        if (!_allCookiesAccepted || _adblockActive) {
            olCookieConsentSelection.load();
        }
    }

    olCookieConsentSelection.check = function(force, async) {
        if(typeof force === typeof undefined) force = false;
        if(typeof async === typeof undefined) async = false;
        console.log("* Ads Debug | olCookieConsentSelection.check("+force+", "+async+")");
        if(_welectBailActive()) return;
        var url = window.location.href;
        if(url.search('premium') >= 0 || url.search('hp_return') >= 0) return;
        console.log("* Ads Debug | !_welectBailActive() & !prem");
        
        function _inner() {
            var accepted = true;
            function _process(accepted) {
                console.log("* Ads Debug | _process("+accepted+")");
                _allCookiesAccepted = accepted;
                _adblockActive = olGUI.isAdblockActive();
                _checkedOnce = true;
                init();
                if (force || (!_allCookiesAccepted || _adblockActive)) {
                    olCookieConsentSelection.load();
                } else {
                    if (!_isClean) {
                        _isClean = true;
                        $.post('/cookieconsent/clean');
                    }
                    olCookieConsentSelection.close();
                }
            };

            if (typeof __tcfapi === "function") {
                __tcfapi('getTCData', 2, function(tcData, success) {
                    var unaccepted = 0;
                    var vendorCount = 0;
                    var legitimateInterests = 0;
                    var unlegitimateInterests = 0;
                    var noConsentAndLegitimateInterest = 0;
                    if (success) {
                        var consents = tcData.vendor.consents;
                        _vendorList.forEach(function(id) {
                            if (!consents[id]) {
                                accepted = false;
                                unaccepted++;
                            }
                        });
                        for (var key in consents) {
                            if (!consents[key]) {
                                if (tcData.vendor.legitimateInterests[key])
                                    noConsentAndLegitimateInterest++;
                                accepted = false;
                            }
                            vendorCount++;
                        }

                        for (var key in tcData.vendor.legitimateInterests) {
                            if (tcData.vendor.legitimateInterests[key])
                                ++legitimateInterests;
                            else
                                ++unlegitimateInterests;
                        }
                    } else {
                        return;
                    }
                    // todo: remove
                    //console.info('VENDOR COUNT ' + vendorCount + " UNACCEPTED " + unaccepted + " legitimateInterests " + legitimateInterests + " unlegitimateInterests " + unlegitimateInterests + " noConsentAndLegitimateInterest " + noConsentAndLegitimateInterest + " % " + (unaccepted /  vendorCount * 100));
                    _process(accepted);
                }, _vendorList);
            } else {
                _process(false);
            }
        }

        if (typeof Welect !== "undefined") {
            Welect.checkAvailability({
                onAvailable: function() {
                    _welectAvailable = true;
                    _updateWelectOption();
                    _initWelect = true;
                },
                onUnavailable: function() {
                    _welectAvailable = false;
                    _updateWelectOption();
                    _initWelect = true;
                }
            });
        }

        if (async)
            setTimeout(_inner, 4000);
        else
            _inner();
    };

    function init() {
        olCookieConsentSelection.initWelect();
        $('#buttonAccept').removeClass('disabled');
        if (_allCookiesAccepted) {
            $('#cookieConsentSelectionCookies .icon-icon_checker').show();
            $('#cookieConsentSelectionCookies .icon-red_cross').hide(); 
        } else {
            $('#cookieConsentSelectionCookies .icon-icon_checker').hide();
            $('#cookieConsentSelectionCookies .icon-red_cross').show();
        }
        _adblockActive = olGUI.isAdblockActive(); // update status
        if (_adblockActive) {
            $('#cookieConsentSelectionAdblocker .icon-icon_checker').hide();
            $('#cookieConsentSelectionAdblocker .icon-red_cross').show();
            $('#buttonAccept').addClass('disabled');
        } else {
            $('#cookieConsentSelectionAdblocker .icon-icon_checker').show();
            $('#cookieConsentSelectionAdblocker .icon-red_cross').hide();
        }
        olMobileApp.hideBottomNav();
        olMobileApp.hideHeader();
    }

    olCookieConsentSelection.buttonAccept = function() {
        if (!_allCookiesAccepted) {
            __cmp('setConsent', 1);
        }
        olCookieConsentSelection.close();
    };

    olCookieConsentSelection.load = function() {
        var currentPath = window.location.pathname;
        if(currentPath.search('hp_return') >= 0) return;
        $.ajax({
            url:'/cookieconsent',
            type:'GET',
            success:function(data){
                olCookieConsentSelection.close();
                $('body').append(data);
                olGUI.lockScroll($('#cookieConsentSelection'));
                init();
            },
        });
    };

    olCookieConsentSelection.setUrl = function(welectUrl) {
        _welectUrl = welectUrl;
    };

    function _welectBailActive() {
        if(_welectVideoBailExpireTime === false) return false;
        var now = new Date();
        return now < _welectVideoBailExpireTime;
    }

    function _updateWelectOption() {
        if (_welectAvailable) {
            $('.ol-cookie-consent-box-bottom').fadeIn();
            $('#settingsWelectButton').show();
        } else {
            _skipWelect()
        }
        $('.ol-cookie-consent-box-bottom').removeClass('disabled');
    }

    function _skipWelect() {
        olCookieConsentSelection.close();
        $.post('/cookieconsent/welect', {skip: true});
        var now = new Date();
        if (_welectVideoBailExpireTime === false ||(_welectVideoBailExpireTime !== false && _welectVideoBailExpireTime < now)) {
            _welectVideoBailExpireTime = new Date(now.getTime() + (_intervalTime*0.5) * 60000);
        }
    }

    function _welectCallback(callback) {
        olCookieConsentSelection.close();
        $.post('/cookieconsent/welect', function() { if(callback) callback(); });
        var now = new Date();
        if (_welectVideoBailExpireTime === false ||(_welectVideoBailExpireTime !== false && _welectVideoBailExpireTime < now)) {
            _welectVideoBailExpireTime = new Date(now.getTime() + _intervalTime * 60000);
        } else if (_welectVideoBailExpireTime !== false && _welectVideoBailExpireTime >= now) {
            _welectVideoBailExpireTime = new Date(_welectVideoBailExpireTime.getTime() + _intervalTime * 60000);
        }
    }

    olCookieConsentSelection.initWelect = function() {
        if (_initWelect) {
            _updateWelectOption();
            return;
        }
        function _scriptLoaded() {
            Welect.checkAvailability( {
                onAvailable: function() {
                    _welectAvailable = true;
                    _updateWelectOption();
                    _initWelect = true;
                },
                onUnavailable: function() {
                    _welectAvailable = false;
                    _updateWelectOption();
                    _initWelect = true;
                }
            });
        }

        $('#welectAd').remove();
        var script = document.createElement('script');
        script.setAttribute("id", "welectAd");
        script.onload = function() {
            _scriptLoaded();
        };
        script.src = _welectUrl;
        document.head.appendChild(script);
    };

    var _buttonLock = false;
    olCookieConsentSelection.runWelect = function(callback) {
        if(_buttonLock) return;
        _buttonLock = true;
        setTimeout(function() {
            _buttonLock = false;
        }, 2000);
        if (typeof callback === typeof undefined) callback = false;
        if (!_allCookiesAccepted)
            olMsgBox.msgById('video_cookies');
        function _showWelect() {
            Welect.checkAvailability( {
                onAvailable: function() {
                    Welect.runSession({onSuccess: function() { 
                        _welectCallback(callback); 
                    }, onCancel: function() {}});
                },
                onUnavailable: function() {
                    _skipWelect()
                }
            });
        }
        
        Welect.checkToken({ onValid: _showWelect, onInvalid: _showWelect });
    };

}(window.olCookieConsentSelection = window.olCookieConsentSelection || {}, jQuery));
