"use strict";
// // File for basic image editing.
// Zoom, Move, with overlay showing which areas are cropped.
(function(olImageEditor, $, undefined) {

    // Creates a new ImageEditor with `options` and returns the instance
    olImageEditor.create = function(options) {
        return new ImageEditor(options);
    };

    function ImageEditor(options) {
        if (options === undefined) options = {};
        $.extend(this.options, options);
        this.transformCached = this.transform; // Cache for recalculation.
        this.previews = new Map();
        this.editors = new Map();
        this.input.url = undefined;
    }

    ImageEditor.prototype = {
        prevX: 0,
        prevY: 0,
        lastZoom: 1,

        input: {
            url: undefined,
            image: undefined
        },
        transform: {
            move: [0, 0], // (-x/imageWidth, -y/imageHeight)
            zoom: 1,      // (1/zoomFactor)
            scale: 1,     // fixed display scale factor to fit image into area
            toString: function() {
                return '{move: ['+this.move[0]+', '+this.move[1]+'], zoom: '+this.zoom+', scale: '+this.scale+'}';
            }
        },
        transformCached : null, // unused?
        previews: null,
        editors: null,
        tmpFileName: '',

        positionIsUndefined: true,

        options: { // defaults
            canvasWidth: 350,
            canvasHeight: 350,
            canvas2Width: 300,
            canvas2Height: 300,
            canvasBlurAlpha: 0.6,
            canvasBlurColor: '#d8d8d8',
            frameWidth: 100,
            frameHeight: 100,
         	dashImageRelPath: 'imgs/dashed_line.png',
        }
    };
    ImageEditor.prototype.constructor = ImageEditor;



    //--- Image loading ---

    ImageEditor.prototype.load = function(imageURL, readyCallback) {
//console.log('  load("'+imageURL+'")');
        if (imageURL === undefined || typeof(imageURL) !== 'string') throw "Invalid URL";
        if (readyCallback === undefined) readyCallback = null;

        this.input.url = imageURL;
        var tempImg = new Image();
        tempImg.onload = function() {
//console.log('=> image loaded');
//console.log('  => repositioning');
            this.positionIsUndefined = true;
            this.update();
//console.log('  => callback');
            if (typeof readyCallback === 'function') readyCallback();
//console.log('<= image loaded');
        }.bind(this);
        tempImg.src = imageURL;
        this.input.image = tempImg;
    };

    // Set image from stored parameters
    ImageEditor.prototype.assignImage = function(imageURL, zoom, position_x, position_y) {
        if (imageURL === undefined || typeof(imageURL) !== 'string') throw "Invalid URL";
        if (zoom === undefined) zoom = 1;
        if (position_x === undefined) position_x = 0;
        if (position_y === undefined) position_y = 0;
        
        this.input.url = imageURL;
        var tempImg = new Image();
        tempImg.onload = function() {
            this._setImageIntoArea(zoom, position_x, position_y);
            this.positionIsUndefined = false;
            this.update();
        }.bind(this);
        tempImg.src = imageURL;
        this.input.image = tempImg;
    };
    
    ImageEditor.prototype.clearImage = function() {
        this.input.url = undefined;
        this.transform.zoom = 1;
        this.transform.move = [0, 0];
        this.transform.scale = 1;
        this.update();
    };



    //--- Operations ---

    ImageEditor.prototype.resetTransform = function() {
        this.transform.zoom = 1.2;
        this.transform.move = [0, 0];
//console.log('  resetTransform() -> '+this.transform);
        this.update();
    };

    ImageEditor.prototype.centerImage = function() {
//console.log('  centerImage()');
        var f = this._centerImageInArea();
        this.update();
        return f;
    }

    // Center vertically and horizontally without zoom change (without update)
    ImageEditor.prototype._centerImageInArea = function() {
        var iw = this.options.canvasWidth - 2*this.options.frameWidth,
            ih = this.options.canvasHeight - 2*this.options.frameHeight;
        var image = this.input.image,
            imageWidth = image.width,
            imageHeight = image.height,
            imageRatio = imageWidth / imageHeight;
        var scale = this.transform.scale;
//console.log('    _centerImageInArea(): canvas='+iw+'x'+ih+', image='+imageWidth+"x"+imageHeight+' ×'+scale+' @'+imageRatio);

        // center horizontally and vertically without changing the zoom
        var f = 1 / this.transform.zoom, //@ZOOM
            width = imageWidth * scale * f, //@ZOOM
            height = width / imageRatio;

        var x = (iw - width ) / 2,
            y = (ih - height) / 2;
        this._transform(x, y);
        return f;
    };

    // Sets image size and initial position (left top) so that it fits into the area.
    // Initializes transform parameters (without calling `update`).
    ImageEditor.prototype._fitImageIntoArea = function() {
        var iw = this.options.canvasWidth - 2*this.options.frameWidth,
            ih = this.options.canvasHeight - 2*this.options.frameHeight;
        var image = this.input.image,
            imageWidth = image.width,
            imageHeight = image.height,
            imageRatio = imageWidth / imageHeight;
        var width = imageWidth,
            height = imageHeight;
        if (imageRatio > 1) {
            width = iw;
            height = width / imageRatio;
            if (height > ih) {
                height = ih;
                width = height * imageRatio;
            }
        } else {
            height = ih;
            width = height * imageRatio;
            if (width > iw) {
                width = iw;
                height = width / imageRatio;
            }
        }

        var scale = width / imageWidth; // adjustment factor to canvas area
//console.log('    _fitImageIntoArea: area='+iw+'x'+ih+', image='+imageWidth+'x'+imageHeight+' -> '+width+'x'+height+' = ×'+scale);

        // Set initial transform parameters
        this.transform.move[0] = 0;
        this.transform.move[1] = 0;
        this.transform.zoom = 1.2;
        this.transform.scale = scale;
    };

    // Sets image size and position from `zoom`, `position_x`, `position_y` 
    // and initializes transform parameters accordingly (without calling `update`).
    ImageEditor.prototype._setImageIntoArea = function(zoom, position_x, position_y) {
        var iw = this.options.canvasWidth - 2*this.options.frameWidth,
            ih = this.options.canvasHeight - 2*this.options.frameHeight;
        var image = this.input.image,
            imageWidth = image.width,
            imageHeight = image.height,
            imageRatio = imageWidth / imageHeight;
        var width = imageWidth,
            height = imageHeight;
        if (imageRatio > 1) {
            width = iw;
            height = width / imageRatio;
            if (height > ih) {
                height = ih;
                width = height * imageRatio;
            }
        } else {
            height = ih;
            width = height * imageRatio;
            if (width > iw) {
                width = iw;
                height = width / imageRatio;
            }
        }

        var scale = width / imageWidth; // adjustment factor to canvas area

        // Set initial transform parameters
        this.transform.scale = scale;
        this.transform.zoom = zoom;
        this.transform.move[0] = position_x / imageWidth;
        this.transform.move[1] = position_y / imageHeight;
    };

    // Sets offset and zoom (without update).
    // The parameters represent display parameters.
    // Any parameter that is undefined will not be changed.
    // Returns the resulting display parameters.
    ImageEditor.prototype._transform = function(x, y, f) {
        var image = this.input.image,
            imageWidth = image.width,
            imageHeight = image.height;
        var scale = this.transform.scale;
        if (x !== undefined) this.transform.move[0] = -x / scale / imageWidth;
        if (y !== undefined) this.transform.move[1] = -y / scale / imageHeight;
        if (f !== undefined) this.transform.zoom = 1 / f; //@ZOOM

        var newX = -imageWidth * this.transform.move[0] * scale,
            newY = -imageHeight * this.transform.move[1] * scale,
            newF = 1 / this.transform.zoom,
            newWidth = imageWidth * scale * newF,
            newHeight = imageHeight * scale * newF;
//console.log('      _transform('+x+', '+y+', '+f+') = ('+newX+', '+newY+', '+newF+')\n      -> '+this.transform);
        return {
            x: newX,
            y: newY,
            f: newF,
            width: newWidth,
            height: newHeight,
            cx: newX + newWidth / 2,
            cy: newY + newHeight / 2
        };
    };

    // Get/set zoom
    ImageEditor.prototype.zoom = function(zoom) {
//console.log((zoom===undefined)? 'zoom()='+this.transform.zoom : 'zoom('+zoom+')');
        if (zoom === undefined) {
            return this.transform.zoom; //@ZOOM
        } else {
            this.transform.zoom = zoom; //@ZOOM
            this.update();
        }
    };

    ImageEditor.prototype.zoomCentered = function(zoom) {
        if (zoom === undefined) return this.transform.zoom;
        var t = this._transform();
        var f = 1 / zoom;
        var t2 = this._transform(undefined, undefined, f);
        var dcx = t.cx - t2.cx,
            dcy = t.cy - t2.cy,
            x = t2.x + dcx,
            y = t2.y + dcy;
//console.log('### zoomCentered('+zoom+'): ', t, t2, [dcx, dcy], [x, y]);
        this._transform(x, y, f);
        this.update();
    };

    // Returns the normalized coordinates for storage
    ImageEditor.prototype.position_xy = function() {
//console.log('  position_xy()=['+(this.transform.move[0] * this.input.image.width)+', '+(this.transform.move[1] * this.input.image.height)+']');
        if (this.input.image === undefined) return null;
        return [
            this.transform.move[0] * this.input.image.width,
            this.transform.move[1] * this.input.image.height
        ];
    };

    // Get/set position to absolute display coordinates
    ImageEditor.prototype.position = function(x, y) {
//if(x!==undefined)console.log('position('+x+', '+y+')');
        if (this.input.image === undefined) return;
        var xNew = 0, yNew = 0;
        if (x === undefined) {
            var t = this._transform();
//console.log('  position()=['+t.x+', '+t.y+']');
            return [t.x, t.y];
        } else if ((typeof(x) === 'number') && (typeof(y) === 'number')) {
            xNew = x;
            yNew = y;
        } else if (x instanceof Array) {
            if ((typeof(x[0]) === 'number') && (typeof(x[1]) === 'number')) {
                xNew = x[0];
                yNew = x[1];
            } else if ((typeof(x['x']) === 'number') && (typeof(x['y']) === 'number')) {
                xNew = x['x'];
                yNew = y['y'];
            } else {
                throw "Invalid Input";
            }
        } else {
            throw "Invalid Input";
        }

        this._transform(xNew, yNew);
        this.update();
    };

    // Move position by relative display coordinates
    ImageEditor.prototype.move = function(x, y) {
//console.log('move('+x+', '+y+')');
        if (this.input.image === undefined) return;

        var xAdd = 0, yAdd = 0;
        if ((typeof(x) === 'number') && (typeof(y) === 'number')) {
            xAdd = x;
            yAdd = y;
        } else if (x instanceof Array) {
            if ((typeof(x[0]) === 'number') && (typeof (x[1]) === 'number')) {
                xAdd = x[0];
                yAdd = x[1];
            } else if ((typeof(x['x']) === 'number') && (typeof(x['y']) === 'number')) {
                xAdd = x['x'];
                yAdd = x['y'];
            } else {
                throw "Invalid Input";
            }
        } else {
            throw "Invalid Input";
        }

        var t = this._transform(),
            xNew = t.x + xAdd,
            yNew = t.y + yAdd;
        this._transform(xNew, yNew);
        this.update();
    };



    //--- Event handler (to be bound)

    ImageEditor.prototype._handleMouseDown = function(event) {
        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el == undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }

        var editor = this.editors.get(el);
        editor.drag.enabled = true;
        editor.drag.x = event.layerX;
        editor.drag.y = event.layerY;
        editor.handler.style.cursor = "grabbing";
        editor.handler.classList.add("dragging");
        // TODO: Add and remove MouseMove, MouseLeave handlers on MouseDown and MouseUp/MouseLeave (Battery saving and speed improvements)
    };

    ImageEditor.prototype._handleMouseUp = function(event) {
        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el === undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }

        var editor = this.editors.get(el);
        editor.drag.enabled = false;
        editor.drag.x = event.layerX;
        editor.drag.y = event.layerY;
        editor.handler.style.cursor = "grab";
        editor.handler.classList.remove("dragging");
    };

    ImageEditor.prototype._handleMouseMove = function(event) {
        var movementX = (this.prevX ? event.screenX - this.prevX : 0)
        var movementY = (this.prevY ? event.screenY - this.prevY : 0)

        this.prevX = event.screenX;
        this.prevY = event.screenY;

        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el === undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }

        if (!this.editors.get(el).drag.enabled) {
            return;
        }

        this.move(movementX, movementY);
    };

    ImageEditor.prototype._handleMouseEnter = function(event) {
        this._handleMouseUp(event);
    };

    ImageEditor.prototype._handleMouseLeave = function(event) {
        this._handleMouseUp(event);
    };

    ImageEditor.prototype._handleTouchStart = function(event) {
        this.prevX = event.touches[0].pageX;
        this.prevY = event.touches[0].pageY;
    };

    ImageEditor.prototype._handleTouchMove = function(event) {
        var movementX = (this.prevX? event.touches[0].pageX - this.prevX : 0);
        var movementY = (this.prevY? event.touches[0].pageY - this.prevY : 0);

        this.prevX = event.touches[0].pageX;
        this.prevY = event.touches[0].pageY;

        // Find Editor/Handler
        var el = event.srcElement.parentElement.parentElement;
        if (el == undefined) {
            // Technically impossible, but vars not assume with JS async context and mobile browsers.
            return;
        }

        this.move(movementX, movementY);
    };

    ImageEditor.prototype._handleTouchCancel = function(event) {
        // TODO
        // https://w3c.github.io/touch-events/#touches-and-targettouches-of-a-touchevent
    };

    ImageEditor.prototype._handleTouchEnd = function(event) {
        // TODO
        // https://w3c.github.io/touch-events/#touches-and-targettouches-of-a-touchevent
    };



    //--- editor/preview area ---

    ImageEditor.prototype.addEditor = function(element) {
        // Editors automatically contain a preview.
        this.removeEditor(element);

        var elHandler = document.createElement('div');
        elHandler.classList.add("handler");
        elHandler.style.display = "block";
        elHandler.style.position = "absolute";
        elHandler.style.top = "0px";
        elHandler.style.bottom = "0px";
        elHandler.style.left = "0px";
        elHandler.style.right = "0px";
        //elHandler.style['z-index'] = "16777215";
        elHandler.style.zIndex = "16777215";
        elHandler.style.background = "transparent";
        elHandler.style.border = "0 solid";
        elHandler.style.margin = "0";
        elHandler.style.padding = "0";
        elHandler.style.cursor = "grab";
        elHandler.onmousedown = this._handleMouseDown.bind(this);
        elHandler.onmouseup = this._handleMouseUp.bind(this);
        elHandler.onmousemove = this._handleMouseMove.bind(this);
        elHandler.onmouseenter = this._handleMouseEnter.bind(this);
        elHandler.onmouseleave = this._handleMouseLeave.bind(this);
        elHandler.ontouchstart = this._handleTouchStart.bind(this);
        elHandler.ontouchend = this._handleTouchEnd.bind(this);
        elHandler.ontouchmove = this._handleTouchMove.bind(this);
        elHandler.ontouchcancel = this._handleTouchCancel.bind(this);
        elHandler.source = this;

        var elRender = document.createElement('div');
        elRender.classList.add("render");
        elRender.style.display = "block";
        elRender.style.position = "absolute";
        elRender.style.top = "0px";
        elRender.style.bottom = "0px";
        elRender.style.left = "0px";
        elRender.style.right = "0px";
        elRender.style.background = "transparent";
        elRender.style.border = "0 solid transparent";
        elRender.style.margin = "0",
        elRender.style.padding = "0";
        elRender.source = this;

        var elContainer = document.createElement('div');
        elContainer.classList.add("ol-image-editor-editor");
        elContainer.style.display = "block";
        elContainer.style.position = "relative";
        elContainer.style.background = "transparent";
        elContainer.style.border = "0 solid transparent";
        elContainer.style.margin = "0",
        elContainer.style.padding = "0";
        elContainer.style.width = this.options.canvasWidth+'px';//"100%";
        elContainer.style.height = this.options.canvasHeight+'px';//"100%";
        elContainer.appendChild(elRender);
        elContainer.appendChild(elHandler);
        elContainer.source = this;

        element.appendChild(elContainer);
        this.editors.set(element, {
            container: elContainer,
            handler: elHandler,
            render: elRender,
            drag: {enabled: false, x: 0, y: 0}
        });

        return this.addPreview(elRender);
    };

    ImageEditor.prototype.removeEditor = function(element) {
        if (!this.editors.has(element)) return;

        var editor = this.editors.get(element);
        this.removePreview(editor.render);
        element.removeChild(editor.container);

        this.editors.set(element, undefined);
    };

    ImageEditor.prototype.getEditorPreview = function(element) {
        if (!this.editors.has(element)) return undefined;
        return this.editors.get(element).render;
    };

    // Adds preview it to `element`
    ImageEditor.count = 0;
    ImageEditor.prototype.addPreview = function(element) {
//console.log('=> addPreview', element);
        this.removePreview(element);

        var cw  = this.options.canvasWidth,   // default: 350
            ch  = this.options.canvasHeight,  // default: 350
            c2w = this.options.canvas2Width,  // default: 300
            c2h = this.options.canvas2Height; // default: 300
        var fw = this.options.frameWidth,     // default: 100
            fh = this.options.frameHeight;    // default: 100

        var elImage = document.createElement('img');
        elImage.classList.add("image");
//        elImage.setAttribute("id", "imageSrc");
        elImage.setAttribute("id", "imageSrc"+(ImageEditor.count++));
        elImage.setAttribute("src", "");
        elImage.style.display = "block";
        elImage.style.position = "absolute";
        elImage.style.left = "0";
        elImage.style.top = "0";
        elImage.style.width = "100%";
        elImage.style.height = "100%";
        elImage.source = this;

        var canvasOverlay = document.createElement('canvas');
        canvasOverlay.classList.add("canvasBlur");
        canvasOverlay.setAttribute("id", "canvasBlur");
        canvasOverlay.style.width = cw;
        canvasOverlay.style.height = ch;
        canvasOverlay.source = this;

//        var canvasOverlay2 = document.createElement('canvas');
//        canvasOverlay2.classList.add("canvasBlur");
//        canvasOverlay2.setAttribute("id", "canvasBlur2");
//        canvasOverlay2.style.left = "0px";
//        canvasOverlay2.style.width = c2w+'px';
//        canvasOverlay2.style.height = c2h+'px';
//        canvasOverlay2.source = this;

        var elOverlay = document.createElement('div');
        elOverlay.classList.add("overlay");
        elOverlay.style.display = "block";
        elOverlay.style.position = "absolute";
        elOverlay.style.width = "100%";
        elOverlay.style.height = "100%";
        elOverlay.style.top = "0px";
        elOverlay.style.bottom = "0px";
        elOverlay.style.left = "0px";
        elOverlay.style.right = "0px";
        elOverlay.style['z-index'] = "16777215";
        elOverlay.style.background = "transparent";
        elOverlay.style['border-width'] = "0";
        elOverlay.style.margin = "0";
        elOverlay.style.padding = "0";
        elOverlay.appendChild(canvasOverlay);
//        elOverlay.appendChild(canvasOverlay2);
        elOverlay.source = this;

        // Kleine Voransicht des hochgeladenen Bildes
        var elContainer = document.createElement('div');
        elContainer.classList.add("ol-image-editor-preview");
        elContainer.style.display = "block";
        elContainer.style.position = "relative";
        elContainer.style.background = "transparent";
        elContainer.style.border = "0 solid transparent";
        elContainer.style.margin = "0",
        elContainer.style.padding = "0";
        elContainer.style.width = cw+'px';//"100%";
        elContainer.style.height = ch+'px';//"100%";
        elContainer.appendChild(elImage);
        elContainer.appendChild(elOverlay);
        elContainer.source = this;

        element.appendChild(elContainer);

        this.previews.set(element, {
            container: elContainer,
            overlay: elOverlay,
            image: elImage,
            border: 0
        });

        // blur effect for canvasBlur
        var canvasBlur = document.getElementsByClassName('canvasBlur');
        canvasBlur[0].width  = cw;
        canvasBlur[0].height = ch;
//        canvasBlur[1].width  = c2w;
//        canvasBlur[1].height = c2h;

        var ctxBlur = canvasBlur[0].getContext('2d');
        ctxBlur.globalAlpha = this.options.canvasBlurAlpha; // 0.6;
        ctxBlur.fillStyle = this.options.canvasBlurColor; // "#D8D8D8";
        ctxBlur.fillRect(0,     0,     cw, fh     ); // top
        ctxBlur.fillRect(0,     fh,    fw, ch-fh*2); // left
        ctxBlur.fillRect(cw-fw, fh,    fw, ch-fh*2); // right
        ctxBlur.fillRect(0,     ch-fh, cw, fh     ); // bottom

        // line around select
        var dashImageUrl = olConfig.image_host + this.options.dashImageRelPath;
        var dashImage = new Image();
        dashImage.onload = function() {
            ctxBlur.drawImage(dashImage, fw-1, fh-1, cw-2*fw+2, ch-2*fh+2);
        }
        dashImage.src = dashImageUrl;

        return elOverlay;
    };

    ImageEditor.prototype.setPreviewBorder = function(element, pixels) {
        if (!this.previews.has(element)) return;
        var preview = this.previews.get(element);
        preview.border = pixels;
        this.update();
    };

    ImageEditor.prototype.getPreviewOverlay = function(element) {
        if (!this.previews.has(element)) return;
        return this.previews.get(element).overlay;
    };

    ImageEditor.prototype.removePreview = function(element) {
        if (!this.previews.has(element)) return;
        var preview = this.previews.get(element);
        element.removeChild(preview.container);
        this.previews.set(element, undefined);
    };



    //--- Update ----

    ImageEditor.prototype.update = function() {
        var cw  = this.options.canvasWidth, ch  = this.options.canvasHeight; // canvas
        var fw = this.options.frameWidth,   fh = this.options.frameHeight;   // frame around image (inside!)
        var iw = cw-2*fw,                   ih = ch-2*fh;                    // image inside canvas
//console.log('    > update # '+this.transform);

        // Initially set imagee size and position
        if (this.positionIsUndefined && this.input.image) {
            this._fitImageIntoArea();
//console.log('    update position: # '+this.transform);
            this.positionIsUndefined = false;
        }

        // Go through all previews and update display
        this.previews.forEach(function(value, key, map) {
////console.log('      preview:', value);
////console.log('      preview: input.image:', this.input.image);

            // Calculate Container Size and Aspect Ratio
            var containerSize = {
                w: value.container.clientWidth || cw,
                h: value.container.clientHeight || ch
            };
            //var containerAspect = containerSize.w / containerSize.h;

            // Calculate View Size and Aspect Ratio
            var view = {x: 0, y: 0, w: iw, h: ih, aspect: iw/ih};

            // Calculate and apply border and offsets
            var border = {
                x: (containerSize.w - view.w) / 2.0,
                y: (containerSize.h - view.h) / 2.0
            };
            value.container.style.margin = border.y + 'px ' + border.x + 'px';
            value.overlay.style.marginLeft   = -border.x + "px";
            value.overlay.style.marginTop    = -border.y + "px";
            value.overlay.style.marginRight  = border.x + "px";
            value.overlay.style.marginBottom = border.y + "px";
            value.overlay.style.borderWidth  = border.y + 'px ' + border.x + 'px';

            // Calculate size and reposition the image.
            var imageWidth = this.input.image? this.input.image.width  : iw,
                imageHeight = this.input.image? this.input.image.height : ih,
                imageRatio = this.input.image? imageWidth/imageHeight : 1,
                width = imageWidth * this.transform.scale,
                height = imageHeight * this.transform.scale;

            var x = -width * this.transform.move[0],
                y = -height * this.transform.move[1],
                f = 1 / this.transform.zoom,
                w = width,
                h = height;

            if (this.input.url === undefined) {
                value.image.style.display = "none";
                value.image.src = '';
            } else {
                value.image.src = this.input.url;
                value.image.style.display = "block";
            }
            value.image.style.left = x+"px";
            value.image.style.top = y+"px";
            value.image.style.marginLeft = '0px';
            value.image.style.marginTop  = '0px';
            value.image.style.width = w+"px";
            value.image.style.height = h+"px";
            value.image.style.transform = "scale("+f+")"; //@ZOOM
            value.image.style.transformOrigin = "left top";
//console.log('      preview: value.image: '+imageWidth+'x'+imageHeight+' -> '+width+'x'+height+' = '+value.image.style.width+'/'+value.image.style.height+' @'+view.x+','+view.y);
        }.bind(this));
//console.log('    < update # '+this.transform);
    };

}(window.olImageEditor = window.olImageEditor || {}, jQuery));
