(function(olACPViolations, $, undefined ) 
{    
    var debug = false;

    olACPViolations.closeViolationWithAjax = function(element)
    {
        var violationId         = $(element).attr('data-violation-id');

        console.log('violationId : ' + violationId + ' wird geschlossen!');

        $.ajax({
            type: "POST",
            url: '/acp/violation/violations/close',
            dataType: 'json',
            data: {
                violationId : violationId
            },
            success: function() { 
                console.log('Success of closeViolation');
                showClosedViolation(); 
            }
        });
    }


    olACPViolations.closeViolation = function(element)
    {
        var violationId        = $("[data-violation-id]:first").attr('data-violation-id');
        //var violationId2         = $(element).attr('data-violation-id');

        console.log('violationId  : ' + violationId  + ' wird geschlossen!');
        //console.log('violationId2 : ' + violationId2 + ' wird geschlossen!');

        $.post('/acp/violation/violations/close', {"violationId": violationId}, function(res) {
            console.log('Success of closeViolation');
            showClosedViolation(); 
        });
    }


    olACPViolations.wantToCloseViolation = function()
    {


        olMsgBox.questionById('confirmUserAttribute', function() { olACP.saveUserAttribute(userId,key); } , olACP.closeMsgBoxAndReturnToUser  , replacements, false);
    }



    function showClosedViolation()
    {
        //Redirect ?
        alert('Verstoß geschlossen!');
    }

    function reloadSameSite()
    {
        location.reload(true)
    }

    olACPViolations.reloadSameSite = function(result)
    {
        location.reload(true)
    }

    olACPViolations.rejectSuspiciousViolation = function(element)
    {
        var userId = $(element).attr('data-user-id');
        var identifierType = $(element).attr('data-identifier-type');
        var identifierValue = $(element).attr('data-identifier-value');

        $.ajax({
            type: "POST",
            url: '/acp/violation/suspiciousViolation/reject',
            dataType: 'json',
            data: {
                userId : userId,
                identifierType : identifierType,
                identifierValue : identifierValue
            },
            success: olACPViolations.reloadSameSite
        });


    }

    olACPViolations.rejectSuspicious = function(element)
    {
        var susId = $(element).attr('data-sus-id');

        console.log('susId : ' + susId + '!');

        $.ajax({
            type: "POST",
            url: '/acp/violation/suspicious/reject',
            dataType: 'json',
            data: {
                susId : susId
            },
            success: switchSuspiciousIcon
        });


    }

    function switchSuspiciousIcon()
    {



    }



    olACPViolations.myNewTrick = function()
    {
        //Neuen Tab öffnen


        //show Violating User


    }

}( window.olACPViolations = window.olACPViolations || {}, jQuery ));