"use strict";
(function(olTraining, $, undefined) {

    function showFirstStartInfo() {
        var $var = $('#training-showStartInfo');
        var show = (Number($var.val()) == 1);
        if (!show) return;

        olOverlayWindow.addCloseListener(function() {
            // set info shown setting
            var data = {name: 'training-firstStart-infoShown', value: 1};
            $.post('/user/settings/change', data, function(rc) {$var.val(1);});
        });
        olOverlayWindow.load('/team/training/info', undefined, function(rc) {
            $('#trainingInfoOverlay .training-info-visual-image').addClass('show');
        });
    }



    //--- Trainingsseite ---

    // Aktualisiert die komplette Trainingsseite (nach Wechsel des Trainingsplans)
    function updateTraining(data) {
        if (data === undefined) data = {};
        if (!('trainingScheduleId' in data) || (data.trainingScheduleId <= 0)) data.trainingScheduleId = $('#dropdownTrainingSchedule').dropdown().value();
        if (!('trainingComponentId' in data) || (data.trainingCompenentId <= 0)) data.trainingComponentId = $('#trainingEditSlotTypeDropdown').dropdown().value() || -1;
        if (!('minutes' in data) || (data.minutes <= 0)) data.minutes = $('#trainingSlotMinutesSlider').attr('data-value') || -1;
        if (!('day' in data) || !data.day) data.day = $('#training-timetable-day').val();
        if (!data.day) data.day = 'week_plan';

        var $anchor = $('#training-timetable-content');
        addLoadingAnimation($anchor);
        $("#ol-root").getLoad("/team/trainingview", data, function(rc) {
            removeLoadingAnimation($anchor);

            //updateTrainingContent(); // wird schon von olTraining.initTraining aufgerufen
        });
    };

    // Initialisierung nach Laden der Trainingsseite
    // Ohne LoadingAnimation
    olTraining.initTraining = function(state) {
        olTraining.state = state;
        // Timeline-Blocks: Minuten-Slider initialisieren
        $('.ol-training-timetable-block-slider-row .bootstrapSlider').bootstrapSlider({scale: 'linear', tooltip: 'hide'});
        // ^ noch nötig?

        // Wochenplan wird jetzt vorweg angezeigt!!!
        var day = timetableTabActive();
        if (day == 'week_plan') {
            updatePlayerDetails(showFirstStartInfo);

            // Höhe ggf. vergrößern, damit Samstag0-Spieleranzeige reinpasst
            adjustSaturdayHeight();

            // Popovers initialisieren: Sperrblöcke, Fehlermeldungen
            initializePopovers();
        } else {
            updateTrainingContent(showFirstStartInfo);
        }
        adjustTrainingContent();

        // Scrollarea initialisieren
        olScrollOverlay.create((day == 'week_plan')? $('#trainingWeektableContainer') : $('#trainingDaystableContainer'));

        // change to expertMode
        var expertMode = localStorage.getItem('expertMode');
        if (expertMode && expertMode === 'true') {
            olTraining.toggleCompact(true)
            $('#toggleTrainingCompact').attr('checked', true)
        }
    };


    // Enable/disable training execute button.
    // If execute is undefined/null then no change happens.
    function setTrainingExecuteButtonStatus(executeNow) {
        if (executeNow === undefined || executeNow === null) return;
        $('#trainingProcessStartBtn').toggleClass('deactivated', !executeNow);
    }


    // Aktualisiert Wochenplan/Tag und Details
    function updateTrainingContent(onsuccess) {
        // Anzeige aktualisieren:
        // - akt. Tab (Wochenplan/So..Mo)
        // - Spielerdetails
        var $anchor = $('#training-timetable-content');
        addLoadingAnimation($anchor);
        olTraining.timetableTabTo($('#training-timetable-day').val(), function() {
            updatePlayerDetails(function() {
                if (typeof onsuccess === 'function') { onsuccess(); removeLoadingAnimation($anchor); }
                else removeLoadingAnimation($anchor);
            });
        }, true);
    }

    // Aktualisiert Wochenplan/Tag, falls `day` oder week_plan aktiv, und Spielerdetails
    function updateTrainingContentIfDayOrWeekplan(day) {
        var $anchor = $('#training-timetable-content');
        addLoadingAnimation($anchor);
        timetableTabToIfDayOrWeekplan(day, function() {
            updatePlayerDetails(function() {
                removeLoadingAnimation($anchor);
            });
        });
    }

    // Ohne LoadingAnimation
    function updatePlayerDetails(onsuccess, type) {
        if (type == 'training_schedule') {
            updatePlayerDetailsSchedule(onsuccess);
        } else {
            updatePlayerDetailsResults(onsuccess);
        }
    }

    function updatePlayerDetailsSchedule(onsuccess) {
        var data = {trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value()};

        var openedIds = getOpenedPlayerDetailsIds();
        var posFilter = getPlayerDetailsFilter();

        var $area = $('#training-timetable-details');

        $.get('/team/training/details', data, function(rc) {
            // Inhalt setzen
            $area.html(rc);

            // Popovers initialisieren: Spieler-Trainingsintensität
            initializePopovers();

            // Filter wieder anwenden
            clickFilterPlayerDetailsBy(posFilter);

            // Offene Details wieder aufklappen => deaktiviert, LoadingAnimation bleibt stehen
            $(openedIds).each(function(i, id) { $(id).click(); });

            // Stick-Header-Status setzen
            adjustStickyHeader();

            if (typeof onsuccess === "function") onsuccess();
        }).fail(function() {
            console.log("*** updatePlayerDetails failed:", data);
        });
    };

    function updatePlayerDetailsResults(onsuccess) {
        var openedIds = getOpenedPlayerDetailsIds();
        var posFilter = getPlayerDetailsFilter();

        var $area = $('#training-timetable-details');
        var $anchor = $('#training-timetable-details');
        addLoadingAnimation($anchor);//,{size: 'medium'});
        $.get('/team/training/detailsTrainingResults', {}, function(rc) {
            // Inhalt setzen
            $area.html(rc);

            // Popovers initialisieren: Spieler-Trainingsintensität
            initializePopovers();

            // Filter wieder anwenden
            clickFilterPlayerDetailsBy(posFilter);

            // Offene Details wieder aufklappen => deaktiviert, LoadingAnimation bleibt stehen
            $(openedIds).each(function(i, id) { $(id).click(); });

            // Stick-Header-Status setzen
            adjustStickyHeader();

            removeLoadingAnimation($anchor);

            if (typeof onsuccess === "function") onsuccess();
        }).fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** updatePlayerDetailsTrainingResults failed");
        });
    };

    function getOpenedPlayerDetailsIds() {
        var ids = [];
        $('.ol-training-playerdetails-collapse').each(function(i, o) {
            var $o = $(o);
            if (!$o.hasClass('active-overview')) return;
            var playerId = $o.attr('data-playerId');
            ids.push('#playerTrainingToggle'+playerId);
        });
        return ids;
    }

    function getPlayerDetailsFilter() {
        var posFilter = $('#dropdownTrainingPlayerDetails').attr('data-value');
        posFilter = Number(posFilter);
        if (isNaN(posFilter)) posFilter = 504;
        return posFilter;
    }



    //--- Schedule ---

    olTraining.onSelectSchedule = function(event) {
        var element = event.target, $element = $(element);

        if ($element.parent().hasClass('premium-locked')) {
            return false;
        }

        var data = {
            trainingScheduleId: $element.attr('data-trainingScheduleId'),
            day: $('#training-timetable-day').val()
        };
        updateTraining(data);
    };

    olTraining.onBlurScheduleName = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingScheduleId: $element.attr('data-trainingScheduleId'),
            name: $element.val()
        };

        // Änderung?
        var $li = $("#liTrainingSchedule"+data.trainingScheduleId);
        if (data.name === $li.attr("data-name")) return;

        // Name zu kurz?
        if (data.name.length < 3) {
            olError($("#msgWrongNameSize").html());
            $element.val($li.attr("data-name"));
            return;
        }

        // Änderung abspeichern
        var $anchor = $element.parent();
        addLoadingAnimation($anchor);
        $.post("/team/training/schedule/rename", data, function(rc) {
            removeLoadingAnimation($anchor);
            var msg = (rc > 0)?   ''
                    : (rc == 0)?  $("#msgWrongName").html()
                    : (rc == -2)? $("#msgNoModifyStandardSchedule").html()
                    :             $("#msgTrainingLocked").html();
            if (msg) {
                olError(msg);
                $element.val($li.attr("data-name"));
            } else {
                $li.attr("data-name", data.name);
                $li.html(data.name);
                $("#dropdownTrainingSchedule").find(".ol-dropdown-text").html(data.name);
            }
        }, 'json').fail(function() {
            removeLoadingAnimation($anchor);
            olError($("#msgWrongName").html());
            $element.val($li.attr("data-name"));
        });
    };

    olTraining.onKeyScheduleName = function(event) {
        if (event.keyCode == 13) { //Enter
            event.preventDefault();
            olTraining.onBlurScheduleName(event);
        }
    };

    olTraining.onActivateSchedule = function(event) {
        var $cur = $('#dropdownTrainingSchedule');
        var active = ($cur.attr('data-active') == 1)? 1 : 0;
        var newActive = active? 0 : 1;

        var $element = $(event.target).closest('button');
        var data = {
            trainingScheduleId: $element.attr('data-trainingScheduleId'),
            active: newActive
        };

        var $li = $("#liTrainingSchedule"+data.trainingScheduleId);
        var $active = $('#trainingScheduleNameActive'+data.trainingScheduleId);

        var $anchor = $('button.ol-team-settings-line-up-header-friendly');
        addLoadingAnimation($anchor);
        $.post("/team/training/schedule/activate", data, function(rc) {
            removeLoadingAnimation($anchor);

            if (rc.err) {
                olError(rc.err);
                return;
            }
            if (rc.executeNow)
                olTraining.state.status = 'executeNow';
            // Aktiv-Anzeige setzen
            $('#dropdownTrainingSchedule li > a').attr('data-active', 0);

            $cur.attr("data-active", newActive);
            $li.attr("data-active", newActive);
            $active.attr("data-active", newActive);

            // "Trainingswoche ausführen"-Button (de)aktivieren
            setTrainingExecuteButtonStatus(rc.executeNow);

            // Aktions-Bereich muss aktualisiert werden
            timetableTabToIf('week_plan');

        }, 'json').fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** onActivateSchedule failed:", data);
        });
    }

    olTraining.onAddSchedule = function(event) {
        var element = event.target, $element = $(element);
        var data = {};

        var $root = $('#ol-root');
        var $anchor = $('#training-timetable-content');
        addLoadingAnimation($anchor);
        $.post("/team/training/schedule/add", data, function(rc) {
            removeLoadingAnimation($anchor);

            if (rc.err) {
                olError(rc.err);
            } else {
                $root.html(rc.html); // ruft olTraining.initTraining auf
                $('#trainingScheduleName').focus().select();
            }
        }).fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** onAddSchedule failed:", data);
        });
    };

    olTraining.onCopySchedule = function(event) {
        var element = event.target, $element = $(element);
        var data = {trainingScheduleId: $element.attr('data-trainingScheduleId')};

        var $root = $('#ol-root');
        var $anchor = $('#training-timetable-content');
        addLoadingAnimation($anchor);
        $.post("/team/training/schedule/copy", data, function(rc) {
            removeLoadingAnimation($anchor);

            if (rc.err) {
                olError(rc.err);
            } else {
                $root.html(rc.html); // ruft olTraining.initTraining auf
                $('#trainingScheduleName').focus().select();
            }
        }).fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** onCopySchedule failed:", data);
        });
    };

    olTraining.onDeleteSchedule = function(event) {
        var element = event.target, $element = $(element);
        var $cur = $('#dropdownTrainingSchedule');
        var active = ($cur.attr('data-active') == 1)? 1 : 0;
        var readonly = ($element.attr('data-readonly') == 1);
        var data = {
            trainingScheduleId: $element.attr('data-trainingScheduleId')
        };

        if (readonly) {
            olError($('#msgNoDeleteStandardSchedule').html());
            return;
        }

        if (active) {
            olError($cur.attr('data-msg-errActive'));
            return;
        }

        olConfirm(element, function() {
            var $anchor = $('#training-timetable-content');
            addLoadingAnimation($anchor);
            $.post("/team/training/schedule/delete", data, function(rc) {
                removeLoadingAnimation($anchor);

                if (rc.err) {
                    olError(rc.err);
                    return;
                }

                $('#ol-root').html(rc.html); // ruft olTraining.initTraining auf
            }).fail(function() {
                removeLoadingAnimation($anchor);
                console.log("*** onDeleteSchedule failed:", data);
            });
        });
    };

    // Checks if training is locked: if not then execute `action`, if yes then execute `undo`
    function ifNotTrainingLocked(action, undo) {
        $.get('/team/training/locked', {}, function(rc) {
            if (rc.locked) {
                olError(rc.err);
                if (typeof undo === 'function') undo();
            } else {
                if (typeof action === 'function') action();
            }
        }, 'json').fail(function() {
            console.log("*** ifNotTrainingLocked failed");
            if (typeof undo === 'function') undo();
        });
    }



    //--- Group ---

    olTraining.onEditGroup = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value(),
            trainingGroupId: $element.attr('data-trainingGroupId') || 0
        };

        editGroupWithData(data, $element);
    };

    // Sets height of area .ol-overlay-windows-scroll in order to limit
    // the overlay height to data-max-width (%/100) of visible area.
    // Take data-max-width (px) of scroll area into account if set.
    function resizeEditGroup() {
        var $area = $('.ol-overlay-window-scroll');
        if (!$area.length) return;

        var pct = Number($('#overlayOptions').attr('data-max-height')) || 0.95;
        var hmax = window.innerHeight*pct;

        $area.removeClass('fullsize');
        $area.css('height', 'auto');
        var harea0 = $area.height();

        var hmin = $area.attr('data-min-height') || 0;
        if (hmin > 0) $area.css('height', hmin);
        var harea = $area.height();

        var $window = $('.ol-overlay-window');
        var hw = $window.height();
        var h = (hw > hmax)? harea - (hw-hmax) : harea;
        $area.css('height', h);
        if (h >= harea0) $area.addClass('fullsize');

        $window.removeClass('scroll-partsize').removeClass('scroll-fullsize');
        $window.addClass((h >= harea0)? 'scroll-fullsize' : 'scroll-partsize');

        olOverlayWindow.reposition();
    }

    function editGroupWithData(data, $element) {
        olOverlayWindow.removeResizeListeners();
        olOverlayWindow.addResizeListener(resizeEditGroup);
        olOverlayWindow.load('/team/training/group/edit', data, function() {
            olTraining.initEditGroup(false);
        });
    };

    function editGroupWithDataOver(data) {
        olOverlayWindow.addLoadingAnimation();
        $.get('/team/training/group/edit', data, function(html) {
            olOverlayWindow.removeLoadingAnimation();
            olOverlayWindow.removeResizeListeners();
            olOverlayWindow.addResizeListener(resizeEditGroup);
            olOverlayWindow.setContent(html);
            olOverlayWindow.setTitleFromContent();
            olTraining.initEditGroup(false);
        });
    };



    //--- Group editor ---

    olTraining.onCloseEditGroup = function(event) {
        olOverlayWindow.close();
    };

    function saveEditGroup($element, closeOverlay, onsuccess) {
        if (closeOverlay === undefined) closeOverlay = true;
        var data = {
            trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value(),
            trainingGroupId: $('#dropdownTrainingEditGroup').dropdown().value(),
            name: $('#trainingEditGroupName').val(),
            playerIds: getSelectedEditGroupPlayers()
        };

        var $anchor = $element;
        addLoadingAnimation($anchor);
        $.post("/team/training/group/save", data, function(rc) {
            removeLoadingAnimation($anchor);

            if (rc.err) {
                olError(rc.err);
                return;
            }

            if (closeOverlay) olOverlayWindow.close();
            if (typeof onsuccess === 'function') onsuccess(data);
            //olTraining.updateTraining(rc); // nicht nötig
        }, 'json').fail(function() {
            removeLoadingAnimation($anchor);
            console.log("**** saveEditGroup failed:", data);
        });
    };

    olTraining.onSelectEditGroup = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value(),
            trainingGroupId: $element.attr('data-trainingGroupId'),
            context: $('#dropdownTrainingEditGroupContext').val()
        };
        selectEditGroup($element, data);
    };

    function selectEditGroup($element, data, selectName) {
        if (selectName === undefined) selectName = false;

        var $anchor = $element;
        addLoadingAnimation($anchor);
        $.get('/team/training/group/edit', data, function(rc) {
            removeLoadingAnimation($anchor);

            olOverlayWindow.setContent(rc);
            olTraining.initEditGroup(selectName);
        }).fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** selectEditGroup failed:", data);
        });
    };

    olTraining.onSelectEditGroupPlayer = function(event) {
        var element = event.target, $element = $(element);

        var playerId = $element.attr('data-playerId'),
            checked = $element.prop('checked');

        ifNotTrainingLocked(function() {
            if (playerId == 0) { // Alle
                var posFilter = Number($('#trainingGroupPositionFilter').dropdown().value());
                if (isNaN(posFilter)) posFilter = 511;
//                $('.ol-training-edit-group .group-col-cb').each(function(i, o)
                $('.ol-training-edit-group .squad-player-wrapper').each(function(i, o) {
                    var position = Number($(o).attr('data-position'));
                    var visible = (position & posFilter) != 0;
                    if (!visible) return;

                    var $cb = $('input[type="checkbox"]', o);
                    $cb.prop('checked', checked);
                });
                redistributeEditGroupPlayers();
            } else {
                relocateEditGroupPlayer(playerId);
            }

            // Änderung gleich speichern; Tag & Details aktualisieren
            saveEditGroup($element, false, updateTrainingContent);
        },
        function() {
            $element.prop('checked', !checked);
        });
    };

    // Anzeige im Gruppen-Editor initialisieren
    olTraining.initEditGroup = function(selectName) {
        if (selectName === undefined) selectName = true;

        // Spieler auf selektiert / nicht-selektiert verteilen
        olTraining.distributeEditGroupPlayers();

        // Gruppennamen selektieren
        if (selectName) $('#trainingEditGroupName').focus().select();

        // Fitness-Werte setzen
        olGUI.onClickDropdownPlayerAttribute(28, 5, '', $('#trainingEditGroup'), 'group-attr-fitness');

        // Schusstechnik-Werte setzen
        olGUI.onClickDropdownPlayerAttribute(20, 5, '', $('#trainingEditGroup'), 'group-attr-prop');

        // Fitness-Filter-Werte setzen (mobile
        olGUI.onClickDropdownPlayerAttribute(28, 5, '', $('#trainingEditGroup'), 'group-attr-prop-mobile');
    };

    function getSelectedEditGroupPlayers() {
        var selectedPlayerIds = [];
        $('.ol-training-edit-group .squad-player-wrapper').each(function(i, o) {
           var $cb = $('input[type="checkbox"]', o);
           if (!$cb.prop('checked')) return;
           selectedPlayerIds.push($cb.attr('data-playerId'));
        });
        return selectedPlayerIds;
    }

    // Spieler nach Erstanzeige auf die Bereiche aufteilen
    olTraining.distributeEditGroupPlayers = function() {
        $('#trainingEditGroup .group-row').each(function(i, o) {
            var $o = $(o);
            var playerId = $o.attr('data-playerId');
            var active = $('#trainingGroupPlayerCb'+playerId).prop('checked');
            var $target = $('#'+(active? 'active' : 'passive')+'GroupRows');
            $target.append($o);
        });
        alternateEditGroupPlayersBackground();
    };

    // Spielerzeilen abwächselnd einfärben
    // Und: Meldung "Keine Spieler in Gruppe" anzeigen/ausblenden
    function alternateEditGroupPlayersBackground() {
        // Spalten abwechselnd einfärben
        var stateId = $('#allGroupRows').attr('data-stateId');
        var cls = 'ol-state-color-light-'+stateId;
        var r = 0;
        var setBg = function(i, o) {
            var $o = $(o);
            $o.removeClass(cls);
            if (r % 2 == 0) $o.addClass(cls);
            r++;
        };
        // $('#activeGroupRows .group-row').each(setBg);
        // $('#passiveGroupRows .group-row').each(setBg);

        // Meldung "Keine Spieler in Grupp" anzeigen/ausblenden
        $('#activeGroupRowsNone').css('display', $('#activeGroupRows .group-row').length? 'none' : 'block');
    };

    // Alle Spieler nach Klick auf die Alles-Checkbox neue einsortieren
    function redistributeEditGroupPlayers() {
        $('#trainingEditGroup .group-row').each(function(i, o) {
           var $o = $(o);
           var playerId = $o.attr('data-playerId');
           relocateEditGroupPlayer(playerId);
        });
        alternateEditGroupPlayersBackground();
    };

    // Spieler playerId nein einsortieren
    function relocateEditGroupPlayer(playerId) {
        var $row = $('#trainingEditGroup .group-row[data-playerId="'+playerId+'"]');
        var index = Number($row.attr('data-index'));
        var active = $('#trainingGroupPlayerCb'+playerId).prop('checked');
        var $targetContainer = $('#'+(active? 'active' : 'passive')+'GroupRows');

        var $targetRows = $('.group-row', $targetContainer);
        var n = $targetRows.length;
        var inserted = false;
        for (var i = 0;  i < n;  i++) {
            var rowi = $targetRows[i], $rowi = $(rowi);
            var indexi = Number($rowi.attr('data-index'));
            if (indexi < index) continue;
            $rowi.before($row);
            inserted = true;
            break;
        }
        if (!inserted) {
            $targetContainer.append($row);
        }
        alternateEditGroupPlayersBackground();
    };

    olTraining.filterEditGroupByPosition = function(event) {
        var element = event.target, $element = $(element);
        var posFilter = $element.parent().attr('data-value');
        filterEditGroupBy(posFilter);
    };

    function filterEditGroupBy(posFilter) {
        posFilter = Number(posFilter);
        $('.ol-training-edit-group .group-row').each(function(i, o) {
            var $o = $(o);
            var position = Number($o.attr('data-position'));
            var visible = (position & posFilter) != 0;
            if (visible) $o.removeClass('hidden'); else $o.addClass('hidden')
        });
    };

    olTraining.onCopyEditGroup = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value(),
            trainingGroupId: $element.attr('data-trainingGroupId'),
            context: $('#dropdownTrainingEditGroupContext').val()
        };

        var $anchor = $element;
        addLoadingAnimation($anchor);
        $.post("/team/training/group/copy", data, function(rc) {
            removeLoadingAnimation($anchor);

            if (rc.err) {
                olError(rc.err);
                return;
            }

            // Neue Gruppe selektieren
            selectEditGroup($element, rc, true)

            // Tag & Details: keine Änderung möglich
        }, 'json').fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** onCopyEditGroup failed:", data);
        });
    };

    olTraining.onDeleteEditGroup = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value(),
            trainingGroupId: $element.attr('data-trainingGroupId'),
            context: $('#dropdownTrainingEditGroupContext').val()
        };

        olConfirm(element, function() {
            var $anchor = $element.parent();
            addLoadingAnimation($anchor);
            $.post("/team/training/group/delete", data, function(rc) {
                removeLoadingAnimation($anchor);

                if (rc.err) {
                    olError(rc.err);
                    return;
                }

                // Anzeige im Dialog aktualisieren
                selectEditGroup($element, rc);

                // Tag & Details aktualieren (Sets dieser Gruppe möglicherweise gelöscht)
                updateTrainingContent();
            }, 'json').fail(function() {
                removeLoadingAnimation($anchor);
                console.log("*** onDeleteEditGroup failed:", data);
            });
        });
    };

    olTraining.onAddEditGroup = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value(),
            trainingGroupId:$('#dropdownTrainingEditGroup').dropdown().value(),
            context: $('#dropdownTrainingEditGroupContext').val()
        };

        var $anchor = $element;
        addLoadingAnimation($anchor);
        $.post("/team/training/group/add", data, function(rc) {
            removeLoadingAnimation($anchor);

            if (rc.err) {
                olError(rc.err);
                return;
            }

            // Anzeige im Dialog aktualisieren
            selectEditGroup($element, rc, true);

            // Tag & Details: keine Änderung möglich
        }, 'json').fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** onAddEditGroup failed:", data);
        });
    };

    olTraining.onBlurEditGroupName = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingGroupId: $('#dropdownTrainingEditGroup').dropdown().value(),
            name: $element.val()
        };

        // Änderung?
        var oldName = $element.attr("data-name");
        if (data.name === oldName) return;

        // Name zu kurz?
        if (data.name.length < 3) {
            olMsgBox.msg($("#msgWrongNameHeadline").html(), $("#msgWrongNameSize").html());
            $element.val(oldName);
            return;
        }

        // Änderung abspeichern
        var $anchor = $element.parent();
        addLoadingAnimation($anchor);
        $.post("/team/training/group/rename", data, function(err) {
            removeLoadingAnimation($anchor);

            if (err) {
                olError(err);
                $element.val(oldName);
                return;
            }

            // Neuen Namen eintragen
            $element.attr("data-name", data.name);
            $li = $('#dropdownTrainingEditGroup li > a[data-trainingGroupId="'+data.trainingGroupId+'"]');
            $li.attr("data-name", data.name);
            $li.html(data.name);
            $("#dropdownTrainingEditGroup button")[0].firstChild.nodeValue = data.name;
            $('#trainingEditGroupDeleteButton').attr('data-name', data.name);

            // Wochenplan/Tag aktualisieren
            timetableTabToIfNotWeekplan();
        }, 'json').fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** Failed to change training group name:", data);
            olError($("#msgWrongName").html());
            $element.val(oldName);
        });
    };

    olTraining.onKeyEditGroupName = function(event) {
        if (event.keyCode == 13) { //Enter
            event.preventDefault();
            olTraining.onBlurEditGroupName(event);
        }
    };



    //--- Slot colors ---

    olTraining.onEditColors = function(event) {
        var element = event.target, $element = $(element);
        var data = {};

        olOverlayWindow.load('/team/training/slotcolors', data, function() {});
    };

    olTraining.onSelectSlotColor = function(event) {
        var element = event.target, $element = $(element);
        var componentId = $element.attr('data-componentId'),
            color = $element.attr('data-value');

        ifNotTrainingLocked(function() {
            var $button = $('#dropdown-color-'+componentId);
            $button.css('background-color', color);
            $button.attr('data-value', color);

            saveSlotColors($element, false);
        });
    };

    function getSelectedSlotColors() {
        var colors = {};
        $('.ol-team-training-dropdown-wrapper button.ol-dropdown-btn').each(function(i, o) {
            var $o = $(o);
            var componentId = $o.attr('data-componentId'),
                color = $o.attr('data-value');
            colors[componentId] = color;
        });
        return colors;
    }

    olTraining.onCloseSlotColors = function(event) {
        olOverlayWindow.close();
    };

    function saveSlotColors($element, closeOverlay) {
        if (closeOverlay === undefined) closeOverlay = false;
        var colors = getSelectedSlotColors();
        var data = {colors: colors};

        var $anchor = $element;
        addLoadingAnimation($anchor);
        $.post("/team/training/slotcolors/save", data, function(err) {
            removeLoadingAnimation($anchor);

            if (err) {
                olError(err);
                return;
            }

            if (closeOverlay) olOverlayWindow.close();

            // Anzeige aktualisieren
            updateTrainingContent($anchor);
        }, 'json').fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** onSaveSlotColors failed:", data);
        });
    }



    //--- Slot-Editor ---

    function resizeEditSlot() {
        if (!$('#trainingEditSlot').length) return;

        var teamSize = Number($('#user-teamSize').val());
        var playerHeight = 31, maxPlayersHeight = playerHeight * teamSize;
        var hasNotices = (Number($('#trainingEditSlot-hasNotices').val()) != 0);

        var $left = $('#ol-training-edit-slot-left'), left = $left[0];
        var $left1 = $('#ol-training-edit-slot-left1'), left1 = $left1[0];
        var $left2 = $('#ol-training-edit-slot-left2'), left2 = $left2[0];
        var $right = $('#ol-training-edit-slot-right'), right = $right[0];
        var $right1 = $('#ol-training-edit-slot-right1'), right1 = $right1[0];
        var $right2 = $('#ol-training-edit-slot-right2'), right2 = $right2[0];
        var $content = $('.training-slot-editor-overlay'), content = $content[0];

        $left2.removeClass('fullsize');
        $left2.css('height', 'auto');
        var left2Height0 = $left2.height();
        var left2MinHeight = Number($left2.attr('data-min-height')) || 0;
//        if (left2MinHeight > 0) $left2.css('min-height', left2MinHeight);

        $right2.removeClass('fullsize');
        $right2.css('height', 'auto');
        var right2MinHeight = Number($right2.attr('data-min-height')) || 0;
        var right2Height0 = $right2.height();
        if ($right2.height() < maxPlayersHeight) $right2.css('height', maxPlayersHeight+'px');
//        if (right2MinHeight > 0) $right2.css('min-height', right2MinHeight);
        var right2FullHeight = Math.max($right2.height(), maxPlayersHeight);

        var twoColumns = (right.offsetLeft > left1.offsetLeft);
//console.log("    twoColumns:", twoColumns);
//console.log("    left2MinHeight:", left2MinHeight);
//console.log("    right2MinHeight:", right2MinHeight);
//console.log("    right2FullHeight:", right2FullHeight);

        var pct = Number($('#overlayOptions').attr('data-max-height')) || 0.95;
        var maxOverlayHeight = window.innerHeight*pct; //hmax
        var overlayHeight = $('.ol-overlay-window').height();
        var contentHeight = $content.height();

        var frameHeight = overlayHeight - contentHeight;
        var heightDiff = maxOverlayHeight - overlayHeight;
        var maxContentHeight = maxOverlayHeight - frameHeight;// (heightDiff < 0)? contentHeight+heightDiff : contentHeight;
//console.log("\n    maxOverlayHeight:", maxOverlayHeight);
//console.log("      maxContentHeight:", maxContentHeight);
//console.log("    overlayHeight:", overlayHeight);
//console.log("      contentHeight:", $content.height());
//console.log("        leftHeight:", $left.height());
//console.log("          left1Height:", $left1.height());
//console.log("          left2Height:", $left2.height());
        var leftPad = $left.height() - $left1.height() - $left2.height();
//console.log("          leftPad:", leftPad);
//console.log("        rightHeight:", $right.height());
//console.log("          right1Height:", $right1.height());
//console.log("          right2Height:", $right2.height());
//console.log("          right2FullHeight:", right2FullHeight);
        var rightPad = $right.height() - $right1.height() - $right2.height();
//console.log("          rightPad:", rightPad);
//console.log("        frameHeight:", frameHeight);
//console.log("    heightDiff:", heightDiff);

        $content.removeClass('onecolumn twocolumn');
        if (twoColumns) {
            $content.addClass('twocolumn');
            if (heightDiff < 0) { //too high
//console.log("left2Height0:", left2Height0);
                var h = maxContentHeight - leftPad - $left1.height();
                if (h > left2Height0) h = left2Height0;
                if (!hasNotices) h = 0;
                $left2.height(h);
                if (h >= left2Height0) $left2.addClass('fullsize');
//console.log("left2:", h, (h >= left2Height0)? 'fullsize' : '');

//console.log("right2Height0:", right2Height0);
                var h = maxContentHeight - rightPad - $right1.height();
                if (h > right2FullHeight) h = right2FullHeight;
                $right2.height(h);
                if (h >= right2Height0) $right2.addClass('fullsize');
//console.log("right2:", h, (h >= right2Height0)? 'fullsize' : '');
            } else {
                $left2.addClass('fullsize');
                $right2.addClass('fullsize');
            }

            var scrollClass = $right2.hasClass('fullsize')? 'scroll-fullsize' : 'scroll-partsize';
            $('.ol-overlay-window').removeClass('scroll-fullsize')
                                   .removeClass('scroll-partsize')
                                   .addClass(scrollClass);
        } else {
            $content.addClass('onecolumn');
            $left2.height('auto');
            $left2.addClass('fullsize');
            $right2.height('auto');
            $right2.addClass('fullsize');
        }

        olOverlayWindow.reposition();
    }

    olTraining.initEditSlot = function() {
        // Minuten-Slider initialisieren
        initMinutesSlider($('#trainingSlotMinutesSlider'), setEditSlotMinutesTitle, changeEditSlotMinutes);

        // Intensität-Property setzen
        // %

        // Player-Attribute initialisieren für Fitness
        olGUI.onClickDropdownPlayerAttribute(28, 5, '', $('#trainingEditSlot'), 'group-attr-prop');

        // Overlay ggf. als invalid markieren für Styling des Headers
        updateOverlayHeaderIcon();

        // Größenanpassung der Infos-Area
        olOverlayWindow.addResizeListener(resizeEditSlot);

        // Popovers initialisieren
        initializePopovers('.training-slot-editor-popover');
    };

    function updateOverlayHeaderIcon() {
        var status = Number($('#trainingEditSlot-status').val());
        var invalid = (status != 0);

        // Overlay ggf. als invalid markieren für Styling des Headers
        $('.ol-overlay-window-header-icon').remove();
        if (invalid) {
            var icon = '<span class="ol-overlay-window-header-icon"><div class="overlayTitle-icon icon-icon_injury_square"></div></span>';
            $('.ol-overlay-window-header-title').after(icon);
        }
    }

    function updateEditSlotStatus(trainingSetId) {
        var data = {trainingSetId: trainingSetId};

        $.get("/team/training/slot/status", data, function(rc) {
	        // Status-Wert eintragen
	        var invalid = (rc.status != 0);
	        $('#trainingEditSlot-status').val(rc.status);
            $('#trainingEditSlot-hasNotices').val(rc.hasNotices);

	        // Invalid-Markierung im Slot-Editor setzen
	        var $editSlot = $('#trainingEditSlot');
	        $editSlot.removeClass('trainingEditSlot-invalid');
	        if (invalid) $editSlot.addClass('trainingEditSlot-invalid');

	        // [!]-Icon im Overlay-Header setzen
	        $('.ol-overlay-window-header-icon').remove();
	        if (invalid) {
	            var icon = '<span class="ol-overlay-window-header-icon"><div class="overlayTitle-icon icon-icon_injury_square"></div></span>';
	            $('.ol-overlay-window-header-title').after(icon);
	        }

            // Warnungs-Anzeige aktualisieren
            $('#trainingEditSlotWarnings').html(rc.warnings);

            // Info-Anzeige aktualisieren
            $('#trainingEditSlotInfos').html(rc.infos);

            // Größenanpassung von Spielerliste und Info-Area
            resizeEditSlot();

            // Minuten-Slider aktualisieren: max. ggf. neusetzen
            var $slider = $('#trainingSlotMinutesSlider');
            $slider.attr('data-slider-max', rc.maxMinutes);
            $('#trainingSlotMinutesSliderMax').text(rc.maxMinutesFmt);
            $slider.data('bootstrapSlider').options.max = rc.maxMinutes;
            $slider.bootstrapSlider('setValue', $slider.bootstrapSlider('getValue'));
        }, 'json').fail(function() {
            console.log("*** updateEditSlotStatus failed:", data);
        });
	}

    function updateEditSlotPartialGroup() {
        var $btn = $('#trainingEditSlotGroupDropdown > button');
        var isPartial = ($('.training-slot-editor-cell-cb input[type="checkbox"]:not(:checked)').length > 0);
        $btn.removeClass('training-edit-slot-partial-group');
        $('#partialTeamInfo').removeClass('training-edit-slot-partial-group');
        if (isPartial) {
            $btn.addClass('training-edit-slot-partial-group');
            $('#partialTeamInfo').addClass('training-edit-slot-partial-group');
        }
    }

    olTraining.onSelectEditSlotComponentType = function(element) {
        var $element = $(element);
        var data = {
            trainingSetId: $('#trainingEditSlot-trainingSetId').val(),
            trainingComponentId: $element.attr('data-value'),
            staffId: -1,
            trainingGroupId: -1,
            minutes: -1,
            justCreated: $('#trainingEditSlot-justCreated').val()
        };
        saveSlotWithData(data, $element, false, true);
    };

    olTraining.onSelectEditSlotStaff = function(event) {
        var element = event.target, $element = $(element);
        var $a = $element.closest('a');
        var data = {
            trainingSetId: $('#trainingEditSlot-trainingSetId').val(),
            trainingComponentId: -1,
            staffId: $a.attr('data-value'),
            trainingGroupId: -1,
            minutes: -1,
            justCreated: $('#trainingEditSlot-justCreated').val()
        };

        ifNotTrainingLocked(function() {
            saveSlotWithData(data, $element, false, false, function() {
                updateEditSlotStatus(data.trainingSetId);
            });
            selectEditSlotStaffDropdownFrom($a);
        });
    };

    olTraining.onSelectEditSlotGroupPlayer = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingSetId: $('#trainingEditSlot-trainingSetId').val(),
            playerId: $(element).attr('data-playerId'),
            playerExcepted: $(element).prop('checked')? 0 : 1
        };

        ifNotTrainingLocked(function() {
            var $anchor = $element;
            addLoadingAnimation($anchor);
            $.post("/team/training/slot/exceptplayer", data, function(err) {
                removeLoadingAnimation($anchor);

                if (err) {
                    olError(err);
                    return;
                }

                // Warnungen, maxMinutes etc. könnten sich geändert haben
                updateEditSlotStatus(data.trainingSetId);
                updateEditSlotPartialGroup();

                // Anzeige aktualisieren
                updateTrainingContent();
            }).fail(function() {
                removeLoadingAnimation($anchor);
                console.log("*** onSelectSlotGroupPlayer failed:", data);
            });
        },
        function() {
            $element.prop('checked', !$element.prop('checked'));
        });
    };

    olTraining.onSelectEditSlotGroup = function(event) {
        var element = event.target, $element = $(element);

        var data = {
            trainingSetId: $('#trainingEditSlot-trainingSetId').val(),
            trainingComponentId: -1,
            staffId: -1,
            trainingGroupId: $element.attr('data-trainingGroupId'),
            minutes: -1,
            justCreated: $('#trainingEditSlot-justCreated').val()
        };

        saveSlotWithData(data, $element, false, true);
    };

    function setEditSlotMinutesTitle($element, minutes) {
        // Neuen Wert oben anzeigen
        var $value = $('#trainingSlotMinutesSliderValue');
        var text = $value.text().replace(/^\S+ (.*)$/, minutes+" $1");
        $value.html(text);
    }

    function changeEditSlotMinutes($element, minutes) {
        setEditSlotMinutesTitle($element, minutes);
        saveSlot($element, false, false);
        ifNotTrainingLocked(function() { $element.data('saveValue')(); });
    }

    olTraining.onCloseEditSlot = function(event) {
        olOverlayWindow.forceClose();

        var justCreated = ($('#trainingEditSlot-justCreated').val() == 1);
        if (justCreated) {
            // Bei Erst-Aufruf ist Update nötig, weil Slot möglicherweise unverändert gelassen
            updateTrainingContent(); //jetzt wieder nötig
        }
    };

    function saveSlot($element, closeOverlay, updateEditSlot) {
        var $slider = $('#trainingSlotMinutesSlider');
        var data = {
            trainingSetId: $element.attr('data-trainingSetId'),
            // trainingComponentId: $('#trainingEditSlotTypeDropdown').dropdown().value(),
            trainingComponentId: $('#trainingTypeSelection').attr('data-value'),
            staffId: -1,
            trainingGroupId: $('#trainingEditSlotGroupDropdown').dropdown().value(),
            minutes: $slider.attr('data-value'), //$('#trainingSlotMinutesSlider').attr('data-value'),
            justCreated: $('#trainingEditSlot-justCreated').val()
        };
        saveSlotWithData(data, $element, closeOverlay, updateEditSlot, function() {
            updateEditSlotStatus(data.trainingSetId);
            ifNotTrainingLocked(null, $slider.data('restoreValue'));
        });
    };

    function saveSlotWithData(data, $element, closeOverlay, updateEditSlot, onsuccess) {
        if (closeOverlay === undefined) closeOverlay = false;
        if (updateEditSlot === undefined) updateEditSlot = true;

        if (!('justCreated' in data)) data.justCreated = 0;
        data.justCreated = (Number(data.justCreated) == 1)? 1 : 0;

        var $anchor = $element;
        addLoadingAnimation($anchor);
        $.post("/team/training/slot/save", data, function(err) {
            removeLoadingAnimation($anchor);

            if (err) {
                olError(err);
                if (typeof onsuccess === 'function') onsuccess();
                return;
            }

            if (closeOverlay) olOverlayWindow.close();

            // Anzeige im Overlay aktualisiern
            if (updateEditSlot) olTraining.updateEditSlot($element);

            // Anzeige im Hintergrund aktualisieren
            updateTrainingContent();

            if (typeof onsuccess === 'function') onsuccess();
        }, 'json').fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** saveSlotWithData failed:", data);
        });
    };

    olTraining.updateEditSlot = function($element) {
        if ($element === undefined) $element = $('#trainingEditSlotTypeDropdown');
        var data = {
            trainingSetId: $('#trainingEditSlot-trainingSetId').val(),
            justCreated: $('#trainingEditSlot-justCreated').val()
        };

        ifNotTrainingLocked(function() {
            var $anchor = $element;
            addLoadingAnimation($anchor);
            $.get('/team/training/slot/edit', data, function(rc) {
                removeLoadingAnimation($anchor);
                olOverlayWindow.setContent(rc);
                olTraining.initEditSlot();
            }).fail(function() {
                removeLoadingAnimation($anchor);
                console.log("*** updateEditSlot failed:", data);
            });
        });
    };

    olTraining.onEditEditSlotGroup = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value(),
            trainingSetId: $('#trainingEditSlot-trainingSetId').val(),
            trainingGroupId: $('#trainingEditSlotGroupDropdown').dropdown().value(),
            justCreated: $('#trainingEditSlot-justCreated').val(),
            context: 'sloteditor'
        };

        olOverlayWindow.closeDisabled = true; // Overlay nicht entfernen...
        olOverlayWindow.addCloseListener(function() { // ...danach wieder Slot-Editor anzeigen
            skipDiscardEditSlot = false;
            var newTrainingGroupId = $('#dropdownTrainingEditGroup').dropdown().value();
            if (newTrainingGroupId > 0 && newTrainingGroupId != data.trainingGroupId) {
                // Geänderte Gruppe übernehmen und danach Slot-Editor anzeigen
                var dataNew = {
                    trainingSetId: data.trainingSetId,
                    trainingComponentId: -1,
                    staffId: -1,
                    trainingGroupId: newTrainingGroupId,
                    minutes: -1,
                    justCreated: data.justCreated,
                    context: 'sloteditor'
                };
                saveSlotWithData(dataNew, $element, false, false, function() {
                    editSlotWithDataOver(dataNew);
                });
            } else {
                // Slot-Editor gleich anzeigen
                editSlotWithDataOver(data);
            }
        });

        // Jetzt Gruppen-Editor aufrufen
        skipDiscardEditSlot = true;
        editGroupWithDataOver(data);
    };

    function selectEditSlotStaffDropdownFrom($a) {
        var staffId = $a.attr('data-value');
        var staffType = $('.training-slot-dropdown-staff-type', $a).html();
        var staffName = $('.training-slot-dropdown-staff-name', $a).html();
        selectEditSlotStaffDropdown(staffId, staffType, staffName);
    }

    function selectEditSlotStaffDropdown(staffId, staffType, staffName) {
        $('#trainingEditSlotStaffDropdown').attr({"data-value": staffId, "data-type": staffType});
        $('#trainingEditSlotStaffDropdown > button.dropdown-toggle .training-slot-dropdown-staff-name').html(staffName);
        $('#trainingEditSlotStaffDropdown > button.dropdown-toggle .training-slot-dropdown-staff-type').html(staffType);
    }

    olTraining.onDiscardEditSlot = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingSetId: $element.attr('data-trainingSetId')
        };
        discardEditSlot(data, $element);
    };

    function discardEditSlot(data, $element) {
//        var data = {trainingSetId: trainingSetId};

        var $anchor = $element;
        addLoadingAnimation($anchor);
        $.post("/team/training/slot/delete", data, function(rc) {
            removeLoadingAnimation($anchor);

            if (rc.err) {
                olError(rc.err);
                return;
            }

            olOverlayWindow.forceClose();
            updateTrainingContent();
            setTrainingExecuteButtonStatus(rc.executeNow);

        }, 'json').fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** discardEditSlot failed:", data);

            olOverlayWindow.forceClose();
            updateTrainingContent(); //~
        });
    };



    //--- Week plan ---

    olTraining.onEditWeektableBlock = function(event, el) {
        if (typeof el == 'undefined') {
            var element = event.target, $element = $(element);
            var $btn = $element.closest('.ol-training-weektable-block');
        } else {
            var $btn = $(el);
        }
        var day = $btn.attr('data-day');
        var trainingSetId = $btn.attr('data-trainingSetId');
        var playerId = $btn.attr('data-playerId');

        var data = {
            trainingSetId: trainingSetId,
            playerId: playerId,
        };
        editSlotWithData(data, $element);
    }

    function adjustSaturdayHeight() {
        var $cont = $('#trainingWeektableContainer');
        var $s0 = $('.ol-training-day[data-day="saturday0"]');
        var $s = $('.ol-training-day[data-day="saturday"]');
        var $f = $('.ol-training-day[data-day="friendly"]');
        var hcont = $cont.height();

        var $col0 = $('.ol-training-day-column', $s0);
        $col0.css('height', '');
        var h0 = 34+$col0.height();

        var $col = $('.ol-training-day-column', $s);
        $col.css('height', '');
        var h = 34+$col.height();

        var $colF = $('.ol-training-day-column', $f);
        //$colF.css('height', '');
        var hF = 34+$colF.height();

        var hnew = Math.max(hcont, h0+20, h+20, hF+20);
        $cont.height(hnew);
    }



    //--- Day plan ---

    olTraining.onEditTimetableBlock = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingSetId: $element.attr('data-trainingSetId')
        };
        editSlotWithData(data, $element);
    };

    var skipDiscardEditSlot = false;
    function editSlotWithData(data, $element) {
        if (!('justCreated' in data)) data.justCreated = 0;
        data.justCreated = (Number(data.justCreated) == 1)? 1 : 0;
        if (data.justCreated) {
            // Klick auf "x" behandeln wie "Verwerfen"
            olOverlayWindow.addResizeListener(resizeEditSlot);
            olOverlayWindow.closeDisabled = true;
            olOverlayWindow.addCloseListener(function() {
                if (skipDiscardEditSlot) return;
                discardEditSlot(data, $element);
            });
        }
        olOverlayWindow.load('/team/training/slot/edit', data, function() {
            olTraining.initEditSlot();
            olOverlayWindow.resize();
        });
    };

    function editSlotWithDataOver(data) {
        olOverlayWindow.addLoadingAnimation();
        $.get('/team/training/slot/edit', data, function(html) {
            olOverlayWindow.removeLoadingAnimation();
            olOverlayWindow.addResizeListener(resizeEditSlot);
            olOverlayWindow.setContent(html);
            olOverlayWindow.setTitleFromContent();
            olTraining.initEditSlot();
        });
    };

    olTraining.onDeleteTimetableBlock = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingSetId: $element.attr('data-trainingSetId')
        };
        var day = $('#training-timetable-day').val();

        olConfirm(element, function() {
            var $anchor = $element;
            addLoadingAnimation($anchor);
            $.post("/team/training/slot/delete", data, function(rc) {
                removeLoadingAnimation($anchor);

                if (rc.err) {
                    olError(rc.err);
                    return;
                }

                // Anzeige aktualisieren
                updateTrainingContentIfDayOrWeekplan(day); // Details & ggf. akt. Tab
                setTrainingExecuteButtonStatus(rc.executeNow);

            }, 'json').fail(function() {
                removeLoadingAnimation($anchor);
                console.log("*** onDeleteTrainingSlot failed:", data);
            });
        });
    };

    function setTimetableMinutesTitle($element, minutes) {
        // Neuen Wert oben anzeigen
        var trainingSetId = $element.attr('data-trainingSetId');
        var $value = $('#trainingTimetableBlockSliderValue-'+trainingSetId);
        var text = $value.html().replace(/^\S+ (.*)$/, minutes+" $1")
        $value.html(text);
    }

    function changeTimetableBlockMinutes($element, minutes) {
        var data = {
            trainingSetId: Number($element.attr('data-trainingSetId')),

            trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value(),
            trainingGroupId: -1,
            trainingComponentId: -1,
            staffId: -1,
            minutes: $element.attr('data-value') //=minutes
        };
        var day = $element.attr('data-day').toLowerCase();

        // Änderung speichern und Timeline aktualisieren
        var $anchor = $element;
        addLoadingAnimation($anchor);
        $.post("/team/training/slot/save", data, function(err) {
            removeLoadingAnimation($anchor);

            if (err) {
                olError(err);
                $element.data('restoreValue')();
                return;
            }

            $element.data('saveValue')();

            // Anzeige im Hintergrund aktualisieren
            updateTimetableDay(day, updatePlayerDetails);
        }, 'json').fail(function() {
            removeLoadingAnimation($anchor);
            console.log("onChangeTimetableBlockMinutes failed //data:", data);
        });
    };

    olTraining.onAddTimetableBlock = function(event) {
        var element = event.target, $element = $(element);
        var $btn = $element.closest('.ol-training-timetable-block');
        var disabled = (Number($btn.attr('data-disabled')) == 1);
        if (disabled) return;  // during drag & drop
        var data = {
            trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value(),
            trainingGroupId: 0,
            day: $btn.attr('data-day'),
            row: $btn.attr('data-row'),
            infrastructureId: $btn.attr('data-infrastructureId'),
            half: $btn.attr('data-half'),
            staffId: $btn.attr('data-staffId')
        };
        if (!data.infrastructureId) data.infrastructureId = 0;
        if (!data.staffId) data.staffId = 0;

        addSlotWithData(data, $element);
    };

    function addSlotWithData(data, $element) {
        var $anchor = $element;
        addLoadingAnimation($anchor);
        $.post("/team/training/slot/add", data, function(rc) {
            removeLoadingAnimation($anchor);

            if (rc.err) {
                olError(rc.err);
                return;
            }

            var trainingSetId = Number(rc.trainingSetId);

            // Bearbeitung öffnen
            rc.justCreated = 1;
            editSlotWithData(rc, $('#timetableBlock-'+trainingSetId));

            // Anzeige hier noch nicht aktualisieren (08.11.18 AK)
//            updateTrainingContentIfDayOrWeekplan(day); // nur aktualisieren, wenn akt. Tab
            setTrainingExecuteButtonStatus(rc.executeNow);

        }, 'json').fail(function() {
            removeLoadingAnimation($anchor);
            console.log("addTrainingSlot failed //data:", data);
        });
    };


    olTraining.forbidDnd = function(event) {
        if (event.button != 0) return;
        var $parent = $(event.target).closest('.event-parent');
        if (!$parent.length) return;
        if ($parent.hasClass('event-skip')) return;
        olError($('#msgNoModifyStandardScheduleExt').html());
    };



    //--- Tabs (week/days) ---

    olTraining.scrollToTimetable = function() {
        var o = document.querySelector('.ol-content-after-league-nav-training');
        if (!o) return;
        o.scrollIntoView();
    };

    // Liefert den Tag des aktuellen Tabs
    function timetableTabActive() {
        // var day = $('.training-timetable-head > button.active').attr('data-day');
        var day = $('#training-timetable-day').val();
        if (!day) day = 'week_plan';
        if (day == 'saturday') {
            var saturdayPresent = ($('#trainingDays > button[data-day="saturday"]').length > 0);
            if (!saturdayPresent) day = 'week_plan';
        }
        return day;
    };

    // Akt. Tab aktualisieren, falls `day` aktiv ist.
    function timetableTabToIf(day, onsuccess) {
        if (timetableTabActive() != day) {
            if (typeof onsuccess === 'function') onsuccess();
        } else {
            olTraining.timetableTabTo(day, onsuccess, false);
        }
    }

    // Akt. Tab aktualisieren, falls `day` oder week_plan aktiv ist.
    // Ohne LoadingAnimation
    function timetableTabToIfDayOrWeekplan(day, onsuccess) {
        var cur = timetableTabActive();
        if ((cur == day) || (cur == 'week_plan')) {
            olTraining.timetableTabTo(undefined, onsuccess, false);
        } else {
            if (typeof onsuccess === 'function') onsuccess();
        }
    }

    function timetableTabToIfNotWeekplan(onsuccess, activate) {
        if (timetableTabActive() == 'week_plan') {
            if (typeof onsuccess === 'function') onsuccess();
        } else {
            olTraining.timetableTabTo(undefined, onsuccess, activate);
        }
    }

    // Unbedingt Tab für `day` aktivieren und aktualisieren
    olTraining.timetableTabToActivate = function(day, onsuccess) {
        olTraining.timetableTabTo(day, onsuccess, true);
    };

    olTraining.timetableTabTo = function(day, onsuccess, activate) {
        if (!day) day = timetableTabActive();
        if (activate === undefined) activate = false;

        // Button/Content switchen
        $('.training-timetable-tab').each(function(i, o) {
           var $o = $(o);
           var show = ($o.attr('id') == 'training-timetable-'+day);
           if (show) {
                $o.removeClass('hidden');
           } else {
                $o.addClass('hidden')
           }
        });

        
        $('#training-timetable-day').val(day);
        
        if (activate) {
            $('.ol-tab-button[data-day="'+day+'"]').click();
            // $('.training-timetable-head > button.active').removeClass('active');
            // $('.training-timetable-head > button[data-day="'+day+'"]').addClass('active');
        }

        // Inhalt holen
        updateTimetableDay(day, onsuccess);
    };

    // Ohne LoadingAnimation
    function updateTimetableDay(day, onsuccess) {
        var data = {
            trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value(),
            day: day.toLowerCase()
        };

        var $content = $('#training-timetable-'+day);

        $.get("/team/training/timeline/day", data, function(rc) {
            // Tages-Anzeige aktualisieren
            $content.html(rc);

            // Timeline-Blocks: Minuten-Slider initialisieren
            var readonly = (Number($('#trainingSchedule-readonly').val()) == 1);
            $('.ol-training-timetable-block-slider-row .bootstrapSlider').each(function(i, o) {
                var $o = $(o);
                initMinutesSlider($o, setTimetableMinutesTitle, changeTimetableBlockMinutes, readonly);
            });

            // Sperrblöcke: Popover initialisieren
            initializePopovers();

            // Drag & Drop initialisieren
            if (day != 'week_plan' && !readonly) olDnd.init(day);

            // Höhe ggf. vergrößern, damit Samstag0-Spieleranzeige reinpasst
            if (day == 'week_plan') adjustSaturdayHeight();

            // Scrollarea initialisieren
            var $weekScrollContent = $('#trainingWeektableContainer'),
                $dayScrollContent = $('#trainingDaystableContainer');
            if (day == 'week_plan') {
                olScrollOverlay.hideScrollArea($dayScrollContent);
                olScrollOverlay.create($weekScrollContent);
            } else {
                olScrollOverlay.hideScrollArea($weekScrollContent);
                olScrollOverlay.create($dayScrollContent);
            }

            // onSuccess-Callback ausführen
            if (typeof onsuccess === 'function') onsuccess();

            olAds.loadAxContainers(true);
        }).fail(function() {
            console.log("*** updateTimetableDay failed:", data);
        });
    };



    //--- Drag & drop ---

    var olDnd = {
        _stopAfter: '', // 'move', 'drag', 'drop', ''=normal function
        _finishAnimation: false, // perform animation?

        init: function(day) {
            this.day = day;
            var sourceSelector = '.ol-training-timetable-block.draggable';
            var destSelector = '.ol-training-timetable-block.droppable';
            this.containmentSelector = '#training-timetable-'+day;
            this.scrollContentSelector = '#trainingDaystableContainer';

            this.getItems();

            $(sourceSelector).draggable({
                //containment: this.containmentSelector, // limits move to the right!!!!!
                refreshPositions: true,

                start: this.dragstart.bind(this),
                stop: this.dragstop.bind(this)
            });
            $(sourceSelector).css('cursor', 'grab');

            $(destSelector).droppable({
                accept: sourceSelector,

                over: this.dropover.bind(this),
                out: this.dropout.bind(this)
            });
        },


        getItems: function() {
            var day = this.day;

            // Collect items
            var $rows = $('.training-timetable-tab[data-day="'+day+'"] .ol-training-table-row');
            var items = Object.create(null);
            for (var r = 0;  r < $rows.length;  r++) {
                var row = $rows[r];
                var firstSet = false;
                var slots = $('.ol-training-timetable-block', row).get()
                var n = slots.length;
                for (var s = 0;  s < n;  s++) {
                    var slot = slots[s], $slot = $(slot);
                    var rowId = $slot.attr('data-rowId');
                    var trainingSetId = Number($slot.attr('data-trainingSetId'));
                    if (isNaN(trainingSetId)) return; // ?
                    if (!(rowId in items)) items[rowId] = [];
                    var type = $slot.attr('data-type');
                    var isFirst = !firstSet && (type == 'block');
                    if (isFirst) firstSet = true;
                    items[rowId].push({
                        index: s,
                        dropIndex: s, // drop position (different for lockblocks)
                        type: type,
                        widthAbs: Number($slot.attr('data-widthAbs')),
                        width: Number($slot.attr('data-width')),
                        trainingSetId: trainingSetId,
                        infrastructureId: Number($slot.attr('data-infrastructureId')) || 0,
                        half: Number($slot.attr('data-half')),
                        priority: Number($slot.attr('data-priority')) || 0,
                        day: day,
                        rowId: rowId,
                        $slot: $slot,
                        $wrapper: $slot.parent(),
                        $afterWrapper: null,
                        first: isFirst, // first with type='block'
                        last: false, // last with type='block'
                        lastInRow: (s == n-1),
                        validTarget: (type == 'block')
                    });
                }

                var lastItem = items[rowId][n-1];
                if (lastItem.type == 'block') {
                    // Add dummy item
                    items[rowId].push({
                        index: n,
                        dropIndex: n,
                        type: '',
                        widthAbs: 0,
                        width: 0,
                        trainingSetId: -1,
                        infrastructureId: lastItem.infrastructureId,
                        half: lastItem.half,
                        priority: 0,
                        day: day,
                        rowId: rowId,
                        $slot: null,
                        $wrapper: null,
                        $afterWrapper: lastItem.$wrapper,
                        first: false,
                        last: false,
                        lastInRow: false,
                        validTarget: false
                    });
                }
            }

            // Complete item properties (last, left, dropIndex)
            for (var rowId in items) {
                var dx = 0;
                var firstBlockIndex = -1;
                var lastBlockIndex = -1;
                for (var i = 0;  i < items[rowId].length;  i++) {
                    items[rowId][i].left = dx;
                    dx += items[rowId][i].width;
                    if (items[rowId][i].type == 'block') {
                        if (firstBlockIndex < 0) firstBlockIndex = i;
                        lastBlockIndex = i;
                    }
                }
                if (lastBlockIndex >= 0) items[rowId][lastBlockIndex].last = true;

                for (var i = 0;  i < items[rowId].length;  i++) {
                    if (items[rowId][i].type == 'lockblock') items[rowId][i].dropIndex = firstBlockIndex;
                }
            }

            // Remember props
            this.day = day;
            this.items = items;
        },

        findItem: function($slot) {
            if (!$slot || !$slot.length) return null;
            var targetId = $slot.attr('id');
            for (var rowId in this.items) {
                for (var i in this.items[rowId]) {
                    var item = this.items[rowId][i];
                    if (item.$slot && item.$slot.attr('id') == targetId) return item;
                }
            }
            return null;
        },

        getItem: function(rowId, index) {
            if (!rowId) return null;
            if (index === undefined) {
                index = rowId[1];
                rowId = rowId[0];
            }
            return this.items[rowId][index] || null;
        },


        updateValidTargets: function(curRowId) {
            for (var rowId in this.items) {
                var row = this.items[rowId];
                for (var i = 0;  i < row.length;  i++) {
                    var item = row[i];
                    if (item.type == 'addblock') {
                        item.validTarget = (item.rowId != curRowId); // not in same row
                    } else if (item.type == 'lockblock') {
                        item.validTarget = (item.rowId != curRowId); // not in same row
                    }
                }
            }
        },


        dragstart: function(event, ui) {
            this.$source = $(event.target);
            var $that = this;
            //TODO: Use sourceItem instead of this.$source

            var sourceItem = this.findItem(this.$source);
            this.fromRowId = this.toRowId = sourceItem.rowId;
            this.fromIndex = this.toIndex = sourceItem.index;
            //TODO: Use from/toItem instead of from/toRowId & from/toIndex

            // Mark container for drag state
            $(this.containmentSelector).addClass('dnd-running');

            // Disable addblocks during drag & drop
            $('.ol-training-timetable-addblock-wrapper .ol-training-timetable-block').attr('data-disable', 1);

            // Hide scroll bars during drag & drop
            olScrollOverlay.disable($('#trainingDaystableContainer'));

            // Mark items that are valid targets
            sourceItem.validTarget = false; // not on me
            this.updateValidTargets(sourceItem.rowId); // only addblocks in other rows

            // Style dragged slot as moving
            this.$source.addClass('slot-drag-move');
        },

        dragstop: function(event, ui) {
            if (this._stopAfter == 'move') return;
            this.$source.removeClass('slot-drag-move');

            // Restore scroll bars
            olScrollOverlay.disable($('#trainingDaystableContainer'), false);

            var sourceItem = this.findItem(this.$source);
            if (this.toRowId == sourceItem.rowId && this.toIndex == sourceItem.index) {
                //console.log('-> stopped at me')
                this.notDropped();
            } else {
                //console.log('-> stopped over target')
                this.dropped();
            }
        },


        dropover: function(event, ui) {
            var $target = $(event.target);

            // Ignore drop only on valid targets
            var targetItem = this.findItem($target);
            if (!targetItem || !targetItem.validTarget) return;
            this.targetOffset = this.calcSlotPos($target);

            // Style affected items
            $target.addClass('slot-drop-over');
            $target.parent().addClass('slot-drop-over-parent');

            this.$source.addClass('slot-drag-over');
            this.$source.parent().addClass('slot-drag-over-parent');

            // move slot in dom
            var sourceItem = this.findItem(this.$source);
            var destItem = this.findItem($target);
            if (!sourceItem || !destItem) return;

            // determine from and to and direction
            var prevRowId = this.fromRowId, prevIndex = this.fromIndex;
            var fromRowId = this.toRowId, fromIndex = this.toIndex;
            var fromItem = this.getItem(fromRowId, fromIndex);
            var toRowId = destItem.rowId, toIndex = destItem.dropIndex;
            var rowChange = (toRowId != fromRowId);
            var dir = (toIndex - fromIndex);
            var marginLeft = Number(destItem.$slot.css('margin-left').replace(/px/, ''));
            if (!rowChange && !dir) {
                if (marginLeft < 0) {
                    dir = -1;
                    var destItem2 = this.getItem(toRowId, toIndex-1);
                    if (destItem2) toIndex--, destItem = destItem2;
                } else if (marginLeft > 0) {
                    dir = 1;
                    var destItem2 = this.getItem(toRowId, toIndex+1);
                    if (destItem2) toIndex++, destItem =destItem2;
                }
            } else if (rowChange && toRowId == sourceItem.rowId && toIndex == sourceItem.index+1 && marginLeft < 0) {
                // back to me
                dir = 0;
                var destItem2 = this.getItem(toRowId, toIndex-1);
                if (destItem2) toIndex--, destItem = destItem2;
            } else if (rowChange && toRowId != sourceItem.rowId && destItem.first) {
                // down to first block
                dir = 0;
            }

            this.fromRowId = fromRowId;
            this.fromIndex = fromIndex;
            this.toRowId = toRowId;
            this.toIndex = toIndex;

            // Set slot margins
            var sourceWidth = sourceItem.width;
            var sourceRowId = sourceItem.rowId, sourceIndex = sourceItem.index;
            var rowIds = [toRowId]; if (fromRowId != toRowId) rowIds.push(fromRowId); rowIds.sort();
            for (var r = 0;  r < rowIds.length;  r++) {
                var rowId = rowIds[r];
                var row = this.items[rowId];
                for (var i = 0;  i < row.length;  i++) {
                    var item = row[i];
                    if (item.type == '') continue;

                    // No margin for source
                    if (rowId == sourceRowId && i == sourceIndex) continue;

                    // Calc margin
                    var itemMovesLeft = false, itemMovesRight = false;
                    if (rowId == sourceRowId) {
                        // source row
                        if (rowChange) {
                            // move between other row and source row
                            if (rowId == toRowId) {
                                // move back in my row
                            } else {
                                // move out of my row
                                if (i > sourceIndex) itemMovesLeft = true;
                            }
                        } else {
                            // move within source row
                            if (item.trainingSetId < 0) {
                                // don't move addblocks/lockblocks
                            } else if (i < sourceIndex) {
                                if (i >= toIndex) itemMovesRight = true;
                            } else if (i > sourceIndex) {
                                if (i <= toIndex) itemMovesLeft = true;
                            }
                        }
                    } else {
                        // other row
                        if (rowId == toRowId) {
                            // moved from source row to this row
                            if (i >= toIndex) itemMovesRight = true;
                        } else {
                            // not moved here => no change
                        }
                    }

                    // Set the margin
                    var left = itemMovesLeft? -sourceWidth : itemMovesRight? sourceWidth : 0;
                    item.$slot.css('margin-left', left? left+'px' : '');
                    item.$slot.removeClass('moved-left').removeClass('moved-right');
                    if (left < 0) item.$slot.addClass('moved-left');
                    if (left > 0) item.$slot.addClass('moved-right');
                }
            }

            // Mark addblock items that are valid targets
            this.updateValidTargets(toRowId);
        },

        dropout: function(event, ui) {
            var $target = $(event.target);

            $target.removeClass('slot-drop-over');
            $target.parent().removeClass('slot-drop-over-parent');

            this.$source.removeClass('slot-drag-over');
            this.$source.parent().removeClass('slot-drag-over-parent');
        },


        notDropped: function() {
            if (this._stopAfter == 'drag') return;
            this.cleanup();
            this.finishDrop();
            if (this._stopAfter == 'drop') return;

            var $anchor = $('.ol-training-table-rows');
            addLoadingAnimationToElement($anchor, {size: 'small'});
            olTraining.timetableTabTo(this.day, function() {
                removeLoadingAnimationFromElement($anchor);

                // Restore scroll bars
                olScrollOverlay.create($('#trainingDaystableContainer'));
            });
        },

        dropped: function() {
            if (this._stopAfter == 'drag') return;
            this.cleanup();

            // Slot im Dom verschieben
            var sourceItem = this.findItem(this.$source);
            var destItem = this.getItem(this.toRowId, this.toIndex);
            var atPlace = (this.toRowId == sourceItem.rowId && this.toIndex == sourceItem.index);
            var before = (this.toIndex == 0 || destItem.first) // before first
                      || (destItem.type == 'addblock') // before addblock
                      || (this.toRowId == sourceItem.rowId && this.toIndex < sourceItem.index) // same row moving left
                      || (this.toRowId != sourceItem.rowId); // other row
            this.prepareFinishAnimation(sourceItem, destItem);
            if (atPlace) {
                // stays at (original) place
            } else if (destItem.$afterWrapper) {
                sourceItem.$wrapper.insertAfter(destItem.$afterWrapper);
            } else if (before) {
                sourceItem.$wrapper.insertBefore(destItem.$wrapper);
            } else {
                sourceItem.$wrapper.insertAfter(destItem.$wrapper);
            }

            sourceItem.$wrapper.css('margin-right', 0);
            this.finishDrop();

            // Mark dropped
            sourceItem.$slot.addClass('slot-dropped');
            sourceItem.$wrapper.addClass('slot-dropped-parent')

            if (destItem.$slot) {
                destItem.$slot.removeClass('slot-drop-over');
                destItem.$wrapper.removeClass('slot-drop-over-parent');
            }


            // Perform move in DB
            if (this._stopAfter == 'drop') return;
            var priority = (destItem.last && !before)? 0 : destItem.priority;
            this.applyChange(sourceItem, destItem, priority);
        },


        cleanup: function() {
            // Remove margins of items moved around
            for (var rowId in this.items) {
                var row = this.items[rowId];
                for (var i = 0;  i < row.length;  i++) {
                    var item = row[i];
                    if (item.type == '') continue;
                    item.$slot.css('margin-left', '');
                    item.$wrapper.css('margin-left', '');
                }
            }

            // Remove position of dragging helper
            this.$source.css({left: '', top: ''});

            // Remove temp styles
            $(this.containmentSelector+' .ol-training-timetable-block-wrapper').removeClass('slot-drop-over-parent');
            $(this.containmentSelector+' .ol-training-timetable-block').removeClass('slot-drop-over');
        },

        finishDrop: function() {
            // Show/hide block-sep's
            $(this.containmentSelector+' .ol-training-timetable-block-wrapper .ol-training-timetable-block-sep').show();
            $(this.containmentSelector+' .ol-training-table-row .ol-training-timetable-block-wrapper:last-child').each(function(i, o) {
                var type = $('.ol-training-timetable-block', o).attr('data-type');
                if (type == 'block') $('.ol-training-timetable-block-sep', o).hide()
            });

            // Reenable addblocks
            $('.ol-training-timetable-addblock-wrapper .ol-training-timetable-block').attr('data-disable', 0);

            // Mark end of drag & drop
            $(this.containmentSelector).removeClass('dnd-running');
        },

        applyChange: function(sourceItem, destItem, priority) {
            if (priority === undefined) priority = destItem.last? 0 : destItem.priority;

            var data = {
                trainingSetId:    sourceItem.trainingSetId,
                infrastructureId: destItem.infrastructureId,
                half:             destItem.half,
                priority:         priority
            };

            var $anchor = $('.ol-training-table-rows');
            addLoadingAnimationToElement($anchor, {size: 'small'});
            $.post('/team/training/slot/move', data, function(err) {
                if (err) {
                    removeLoadingAnimationFromElement($anchor);
                    olError(err);
                    return;
                }

                olTraining.timetableTabTo(typeof this.day == 'undefined' ? $('#training-timetable-day').val() : this.day, function() {
                    removeLoadingAnimationFromElement($anchor);

                    // Animate slot move if necessary
                    olDnd.performFinishAnimation();

                    // Restore scroll bars
                    olScrollOverlay.create($('#trainingDaystableContainer'));
                }, true);
            }).fail(function() {
                removeLoadingAnimationFromElement($anchor);
                console.log("*** olDnd.applyChange failed:", data);
            });
        },

        prepareFinishAnimation: function(sourceItem, destItem) {
            this.animationData = !this._finishAnimation? null : {
                containerId: 'training-timetable-'+this.day,
                trainingSetId: sourceItem.trainingSetId,
                pos: this.targetOffset
            };
        },

        calcSlotPos: function($slot) {
            var pos = $slot.offset();
            pos.top = Math.floor(pos.top);
            pos.left = Math.floor(pos.left);
            return pos;
        },

        performFinishAnimation: function() {
            var data = this.animationData;
            if (!data) return; // if disabled

            // Get slot info etc.
            var scrollContent = $(this.scrollContentSelector)[0];
            var $container = $('#'+data.containerId);
            var $targetSlot = $('#slot-'+data.trainingSetId);
            var targetWidth = $targetSlot.css('width');
//            var targetPos = $targetSlot.offset();
            var targetPos = this.calcSlotPos($targetSlot);

            // Determine if to animate
            var samePos = (data.pos.top == targetPos.top && data.pos.left == targetPos.left);
            if (data.pos.left < 0 && scrollContent.scrollLeft <= 0) samePos = true;
            var animate = !samePos;
            if (!animate) return;

            // Position clone for animation
            var $tmpSlot = $targetSlot.clone();
            $tmpSlot.attr('id', 'tmpSlot');
            $tmpSlot.addClass('slot-drag-move');
            $('.ol-training-timetable-block-sep', $tmpSlot).css('display', 'none');
            $tmpSlot.css({
                width: targetWidth,
                position: 'absolute',
                top: data.pos.top+'px',
                left: data.pos.left+'px'
            });
            $tmpSlot.appendTo(document.body);

            // Move tmpSlot to new position
            var scrollInView = function() {
                var left = scrollContent.offsetLeft, right = left + scrollContent.offsetWidth;
                var x = $tmpSlot[0].offsetLeft;
                if (left <= x && x < right) return
                scrollContent.scrollLeft = Math.max(x-100, 0);
            };
            $tmpSlot.animate({
                top: targetPos.top+'px',
                left: targetPos.left+'px'
            }, {
            	duration: 2000,
                easing: 'easeOutQuad',
	            step: scrollInView,
	            complete: function() {$tmpSlot.remove(); scrollInView();}
            });

        }
    };



    //--- Training execution ---

    olTraining.onClickWeektableActionDebugReset = function(event) {
        olMsgBox.postLoad('/team/training/processreset', undefined, updateTrainingContent);
    };

    // Todo/n.n. benutzt
    olTraining.onClickWeektableCheckbox = function(event) {
        //TODO
        var element = event.target, $element = $(element);
        var $btn = $element.closest('.ol-lineup-editor-checkbox');
        var text = getMsgAttr($btn, 'text');
        olError(text);
    };

//    function updateWeektableAction()
//    {
//        $.get('team/training/process/status', data, function(rc)
//        {
//            var status = rc.status;
//            var $btn = $('#trainingProcessStartBtn');
//            $btn.removeClass('deactivated')
//            if (status == 'executeNow') $btn.addClass('deactivated');
//        });
//    }
    olTraining.timer = false;
    olTraining.welect = false;
    var welectStartLoading = false;

    olTraining.resetWelectButtonLock = function() { welectStartLoading= false; };

    olTraining.onClickWeektableAction = function(event, welect, welectURL) {
        function _scriptLoaded() {
            olTraining.welect = welect;
            var _onClickWeektableAction = function(saturdayHasMatch) {
                olOverlayWindow.load('/team/training/process', undefined, function() {
                    if (olTraining.timer === false) {
                        var d = new Date();
                        olTraining.timer = d.getTime();
                    }
                    olTraining.initTrainingResults(saturdayHasMatch);
                });
            };

            var _showWelect = function(saturdayHasMatch) {
                Welect.checkAvailability( {
                    onAvailable: function() {
                        Welect.runSession({onSuccess: function() { _onClickWeektableAction(saturdayHasMatch); }, onCancel: function() {}});
                    },
                    onUnavailable: function() {
                        olTraining.additionalTime = 3000;
                        _onClickWeektableAction();
                    }
                });
            };

            var $element = $('#trainingProcessStartBtn');
            if ($element.hasClass('deactivated')) return;
            var data = {trainingScheduleId: $element.attr('data-trainingScheduleId')};

            var status = olTraining.state.status,
                saturdayHasMatch = olTraining.state.saturdayHasMatch;;        // Nicht ausführbar
            if (status != 'executeNow') {
                return;
            }

            var $anchor = $element;
            addLoadingAnimation($anchor);
            var title = getMsgAttr($element, 'title'),
                ok = getMsgAttr($element, 'ok'),
                text = getMsgAttr($element, 'text-'+status), // info on training status
                addText = getMsgAttr($element, 'text-friendly_'+status); // info on friendly status
                notice = getMsgAttr($element, 'text-'+status); // error on friendly status
            if (addText) text += '<br />'+addText;
            removeLoadingAnimation($anchor);

            // Nicht ausführbar
            if (status != 'executeNow') {
                if (notice) olMsgBox.msg(title, notice, undefined, ok);
                return;
            }

            // Ausführen
            olMsgBox.question(title, text, function() {
                if (welectStartLoading) return;
                welectStartLoading = true;
                if (olTraining.welect)
                    Welect.checkToken({ onValid: function() { _showWelect(saturdayHasMatch); }, onInvalid: function() { _showWelect(saturdayHasMatch); } });
                else
                    setTimeout(function() { _onClickWeektableAction(saturdayHasMatch); }, 100);
            });
        }

        // first unload existing script
        if (welectURL) {
            $('#welectAd').remove();
            var script = document.createElement('script');
            script.setAttribute("id", "welectAd");
            script.onload = function () {
                _scriptLoaded();
            };
            script.src = welectURL;
            document.head.appendChild(script);
        } else {
            _scriptLoaded();
        }
    };
    olTraining.additionalTime = 0;

    olTraining.initTrainingResults = function(saturdayHasMatch) {
        if (saturdayHasMatch === undefined) saturdayHasMatch = true;
        var numDays = saturdayHasMatch? 6 : 7;
        //var pctPrepareStart = 10, pctPrepareStep = 18, pctPrepareDone = 28, pctDayStep = 12;
        var pctPrepareStart = 10, pctPrepareStep = 18, pctPrepareDone = 28, pctDayStep = 72/numDays;
        var stepDuration = 2000;

        var $progressBar = $('#trainingExecutionOverlayBarProgress');
        $progressBar.removeClass('notransition fasttransition slowtransition').addClass('slowtransition');
        if (olTraining.welect) {
            stepDuration = 500 + olTraining.additionalTime;
            pctPrepareDone = 100;
        }
        olTrainingResultsQueue.rc = null;
        olTrainingResultsQueue.add(trainingResultsStart, 100, false, {pct: pctPrepareStart});
        olTrainingResultsQueue.add(trainingResultsPrepare, stepDuration, true, {pctRunning: pctPrepareStart, pctStep: pctPrepareStep, pctDone: pctPrepareDone});
        // for (var i = 0;  i <= 5;  i++) {
        if (olTraining.welect === false) {
	        for (var i = 0;  i < numDays;  i++) {
	            var dayName = $('#textTrainingExecution'+i).text();
	            olTrainingResultsQueue.add(trainingResultsDay, stepDuration, false, {dayName: dayName, dayOffset: i, pct: pctPrepareDone+(i+1)*pctDayStep});
	        }
        }

        olTrainingResultsQueue.add(trainingResultsDone, stepDuration, false, null);
        olTrainingResultsQueue.start();
    };

    function trainingResultsStart(data) {
        // Deactivate execution button
        //$('#trainingProcessStartBtn').addClass('deactivated');
        setTrainingExecuteButtonStatus(false);

        // Advance progress bar
        var $progressBar = $('#trainingExecutionOverlayBarProgress');
        $progressBar.width(data.pct+'%');

        // Next step
        olTrainingResultsQueue.step();
    }

    function trainingResultsPrepare(data) {
        // Ask if training calculation is done
        $.get('/team/training/process/done', function(rc) {
            // Remember training results (injuries)
            olTrainingResultsQueue.rc = rc;

            // Advance progress bar
            var $progressBar = $('#trainingExecutionOverlayBarProgress');
            var pct = rc.done? data.pctDone : Math.min((data.pctRunning+olTrainingResultsQueue._count)*data.pctStep, data.pctDone);
            $progressBar.width(pct+'%');

            // Next step if done; otherwise repeat this step
            if (rc.done) olTrainingResultsQueue.skip();
            olTrainingResultsQueue.step();
        })
    }

    function trainingResultsDay(data) {
        var rc = olTrainingResultsQueue.rc;

        // Advance progress bar
        var $progressBar = $('#trainingExecutionOverlayBarProgress');
        $progressBar.removeClass('notransition fasttransition slowtransition').addClass('fasttransition');
        $progressBar.width(data.pct+'%');

        // Show training day
        $('#trainingExecutionOverlayTitle').html(data.dayName+'...');

        // Show injureds this day
        var html = rc.injuredsTextByDay[data.dayOffset] || '&nbsp;';
        $('#trainingExecutionOverlayText').html(html);

        // Next day
        olTrainingResultsQueue.step();
    }

    function trainingResultsDone(data) {
        var rc = olTrainingResultsQueue.rc;

        // Advance progress bar
        var $progressBar = $('#trainingExecutionOverlayBarProgress');
        $progressBar.removeClass('notransition fasttransition slowtransition').addClass('notransition');
        $progressBar.width('100%');

        // Show training done
        $('#trainingExecutionOverlayRoot').addClass('ol-training-done');
        $('#trainingExecutionOverlayTitle').html($('#textTrainingExecutionDone').html());

        // Show all injureds during training
        var html = rc.injuredText || '&nbsp;';
        $('#trainingExecutionOverlayText').html(html);

        // Reload training in background   => schon vorher?
        olAnchorNavigation.reload();

        // Finish queue
        olTrainingResultsQueue.stop();

        var d = new Date();
        var tempTime = d.getTime() - olTraining.timer;
        $('#textTrainingExecutionDoneTimer').html('Debug: ' + tempTime + 'ms');
    }

    var olTrainingResultsQueue = {
        _elements: [],
        _running: true,
        _count: 0,

        add: function(run, delay, repeat, data) {
            if (delay === undefined) delay = 0;
            if (repeat === undefined) repeat = false;
            if (data === undefined) data = null;
            this._elements.push({run: run, delay: delay, repeat: repeat, data: data});
        },

        start: function() {
            this._running = true;
            this._count = 0;
            this.step();
        },

        stop: function() {
            this._running = false;
        },

        reset: function() {
            this._elements = [];
            this._running = false;
            this._count = 0;
        },

        skip: function() {
            if (!this._running) return;
            if (!this._elements.length) return;
            this._elements.shift();
        },

        step: function() {
            if (!this._running) return;
            var element = this._elements[0];
            if (!element) return;
            if (!element.repeat) this._elements.shift();

            var f = function() {
                element.run(element.data);
                this._count++
            };
            if (element.delay > 0) {
                setTimeout(f, element.delay);
            } else {
                f();
            }
        }
    };

    olTraining.onClickShowTrainingResults = function(event) {
        olOverlayWindow.close();

        if (olAppInfo.APP_PLATFORM == "iphone" && typeof container == "undefined") {
            container = document.getElementById('scrollRoot');
        } else {
            container = 'html, body';
        }
        $(container).animate({
            scrollTop: $("#training-player-details").offset().top - 80
        }, 1500, setPlayerDetailsType('performance_level'));
    };



    //--- Player details ---

    olTraining.onCollapsePlayerDetailsCategory = function(event) {
        var $element = $(event.target).closest('.ol-team-overview-squad-headline-white'),
            $category = $element.closest('.ol-training-playerdetails-category'),
            $players = $category.find('.ol-training-playerdetails-toggle');
        $players.click();
        $element.toggleClass('category-active');
    };

    olTraining.onCollapsePlayerDetails = function(event) {
        var element = event.target, $element = $(element);
        var data = {
            trainingScheduleId: $('#dropdownTrainingSchedule').dropdown().value(),
            playerId: $element.attr('data-playerId')
        };

        // Auf-/Zuklappen
        $('#playerTraining'+data.playerId+', #training'+data.playerId+', #playerTrainingRow'+data.playerId).toggleClass('active-overview');
        var $contentArea = $('#playerTraining'+data.playerId);
        var $row = $('#playerTrainingRow'+data.playerId);
        var wasCollapsed = $contentArea.hasClass('active-overview');

        // Inhalt holen
        if (!wasCollapsed) return; // nur wenn aufgeklappt wird
        if ($contentArea.html().trim() != '') return; // already loaded

        var $anchor = $contentArea;
        addLoadingAnimation($anchor);
        $.get('/team/training/details/player', data, function(rc) {
            // Inhalt anzeigen
            $contentArea.html(rc);

            // Sperrblöcke: Popover initialisieren
            initializePopovers();

            // Scrollarea initialisieren
            if (getPlayerDetailsType() == 'training_schedule')
                olScrollOverlay.create($('#trainingPlayertableWeekContainer-'+data.playerId));

            removeLoadingAnimation($anchor);
        }).fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** onCollapsePlayerDetails failed:", data);
        });
    };

    olTraining.filterPlayerDetailsByPosition = function(event) {
        var element = event.target, $element = $(element);
        var posFilter = $element.parent().attr('data-value');
        filterPlayerDetailsBy(posFilter);
    };

    function filterPlayerDetailsBy(posFilter) {
        posFilter = Number(posFilter);

        var stateId = $('#training-stateId').val();
        var rowClass = 'ol-state-color-light-'+stateId;

        // Spieler ausblenden/anzeigen und abwechselnde Hintergrundfärbung setzen
        var n = 0;
        $('.ol-training-playerdetails-category .ol-training-playerdetails-table-row').each(function(i, o) {
            var $o = $(o);
            var position = Number($o.attr('data-position'));
            var visible = (position & posFilter) != 0;
            $o.removeClass(rowClass);
            if (visible) {
                $o.removeClass('hidden');
                if (++n % 2 == 0) $o.addClass(rowClass);
            } else {
                $o.addClass('hidden')
            }
        });

        // Leere Kategorien ausblenden
        var $categories = $('.ol-training-playerdetails-category');
        $categories.each(function(i, o) {
            var $o = $(o);
            var n = 0;
            $('.ol-training-playerdetails-table-row, .squad-player-wrapper', $o).each(function(j, row) {
                if (!$(row).hasClass('hidden')) n++;
            });
            if (n == 0) $o.addClass('hidden'); else $o.removeClass('hidden');
        });
    };

    function clickFilterPlayerDetailsBy(posFilter) {
        var $a = $('#dropdownTrainingPlayerDetails ul.dropdown-menu li[data-value="'+posFilter+'"] > a');
        $a.click();
    }


    function getPlayerDetailsType() {
        // var tab = $('#tabsTrainingPlayerDetails > button.active').attr('data-tab');
        var tab = $('.ol-training-playerdetails-doubleButtons > button.active').attr('data-tab');
        if (!tab) tab = (Number($('#training-trainingDone').val()) == 1)? 'performance_level' : 'training_schedule';
        return tab;
    }
    
    function setPlayerDetailsType(tab) {
        $('.ol-training-playerdetails-doubleButtons button[data-tab="'+tab+'"]').click();
    }

    olTraining.onClickPlayerDetailsType = function(inputElement) {
        var $element = $(inputElement);
        var tab = $element.attr('data-tab');
        // $('#tabsTrainingPlayerDetails button').removeClass('active');
        // $element.addClass('active');


        updatePlayerDetails(null, tab);
    };

    olTraining.onCollapsePlayerDetailResults = function(playerId) {
        var data = {playerId: playerId};

        // Auf-/Zuklappen
        $('#playerTrainingResults'+data.playerId+', #trainingResults'+data.playerId+', #playerTrainingResultsRow'+data.playerId).toggleClass('active-overview');
        var $contentArea = $('#playerTrainingResults'+data.playerId);
        var $row = $('#playerTrainingResultsRow'+data.playerId);
        var wasCollapsed = $contentArea.hasClass('active-overview');

        // Inhalt holen
        if (!wasCollapsed) return; // nur wenn aufgeklappt wird
        if ($contentArea.html().trim() != '') return; // already loaded

        var $anchor = $contentArea;
        addLoadingAnimation($anchor);
        $.get('/team/training/details/results/player', data, function(rc) {
            // Inhalt anzeigen
            $contentArea.html(rc);
            
            // Auf-/Zuklappen
            //%

            // Form popover if injured
            initializePopovers('.ol-player-detail-results-content .ol-player-out');

            removeLoadingAnimation($anchor);
        }).fail(function() {
            removeLoadingAnimation($anchor);
            console.log("*** onCollapsePlayerDetailResults failed:", data);
        });
    };

    olTraining.toggleCompact = function(toggle) {
        if (toggle) {
            $('body').addClass('expertMode')
        } else {
            $('body').removeClass('expertMode')
        }
    }

    olTraining.toggleCompactTrainingView = function() {
        var $checkbox = $('#toggleTrainingCompact');
        $checkbox.attr('checked', function(index, attr){
            return attr ? null : true;
        });

        if ($checkbox.is('[checked]')) {
            localStorage.setItem('expertMode', true);
            olTraining.toggleCompact(true);
        } else {
            localStorage.setItem('expertMode', false);
            olTraining.toggleCompact(false);
        }
    }

    olTraining.togglePitchSide = function(row, dropdown) {
        var day = $('#training-timetable-day').val()
        var $container = $('#training-timetable-'+day);
        $container.attr('data-row', row);

        if (dropdown) {
            var pitch = dropdown.innerHTML;
            var $dropdown = $container.find('.dropdown').find('.ol-dropdown-text');
            $dropdown.html(pitch);
        }
    }

    //--- Widgets ---

    // $element  jQuery-Objekt des Slider-Elements
    // onstep    Callback zum Aktualisieren der Anzeige bei Wertänderung
    // onchange  Callback zum Schreiben des Werts am Ende bei tatsächlicher Änderung
    function initMinutesSlider($element, onstep, onchange, disabled) {
        if (disabled === undefined) disabled = $element.prop('disabled');

        var $slider = $element.bootstrapSlider({scale: 'linear', tooltip: 'hide'});
        if (!$slider) return;

        if (disabled) {
            $slider.bootstrapSlider('disable');
        } else {
            $slider.on('change', function(ev) {
                var $o = $(ev.target);
                var value = ev.value.newValue;
                if (typeof onstep === 'function') onstep($o, value);
            });

            $slider.on('slideStop', function(ev) {
                var $o = $(ev.target);
                var value = ev.value;
                if (typeof onchange === 'function') onchange($o, value);
            });

            $slider.attr('data-savedValue', $slider.attr('data-value'));

            $slider.data('saveValue', function() {
                $slider.attr('data-savedValue', $slider.attr('data-value'));
            });

            $slider.data('restoreValue', function() {
                var savedValue = Number($slider.attr('data-savedValue'));
                if (typeof onstep === 'function') onstep($slider, savedValue);
                $slider.bootstrapSlider('setValue', savedValue);
            });
        }

        return $slider;
    }



    //--- Animations ---

    var _animationElement = null;

    function addLoadingAnimation($element, opts) {
        if (opts === undefined) opts = {};
        if (!('size' in opts)) opts.size = "small";
        _animationElement = ($element && $element.length)? $element : null;
        if (_animationElement) addLoadingAnimationToElement(_animationElement, opts);
    }

    function removeLoadingAnimation($element) {
        if (typeof $element !== 'undefined') removeLoadingAnimationFromElement($element);
        else if (_animationElement) removeLoadingAnimationFromElement(_animationElement);
        _animationElement = null;
    }



    //--- Event handlers ---

    // Wochenplan/Tag und Details mit Nav-Bereich links alignen
    // % und Scrollarea anpassen
    // und Abschneiden rechts bei ol-xs unterbinden
    function adjustTrainingContent() {
        // Linken Rand setzen
        var $o = $('#trainingArea');
        if ($o.length == 0) return;
        var marginLeft = ($o.parent().outerWidth() - $o.outerWidth()) / 2;
        $('.ol-content-after-league-nav-training').css('margin-left', marginLeft+"px");

        var xs = (olGUI.getBootstrapDeviceSize() == 'ol-xs');
        $('.ol-content-after-league-nav-training').css('width', xs? 'calc(100% - 7px)' : '');
    }

    $(window).resize(adjustTrainingContent);
    adjustTrainingContent();


    // Setzt Sticky-Header-Status bei PlayerDetails-Header
    function adjustStickyHeader() {
        var $st = $('.ol-training-playerdetails-header-sticky'), st = $st[0];
        if (!st) return;
        var stuck = (st.offsetTop > 0);
        if (stuck) $st.addClass('stuck'); else $st.removeClass('stuck');
    }
    $(window).scroll(adjustStickyHeader);
    adjustStickyHeader();



    //--- Dialogs ---

    // Holt den Werte von data-msg-`key`, setzt die {`key2`} aus data-`key2` ein
    // und liefert den resultierenden Text (dflt='').
    function getMsgAttr($element, key, dflt) {
        if (!($element instanceof jQuery)) $element = $($element);
        if (dflt === undefined) dflt = '';

        var value = $element.attr('data-msg-'+key);
        if ((value === undefined) || (value === false)) value = dflt;

        value = value.replace(/{([^}]+)}/g, function(m0, key) {
            var value = $element.attr('data-'+key);
            if ((value === undefined) || (value === false)) value = '';
            return value;
        });
        value = value.replace(/\n/g, '<br />');

        return value.trim();
    }

    function olConfirm(element, callbackYes, callbackNo) {
        var $element = $(element);
        var header = getMsgAttr($element, 'header'),
            text = getMsgAttr($element, 'text'),
            textYes = getMsgAttr($element, 'textYes'),
            textNo = getMsgAttr($element, 'textNo');

        // Kein Text => kein Confirm
        if (text == '') {
            if (typeof callbackYes === 'function') callbackYes();
            return;
        }

        // Confirm anzeigen
        olMsgBox.question(header, text, callbackYes, callbackNo, textYes, textNo);
    }

    function olError(text, onsuccess) {
        var header = $('#msgHeader').html() || '',
            textYes = $('#msgOk').html() || 'OK',
            callbackYes = (typeof onsuccess === 'function')? onsuccess : undefined;
        var m = text.match(/^([^|]*)\|(.*)$/);
        if (m) {
            header = m[1];
            text = m[2];
        }
        olMsgBox.msg(header, text, callbackYes, textYes);
    }

    //function getErrValue(html, name) {
    //    if (typeof name === 'undefined') name = 'err';
    //    if (!html) html = '';
    //    var m = html.match(new RegExp('<!--'+name+':(.*?)-->'));
    //    return m? m[1] : '';
    //}

}(window.olTraining = window.olTraining || {}, jQuery));
