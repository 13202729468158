// TODO Move to namespace object

var startModificationStands;

function initializeStadiumSVG() {
    if (typeof stadiumModificationData != 'object') {
        stadiumModificationData = new Object();
    }

    startModificationStands = [];
    if ($('#noBlockModification').val() == 0) {
        var startModificationLevels = [];
        for (var standCode in stadiumModificationData) {
            if (stadiumModificationData.hasOwnProperty(standCode)) {
                var level = $('#svg_input_' + standCode).attr('data-level');
                if (stadiumModificationData[standCode]['startModification']) {
                    if ($('#svg_input_' + standCode).val() == 0) {
                        $('#svg_input_' + standCode).val(1);
                    }
                    startModificationStands.push(standCode);
                    $('#stadium_star_mark_' + standCode).html('*');
                    if (!startModificationLevels.includes(level)) {
                        startModificationLevels.push(level);
                    }
                } else {
                    $('#stadium_star_mark_' + standCode).html('');
                }

                var isParentClear = true;
                if (stadiumModificationData[standCode]['modDependence']) {
                    for (var key in stadiumModificationData[standCode]['modDependence']) {
                        if (stadiumModificationData[standCode]['modDependence'].hasOwnProperty(key)) {
                            var dependentStandCode = stadiumModificationData[standCode]['modDependence'][key];
                            if ($('#svg_input_' + dependentStandCode).val() != 4) {
                                isParentClear = false;
                            }
                        }
                    }
                }
                if (isParentClear && $('#svg_input_' + standCode).val() == 0) {
                    $('#svg_input_' + standCode).val(1);
                }
            }
        }
        $('.stadiumLowerStandMandatoryMessage').hide();
        startModificationLevels.forEach(function(level) {
            $('#stadium_lower_stand_mandatory_message_' + level).show();
        });
    }

    $.fn.clickoffFn = function() {
        var actStandCode = $(this).attr('id').replace('stadium_range_', '');
        var shortCode = $('#svg_input_' + actStandCode).attr('data-shortcode');
        var thisObj = $(this);
        olMsgBox.questionById('deselectedStadiumAlert',
            function() {
                var actStandCodeArr = $(thisObj).removeChildStand();
                actStandCodeArr.push(actStandCode);
                var tmpActStandCodeArr = actStandCodeArr;
                actStandCodeArr = [];
                tmpActStandCodeArr.forEach(function(v) {
                    if (!actStandCodeArr.includes(v)) {
                        actStandCodeArr.push(v);
                    }
                });
                var count = 0;

                console.log("actStandCodeArr", actStandCodeArr, "this", thisObj);
                actStandCodeArr.forEach(function(v) {
                    count++;
                    setTimeout(function() {
                        if ($('#' + v).hasClass('activeBlock')) {
                            $('#' + v).trigger('click');
                        }
                    }, 203 * count);
                });
            },
            function() { return; },
            {
                '[stand_placeholder]': shortCode
            }
        );
    }

    var actStandCodeArr = [];
    $.fn.removeChildStand = function(firstCall) {
        if (firstCall == undefined) firstCall = true;
        
        var actStandCode = $(this).attr('id').replace('stadium_range_', '');

        if (firstCall) {
            actStandCodeArr = [];
        }

        var actStandCodeArr = [];

        var removeStandArr = [];
        for (var standCode in stadiumModificationData) {
            if (stadiumModificationData.hasOwnProperty(standCode)) {
                if (stadiumModificationData[standCode]['modDependence'].length) {
                    for (var key in stadiumModificationData[standCode]['modDependence']) {
                        if (stadiumModificationData[standCode]['modDependence'].hasOwnProperty(key)) {
                            var dependenceShortCode = stadiumModificationData[standCode]['modDependence'][key];
                            if(dependenceShortCode == actStandCode && $('#' + dependenceShortCode).is('.activeBlock, .constructionBlock, .builtBlock')) {
                                if(!removeStandArr.includes(standCode)) {
                                    if ($('#' + standCode).is('.activeBlock, .constructionBlock, .builtBlock')) {
                                        removeStandArr.push(standCode);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }


        removeStandArr.forEach(function(v) {
            actStandCodeArr = actStandCodeArr.concat($('#' + v).removeChildStand(false));
            actStandCodeArr.push(v);
        });
        console.log(firstCall, actStandCodeArr);
        return actStandCodeArr;
    }
    console.log("SVG INPUTS?", $('.svg_input').size());
    $('.svg_input').each(function(e) {
        var input_id = $(this).attr('id');
        var input_val = $(this).val();
        var label = $(this).attr('data-label');
        var level = $(this).attr('data-level');
        var id = $(this).attr('data-id');
        var modificationID = $(this).attr('data-modificationid');
        var isactive = $(this).attr('data-isactive');
        var shortcode = $(this).attr('data-shortcode');

        var actual_id = input_id.replace('svg_input_', '');

        if (input_val == 0) {
            $('#' + actual_id).addClass('disabledBlock')
            /*if ($('.svg_input[value=3]').size() == 0) {

                $('#' + actual_id).popover(                {
                    'placement': 'top',
                    'title': 'Angrenzende Elemente müssen ausgebaut sein.',
                    'body': 'Angrenzende Elemente müssen ausgebaut sein.',
                    'trigger': 'hover',
                    'container': 'svg',
                    'template': '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header popover-title popover-body" style="font-weight: 600"></h3></div>'
                }).popover('enable');
            }*/
            $('#' + actual_id + '>g.declined').css('display', 'block');
        } else if (input_val == 1) {
            $('#' + actual_id).addClass('conPossibleBlock');
            $('#' + actual_id + '>g.con_possible').css('display', 'block');
        } else if (input_val == 2) {
            $('#' + actual_id + '>g.active').css('display', 'block');
        } else if (input_val == 3) {
            $('#' + actual_id).addClass('constructionBlock');
            if (actual_id == 'O' && $('#oBoxInConstruction').val() == '1') {
                $('#' + actual_id + '>g.built').css('display', 'block');
            } else if (actual_id == 'O2' && $('#o2BoxInConstruction').val() == '1') {
                $('#' + actual_id + '>g.built').css('display', 'block');
            } else {
                $('#' + actual_id + '>g.construction').css('display', 'block');
            }

        } else if (input_val == 4) {
            $('#' + actual_id).addClass('builtBlock');
            $('#' + actual_id + '>g.built').css('display', 'block');
        }

        if (isactive) {
            $('#' + actual_id).addClass('activeBlock');
        }

        $('#' + actual_id).attr('data-label', label);
        $('#' + actual_id).attr('data-level', level);
        $('#' + actual_id).attr('data-id', id);
        $('#' + actual_id).attr('data-modificationid', modificationID);
        //$('#' + actual_id).find('text').html(shortcode);

        
    });

    $('.svgStadiumBlock').on('click', function(e, checkmarkFlg) {
        if (!$(this).hasClass('disabledBlock') && !$(this).hasClass('clickoff')) {
            var id = $(this).attr('data-id');

            if ($(this).hasClass('conPossibleBlock')) {
                if (typeof startStadiumAlert == 'function') {
                    var rangeObj = $('.ticket-price-settings[data-id="' + id + '"]').find('.myRange');
                    startStadiumAlert(rangeObj, checkmarkFlg);

                    if ($(this).attr('data-deselect') == '1') {
                        $(this).removeAttr('data-deselect');
                        var elementID = $(rangeObj).attr('id');
                        var standCode = elementID.replace('stadium_range_', '');

                        $('#stadium_range_' + standCode).attr('data-initial', 1);
                        $('#stadium_range_' + standCode).val(0);

                        $('#stadium_stand_value_' + standCode).val(0);
                        $('#stadium_seat_value_' + standCode).val(0);

                        if (!$('#stadium_roof_' + standCode).hasClass('mustCheck')) {
                            $('#stadium_roof_' + standCode)[0].checked = false;
                        }

                        $('#stadium_business_row_' + standCode)
                        .addClass('d-none')
                        .hide();
                        $('#stadium_business_button_' + standCode).find('.buttonStatus').removeClass('fa-minus').addClass('fa-plus');
                        $('#stadium_business_hr_row_' + standCode).hide();
                        $('#stadium_business_row_' + standCode).removeClass('border-top-none');
                        $('#stadium_business_row_' + standCode).removeClass('border-bottom-none');
                        $('#stadium_business_value_' + standCode).val(0);
                        $('#stadium_seat_business_value_' + standCode).val(0);
                        $('#stadium_business_range_' + standCode).val(0);

                        $('#stadium_box_row_' + standCode)
                        .addClass('d-none')
                        .hide();
                        $('#stadium_box_button_' + standCode).find('.buttonStatus').removeClass('fa-minus').addClass('fa-plus');
                        $('#stadium_box_hr_row_' + standCode).hide();
                        $('#stadium_box_row_' + standCode).removeClass('border-top-none');
                        $('#stadium_box_value_' + standCode).val(0);
                        $('#stadium_seat_box_value_' + standCode).val(0);
                        $('#stadium_box_range_' + standCode).val(0);

                        $('#stadium_row_' + standCode).removeClass('border-bottom-none');

                        $('#stadium_deselect_icon_' + standCode).hide();

                        if ($('#selectLevelAllStand').val() == 1) {
                            deactiveAllStand(rangeObj);
                        }

                        calculateAllCapacity();

                        if (!checkStandConfiguration(rangeObj)) {
                            $('#stadiumConfiguratorSave').addClass('disabled-button');
                        } else {
                            $('#stadiumConfiguratorSave').removeClass('disabled-button');
                        }
                    }
                }
            }

            $('.ticket-price-settings[data-id!="' + id + '"]').removeClass('active');
            $('.ticket-price-settings[data-id!="' + id + '"]').removeClass('fixActive');

            if ($('.ticket-price-settings[data-id="' + id + '"]').length) {
                var elementID = $('.ticket-price-settings[data-id="' + id + '"]').attr('id');
                var standCode = elementID.replace('stadium_row_', '');
                $('.stadiumBusinessHrRow:not(#stadium_business_hr_row_' + standCode + ')').removeClass('fixActive-background-border');
                $('.stadiumBoxHrRow:not(#stadium_box_hr_row_' + standCode + ')').removeClass('fixActive-background-border');
            }

            var stateId = $('#stateId').length ? $('#stateId').val() : '';
            if ($(this).hasClass('activeBlock') || $('.ticket-price-settings[data-id="' + id + '"]').hasClass('fixActive')) {
                if ($('.ticket-price-settings[data-id="' + id + '"]').hasClass('fixActive')) {
                    $('.ticket-price-settings[data-id="' + id + '"]').removeClass('fixActive');
                    $('.ticket-price-settings[data-id="' + id + '"]').removeClass('active');

                    $('#stadium_business_hr_row_' + standCode).removeClass('fixActive-background-border');
                    $('#stadium_box_hr_row_' + standCode).removeClass('fixActive-background-border');
                }
                $(this).removeClass('activeBlock');
                $(this).find('text').removeClass('stateColorAppliedSVGText ol-state-primary-svg-color-' + stateId);
                $('.ticket-price-settings[data-id="' + id + '"]').removeClass('activeStadiumRow');
                $('#stadium_business_hr_row_' + standCode).addClass('border-left-right-none');
                $('#stadium_box_hr_row_' + standCode).addClass('border-left-right-none');
                $('.ticket-price-settings[data-id="' + id + '"]').addClass('nohover');
                $('.ticket-price-settings[data-id="' + id + '"]').removeClass('active');
            } else {
                if (!$(this).hasClass('builtBlock') && !$(this).hasClass('constructionBlock')) {
                    $(this).addClass('activeBlock');
                    $(this).find('text').addClass('stateColorAppliedSVGText ol-state-primary-svg-color-' + stateId);
                }
                var standCode = '';
                if ($('.ticket-price-settings[data-id="' + id + '"]').length) {
                    var elementID = $('.ticket-price-settings[data-id="' + id + '"]').attr('id');
                    standCode = elementID.replace('stadium_row_', '');
                }
                if ($(this).hasClass('builtBlock') || $(this).hasClass('constructionBlock')) {
                    $('.ticket-price-settings[data-id="' + id + '"]').addClass('active');
                    $('.ticket-price-settings[data-id="' + id + '"]').addClass('fixActive');

                    $('#stadium_business_hr_row_' + standCode).addClass('fixActive-background-border');
                    $('#stadium_box_hr_row_' + standCode).addClass('fixActive-background-border');
                } else {
                    $('.ticket-price-settings[data-id="' + id + '"]').addClass('activeStadiumRow');
                    $('#stadium_business_hr_row_' + standCode).removeClass('border-left-right-none');
                    $('#stadium_box_hr_row_' + standCode).removeClass('border-left-right-none');
                    $('.ticket-price-settings[data-id="' + id + '"]').addClass('nohover');
                    $('.ticket-price-settings[data-id="' + id + '"]').removeClass('active');
                }
            } 

            if (typeof showHideLegend == 'function') {
                showHideLegend();
            }
        }
    });

    var svgPopoverShown = false;
    var svgPopoverTimeout = 0;
    $('.svgStadiumBlock')
    .on('mouseenter', function(e) {
        $(this).svgStadiumBlockMouseenter();

        if ($(this).hasClass('disabledBlock') && $('.svg_input[value=3]').size() == 0 && svgPopoverShown != this.id) {
            $('#svgPopover > .popover-title > b').text(Lang.trans('js/stadium.adjacentElementsNeed'));
            var svg = $(this).parents('svg')

            var offset = svg.offset();
            
            svgPopoverShown = this.id;
            var left = -145 + e.offsetX;
            var top = 90 + e.offsetY;
            $('#svgPopover').attr('style', 'left:'+ (left) + "px !important; top: "+(top)+"px").addClass('in');

            clearTimeout(svgPopoverTimeout);
            svgPopoverTimeout = setTimeout(function() {svgPopoverShown = false; $('#svgPopover').removeClass('in')}, 2500);
        }
    }).on('mouseleave', function(e) {
        $(this).svgStadiumBlockMouseleave();
    });

    $.fn.svgStadiumBlockMouseenter = function() {
        if (!$(this).hasClass('disabledBlock')) {
            $('.ticket-price-settings:not(.fixActive)').removeClass('active');
            var id = $(this).attr('data-id');
            $('.ticket-price-settings[data-id="' + id + '"]').addClass('active');

            if ($('.ticket-price-settings[data-id="' + id + '"]').length) {
                var elementID = $('.ticket-price-settings[data-id="' + id + '"]').attr('id');
                var standCode = elementID.replace('stadium_row_', '');
                $('#stadium_business_hr_row_' + standCode).addClass('active-background-color');
                $('#stadium_box_hr_row_' + standCode).addClass('active-background-color');
            }

            $(this).find('>g').each(function(e) {
                if ($(this).css('display') == 'block') {
                    if ($(this).hasClass('con_possible')) {
                        $(this).find('.mainBlock').css('opacity', '0.1');
                    } else if ($(this).hasClass('active')) {
                        $(this).find('.mainBlock').css('opacity', '0.1');
                        $(this).find('.activeStandColorBorder').css('opacity', '0.7');
                    } else {
                        $(this).find('.mainBlock').css('opacity', '0.7');
                    }
                }
            });
        }
    }

    $.fn.svgStadiumBlockMouseleave = function() {
        if (!$(this).hasClass('disabledBlock')) {
            $('.ticket-price-settings:not(.fixActive)').removeClass('active');

            $('.stadiumBusinessHrRow').removeClass('active-background-color');
            $('.stadiumBoxHrRow').removeClass('active-background-color');
            
            $(this).find('>g').each(function(e) {
                if ($(this).css('display') == 'block') {
                    $(this).find('.mainBlock').css('opacity', '');
                    $(this).find('.activeStandColorBorder').css('opacity', '');
                }
            });
        }
    }


    $(function(eReady) {
        var standArr = [];
        for (var standCode in stadiumModificationData) {
            if (stadiumModificationData.hasOwnProperty(standCode)) {
                if (stadiumModificationData[standCode]['startModification'] && $('#svg_input_' + standCode).val() < 2) {
                    $('#stadium_deselect_icon_' + standCode).remove();
                    $('#stadium_range_' + standCode).removeAttr('data-initial');

                    standArr.push(standCode);
                }
            }
        }

        setTimeout(function() {
            standArr.forEach(function(v) {
                $('#' + v).trigger('click');
                $('#' + v).off('click');
                $('#' + v).on('click', function() {$(this).autofocus();});

                unlockLevel($('#stadium_range_' + v));
            });
        }, 1);

        $('.svg_input').each(function(e) {
            var value = $(this).val();
            if (value == 0 || value == 3) {
                var dataID = $(this).attr('data-id');
                $('.ticket-price-settings').each(function(e) {
                    if($(this).attr('data-id') == dataID) {
                        if (value == 0) {
                            $(this).addClass('disabledRow');
                            $(this).find('.myRange').attr('disabled', true);
                            $(this).find('.businessRange').attr('disabled', true);
                            $(this).find('.myBoxRange').attr('disabled', true);
                            $(this).find('.roofCheck').addClass('disabled-checkbox');
                        } else if (value == 3) {
                            $(this).find('.myRange').attr('disabled', true);
                            $(this).find('.businessRange').attr('disabled', true);
                            $(this).find('.myBoxRange').attr('disabled', true);
                            $(this).find('.roofCheck').addClass('disabled-checkbox');
                        }
                    }
                })
            }
        });

        $('.ticket-price-settings')
        .on('mouseenter', function(e) {
            if (!$(this).hasClass('disabledRow') && !$(this).hasClass('activeStadiumRow')) {
                var stadiumRowDataID = $(this).attr('data-id');
                var svgInputElement;
                $('.svg_input').each(function(e) {
                    var svgInputDataID = $(this).attr('data-id');
                    if (svgInputDataID == stadiumRowDataID) {
                        svgInputElement = $(this);
                    }
                });
                var input_id = $(svgInputElement).attr('id');
                var input_val = $(this).val();
                var label = $(this).attr('data-label');
                var id = $(this).attr('data-id');

                var actual_id = input_id.replace('svg_input_', '');
                $('#' + actual_id).trigger('mouseenter');

                $('.ticket-price-settings:not(.fixActive)').removeClass('active');
                var id = $(this).attr('data-id');
                $('.ticket-price-settings[data-id="' + id + '"]').addClass('active');
            }
        }).on('mouseleave', function(e) {
            if (!$(this).hasClass('disabledRow') && !$(this).hasClass('activeStadiumRow')) {
                if (!$(this).hasClass('fixActive')) {
                    $(this).removeClass('active');
                }
                var stadiumRowDataID = $(this).attr('data-id');
                var svgInputElement;
                $('.svg_input').each(function(e) {
                    var svgInputDataID = $(this).attr('data-id');
                    if (svgInputDataID == stadiumRowDataID) {
                        svgInputElement = $(this);
                    }
                });
                var input_id = $(svgInputElement).attr('id');
                var actual_id = input_id.replace('svg_input_', '');
                $('#' + actual_id).trigger('mouseleave');
            }
        }).on('click', function(e) {
            if (
                !$(this).hasClass('disabledRow') &&
                !$(this).hasClass('activeStadiumRow') &&
                !$(e.target).hasClass('fa-times') &&
                !$(e.target).hasClass('ticketPriceInput')
            ) {
                var stadiumRowDataID = $(this).attr('data-id');
                var svgInputElement;
                $('.svg_input').each(function(e) {
                    var svgInputDataID = $(this).attr('data-id');
                    if (svgInputDataID == stadiumRowDataID) {
                        svgInputElement = $(this);
                    }
                });
                var input_id = $(svgInputElement).attr('id');
                var actual_id = input_id.replace('svg_input_', '');

                var checkmarkFlg = false;
                if ($(e.target).hasClass('checkmark')) {
                    checkmarkFlg = true;
                }

                $('#' + actual_id).trigger('click', [checkmarkFlg]);
                $('#' + actual_id).trigger('mouseleave');
                if (!$(this).hasClass('fixActive')) {
                    $(this).removeClass('active');
                }
            }
            if (typeof showHideLegend == 'function') {
                showHideLegend();
            }
        });

        $('.svgStadiumBlock').click(function() {
            $(this).autofocus();
        });

        $.fn.autofocus = function() {
            if (!$(this).hasClass('disabledBlock')) {
                var level = $(this).attr('data-level');
                var index = $('#configurator-accordion').find('div.accordionCnt02');
                var accordionDivID;
                for(var j=0; j<index.length; j++){
                    if($(index[j]).attr('data-level') == level){
                        $("#configurator-accordion").accordion("option", "active", j);
                        accordionDivID = index[j].id;
                    }
                }

                if (accordionDivID) {
                    var dataLabel = $(this).attr('data-label');
                    var $this = $(this),
                        $toElement      = '#stadium_row_' + dataLabel,
                        $focusElement   = '#stadium_row_' + dataLabel,
                        $offset         = 0,
                        $speed          = 700 * 1 || 500;

                    var $extraOffset = $('.overflow-div').offset().top;

                    $('#' + accordionDivID).find('.overflow-div').animate({
                        scrollTop: $($toElement).offset().top - $extraOffset
                    }, $speed);

                    if ($focusElement) {
                        $($focusElement).focus();
                    }
                }
            }
        };
        $('.deselect-row').on('click', function(e) {
            var elementID = $(this).attr('id');
            var standCode = elementID.replace('stadium_deselect_icon_', '');

            $('#' + standCode).trigger('click');
        });

        if (typeof showHideLegend == 'function') {
            showHideLegend();
        }
    });
}

function checkStandConfiguration(thisObj) {
    $return = true;
    var level = $(thisObj).attr('data-level');

    for (var standCode in stadiumModificationData) {
        if (stadiumModificationData.hasOwnProperty(standCode)) {
            if (stadiumModificationData[standCode]['startModification']) {
                if ($('#stadium_row_' + standCode).find('.myRange').attr('data-initial') == '1') {
                    $return = false;
                }
            }
        }
    }

    if ($('#allBlockTotalCost').parent().hasClass('insufficient-fund') || $('#allBlockTotalCost').text() == "0") {
        $return = false;
    }

    return $return;
}

function unlockLevel(thisObj) {
    var parentClearArr = [];
    var otherStandArr = [];
    for (var standCode in stadiumModificationData) {
        if (stadiumModificationData.hasOwnProperty(standCode)) {
            if (stadiumModificationData[standCode]['modDependence'].length) {
                var isParentClear = true;
                for (var key in stadiumModificationData[standCode]['modDependence']) {
                    if (stadiumModificationData[standCode]['modDependence'].hasOwnProperty(key)) {
                        var dependenceShortCode = stadiumModificationData[standCode]['modDependence'][key];
                        if(!$('#' + dependenceShortCode).is('.activeBlock, .constructionBlock, .builtBlock')) {
                            isParentClear = false;
                        }
                    }
                }
                otherStandArr.push(standCode);
                if (isParentClear) {
                    parentClearArr.push(standCode);
                }
            }
        }
    }

    otherStandArr.forEach(function(standCode) {
        if (parentClearArr.includes(standCode)) {
            unlockOtherStand([standCode]);
        } else {
            lockOtherStand([standCode]);
        }
    });

    if (typeof showHideLegend == 'function') {
        showHideLegend();
    }
    addRemoveDeselect();
}

function unlockOtherStand(standArr) {
    standArr.forEach(function(v) {
        if ($('#' + v + '').hasClass('disabledBlock')) {
            $('#svg_input_' + v).val(1);
            
            $('#' + v).removeClass('disabledBlock')
            $('#' + v + '>g.declined').css('display', 'none');
            $('#' + v).addClass('conPossibleBlock');
            $('#' + v + '>g.con_possible').css('display', 'block');

            $('#stadium_row_' + v).removeClass('disabledRow');
            $('#stadium_business_row_' + v).removeClass('disabledRow');
            $('#stadium_box_row_' + v).removeClass('disabledRow');
            $('#stadium_range_' + v).removeAttr('disabled');
            $('#stadium_range_' + v).attr('data-initial', 1);
            $('#stadium_business_range_' + v).removeAttr('disabled');
            $('#stadium_box_range_' + v).removeAttr('disabled');
            $('#stadium_roof_' + v).removeClass('disabled-checkbox');
        }
    });
}

function lockOtherStand(standArr) {
    standArr.forEach(function(v) {
        $('#svg_input_' + v).val(0);
        
        $('#' + v).addClass('disabledBlock')
        $('#' + v + '>g.declined').css('display', 'block');
        $('#' + v + '').removeClass('conPossibleBlock');
        $('#' + v + '>g.con_possible').css('display', 'none');

        $('#stadium_row_' + v).addClass('disabledRow');
        $('#stadium_business_row_' + v).addClass('disabledRow');
        $('#stadium_box_row_' + v).addClass('disabledRow');
        $('#stadium_range_' + v).attr('disabled', true);
        $('#stadium_range_' + v).attr('data-initial', 1);
        $('#stadium_business_range_' + v).attr('disabled', true);
        $('#stadium_box_range_' + v).attr('disabled', true);
        $('#stadium_roof_' + v).addClass('disabled-checkbox');
    });
}

function addRemoveDeselect() {
    var lockStandArr = [];
    var otherStandArr = [];
    for (var standCode in stadiumModificationData) {
        if (stadiumModificationData.hasOwnProperty(standCode)) {
            if (stadiumModificationData[standCode]['modDependence'].length) {
                for (var key in stadiumModificationData[standCode]['modDependence']) {
                    if (stadiumModificationData[standCode]['modDependence'].hasOwnProperty(key)) {
                        var dependenceShortCode = stadiumModificationData[standCode]['modDependence'][key];
                        if ($('#' + dependenceShortCode).is('.activeBlock, .constructionBlock, .builtBlock')) {
                            if(!otherStandArr.includes(dependenceShortCode)) {
                                otherStandArr.push(dependenceShortCode);
                            }
                        }
                        if ($('#' + standCode).is('.activeBlock, .constructionBlock, .builtBlock')) {
                            if(!lockStandArr.includes(dependenceShortCode)) {
                                lockStandArr.push(dependenceShortCode);
                            }
                        }
                    }
                }
            }
        }
    }

    otherStandArr.forEach(function(standCode) {
        if (!startModificationStands.includes(standCode)) {
            if (lockStandArr.includes(standCode)) {
                detacheStandFunctionality([standCode]);
            } else {
                attachStandFunctionality([standCode]);
            }
        }
    });
}

function detacheStandFunctionality(standArr) {
    standArr.forEach(function(v) {
        if (!$('#' + v).is('.constructionBlock, .builtBlock')) {
            $('#stadium_deselect_icon_' + v).hide();
            $('#stadium_range_' + v).removeAttr('data-initial');
            $('#' + v).addClass('clickoff');
            $('#' + v).off('click.clickoff');
            $('#' + v).on('click.clickoff', function() {
                $(this).clickoffFn();
            });
        }
    });
}

function attachStandFunctionality(standArr) {
    standArr.forEach(function(v) {
        if ($('#' + v).hasClass('conPossibleBlock')) {
            $('#stadium_deselect_icon_' + v).show();
        }
        $('#stadium_range_' + v).attr('data-initial', 0);
        $('#' + v).removeClass('clickoff');
        $('#' + v).off('click.clickoff');
    });
}

function activeAllStand(thisObj) {
    var standCode = $(thisObj).attr('id').replace('stadium_range_', '');
    if($('#' + standCode).hasClass('activeBlock') == false) {
        var level = $(thisObj).attr('data-level');
        var count = 0;
        var clickedItem = '';
        var standArr = [];
        $('.myRange').each(function(e) {
            var elementLevel = $(this).attr('data-level');
            if (elementLevel == level) {
                var elementStandCode = $(this).attr('id').replace('stadium_range_', '');
                standArr.push(elementStandCode);
            }
        });

        standArr.forEach(function(v) {
            if($('#stadium_row_' + v).find('.myRange').attr('data-initial') != '1') {
                count ++;
                clickedItem = v;
            }
        }); 
        if (count == 1) {
            standArr.forEach(function(v) {
                if (clickedItem != v) {
                    $('#' + v).trigger('click');
                }
            });
            setTimeout(function() {
                unlockLevel(thisObj);
            }, 10);
        }
    }
}

function deactiveAllStand(thisObj) {
    var standCode = $(thisObj).attr('id').replace('stadium_range_', '');
    if ($('#' + standCode).hasClass('clickoff') == false) {
        var standCode = $(thisObj).attr('id').replace('stadium_range_', '');
        if($('#' + standCode).hasClass('activeBlock') == true) {
            var level = $(thisObj).attr('data-level');
            var count = 0;
            var clickedItem = '';
            var standArr = [];
            $('.myRange').each(function(e) {
                var elementLevel = $(this).attr('data-level');
                if (elementLevel == level) {
                    var elementStandCode = $(this).attr('id').replace('stadium_range_', '');
                    standArr.push(elementStandCode);
                }
            });
            standArr.forEach(function(v) {
                if($('#' + v).attr('data-deselect') == null) {
                    count ++;
                    clickedItem = v;
                }
            });
            if (count == 1) {
                standArr.forEach(function(v) {
                    if (clickedItem != v) {
                        $('#' + v).trigger('click');
                    }
                });
                setTimeout(function() {
                    unlockLevel(thisObj);
                }, 10);
            }
        }
    }
}