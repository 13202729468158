"use strict";
(function(olTrans, $, undefined) {

    olTrans.textToHtml = function(text) {
        return text.replace(/&/g, '&amp;')
                   .replace(/'/g, '&apos')
                   .replace(/"/g, '&quot;')
                   .replace(/</g, '&lt;')
                   .replace(/>/g, '&gt;');
    };


    // TML = Tiny Markup Language
    //
    // \n\n     separates paragraphs (text is included in <p>'s)
    // \n       ends line (<br /> ist added)
    // !!...!!  bold
    // !_..._!  bold underline
    // ??...??  italic
    // ?_..._?  italic underline
    // --...--  strikethru
    // __...__  underline
    // °        &nbsp;
    //

    olTrans.tmlToHtml = function(text) {
        text = text.replace(/\r\n/g, '\n');
        var html = '';
        var paras = text.split(/\n\n/);
        for (var i = 0;  i < paras.length;  i++) {
            var para = paras[i];
            html += '<p>'+olTrans.tmlParaToHtml(para)+'</p>';
        }
        return html;
    };

    olTrans.tmlParaToHtml = function(text) {
        text = text.replace(/\r\n/g, '\n');
        var html = '';
        var lines = text.split(/\n/);
        for (var i = 0;  i < lines.length;  i++) {
            var line = lines[i];
            html += olTrans.tmlLineToHtml(line)+'<br />';
        }
        html = html.replace(/<br \/>$/, '');
        return html;
    };

    olTrans.tmlLineToHtml = function(text) {
        return olTrans.textToHtml(text)
               .replace(/!!(.*?)(!?)!!/g, '<b>$1$2</b>')
               .replace(/!_(.*?)_!/g, '<b><u>$1</u></b>')
               .replace(/\?\?(.*?)(\??)\?\?/g, '<i>$1$2</i>')
               .replace(/\?_(.*?)_\?/g, '<i><u>$1</u></i>')
               .replace(/--(.*?)(-?)--/g, '<del>$1$2</del>')
               .replace(/__(.*?)(_?)__/g, '<u>$1$2</u>')
               .replace(/°/, '&nbsp;');
    };


    olTrans.htmlToTml = function(text) {
        text = text.replace(/<\/p>/g, '').replace(/^<p>/, '');
        var tml = '';
        var paras = text.split('<p>');
        for (var i = 0;  i < paras.length;  i++) {
            tml += olTrans.htmlParaToTml(paras[i]);
            tml += '\n\n';
        }
        tml = tml.replace(/\n\n$/, '');
        return tml;
    };

    olTrans.htmlParaToTml = function(text) {
        text = text.replace(/<br\s*\/?>/g, '<br>');//.replace(/<br>$/, '');
        var tml = '';
        var lines = text.split('<br>');
        for (var i = 0;  i < lines.length;  i++) {
            tml += olTrans.htmlLineToTml(lines[i]);
            tml += '\n';
        }
        tml = tml.replace(/\n$/, '');
        return tml;
    };

    olTrans.htmlLineToTml = function(text) {
        return text.replace(/<b><u>(.*?)<\/u><\/b>/g, '!_$1_!')
                   .replace(/<b>(.*?)<\/b>/g, '!!$1!!')
                   .replace(/<i><u>(.*?)<\/u><\/i>/g, '?_$1_?')
                   .replace(/<i>(.*?)<\/i>/g, '??$1??')
                   .replace(/<del>(.*?)<\/del>/g, '--$1--')
                   .replace(/<u>(.*?)<\/u>/g, '__$1__')
                   .replace(/&nbsp;/g, '°')
                   .replace(/\u00a0/g, '°')
                   .replace(/&gt;/g, '>')
                   .replace(/&lt;/g, '<')
                   .replace(/&quot;/g, "'")
                   .replace(/&amp;/g, '&');
    };

}(window.olTrans = window.olTrans || {}, jQuery));
