// use the resources/views/misc/lineDiagram.blade.php template

(function(lineDiagram, $, undefined)
{
    lineDiagram.diagrams = new Object();

    lineDiagram.init  = function(name, config)
    {
        lineDiagram.diagrams[name] = new Object();
        var d = lineDiagram.diagrams[name];
        var dataObjects = new Object();
        var onHoverCallbacks = new Array();
        var onHoverPointCallbacks = new Array();
        var lastHover = false;
        var lastHighlight = new Object();
        lastHighlight.x = false;
        lastHighlight.y = false;
        var lastValidHighlight = new Object();
        lastValidHighlight.x = false;
        lastValidHighlight.y = false;
        
        var data = new Object();
        var visible = new Object();

        var colors = config.colors;
        var data = config.data;
        var dataOffset = config.dataOffset;

        var stepsX = config.stepsX;
        var stepsY = config.stepsY;
        var aspectRatio = config.yxAspectRatio;
        var reverse = config.reverse;

        var width = 0;
        var height = 0;
        var padding = 0;
        var originX = 0, originY = 0, sizeX = 0, sizeY = 0;
        var stepSizeX, stepSizeY;
        var fontSizeDescWidth;
        
        var font = config.font;
        var fontSize = config.fontSize;
        var fontWeight = config.fontWeight;
        var fontSizeWidth = 0;
        
        var highlightRadius = 0;
        
        var xAxisName = config.xAxisName;
        var yAxisName = config.yAxisName;
        
        var strokeBorder = config.strokeBorder; 
        var strokeLine = config.strokeLine;
        var strokeGrid = config.strokeGrid;
        var strokeWidthBorder, strokeWidthGrid, strokeWidthLine;

        var gridColor = config.gridColor;

        var canvasName = "ol-line-diagram-" + name;
        var canvas = $("#" + canvasName);
        var context = $("#" + canvasName)[0].getContext('2d');

        // resize the canvas to fill browser window dynamically
        window.addEventListener('resize', resizeCanvas, false);

        d.getLineColor = function(name)
        {
            counter = 0;
            for (var property in data) 
            {
                if(name == property && visible.hasOwnProperty(property) && data.hasOwnProperty(property))
                    return colors[counter];
                counter++;
            }
        };

        d.showLine = function(name)
        {
            visible[name] = true;
            drawDiagram(true);
        };

        d.hideLine  = function(name)
        {
            delete visible[name];
            drawDiagram(true);
        };
        
        d.pointToAbs = function(x, y)
        {
            return { x: (x * stepSizeX + originX), y: (originY - (stepsY - y) * stepSizeY) };
        }
        
        d.addOnHoverCallback = function(callback)
        {
            onHoverCallbacks.push(callback);
        }
        
        d.addOnHoverPointCallback = function(callback)
        {
            onHoverPointCallbacks.push(callback);
        }

        scale();
        
        for(i = 0; i < config.showLines.length; i++)
        {
            d.showLine(config.showLines[i]);
        }

        resizeCanvas();
        drawDiagram(true);
        
        context.canvas.addEventListener('mouseleave', function() 
        {
            lastValidHighlight.x = false;
            drawDiagram(false);
        });
        
        context.canvas.addEventListener('mousemove', function(evt) 
        {
            var mousePos = getMousePos(context.canvas, evt);
            mousePos.absX = mousePos.x;
            mousePos.absY = mousePos.y;
            highlightPoint(mousePos);
            
            var idx = 4 * (mousePos.x + mousePos.y * context.canvas.width) + 3;
            for (var property in dataObjects) 
            {
                if (dataObjects.hasOwnProperty(property))
                {
                    var value = dataObjects[property][idx];
                    if (value > 0) 
                    {
                        if(lastHover !== property)
                        {
                            if(lastHover !== false)
                                triggerOnHoverCallbacks(lastHover, false);
                            triggerOnHoverCallbacks(property, true);
                            lastHover = property;
                            return;
                        }
                        return;
                    }
                }
            }
            
            if(lastHover !== false)
            {
                triggerOnHoverCallbacks(lastHover, false);
                lastHover = false;
                return;
            }
            
        }, false);
        
        function highlightPoint(mousePosition)
        {
                //console.info(mousePosition.x + " / " + mousePosition.y);
            var relX = mousePosition.x - originX;
            var relY = originY - mousePosition.y;
            
            var pX = parseInt((relX + 0.5 * stepSizeX) / stepSizeX);
            var pY = parseInt((relY + 0.5 * stepSizeY) / stepSizeY);
            var modX = Math.abs(pX * stepSizeX - relX);
            var modY = Math.abs(pY * stepSizeY - relY);

            pY = stepsY - pY;
            if(modX < strokeWidthLine * 2 && modY < strokeWidthLine * 2)
            {
                if(pX !== lastHighlight.x && pY !== lastHighlight.y)
                {
                    lastHighlight.x = pX;
                    lastHighlight.y = pY;
                    lastHighlight.absX = mousePosition.absX;
                    lastHighlight.absY = mousePosition.absY;
                    // draw
                    drawDiagram(false);
                }
            }
            else if(lastHighlight.x !== false)
            {
                triggerOnHoverPointCallback(lastHighlight, false);
                lastHighlight.x = false;
                lastHighlight.y = false;
                // draw
                drawDiagram(false); 
            }
        }

        function touchHighlight(touchEvent) {
            mousePosition = {x: touchEvent.offsetX, y: touchEvent.offsetY};
            var relX = mousePosition.x - originX;
            var relY = originY - mousePosition.y;
            
            var pX = parseInt((relX + 0.5 * stepSizeX) / stepSizeX);
            var pY = parseInt((relY + 0.5 * stepSizeY) / stepSizeY);
            var modX = Math.abs(pX * stepSizeX - relX);
            var modY = Math.abs(pY * stepSizeY - relY);

            lastHighlight.x = pX;
            lastHighlight.y = pY;
            drawDiagram(false);
        }

        if (!olAppInfo.APP_MODE) {
            $(document).on('touchstart', touchHighlight);
        }
            
        function triggerOnHoverPointCallback(mousePos, hoverIn, property)
        {
            for(i = 0; i < onHoverPointCallbacks.length; i++)
            {
                onHoverPointCallbacks[i](mousePos, hoverIn, name, property);
            }
        }
        
        function triggerOnHoverCallbacks(name, hoverIn)
        {
            for(i = 0; i < onHoverCallbacks.length; i++)
            {
                onHoverCallbacks[i](name, hoverIn);
            }
        }
                
        function getMousePos(canvas, evt) 
        {
            var rect = canvas.getBoundingClientRect();
            return {
                x: evt.clientX - rect.left,
                y: evt.clientY - rect.top
            };
        }
        
        function scale()
        {
            width = canvas.innerWidth();
            height = canvas.innerHeight();
            padding = 0.05 * width;
            originX = padding;
            originY = height - padding;
            sizeX = width - 2 * padding;
            sizeY = height - padding * 1.3;
            strokeWidthBorder = width * strokeBorder;
            strokeWidthLine = width * strokeLine;
            strokeWidthGrid = width * strokeGrid;
            highlightRadius = 1.5 * strokeWidthLine;
            fontSizeWidth = Math.max(12, parseInt(width / 1380.0 * fontSize));
            fontSizeDescWidth = Math.max(12, parseInt(width / 1000.0 * fontSize));
            stepSizeX = sizeX / (stepsX - 1);
            stepSizeY = sizeY / (stepsY - 1);

            if ($("body").hasClass('ol-xs') || $("body").hasClass('ol-sm')) {
                strokeWidthBorder = Math.max(strokeWidthBorder, 2)
                strokeWidthLine = Math.max(strokeWidthLine, 2)
                strokeWidthGrid = Math.max(strokeWidthGrid, 1)
            }
        }

        function resizeCanvas() 
        {
            context.canvas.width = context.width = canvas.parent().innerWidth();
            context.canvas.height = context.width * aspectRatio;

            context.clearRect( 0, 0, context.canvas.width, context.canvas.height);
            scale();
            drawDiagram(true);
        }

        function getControlPoints(x0,y0,x1,y1,x2,y2,t)
        {
            var d01=Math.sqrt(Math.pow(x1-x0,2)+Math.pow(y1-y0,2));
            var d12=Math.sqrt(Math.pow(x2-x1,2)+Math.pow(y2-y1,2));
            var fa=t*d01/(d01+d12);
            var fb=t*d12/(d01+d12);
            var p1x=x1-fa*(x2-x0);
            var p1y=y1-fa*(y2-y0);
            var p2x=x1+fb*(x2-x0);
            var p2y=y1+fb*(y2-y0);  
            return [p1x,p1y,p2x,p2y];
        }
        
        function drawLine(dataId, color, save)
        {
            if(save && typeof dataObjects[dataId] !== "undefined")
                delete dataObjects[dataId];
            var lastX = originX;
            var lastY = originY;
            var last2X = false;
            var last2Y = false;
            
            context.strokeStyle = color;
            context.lineWidth = strokeWidthLine;
            context.lineCap = 'round';
            context.beginPath();
            $.each(data[dataId], function(index, value) 
            {
                var x = originX + stepSizeX * (index + dataOffset) * config.valueStepSizeX; 
                var y = originY  - (reverse ? (stepsY - value / config.valueStepSizeY) : (value / config.valueStepSizeY)) * stepSizeY;
                if(!lastX)
                {
                    context.moveTo(x, y);
                }
                if(data[dataId].length == 1)
                {
                    context.lineWidth = strokeWidthLine * 2;
                    context.lineTo(x, y);
                }
                if(lastX && lastY && last2X)
                {
                    var c = getControlPoints(last2X, last2Y, lastX, lastY, x, y, 0.00001);
                    context.bezierCurveTo(c[0], c[1], c[2], c[3], x, y);

                   // context.arcTo(rectX + rectWidth, rectY, rectX + rectWidth, rectY + cornerRadius, cornerRadius);
                   // context.lineTo(x, y);
                }
                last2X = lastX;
                last2Y = lastY;
                lastX = x;
                lastY = y;
            });
            context.stroke();
            if(save)
            {
                dataObjects[dataId] = context.getImageData(0, 0, context.canvas.width, context.canvas.height).data;
                context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            }
        }
        
        function drawCollisionLines()
        {
            dataObjects = new Object();
            lastHover = false;
            lastHighlight.x = false;
            lastHighlight.y = false;
            var counter = 0;
            for (var property in data) 
            {
                if (visible.hasOwnProperty(property) && data.hasOwnProperty(property)) 
                {
                    drawLine(property, colors[counter++], true);
                }
                else
                {
                    counter++
                }
            }
        }
        
        function drawLines()
        {
            var counter = 0;
            for (var property in data) 
            {
                if (visible.hasOwnProperty(property) && data.hasOwnProperty(property)) 
                {
                    drawLine(property, colors[counter++], false);
                }
                else
                {
                    counter++
                }
            }
        }
        function drawHighlights()
        { 
          
            if(lastHighlight.x !== false)
            {
                for (var property in data) 
                {
                    if (visible.hasOwnProperty(property) && data.hasOwnProperty(property)) 
                    {
                        if(data[property][lastHighlight.x - dataOffset] == (lastHighlight.y))
                        {
                             console.info("highlight " + lastHighlight.x + " / " + lastHighlight.y + " = " + data[property][lastHighlight.x - dataOffset] + " - " + lastHighlight.y);
                            lastValidHighlight.x = lastHighlight.x;
                            lastValidHighlight.y = lastHighlight.y;
                            triggerOnHoverPointCallback(lastHighlight, true, property);
                            lastValidHighlight.property = property;
                        }
                    }
                }
            }
            if(lastValidHighlight.x !== false)
            {        
                console.info("draw highlight " + lastValidHighlight.x + " / " + lastValidHighlight.y);
                context.beginPath();
                context.arc(originX + lastValidHighlight.x * stepSizeX, originY - (stepsY - lastValidHighlight.y) * stepSizeY, highlightRadius, 0, 2 * Math.PI, false);
                context.fillStyle = d.getLineColor(lastValidHighlight.property);
                context.fill();
            }
        }
        
        function drawDiagram(collision)
        {
            context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            
            if(collision)
                drawCollisionLines();
            drawHighlights();
                
            $("#yCaption")
                    .css("font-size", fontSizeWidth + "pt")
                    .css("font-weight", fontWeight)
                    .css("font-family", font);
            // $("#xCaption")
            //         .css("font-size", fontSizeWidth + "pt")
            //         .css("font-weight", fontWeight)
            //         .css("font-family", font);
            /*
            context.fillStyle = captionColor;
            context.textAlign = 'center';
            context.save();
            context.font= Math.max(100, fontWeight - 200) + " " + fontSizeWidth + "pt " + font;
            context.fillText(xAxisName, originX + sizeX / 2, height - fontSizeWidth/2);
            context.translate(0, height);
            context.rotate(-Math.PI / 2);
            context.fillText(yAxisName, originX + sizeY / 2, fontSizeWidth);
            context.restore();
            */
            context.textAlign = 'center';
            context.font= fontWeight + " " + fontSizeWidth + "pt " + font;
            context.fillStyle = "#000000";

            var isXS = $('body').hasClass('ol-xs');
            
            for(_x = 0; _x < stepsX; _x++)
            {
                var xIndex = _x + config.valueOffsetX;

                var left = (originX + stepSizeX * _x) - $("#xDescValue" + xIndex).width() * 0.5;
                var top =  (originY + 0.35 * padding) - $("#xDescValue" + xIndex).height() * 0.5;

                $("#xDescValue" + xIndex)
                        .html(((xIndex + config.start) * config.valueStepSizeX))
                        .css("left", left).css("top", top);
                $('#xCaption').css("top", top);
                
                if (isXS) {
                    $("#xDescValue" + xIndex).css({"left": left + 9, "top": top + 2})
                }

                // hide vertical grid
                // context.beginPath();
                // border = (_x == 0 || _x == (stepsX - 1) || _x == stepsX / 2);
                // if(border)
                //     context.lineWidth = strokeWidthBorder; 
                // else
                //     context.lineWidth = strokeWidthGrid;
                
                // context.strokeStyle = isXS ? "#ddd" : gridColor;
                // context.moveTo(originX + stepSizeX * _x, originY + strokeWidthBorder * 0.5);
                // context.lineTo(originX + stepSizeX * _x, originY - sizeY -  strokeWidthBorder * 0.5);
                // context.stroke();
            }
            for(_y = 0; _y < stepsY; _y++)
            {
                var yIndex = _y + config.valueOffsetY;
                var text = yIndex;
                if(reverse) text = ((stepsY) - yIndex);

                var right = width - (originX - 0.075 * padding - $("#xDescValue" + xIndex).width() * 0.5)
                    
                $("#yDescValue" + yIndex)
                        .html(text * config.valueStepSizeY + config.valueOffsetY )
                        .css("right",  right).css("top", originY - _y * stepSizeY - $("#xDescValue" + xIndex).height() * 0.5);
                
                if (isXS) {
                    $("#yDescValue" + yIndex).css("right",  right-13)
                }

                context.beginPath();
                border = (_y == 0 || _y == (stepsY - 1) || _y == stepsY / 2);
                if(border)
                    context.lineWidth = strokeWidthBorder;
                else
                    context.lineWidth = strokeWidthGrid;
                context.strokeStyle = isXS ? "#ddd" : gridColor;
                context.moveTo(originX - strokeWidthBorder * 0.5, originY - _y * stepSizeY);
                context.lineTo(originX + sizeX +  strokeWidthBorder * 0.5, originY - _y * stepSizeY);
                context.stroke();
            }

            drawLines();
        }
    }

}( window.lineDiagram = window.lineDiagram || {}, jQuery ));