(function(olFriendlyResult, $, undefined) {

    olFriendlyResult.welectLoaded = false;
    
    olFriendlyResult.initWelect = function(welectURL, id) {
        // first unload existing script
        $('#welectAd').remove();
        var script = document.createElement('script');
        script.setAttribute("id", "welectAd");
        script.onload = function() {
            olFriendlyResult.welectLoaded = true;
            runWelect(id);
        };
        script.src = welectURL; 

        document.head.appendChild(script); 
    };
    
    function runWelect(id) {
        function _loadOverlay(id) {
            olOverlayWindow.load('/friendly/results/startoverlay', {}, function() {
                olFriendlyOffer.start(id, false);
            });            
        }

        function _showWelect(id) {
            Welect.checkAvailability({ 
                onAvailable: function() {
                    Welect.runSession({onSuccess: function() {  
                        _loadOverlay(id);
                    }, onCancel: function() {}});
                }, 
                onUnavailable: function() {
                    _loadOverlay(id);
                }
            });
        }
        
        Welect.checkToken({ onValid: function() { _showWelect(id); }, onInvalid: function() { _showWelect(id); } });
    }

}(window.olFriendlyResult = window.olFriendlyResult || {}, jQuery));
