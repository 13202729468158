"use strict";
(function(olOfficeTraining, $, undefined) {

    var cfg = false;
    var day = false;
    var slot = false;
    var salaryTableFix = 0;
    var trainingGroup = new Object();
    var trainingGroupComponents = new Object();
    
    olOfficeTraining.loadProgressBar = function(progressBarValueSet) {
        progressBarValueSet = parseInt(progressBarValueSet);
        var targetValue = parseInt($(".training-progressbar .ol-progressbar-large").attr("data-value"));
        $(".training-progressbar #progressBar").width(targetValue + "%");
        $("#progressBarExpander").width((100 - targetValue) + "%");
        var width = $(".training-progressbar .ol-progressbar-large").width();
        console.info(width);
        var relWidth = (width * 0.09) < 150 ? (110 / width * 100) : 0;
        var left = targetValue > 95 ? Math.min(targetValue - Math.max(relWidth, 9)) : targetValue;
        $(".training-progressbar #progressBarValue").css("left", left + "%");
        
        $({countNum: 0}).animate({countNum: progressBarValueSet}, {
            duration: 2000,
            easing: 'easeOutQuad',
            step: function() {
                if (this.countNum < 8) {
                    var value = parseInt(this.countNum + 1);
                }
                $(".training-progressbar #progressBarValue span:first-child").html(value + '* ');
            },
            complete: function() {
            }
        });
    };

    olOfficeTraining.practiceCourtWizardOnClickExecute = function() {
        var data = new Object();
        data.value = olGUI.stripNumericString($("#sliderInput").val());
        data.execute = 0;
        olOverlayWindow.addLoadingAnimation();
        count = $("#infrastructureCount").val();
        $.getJSON('/office/training/infrastructure/buy', {numberOfPracticeCourts: count}, function(responseData) {
            olOverlayWindow.removeLoadingAnimation();
            if (responseData.check === "failed") {
                olMsgBox.json(responseData);
            } else if (responseData.check === "success") {
                olMsgBox.json(responseData, function() {
                    data.execute = 1;
                    $.getJSON('/office/training/infrastructure/buy', {numberOfPracticeCourts: count}, function(responseData) {
                        olGUI.updateLiquidFunds();
                        loadPage('/office/training', undefined, undefined, '');
                        olOverlayWindow.close();
                    });
                });
            }
        });
    };

    olOfficeTraining.onClickDismissStaff = function(staffId, textheader, text) {
        olMsgBox.question(textheader, text, function() {
            $.getJSON('/office/training/staff/dismiss', {staffId: staffId, textHeader: textheader, text: text}, function(responseData) {
                if (responseData.check === "failed")
                    olMsgBox.json(responseData);
                if (responseData.check === "success")
                    olMsgBox.json(responseData, function() {
                        loadPage('/office/training',{}, function() {
                            olOverlayWindow.load('/office/training/staff');
                        });
                    });
            });
        });
    };

    olOfficeTraining.onClickHireStaff = function() {
        var msgBoxId = 'hireCoTrainer';
        // var staff = $("#trainer_assistent").val();
        var staff = $('#hireStaffVal').attr('value');
        
        if (staff == "CO_TRAINER") {
            msgBoxId = 'hireCoTrainer';
        }
        if (staff == "ASSISTANT") {
            msgBoxId = 'hireAssistant';
        }
        olMsgBox.questionById(msgBoxId, function() {
            olOverlayWindow.addLoadingAnimation();
            $.getJSON('/office/training/staff/hire', {staff: staff}, function(responseData) {
                olOverlayWindow.removeLoadingAnimation();
                if (responseData.check === "failed")
                    olMsgBox.json(responseData);
                if (responseData.check === "success")
                    olMsgBox.json(responseData, function() {
                        loadPage('/office/training',{}, function() {
                            olOverlayWindow.load('/office/training/staff');
                        });
                        olGUI.updateLiquidFunds();
                    });
            });
        });
    };

    olOfficeTraining.onClickInfrastructureSell = function(rebuildPracticeCourts) {
        $('.infrastructure-buildRebuild').prop("disabled", true);
        $.getJSON('/office/training/infrastructure/sell', {rebuildPracticeCourts: rebuildPracticeCourts, execute: false}, function(responseData) {
            olOverlayWindow.removeLoadingAnimation();
            $('.infrastructure-buildRebuild').prop("disabled", false);
            olMsgBox.json(responseData, function() { // yes
                $.getJSON('/office/training/infrastructure/sell', {rebuildPracticeCourts: rebuildPracticeCourts, execute: true}, function(responseData) {
                    olMsgBox.json(responseData);
                    loadPage('/office/training',{}, function() {
                        olOverlayWindow.load('/office/training/infrastructure');
                    });
                    olGUI.updateLiquidFunds();
                });
            },
            function() { // no
                olOverlayWindow.close();
            });
        });
    };

    olOfficeTraining.onClickBuyPracticeCourt = function(textheader, text) {
        var buildPracticeCourts = document.getElementById('buildPracticeCourts').innerHTML;
        count = $("#infrastructureCount").val() - buildPracticeCourts;
        var $oneTimeCosts = $('#one-time-costs'),
            oneTimeCosts = $oneTimeCosts.text();
        var replacement = {':value': olGUI.addCurrencySymbol(oneTimeCosts)};
        var qId = 'questionBuyInfrastructure';
        if (count < 0) {
            qId = 'questionSellInfrastructure';
        }
        $('.infrastructure-buildRebuild').prop("disabled", true);
        olMsgBox.questionById(qId, function() {
            var buildPracticeCourts = document.getElementById('buildPracticeCourts').innerHTML;
            count = $("#infrastructureCount").val() - buildPracticeCourts;
            // bei negativen Werten Rueckbau der Trainingsplaetze
            if (count < 0) {
                $('.infrastructure-buildRebuild').prop("disabled", false);
                olOfficeTraining.onClickInfrastructureSell(count);
            } else {
                $.getJSON('/office/training/infrastructure/buy', {numberOfPracticeCourts: count}, function(responseData) {
                    $('.infrastructure-buildRebuild').prop("disabled", false);
                    olOverlayWindow.removeLoadingAnimation();
                    if (responseData.check === "failed")
                        olMsgBox.json(responseData);
                    if (responseData.check === "success")
                        olMsgBox.json(responseData, function() {
                            loadPage('/office/training', {}, function() {
                                olOverlayWindow.load('/office/training/infrastructure');
                            });

                            olGUI.updateLiquidFunds();
                        });
                });
            }
        }, function() { 
            $('.infrastructure-buildRebuild').prop("disabled", false);
        }, replacement);
    };

    olOfficeTraining.initInfrastructureView = function(config) {
        olGUI.colorizeStateColorElements(config.stateId);
        olGUI.colorizeStateColorElements(config.stateId, "color", true, ".ol-dark-state-color");
        olGUI.colorizeStateColorElements(config.stateId, "background-color", true, ".ol-dark-state-bg-color");
        $('.infrastructure-buildRebuild').hide();
        $('.infrastructure-cancel').hide();
        $('.infrastructure-close').show();
        $('#training-salary-info').hide();
        $("#infrastructureCount").bootstrapSlider({scale: 'linear', tooltip: 'hide'});
        $("#infrastructureCount").slider().on("change", function(slideEvt) {
            var currencySymbolBefore = !config.currencySymbolAfter? config.currencySymbol+' ' : '';
            var currencySymbolAfter = config.currencySymbolAfter? ' '+config.currencySymbol : '';
            
            // fertige Trainingsplaetze
            var buildPracticeCourts = document.getElementById('buildPracticeCourts').innerHTML;
            // Wert Slider
            var value = parseInt(slideEvt.value.newValue);
            $("#snapTarget").find("span").removeClass("active");
            $($("#snapTarget").find("span").get(value - 1)).addClass("active");
            // Bauzeit fuer neue Trainingsplaetze, Bauzeit = 0 wenn keine neue Plaetze angelegt werden sollen 
            var duration = "duration" + (value - buildPracticeCourts);
            // aktuelle jaehrliche fixkosten
            var actualCosts = (config.basePrice+config.basePrice) * (buildPracticeCourts-1) * (1.0 - (config.basePriceFactor * (buildPracticeCourts - 1)));
            if (value == 1) {
                var ongoingCosts = (config.basePrice * (value)) - actualCosts * 1;
                var ongoingCostsComplete = config.basePrice * (value);
            } else {
                var ongoingCosts = (config.basePrice*1)+(config.basePrice * (value-1) * (1.0 - (config.basePriceFactor * (value - 1)))) - actualCosts;
                var ongoingCostsComplete = (config.basePrice*1)+(config.basePrice * (value-1) * (1.0 - (config.basePriceFactor * (value - 1))));
            }
            var oneTimeCosts = config.basePriceOneTimeCosts * (value - buildPracticeCourts);
            if (value - buildPracticeCourts >= 0) {
                $('#playerBuildingTime').html('<span>' + config.transTimeBuildPracticeCourts + '</span><span><span id="duration">0</span> ' + config.transWeeks + '</span>');
                $('#buildingCosts').html('<span>' + config.transBuildingCosts + '</span><span>'+currencySymbolBefore+'<span id="one-time-costs" data-current-value=""></span>'+currencySymbolAfter+'</span>');
                if (value == buildPracticeCourts) {
                    $('#duration').html(0);
                } else {
                    $('#duration').html(config[duration]);
                }

                // Neue Trainingsplaetze sollen angelegt werden
                //Baukosten fuer neue Trainingsplaetze
                if (value - buildPracticeCourts == 2)
                    oneTimeCosts *= (1.0 - config.basePriceOneTimeCostsReduction2);
                if (value - buildPracticeCourts == 3)
                    oneTimeCosts *= (1.0 - config.basePriceOneTimeCostsReduction3);
                $('#overallValue').html(config.transYearFixCost);
                if (value - buildPracticeCourts == 0) {
                    // Kein Ausbau geplant, kein Button
                    $('.infrastructure-buildRebuild').hide();
                    $('.infrastructure-cancel').hide();
                    $('.infrastructure-close').show();
                } else {
                    // Ausbau vorgesehen, Button wird angezeigt
                    $('.infrastructure-buildRebuild').show();
                    $('.infrastructure-cancel').show();
                    $('.infrastructure-buildRebuild').html(config.transBuild);
                    $('.infrastructure-close').hide();
                }
                
                if (buildPracticeCourts - value == 0){
                   ongoingCosts = -0.5; 
                }
                
                $("#one-time-costs").html(new Intl.NumberFormat().format(parseInt(oneTimeCosts + 0.5)));
                $("#ongoing-costs").html(new Intl.NumberFormat().format(parseInt(ongoingCosts + 0.5)));
                $("#ongoing-costs-complete").html(new Intl.NumberFormat().format(parseInt(ongoingCostsComplete + 0.5)));
                $("#ongoing-costs-per-week").html(new Intl.NumberFormat().format(parseInt(ongoingCosts / config.daysPerSeason + 0.5)));
                $('#newFunds').html(olGUI.numberWithPoints($('#funds').attr('data-value') - oneTimeCosts));
                $('#newLiquidFunds').html(olGUI.numberWithPoints($('#liquidFunds').attr('data-value') - oneTimeCosts));
            } else {
                // Anzahl der zurueckbauenden Trainingsplaetze
                var rebuildPracticeCourts = buildPracticeCourts - value;
                // Neuberechnung laufender Fixkosten pro Jahr fuer verbleibende Trainingsplaetze -> mindestens 4000 (wenn value = 1)
                var newActualCosts = (config.basePrice*1)+(config.basePrice * (value-1) * (1.0 - (config.basePriceFactor * (value - 1))));
                // Ersparnis durch Rueckbau
                var spareMoney = actualCosts - newActualCosts;
                if (rebuildPracticeCourts == 1) {
                    trainingsCourtText = config.transPracticeCourtSmall
                } else {
                    trainingsCourtText = config.transPracticeCourtsSmall
                }
                // Anpassen der Anzeige
                $('#playerBuildingTime').html('<span>' + config.transRebuild + '</span><span><span id="duration">' + rebuildPracticeCourts + '</span> ' + trainingsCourtText + '</span>');
                // Rueckbau von Trainingsplaetzen
                if (newActualCosts == 0) {
                    newActualCosts = config.basePrice;
                };
                
                // jaehrliche Fixkosten ersetzen mit 'Erstattung' und dem Betrag der erstattet wird
                $('#overallValue').html(config.transCashBack);
                $('#ongoing-costs').html(new Intl.NumberFormat().format(rebuildPracticeCourts * 37500));
                $('.infrastructure-buildRebuild').show();
                $('.infrastructure-cancel').show();
                $('.infrastructure-close').hide();
                $('.infrastructure-buildRebuild').html(config.transRebuilding);
                // neue jaehrliche Fixkosten
                $('#ongoing-costs-complete').html(new Intl.NumberFormat().format(newActualCosts));
                // auszublendendes -> Baukosten
                $('#buildingCosts').html('<span>' + config.transSpareMoney + '</span><span>'+currencySymbolBefore+'<span id="one-time-costs" data-current-value="">' + new Intl.NumberFormat().format(spareMoney) + '</span>'+currencySymbolAfter+'</span>');
            }
        });
        $("#snapTarget span").bind("click touchstart", function(event) {
            var val = parseInt($(event.target).text());
            $("#infrastructureCount").bootstrapSlider('setValue', val);
            var event = jQuery.Event("change");
            event.value = new Object();
            event.value.newValue = val;
            $("#infrastructureCount").trigger(event);
        });
        $("#snapTarget2 span").bind("click touchstart", function(event) {
            var val = parseInt($(event.target).text());
            $("#infrastructureCount").bootstrapSlider('setValue', val);
            var event = jQuery.Event("change");
            event.value = new Object();
            event.value.newValue = val;
            $("#infrastructureCount").trigger(event);
        });
    };
    olOfficeTraining.initGoalKeeperView = function(config) {
        olGUI.colorizeStateColorElements(config.stateId);
        olGUI.colorizeStateColorElements(config.stateId, "color", true, ".ol-dark-state-color");
        olGUI.colorizeStateColorElements(config.stateId, "background-color", true, ".ol-dark-state-bg-color");
        $("#goalKeeperCount").bootstrapSlider({scale: 'linear', tooltip: 'hide'});
        $("#goalKeeperCount").slider().on("change", function(slideEvt) {
            var value = parseInt(slideEvt.value.newValue);
            $("#snapTarget").find("span").removeClass("active");
            $($("#snapTarget").find("span").get(value)).addClass("active");
            var oneTimeCosts = config.basePriceOneTimeCosts * value;
            oneTimeCosts = parseInt(oneTimeCosts + 0.5);
            $("#one-time-costs").html(oneTimeCosts);
            var ongoingCosts = config.ongoingCostsGoalKeeperTrainer;
            var ongoingCostsComplete = config.ongoingCostsGoalKeeperTrainer * (value);
            $("#ongoing-costs").html(parseInt(ongoingCosts + 0.5));
            $("#ongoing-costs-complete").html(parseInt(ongoingCostsComplete + 0.5));
            $('#newFunds').html(olGUI.numberWithPoints($('#funds').attr('data-value') - oneTimeCosts));
            $('#newLiquidFunds').html(olGUI.numberWithPoints($('#liquidFunds').attr('data-value') - oneTimeCosts));
        });
        $("#snapTarget span").bind("click touchstart", function(event) {
            var val = parseInt($(event.target).text());
            $("#goalKeeperCount").bootstrapSlider('setValue', val);
            var event = jQuery.Event("change");
            event.value = new Object();
            event.value.newValue = val;
            $("#goalKeeperCount").trigger(event);
        });
    };

    olOfficeTraining.initStaffView = function(config) {
        olGUI.colorizeStateColorElements(config.stateId);
        olGUI.colorizeStateColorElements(config.stateId, "color", true, ".ol-dark-state-color");
        olGUI.colorizeStateColorElements(config.stateId, "background-color", true, ".ol-dark-state-bg-color");
        $("#CoTrainerCount").bootstrapSlider({scale: 'linear', tooltip: 'hide'});
        $("#CoTrainerCount").slider().on("change", function(slideEvt) {
            var value = parseInt(slideEvt.value.newValue);
            $("#snapTarget").find("span").removeClass("active");
            $($("#snapTarget").find("span").get(value)).addClass("active");
            var oneTimeCosts = config.basePriceOneTimeCosts * value;
            oneTimeCosts = parseInt(oneTimeCosts + 0.5);
            $("#one-time-costs").html(olGUI.numberWithPoints(oneTimeCosts));
            var ongoingCosts = config.ongoingCostsCoTrainer;
            var ongoingCostsComplete = config.ongoingCostsCoTrainer * (value);
            //$("#ongoing-costs-cotrainer").html(parseInt(ongoingCosts + 0.5));            
            $("#ongoing-costs-cotrainer-complete").html(parseInt(ongoingCostsComplete + 0.5));
            $('#newFunds').html(olGUI.numberWithPoints($('#funds').attr('data-value') - oneTimeCosts));
            $('#newLiquidFunds').html(olGUI.numberWithPoints($('#liquidFunds').attr('data-value') - oneTimeCosts));
            var costsAssistant = parseInt(document.getElementById('ongoing-costs-assistant-complete').innerHTML);
            var completeCosts = costsAssistant + ongoingCostsComplete;
            $('#ongoing-costs-complete').html(olGUI.numberWithPoints(completeCosts));
        });
        $("#AssistantCount").bootstrapSlider({scale: 'linear', tooltip: 'hide'});
        $("#AssistantCount").slider().on("change", function(slideEvt) {
            var value = parseInt(slideEvt.value.newValue);
            $("#snapTarget").find("span").removeClass("active");
            $($("#snapTarget").find("span").get(value)).addClass("active");
            var oneTimeCosts = config.basePriceOneTimeCosts * value;
            oneTimeCosts = parseInt(oneTimeCosts + 0.5);
            $("#one-time-costs").html(oneTimeCosts);
            var ongoingCosts = config.ongoingCostsAssistant;
            var ongoingCostsComplete = config.ongoingCostsAssistant * (value);
            $("#ongoing-costs-assistant").html(parseInt(ongoingCosts + 0.5));
            $("#ongoing-costs-assistant-complete").html(parseInt(ongoingCostsComplete + 0.5));
            $('#newFunds').html(olGUI.numberWithPoints($('#funds').attr('data-value') - oneTimeCosts));
            $('#newLiquidFunds').html(olGUI.numberWithPoints($('#liquidFunds').attr('data-value') - oneTimeCosts));
            var costsTrainer = parseInt(document.getElementById('ongoing-costs-cotrainer-complete').innerHTML);
            var completeCosts = costsTrainer + ongoingCostsComplete;
            $('#ongoing-costs-complete').html(olGUI.numberWithPoints(completeCosts));
        });
        $("#snapTarget span").bind("click touchstart", function(event) {
            var val = parseInt($(event.target).text());
            $("#goalKeeperCount").bootstrapSlider('setValue', val);
            var event = jQuery.Event("change");
            event.value = new Object();
            event.value.newValue = val;
            $("#goalKeeperCount").trigger(event);
        });
    };

    olOfficeTraining.onClickHireGoalKeeper = function() {
        msgBoxId = 'hireKeeper';
        olMsgBox.questionById(msgBoxId, function() {
            var staff = 'GOALKEEPER_TRAINER';
            olOverlayWindow.addLoadingAnimation();
            $.getJSON('/office/training/staff/hire', {staff: staff}, function(responseData) {
                olOverlayWindow.removeLoadingAnimation();
                if (responseData.check === "failed")
                    olMsgBox.json(responseData);
                if (responseData.check === "success")
                    olMsgBox.json(responseData, function() {
                        loadPage('/office/training',{}, function() {
                            olOverlayWindow.load('/office/training/goalkeepercoach');
                        });
                        olGUI.updateLiquidFunds();
                    });
            });
        });
    };

    olOfficeTraining.onClickDismissGoalKeeper = function(staffId, textheader, text) {
        olMsgBox.question(textheader, text, function() {
            $.getJSON('/office/training/staff/dismiss', {staffId: staffId, textHeader: textheader, text: text}, function(responseData) {
                if (responseData.check === "failed")
                    olMsgBox.json(responseData);
                if (responseData.check === "success")
                    olMsgBox.json(responseData, function() {
                        loadPage('/office/training',{}, function() {
                            olOverlayWindow.load('/office/training/goalkeepercoach');
                        });
                    });
            });
        });
    };

    olOfficeTraining.toggleInput = function(config) {
        if ($('#trainer_assistent').val() == 'CO_TRAINER') {
            $('#trainer_assistent').val('ASSISTANT');
            $('.staff').html(Lang.trans('js/office.assistants'));
            $('#ongoing-costs-complete').html(olGUI.numberWithPoints(config.ongoingCostsAssistant));
        } else {
            $('#trainer_assistent').val('CO_TRAINER');
            $('.staff').html(Lang.trans('js/office.coTrainer'));
            $('#ongoing-costs-complete').html(olGUI.numberWithPoints(config.ongoingCostsCoTrainer));
        }
    };

    olOfficeTraining.loadSalaryTableFadeIn = function(url) {
        if (salaryTableFix == 0) {
            $.get(url, function(response)
            {
                $("#training-salary-info").html(response);
                $("#training-salary-info").fadeIn();
                salaryTableFix = 1;
            });
        }
    };

    olOfficeTraining.loadSalaryTableFadeOut = function(url) {
        if (salaryTableFix == 1) {
            $("#training-salary-info").fadeOut();
            salaryTableFix = 0;
        }
    };


    // Kostentabelle jährliche Fixkosten Trainingsplatz
    olOfficeTraining.loadPracticeCourtTableFadeIn = function(url) {
        if (salaryTableFix == 0) {
            $.get(url, function(response) {
                $("#training-practiceCourt-info").html(response);
                $("#training-practiceCourt-info").fadeIn();
                salaryTableFix = 1;
            });
        }
    };

    olOfficeTraining.loadPracticeCourtTableFadeOut = function(url) {
        if (salaryTableFix == 1) {
            $("#training-practiceCourt-info").fadeOut();
            salaryTableFix = 0;
        }
    };

    // modifiziertes olOverlayWindow
    // erneutes Laden des aktuellen Inhaltes des OverlayWindow 
    olOfficeTraining.olOverlayWindowCustomload = function(url) {
        $.get(url, function(response) {
            $(".ol-overlay-window-content").html(response);
        });
    };

    //// Holt den Werte von data-msg-`key`, setzt die {`key2`} aus data-`key2` ein
    //// und liefert den resultierenden Text (dflt='').
    //function getMsgAttr($element, key, dflt) {
    //    if (!($element instanceof jQuery))
    //        $element = $($element);
    //    if (dflt === undefined)
    //        dflt = '';
    //    var value = $element.attr('data-msg-' + key);
    //    if ((value === undefined) || (value === false))
    //        value = dflt;
    //    value = value.replace(/{([^}]+)}/g, function(m0, key) {
    //        var value = $element.attr('data-' + key);
    //        if ((value === undefined) || (value === false))
    //            value = '';
    //        return value;
    //    });
    //    return value.trim();
    //}
    //
    //function olConfirm(element, callbackYes, callbackNo) {
    //    var $element = $(element);
    //    var header = getMsgAttr($element, 'header'),
    //            text = getMsgAttr($element, 'text'),
    //            textYes = getMsgAttr($element, 'textYes'),
    //            textNo = getMsgAttr($element, 'textNo');
    //    // Kein Text => kein Confirm
    //    if (text == '') {
    //        if (typeof callbackYes === 'function')
    //            callbackYes();
    //        return;
    //    }
    //
    //    // Confirm anzeigen
    //    olMsgBox.question(header, text, callbackYes, callbackNo, textYes, textNo);
    //}
    // 
    //function olError(text) {
    //    var header = $('#msgHeader').html() || '',
    //            textYes = $('#msgOk').html() || 'OK';
    //    olMsgBox.msg(header, text, undefined, textYes);
    //}

}(window.olOfficeTraining = window.olOfficeTraining || {}, jQuery));