
(function(self, $, undefined) {
    self.SUBMIT_LOG_ENDPOINT = "/misc/logging/add";


    self.submitLog = function(category, logData) {
        var uId = olGUI.getUid();
        var time = (new Date()).getTime();
        var identifier = btoa("" + uId + "." + time);

        if (!logData) {
            logData = [];
        }

        var data = {
            identifier: identifier,
            category: category,
            userId: uId,
            log: logData
        };

        return new Promise(function(resolve, reject) {
            $.ajax({
                type: "POST",
                url: self.SUBMIT_LOG_ENDPOINT,
                data: JSON.stringify(data),
                contentType: "application/json",
                'processData': false,
                success: function() {
                    resolve(identifier);
                },
                error: function() {
                    reject();
                }
            });

        });
    }


})(olLogging = window.olLogging || {}, jQuery);
