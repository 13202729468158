(function($) {
    $.debug = $.debug || {};
    $.debug.debugNavigation = (function() {
        var timeStart = 6;
        var timeEnd = 24;

        var onChangeDropdownSeason = function(season) {
            $.post('/news/selectionSeason', {'season': season}, reload);
        };

        var onChangeDropdownMatchday = function(matchday) {
            $.post('/news/selectionMatchday', {'matchday': matchday}, reload);
        };

        var onChangeDropdownWeek = function(week) {
            $.post('/news/selectionWeek', {'week': week}, reload);
        };

        var onChangeDropdownStart = function(start) {
            timeStart = start;
            $.post('/news/selectionTime', {'timeStart': timeStart, 'timeEnd': timeEnd}, reload);
        };

        var onChangeDropdownEnd = function(end) {
            timeEnd = end;
            $.post('/news/selectionTime', {'timeStart': timeStart, 'timeEnd': timeEnd}, reload);
        };

        var resetData = function() {
            $.post('/news/selectionReset', {}, reload);
        };

        function reload() {
            loadPageContent('/news', $('#ol-root'));
        }

        return {
            onChangeDropdownSeason: onChangeDropdownSeason,
            onChangeDropdownMatchday: onChangeDropdownMatchday,
            onChangeDropdownWeek: onChangeDropdownWeek,
            onChangeDropdownStart: onChangeDropdownStart,
            onChangeDropdownEnd: onChangeDropdownEnd,
            resetData: resetData
        };
    })();
})(jQuery);