window.olNavigationModules = window.olNavigationModules || {};

// constructor navigation module: geolocation
window.olNavigationModules.geo = (function(olNavGeo, $, packets, subcall, undefined) {
    var _state = -1;
    var _district = -1;
    var _community = -1;

    // navigation, dropdowns, inputs, etc.
    var $nav, $states, $districts, $communities;

    olNavGeo.init = function(config) {
        $nav = $('#navGeolocation');
        $states = $('#dropdown-navStates.dropdown', $nav);
        $districts = $('#dropdown-navDistricts.dropdown', $nav);
        $communities = $('#dropdown-navCommunities.dropdown', $nav);

        olNavGeo.reset();

        if (typeof config.state === typeof 0 && config.state > 0) {
            $states.dropdown().selectByValue(config.state);
            _state = config.state;

            $districts.dropdown().enable();
        }
        if (typeof config.district === typeof 0 && config.district > 0) {
            $districts.dropdown().selectByValue(config.district);
            _district = config.district;

            $communities.dropdown().enable();
        }
        if (typeof config.community === typeof 0 && config.community > 0) {
            $communities.dropdown().selectByValue(config.community);
            _community = config.community;
        }
    };

    packets.geo = function() {
        var data = {};

        if (_state > 0)
            data.state = _state;
        if (_district > 0)
            data.district = _district;
        if (_community > 0)
            data.community = _community;
        if (!($.isEmptyObject(data)))
            data = {region: data};

        return data;
    };

    olNavGeo.reset = function(r) {
        switch (r) {
            default:
                $states.dropdown().enable();
            case 'state':
                $states.dropdown().selectByValue(-1);
                _state = -1;

                $districts.dropdown().disable();
            case 'district':
                $districts.dropdown().selectByValue(-1);
                _district = -1;

                $communities.dropdown().disable();
            case 'community':
                $communities.dropdown().selectByValue(-1);
                _community = -1;
        }
    };

    olNavGeo.onChangeState = function(val) {
        if (typeof val !== typeof 0)
            return;

        if (val === -1) {
            olNavGeo.reset('state');
            subcall();
        } else {
            $districts.dropdown().loadElements('/nav/geo/districts', {state: val}, function(result) {
                $districts.dropdown().selectByValue(-1);
                if (result) {
                    _state = val;
                    $districts.dropdown().enable();
                    subcall();
                } else {
                    $states.dropdown().selectByValue(-1);
                    $districts.dropdown().disable();
                }
            });
        }
    };

    olNavGeo.onChangeDistrict = function(val) {
        if (typeof val !== typeof 0)
            return;

        if (val === -1) {
            olNavGeo.reset('district');
            subcall();
        } else {
            $communities.dropdown().loadElements('/nav/geo/communities', {district: val}, function(result) {
                $communities.dropdown().selectByValue(-1);
                if (result) {
                    _district = val;
                    $communities.dropdown().enable();
                    subcall();
                } else {
                    $districts.dropdown().selectByValue(-1);
                    $communities.dropdown().disable();
                }
            });
        }
    };

    olNavGeo.onChangeCommunity = function(val) {
        if (typeof val !== typeof 0)
            return;

        if (val === -1) {
            olNavGeo.reset('community');
        } else {
            _community = val;
        }
        subcall();
    };
});
