(function(olShareLink, $, undefined) {

    olShareLink.location = function(location) {
        var locationString = String(location);
        var transfermarket = locationString.indexOf("transferlist/");
        var matchdaytable = locationString.indexOf("matchdaytable/");
        var offerId = sessionStorage.getItem("transferPlayer");

        if (transfermarket >= 0 && offerId !== null) {
            // when on transfermarket and a playerprofile is opened
            var url = location.origin + '/transferlist/gettransferlistview?offerId=' + offerId;
            olShareLink.copyLink(url);
            return;
        }

        if (matchdaytable >= 0) {
            var season = $('#dropdown-matchday-table-season-matchdayTable').attr("data-value");
            var matchday = $('#dropdown-matchday-table-matchday-matchdayTable').attr("data-value");
            var league = [$('#dropdown-matchday-table-1-matchdayTable'), $('#dropdown-matchday-table-2-matchdayTable'), $('#dropdown-matchday-table-3-matchdayTable')];
            // when subpage is selected => add subpage param to the end of the url.
            var subpage = '';
            if(location.search.includes('subPage')){
                subpage = location.search;
                subpage = '&' + subpage.slice(1);
            }
            var url = ''
  
            for (var i = 0;  i < league.length;  i++) {
                var $element = league[i];
                if ($element.css('display') != 'none') {
                    var leagueId = $element.attr('data-value');
                    url = location.origin + '/matchdaytable/matchdaytable?season=' + season + '&matchday=' + matchday + '&leagueId=' + leagueId + subpage;
                }
            }

            if(url.length == 0) {
                url = location.origin + '/matchdaytable/matchdaytable?season=' + season + '&matchday=' + matchday + '&leagueId=1' + subpage;
            }

            olShareLink.copyLink(url);
            return;
        }

        olShareLink.copyLink(location.href);
    };

    olShareLink.copyLink = function(link) {
        try {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(link).then(function() {
                    showCopyResult(true);
                }, function(err) {
                    showCopyResult(false);
                });
            } else {
                var ta = document.createElement('textarea');
                ta.style = "position: absolute; top: -1000px; left: -1000px;";
                var t = document.createTextNode(link);
                ta.appendChild(t);
                document.body.appendChild(ta);
                ta.select();
                var copied = false;
                try {
                    copied = document.execCommand("copy");
                    if (!copied) console.log('execCommand() = '+copied);
                } catch(ex) {
                    console.log('execCommand failed: '+ex);
                    copied = false;
                }
                document.body.removeChild(ta);
                showCopyResult(copied);
            }
        } catch(err) {
            console.log('error1: '+err);
            showCopyResult(false);
        }
    };

    function showCopyResult(copied) {
        var transfermarket = String(location).search("transferlist/");
        var toolTip = $('#copyTooltip');
        if (transfermarket > 0) {
            toolTip = $('.ol-transfer-detail-btns #copyTooltip')
        }

        var msg = Lang.trans('js/misc.'+(copied? 'linkCopied' : 'linkNotCopied'));
        toolTip.html(msg);
        toolTip.fadeIn();
        setTimeout(function() {
            toolTip.fadeOut();
        }, 2000);
    }

    olShareLink.pasteLink = function() {
        var input = $('.ol-insert-link-input');
        $('.ol-paste-input-wrapper').toggleClass('input-hidden');

        try { // function throwing error in Firefox
            navigator.clipboard.readText().then(function(clipText) { // paste clipboard content into input
                input.val(clipText);
            });
        } catch (e) {}

        input.on("keypress", function(event) {
            if (event.key === "Enter") {
                event.preventDefault();
                $('#goBtn').click();
            }
        });
    };

    olShareLink.loadLink = function() {
        var $input = $('.ol-insert-link-input');
        var toolTip = $('#inputTooltip');
        var url = $input.val();
        var transfermarket = String(window.location).indexOf("transferlist/");

        //check if link is valid, not empty and from onlinliga
        function isValidUrl(urlString) {
            // check if url is from Ol and same locale
            var urlOl = false;
            var locale = window.location.host;
            if (urlString.indexOf(locale) >= 0) {
                urlOl = true;
            }
            return urlOl;
        }

        if (url.length == 0) {
            // no link provided
            toolTip.html(Lang.trans('js/misc.noLink'))
            toolTip.fadeIn();
            setTimeout(function() {
                toolTip.fadeOut();
            }, 2000);
        } else if (!isValidUrl(url)) {
            // url is invalid
            toolTip.html(Lang.trans('js/misc.linkInvalid'))
            toolTip.fadeIn();
            setTimeout(function() {
                toolTip.fadeOut();
            }, 2000);
        } else {
            // link from ol and same locale
            if (transfermarket >= 0 && olAppInfo.APP_MODE) {
                var offerId = url.replace(/[^0-9]/g,'');
                olTransferList.showPlayerView(offerId);
            } else {
                olAnchorNavigation.load(url);
            }
        }
    };

}(window.olShareLink = window.olShareLink || {}, jQuery));
