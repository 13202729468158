
/** General Ad logic */
(function(olAds, $, undefined) {
    
    var defaultConfig = {
        "autoRefresh": true,
        "minRefreshTime": 21000,
        "autoRefreshSlots": {
            "ax-skyscraper": 60000,
            "ax-skyscraper-left": 60000,
            "ax-billboard-top": 60000
        },
        "maxLogEntries": 50,
        "adProvider": 0,
        "refreshOnPageload": true
    }
    olAds.config = Object.assign({}, defaultConfig, olAds.config);
    olAds.userPlus = false;

    olAds.DEVICE_CLASS_DESKTOP = "desktop";
    olAds.DEVICE_CLASS_TABLET = "tablet";
    olAds.DEVICE_CLASS_MOBILE = "mobile";

    olAds.LOCAL_STORAGE_LOG_NAME = "ol_ad_log";
    olAds.LOGGING_CATEGORY_NAME = "REPORT_ADS";

    olAds.AD_PROVIDER_HIMEDIA = 0;
    olAds.AD_PROVIDER_SNACK = 1;
    olAds.AD_PROVIDER_SPM = 2; // AT/CH
    //olAds.AD_PROVIDER_HIMEDIA_NEW = 3; // UK/US // `headerlist-ads-change`

    // Currently not in use
    olAds.adsense = function(options, delay) {
        if (typeof options === typeof undefined)
            options = {};
        else if (!$.isPlainObject(options)) {
            delay = options;
            options = {};
        }
        try {
            if (typeof delay === typeof 0 && delay > 0)
                setTimeout((adsbygoogle = window.adsbygoogle || []).push, delay, options);
            else if (typeof delay === typeof true && delay)
                $(function() {
                    (adsbygoogle = window.adsbygoogle || []).push(options);
                });
            else
                (adsbygoogle = window.adsbygoogle || []).push(options);
        } catch (e) {
            console.log("Error Loading Ad", e);
        }
    };

    olAds.setUserPlus = function(set) {
        olAds.userPlus = set;
    };

    olAds.check = function(adClass) {
        $('.ol-ad-' + adClass + ':not(.initialized):not([data-show-fallback=false])').each(function() {
            $(this).addClass('initialized');
            if (typeof canShowAds != "undefined" && canShowAds) {
                return;
            }
            $(this).getLoad('/sponsoring/banner');
        });
    };

    /*
        Initializes configuration values and event listeners
    */
    var initialized = false;
    var pageActive = true;
    var skinActive = false;

    var provider;

    olAds.init = function(additionalConfig) {
        if (typeof idhb == "undefined" || !idhb.requestAds) return; // Don't run if IDHB is not ready yet

        if (initialized) return; // Only run once
        if (olAds.userPlus) return;
        initialized = true;

        olAds.config = Object.assign({}, defaultConfig, olAds.config);

        switch (olAds.config.adProvider) {
            case olAds.AD_PROVIDER_HIMEDIA:
                provider = olAdProviderHimedia;
                if (olAds.config.initMode === undefined) olAds.config.initMode = 1;
                olAds.config.initMode = 1;
                break;
            case olAds.AD_PROVIDER_SNACK:
                provider = olAdProviderSnack;
                if (olAds.config.initMode === undefined) olAds.config.initMode = 1;
                break;
            case olAds.AD_PROVIDER_SPM:
                provider = olAdProviderSpm;
                if (olAds.config.initMode === undefined) olAds.config.initMode = 0;
                break;
            default:
                return;
        }

        console.log("* Ads Debug | provider ("+olAds.config.adProvider+"): ", provider); //DBG
        provider.init();

        document.addEventListener('scroll', function() { olAds.loadAxContainers(false); });
        window.addEventListener('blur', function(event) { pageActive = false; });
        window.addEventListener('focus', function(event) { pageActive = true; });
        console.log('* Ads Debug | initMode='+olAds.config.initMode);
        if (olAds.config.initMode == 1) {
            setInterval(function() { olAds.loadAxContainers(false); }, 1000);
        } else if (olAds.config.initMode == 2) {
            olAds.axFirst = true;
            olAds.axTimer = setInterval(function() { 
                if (!window.cmp_done) return;
                olAds.loadAxContainers(olAds.axFirst);
                olAds.axFirst = false; 
            }, 1000);
        } else {
            $(function() { olAds.loadAxContainers(true); });
        }
    };


    // Gets all containers on the site that are marked as persistent and are in need of refresh
    olAds.getPersistentAxContainers = function(forceChange) {
        if (!olAds.config.autoRefresh) {
            return [];
        }

        var now = (new Date()).getTime();
        var newSlots = [];

        $('.ax-container-persistent:visible').each(function() {
            var lastRefresh = $(this).data('last-refresh');
            var autoRefresh = $(this).data('refresh-after') || olAds.config.autoRefreshSlots[$(this).attr('id')];

            if (autoRefresh < olAds.config.minRefreshTime) {
                console.warn("Warning: Auto Refresh time set less than min refresh time");
                autoRefresh = olAds.config.minRefreshTime;
            }


            // Refresh slot if forceChange is set (for page loads) or when autoRefresh interval is reached
            var refreshThis = false;
            if (forceChange) {
                if (!lastRefresh || (now - lastRefresh) > olAds.config.minRefreshTime) {
                    refreshThis = true;
                }
            } else if (!skinActive && autoRefresh && pageActive) {
                if (((now - lastRefresh) > autoRefresh) && $(this).isInViewport()) {
                    refreshThis = true;
                }
            } else if (skinActive && autoRefresh && pageActive && $(this).attr('id') == "ax-skin") {
                if (((now - lastRefresh) > autoRefresh) && $(this).isInViewport()) {
                    refreshThis = true;
                }
            }

            if (refreshThis) {
                newSlots.push($(this));
                $(this).data('last-refresh', now);
            }
        });

        return newSlots;
    };

    // Gets all containers on the site that have not been loaded yet
    function getAxContainers(forceChange) {
        var newSlots = [];

        newSlots = olAds.getPersistentAxContainers(forceChange);
        //console.log("* Ads Debug | Current Persistent slots: ", newSlots.map(function(el) { return el.attr('id') || el.attr('data-name'); }))

        $('.ax-container-ajax[data-loaded="false"]:visible').each(function() {
            if (!$(this).data('lazy-load') || $(this).isInViewport()) {
                var loaded = $(this).attr("data-loaded") == "true";
                if (!loaded) {
                    newSlots.push($(this));
                }

                $(this).attr("data-loaded", "true");
            }
        });

        if (newSlots.length == 0) {
            return newSlots;
        }

        console.log("* Ads Debug | Reloading slots: ", newSlots.map(function(el) { return el.attr('id') || el.attr('data-name'); }))

        newSlots = newSlots.sort(function(a, b) {
            var pa = a.data('priority') || Infinity;
            var pb = b.data('priority') || Infinity;

            return pa - pb;
        });

        return newSlots;
    }

    // Loads Sponsors into Slots if IDHB is unavailable
    olAds.handleBlock = function(slotIds) {
        for (var x = 0; x < slotIds.length; x++) {
            var slot = $(document.getElementById(slotIds[x])).parent();

            if (!slot.data('no-sponsor')) {
                $(document.getElementById(slotIds[x])).parent().getLoad('/sponsoring/banner?container=1');
            }
        }
    };

    // Loads all unloaded or refreshable containers.
    // Returns true if there are no containers to reload
    olAds.loadAxContainers = function(reloadPersistent) {
        console.log("* Ads Debug | loadAxContainers("+reloadPersistent+")");
        return new Promise(function(resolve, reject) {
            if (!initialized) {
                olAds.init();
            }
            if (typeof provider == "undefined") {
                resolve();
                return;
            }
            var newSlots = getAxContainers(reloadPersistent);

            // No slots to load
            if (!newSlots || newSlots.length == 0) {
                resolve("No Slots");
                return;
            }

            var slotIds = newSlots.map(provider.prepareSlot).filter(function(x) { return x !== null; });

            if (olAds.userPlus || !provider.canShowAds()) {
                olAds.handleBlock(slotIds);
                resolve("IDHB blocked or unavailable");
                return;
            }

            var specialSlots = provider.getSpecialSlots(slotIds);

            var normalSlots = slotIds.filter(function(slotId) { return !specialSlots.includes(slotId); });

            console.log("* Ads Debug | Requesting normal slots", normalSlots);
            provider.renderAds(normalSlots);

            console.log("* Ads Debug | Prefetching special slots", specialSlots);
            provider.renderSpecialAds(specialSlots);
        });
    };


    // Submits the logged adRender entries from LocalStorage to the server
    olAds.submitAdLog = function() {
        var adLog = localStorage.getItem(olAds.LOCAL_STORAGE_LOG_NAME) || "";
        var adLogData = olGUI.jsonParse(adLog, []).slice(0, olAds.config.maxLogEntries);
        if (adLogData.length === 0) {
            var text = $('#reportAdsButton').attr('data-text-nodata');
            olMsgBox.msg('', text);
            return;
        }
        adLog = JSON.stringify(adLogData);
        olLogging.submitLog(olAds.LOGGING_CATEGORY_NAME, adLog).then(function(identifier) {
            var text = $('#reportAdsButton').data('text-success');
            text = text.replace(':identifier', identifier);
            olMsgBox.msg('', text);
        }).catch(function(error) {
            console.error(error);
        });
    };


    // Fallback for when ads couldn't be loaded into an element
    olAds.handleFallback = function(elementId) {
        var element = document.getElementById(elementId)
        var container = $(element).parent();
        element.remove();

        console.log("* Ads Debug | Removed Empty Slot with ID", elementId);
        if (container.data('fallback')) {
            if (container.data('fallback') == "sponsor") {
                container.getLoad('/sponsoring/banner?container=1');
            } else if (container.data('fallback') == "none") {
                // Nothing for now
            } else {
                container.getLoad('/sponsoring/banner?container=1');
            }
        } else {
            container.getLoad('/sponsoring/banner?container=1');
        }

    };

    // Logs if a pageview was with or without adblock
    window.addEventListener('load', function() {
        var mobile = olGUI.isMobileDevice() ? "true" : "false";
        if (window.ga && ga.create) {
            $.post('/pageview', { "adblock": false, "mobile": mobile});
        } else {
            $.post('/pageview', { "adblock": true, "mobile": mobile});
        }
    }, false);


    olAds.canShowAds = function() {
        if (location.hostname.includes("onlineliga.ofa")) {
            return true;
        }

        if (typeof idhb == "undefined" || !idhb.requestAds) return false;
        if (olAds.userPlus) return false;

        if (!initialized) {
            olAds.init();
        }

        if (!provider) return false;

        return provider.canShowAds();
    };

    olAds.getDeviceClass = function() {
        var deviceSize = (window.innerWidth > 0) ? window.innerWidth : screen.width;
        if (deviceSize < 768) {
            return olAds.DEVICE_CLASS_MOBILE;
        } else if (deviceSize <= 991) {
            return olAds.DEVICE_CLASS_TABLET;
        } else {
            return olAds.DEVICE_CLASS_DESKTOP;
        }
    };

    olAds.loadAdblockAwareSlots = function() {
        $('.blocker-aware-slot:not(.initialized)').each(function(idx) {
            var name = $(this).data('name');
            var deviceClass = olAds.getDeviceClass();

            var fallback = '/sponsor/smartslot/' + name + '/' + deviceClass + '/fallback';
            var content = '/sponsor/smartslot/' + name + '/' + deviceClass;

            if (olAds.canShowAds()) {
                $(this).getLoad(content);
            } else {
                $(this).getLoad(fallback);
            }

            $(this).addClass('initialized');
        });
    };


    olAds.showCMP = function() {
        try {
            var result = __cmp('showScreen');
        } catch (e) {
            result = null;
        }

        if (result === null) {
            if (olAppInfo.APP_MODE) {
                olMobileApp.showCMP();
            } else {
                try {
                    window.__tcfapi('displayConsentUi', 2, function() { });
                } catch (e) {
                    return false;
                }
            }
        }

        return true;
    };

    /** Checks if the vendorList for the consent wall is present in the CMP vendors */
    olAds.checkVendors = function() {
        var vendors = olConfig.vendorList;
        console.log("--- Checking vendor list (n="+vendors.length+')');
        
        if (typeof __tcfapi === undefined) {
            console.log("*** CMP not loaded");	
            return;
        }
        var tcfVendors = __tcfapi('getTCData').vendor.consents;
        
        var missingVendors = [], v;
        for (var v in vendors) {
            if (!(v in tcfVendors)) missingVendors.push(v);
        }
        
        if (missingVendors.length === 0) {
            console.log('=> All vendors present');
            return true;
        } else {
            console.log('=> missingVendors:', missingVendors);
            return false;
        }
    };

})(olAds = window.olAds || {}, jQuery);
