/*
Anwendungsbeispiel:

<div class="ol-scroll-overlay-parent">
    ...
    <div class="ol-scroll-overlay-content myScrollable">
        Dies ist der zu scrollende Bereich
    </div>
    ...
    <div class="ol-scroll-overlay-area" data-row-count="10" data-row-height="375" data-mode="continuous"></div>
    ...
</div>

olScrollOverlay.create($('.myScrollable'));

oder alle über die Klasse:

olScrollOverlay.createAll();

Parameter:
- Es werden max data-row-count Pfeile im Abstand von data-row-height angezeigt
  (werden automatisch in .ol-scroll-overlay-area eingefügt).
- Klick-Modi (data-mode):
  continuous  scrollt weiter, wenn Maus gedrückt gehalten wird (Default)
  single      nur Scrollen per (wiederholtem) Klick
- data-hide="popover" Popovers schließen, wenn gescrollt wird
- data-delay gibt die Verzögerung der Aktualisierung der Pfeile nach Scrollen an (in ms, default=0)
*/
(function(olScrollOverlay, $, undefined) {
    var _dfltRowCount = 10;
    var _dfltRowsHeight = 3750;
    var _dfltScrollDelay = 0;//250;
    var _dfltScrollStep = 200;
    
    var scrollStep;
    
    olScrollOverlay.createAll = function() {
        $('.ol-scroll-overlay-content').each(function(i, o) {
            olScrollOverlay.create($(o));
        });
    };

    // data-row-count  (max.) Anzahl Pfeile (Dflt=10)
    // data-row-height  Größe der Pfeile-Rows (Dflt=375)
    olScrollOverlay.create = function($scrollContent) {
        if (!$scrollContent.length) return;
        var $scrollParent = $scrollContent.closest('.ol-scroll-overlay-parent');
        if (!$scrollParent.length) return;
        var $scrollArea = $('.ol-scroll-overlay-area', $scrollParent);
        if (!$scrollArea.length) return;

        // Scrollbar erzeugen und einfügen
        var rowCount = Number($scrollArea.attr('data-row-count'));
        if (isNaN(rowCount) || (rowCount <= 0)) rowCount = _dfltRowCount;
        var rowHeight = Number($scrollArea.attr('data-row-height'));
        if (isNaN(rowHeight) || (rowHeight <= 0)) rowHeight = _dfltRowsHeight / rowCount;
        var mode = $scrollArea.attr('data-mode'); // single, [continuous]
        if (!mode) mode = 'continuous';
        var delay = Number($scrollArea.attr('data-delay'));
        if (isNaN(delay)) delay = _dfltScrollDelay;
        
        // Global variables
        scrollStep = Number($scrollArea.attr('data-scroll-step'));
        if (isNaN(scrollStep)) scrollStep = _dfltScrollStep;

        var hide = ($scrollArea.attr('data-hide') || '').trim();
        var hidePopovers = (hide == 'popover');

        var html = '<div class="ol-scroll-overlay-left"';
        if (mode == 'single') {
            html += ' onclick="olScrollOverlay.onClickScrollLeft(this);"';
        } else { // mode == 'continuous'
            html += ' onmousedown="olScrollOverlay.onStartScrollLeft(this);"'+
                    ' onmouseup="olScrollOverlay.onStopScrollLeft(this);"';
        }
        html += '>';
        for (var r = 0;  r < rowCount;  r++) {
            var top = r*rowHeight + rowHeight/2 + 4;
            html += '<div class="ol-scroll-arrow-left icon-icon_scroll_left" style="top: '+top+'px;"></div>';
        }
        html += '</div>';
        html += '<div class="ol-scroll-overlay-right"';
        if (mode == 'single') {
            html += ' onclick="olScrollOverlay.onClickScrollRight(this);"';
        } else { // mode == 'continuous'
            html += ' onmousedown="olScrollOverlay.onStartScrollRight(this);"'+
                    ' onmouseup="olScrollOverlay.onStopScrollRight(this);"';
        }
        html += '>';
        for (var r = 0;  r < rowCount;  r++) {
            var top = r*rowHeight + rowHeight/2 + 4;
            html += '<div class="ol-scroll-arrow-right icon-icon_scroll_right" style="top: '+top+'px;"></div>';
        }
        html += '</div>';
        $scrollArea.html(html);

        // Anpassung bei Scroll registrieren
        $scrollContent.scroll(function() {
            clearTimeout($.data(this, 'scrollTimer'));
            $.data(this, 'scrollTimer', setTimeout(function() {
                if (hidePopovers) $('[data-toggle="popover"]').popover('hide');
                olScrollOverlay.updateScrollArea($scrollContent);
            }, delay));
        });

        // Anzeige initialisieren
        olScrollOverlay.updateScrollArea($scrollContent);
    };


    olScrollOverlay.disableAll = function(disabled/*=true*/) {
        $('.ol-scroll-overlay-content').each(function(i, o) {
            olScrollOverlay.disable($(o), disabled);
        });
    };

    // NOTE: disable(false) doesnt't redisplay scroll bar automatically
    // (call .updateScrollArea($scrollContent) if this is required).
    olScrollOverlay.disable = function($scrollContent, disabled/*=true*/) {
        if (disabled === undefined) disabled = true;

        var scrollContent = $scrollContent[0];
        if (!scrollContent) return;
        var $scrollParent = $scrollContent.closest('.ol-scroll-overlay-parent');
        var $scrollArea = $('.ol-scroll-overlay-area', $scrollParent);

        $scrollArea.attr('data-disabled', disabled? 1 : 0);
        if (disabled) {
            olScrollOverlay.hideScrollArea($scrollContent);
        }
    };


    olScrollOverlay.hideScrollArea = function($scrollContent) {
        var scrollContent = $scrollContent[0];
        if (!scrollContent) return;
        var $scrollParent = $scrollContent.closest('.ol-scroll-overlay-parent');
        var $scrollArea = $('.ol-scroll-overlay-area', $scrollParent);

        // Größe und Position des Overlays anpassen
        $scrollArea.css('top', scrollContent.offsetTop);
        $('.ol-scroll-overlay-left, .ol-scroll-overlay-right', $scrollArea).height($scrollContent.height());

        // Linke und rechte Scrollbar anzeigen/ausblenden
        $('.ol-scroll-overlay-left', $scrollParent).removeClass('scrolling-enabled');
        $('.ol-scroll-overlay-right', $scrollParent).removeClass('scrolling-enabled');
    };


    olScrollOverlay.updateScrollAreas = function() {
        $('.ol-scroll-overlay-content').each(function(i, o) {
            olScrollOverlay.updateScrollArea($(o));
        });
    };

    olScrollOverlay.updateScrollArea = function($scrollContent) {
        var scrollContent = $scrollContent[0];
        if (!scrollContent) return;
        var $scrollParent = $scrollContent.closest('.ol-scroll-overlay-parent');
        var $scrollArea = $('.ol-scroll-overlay-area', $scrollParent);

        var disabled = (Number($scrollArea.attr('data-disabled')) == 1);
        if (disabled) return;

        // Größe und Position des Overlays anpassen
        $scrollArea.css('top', scrollContent.offsetTop);
        $('.ol-scroll-overlay-left, .ol-scroll-overlay-right', $scrollArea).height($scrollContent.height());
        var $leftBar = $('.ol-scroll-overlay-left', $scrollParent);
        var $rightBar = $('.ol-scroll-overlay-right', $scrollParent);

        // Linke und rechte Scrollbar anzeigen/ausblenden
        if (scrollContent.scrollWidth > $scrollContent.width()) {
            if ($scrollContent.scrollLeft() > 0) {
                // space for scrolling left (show left scroll element)
                $leftBar.addClass('scrolling-enabled');
            } else {
                $leftBar.removeClass('scrolling-enabled');
            }
            if (($scrollContent.scrollLeft() + $scrollContent.width()) >= scrollContent.scrollWidth && $scrollContent.scrollLeft() > 0) {
                $rightBar.removeClass('scrolling-enabled');
            } else {
                // space for scrolling to the right (show right scroll element)
                $rightBar.addClass('scrolling-enabled');
            }
        } else {
            $('.scrolling-enabled', $scrollParent).removeClass('scrolling-enabled');
        }
    };


    olScrollOverlay.onClickScrollLeft = function(scrollbar) {
        var $scrollParent = $(scrollbar).closest('.ol-scroll-overlay-parent');
        var $scrollContent = $('.ol-scroll-overlay-content', $scrollParent);
        $scrollContent.animate({scrollLeft: '-=' + scrollStep}, 500, 'easeOutQuad');
    };

    olScrollOverlay.onClickScrollRight = function(scrollbar) {
        var $scrollParent = $(scrollbar).closest('.ol-scroll-overlay-parent');
        var $scrollContent = $('.ol-scroll-overlay-content', $scrollParent);
        $scrollContent.animate({scrollLeft: '+=' + scrollStep}, 500, 'easeOutQuad');
    };


    olScrollOverlay.onStopScrollLeft = function(scrollbar) {
        $(scrollbar).attr('data-scrolling', 0);
    };

    olScrollOverlay.onStartScrollLeft = function(scrollbar) {
        var $scrollParent = $(scrollbar).closest('.ol-scroll-overlay-parent');
        var $scrollContent = $('.ol-scroll-overlay-content', $scrollParent);

        var $scrollbar = $(scrollbar);
        $scrollbar.attr('data-scrolling', 1);
        var step = function() {
            //console.log("left step", $scrollbar.attr('data-scrolling'), $scrollbar.hasClass('scrolling-enabled'));
            //if ($scrollbar.attr('data-scrolling') != 1 || !$scrollbar.hasClass('scrolling-enabled')) console.log("left stopped");
            if ($scrollbar.attr('data-scrolling') != 1) {
                olScrollOverlay.updateScrollArea($scrollContent); // Force update of display
                return;
            }
            if (!$scrollbar.hasClass('scrolling-enabled')) return;
            //console.log("left started");
            $scrollContent.animate({scrollLeft: '-=200'}, {
                duration: 500,
                //easing: 'easeOutQuad',
                easing: 'linear',
                complete: step
            });
        };
        step();
    };

    olScrollOverlay.onStopScrollRight = function(scrollbar) {
        $(scrollbar).attr('data-scrolling', 0);
    };

    olScrollOverlay.onStartScrollRight = function(scrollbar) {
        var $scrollParent = $(scrollbar).closest('.ol-scroll-overlay-parent');
        var $scrollContent = $('.ol-scroll-overlay-content', $scrollParent);

        var $scrollbar = $(scrollbar);
        $scrollbar.attr('data-scrolling', 1);
        var step = function() {
            //console.log("right step", $scrollbar.attr('data-scrolling'), $scrollbar.hasClass('scrolling-enabled'));
            //if ($scrollbar.attr('data-scrolling') != 1 || !$scrollbar.hasClass('scrolling-enabled')) console.log("right stopped");
            if ($scrollbar.attr('data-scrolling') != 1) {
                olScrollOverlay.updateScrollArea($scrollContent); // Force update of display
                return;
            }
            if (!$scrollbar.hasClass('scrolling-enabled')) return;
            //console.log("right started");
            $scrollContent.animate({scrollLeft: '+=200'}, {
                duration: 500,
                //easing: 'easeOutQuad',
                easing: 'linear',
                complete: step
            });
        };
        step();
    };


    $(window).resize(function() {
        olScrollOverlay.updateScrollAreas();
    });

}(window.olScrollOverlay = window.olScrollOverlay || {}, jQuery));
