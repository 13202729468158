/*!
 * Animated Table
 *
 * Copyright, Ricardo Mieres <ricardo@mieres.de>
 */
(function ($) {

    var AnimatedTable = function (element, options) {
        if (typeof  options == 'undefined') {
            alert("please difine the header of the table")
        }
        if (typeof  options.headers != "object") {
            alert("please define the header of the table as object")
        }
        if (typeof  options.rowEffectDelay == "undefined") {
            options.rowEffectDelay = 30
        }
        var effectClass = "bounceInUp";

        var modal = 0;

        var onPreLoadDataList = function () {
            console.debug("you can define this function! onPreLoadDataList")
        };

        var filterDataFunction = function () {
            console.debug("you have to define this function! filterDataFunction")
        };

        var onLoadDataDone = function () {
            console.debug("you have to definde this function! onLoadDataDone")
        };
        var onLoadWebContentDone = function () {
            console.debug("you have to define this function! onLoadWebContentDone")
        };

        if (typeof options.filterDataFunction === "function") {
            filterDataFunction = options.filterDataFunction;
        }

        if (typeof options.onLoadDataDone === "function") {
            onLoadDataDone = options.onLoadDataDone;
        }
        if (typeof options.onLoadWebContentDone === "function") {
            onLoadWebContentDone = options.onLoadWebContentDone;
        }

        if (typeof options.onPreLoadDataList === "function") {
            onPreLoadDataList = options.onPreLoadDataList;
        }

        if (options.effectClass) {
            effectClass = options.effectClass;
        }
        var rowSumDelay = 0
        var currentPage = 0;
        var currentSortField = "";
        var currentSortMode = "";
        var isSortedByContentElement= false;
        var backPosition;
        var dataCount = 0;
        var templateElement = $(element);
        var tableTemplateTitle = templateElement.find('div.title_container');
        var tableTemplateList = templateElement.find('div.list_container');

        var settings = $.extend({
            sourceUrl: templateElement.attr("data-url"),
        }, options || {});

        this.getDataCount = function () {
            return dataCount;
        }

        this.getCurrentSortMode = function () {
            return currentSortMode;
        }
        this.setCurrentSortMode = function (sortMode) {
            currentSortMode = sortMode;
        }
        this.getCurrentSortField = function () {
            return currentSortField;
        }
        this.setCurrentSortField = function (sortField) {
            currentSortField = sortField;
        }
        this.callDataSort = function (filterData, sortField, sortMode ) {
            currentSortField = sortField;
            currentSortMode = sortMode;
            this.callData(filterData)
        }
        this.callData2 = function (filterData) {
            rowSumDelay  = 0
            if (!filterData) {
                filterData = {}
            }
            if (onPreLoadDataList) {
                onPreLoadDataList(filterData)
            }
            $(".tempDivContainer").remove();

            addLoadingAnimationToElement($("#ol-root"));
            $.ajax({
                type: 'get',
                url: settings.sourceUrl,
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                data: filterData,
                context: document.body,
                success: function (jsonData) {
                    currentSortField = jsonData.tableCurrentSortField;
                    currentSortMode = jsonData.tableCurrentSortMode;
                    currentPage = jsonData.tableCurrentPage;
                    isSortedByContentElement = filterData.isSortedByContentElement;
                    if (templateElement.data('animatedTable').renderTable) {
                        templateElement.data('animatedTable').renderTable(
                            jsonData.data[jsonData.tableDataFieldName],
                            jsonData.data[jsonData.tableDataCountFieldName],
                            jsonData.tableCurrentPage
                        );
                    }
                    removeLoadingAnimationFromElement($("#ol-root"));

                    if (onLoadDataDone) {
                        onLoadDataDone (jsonData, rowSumDelay)
                    }
                },
                error: function () {
                    removeLoadingAnimationFromElement($("#ol-root"));
                    if (onLoadDataDone) {
                        onLoadDataDone (null, null)
                    }
                }
            });
        };
        this.callData = function (filterData) {
            rowSumDelay  = 0
            if (!filterData) {
                filterData = {}
            }
            if (currentSortField) {
                filterData.sort = {by: currentSortField, sorting: currentSortMode};
            }

            if (onPreLoadDataList) {
                onPreLoadDataList(filterData)
            }

            $(".tempDivContainer").remove();
            addLoadingAnimationToElement($("#ol-root"));
            $.ajax({
                type: 'get',
                url: settings.sourceUrl,
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                data: filterData,
                context: document.body,
                success: function (jsonData) {
                    currentSortField = jsonData.tableCurrentSortField;
                    currentSortMode = jsonData.tableCurrentSortMode;
                    currentPage = jsonData.tableCurrentPage;
                    isSortedByContentElement = filterData.isSortedByContentElement;
                    if (templateElement.data('animatedTable').renderTable) {
                        templateElement.data('animatedTable').renderTable(
                            jsonData.data[jsonData.tableDataFieldName],
                            jsonData.data[jsonData.tableDataCountFieldName],
                            jsonData.tableCurrentPage,
                            jsonData
                        );
                    }
                    removeLoadingAnimationFromElement($("#ol-root"));
                    if (onLoadDataDone) {
                        onLoadDataDone (jsonData, rowSumDelay)
                    }
                },
                error: function () {
                    removeLoadingAnimationFromElement($("#ol-root"));
                    if (onLoadDataDone) {
                        onLoadDataDone (null, null)
                    }
                }
            });
        };

        this.addData = function () {
            rowSumDelay  = 0
            var  filterData = filterDataFunction();
            if (currentSortField) {
                filterData.sort = {by: currentSortField, sorting: currentSortMode};
            }


            currentPage ++;
            filterData.page = currentPage;
            console.debug(filterData);

            $(".tempDivContainer").remove();
            addLoadingAnimationToElement($("#ol-root"));

            $.ajax({
                type: 'get',
                url: settings.sourceUrl,
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                data: filterData,
                context: document.body,
                success: function (jsonData) {
                    currentPage = jsonData.tableCurrentPage;
                    currentSortField = jsonData.tableCurrentSortField;
                    currentSortMode = jsonData.tableCurrentSortMode;
                    templateElement.data('animatedTable').addElements2Table(
                        jsonData.data[jsonData.tableDataFieldName],
                        jsonData.data[jsonData.tableDataCountFieldName],
                        jsonData.tableCurrentPage,
                        jsonData
                    );
                    removeLoadingAnimationFromElement($("#ol-root"));
                    if (onLoadDataDone) {
                        onLoadDataDone (jsonData, rowSumDelay)
                    }
                },
                error: function () {
                    removeLoadingAnimationFromElement($("#ol-root"));
                    if (onLoadWebContentDone) {
                        onLoadWebContentDone(null, null);
                    }
                }

            });
        };

        this.getRowHeader = function () {

            var headElementList = document.createElement("ul");
            headElementList.setAttribute("class", "list-inline")

            $.each(settings.headers, function (idx, obj) {
                var cellHead = document.createElement("li");
                cellHead.setAttribute("class", "table-cell table-cell-head")
                cellHead.setAttribute("id", "table-cell-" + obj.field)
                cellHead.setAttribute("data-field",  obj.field)
                cellHead.setAttribute("data-field-sort",  obj.fieldSort)

                if (obj.width) {
                    $(cellHead).css("width",obj.width)
                }
                if (obj.textAlign) {
                    $(cellHead).css("text-align",obj.textAlign)
                }
                if (obj.class) {
                    $(cellHead).addClass(obj.class)
                }

                if (obj.sort &&  obj.fieldSort) {

                    if (obj.type == "html") {
                        // to implement in the content element
                    } else {
                        $(cellHead).css("cursor","pointer");

                        if (currentSortField === obj.fieldSort ) {
                            $(cellHead).removeClass("desc");
                            $(cellHead).removeClass("asc");
                            $(cellHead).addClass(currentSortMode);
                        }
                        $(cellHead).click( function (e) {
                            var _this = $(e.target)
                            if (currentSortField !== "") {
                                var headerElement =  $("#table-cell-" + currentSortField );
                                headerElement.parent().find('.table-cell-head').each(function() {
                                    if ($( this ).attr("data-field") !== _this.attr("data-field-sort")) {
                                        $( this ).removeClass("desc");
                                        $( this ).removeClass("asc");
                                    }
                                })
                            }
                            currentSortField = _this.attr("data-field-sort")
                            if (_this.hasClass("asc")) {
                                currentSortMode = "desc";
                                _this.removeClass("asc");
                                _this.addClass("desc");
                            } else {
                                currentSortMode = "asc";
                                _this.removeClass("desc");
                                _this.addClass("asc");
                            }
                            var  filterData = filterDataFunction();
                            addLoadingAnimationToElementNoTimeOut($("#ol-root"));
                            if (templateElement.data('animatedTable')) {
                                templateElement.data('animatedTable').callData(filterData);
                            }
                        })
                    }
                }

                switch (obj.type) {
                    case "GetHtml":
                        $.get(obj.url, function (data) {
                            cellHead.innerHTML = data;
                        });
                        break;
                    default:
                        if (obj.headerHtmlContentId) {
                            var cloneHeaderHtmlContent = $("#" + obj.headerHtmlContentId).clone();
                            cloneHeaderHtmlContent.attr("id", obj.headerHtmlContentId +  "H");
                            cloneHeaderHtmlContent.show();
                            $(cellHead).html(cloneHeaderHtmlContent);


                        } else {

                            var splitValue = obj.value.split("|");
                            if (splitValue.length>1) {
                                splitValue = splitValue.join("<br/>");
                                cellHead.innerHTML = splitValue;
                            } else {
                                cellHead.innerHTML = obj.value;
                            }
                        }
                }
                headElementList.appendChild(cellHead);
            });
            var tableRowHeader = document.createElement("li");


            tableRowHeader.setAttribute("class", "table-row " + effectClass + " rowHead");
            tableRowHeader.setAttribute("id", "itemList_0");
            tableRowHeader.appendChild(headElementList);



            return tableRowHeader;
        }

        this.getRow = function (rowObject) {
            var rowElementList = document.createElement("ul");
            rowElementList.setAttribute("class", "list-inline")
            $.each(settings.headers, function (idx, header) {
                var cell = document.createElement("li");
                cell.setAttribute("class", "table-cell")

                if (header.width) {
                    $(cell).css("width",header.width)
                }
                if (header.textAlign) {
                    $(cell).css("text-align",header.textAlign)
                }
                if (header.class) {
                    $(cell).addClass(header.class)
                }

                switch (header.type) {
                    case "GetHtml":
                        var value = rowObject[header.field];
                        $.get(header.url , {'payload': value}, function (data) {
                            cell.innerHTML = data;
                        });
                        break;
                    default:

                        var splitField = header.field.split("|");
                        if (splitField.length>1) {
                            var value = []
                            for (i = 0; i < splitField.length; i++) {
                                var part = splitField[i].split(",")
                                if (part.length > 1) {
                                    var partValue = []
                                    for (j = 0; j < part.length; j++) {

                                        if(header.spanWrap) {
                                            partValue[j] = "<span class='animatedTable-field-span-wrap-" + part[j] +"'>" + rowObject[part[j]] + "</span>";
                                        } else {
                                            partValue[j] = rowObject[part[j]];
                                        }
                                    }
                                    value[i] = partValue.join(" ");
                                } else {
                                    if(header.spanWrap) {
                                        value[i] = "<span class='animatedTable-field-span-wrap-" + splitField[i] +"'>"+ rowObject[splitField[i]] + "</span>";
                                    } else {
                                        value[i] = rowObject[splitField[i]];
                                    }

                                }
                            }
                            if(header.spanWrap) {
                                cell.innerHTML = value.join("");
                            } else {
                                cell.innerHTML = value.join("<br/>");
                            }

                        } else {
                            var value = rowObject[header.field];
                            if (header.localeString &&
                                header.localeString.locale &&
                                header.localeString.style &&
                                header.localeString.currency) {

                                cell.innerHTML = value.toLocaleString(
                                    header.localeString.locale,
                                    {
                                        style: header.localeString.style,
                                        currency: header.localeString.currency
                                    });

                            } else {

                                cell.innerHTML = value;
                            }
                        }
                }

                if (header.showModal) {
                    $(cell).css("cursor","pointer");
                    $(cell).click( function (e) {

                        var tempDivContainer = document.getElementById("tempDivContainer");
                        if (!tempDivContainer) {
                            var display = $(tempDivContainer).css('display');
                            if (display === 'none' || display == undefined) {
                                modal = 0
                            }
                        }

                        if (modal == 0) {
                            modal = 1
                            header.showModal(rowObject)
                        }
                    });
                   /*
                    $(cell).on('mousedown touchstart', function(){
                        header.onclick(rowObject)
                    }); */

                }

                rowElementList.appendChild(cell);

            });
            var tableRow = document.createElement("li");
            tableRow.setAttribute("class", "table-row");
            tableRow.appendChild(rowElementList);
            return tableRow;
        }
        this.addElements2Table = function (animatedTableData, animatedTableDataCount, currentPage,jsonData){

            var tableList = document.getElementById(templateElement.attr("id") + "TableList");
            if (tableList) {
                var rowSumDelay = 0
                $.each(animatedTableData, function (idx, row) {
                    var rowElement = templateElement.data('animatedTable').getRow(row)
                    setTimeout(function () {
                        rowElement.className = rowElement.className + " "  + effectClass  ;
                        if ((idx % 2) === 0) {
                            rowElement.className = rowElement.className + " rowOdd";
                        } else {
                            rowElement.className = rowElement.className + " rowCouple";
                        }
                        tableList.appendChild(rowElement)

                    }, options.rowEffectDelay * idx);

                    rowSumDelay  += options.rowEffectDelay * idx
                });

            }
        }
        this.getWebContent = function (sourceUrl, params, backLinkLabel) {
            if (modal==1) {
                modal = 2
                addLoadingAnimationToElement($("#ol-root"));
                $.ajax({
                    type: 'get',
                    url: sourceUrl,
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    data: params,
                    context: document.body,
                    success: function (content) {

                        var tempDivContainer = document.getElementById("tempDivContainer");
                        if (!tempDivContainer) {
                            tempDivContainer = document.createElement("div");
                            tempDivContainer.setAttribute("id", "tempDivContainer");
                            tempDivContainer.setAttribute("class", "tempDivContainer");
                            var baseTempDivBaseClass = "tempDivContainer";

                            if (options.tempWrapDivContainerExtraClass) {
                                tempDivContainer.setAttribute(
                                    "class",
                                    baseTempDivBaseClass + " " + options.tempWrapDivContainerExtraClass
                                );
                            }
                        }
                        /*
                          var parentElement =  document.getElementById(options.id);
                          var rect = parentElement.getBoundingClientRect();


                          var bodyRect = document.body.getBoundingClientRect();
                          var elemRect = parentElement.getBoundingClientRect();
                          var offsetTop   = elemRect.top - bodyRect.top;
                          var offsetLeft   = elemRect.left - bodyRect.left;

                          tempDivContainer.style.position = "absolute";
                          tempDivContainer.style.display = "block";
                          tempDivContainer.style.top = offsetTop + "px";
                          tempDivContainer.style.left = offsetLeft + "px";
                          tempDivContainer.style.width = elemRect.width + "px";
                          tempDivContainer.style.height = elemRect.height + "px";
                          tempDivContainer.style.backgroundColor = "white";
                          tempDivContainer.style.zIndex = 3;

                          */
                        console.debug(options, "options")
                        if (options.tempWrapDivContainerId) {
                            var tempWrapContainer = document.getElementById(options.tempWrapDivContainerId);
                            if (tempWrapContainer) {
                                tempWrapContainer.appendChild(tempDivContainer);
                            } else {
                                document.body.appendChild(tempDivContainer);
                            }
                        } else {
                            document.body.appendChild(tempDivContainer);
                        }

                        if (backLinkLabel) {
                            var backLinkContainer = document.createElement("div");
                            backLinkContainer.setAttribute("class", "back-action");
                            backPosition = document.documentElement.scrollTop

                            var backLink = "<i class=\"fa fa-angle-left backLink\"></i>" +
                                "<a  class='backLink'>" + backLinkLabel + "</a>";

                            backLinkContainer.innerHTML = backLink;
                            $(backLinkContainer).click(function () {
                                modal = 0;
                                $(this).parent().remove()
                                $('html, body').animate({
                                    scrollTop: backPosition
                                }, 500);
                            })
                            $(tempDivContainer).append( $(backLinkContainer))
                        }

                        $(tempDivContainer).append(content);

                        $('html, body').animate({
                            scrollTop: 0,
                            complete:function(){
                                $(tempDivContainer).show();
                            }
                        }, 500);

                        removeLoadingAnimationFromElement($("#ol-root"));
                        if (onLoadWebContentDone) {
                            onLoadWebContentDone(content);
                        }
                    },
                    error: function () {
                        removeLoadingAnimationFromElement($("#ol-root"));
                        if (onLoadWebContentDone) {
                            onLoadWebContentDone(null);
                        }
                    }
                });
            }

        }

        this.renderTable = function (animatedTableData, animatedTableDataCount, currentPage,jsonData) {

            tableTemplateList.html("");
            tableTemplateTitle.html("");

            var rowHeader = this.getRowHeader();

            var tableList = document.createElement("ul");

            tableList.setAttribute("id", templateElement.attr("id") + "TableList");
            tableList.setAttribute("class", "list-inline animatedTable")
            tableList.appendChild(rowHeader)

            var tableResultResume = document.createElement("span");

            tableResultResume.setAttribute("class", "animatedTableTitle")
            tableResultResume.innerHTML = tableTemplateTitle.attr("data-table-title").toString().replace(
                "#totalRows#",
                animatedTableDataCount
            );

            tableTemplateTitle.html(tableResultResume)
            tableTemplateList.append(tableList)
            $.each(animatedTableData, function (idx, row) {
                var rowElement = templateElement.data('animatedTable').getRow(row)
                rowElement.setAttribute("id", "itemList" + "_" + (idx + 1));
                setTimeout(function () {
                    rowElement.className = rowElement.className + " "  + effectClass  ;
                    if ((idx % 2) === 0) {
                        rowElement.className = rowElement.className + " rowOdd";
                    } else {
                        rowElement.className = rowElement.className + " rowCouple";
                    }
                    tableList.appendChild(rowElement)

                }, options.rowEffectDelay * idx);
                rowSumDelay += options.rowEffectDelay * idx
            });
            dataCount= animatedTableDataCount;

        }
    };
    $.fn.animatedTable = function (options) {

        var element = $(this);
        options.id = $(this).attr("id")
        if (element.data('animatedTable')) return element.data('animatedTable');
        var aTable = new AnimatedTable(this, options);
        element.data('animatedTable', aTable);

        $(window).scroll(function () {
                if ($(window).scrollTop() + $(window).height() >= (getDocHeight() - 20)) {
                    aTable.addData()
                }
            }
        );
        return aTable;
    };
})(jQuery);
