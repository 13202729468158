(function(olRanking, $, undefined) {

    var view;
    var season;
    var stateId;
    var districtId;
    var communityId;
    var page = 1;
    var first = true;
    var noMoreResults = false;
    var widgetMode = false;
    var sortBy;
    var loading = false;

    // init function, execution in html (blade)
    olRanking.init = function(config){
        
        season = config.season;
        view = config.view;
        stateId = config.stateId;
        districtId = config.districtId;
        communityId = config.communityId;
        sortBy = "points";
        page = 1;
        noMoreResults = false;
        loading = false;

        // update banner title based on ranking type
        var rankingType = (config.rankingType || 'states').toLowerCase();
        if (rankingType === 'states') {
            updateBannerTitle(Lang.trans('js/statistics.state'));
        } else if (rankingType === 'district'){
            updateBannerTitle(Lang.trans('js/statistics.city'));
        } else if (rankingType == 'teams'){
            updateBannerTitle(Lang.trans('js/statistics.team'));
        }
    };

    olRanking.switchState = function(stateId){
        // set filter panel background based on stateId;
        switchStateBackgroundPattern(stateId);
    };

    function switchStateBackgroundPattern(stateId)
    {
        var old = $("#ol-bg-pattern");
        var clone = old.clone();
        old.attr("id", "ol-bg-pattern-old");
        clone.attr("id", "ol-bg-pattern");
        clone.addClass("ol-transparent");
        clone.removeClass (function(index, className) {
            return (className.match (/(^|\s)ol-state-bg-color-\S+/g) || []).join(' ');
        });
        var child = clone.find("div");
        child.removeClass (function(index, className) {
            return (className.match (/(^|\s)ol-state-bg-\S+/g) || []).join(' ');
        });
        child.addClass("ol-state-bg-" + stateId);
        clone.removeClass (function(index, className) {
            return (className.match (/(^|\s)ol-state-bg-color-\S+/g) || []).join(' ');
        });
        clone.addClass("ol-state-bg-color-" + stateId);
        clone.insertAfter(old);
        setTimeout(function()
        {
            clone.removeClass("ol-transparent");
            setTimeout(function() { old.remove(); }, 510)
        }, 1);
    }

    function updateBannerTitle(string) {
        $('.ranking-header-sponsor .ol-title > span').first().text(string);
    }

    function switchContext()
    {
        if(stateId != 0)
            switchStateBackgroundPattern(0);
        //stateId = 0;
        districtId = 0;
        communityId = 0;
        //$('#dropdownState').dropdown().selectByValue(0);
        noMoreResults = false;
        sortBy = "points";
        page = 1;
        olRanking.update();
    }

    olRanking.onClickDistrictsButton = function(wM)
    {
        if(typeof stateId === typeof undefined)
            wM = false;
        widgetMode = wM;
        view = "district";
        switchContext();
    };

    olRanking.onClickStatesButton = function()
    {
       view = "states";
        switchContext();
    };

    olRanking.onClickTeamButton = function()
    {
        view = "teams";
        switchContext();
    };

    olRanking.update = function() {
        if (view == "states") {
            var data = {stateId: stateId, season: season, sortBy: sortBy, page: page};
            loadPageContent('/statistics/ranking/states', $('#ol-ranking-content'), data, false, true);
        } else if(view == "teams") {
            var data = {stateId: stateId, districtId: districtId, communityId: communityId, season: season, page: page};
            loadPageContent('/statistics/ranking/teams', $('#ol-ranking-content'), data, false, true);
        } else {
            var data = {stateId: widgetMode? 0 : stateId, season: season, sortBy: sortBy, page: page};
            loadPageContent('/statistics/ranking/districts', $('#ol-ranking-content'), data, false, true);
        }
    };

    olRanking.scrollContentIntoView = function() {
        window.scrollTo(0, $('#ol-ranking-content').offset().top-50);
    };


}( window.olRanking = window.olRanking || {}, jQuery ));