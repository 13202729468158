/**
 * Manage control buttons of Live Ticker, aka Play/Resume/Pause/End/Settings popup.
 * It cooperates with olLiveTickerTimer object, which is Live Ticker Timer.
 */
(function(olLiveTickerSettingsControl, $, options) {

    var liveModeBlick = null;

    // Init actions of the buttons.
    olLiveTickerSettingsControl.init = function(data, stateId) {
        if  (stateId === undefined) stateId = 0;
        var liveTickerSlider = $('#liveTickerMinutesSlider').bootstrapSlider({scale: 'linear', tooltip: 'hide'});

        liveTickerSlider.on('change', function(e) {
            olLiveTickerSettingsControl.setLiveTickerSliderValue(e.value.newValue, false);
        });

        liveTickerSlider.on('slideStop', function(e) {
            olLiveTickerSettingsControl.setLiveTickerSliderValue(e.value, true);
        });

        var realDuration = 90;

        if (data.liveTickerDuration) {
            realDuration = data.liveTickerDuration;
        }

        liveTickerSlider.bootstrapSlider('setValue', realDuration);
        var valueEl = $('#liveTickerMinutesSliderValue');
        var text = valueEl.text().replace(/^\S+ (.*)$/, realDuration + " $1");
        valueEl.html(text);

        olLiveTickerSettingsControl.changeCheckboxes('liveTickerGoalAlarm', parseInt(data.liveTickerGoalAlarm));
        olLiveTickerSettingsControl.changeCheckboxes('liveTickerAudio', parseInt(data.liveTickerAudio));

        // add state color to slider track, necessary for relaunch style
        var $sliderTrack = $('.ol-live-ticker-overlay-slider-container .slider-track .slider-selection');
        var $sliderHandle = $('.ol-live-ticker-overlay-slider-container .slider-track .slider-handle');
        $sliderTrack.each(function(i, el) {
            $(el).addClass('ol-state-primary-color-'+ stateId);
        });
        $sliderHandle.each(function(i, el) {
            $(el).addClass('ol-state-primary-color-'+ stateId);
        });
    };

    /**
     * Change checkboxes like Audio or Goal Alarm.
     * 
     * @param id
     * @param val
     */
    olLiveTickerSettingsControl.changeCheckboxes = function(id, val) {
        if (val === 1) {
            $('.' + id + '1').hide();
            $('.' + id + '2').show();
            $('#' + id).attr('data-value', 1);
            $('#' + id).prop('checked', true);
        } else {
            $('.' + id + '1').show();
            $('.' + id + '2').hide();
            $('#' + id).attr('data-value', 0);
            $('#' + id).prop('checked', false);
        }
    };

    /**
     * Toggle Play-Resume/Pause button.
     *
     * @param withUpdate
     */
    olLiveTickerSettingsControl.togglePlayButton = function(withUpdate, el) {
        if (olLiveTickerTimer.timeIsUp()) return;
        
        $(el).toggleClass('pause play');
        $('.ol-liveticker-timeline-progress').toggleClass('paused playing');
        $('.ol-live-icon-before').toggleClass('live-blink');

        if (withUpdate) {
            olLiveTickerTimer.togglePlayButton();
        }
    };

    /**
     * Leaves the live mode. Button action.
     */
    olLiveTickerSettingsControl.finish = function() {
        olLiveTickerTimer.finish();
        if (liveModeBlick) clearInterval(liveModeBlick);
        $('.live-mode-blick').remove();
        $('.ltTimer').remove();
    };

    ///**
    // * Stop button action.
    // *
    // * @param withUpdate
    // */
    //olLiveTickerSettingsControl.stop = function(withUpdate) {
    //    if(withUpdate) {
    //        olLiveTickerTimer.stop();
    //    }
    //};

    /**
     * Audio and Goal Alarm button action.
     *
     * @param id
     */
    olLiveTickerSettingsControl.setCheckbox = function(id) {
        var newVal = parseInt($('#' + id).attr('data-value')) === 1? 0 : 1;
        olLiveTickerSettingsControl.changeCheckboxes(id, newVal);
        olLiveTickerTimer.setAudioOrGoalAlarmSettings(id, newVal);
    };

    /**
     * Set duration/speed of Live Ticker timer.
     *
     * @param value
     * @param withUpdate
     */
    olLiveTickerSettingsControl.setLiveTickerSliderValue = function(value, withUpdate) {
        toggleSpeedSlider(value);
        if (withUpdate) {
            olLiveTickerTimer.setDuration(value);
        }
    };

    /**
     * Change timer speed.
     *
     * @param value
     */
    function toggleSpeedSlider(value) {
        value = parseInt(value);
        var valueEl = $('#liveTickerMinutesSliderValue');
        // var text = valueEl.text().replace(/^\S+ (.*)$/, value+" $1");
        valueEl.html(value);

        $('#liveTickerMinutesSlider').bootstrapSlider('setValue', value);
        var event = jQuery.Event("slide");
        event.value = new Object();
        event.value.newValue = value;
        $('#liveTickerMinutesSlider').trigger(event);
    }


    liveModeBlick = window.setInterval(function() {
        $('.live-mode-blick').fadeIn(500).fadeOut(500);
    }, 1000);

})(window.olLiveTickerSettingsControl = window.olLiveTickerSettingsControl || {}, jQuery, {});
