"use strict";
(function(olFingerprint, $, undefined) {

    olFingerprint.init = function() {
        var browserTests = [
            "audio",
            "availableScreenResolution",
            "canvas",
            "colorDepth",
            "cookies",
            "cpuClass",
            "deviceDpi",
            "doNotTrack",
            "indexedDb",
            "installedFonts",
            "language",
            "localStorage",
            "pixelRatio",
            "platform",
            "plugins",
            "processorCores",
            "screenResolution",
            "sessionStorage",
            "timezoneOffset",
            "touchSupport",
            "userAgent",
            "webGl"
        ];

        imprint.test(browserTests).then(function(result) {
            var client = new ClientJS();
            var fingerprint = client.getFingerprint();
            $.post('/auth/storeFingerprint' , {hashValue: result + fingerprint});
        });
    }
    
}(window.olFingerprint = window.olFingerprint || {}, jQuery));
