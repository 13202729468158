"use strict";
(function(olPopularityWidget, $, undefined) {
    
    function getWidgetElement(uniqueId)
    {
        return $('#' + uniqueId);
    }
    
    function updateIconAnimation(uniqueId)
    {
        var $widget = getWidgetElement(uniqueId);
        var bIsInited = $widget.data('animation-initialized');
        
        if(!bIsInited && isInViewport($widget))
        {
            $widget.data('animation-initialized', true);
            var popAbsVal = parseFloat($widget.attr('data-pop-abs-val'));
            var popRelVal = parseFloat($widget.attr('data-pop-rel-val'));
            var time = parseInt($widget.attr('data-time'));
            var animationOffset = parseInt($widget.attr('data-animation-offset'));
            var animationFrame = parseInt($widget.attr('data-animation-frame'));
            
            function updateCounter(popAbsVal, uniqueId, element)
            { 
                var now = element.Counter;
                $('#' + uniqueId + ' .ol-popularity-widget-value').html((parseInt(now * 10) / 10).toFixed(1));
            };
            
            function updateIcons(popAbsVal, uniqueId, element)
            {     
                var e =getWidgetElement(uniqueId);
                var now = (element.Counter / (time / 1000)) * 5;              
                for(var i = 0; i < 5; i++)
                {
                    var isIconInited = e.data('animation-initialized-icon-' + i);
                    if(!isIconInited && now >= i)
                    {
                        var mod = Math.max(0, popAbsVal - i);
                        var animId = uniqueId + '_animate_' + i; 
                        
                        function innerLoop(j, animId, mod)
                        {                            
                            var reduced = (j - 1);
                            if(mod >= (reduced * (1 / 9)))
                            {
                                setTimeout(function() 
                                { 
                                    $('#' + animId + ' span').hide(); $('#' + animId + j).show() 
                                }, animationOffset + animationFrame * reduced );
                            }
                        }
                        
                        for(var j = 2; j <= 9; j++)
                        {
                            innerLoop(j, animId, mod);
                        }
                        e.data('animation-initialized-icon-' + i, true);
                    }
                }
            };
            
            $('#' + uniqueId).find('.ol-popularity-widget-bg').first().animate({ 'width': popRelVal + '%'}, {
                duration: time,
                easing: 'linear'});
            
            if($('#' + uniqueId + ' .ol-popularity-widget-value').is(':visible'))
            {
                jQuery({ Counter: 0 }).animate({ Counter: popAbsVal }, {
                    duration: time,
                    easing: 'linear',
                    complete: function() { updateCounter(popAbsVal, uniqueId, this); },
                    step: function() { updateCounter(popAbsVal, uniqueId, this); }
                    });
            }
            
            jQuery({ Counter: 0 }).animate({ Counter: popAbsVal }, {
                duration: time,
                easing: 'linear',
                step: function() { updateIcons(popAbsVal, uniqueId, this); },
                complete: function() { updateIcons(popAbsVal, uniqueId, this); }
            });
        }
    }
    
    // init scroll update handler
    $(window).scroll(function()
    {
        $('.ol-popularity-widget.ol-popularity-animation').each(function(index, element)
        {
            updateIconAnimation($(element).get(0).id);
        });
    });
    
    olPopularityWidget.init = function(uniqueId, stateId, backgroundColor, animation)
    {   
        if(typeof backgroundColor !== typeof undefined && backgroundColor === "rgba(0, 0, 0, 0)") backgroundColor = "rgba(255, 255, 255, 1)";
        if(typeof backgroundColor === typeof undefined) backgroundColor = false;
        var backgroundColor = backgroundColor === false ? olGUI.getStateLightColor(stateId) : backgroundColor;
        getWidgetElement(uniqueId).find('svg .a').css({"fill":backgroundColor}); 
        if (animation) {
            updateIconAnimation(uniqueId);
        }
    };

}(window.olPopularityWidget = window.olPopularityWidget || {}, jQuery));
